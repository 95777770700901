import React from 'react';


const MsgCardBody = (props) => {
    return <div className="card-body">
        <div className="row">
            <div className="col-12 messanger">
                <div className="messaging">
                    <div className="inbox_msg">
                        <div className="mesgs">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

const queryStatusEnum = {
    // all: 'All',
    open: 'Open',
    resolved: 'Resolved'
};

const queryStatusClassEnum = {
    // all: 'badge-gradient-primary',
    open: 'badge-gradient-warning',
    resolved: 'badge-gradient-success'
};

const queryStatusOutlineClassEnum = {
    open: 'badge-outline-warning',
    // all: 'badge-outline-primary',
    resolved: 'badge-outline-success'
};

const FilterTabs = (props) => {
    return <div className="col-12 col-md-6 mb-3 align-self-center">
        <div className="badgenumber">
            {Object.keys(queryStatusEnum).map(key =>
                <div className={`pointer-cursor mr-1 badge ${props.selectedStatus === key ? queryStatusClassEnum[key] : queryStatusOutlineClassEnum[key]}`}
                    onClick={() => props.setSelectedFilter(key)}>{queryStatusEnum[key]} {props.getCountByStatus(key)}
                </div>)}
        </div>
    </div>;
}

const OuterLayout = (props) => {
    return <>
        <div className="card shadow">
            <div className="card-header header-sm align-items-center p-1">
                <h4 style={{textTransform:"none"}}className="card-title card-title p-2 m-0">{props.title}
                    <a id="menu-close" onClick={props.onHide.bind(this)} className="btn btn-icon toggle float-right">
                        <i className="mdi mdi-close"></i>
                    </a>
                </h4>
            </div>
            <div className="card-body" style={{ padding: props.padding ? props.padding : '' }}>
                <div className="messaging">
                    <div className="inbox_msg">
                        <div className="mesgs">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}

const DocChatLayout = (props) => {
    return <div className="row">
        <div className="col-12 grid-margin">
            <div className="card">
                {props.children}
            </div>
        </div>
    </div>
}
const DocChatHeader = (props) => {
    return <div className="card-header header-sm d-flex justify-content-between align-items-center">
        <h4 className="card-title mb-0 mt-0">Chat</h4>
        <div className="wrapper d-flex align-items-center">
            {props.children}
            <a id="menu-close" onClick={props.onHide.bind(this)} className="btn btn-icon toggle float-right">
                <i className="mdi mdi-close"></i>
            </a>
        </div>
    </div>
}

const MsgType = (props) => {
    return <div className="type_msg">
        <div className="input_msg_write">
            {props.children}
        </div>
    </div>
}

const HelperAlert = (props) => {
    return <div className="alert alert-fill-danger mt-2 mb-2 justify-content-center p-2" role="alert">
        {props.msg}
    </div>
}

const ChatHistory = (props) => {
    return <div className="msg_history" style={{ padding: props.padding ? props.padding : '', height: props.height ? props.height : '', overflowY: props.overflowY ? props.overflowY : '' }}>{props.children}</div>
}

const OutgoingMsg = (props) => {
    return <div className="outgoing_msg">
        <div className="sent_msg">
            {props.children}
        </div></div>
}
const IncomingMsg = (props) => {
    return <div className="incoming_msg">{props.children}</div>
}

const ChatAttachment = (props) => {
    return <div className="chat-attachment ">
        <ul>
            {/*}       <li>
        <div className="thumb"><i className="mdi mdi-file-pdf"></i></div>
        <div className="details">
          <p className="file-name">Seminar Reports.pdf</p>
          <div className="buttons">
            <p className="file-size">678Kb</p>
            <a href="#" className="view">View</a>
            <a href="#" className="download">Download</a>
          </div>
        </div>
      </li>*/}
            <li>
                <div className="thumb"><i className="mdi mdi-file-image"></i></div>
                <div className="details">
                    <p className="file-name">new-medicine.jpg</p>
                    <div className="buttons">
                        <p className="file-size">1.96Mb</p>
                        <a href="#" className="view">View</a>
                        <a href="#" className="download">Download</a>
                    </div>
                </div>
            </li>
        </ul>
    </div>;
}

const DocChatAttachment = (props) => {
    return <div className="chat-attachment ">
        <ul>
            <li>
                <div className="thumb">
                    <a className="lightbox" href="#filea"><i className="mdi mdi-file-pdf"></i> </a>
                    <div className="lightbox-target" id="filea">
                        <img src="images/file.svg" alt="" width="300px" />
                        <a className="lightbox-close" href="#"></a>
                    </div>
                </div>
                <div className="details">
                    <p className="file-name">
                        Prescription.pdf</p>
                    <div className="buttons">
                        <p className="file-size">678Kb</p>
                        <a href="#" className="view">View</a>
                        <a href="#" className="download">Download</a>
                    </div>
                </div>
            </li>
            <li>
                <div className="thumb"><i className="mdi mdi-file-image"></i></div>
                <div className="details">
                    <p className="file-name">new-medicine.jpg</p>
                    <div className="buttons">
                        <p className="file-size">1.96Mb</p>
                        <a href="#" className="view">View</a>
                        <a href="#" className="download">Download</a>
                    </div>
                </div>
            </li>
        </ul>
    </div>;
}



const MultiLayout = (props) => {
    return <div className="main-panel">
        <div className="content-wrapper doctor-search-wrapper">
            <section className="appointmentslot">
                <div className="row ">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                {props.children}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
}
const Ticket = (props) => {
    return <a className="tickets-card row no-gutters" style={{ cursor: 'default' }}>{props.children}</a>
}

const PatientDetails = (props) => {
    return <div className="tickets-details col-xl-3 col-lg-4 col-sm-12  col-12">
        <div className="wrapper d-flex align-items-center">
            {props.children}
        </div>
    </div>
}

const PatientMsg = (props) => {
    return <div className=" col-lg-6 col-xl-8  col-sm-12">
        <hr className="d-block mt-2 mb-2 d-lg-none" />
        <p className="msg" style={{ wordBreak: 'break-all' }}>
            {props.children}
        </p>
    </div>
}
const DocReply = (props) => {
    return <div className="col-12 pt-2">{props.children}</div>
}



const View = {
    OuterLayout,
    ChatHistory,
    FilterTabs,
    MsgType,
    IncomingMsg,
    OutgoingMsg,
    ChatAttachment,
    DocChatAttachment,
    HelperAlert,
    MultiLayout,
    DocReply,
    PatientDetails,
    PatientMsg,
    Ticket,
    DocChatLayout,
    DocChatHeader,
    MsgCardBody
}

export default View