import AccountCreateContainer from '../Views/Account/AccountCreateContainer';
import AccountUserListContainer from '../Views/Account/AccountUserListContainer';
import AccountListContainer from '../Views/Account/AccountListContainer';


const adminRoutes = [{
    route: `/account-list`,
    sidebarName: "Account",
    navbarName: "Account",
    key: "account-view",
    icon: "account-view",
    isHide: false,
    component: AccountListContainer
}, {
    route: `/create-account`,
    sidebarName: "Account",
    navbarName: "Account",
    key: "account-create",
    icon: "account-create",
    isHide: false,
    component: AccountCreateContainer
    // account-users
}, {
    route: `/account-users`,
    sidebarName: "Account Users",
    navbarName: "Account Users",
    key: "account-users",
    icon: "account-users",
    isHide: false,
    component: AccountUserListContainer
    // 
}, {
    redirect: true,
    path: "/doctor-login",
    to: "/doctor-login",
    navbarName: "Redirect"
}];


export default adminRoutes;