import React, { useState } from "react";
import { S3UPLOAD_KEY } from '../../Constants/s3Key';
import Spinner from "../../Common/Spinner/Spinner";
import { Storage } from 'aws-amplify';
import SweetAlertComponent from '../../Common/Alert/SweetAlertComponent';
import { ToastContainer, toast } from 'react-toastify';
import accountService from '../../Services/account.settings';
import AccountDetails from './AccountDetails';
import "react-datepicker/dist/react-datepicker.css";
import ReactTooltip from 'react-tooltip';

const SavedImage = (props) => {
    let fileName = props.fileName ? props.fileName : props.imgKey ? props.imgKey.split('/').pop() : '';
    return <div key={props.idx} className="row mb-2">
        <div className="col-3 col-md-2"><img src="images/file.svg" alt="" width="40px" />
        </div>
        <div className="col-8 col-md-10">
            <div className="d-flex justify-content-between">
                <small>{fileName}</small>
                {!props.hideDelete && <small> <span onClick={() => props.deleteFromSavedFiles()}> <i className="mdi mdi-close"></i></span></small>}
            </div>
            <div className="progress progress-lg mt-2">
                <div className="progress-bar bg-info" role="progressbar" style={{ width: "100%" }}>100%</div>
            </div>
        </div>
    </div>
}
const NewUploadedImage = (props) => {
    return <div key={props.idx} className="row mb-2">
        <div className="col-3 col-md-2"><img src="images/file.svg" alt="" width="40px" />
        </div>
        <div className="col-8 col-md-10">
            <div className="d-flex justify-content-between">
                <small>{props.fileName}</small>
                {!props.hideDelete && <small> <span onClick={() => props.deleteFromFiles()}> <i className="mdi mdi-close"></i></span></small>}
            </div>
            <div className="progress progress-lg mt-2">
                <div className="progress-bar bg-info" role="progressbar" style={{ width: "100%" }}>100%</div>
            </div>
        </div>
    </div>
}
class AccountwiseSettingsComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            remarks: '',
            isLoading: '',
            alert: { show: false, type: '', title: '', text: '' },

            uploadedFile: [],
            uploadedPaymentImg: [],
            uploadedHeaderImg: [],
            uploadedFooterImg: [],
            uploadedSignImg: [],
            uploadedBrandImgs: [],
            uploadedAlternateLogo: [],

            uploadedPatientFile: [],
            uploadedPatientPaymentImg: [],
            uploadedPatientHeaderImg: [],
            uploadedPatientFooterImg: [],
            uploadedPatientSignImg: [],
            uploadedPatientBrandImgs: [],
            uploadedPatientAlternateLogo: [],

            settings: {
                speclty_complaints: props.details && props.details.speclty_complaints ? props.details.speclty_complaints : null,
                theme: props.details && props.details.theme ? props.details.theme : '',
                logo_bg_color: props.details && props.details.logo_bg_color ? props.details.logo_bg_color : 'none',
                translation_categories: props.details && props.details.translation_categories && props.details.translation_categories.length > 0 ? props.details.translation_categories.toString() : '',
                is_smswallet: props.details && props.details.is_smswallet ? true : false,
                price_per_sms: props.details && props.details.price_per_sms ? props.details.price_per_sms : '',
                use_third_party: props.details && props.details.use_third_party ? true : false,
                web_logo: props.details && props.details.web_logo ? props.details.web_logo : '',
                brand_name: props.details && props.details.brand_name ? props.details.brand_name : [],
                payment_image: props.details && props.details.payment_image ? props.details.payment_image : '',
                alternate_cliny_logo: props.details && props.details.alternate_cliny_logo ? props.details.alternate_cliny_logo : '',
                is_patient_hid: props.details && props.details.is_patient_hid ? props.details.is_patient_hid : false,
                is_speciality_req: props.details && props.details.is_speciality_req ? props.details.is_speciality_req : false,
                ask_patient_symptoms: props.details && props.details.ask_patient_symptoms ? props.details.ask_patient_symptoms : false,
                is_patient_speciality: props.details && props.details.is_patient_speciality ? props.details.is_patient_speciality : false,
                clinic_name: props.details && props.details.clinic_name ? props.details.clinic_name : '',
                emergency_info: props.details && props.details.emergency_info ? props.details.emergency_info : '',
                emergency_txt: props.details && props.details.emergency_txt ? props.details.emergency_txt : '',
                specialities: props.details && props.details.specialities ? Array.isArray(props.details.specialities) ? props.details.specialities.join() : props.details.specialities : '',
                branches: props.details && props.details.branches ? Array.isArray(props.details.branches) ? props.details.branches.join() : props.details.branches : '',
                branch_pincode: props.details && props.details.branch_pincode ? props.details.branch_pincode : {},
                services_fees: props.details && props.details.services_fees && Array.isArray(props.details.services_fees) ? props.details.services_fees : [],
                disclaimer: props.details && props.details.disclaimer ? props.details.disclaimer : '',
                redirect_time: props.details && props.details.redirect_time ? props.details.redirect_time : '',
                redirectOff: props.details && props.details.redirectOff ? props.details.redirectOff : '',
                free_follow_days: props.details && props.details.free_follow_days ? props.details.free_follow_days : '',
                support_link: props.details && props.details.support_link ? props.details.support_link : '',
                pre_appt_remarks: props.details && props.details.pre_appt_remarks ? props.details.pre_appt_remarks : '',
                slots_required: props.details && props.details.slots_required ? props.details.slots_required : false,
                is_copy_email: props.details && props.details.is_copy_email,
                institution: props.details && props.details.institution,
                transaction_report_to_email: props.details && props.details.transaction_report_to_email ? props.details.transaction_report_to_email.toString() : '',
                payment_message: props.details && props.details.payment_message ? props.details.payment_message : 'Dear [[patient_name]], your appointment has been booked with [[doctor_name]] on [[appt_date]] at [[appt_slot]]. Kindly make the payment on the given link: [[payment_url]][[payment_order_id]]',
                chat_message: props.details && props.details.chat_message ? props.details.chat_message : 'Dear [[patient_name]], your appointment has been confirmed with [[doctor_name]] on [[appt_date]] at [[appt_slot]]. You can visit your chatroom here: [[url]][[account_id]]waiting-area/[[room_id]]',
                prescription_message: props.details && props.details.prescription_message ? props.details.prescription_message : 'Dear [[patient_name]], your consultation with [[doctor_name]] is complete. Please find your prescription here: [[prescription_link]]',
                doctor_appointment_message: props.details && props.details.doctor_appointment_message ? props.details.doctor_appointment_message : 'Hi [[doctor_name]], you have an appointment booked with [[patient_name]] on [[appt_date]] at [[appt_slot]]',
                enable_tele_call: props.details && props.details.enable_tele_call ? props.details.enable_tele_call : false,
                upload_text: props.details && props.details.upload_text ? props.details.upload_text : '',
                ivrs_reminder_settings: props.details && props.details.ivrs_reminder_settings ? props.details.ivrs_reminder_settings : {
                    "expiry_gap": 0,
                    "gap": 0,
                    "is_active": false,
                    "voice_message": "Dear [[doctorName]], You have an appointment at [[apptSlot]]. Please be available."
                },
                video_timer_settings: props.details && props.details.video_timer_settings ? props.details.video_timer_settings : {
                    enable_popup: false,
                    doc_alert_time: 0,
                    is_active: false,
                    enable_doc_alert: false,
                    alert_emails: "",
                    popup_message:  "mins left for consultation to complete!",
                    popup_time: 0,
                    slot_time: 0
                },
                layout: props.details && props.details.layout || {},
                patient_layout: props.details && props.details.patient_layout || {},
                // vaccination settings
                append_to_sms: props.details && props.details.append_to_sms ? props.details.append_to_sms : '',
                show_pay_popup: props.details && props.details.show_pay_popup ? props.details.show_pay_popup : false,
                pay_popup_message: props.details && props.details.pay_popup_message ? props.details.pay_popup_message : '',
                show_popup: props.details && props.details.show_popup ? props.details.show_popup : false,
                popup_message: props.details && props.details.popup_message ? props.details.popup_message : '',
                sms_vendor: props.details && props.details.sms_vendor ? props.details.sms_vendor : '',
                otp_sms_vendor: props.details && props.details.otp_sms_vendor ? props.details.otp_sms_vendor : '',
                vacc_registration_msg: props.details && props.details.vacc_registration_msg ? props.details.vacc_registration_msg : '',
                refund_processed_message: props.details && props.details.refund_processed_message ? props.details.refund_processed_message : '',
                refund_approval_message: props.details && props.details.refund_approval_message ? props.details.refund_approval_message : '',
                show_vacc_booking_list: props.details && props.details.show_vacc_booking_list ? props.details.show_vacc_booking_list : false,
                show_vacc_questionnair: props.details && props.details.show_vacc_questionnair ? props.details.show_vacc_questionnair : '',
                flow_type: props.details && props.details.flow_type ? props.details.flow_type : '',
                is_vacc_account: props.details && props.details.is_vacc_account ? props.details.is_vacc_account : '',
                open_url_images: props.details && props.details.open_url_images ? props.details.open_url_images : {
                    "business-logo": '',
                    "green-logo": '',
                    "login-bg": '',
                    "logo": "",
                    "logo-mini": '',
                    "vaccination-img": ''
                },
                open_text: props.details && props.details.open_text ? props.details.open_text : {
                    "login_h1": "",
                    "login_h2": ""
                }

            },
            serviceName: '',
            serviceCost: '',
            speclty_complaints: props.details && props.details.speclty_complaints && ((Array.isArray(props.details.speclty_complaints) && props.details.speclty_complaints.length > 0) || (typeof props.details.speclty_complaints === 'object' && Object.keys(props.details.speclty_complaints).length !== 0)) ? { show: props.details.is_patient_speciality, map: this.converComplaintsData(props.details.speclty_complaints) } : { show: false, map: null },
        }
    }

    converComplaintsData(speclty_complaints) {
        if (speclty_complaints) {
            if (!Array.isArray(speclty_complaints)) {
                return Object.keys(speclty_complaints).map(s => ({ complaint: s, speci: speclty_complaints[s] }))
            }
            return speclty_complaints;
        }
    }

    // free_follow_days,slots_required
    onFileUpload(e, type, subtype) {
        let file = e.target.files[0];
        if (!file) return;
        let re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png|\.pdf)$/i;
        if (!re.exec(file.name.toLowerCase())) {
            this.notify(`${file.name} is not allowed for upload`);
            return;
        }
        let { uploadedFile, uploadedPaymentImg, uploadedHeaderImg, uploadedFooterImg, uploadedSignImg, uploadedBrandImgs, uploadedAlternateLogo, alternate_cliny_logo, web_logo, payment_image, layout, patient_layout, uploadedPatientFile, uploadedPatientPaymentImg, uploadedPatientHeaderImg, uploadedPatientFooterImg, uploadedPatientSignImg, uploadedPatientBrandImgs, uploadedPatientAlternateLogo } = this.state;
        if (subtype) {
            if (type === 'header_image') {
                uploadedHeaderImg[0] = { image: file, name: file.name };
                layout = { ...layout, header_image: '' }
            } else if (type === 'footer_image') {
                uploadedFooterImg[0] = { image: file, name: file.name };
                layout = { ...layout, footer_image: '' }
            } else if (type === 'signature_image') {
                uploadedSignImg[0] = { image: file, name: file.name };
                layout = { ...layout, signature_image: '' }
            } else if (type === 'payment_image') {
                uploadedPaymentImg[0] = { image: file, name: file.name };
                payment_image = '';
            } else if (type === 'brand_name') {
                uploadedBrandImgs.push({ image: file, name: file.name });
            } else if (type === 'alternate_cliny_logo') {
                uploadedAlternateLogo[0] = { image: file, name: file.name };
                alternate_cliny_logo = ''
            } else {
                uploadedFile[0] = { image: file, name: file.name };
                if (!this.props.doctor) {
                    web_logo = '';
                }
            }
            this.setState({ uploadedFile, uploadedPaymentImg, uploadedHeaderImg, uploadedFooterImg, uploadedSignImg, uploadedBrandImgs, uploadedAlternateLogo, alternate_cliny_logo, web_logo, payment_image, layout });
        } else {
            if (type === 'header_image') {
                uploadedPatientHeaderImg[0] = { image: file, name: file.name };
                patient_layout = { ...patient_layout, header_image: '' }
            } else if (type === 'footer_image') {
                uploadedPatientFooterImg[0] = { image: file, name: file.name };
                patient_layout = { ...patient_layout, footer_image: '' }
            } else if (type === 'signature_image') {
                uploadedPatientSignImg[0] = { image: file, name: file.name };
                patient_layout = { ...patient_layout, signature_image: '' }
            } else if (type === 'payment_image') {
                uploadedPatientPaymentImg[0] = { image: file, name: file.name };
                payment_image = '';
            } else if (type === 'brand_name') {
                uploadedPatientBrandImgs.push({ image: file, name: file.name });
            } else if (type === 'alternate_cliny_logo') {
                uploadedPatientAlternateLogo[0] = { image: file, name: file.name };
                alternate_cliny_logo = ''
            } else {
                uploadedFile[0] = { image: file, name: file.name };
                if (!this.props.doctor) {
                    web_logo = '';
                }
            }
            this.setState({ uploadedFile, uploadedPaymentImg, uploadedHeaderImg, uploadedFooterImg, uploadedSignImg, uploadedBrandImgs, uploadedAlternateLogo, alternate_cliny_logo, web_logo, payment_image, layout, patient_layout, uploadedPatientFile, uploadedPatientPaymentImg, uploadedPatientHeaderImg, uploadedPatientFooterImg, uploadedPatientSignImg, uploadedPatientBrandImgs, uploadedPatientAlternateLogo });
        }
    }

    notify(message) {
        toast.warning(message, { position: toast.POSITION.TOP_RIGHT, containerId: 'A' });
    }

    deleteFromFiles(idx, type, subtype) {
        let { uploadedFile, uploadedPaymentImg, uploadedAlternateLogo, uploadedBrandImgs, uploadedHeaderImg, uploadedFooterImg, uploadedSignImg, uploadedPatientFile, uploadedPatientPaymentImg, uploadedPatientHeaderImg, uploadedPatientFooterImg, uploadedPatientSignImg, uploadedPatientBrandImgs, uploadedPatientAlternateLogo } = this.state;
        if (subtype) {
            if (type === 'header_image') {
                uploadedHeaderImg.splice(idx, 1);
            } else if (type === 'footer_image') {
                uploadedFooterImg.splice(idx, 1);
            } else if (type === 'signature_image') {
                uploadedSignImg.splice(idx, 1);
            } else if (type === 'payment_image') {
                uploadedPaymentImg.splice(idx, 1);
            } else if (type === 'brand_name') {
                uploadedBrandImgs.splice(idx, 1)
            } else if (type === 'alternate_cliny_logo') {
                uploadedAlternateLogo.splice(idx, 1)
            } else {
                uploadedFile.splice(idx, 1);
            }
        } else {
            if (type === 'header_image') {
                uploadedPatientHeaderImg.splice(idx, 1);
            } else if (type === 'footer_image') {
                uploadedPatientFooterImg.splice(idx, 1);
            } else if (type === 'signature_image') {
                uploadedPatientSignImg.splice(idx, 1);
            } else if (type === 'payment_image') {
                uploadedPatientPaymentImg.splice(idx, 1);
            } else if (type === 'brand_name') {
                uploadedPatientBrandImgs.splice(idx, 1)
            } else if (type === 'alternate_cliny_logo') {
                uploadedPatientAlternateLogo.splice(idx, 1)
            } else {
                uploadedFile.splice(idx, 1);
            }
        }

        this.setState({ uploadedFile, uploadedPaymentImg, uploadedAlternateLogo, uploadedBrandImgs, uploadedHeaderImg, uploadedFooterImg, uploadedSignImg, uploadedPatientPaymentImg, uploadedPatientHeaderImg, uploadedPatientFooterImg, uploadedPatientSignImg, uploadedPatientBrandImgs, uploadedPatientAlternateLogo });
    }

    onSubmitReport() {
        let { uploadedFile, uploadedPaymentImg, uploadedBrandImgs, uploadedAlternateLogo, uploadedFooterImg, uploadedSignImg, uploadedHeaderImg, settings, speclty_complaints, uploadedPatientPaymentImg, uploadedPatientHeaderImg, uploadedPatientFooterImg, uploadedPatientSignImg, uploadedPatientBrandImgs, uploadedPatientAlternateLogo } = this.state;
        // transaction_report_to_email
        let trancRepEmail = settings.transaction_report_to_email ? settings.transaction_report_to_email.split(",") : []
        let branchlistData = (settings.branches && (settings.branches.indexOf(',') !== 1)) ? settings.branches.split(",").map(s => s.trim ? s.trim().toLowerCase() : s) : settings.branches ? settings.branches : ''
        let translationCategoriesData = (settings.translation_categories && (settings.translation_categories.indexOf(',') !== 1)) ? settings.translation_categories.split(",").map(s => s.trim ? s.trim().toLowerCase() : s) : settings.translation_categories ? settings.translation_categories : ''
        let specialitiesData = (settings.specialities && (settings.specialities.indexOf(',') !== 1)) ? settings.specialities.split(",").map(s => s.trim ? s.trim() : s) : settings.specialities ? settings.specialities : ''
        let specltyComplaintMap = []
        if (speclty_complaints.show && speclty_complaints.map && settings.is_patient_speciality) {
            speclty_complaints.map.forEach(item => {
                if ((!item.speci && !item.speci.trim()) || (!item.complaint && !item.complaint.trim())) return;
                specltyComplaintMap.push({ speci: item.speci, complaint: item.complaint ? item.complaint.trim() : '' });
            })
        }
        let imgData = {
            ...settings,
            transaction_report_to_email: trancRepEmail,
            speclty_complaints: specltyComplaintMap.length === 0 ? {} : specltyComplaintMap,
            specialities: specialitiesData,
            translation_categories: translationCategoriesData,
            branches: branchlistData
        }

        this.setState({ isLoading: true });

        if (!this.props.doctor) { // on account settings page
            let PromiseArr = [];
            // uploadedPatientPaymentImg, uploadedPatientHeaderImg, uploadedPatientFooterImg, uploadedPatientSignImg, uploadedPatientBrandImgs, uploadedPatientAlternateLogo
            if (uploadedPatientBrandImgs.length) {
                // imgData.brand_name = [];
                uploadedPatientBrandImgs.forEach((file) => {
                    PromiseArr.push(this.saveImageToS3AndReturnImgPath(file, 'brand_name_patient'));
                });
            }
            if (uploadedPatientPaymentImg.length) {
                PromiseArr.push(this.saveImageToS3AndReturnImgPath(uploadedPatientPaymentImg[0], 'payment_image_patient'));
            }

            if (uploadedPatientHeaderImg.length) {
                PromiseArr.push(this.saveImageToS3AndReturnImgPath(uploadedPatientHeaderImg[0], 'header_image_patient'));
            }

            if (uploadedPatientFooterImg.length) {
                PromiseArr.push(this.saveImageToS3AndReturnImgPath(uploadedPatientFooterImg[0], 'footer_image_patient'));
            }

            if (uploadedPatientSignImg.length) {
                PromiseArr.push(this.saveImageToS3AndReturnImgPath(uploadedPatientSignImg[0], 'signature_image_patient'));
            }

            if (uploadedPatientAlternateLogo.length) {
                PromiseArr.push(this.saveImageToS3AndReturnImgPath(uploadedPatientAlternateLogo[0], 'alternate_cliny_logo_patient'));
            }

            // uploading payment image
            if (uploadedPaymentImg.length) {
                PromiseArr.push(this.saveImageToS3AndReturnImgPath(uploadedPaymentImg[0], 'payment_image'));
            }

            // uploading header image
            if (uploadedHeaderImg.length) {
                PromiseArr.push(this.saveImageToS3AndReturnImgPath(uploadedHeaderImg[0], 'header_image'));
            }

            // uploading footer image
            if (uploadedFooterImg.length) {
                PromiseArr.push(this.saveImageToS3AndReturnImgPath(uploadedFooterImg[0], 'footer_image'));
            }

            // uploading signature image
            if (uploadedSignImg.length) {
                PromiseArr.push(this.saveImageToS3AndReturnImgPath(uploadedSignImg[0], 'signature_image'));
            }

            // uploading alternate logo image
            if (uploadedAlternateLogo.length) {
                PromiseArr.push(this.saveImageToS3AndReturnImgPath(uploadedAlternateLogo[0], 'alternate_cliny_logo'));
            }

            // uploading brand images
            if (uploadedBrandImgs.length) {
                // imgData.brand_name = [];
                uploadedBrandImgs.forEach((file) => {
                    PromiseArr.push(this.saveImageToS3AndReturnImgPath(file, 'brand_name'));
                });
            }

            // uploading profile photo / account logo
            if (uploadedFile.length) {
                PromiseArr.push(this.saveImageToS3AndReturnImgPath(uploadedFile[0], ''));
            }

            Promise.all(PromiseArr).then(res => {
                if (res && res.length) {
                    res.forEach((r) => {
                        if (!r.key) return;
                        if (r.key.indexOf('header_image_patient') !== -1) {
                            imgData.patient_layout.header_image = `public/${r.key}`;
                        } else if (r.key.indexOf('footer_image_patient') !== -1) {
                            imgData.patient_layout.footer_image = `public/${r.key}`;
                        } else if (r.key.indexOf('signature_image_patient') !== -1) {
                            imgData.patient_layout.signature_image = `public/${r.key}`;
                        } else if (r.key.indexOf('header_image') !== -1) {
                            imgData.layout.header_image = `public/${r.key}`;
                        } else if (r.key.indexOf('footer_image') !== -1) {
                            imgData.layout.footer_image = `public/${r.key}`;
                        } else if (r.key.indexOf('signature_image') !== -1) {
                            imgData.layout.signature_image = `public/${r.key}`;
                        } else if (r.key.indexOf('payment_image') !== -1) {
                            imgData.payment_image = `public/${r.key}`;
                        } else if (r.key.indexOf('alternate_cliny_logo') !== -1) {
                            imgData.alternate_cliny_logo = `public/${r.key}`
                        } else if (r.key.indexOf('brand_name') !== -1) {
                            imgData.brand_name.push(`public/${r.key}`);
                        } else {
                            imgData.web_logo = `public/${r.key}`;
                        }
                    });
                }

                // if (imgData.brand_name && imgData.brand_name.length === 0) {
                //     imgData.brand_name = null;
                // }
                this.saveSettings(imgData);
            }).catch(err => console.log('promises dint resolve'));

        } else {
            // uploading profile photo / account logo on doctor creation page
            if (uploadedFile.length) {
                this.saveImageToS3AndReturnImgPath(uploadedFile[0], '').then(r => {
                    this.props.setImgPath(r.key, uploadedFile[0].name);
                }).catch(err => console.log('could not upload photo'));
            }
        }
    }

    saveImageToS3AndReturnImgPath(file, type) {
        let { image, name } = file;
        name = name.toLowerCase().replace(/\s/g, '')
        Storage.configure({
            level: 'public',
            AWSS3: S3UPLOAD_KEY
        });
        let url = 'logos/';
        let apistage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1 || window.location.hostname.indexOf('d32uc620y3be9') !== -1 || window.location.hostname.indexOf('v4abbottclinytics') !== -1) ? 'dev' : 'prod';
        if (this.props.doctor) {
            url = url + `${apistage}/${this.props.providerName}/profile_pic/img/${new Date().getTime()}/${name}`;
        } else {
            if (type === 'header_image_patient') {
                url = url + `${apistage}/${this.props.providerName}/header_image_patient/${new Date().getTime()}/${name}`;
            } else if (type === 'footer_image_patient') {
                url = url + `${apistage}/${this.props.providerName}/footer_image_patient/${new Date().getTime()}/${name}`;
            } else if (type === 'signature_image_patient') {
                url = url + `${apistage}/${this.props.providerName}/signature_image_patient/${new Date().getTime()}/${name}`;
            } else if (type === 'header_image') {
                url = url + `${apistage}/${this.props.providerName}/header_image/${new Date().getTime()}/${name}`;
            } else if (type === 'footer_image') {
                url = url + `${apistage}/${this.props.providerName}/footer_image/${new Date().getTime()}/${name}`;
            } else if (type === 'signature_image') {
                url = url + `${apistage}/${this.props.providerName}/signature_image/${new Date().getTime()}/${name}`;
            } else if (type === 'payment_image') {
                url = url + `${apistage}/${this.props.providerName}/payment_image/${new Date().getTime()}/${name}`;
            } else if (type === 'brand_name') {
                url = url + `${apistage}/${this.props.providerName}/brand_name/${new Date().getTime()}/${name}`;
            } else if (type === 'alternate_cliny_logo') {
                url = url + `${apistage}/${this.props.providerName}/alternate_cliny_logo/${new Date().getTime()}/${name}`;
            } else {
                url = url + `${apistage}/${this.props.providerName}/img/${new Date().getTime()}/${name}`;
            }
        }
        return Storage.put(url, image, { contentType: file.image.type, ACL: 'public-read' });
    }

    async saveSettings(imgData) {
        try {
            let response = await accountService.uploadAccountLogo(this.props.providerName, imgData);
            let { alert } = this.state;
            if (response && response.type === 'success') {
                alert = { show: true, type: 'success', title: 'File uploaded succesfully' };
                this.setState({ alert, isLoading: false });
            } else {
                alert = { show: true, type: 'error', title: 'Error', text: 'Error while uploading! Please try again.' };
                this.setState({ isLoading: false, alert });
            }
        } catch (err) {
            let { alert } = this.state;
            alert = { show: true, type: 'error', title: 'Error', text: 'Error while uploading! Please try again.' };
            this.setState({ isLoading: false, alert });
        }
    }

    sweetAlertClose = () => {
        this.setState({ alert: { show: false } }, () => this.props.emitCancelEvent(true));
    }

    getVideoTimerSettings = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                video_timer_settings: {
                    ...prevState.settings.video_timer_settings,
                    [name]: value
                }
            }
        }))
    }

    getIvrsSettings = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                ivrs_reminder_settings: {
                    ...prevState.settings.ivrs_reminder_settings,
                    [name]: value
                }
            }
        }))
    }
    getIvrsCheckboxSettings = (e) => {
        const { name, checked } = e.target;
        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                ivrs_reminder_settings: {
                    ...prevState.settings.ivrs_reminder_settings,
                    [name]: checked
                }
            }
        }))
    }

    getVideoTimerCheckboxSettings = (e) => {
        const { name, checked } = e.target;
        this.setState(prevState => ({
            settings: {
                ...prevState.settings,
                video_timer_settings: {
                    ...prevState.settings.video_timer_settings,
                    [name]: checked
                }
            }
        }))
    }

    getDetailChange = (e) => {
        this.setState({ settings: { ...this.state.settings, [e.target.name]: e.target.value } });
    }

    getCheckboxEvent = (event) => {
        this.setState({ settings: { ...this.state.settings, [event.target.name]: event.target.checked } });
    }

    onSpeciCompCheckClick = (e) => {
        let { speclty_complaints } = this.state;
        if (e.target.checked && !speclty_complaints.map) {
            speclty_complaints.map = [{ speci: '', complaint: '' }]
        }
        this.setState({ speclty_complaints: { ...speclty_complaints, show: e.target.checked } });
    }

    onSpeciCompAddition = (idx, e) => {
        let { speclty_complaints } = this.state;
        let { name, value } = e.target;
        speclty_complaints.map[idx][name] = value
        this.setState({ speclty_complaints });
    }

    incSpeciComp = (idx) => {
        let { speclty_complaints } = this.state;
        speclty_complaints.map.splice(idx + 1, 0, { speci: '', complaint: '' });
        this.setState({ speclty_complaints });
    }

    decSpeciComp = (idx) => {
        let { speclty_complaints } = this.state;
        speclty_complaints.map.splice(idx, 1);
        this.setState({ speclty_complaints });
    }

    getOnServiceDetailAddition = (name, e) => {
        if (name) {
            this.setState({ [e.target.name]: e.target.value });
            return;
        }
        let { serviceName, serviceCost } = this.state;
        let { services_fees } = this.state.settings;
        if (!serviceName || !serviceCost) {
            return;
        }
        let data = {
            serviceName: serviceName,
            serviceCost: serviceCost
        }
        services_fees.push(data);
        this.setState({ settings: { ...this.state.settings, services_fees } }, () => {
            let { serviceName, serviceCost } = this.state;
            serviceName = '';
            serviceCost = ''
            this.setState({ serviceName, serviceCost })
        });
    }

    deleteFeeData = (idx) => {
        let { services_fees } = this.state.settings;
        services_fees.splice(idx, 1);
        this.setState({ settings: { ...this.state.settings, services_fees } });
    }

    getEmitedThemeData = (indThemeData) => {
        this.setState({ settings: { ...this.state.settings, theme: indThemeData.key } });
    }

    getEmitedClickedPlaceholderData = (messageFieldType, indPlaceholder) => {
        let { settings } = this.state;
        let message = settings[messageFieldType];
        if (message.indexOf(indPlaceholder.key) !== -1) {
            message = message.replace(`[[${indPlaceholder.key}]]`, "");
        } else {
            message = message + `[[${indPlaceholder.key}]]`
        }
        this.setState({ settings: { ...this.state.settings, [messageFieldType]: message } });
    }

    deleteFromSavedFiles = (type, subtype, idx) => {
        let { web_logo, payment_image, alternate_cliny_logo, brand_name, layout, patient_layout } = this.state.settings;
        if (subtype) {
            if (type === 'payment_image') {
                payment_image = '';
            } else if (type === 'brand_name') {
                brand_name.splice(idx, 1);
            } else if (type === 'alternate_cliny_logo') {
                alternate_cliny_logo = '';
            } else if (type === 'web_logo') {
                web_logo = '';
            } else if (type === 'header_image') {
                layout = {
                    ...layout, header_image: ''
                }
            } else if (type === 'footer_image') {
                layout = {
                    ...layout, footer_image: ''
                }
            } else if (type === 'signature_image') {
                layout = {
                    ...layout, signature_image: ''
                }
            }
        } else {
            if (type === 'payment_image') {
                payment_image = '';
            } else if (type === 'brand_name') {
                brand_name.splice(idx, 1);
            } else if (type === 'alternate_cliny_logo') {
                alternate_cliny_logo = '';
            } else if (type === 'web_logo') {
                web_logo = '';
            } else if (type === 'header_image') {
                patient_layout = {
                    ...patient_layout, header_image: ''
                }
            } else if (type === 'footer_image') {
                patient_layout = {
                    ...patient_layout, footer_image: ''
                }
            } else if (type === 'signature_image') {
                patient_layout = {
                    ...patient_layout, signature_image: ''
                }
            }
        }

        this.setState({
            settings: {
                ...this.state.settings, layout, patient_layout,
                web_logo, payment_image, alternate_cliny_logo, brand_name
            }
        });
    }

    setLogoBg = (theme) => {
        this.setState({ settings: { ...this.state.settings, ['logo_bg_color']: theme } });
    }

    isBtnDisabled() {
        let { settings } = this.state;
        if (!this.props.doctor) {
            if (settings.is_patient_speciality && (!settings.specialities || !settings.specialities.trim())) {
                return true;
            }
        }
        return false;
    }

    TooltipEnum = {
        account_logo: 'Upload the image to view it on patient registration page',
        payment_image: 'Upload the image to view it on payment gateway page',
        sponsor_image: 'Upload multiple images to view on EMR portal\'s doctor login',
        alternate_logo_image: 'Upload the image to view it on Doctor, staff portal'
    }

    render() {
        const { uploadedFile, isLoading, uploadedPaymentImg, uploadedBrandImgs, uploadedAlternateLogo, uploadedHeaderImg,
            uploadedFooterImg, uploadedSignImg, serviceName, serviceCost, speclty_complaints, uploadedPatientPaymentImg, uploadedPatientHeaderImg, uploadedPatientFooterImg, uploadedPatientSignImg, uploadedPatientBrandImgs, uploadedPatientAlternateLogo } = this.state;
        const { web_logo, brand_name, alternate_cliny_logo, payment_image } = this.state.settings;
        const { open } = this.props;
        // let isInValid = false || (uploadedFile && uploadedFile.length === 0);
        
        return (
            <div className={`modal fade ${open ? 'show' : ''}`} id="timeslotmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden={`${open ? true : false}`} style={{
                    display: open ? 'block' : 'none', paddingRight: open ? '17px' : '', overflowX: 'hidden', overflowY: 'auto', backgroundColor: '#716e6e54'
                }}>
                <div className={`modal-dialog ${this.props.fullModal ? 'modal-lg' : ''}`} role="document" style={{ marginTop: 'calc(70px + 0px)' }}>
                    <div className="modal-content bg-white">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{this.props.title ? this.props.title : 'Select your booking slot'}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.emitCancelEvent.bind(this, false)}>
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body ">
                            <section className="appointmentslot">
                                <div className="row mb-3 border-bottom">
                                    <div className={`col-12 ${this.props.doctor ? '' : 'col-md-3'}`}>
                                        {/* For uploading profile photo/account logo */}
                                        <form method="post" action="#" id="#">
                                            <div className="form-group filedrag color">
                                                <label>{this.props.doctor ? 'Upload File' : 'Account Logo'}  <i className="mdi mdi-information-outline" data-tip data-for="account_logo" /></label>
                                                <input type="file" className="form-control" onChange={e => this.onFileUpload(e)} onClick={(e) => { e.target.value = null }}></input>
                                            </div>
                                        </form>
                                        {uploadedFile.length === 0 && !web_logo
                                            ?
                                            <small className="text-muted mb-3">No file uploaded as yet.</small>
                                            :
                                            <>{uploadedFile.map((file, idx) =>
                                                <NewUploadedImage idx={idx} fileName={file.name} deleteFromFiles={() => this.deleteFromFiles(idx)} />)}
                                            </>}
                                        {!this.props.doctor && web_logo && <SavedImage imgKey={web_logo} deleteFromSavedFiles={this.deleteFromSavedFiles.bind(this, 'web_logo')} />}
                                    </div>

                                    {!this.props.doctor && <>
                                        <div className="col-12 col-md-3">
                                            {/* For uploading payment image */}
                                            <form method="post" action="#" id="#">
                                                <div className="form-group filedrag color">
                                                    <label>Payment Image <i className="mdi mdi-information-outline" data-tip data-for='payment_image' /></label>
                                                    <input type="file" className="form-control" onChange={e => this.onFileUpload(e, 'payment_image', 'general')} onClick={(e) => { e.target.value = null }}></input>
                                                </div>
                                            </form>
                                            {uploadedPaymentImg.length === 0 && !payment_image
                                                ?
                                                <small className="text-muted">No file uploaded as yet.</small>
                                                :
                                                <>{uploadedPaymentImg.map((file, idx) =>
                                                    <NewUploadedImage idx={idx} fileName={file.name} deleteFromFiles={() => this.deleteFromFiles(idx, 'payment_image', 'general')} />)}
                                                </>}
                                            {payment_image && <SavedImage imgKey={payment_image} deleteFromSavedFiles={this.deleteFromSavedFiles.bind(this, 'payment_image', 'general')} />}
                                        </div>
                                        <div className="col-12 col-md-3">
                                            {/* For uploading multiple brand names for appt page footer image */}
                                            <form method="post" action="#" id="#">
                                                <div className="form-group filedrag color">
                                                    <label>Sponsor Images <i className="mdi mdi-information-outline" data-tip data-for='sponsor_image' /></label>

                                                    <input type="file" className="form-control" multiple onChange={e => this.onFileUpload(e, 'brand_name', 'general')} onClick={(e) => { e.target.value = null }}></input>
                                                </div>
                                            </form>
                                            {uploadedBrandImgs.length === 0 && (!brand_name || !brand_name.length)
                                                ?
                                                <small className="text-muted">No file uploaded as yet.</small>
                                                :
                                                <>{uploadedBrandImgs.map((file, idx) =>
                                                    <NewUploadedImage idx={idx} fileName={file.name} deleteFromFiles={() => this.deleteFromFiles(idx, 'brand_name', 'general')} />
                                                )}
                                                </>}
                                            {brand_name && brand_name.map((brand, idx) => <SavedImage imgKey={brand} key={idx} deleteFromSavedFiles={this.deleteFromSavedFiles.bind(this, 'brand_name', 'general', idx)} />)}
                                        </div>
                                        <div className="col-12 col-md-3">
                                            {/* For uploading alternate logo image */}
                                            <form method="post" action="#" id="#">
                                                <div className="form-group filedrag color">
                                                    <label>Alternate Logo Image <i className="mdi mdi-information-outline" data-tip data-for='alternate_logo_image' /></label>
                                                    <input type="file" className="form-control" onChange={e => this.onFileUpload(e, 'alternate_cliny_logo', 'general')} onClick={(e) => { e.target.value = null }}></input>
                                                </div>
                                            </form>
                                            {uploadedAlternateLogo.length === 0 && !alternate_cliny_logo
                                                ?
                                                <small className="text-muted">No file uploaded as yet.</small>
                                                :
                                                <>{uploadedAlternateLogo.map((file, idx) =>
                                                    <NewUploadedImage idx={idx} fileName={file.name} deleteFromFiles={() => this.deleteFromFiles(idx, 'alternate_cliny_logo', "general")} />
                                                )}
                                                </>}
                                            {alternate_cliny_logo && <SavedImage imgKey={alternate_cliny_logo} deleteFromSavedFiles={this.deleteFromSavedFiles.bind(this, 'alternate_cliny_logo', 'general')} />}
                                        </div>
                                    </>}
                                </div>
                                {Object.keys(this.TooltipEnum).map(key => <ReactTooltip id={key} effect='solid'>
                                    <span>{this.TooltipEnum[key]}</span>
                                </ReactTooltip>)}
                                {!this.props.doctor && <AccountDetails
                                    details={{ ...this.state.settings, serviceName, serviceCost, speclty_complaints }}
                                    getIvrsSettings={this.getIvrsSettings.bind(this)}
                                    getIvrsCheckboxSettings={this.getIvrsCheckboxSettings.bind(this)}
                                    getVideoTimerCheckboxSettings={this.getVideoTimerCheckboxSettings.bind(this)}
                                    getVideoTimerSettings={this.getVideoTimerSettings.bind(this)}
                                    getEmitedDetailChange={this.getDetailChange.bind(this)} getCheckboxEvent={this.getCheckboxEvent.bind(this)}
                                    emitOnServiceDetailAddition={this.getOnServiceDetailAddition.bind(this)}
                                    emitDeleteFeeDataEvent={this.deleteFeeData.bind(this)}
                                    onSpeciCompCheckClick={this.onSpeciCompCheckClick.bind(this)}
                                    onSpeciCompAddition={this.onSpeciCompAddition.bind(this)}
                                    incSpeciComp={this.incSpeciComp.bind(this)}
                                    decSpeciComp={this.decSpeciComp.bind(this)}
                                    emitClickedThemeData={this.getEmitedThemeData.bind(this)}
                                    setLogoBg={this.setLogoBg.bind(this)}
                                    emitClickedPlaceholderData={this.getEmitedClickedPlaceholderData.bind(this)} />}
                                {!this.props.doctor && <LayoutSection layout={this.state.settings.layout} uploadedHeaderImg={uploadedHeaderImg} uploadedFooterImg={uploadedFooterImg} uploadedSignImg={uploadedSignImg} emitDetailChange={this.getDetailChange.bind(this)} onFileUpload={this.onFileUpload.bind(this)} deleteFromSavedFiles={this.deleteFromSavedFiles.bind(this)} deleteFromFiles={this.deleteFromFiles.bind(this)} />}
                                {!this.props.doctor && <PatientLayoutSection patient_layout={this.state.settings.patient_layout} uploadedHeaderImg={uploadedPatientHeaderImg} uploadedFooterImg={uploadedPatientFooterImg} uploadedSignImg={uploadedPatientSignImg} emitDetailChange={this.getDetailChange.bind(this)} onFileUpload={this.onFileUpload.bind(this)} deleteFromSavedFiles={this.deleteFromSavedFiles.bind(this)} deleteFromFiles={this.deleteFromFiles.bind(this)} />}

                                {/* PatientLayoutSection */}
                            </section>
                        </div>
                        <div className="modal-footer">
                            {isLoading && <div style={{ display: 'inline-block', 'marginRight': '20px' }}><Spinner width={40} height={40}></Spinner></div>}
                            <button type="submit" disabled={isLoading || this.isBtnDisabled()} className="btn btn-success mr-2 btn-rounded" onClick={() => this.onSubmitReport()}>Submit</button>
                            <button type="button" className="btn btn-light" data-dismiss="modal" onClick={this.props.emitCancelEvent.bind(this, false)}>Cancel</button>
                            {this.isBtnDisabled() && <p><small className="error-text text-danger">*Fill all Required fields</small></p>}
                        </div>
                    </div>
                </div>
                {this.state.alert.show &&
                    <SweetAlertComponent show={this.state.alert.show} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.sweetAlertClose}></SweetAlertComponent>
                }
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
            </div>
        )
    }
}

export default AccountwiseSettingsComponent;

export function Slider({ name, value, changeLayout }) {
    return <div className="align slidecontainer" style={{ width: '150px', float: 'right' }}>
        <i className="mdi mdi-format-float-left mr-5"></i>
        <i className="mdi mdi-format-float-center mr-5"></i>
        <i className="mdi mdi-format-float-right"></i>
        <input type="range" name={name} min="1" max="3" value={value} className="slider" id={name} onChange={changeLayout.bind(this)} />
    </div>;
}
export function SliderSignAlign({ name, value, changeLayout }) {
    return <div className="align slidecontainer" style={{ width: '225px' }}>
        <input type="range" name={name} min="1" max="3" value={value} className="slider" id={name} onChange={changeLayout.bind(this)} />
        <span className="mr-5">Above</span>
        <span className="mr-5">Right</span>
        <span>Below</span>
    </div>;
}
export function SliderSignSize({ name, value, changeLayout }) {
    return <div className="align slidecontainer" style={{ width: '172px' }}>
        <input type="range" name={name} min="1" max="3" value={value} className="slider" id={name} onChange={changeLayout.bind(this)} />
        <i className="mdi mdi-transcribe mr-5"></i>
        <i className="mdi mdi-transcribe mdi-24px mr-5"></i>
        <i className="mdi mdi-transcribe mdi-36px"></i>
    </div>;
}
// online doctor prescription layout setting
export function LayoutSection(props) {
    function changeLayout(e) {
        let { layout } = props;
        let { name, value } = e.target;
        if (name === 'sign_text_disabled') {
            value = e.target.checked
        }
        layout = {
            ...layout,
            [name]: value
        }
        props.emitDetailChange({ target: { name: 'layout', value: layout } });
    }
    function onCheckboxLayout(e) {
        let { layout } = props;
        let { name, checked } = e.target;
        layout = {
            ...layout,
            [name]: checked
        }
        props.emitDetailChange({ target: { name: 'layout', value: layout } });
    }
    let TooltipEnum = {
        patient_prescription_layout: 'Position for Patient Prescription Header, footer, notes, Print Prescription can be customized and added'
    }

    return <div className="form-group pt-3 mb-3 border-top">
        <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Doctor Print Prescription Layout <i className="mdi mdi-information-outline" data-tip data-for='patient_prescription_layout' /></label>
        <div className="row">
            <div className="form-group mb-3 col-md-4">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label className="form-check-label" htmlFor="name" style={{ textTransform: 'uppercase' }}>
                        <input type="checkbox" className="form-check-input" name="only_header" id="only_header" checked={props.layout.only_header ? true : false}
                            onClick={onCheckboxLayout.bind(this)} /> Only Header Image <i className="input-helper"></i></label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="form-group mb-3 col-md-4">
                <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Header Text 1<em>(Italics)</em></label>
                <Slider name={'h_text_align'} value={props.layout.h_text_align || '3'} changeLayout={changeLayout.bind(this)} />
                <input type="text" name="header_text" className="form-control" placeholder='Enter text' value={props.layout.header_text}
                    onChange={changeLayout.bind(this)} />
            </div>
            <div className="form-group mb-3 col-md-4">
                <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Header Text 2<strong>(Bold)</strong></label>
                <Slider name={'h_text2_align'} value={props.layout.h_text2_align || '2'} changeLayout={changeLayout.bind(this)} />
                <input type="text" name="header_text2" className="form-control" placeholder='Enter text' value={props.layout.header_text2}
                    onChange={changeLayout.bind(this)} />
            </div>
            <div className="col-md-4">
                <label>Header Image</label>
                <Slider name={'h_image_align'} value={props.layout.h_image_align || '1'} changeLayout={changeLayout.bind(this)} />
                <form method="post" action="#" id="#" className="mt-4">
                    <div className="form-group filedrag color">
                        <input type="file" className="form-control" onChange={e => props.onFileUpload(e, 'header_image', 'doc')} onClick={(e) => { e.target.value = null }}></input>
                    </div>
                </form>
                {props.uploadedHeaderImg.length === 0 && !props.layout.header_image
                    ?
                    <small className="text-muted">No file uploaded as yet.</small>
                    :
                    <>{props.uploadedHeaderImg.map((file, idx) =>
                        <NewUploadedImage idx={idx} fileName={file.name} deleteFromFiles={() => props.deleteFromFiles(idx, 'header_image', 'doc')} />
                    )}
                    </>}
                {props.layout.header_image && <SavedImage imgKey={props.layout.header_image} deleteFromSavedFiles={props.deleteFromSavedFiles.bind(this, 'header_image', 'doc')} />}
            </div>
        </div>
        <div className="row">
            <div className="form-group mb-3 col-md-4">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label className="form-check-label" htmlFor="name" style={{ textTransform: 'uppercase' }}>
                        <input type="checkbox" className="form-check-input" name="only_footer" id="only_footer" checked={props.layout.only_footer ? true : false}
                            onClick={onCheckboxLayout.bind(this)} /> Only Footer Image <i className="input-helper"></i></label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="form-group mb-3 col-md-4">
                <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Footer Text<em>(Italics)</em></label>
                <Slider name={'f_text_align'} value={props.layout.f_text_align || '3'} changeLayout={changeLayout.bind(this)} />
                <input type="text" name="footer_text" className="form-control" placeholder='Enter text' value={props.layout.footer_text}
                    onChange={changeLayout.bind(this)} />
            </div>
            <div className="form-group mb-3 col-md-4">
                <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Footer Text 2<strong>(Bold)</strong></label>
                <Slider name={'f_text2_align'} value={props.layout.f_text2_align || '2'} changeLayout={changeLayout.bind(this)} />
                <input type="text" name="footer_text2" className="form-control" placeholder='Enter text' value={props.layout.footer_text2}
                    onChange={changeLayout.bind(this)} />
            </div>
            <div className="col-md-4">
                <label>Footer Image</label>
                <Slider name={'f_image_align'} value={props.layout.f_image_align || '1'} changeLayout={changeLayout.bind(this)} />
                <form method="post" action="#" id="#" className="mt-4">
                    <div className="form-group filedrag color">
                        <input type="file" className="form-control" onChange={e => props.onFileUpload(e, 'footer_image', 'doc')} onClick={(e) => { e.target.value = null }}></input>
                    </div>
                </form>
                {props.uploadedFooterImg.length === 0 && !props.layout.footer_image
                    ?
                    <small className="text-muted">No file uploaded as yet.</small>
                    :
                    <>{props.uploadedFooterImg.map((file, idx) =>
                        <NewUploadedImage idx={idx} fileName={file.name} deleteFromFiles={() => props.deleteFromFiles(idx, 'footer_image', 'doc')} />)}
                    </>}
                {props.layout.footer_image && <SavedImage imgKey={props.layout.footer_image} deleteFromSavedFiles={props.deleteFromSavedFiles.bind(this, 'footer_image', 'doc')} />}
            </div>
        </div>
        <div className="row mt-3">
            <div className="form-group mb-3 col-md-4">
                <label>Signature Size</label>
                <SliderSignSize name={'sign_size'} value={props.layout.sign_size || '1'} changeLayout={changeLayout.bind(this)} />
            </div>
            <div className="form-group mb-3 col-md-4">
                <label>Signature Alignment (w.r.t Doc Name)</label>
                <SliderSignAlign name={'sign_align'} value={props.layout.sign_align || '1'} changeLayout={changeLayout.bind(this)} />
            </div>
            <div className="form-group mb-3 col-md-4">
                <label>Signature Image</label>
                <form method="post" action="#" id="#" className="mt-4">
                    <div className="form-group filedrag color">
                        <input type="file" className="form-control" onChange={e => props.onFileUpload(e, 'signature_image', 'doc')} onClick={(e) => { e.target.value = null }}></input>
                    </div>
                </form>
                {props.uploadedSignImg.length === 0 && !props.layout.signature_image
                    ?
                    <small className="text-muted">No file uploaded as yet.</small>
                    :
                    <>{props.uploadedSignImg.map((file, idx) =>
                        <NewUploadedImage idx={idx} fileName={file.name} deleteFromFiles={() => props.deleteFromFiles(idx, 'signature_image', 'doc')} />)}
                    </>}
                {props.layout.signature_image && <SavedImage imgKey={props.layout.signature_image} deleteFromSavedFiles={props.deleteFromSavedFiles.bind(this, 'signature_image', 'doc')} />}
            </div>
            <div className="form-group mb-3 col-md-4">
                <label>Signature Text</label>
                <input type="text" name="sign_text" className="form-control" value={props.layout.sign_text || ''}
                    onChange={changeLayout.bind(this)} placeholder="Enter text" />
            </div>
            <div className="form-group mb-3 col-md-4">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label className="form-check-label" htmlFor="name">
                        <input type="checkbox" className="form-check-input" name="sign_text_disabled" checked={!!props.layout.sign_text_disabled}
                            onClick={changeLayout.bind(this)} /> Text Disable? <i className="input-helper"></i></label>
                </div>
            </div>
        </div>
        {Object.keys(TooltipEnum).map(key => <ReactTooltip id={key} effect='solid'>
            <span>{TooltipEnum[key]}</span>
        </ReactTooltip>)}
    </div>
}
// patient side prescription layout
export function PatientLayoutSection(props) {
    function changeLayout(e) {
        let { patient_layout } = props;
        let { name, value } = e.target;
        if (name === 'sign_text_disabled') {
            value = e.target.checked
        }
        patient_layout = {
            ...patient_layout,
            [name]: value
        }
        props.emitDetailChange({ target: { name: 'patient_layout', value: patient_layout } });
    }
    function onCheckboxLayout(e) {
        let { patient_layout } = props;
        let { name, checked } = e.target;
        patient_layout = {
            ...patient_layout,
            [name]: checked
        }
        props.emitDetailChange({ target: { name: 'patient_layout', value: patient_layout } });
    }
    let TooltipEnum = {
        patient_prescription_layout: 'Position for Patient Prescription Header, footer, notes, Print Prescription can be customized and added'
    }

    return <div className="form-group pt-3 mb-3 border-top">
        <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Patient Page Prescription Layout <i className="mdi mdi-information-outline" data-tip data-for='patient_prescription_layout' /></label>
        <div className="row">
            <div className="form-group mb-3 col-md-4">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label className="form-check-label" htmlFor="name" style={{ textTransform: 'uppercase' }}>
                        <input type="checkbox" className="form-check-input" name="only_header" id="only_header" checked={props.patient_layout.only_header ? true : false}
                            onClick={onCheckboxLayout.bind(this)} /> Only Header Image <i className="input-helper"></i></label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="form-group mb-3 col-md-4">
                <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Header Text 1<em>(Italics)</em></label>
                <Slider name={'h_text_align'} value={props.patient_layout.h_text_align || '3'} changeLayout={changeLayout.bind(this)} />
                <input type="text" name="header_text" className="form-control" placeholder='Enter text' value={props.patient_layout.header_text}
                    onChange={changeLayout.bind(this)} />
            </div>
            <div className="form-group mb-3 col-md-4">
                <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Header Text 2<strong>(Bold)</strong></label>
                <Slider name={'h_text2_align'} value={props.patient_layout.h_text2_align || '2'} changeLayout={changeLayout.bind(this)} />
                <input type="text" name="header_text2" className="form-control" placeholder='Enter text' value={props.patient_layout.header_text2}
                    onChange={changeLayout.bind(this)} />
            </div>
            <div className="col-md-4">
                <label>Header Image</label>
                <Slider name={'h_image_align'} value={props.patient_layout.h_image_align || '1'} changeLayout={changeLayout.bind(this)} />
                <form method="post" action="#" id="#" className="mt-4">
                    <div className="form-group filedrag color">
                        <input type="file" className="form-control" onChange={e => props.onFileUpload(e, 'header_image')} onClick={(e) => { e.target.value = null }}></input>
                    </div>
                </form>
                {props.uploadedHeaderImg.length === 0 && !props.patient_layout.header_image
                    ?
                    <small className="text-muted">No file uploaded as yet.</small>
                    :
                    <>{props.uploadedHeaderImg.map((file, idx) =>
                        <NewUploadedImage idx={idx} fileName={file.name} deleteFromFiles={() => props.deleteFromFiles(idx, 'header_image')} />
                    )}
                    </>}
                {props.patient_layout.header_image && <SavedImage imgKey={props.patient_layout.header_image} deleteFromSavedFiles={props.deleteFromSavedFiles.bind(this, 'header_image')} />}
            </div>
        </div>
        <div className="row">
            <div className="form-group mb-3 col-md-4">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label className="form-check-label" htmlFor="name" style={{ textTransform: 'uppercase' }}>
                        <input type="checkbox" className="form-check-input" name="only_footer" id="only_footer" checked={props.patient_layout.only_footer ? true : false}
                            onClick={onCheckboxLayout.bind(this)} /> Only Footer Image <i className="input-helper"></i></label>
                </div>
            </div>
        </div>
        <div className="row">
            <div className="form-group mb-3 col-md-4">
                <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Footer Text<em>(Italics)</em></label>
                <Slider name={'f_text_align'} value={props.patient_layout.f_text_align || '3'} changeLayout={changeLayout.bind(this)} />
                <input type="text" name="footer_text" className="form-control" placeholder='Enter text' value={props.patient_layout.footer_text}
                    onChange={changeLayout.bind(this)} />
            </div>
            <div className="form-group mb-3 col-md-4">
                <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Footer Text 2<strong>(Bold)</strong></label>
                <Slider name={'f_text2_align'} value={props.patient_layout.f_text2_align || '2'} changeLayout={changeLayout.bind(this)} />
                <input type="text" name="footer_text2" className="form-control" placeholder='Enter text' value={props.patient_layout.footer_text2}
                    onChange={changeLayout.bind(this)} />
            </div>
            <div className="col-md-4">
                <label>Footer Image</label>
                <Slider name={'f_image_align'} value={props.patient_layout.f_image_align || '1'} changeLayout={changeLayout.bind(this)} />
                <form method="post" action="#" id="#" className="mt-4">
                    <div className="form-group filedrag color">
                        <input type="file" className="form-control" onChange={e => props.onFileUpload(e, 'footer_image')} onClick={(e) => { e.target.value = null }}></input>
                    </div>
                </form>
                {props.uploadedFooterImg.length === 0 && !props.patient_layout.footer_image
                    ?
                    <small className="text-muted">No file uploaded as yet.</small>
                    :
                    <>{props.uploadedFooterImg.map((file, idx) =>
                        <NewUploadedImage idx={idx} fileName={file.name} deleteFromFiles={() => props.deleteFromFiles(idx, 'footer_image')} />)}
                    </>}
                {props.patient_layout.footer_image && <SavedImage imgKey={props.patient_layout.footer_image} deleteFromSavedFiles={props.deleteFromSavedFiles.bind(this, 'footer_image')} />}
            </div>
        </div>
        <div className="row mt-3">
            <div className="form-group mb-3 col-md-4">
                <label>Signature Size</label>
                <SliderSignSize name={'sign_size'} value={props.patient_layout.sign_size || '1'} changeLayout={changeLayout.bind(this)} />
            </div>
            <div className="form-group mb-3 col-md-4">
                <label>Signature Alignment (w.r.t Doc Name)</label>
                <SliderSignAlign name={'sign_align'} value={props.patient_layout.sign_align || '1'} changeLayout={changeLayout.bind(this)} />
            </div>
            <div className="form-group mb-3 col-md-4">
                <label>Signature Image</label>
                <form method="post" action="#" id="#" className="mt-4">
                    <div className="form-group filedrag color">
                        <input type="file" className="form-control" onChange={e => props.onFileUpload(e, 'signature_image')} onClick={(e) => { e.target.value = null }}></input>
                    </div>
                </form>
                {props.uploadedSignImg.length === 0 && !props.patient_layout.signature_image
                    ?
                    <small className="text-muted">No file uploaded as yet.</small>
                    :
                    <>{props.uploadedSignImg.map((file, idx) =>
                        <NewUploadedImage idx={idx} fileName={file.name} deleteFromFiles={() => props.deleteFromFiles(idx, 'signature_image')} />)}
                    </>}
                {props.patient_layout.signature_image && <SavedImage imgKey={props.patient_layout.signature_image} deleteFromSavedFiles={props.deleteFromSavedFiles.bind(this, 'signature_image')} />}
            </div>
            <div className="form-group mb-3 col-md-4">
                <label>Signature Text</label>
                <input type="text" name="sign_text" className="form-control" value={props.patient_layout.sign_text || ''}
                    onChange={changeLayout.bind(this)} placeholder="Enter text" />
            </div>
            <div className="form-group mb-3 col-md-4">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label className="form-check-label" htmlFor="name">
                        <input type="checkbox" className="form-check-input" name="sign_text_disabled" checked={!!props.patient_layout.sign_text_disabled}
                            onClick={changeLayout.bind(this)} /> Text Disable? <i className="input-helper"></i></label>
                </div>
            </div>
        </div>
        {/* <div className="row mt-3">
            <div className="col-12">
                <div class="faq-section">
                    <div class="accordion accordion-solid-content" id="accordion-5" role="tablist">

                        <div class="card">
                            <div class="card-header" role="tab" id="heading-14">
                                <h6 class="m-0">
                                    <a class="collapsed" data-toggle="collapse" href="#collapse-14" aria-expanded="false" aria-controls="collapse-14"> Question No. 2 </a>
                                </h6>
                            </div>
                            <div id="collapse-14" class="collapse" role="tabpanel" aria-labelledby="heading-14" data-parent="#accordion-5" style="">
                                <div class="card-body">
                                    <div className="row mt-3">
                                        <div className="form-group mb-3 col-md-4">
                                            <label style={{ textTransform: 'capitalize' }}>Footer Text<em>(Italics)</em></label>
                                            <input type="text" name="footer_text" className="form-control" placeholder='Enter text' value={props.patient_layout.footer_text}
                                                onChange={changeLayout.bind(this)} />
                                        </div>
                                        <div className="form-group mb-3 col-md-4">
                                            <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                                                <label className="form-check-label" htmlFor="name" style={{ textTransform: 'uppercase' }}>
                                                    <input type="checkbox" className="form-check-input" name="only_footer" id="only_footer" checked={props.layout.only_footer ? true : false}
                                                        onClick={onCheckboxLayout.bind(this)} /> Only Footer Image <i className="input-helper"></i></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div> */}
        {Object.keys(TooltipEnum).map(key => <ReactTooltip id={key} effect='solid'>
            <span>{TooltipEnum[key]}</span>
        </ReactTooltip>)}
    </div>
}

