import React from 'react';
import ReactTooltip from 'react-tooltip';

import FormElements from '../EditComponents/FormElements';

class Column extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            element: this.props.col && this.props.col.type ? this.props.col['type'] : ''
        }
    }

    getElemHtml() {
        let { type, uiData } = this.props.col;
        if (!type) return;
        let className = type.replace(type[0], type[0].toUpperCase());
        const ElemHtml = FormElements[className];
        let arr = [];
        arr.push(<><ElemHtml element={this.props.col} options={this.props.uiDataDict && this.props.uiDataDict[uiData] ? this.props.uiDataDict[uiData].options : []}></ElemHtml></>);
        return arr;
    }

    onColAddition = () => { this.props.onColAddition(this.props.colIndex); }
    onColDeletion = () => { this.props.onColDeletion(this.props.colIndex); }

    onEditColumnClick = () => {
        this.props.onEditColumnClick({ column: this.props.col, columnIndex: this.props.colIndex });
    }

    render() {
        const element = this.props.col['type'];
        const elementClass = this.getElemHtml();
        return (<>
            <div className="row col-form-item pointer-cursor pl-3" onClick={this.onEditColumnClick.bind(this)}>
                <div className="col-md-7">
                    {this.props.col['header'] && <h6>Header: {this.props.col['header']}</h6>}
                    {(this.props.col['label_x'] || this.props.col['label_y']) && 
                    <h6>Row Label<i className="mdi mdi-arrow-right"></i>: {this.props.col['label_x']}
                    , Column Label<i className="mdi mdi-arrow-down"></i>: {this.props.col['label_y']}</h6>}
                    {!element && <h6>Col {this.props.colIndex + 1}</h6>}
                    {element && <div>{elementClass}</div>}
                </div>
                <div className="col-md-2 left-border center-align">{this.props.col['name'] ? this.props.col['name'] : '-'}</div>
                <div className="col-md-3 left-border center-align">
                    {!element && <a className="actions" data-tip data-for='addTool'><i className="mdi mdi-settings"></i></a>}
                    {element && <a className="actions" onClick={this.onEditColumnClick.bind(this)} data-tip data-for='editTool'><i className="mdi mdi-pencil-box-outline"></i></a>}
                    {this.props.showType !== 'xyTable' && <>
                        <a className="actions" onClick={this.onColAddition.bind(this)} data-tip data-for='addColumn'><i className="mdi mdi-plus-circle"></i></a>
                        {this.props.showDelete && <a className="actions" onClick={this.onColDeletion.bind(this)} data-tip data-for='deleteColumn'><i className="mdi mdi-minus-circle"></i></a>}
                    </>}
                </div>
                <ReactTooltip id='addTool' effect='solid'>
                    <span>Add Tool</span>
                </ReactTooltip>
                <ReactTooltip id='editTool' effect='solid'>
                    <span>Edit Tool</span>
                </ReactTooltip>
                <ReactTooltip id='addColumn' effect='solid'>
                    <span>Add Column</span>
                </ReactTooltip>
                <ReactTooltip id='deleteColumn' effect='solid'>
                    <span>Delete Column</span>
                </ReactTooltip>
            </div>
        </>
        );
    }
}

export default Column;