import React, { Component } from 'react';
import View from '../../Shared/utils/CommonTableView';
import CommonModalView from '../../Common/Modal/CommonModalView';
import HeaderWrapper from '../../Common/Header/HeaderWrapper';
import SweetAlertComponent from '../../Common/Alert/SweetAlertComponent';
import Utils from '../../Shared/utils/utils';
import doctorService from "../../Services/doctor.service";
import accountService from '../../Services/account.settings';
import onlineConsultationService from '../../Services/onlineConsultation.service';
import Papa from 'papaparse';
import FileUploadModal from './FileUploadModal';

class InventoryManagementContainer extends Component {
	constructor(props) {
		super(props);
		let accountInfo = sessionStorage.getItem('accountInfo') ? JSON.parse(sessionStorage.getItem('accountInfo')) : '';
		let queryParams = (this.props.location && this.props.location.search !== '') ? JSON.parse('{"' + decodeURI(this.props.location.search.substr(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : '';
		this.state = {
			mobileNav: false,
			alert: { title: '', type: '', message: '', show: false },
			loading: true,
			searchText: '',
			providerName: this.props.match && this.props.match.params && this.props.match.params.providerName ? this.props.match.params.providerName : this.props.providerName ? this.props.providerName : '',
			queryParams: queryParams ? queryParams : this.props.providerName ? { noheader: 'true' } : '',
			invoice_config: null,
			isLoading: false,
			accountInfo: accountInfo,
			filterList: null,
			alert: { show: false, type: '', title: '', text: '' },
			// vaccHeaderConfig: ['type', 'name', 'description', "deposit", 'price', 'image_link', 'is_rental',
			// 	'timing', 'duration', 'city', 'branch', 'department',
			// 	'branch_id', 'site_id', 'site_type_id', 'data_level', 'is_active', 'slots required?', 'doctor'],
			headerConfig: ['type', 'name', 'description', "deposit", 'price', 'image_link', 'is_rental', 'timing', 'duration', 'city', 'branch', 'department', 'slots required?', 'doctor'],
			headerNotPres: [],
			invoiceBody: null,
			userList: [],
			invoice_type: '',
			isEditList: false,
			isEdit: false,
			accountDetail: null,
			isDirty: false,
			fileUploadModal: false,
			peptoken: queryParams && queryParams.peptoken ? queryParams.peptoken : ''
		}
	}

	componentDidMount() {
		// if (this.state.queryParams.token) {
		// 	sessionStorage.setItem("emrToken", this.state.queryParams.token)
		// }
		if (this.state.queryParams.token) {
			sessionStorage.setItem("peptoken", this.state.queryParams.peptoken);
			this.setState({ peptoken: this.state.queryParams.peptoken })
		}
		if (this.state.queryParams.noheader) {
			sessionStorage.setItem("noheader", this.state.queryParams.noheader)
		}

		if (this.state.providerName) {
			if (!this.state.queryParams.token) {
				sessionStorage.setItem('s3AccountId', this.state.providerName);
			}
			this.getAccountInfo();
			this.fetchFilterList();
		}
		this.getUserList();
	}

	async fetchFilterList() {
		try {
			let response = await accountService.getFilterData(this.state.providerName, this.state.peptoken);
			
			let { filterList } = this.state;
			if (response && response.type === "success") {
				filterList = response.data && response.data.category_list && response.data.category_list.length > 0 ? response.data.category_list : null
				this.setState({ filterList }, () => {
					let data = response.data && response.data.category_list && response.data.category_list.length > 0 ? response.data.category_list : null
					if (data) {
						this.setState({ invoice_type: data[0], loading: true }, () => this.fetchCatalogueData())
					} else {
						this.setState({ loading: true }, () => this.fetchCatalogueData())

					}
				});
			} else if (response && response.type === "error") {
				filterList = null;
				this.setState({ filterList }, () => this.fetchCatalogueData())
			} else {
				filterList = null;
				this.setState({ filterList, loading: false }, () => this.fetchCatalogueData())
			}
		} catch (error) {
			console.log(error);
			let { filterList } = this.state;
			filterList = null;
			this.setState({ filterList, loading: false })
		}
	}

	async fetchCatalogueData() {
		try {
			let res = await accountService.getCatalogueData(this.state.providerName, this.state.invoice_type, this.state.peptoken);
			let { invoiceBody, headerConfig } = this.state;
			if (res && res.data && res.data.catalogue) {
				
				invoiceBody = res.data.catalogue.body;
				this.setState({ invoiceBody, loading: false });
			} else {
				this.setState({ invoiceBody, loading: false });
			}
		} catch (err) {
			console.log(err)
			this.setState({ loading: false });
		}
	}

	async getAccountInfo() {
		try {
			let res = await onlineConsultationService.getAccountInfo(this.state.providerName);
			if (res && res.data) {
				this.setState({ accountInfo: res.data })
				sessionStorage.setItem('accountInfo', JSON.stringify(res.data));
			} else {
				this.setState({ accountInfo: '' })
				sessionStorage.setItem('accountInfo', ' ');
			}
		} catch (err) {
			console.log(err)
		}
	}

	async getUserList() {
		try {
			// this.state.speciality
			let { userList } = this.state;
			const response = await doctorService.getOnlineDoctor('', this.state.providerName, '', this.state.queryParams.token);
			
			if (!response) {
				userList = [];
				this.setState({ userList })
				return;
			}
			switch (response.type) {
				case 'error':
					this.setState({ userList: [] })
					break;
				case 'success':
					this.setState({ userList: response.data.rows })
					break;
				default:
					break;
			}
		} catch (error) {
			console.log(error)
			this.setState({ userList: [] })
		}
	}

	onFileUpload(data) {
		let file = data.target.files[0];
		if (file != undefined) {
			Papa.parse(file, {
				// header: true,
				complete: this.setParsedFile
			});
		}
	}

	getCatalogueListData = (invoiceBody, headerConfig) => {
		// for(let i in headerConfig){
		// 	let data = {};

		// }

		let finalData = []

		for (let i in invoiceBody) {
			let data = {}
			let indEle = invoiceBody[i];
			for (let j in indEle) {
				data[headerConfig[j]] = indEle[j];
			}
			finalData.push(data)
		}
		return finalData
	}

	async submitInvoiceData() {
		try {
			let data = {}
			if (this.state.isEditList) {
				data = {
					'catalogue_name': this.state.invoice_type,
					"catalogue": this.getCatalogueListData(this.state.invoiceBody, this.state.headerConfig)
				}
			} else {
				data = {
					'catalogue': {
						header: this.state.headerConfig,
						body: this.state.invoiceBody
					}
				}
			}
			this.setState({ isLoading: true })
			let response = await accountService.createCatalogueData(this.state.providerName, data, this.state.isEditList, this.state.peptoken);
			let { alert } = this.state;
			if (response && response.type === 'success') {
				alert = { show: true, type: 'success', title: 'Invoice Settings Updated succesfully' };
				this.setState({ alert, isLoading: false, isDirty: false, isEdit: false, isEditList: false });
			} else {
				alert = { show: true, type: 'error', title: 'Error', text: 'Error while uploading Invoice Settings! Please try again.' };
				this.setState({ isLoading: false, alert });
			}
		} catch (err) {
			let { alert } = this.state;
			alert = { show: true, type: 'error', title: 'Error', text: 'Error while uploading Invoice Settings! Please try again.' };
			this.setState({ isLoading: false, alert });
		}
	}

	setParsedFile = (results) => {
		
		let typeData = ["consultation", "pharmacy", "investigations", "others"]
		let { sheet, excel } = this.state;
		let finalSheetData = this.formatFinalSheetData(typeData, results.data);
		sheet = finalSheetData;
		excel = Papa.unparse(finalSheetData);
		this.setState({ sheet, excel, isDirty: true });

		this.checkForUnavailableAccounts(sheet);
	}

	formatFinalSheetData(typeData, data) {
		// return data;
		let finalData = []
		for (let i = 1; i < data.length; i++) {
			// if (typeData.indexOf((data[i][0]).toLowerCase()) !== -1) {
			// data.splice(i, 1);
			finalData.push(data[i])
			// }
		}
		return finalData;
	}

	checkForUnavailableAccounts(sheet) {
		if (!Array.isArray(sheet)) return;
		let header = sheet[0];
		
		this.checkForNoHeaderData(header, sheet);
	}

	onEditButtonEvent = (event) => {
		let { isEdit, isEditList } = this.state;
		isEdit = !isEdit;
		isEditList = false;
		this.setState({ isEdit, isEditList });
	}

	onListEdit = (event) => {
		let { isEditList, isEdit } = this.state;
		isEditList = true;
		isEdit = !isEdit
		this.setState({ isEditList, isEdit });
	}

	checkForNoHeaderData(header, excelBody) {
		if (!header || header.length === 0) {
			return;
		}
		this.checkIfExcelBodyIsEmpty(excelBody);
	}

	checkIfExcelBodyIsEmpty(excelBody) {
		let { invoiceBody } = this.state;
		let finalBodyData = [];
		for (let i in excelBody) {
			if (!excelBody[i].length) {
				return;
			}
			let isPresent = false;
			for (let j in excelBody[i]) {
				if (excelBody[i][j]) {
					isPresent = true;
				}
			}
			if (isPresent) {
				finalBodyData.push(excelBody[i]);
			}
		}
		invoiceBody = finalBodyData;
		this.setState({ invoiceBody });
	}

	setSelectValueOnValue = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value
		}, () => this.fetchCatalogueData())
	}

	onTextChangeEvent = (i, j, event) => {
		
		let { invoiceBody } = this.state;
		invoiceBody[i][j] = event.target.value;
		if (j === 17) {
			invoiceBody[i][18] = invoiceBody[i][18] ? invoiceBody[i][18] : ''
		}

		this.setState({ invoiceBody, isDirty: true });
	}

	onUserChangeEvent = (i, event) => {
		
		let { invoiceBody } = this.state;
		invoiceBody[i][18] = event.target.value
		// if (invoiceBody[i][12])
		// else invoiceBody[i].push(event.target.value);
		this.setState({ invoiceBody, isDirty: true });
	}

	setAlert = (event) => {
		let { alert } = this.state;
		alert.show = false;
		this.setState({ alert });
	}

	openFileModal = (event) => {
		let { fileUploadModal } = this.state;
		fileUploadModal = !fileUploadModal;
		this.setState({ fileUploadModal })
	}

	getEmitedCancelEvent = (event) => {
		let { fileUploadModal } = this.state;
		fileUploadModal = false;
		this.setState({ fileUploadModal })
	}

	getEmitedFileFinalUpdateData(finalData, sheetData, excelData) {
		// getEmitedFileFinalUpdateData(finalBodyData, this.state.sheet, this.state.excel)
		let { invoiceBody, sheet, excel, fileUploadModal } = this.state;
		invoiceBody = finalData;
		sheet = sheetData;
		excel = excelData;
		fileUploadModal = false;
		this.setState({ invoiceBody, sheet, excel, isDirty: true, fileUploadModal, isEdit: true })
	}

	getEmitedDetailChange = (event) => {
		let { name, value } = event.target;
		this.setState({
			[name]: value
		})
	}

	async searchInventoryData() {
		try {
			let response = await accountService.searchCatalogue(this.state.providerName, this.state.searchText, this.state.invoice_type, this.state.peptoken);
			
		} catch (error) {
			console.log(error)
		}
	}

	onDeleteIndItem = (i) => {
		let { invoiceBody, isDirty } = this.state;
		invoiceBody.splice(i, 1);
		isDirty = true;
		this.setState({ invoiceBody, isDirty })
	}

	getHtmlText(data) {
		let text = data ? data : '-';
		return { __html: text };
	}
	// fileUploadModal

	render() {
		const { mobileNav, alert, loading, headerConfig,
			invoiceBody, isEdit, isDirty, isLoading, userList, filterList,
			invoice_type, vaccHeaderConfig } = this.state;
		return (
			<>
				<View.DocumentTitle title="Service Management" />
				{/* Header For screen */}
				{!this.state.queryParams.noheader &&
					<HeaderWrapper isOpenRoute={true}  {...this.props} screen showDoctorList={false} toggleMobileNav={() => this.setState({ mobileNav: !this.state.mobileNav })} mobileNav={mobileNav}></HeaderWrapper>
				}

				<div className={`${this.props.viewFrom ? '' : 'container-fluid page-body-wrapper'}`}>
					{/* Header For mobile view */}
					{!this.state.queryParams.noheader &&
						<HeaderWrapper isOpenRoute={true} {...this.props}
							mobile mobileNav={mobileNav}
							showDoctorList={false}>
						</HeaderWrapper>
					}
					<div className={`${this.props.viewFrom ? '' : 'main-panel'}`}>
						<div className={`${this.props.viewFrom ? '' : 'content-wrapper'}`}>
							<div className="card-body p-0">
								<div className="modal-content bg-white">
									<div className="modal-header row">
										<div className="col-sm-12 col-lg-12">
											<h5 className="modal-title" id="exampleModalLabel">
												{this.props.title ? this.props.title : 'Service Management'}
												<button className="btn btn-outline-success btn-xs mr-2 btn-rounded ml-2"
													onClick={this.openFileModal.bind(this)}>Upload File</button>
											</h5>
										</div>
										<div className="col-sm-12 col-lg-4">
											{filterList &&
												<div className="form-group  mb-3">
													<label>Type*</label>
													<select className="form-control " name="invoice_type" value={invoice_type} onChange={this.setSelectValueOnValue.bind(this)}>
														{filterList.map((indData, i) =>
															<option value={indData} key={i}>{indData}</option>
														)}
													</select>
												</div>
											}
										</div>
									</div>
									<div className="modal-body ">
										<section className="appointmentslot">
											<div className="row">
												<div className={`col-lg-12 col-sm-12`}>

														<div className="row">
															<div className={`col-lg-12 col-sm-12`} style={{ display: 'flex' }}>
																<p className="m-1" style={{ flex: 1 }}>Service List
															<button className="btn btn-outline-success btn-xs
															 mr-2 btn-rounded ml-2" style={{
																			display: !loading && !!invoiceBody ? '' : 'none'
																		}}
																		onClick={this.onListEdit.bind(this)}>Edit List</button></p>
																<p className="m-1" onClick={this.onEditButtonEvent.bind(this)}
																	style={{ display: !loading && !!invoiceBody ? '' : 'none' }}>
																	<i className={`mdi ${isEdit ? 'mdi-eye' : 'mdi-table-edit'}`}
																		data-tip data-for="invoice_edit" />
																</p>
															</div>
															<div className={`col-lg-12 col-sm-12`}>
																<div className="clinytic-line-loader"
																	style={{
																		display: loading ? '' : 'none',
																		width: "100%"
																	}}></div>
																<div className="table-responsive">
																	<table className={`${isEdit ? '' :
																		'table-striped'} table-hover table-sm`}
																		style={{ width: '100%', display: !loading && !!invoiceBody ? '' : 'none' }}>
																		<thead>
																			<tr style={{ textTransform: "capitalize" }}>
																				{headerConfig.map((indHeader, i) =>
																					<th key={i} style={{
																						display: (i === 0) &&
																							!isEdit ? 'none' : ''
																					}}>{indHeader}</th>
																				)}
																				<th key={'action'} style={{
																					display: isEdit ? '' : 'none'
																				}}>Action</th>
																			</tr>
																		</thead>
																		<tbody>
																			{loading ? (
																				<></>
																			) : (
																				<>
																					{!!invoiceBody ? (
																						<>
																							{invoiceBody.map((indInvoiceObj, i) =>
																								<tr key={i}>
																									{indInvoiceObj.map((indItem, j) =>
																										j === 18 ? <>
																											{indInvoiceObj[17] === 'Y' ? <>
																												{isEdit ? (
																													<td key={i} style={{ width: '100px' }}>
																														<select value={indInvoiceObj[18] || 'NIL'}
																															onChange={this.onUserChangeEvent.bind(this, i)}
																															className="form-control"
																															id={`exampleInputUsername ${i}`}>
																															<option value={'NIL'} disabled>None</option>
																															{userList && userList.map(user =>
																																<option value={user.doctor_id}>
																																	{user.doctor_name}
																																</option>)}
																														</select>
																													</td>
																												) : (
																													<td style={{ width: '100px' }}
																														key={i}>{indInvoiceObj[18]}</td>
																												)}
																											</> : <><span>Not selected</span></>}
																										</> :
																											<>
																												{isEdit ? (
																													<td key={j}>
																														<input disabled={(this.state.isEditList && j === 0) ?
																															true : false} type="text"
																															value={indItem}
																															onChange={this.onTextChangeEvent.bind(this, i, j)}
																															className="form-control"
																															id={`exampleInputUsername ${i, j}`} />
																													</td>
																												) : (
																													<>
																														{(j === 5) ? (
																															<td key={j} >
																																<p
																																	style={{
																																		wordBreak: "break-all",
																																		whiteSpace: "normal",
																																		width: 200
																																	}}>
																																	<a href={indItem}
																																		target="_blank">
																																		{indItem}
																																	</a>
																																</p>
																															</td>
																														) : (
																															<td key={j}
																																style={{ display: (j === 0) ? 'none' : '' }}
																																dangerouslySetInnerHTML={this.getHtmlText(indItem)}>
																															</td>
																														)}
																													</>
																												)}
																											</>
																									)}
																									<td style={{ display: isEdit ? '' : 'none' }}>
																										<button className="btn btn-outline-success btn-xs 
																									mr-2 btn-rounded ml-2"
																											onClick={this.onDeleteIndItem.bind(this, i)}>
																											Delete
																										</button>
																									</td>
																								</tr>
																							)}
																						</>
																					) : (
																						<>

																							{/* <p><img className="spinner rotate" src="images/nodata.svg" alt="image" /></p> */}
																						</>
																					)}
																				</>
																			)}

																		</tbody>
																	</table>
																	<div style={{ width: "100%", textAlign: 'center', display: (invoiceBody && invoiceBody.length === 0) || !invoiceBody && !loading ? '' : 'none' }}>
																		<p><img src="/images/nodata.svg" alt="image" style={{ height: 150 }} /> </p>
																		<p>No Service List Data</p>
																	</div>
																</div>
															</div>

														</div>
													 

												</div>
											</div>

										</section>
									</div>
									{isLoading &&
										<div className="clinytic-line-loader"></div>
									}
									<div className="modal-footer" style={{ display: !loading && !!invoiceBody ? '' : 'none' }}>
										<button type="submit" className="btn btn-success mr-2 btn-rounded" disabled={!isDirty || isLoading} onClick={this.submitInvoiceData.bind(this)}>Submit</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					{this.state.fileUploadModal &&
						<FileUploadModal invoice_type={invoice_type} open={this.state.fileUploadModal}
							emitFileFinalUpdateData={this.getEmitedFileFinalUpdateData.bind(this)}
							// getEmitedFileFinalUpdateData(finalBodyData, this.state.sheet, this.state.excel)
							emitCancelEvent={this.getEmitedCancelEvent.bind(this)}></FileUploadModal>
					}

					{alert.show && <SweetAlertComponent show={alert.show} type={alert.type} title={alert.title} text={alert.text} sweetAlertClose={this.setAlert.bind(this)} />}

				</div>
			</>
		);
	}
}

export default InventoryManagementContainer;