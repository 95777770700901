import request from '../Api/request';
import userAuth from '../Authentication/authActions';
import Utils from '../Shared/utils/utils';

function getApiStageType() {
    return userAuth.getApiStageType()
}

function getUserApiStageUrl() {
    return userAuth.getPatientApiUrl()
}
function getNewPatientApiStageUrl() {
    return userAuth.getNewPatientApiUrl()
}

function getWaitingTimeUrl() {
    return userAuth.getAppointmentApiUrl()
}

function updatePateintChatDetailData(data, patientId) {
    let apiStage = getApiStageType()
    let api = getUserApiStageUrl();
    return request({
        baseURL: api,
        url: `/onlinepatient/${patientId}`,
        method: 'put',
        data: data
    }, 'appointment');
}

function onlinePateintCheckin(appointmentId, chatRoomId) {
    let api = getNewPatientApiStageUrl();
    if (chatRoomId) {
        return request({
            baseURL: api,
            url: `/onlinepatient/checkin/${appointmentId}?room_id=${chatRoomId}`,
            method: 'get',
        }, 'appointment');
    } else {
        return request({
            baseURL: api,
            url:  `/onlinepatient/checkin/${appointmentId}`,
            method: 'get',
        }, 'appointment');
    }

}

function callStartRecordReceiverPatient(data) {
    let apiStage = getApiStageType()
    let api = getUserApiStageUrl();
    // let api = getUserApiStageUrl();
    return request({
        baseURL: api,
        url: `/callrecord/receiver/patient`,
        method: 'post',
        data: data
    }, 'appointment');
}

function callrecordInitiateDoctor(data) {
    let apiStage;
    // = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1) ? 'dev' : 'prod';
    let role = sessionStorage.getItem('userRole');
    if (role.indexOf('prod') !== -1) {
        apiStage = 'prod';
    } else {
        apiStage = 'dev';
    }
    let api;
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/patient` :`https://emrapi.hlthclub.in/patient`;
    } else {
        api = `https://betaemrapi.hlthclub.in/patient`
    }
    //  getUserApiStageUrl();
    //  getUserApiStageUrl();
    return request({
        baseURL: api,
        url: `/callrecord/initiate/doctor`,
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function callRecordEndDoctorPatient(data, type, id) {
    let api = getNewPatientApiStageUrl();

    if (type === "patient") {
        return request({
            baseURL: api,
            url: `/callrecord/patientend/${id}`,
            method: 'post',
            data: data
        }, 'appointment');
    } else {
        return request({
            baseURL: api,
            url: `/callrecord/doctorend/${id}`,
            method: 'post',
            data: data
        }, 'appointment');
    }

}

function callRecordReceiverPatient(data) {
    let api = getNewPatientApiStageUrl();
    return request({
        baseURL: api,
        url: `/callrecord/initiate/patient`,
        method: 'post',
        data: data
    }, 'appointment');
}

function getOnlinePatientDetail(chatRoomId) {
    let api = getNewPatientApiStageUrl();
    return request({
        baseURL: api,
        url: `/onlinepatient/chatroom/${chatRoomId}`,
        method: 'get',
    }, 'appointment');
}

function chatRoomIdValidation(chatRoomId) {
    let api = getNewPatientApiStageUrl();
    return request({
        baseURL: api,
        url: `/onlinepatient/validate/${chatRoomId}`,
        method: 'get',
    }, 'appointment');
}

function getPatientWaitingTimeData(appointmentId) {
    let apiStage = getApiStageType()
    let api = getWaitingTimeUrl();
    return request({
        baseURL: api,
        url: `/onlineappointment/waiting_time/${appointmentId}`,
        method: 'get',
    }, 'appointment');
}

const chatRoomService = {
    getOnlinePatientDetail,
    updatePateintChatDetailData,
    chatRoomIdValidation,
    callRecordReceiverPatient,
    onlinePateintCheckin,
    callStartRecordReceiverPatient,
    callrecordInitiateDoctor,
    getPatientWaitingTimeData,
    callRecordEndDoctorPatient
}

export default chatRoomService;