export const CountryCodesJson = [{
        "name": "Afghanistan",
        "dialCode": "+93",
        "code": "AF",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Åland Islands",
        "dialCode": "+358",
        "code": "AX",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Albania",
        "dialCode": "+355",
        "code": "AL",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Algeria",
        "dialCode": "+213",
        "code": "DZ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "American Samoa",
        "dialCode": "+1684",
        "code": "AS",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Andorra",
        "dialCode": "+376",
        "code": "AD",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Angola",
        "dialCode": "+244",
        "code": "AO",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Anguilla",
        "dialCode": "+1264",
        "code": "AI",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Antarctica",
        "dialCode": "+672",
        "code": "AQ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Antigua and Barbuda",
        "dialCode": "+1268",
        "code": "AG",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Argentina",
        "dialCode": "+54",
        "code": "AR",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Armenia",
        "dialCode": "+374",
        "code": "AM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Aruba",
        "dialCode": "+297",
        "code": "AW",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Australia",
        "dialCode": "+61",
        "code": "AU",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Austria",
        "dialCode": "+43",
        "code": "AT",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Azerbaijan",
        "dialCode": "+994",
        "code": "AZ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Bahamas",
        "dialCode": "+1242",
        "code": "BS",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Bahrain",
        "dialCode": "+973",
        "code": "BH",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Bangladesh",
        "dialCode": "+880",
        "code": "BD",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Barbados",
        "dialCode": "+1246",
        "code": "BB",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Belarus",
        "dialCode": "+375",
        "code": "BY",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Belgium",
        "dialCode": "+32",
        "code": "BE",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Belize",
        "dialCode": "+501",
        "code": "BZ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Benin",
        "dialCode": "+229",
        "code": "BJ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Bermuda",
        "dialCode": "+1441",
        "code": "BM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Bhutan",
        "dialCode": "+975",
        "code": "BT",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Bolivia, Plurinational State of bolivia",
        "dialCode": "+591",
        "code": "BO",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Bosnia and Herzegovina",
        "dialCode": "+387",
        "code": "BA",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Botswana",
        "dialCode": "+267",
        "code": "BW",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Bouvet Island",
        "dialCode": "+47",
        "code": "BV",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Brazil",
        "dialCode": "+55",
        "code": "BR",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "British Indian Ocean Territory",
        "dialCode": "+246",
        "code": "IO",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Brunei Darussalam",
        "dialCode": "+673",
        "code": "BN",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Bulgaria",
        "dialCode": "+359",
        "code": "BG",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Burkina Faso",
        "dialCode": "+226",
        "code": "BF",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Burundi",
        "dialCode": "+257",
        "code": "BI",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Cambodia",
        "dialCode": "+855",
        "code": "KH",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Cameroon",
        "dialCode": "+237",
        "code": "CM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Canada",
        "dialCode": "+1",
        "code": "CA",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Cape Verde",
        "dialCode": "+238",
        "code": "CV",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Cayman Islands",
        "dialCode": "+ 345",
        "code": "KY",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Central African Republic",
        "dialCode": "+236",
        "code": "CF",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Chad",
        "dialCode": "+235",
        "code": "TD",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Chile",
        "dialCode": "+56",
        "code": "CL",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "China",
        "dialCode": "+86",
        "code": "CN",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Christmas Island",
        "dialCode": "+61",
        "code": "CX",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Cocos (Keeling) Islands",
        "dialCode": "+61",
        "code": "CC",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Colombia",
        "dialCode": "+57",
        "code": "CO",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Comoros",
        "dialCode": "+269",
        "code": "KM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Congo",
        "dialCode": "+242",
        "code": "CG",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Congo, The Democratic Republic of the Congo",
        "dialCode": "+243",
        "code": "CD",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Cook Islands",
        "dialCode": "+682",
        "code": "CK",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Costa Rica",
        "dialCode": "+506",
        "code": "CR",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Cote d'Ivoire",
        "dialCode": "+225",
        "code": "CI",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Croatia",
        "dialCode": "+385",
        "code": "HR",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Cuba",
        "dialCode": "+53",
        "code": "CU",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Cyprus",
        "dialCode": "+357",
        "code": "CY",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Czech Republic",
        "dialCode": "+420",
        "code": "CZ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Denmark",
        "dialCode": "+45",
        "code": "DK",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Djibouti",
        "dialCode": "+253",
        "code": "DJ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Dominica",
        "dialCode": "+1767",
        "code": "DM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Dominican Republic",
        "dialCode": "+1849",
        "code": "DO",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Ecuador",
        "dialCode": "+593",
        "code": "EC",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Egypt",
        "dialCode": "+20",
        "code": "EG",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "El Salvador",
        "dialCode": "+503",
        "code": "SV",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Equatorial Guinea",
        "dialCode": "+240",
        "code": "GQ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Eritrea",
        "dialCode": "+291",
        "code": "ER",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Estonia",
        "dialCode": "+372",
        "code": "EE",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Ethiopia",
        "dialCode": "+251",
        "code": "ET",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "dialCode": "+500",
        "code": "FK",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Faroe Islands",
        "dialCode": "+298",
        "code": "FO",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Fiji",
        "dialCode": "+679",
        "code": "FJ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Finland",
        "dialCode": "+358",
        "code": "FI",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "France",
        "dialCode": "+33",
        "code": "FR",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "French Guiana",
        "dialCode": "+594",
        "code": "GF",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "French Polynesia",
        "dialCode": "+689",
        "code": "PF",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "French Southern Territories",
        "dialCode": "+262",
        "code": "TF",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Gabon",
        "dialCode": "+241",
        "code": "GA",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Gambia",
        "dialCode": "+220",
        "code": "GM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Georgia",
        "dialCode": "+995",
        "code": "GE",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Germany",
        "dialCode": "+49",
        "code": "DE",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Ghana",
        "dialCode": "+233",
        "code": "GH",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Gibraltar",
        "dialCode": "+350",
        "code": "GI",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Greece",
        "dialCode": "+30",
        "code": "GR",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Greenland",
        "dialCode": "+299",
        "code": "GL",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Grenada",
        "dialCode": "+1473",
        "code": "GD",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Guadeloupe",
        "dialCode": "+590",
        "code": "GP",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Guam",
        "dialCode": "+1671",
        "code": "GU",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Guatemala",
        "dialCode": "+502",
        "code": "GT",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Guernsey",
        "dialCode": "+44",
        "code": "GG",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Guinea",
        "dialCode": "+224",
        "code": "GN",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Guinea-Bissau",
        "dialCode": "+245",
        "code": "GW",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Guyana",
        "dialCode": "+592",
        "code": "GY",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Haiti",
        "dialCode": "+509",
        "code": "HT",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Heard Island and Mcdonald Islands",
        "dialCode": "+0",
        "code": "HM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Holy See (Vatican City State)",
        "dialCode": "+379",
        "code": "VA",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Honduras",
        "dialCode": "+504",
        "code": "HN",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Hong Kong",
        "dialCode": "+852",
        "code": "HK",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Hungary",
        "dialCode": "+36",
        "code": "HU",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Iceland",
        "dialCode": "+354",
        "code": "IS",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "nepal",
        "dialCode": "+977",
        "code": "NEP",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Indonesia",
        "dialCode": "+62",
        "code": "ID",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Iran, Islamic Republic of Persian Gulf",
        "dialCode": "+98",
        "code": "IR",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Iraq",
        "dialCode": "+964",
        "code": "IQ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Ireland",
        "dialCode": "+353",
        "code": "IE",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Isle of Man",
        "dialCode": "+44",
        "code": "IM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Israel",
        "dialCode": "+972",
        "code": "IL",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Italy",
        "dialCode": "+39",
        "code": "IT",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Jamaica",
        "dialCode": "+1876",
        "code": "JM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Japan",
        "dialCode": "+81",
        "code": "JP",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Jersey",
        "dialCode": "+44",
        "code": "JE",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Jordan",
        "dialCode": "+962",
        "code": "JO",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Kazakhstan",
        "dialCode": "+7",
        "code": "KZ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Kenya",
        "dialCode": "+254",
        "code": "KE",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Kiribati",
        "dialCode": "+686",
        "code": "KI",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Korea, Democratic People's Republic of Korea",
        "dialCode": "+850",
        "code": "KP",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Korea, Republic of South Korea",
        "dialCode": "+82",
        "code": "KR",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Kosovo",
        "dialCode": "+383",
        "code": "XK",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Kuwait",
        "dialCode": "+965",
        "code": "KW",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Kyrgyzstan",
        "dialCode": "+996",
        "code": "KG",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Laos",
        "dialCode": "+856",
        "code": "LA",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Latvia",
        "dialCode": "+371",
        "code": "LV",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Lebanon",
        "dialCode": "+961",
        "code": "LB",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Lesotho",
        "dialCode": "+266",
        "code": "LS",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Liberia",
        "dialCode": "+231",
        "code": "LR",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Libyan Arab Jamahiriya",
        "dialCode": "+218",
        "code": "LY",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Liechtenstein",
        "dialCode": "+423",
        "code": "LI",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Lithuania",
        "dialCode": "+370",
        "code": "LT",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Luxembourg",
        "dialCode": "+352",
        "code": "LU",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Macao",
        "dialCode": "+853",
        "code": "MO",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Macedonia",
        "dialCode": "+389",
        "code": "MK",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Madagascar",
        "dialCode": "+261",
        "code": "MG",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Malawi",
        "dialCode": "+265",
        "code": "MW",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Malaysia",
        "dialCode": "+60",
        "code": "MY",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Maldives",
        "dialCode": "+960",
        "code": "MV",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Mali",
        "dialCode": "+223",
        "code": "ML",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Malta",
        "dialCode": "+356",
        "code": "MT",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Marshall Islands",
        "dialCode": "+692",
        "code": "MH",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Martinique",
        "dialCode": "+596",
        "code": "MQ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Mauritania",
        "dialCode": "+222",
        "code": "MR",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Mauritius",
        "dialCode": "+230",
        "code": "MU",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Mayotte",
        "dialCode": "+262",
        "code": "YT",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Mexico",
        "dialCode": "+52",
        "code": "MX"
    },
    {
        "name": "Micronesia, Federated States of Micronesia",
        "dialCode": "+691",
        "code": "FM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Moldova",
        "dialCode": "+373",
        "code": "MD",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Monaco",
        "dialCode": "+377",
        "code": "MC",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Mongolia",
        "dialCode": "+976",
        "code": "MN",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Montenegro",
        "dialCode": "+382",
        "code": "ME",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Montserrat",
        "dialCode": "+1664",
        "code": "MS",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Morocco",
        "dialCode": "+212",
        "code": "MA",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Mozambique",
        "dialCode": "+258",
        "code": "MZ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Myanmar",
        "dialCode": "+95",
        "code": "MM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Namibia",
        "dialCode": "+264",
        "code": "NA",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Nauru",
        "dialCode": "+674",
        "code": "NR",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Nepal",
        "dialCode": "+977",
        "code": "NP",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Netherlands",
        "dialCode": "+31",
        "code": "NL",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Netherlands Antilles",
        "dialCode": "+599",
        "code": "AN",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "New Caledonia",
        "dialCode": "+687",
        "code": "NC",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "New Zealand",
        "dialCode": "+64",
        "code": "NZ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Nicaragua",
        "dialCode": "+505",
        "code": "NI",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Niger",
        "dialCode": "+227",
        "code": "NE",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Nigeria",
        "dialCode": "+234",
        "code": "NG",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Niue",
        "dialCode": "+683",
        "code": "NU",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Norfolk Island",
        "dialCode": "+672",
        "code": "NF",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Northern Mariana Islands",
        "dialCode": "+1670",
        "code": "MP",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Norway",
        "dialCode": "+47",
        "code": "NO",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Oman",
        "dialCode": "+968",
        "code": "OM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Pakistan",
        "dialCode": "+92",
        "code": "PK",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Palau",
        "dialCode": "+680",
        "code": "PW",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Palestinian Territory, Occupied",
        "dialCode": "+970",
        "code": "PS",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Panama",
        "dialCode": "+507",
        "code": "PA",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Papua New Guinea",
        "dialCode": "+675",
        "code": "PG",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Paraguay",
        "dialCode": "+595",
        "code": "PY",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Peru",
        "dialCode": "+51",
        "code": "PE",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Philippines",
        "dialCode": "+63",
        "code": "PH",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Pitcairn",
        "dialCode": "+64",
        "code": "PN",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Poland",
        "dialCode": "+48",
        "code": "PL",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Portugal",
        "dialCode": "+351",
        "code": "PT",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Puerto Rico",
        "dialCode": "+1939",
        "code": "PR",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Qatar",
        "dialCode": "+974",
        "code": "QA",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Romania",
        "dialCode": "+40",
        "code": "RO",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Russia",
        "dialCode": "+7",
        "code": "RU",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Rwanda",
        "dialCode": "+250",
        "code": "RW",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Reunion",
        "dialCode": "+262",
        "code": "RE",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Saint Barthelemy",
        "dialCode": "+590",
        "code": "BL",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Saint Helena, Ascension and Tristan Da Cunha",
        "dialCode": "+290",
        "code": "SH",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Saint Kitts and Nevis",
        "dialCode": "+1869",
        "code": "KN",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Saint Lucia",
        "dialCode": "+1758",
        "code": "LC",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Saint Martin",
        "dialCode": "+590",
        "code": "MF",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Saint Pierre and Miquelon",
        "dialCode": "+508",
        "code": "PM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "dialCode": "+1784",
        "code": "VC",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Samoa",
        "dialCode": "+685",
        "code": "WS",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "San Marino",
        "dialCode": "+378",
        "code": "SM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Sao Tome and Principe",
        "dialCode": "+239",
        "code": "ST"
    },
    {
        "name": "Saudi Arabia",
        "dialCode": "+966",
        "code": "SA",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Senegal",
        "dialCode": "+221",
        "code": "SN",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Serbia",
        "dialCode": "+381",
        "code": "RS",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Seychelles",
        "dialCode": "+248",
        "code": "SC",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Sierra Leone",
        "dialCode": "+232",
        "code": "SL",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Singapore",
        "dialCode": "+65",
        "code": "SG",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Slovakia",
        "dialCode": "+421",
        "code": "SK",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Slovenia",
        "dialCode": "+386",
        "code": "SI",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Solomon Islands",
        "dialCode": "+677",
        "code": "SB",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Somalia",
        "dialCode": "+252",
        "code": "SO",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "South Africa",
        "dialCode": "+27",
        "code": "ZA",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "South Sudan",
        "dialCode": "+211",
        "code": "SS",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "dialCode": "+500",
        "code": "GS",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Spain",
        "dialCode": "+34",
        "code": "ES",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Sri Lanka",
        "dialCode": "+94",
        "code": "LK",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Sudan",
        "dialCode": "+249",
        "code": "SD",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Suriname",
        "dialCode": "+597",
        "code": "SR",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Svalbard and Jan Mayen",
        "dialCode": "+47",
        "code": "SJ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Swaziland",
        "dialCode": "+268",
        "code": "SZ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Sweden",
        "dialCode": "+46",
        "code": "SE",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Switzerland",
        "dialCode": "+41",
        "code": "CH",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Syrian Arab Republic",
        "dialCode": "+963",
        "code": "SY",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Taiwan",
        "dialCode": "+886",
        "code": "TW",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Tajikistan",
        "dialCode": "+992",
        "code": "TJ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Tanzania, United Republic of Tanzania",
        "dialCode": "+255",
        "code": "TZ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Thailand",
        "dialCode": "+66",
        "code": "TH",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Timor-Leste",
        "dialCode": "+670",
        "code": "TL",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Togo",
        "dialCode": "+228",
        "code": "TG",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Tokelau",
        "dialCode": "+690",
        "code": "TK",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Tonga",
        "dialCode": "+676",
        "code": "TO",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Trinidad and Tobago",
        "dialCode": "+1868",
        "code": "TT",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Tunisia",
        "dialCode": "+216",
        "code": "TN",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Turkey",
        "dialCode": "+90",
        "code": "TR",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Turkmenistan",
        "dialCode": "+993",
        "code": "TM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Turks and Caicos Islands",
        "dialCode": "+1649",
        "code": "TC",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Tuvalu",
        "dialCode": "+688",
        "code": "TV",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Uganda",
        "dialCode": "+256",
        "code": "UG",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Ukraine",
        "dialCode": "+380",
        "code": "UA",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "United Arab Emirates",
        "dialCode": "+971",
        "code": "AE",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "United Kingdom",
        "dialCode": "+44",
        "code": "GB",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "United States",
        "dialCode": "+1",
        "code": "US",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Uruguay",
        "dialCode": "+598",
        "code": "UY",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Uzbekistan",
        "dialCode": "+998",
        "code": "UZ",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Vanuatu",
        "dialCode": "+678",
        "code": "VU",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Venezuela, Bolivarian Republic of Venezuela",
        "dialCode": "+58",
        "code": "VE",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Vietnam",
        "dialCode": "+84",
        "code": "VN",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Virgin Islands, British",
        "dialCode": "+1284",
        "code": "VG",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Virgin Islands, U.S.",
        "dialCode": "+1340",
        "code": "VI",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Wallis and Futuna",
        "dialCode": "+681",
        "code": "WF",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Yemen",
        "dialCode": "+967",
        "code": "YE",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Zambia",
        "dialCode": "+260",
        "code": "ZM",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    },
    {
        "name": "Zimbabwe",
        "dialCode": "+263",
        "code": "ZW",
        "image": "https://www.countryflags.io/AF/flat/64.png"
    }
]