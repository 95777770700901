import React from "react";

class SetUserPasswordComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            password: '',
            confirmPassword: ''
        }
    }

    regexTestPass(password) {
        let strongPasswordTest = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
        return strongPasswordTest.test(password);
    }

    render() {
        let { password, confirmPassword } = this.state;
        let regexTestPass = this.regexTestPass(password);
        let disabled = !(password && confirmPassword && password === confirmPassword && regexTestPass);
        return <>
            <div className="row">
                <form className="pt-3 col-md-7">
                    <div className="form-group">
                        <label>Enter your new password</label>
                        <input className="form-control"
                            name="password" type="password" value={password}
                            onChange={(e) => this.setState({ password: e.target.value })} placeholder="Enter password" />
                    </div>

                    <div className="form-group">
                        <label>Enter your confirm password</label>
                        <input className="form-control"
                            type="password" value={confirmPassword} onChange={(e) => this.setState({ confirmPassword: e.target.value })} id="exampleInputUsername1" placeholder="Confirm password" />
                        <div>{password && confirmPassword && password !== confirmPassword && <small className="text-danger error-text" >Passwords do not match</small>}</div>
                        {password && confirmPassword && !regexTestPass && <small className="text-danger error-text" >Must satisfy password policy</small>}
                    </div>

                    <div className="mt-3">
                        <button type="button" disabled={disabled} className={disabled ? 'btn btn-outline-secondary btn-rounded' : 'btn btn-white btn-rounded'} onClick={() => this.props.emitPassword(password)}>SUBMIT</button>
                        <div style={{ display: 'inline-block', 'marginLeft': '30px' }}></div>
                    </div>
                </form>
                <div className="col-md-5 form-group" style={{paddingTop: '16px'}}>
                    <label>Password Policy</label>
                    <ul style={{fontSize: '0.875rem'}}>
                        <li>
                            At least 1 Uppercase
                        </li>
                        <li>
                            At least 1 Lowercase
                        </li>
                        <li>
                            At least 1 Number
                        </li>
                        <li>
                            At least 1 Special Character
                        </li>
                        <li>
                            At least 8 Characters
                        </li>
                    </ul>
                </div>
            </div>
        </>
    }
}

export default SetUserPasswordComponent;