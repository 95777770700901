import axios from "axios"
import {
    Auth
} from 'aws-amplify';


function getApiStageType() {
    let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1 || window.location.hostname.indexOf('d32uc620y3be9') !== -1 || window.location.hostname.indexOf('v4abbottclinytics') !== -1) ? 'dev' : 'prod';
    // console.log(" hostname "+window.location.hostname+ " stage "+apiStage)
    return apiStage
}

function isUatEnvir() {
    let apiStage = (window.location.hostname.indexOf('uatclinytics') !== -1 || window.location.hostname.indexOf('localhost') !== -1) ? true : false;
    return apiStage
}

function getPatientApiUrl() {
    var apiStage = getApiStageType()

    if (apiStage === "prod") {
        if (isUatEnvir()) {
            return "https://uatemrapi.hlthclub.in/patient"
        }
        return `https://emrapi.hlthclub.in/patient`;
    } else {
        return `https://betaemrapi.hlthclub.in/patient`
    }
}

function getOnlinePatientApiStageUrl() {
    var apiStage = getApiStageType()
    if (apiStage === "prod") {
        if (isUatEnvir()) {
            return "https://uatemrapi.hlthclub.in/onlinepatient"
        }
        return `https://emrapi.hlthclub.in/onlinepatient`;
    } else {
        return `https://betaemrapi.hlthclub.in/onlinepatient`
    }
}

function getNewPatientApiUrl() {
    var apiStage = getApiStageType()

    if (apiStage === "prod") {
        if (isUatEnvir()) {
            return "https://uatemrapi.hlthclub.in/patient"
        }
        return `https://emrapi.hlthclub.in/patient`;
    } else {
        return `https://betaemrapi.hlthclub.in/patient`
    }
}

function getAppointmentApiUrl() {
    let apiStage = getApiStageType()
    if (apiStage === "prod") {
        return isUatEnvir() ? `https://uatemrapi.hlthclub.in/appointment` : `https://emrapi.hlthclub.in/appointment`;
    } else {
        return `https://betaemrapi.hlthclub.in/appointment`
    }
}


function getRefundApiUrl() {
    let apiStage = getApiStageType()
    if (apiStage === "prod") {
        return isUatEnvir() ? `https://uatemrapi.hlthclub.in/payments` : `https://emrapi.hlthclub.in/payments/`;
    } else {
        return `https://betaemrapi.hlthclub.in/payments/`
    }
}

function getUserApiUrl() {
    let apiStage = getApiStageType()
    if (apiStage === "prod") {
        return `https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        return `https://12vwe151nh.execute-api.ap-south-1.amazonaws.com/`
    }
}

function getEmrNotificationApiUrl() {
    let apiStage = getApiStageType()
    let api = (apiStage == 'dev') ? 'https://482wiwire5.execute-api.ap-south-1.amazonaws.com/' : "https://fs4llg3jbf.execute-api.ap-south-1.amazonaws.com/";
    return api;
}

function getEmrPaymentApiUrl() {
    let apiStage = getApiStageType()
    let api = (apiStage == 'dev') ? 'https://betaemrapi.hlthclub.in/telephony/' : isUatEnvir() ? `https://uatemrapi.hlthclub.in/telephony/` : "https://emrapi.hlthclub.in/telephony/";
    return api;
}




function resetAppOnLogout() {
    // reset auth token & user info
    sessionStorage.removeItem('emrToken');
    // reset axios header, remove Authorization header
    updateAuthHeader();
}
/**
 * Set Authorization token to header by default
 */
export function updateAuthHeader() {
    const AUTH_TOKEN = sessionStorage.getItem('emrToken');
    if (AUTH_TOKEN) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + AUTH_TOKEN;
    } else {
        axios.defaults.headers.common['Authorization'] = '';
    }
}

export function updateAuthHeaderWhenExpire() {
    const AUTH_TOKEN = sessionStorage.getItem('emrToken');
    if (AUTH_TOKEN) {
        Auth.currentSession().then(function (session) {
            if (!session || !session.idToken || !session.idToken.jwtToken) {
                axios.defaults.headers.common['Authorization'] = 'Bearer ' + AUTH_TOKEN;
                return;
            }
            sessionStorage.removeItem('emrToken');
            sessionStorage.setItem('emrToken', session.idToken.jwtToken);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + session.idToken.jwtToken;
            // window.location.reload();
        }, function (err) {
            console.log(err)
        })
    } else {
        axios.defaults.headers.common['Authorization'] = '';
    }
}

export function getAuthUserId() {
    const AUTH_TOKEN = sessionStorage.getItem('emrToken');
    if (AUTH_TOKEN) {
        return Auth.currentSession().then(function (session) {
            if (!session || !session.idToken || !session.idToken.payload || !session.idToken.payload.sub) {
                return;
            }
            return session.idToken.payload.sub;
        }, function (err) {
            console.log(err)
        })
    }
}

export function getToken() {
    const AUTH_TOKEN = sessionStorage.getItem('emrToken') ? sessionStorage.getItem('emrToken') : '';
    return AUTH_TOKEN;
}

export function setTokenAfterHalfHr() {
    return setInterval(() => {
        Auth.currentSession().then(function (session) {
            if (!session || !session.idToken || !session.idToken.jwtToken) {
                console.log('nothing happens');
                if (window.location.pathname.indexOf("404") !== -1 && window.location.pathname.indexOf("login") !== -1) {
                    window.location = "/doctor-login";
                }
            } else {
                sessionStorage.setItem('emrToken', session.idToken.jwtToken);
                const nativeMode = sessionStorage.getItem('nativeMode');
                if (nativeMode === 'true' || nativeMode === true) {
                    try {
                        emitNotifyEvent(session.idToken.jwtToken)
                    } catch (e) {
                        console.log("Notify Not found")
                    }
                }

            }
        }, function (err) {
            console.log(err)
        })
    }, 2100000)
}

function emitNotifyEvent(token) {
    let obj = {
        "req": {
            "token": token
        },
        "action": "refresh-token",
        "path": window.location.href
    }
    window.external.notify(JSON.stringify(obj))
}

export function throttle(func, limit) {
    let inThrottle
    return function () {
        const args = arguments
        const context = this
        if (!inThrottle) {
            func.apply(context, args)
            inThrottle = true
            setTimeout(() => inThrottle = false, limit)
        }
    }
}

var timeoutID;

function checkUserVisibilityOnApp() {
    window.localStorage.setItem("wasUserInactive", "false")
    window.addEventListener("mousemove", resetTimer, false);
    window.addEventListener("mousedown", resetTimer, false);
}

function startTimer() {
    timeoutID = window.setTimeout(goInactive, 50000);
}

function resetTimer(e) {
    window.clearTimeout(timeoutID);
    goActive();
}

function goInactive() {
    window.localStorage.setItem("wasUserInactive", "true");
}

function goActive() { 
    if (window.localStorage.getItem("wasUserInactive") === "true") {
        window.localStorage.setItem("wasUserInactive", "false")

        if (window.location.href.indexOf("login") === -1) {

            Auth.currentSession().then(function (session) {
                if (!session || !session.idToken || !session.idToken.jwtToken) {
                    if (window.location.pathname.indexOf("forgot-password") === -1 && window.location.pathname.indexOf("404") === -1 && window.location.pathname.indexOf("login") === -1) {
                        window.location = "/doctor-login";
                    }
                } else {

                    sessionStorage.setItem('emrToken', session.idToken.jwtToken);
                }
            }, function (err) {

                if (window.location.pathname.indexOf("forgot-password") === -1 && window.location.pathname.indexOf("404") === -1 && window.location.pathname.indexOf("login") === -1) {
                    window.location = "/doctor-login";
                }
            })
        }
    }
    startTimer();
}



const userAuth = {
    // updateAuthHeader,
    updateAuthHeaderWhenExpire,
    resetAppOnLogout,
    getToken,
    setTokenAfterHalfHr,
    throttle,
    getAuthUserId,
    checkUserVisibilityOnApp,
    getApiStageType,
    getPatientApiUrl,
    getNewPatientApiUrl,
    getAppointmentApiUrl,
    getEmrNotificationApiUrl,
    getEmrPaymentApiUrl,
    getUserApiUrl,
    getRefundApiUrl,
    getOnlinePatientApiStageUrl
}

export default userAuth;