import React from "react";

class ColumnTitleComponent extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    onHeaderClicked = (event) => {
        this.props.emitHeaderCollapseEvent(event);
    }

    render() {
        return (
            <div className={!this.props.hideShowOff ? "card-header" : ''} role="tab" id="heading-13" onClick={this.onHeaderClicked.bind(this, this.props.indSection.formname)} style={{ cursor: 'pointer', display: this.props.indSection.hideShowOff ? 'none' : '' }}>
                <a data-toggle="collapse" aria-expanded={`${this.props.indSection.collapse ? 'false' : 'true'}`}
                    aria-controls="collapse-13" className="p-2" style={{ fontSize: 14 }}> <h6 className="mb-0 mt-0">{this.props.indSection.title}</h6> </a>
            </div>
        );
    }
}

export default ColumnTitleComponent;
