import React from "react";

function MoolchandPlainTermsAndConditions(props) {
    return (
        <>
            <div className="row">
                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                        The Terms and Conditions contained herein shall apply to any person (“User”) using the services of Moolchand Hospital. Each User is therefore deemed to have read and accepted these terms.


                            <h4 className="card-title">A. Limitation of Liability</h4>
                            
                            <p className="c1">
                                <span className="c0">
                                    1. Moolchand Hospital has made this Service available to the User as a matter of convenience. Moolchand Hospital expressly disclaims any claim or liability arising out of the provision of this Service. The User agrees and acknowledges that he/ she shall be solely responsible for his/ her conduct and that Moolchand Hospital reserves the right to terminate the rights to use of the Service immediately without giving any prior notice thereof.
                                </span>
                            </p>
                            <p className="c1">
                                <span className="c0">2. Moolchand Hospital and/or the Payment Service Providers shall not be liable for any inaccuracy, error or delay in, or omission of (a) any data, information or message, or (b) the transmission or delivery of any such data, information or message; or (c) any loss or damage arising from or occasioned by any such inaccuracy, error, delay or omission, non-performance or interruption in any such data, information or message. Under no circumstances shall Moolchand Hospital and/or the Payment Service Providers, its employees, directors, and its third party agents involved in processing, delivering or managing the Services, be liable for any direct, indirect, incidental, special or consequential damages, or any damages whatsoever, including punitive or exemplary arising out of or in any way connected with the provision of or any inadequacy or deficiency in the provision of the Services or resulting from unauthorized access or alteration of transmissions of data or arising from suspension or termination of the Services.
                                </span>
                            </p>
                            

                            <p className="c1">
                                <span className="c0">
                                    3. Moolchand Hospital and the Payment Service Provider(s) assume no liability whatsoever for any monetary or other damage suffered by the User on account of:
                                </span>
                            </p>
                            <p className="c1">
                                <span className="c0">
                                    <ul>
                                        <li>
                                            (i) the delay, failure, interruption, or corruption of any data or other information transmitted in connection with use of the Payment Gateway or Services in connection thereto; and/ or
                                        </li>
                                        <li>
                                        (ii) any interruption or errors in the operation of the Payment Gateway.
                                        </li>
                                    </ul>
                                </span>
                            </p>
                            <p className="c1">
                                <span className="c0">
                                    4. The User shall indemnify and hold harmless the Payment Service Provider(s) and Moolchand Hospital and their respective officers, directors, agents, and employees, from any claim or demand, or actions arising out of or in connection with the utilization of the Services.
                                </span>
                            </p>
                            
                            
                            <p className="c1">
                                <span className="c0">
                                    5. The User agrees that Moolchand Hospital or any of its employees will not be held liable by the User for any loss or damages arising from your use of, or reliance upon the information contained on the Website, or any failure to comply with these Terms and Conditions where such failure is due to circumstance beyond Moolchand Hospital’s reasonable control.
                                </span>
                            </p>
                            <p className="c1">
                                <span className="c0">
                                6. The User agrees, understands and confirms that his/ her personal data including without limitation details relating to debit card/ credit card transmitted over the Internet may be susceptible to misuse, hacking, theft and/ or fraud and that Moolchand Hospital or the Payment Service Provider(s) have no control over such matters.


                                </span>
                            </p>
                            <p className="c1">
                                <span className="c0">
                                7. Although all reasonable care has been taken towards guarding against unauthorized use of any information transmitted by the User, Moolchand Hospital does not represent or guarantee that the use of the Services provided by/ through it will not result in theft and/or unauthorized use of data over the Internet.


                                </span>
                            </p>
                            <p className="c1">
                                <span className="c0">
                                8. Moolchand Hospital, the Payment Service Provider(s) and its affiliates and associates shall not be liable, at any time, for any failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of information contained on the Website.
                                </span>
                            </p>        
                            <h4 className="card-title">B. Debit/Credit Card, Bank Account Details / VPA details</h4>
                            <p className="c1">
                                <span className="c0">
                                    1. The User agrees that the debit/credit card details provided by him/ her for use of the aforesaid Service(s) must be correct and accurate and that the User shall not use a debit/ credit card / VPA, that is not lawfully owned by him/ her or the use of which is not authorized by the lawful owner thereof. The User further agrees and undertakes to provide correct and valid debit/credit card details.

                                </span>
                            </p>       
                            <p className="c1">
                                <span className="c0">
                                    2. The User may pay for his/ her services to Moolchand Hospital by using a debit/credit card, VPA or through online banking account. The User warrants, agrees and confirms that when he/ she initiates a payment transaction and/or issues an online payment instruction and provides his/ her card / bank details:
                                </span>
                            </p>  
                            <p className="c1">
                                <span className="c0">
                                    <ul>
                                        <li>
                                            i. The User is fully and lawfully entitled to use such credit / debit card, VPA, bank account for such transactions;
                                        </li>
                                        <li>
                                            ii. The User is responsible to ensure that the card/VPA/ bank account details provided by him/ her are accurate;
                                        </li>
                                        <li>
                                            iii. The User is authorizing debit of the nominated card/ VPA/bank account for the payment - services selected by such User along with the applicable Fees.
                                        </li>
                                        <li>
                                            iv. The User is responsible to ensure sufficient credit is available on the nominated card/ bank account at the time of making the payment to permit the payment of the dues payable or the bill(s) selected by the User inclusive of the applicable Fee.
                                        </li>
                                    </ul>
                                </span>
                            </p>
                            <h4 className="card-title">C. Payment Gateway Disclaimer</h4>
                            <p className="c1">
                                <span className="c0">
                                    The Service is provided in order to facilitate access to view and pay for services/bills online. Moolchand Hospital or the Payment Service Provider(s) do not make any representation of any kind, express or implied, as to the operation of the Payment Gateway other than what is specified in the Website for this purpose. By accepting/ agreeing to these Terms and Conditions, the User expressly agrees that his/ her use of the aforesaid online payment Service is entirely at own risk and responsibility of the User.

                                </span>
                            </p>       
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default MoolchandPlainTermsAndConditions;