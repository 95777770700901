import React from "react";
import ValidationComponent from "./ValidationComponent";
class CheckboxComponent extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    changeValue = (event) => {
        this.props.emitTextboxValue({ name: event.target.name, value: event.target.value, type: 'checkbox' })
    }

    checkIfValuePresentInValuesArray(valuesArray, value) {
        if (!value || !valuesArray) {
            return;
        }
        for (let index in valuesArray) {
            if (valuesArray[index] === value) {
                return true
            }
        }
        return false;
    }

    setErrorStatus = (errorStatus) => {
        this.props.emitErrorStatus(errorStatus);
    }

    checkForLabelValue(str) {
        if (!str || !str.trim()) {
            return false;
        }
        return true
    }

    render() {
        // console.log('-----------------------------------checkbox---------------------------------');
        const { type, name, classname, value, values, placeholder, label, labelSize, size, options, offset, rowType } = this.props;
        return (
            <React.Fragment>
                {labelSize && <label className={`form-label col-${labelSize}`} style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}>{label}</label>}
                <div className={`col-lg-${size} form-group  mb-0 }`} style={{ padding: rowType === "row" ? ' ' : '', margin: rowType === "row" ? ' ' : '' }}>
                    {!labelSize && <label className="form-label" style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}>{label}</label>}
                    <div className="d-sm-flex" style={{ flexWrap: 'wrap' }}>
                        {options && options.map((indOption, index) => {
                            return <div className="form-check mr-3 " key={index} style={{ marginTop: 5 }}>
                                <label className="form-check-label">
                                    <input type="checkbox" className="form-check-input" name={`${label}-${name}`} id={name} value={indOption.value || ''} checked={this.checkIfValuePresentInValuesArray(values, indOption.value)} onChange={this.changeValue.bind(this)} /> {indOption.value} <i className="input-helper"></i><i className="input-helper"></i></label>
                            </div>
                        })}
                    </div>
                    {this.props.validation && <ValidationComponent value={values} checkForm={this.props.checkForm} isDirty={this.props.isDirty} validation={this.props.validation} emitErrorStatus={this.setErrorStatus.bind(this)} />}
                </div>
                {offset &&
                    <div className={offset}> </div>
                }
            </React.Fragment>
        );
    }
}

export default CheckboxComponent;

{/* <label className={`col-lg-${labelSize}`} htmlFor={name}>{label}</label>
{
    options &&
    <React.Fragment>
        {options.map((indOption, index) =>
            <div className={`col-lg-${size}`} key={index}>
                <div className="form-check">
                    <label className="form-check-label" style={{ fontWeight: 500 }}>
                        <input type="checkbox" className="form-check-input" name={`${label}-${name}`} id={name} value={indOption.value || ''} checked={this.checkIfValuePresentInValuesArray(indOption.value, indOption.values)} onChange={this.changeValue.bind(this)} />
                        {indOption.value}
                        <i className="input-helper"></i></label>
                </div>
            </div>
        )}
    </React.Fragment>
} */}