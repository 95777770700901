import axios from 'axios';
import Utils from "../../Shared/utils/utils";
import userAuth from '../../Authentication/authActions';

function getBaseUrl() {
  let apiStage = (window.location.hostname.indexOf('localhost') !== -1 ||
    window.location.hostname.indexOf('beta') !== -1 ||
    window.location.hostname.indexOf('d32uc620y3be9') !== -1
  ) ? 'dev' : 'prod';
  return apiStage == "prod" ? (isUatEnvir() ? "https://uatemrapi.hlthclub.in" : 'https://emrapi.hlthclub.in') : 'https://betaemrapi.hlthclub.in';
}

function getOpdBaseUrl() {
  let apiStage = (window.location.hostname.indexOf('localhost') !== -1 ||
    window.location.hostname.indexOf('beta') !== -1 ||
    window.location.hostname.indexOf('d32uc620y3be9') !== -1
  ) ? 'dev' : 'prod';
  return apiStage == "prod" ?(isUatEnvir() ? "https://uatemrapi.hlthclub.in/comm/" : 'https://emrapi.hlthclub.in/comm/')  : 'https://betaemrapi.hlthclub.in/comm/';
}

function getVaccinationBaseUrl() {
  let apiStage = (window.location.hostname.indexOf('localhost') !== -1 ||
    window.location.hostname.indexOf('beta') !== -1 || 
    window.location.hostname.indexOf('d32uc620y3be9') !== -1) ? 'dev' : 'prod';
  return apiStage == "prod" ? (isUatEnvir() ? "https://uatemrapi.hlthclub.in/vaccination" :
   'https://emrapi.hlthclub.in/vaccination') : 'https://betaemrapi.hlthclub.in/vaccination';
}

function isUatEnvir() {
  let isUat = (window.location.hostname.indexOf('uatclinytics') !== -1 ||
   window.location.hostname.indexOf('localhost') !== -1) ? true : false;
  return isUat
}

let generateOTP = function (body, flowType) {
  let data = JSON.stringify(body);
  let config = {
    method: 'post',
    // https://a1eky9f5y2.execute-api.ap-south-1.amazonaws.com/dev/generate_vaccination_otp
    //  flowType === "v2" ? "https://a1eky9f5y2.execute-api.ap-south-1.amazonaws.com/dev/generate_vaccination_otp" :
    url: getBaseUrl() + '/onlinepatient/teleheal/generate-otp',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
  return axios(config)
}

let verifyOPDOTP = function (body) {
  let data = JSON.stringify(body);
  let config = {
    method: 'put',
    url: getOpdBaseUrl() + 'otp/verify',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
  return axios(config)
}

let verifyOTP = function (body) {
  let data = JSON.stringify(body);
  let config = {
    method: 'post',
    url: getBaseUrl() + '/onlinepatient/teleheal/verify-otp',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
  return axios(config)
}

let fetchSiteListData = function (body) {
  let data = JSON.stringify(body);
  let config = {
    method: 'post',
    url: getVaccinationBaseUrl() + '/get_site_data',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
  return axios(config)
}

let generateOpdOtp = function (body) {
  let data = JSON.stringify(body);
  let config = {
    method: 'post',
    url: getOpdBaseUrl() + 'otp/register',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
  return axios(config)
}

let getPatientListFromPhoneOrPHID = function (account_id, phone, flowType, branch, patient_hid, location) {
  let config = {};
  if (flowType === "v2") {
    let payload = {
      "account_id": account_id,
      "mobile": phone
    }
    if (branch) {
      payload["branch"] = branch
    }
    config = {
      method: 'post',
      url: getVaccinationBaseUrl() + `/getBookingList`,
      data: payload,
      headers: {
        'Content-Type': 'application/json'
      }
    };
  } else {
    let params = ""
    if (phone) {
      params = `account_id=${account_id}&phone=${phone}`
    } else if (patient_hid) {
      params = `account_id=${account_id}&get_data_by=uhid&patient_hid=${patient_hid}`
    }
    if (location) {
      params = params + `&location=${location}`
    }
    config = {
      method: 'get',
      url: getBaseUrl() + `/onlinepatient/ipd/fetch-list?${params}`,
      headers: {
        'Content-Type': 'application/json'
      }
    };
  }
  // https://a1eky9f5y2.execute-api.ap-south-1.amazonaws.com/dev/getBookingList

  return axios(config)
}

let fetchListForOPD = (dataBy, data, account_id) => {
  let query = '';
  if (dataBy === "phone") {
    query = `get_data_by=${dataBy}&phone=${data}&account_id=${account_id}`;
  } else {
    query = `get_data_by=${dataBy}&email=${data}&account_id=${account_id}`;
  }
  let config = {
    method: 'get',
    url: getBaseUrl() + `/onlinepatient/fetch-list?${query}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + userAuth.getToken()
    }
  };
  return axios(config)
}

let getPhoneNumber = (phone = "") => {
  return phone ? phone.substring(3) : ""
}

let saveNewPatient = function (data) {
  let config = {
    method: 'post',
    url: getBaseUrl() + '/onlinepatient/onlinepatient',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
  return axios(config)
}

let bookAppointment = function (data) {
  let config = {
    method: 'post',
    url: getBaseUrl() + '/onlinepatient/onlinepatient/book_test',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };


  return axios(config)
}

let getAccInfo = function (account_id) {
  let config = {
    method: 'get',
    url: getBaseUrl() + `/appointment/onlineappointment/get-account-info?account_id=${account_id}`,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return axios(config)
}

let fetchLocationByCovin = function (account_id, mobile) {
  let config = {
    method: 'get',
    url: getVaccinationBaseUrl() + `/get_location?account_id=${account_id}&mobile=${mobile}`,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  return axios(config)
}

let getMasterData = function (account_id) {
  let config = {
    method: 'get',
    url: getBaseUrl() + `/onlinepatient/get-tp-master-data?account_id=${account_id}`,
    headers: {
      'Content-Type': 'application/json'
    }
  };
  // ,
  // 'Authorization': 'Bearer ' + (sessionStorage.getItem('vaccinationToken') ? sessionStorage.getItem('vaccinationToken') : '')

  return axios(config)
}

let getProviderNameSetting = function (account_id) {
  let config = {
    method: 'get',
    url: getLabInsiteBaseUrl() + `${account_id}/vault/lab-insights-svc/getSettings`,
    headers: {
      'Content-Type': 'application/json'
    }
  };

  return axios(config)
}
// https://betalabinsights.hlthclub.in/

function getLabInsiteBaseUrl() {
  let apiStage = (window.location.hostname.indexOf('localhost') !== -1 ||
    window.location.hostname.indexOf('beta') !== -1 || window.location.hostname.indexOf('uat') !== -1 ||
    window.location.hostname.indexOf('d32uc620y3be9') !== -1) ? 'dev' : 'prod';
  return apiStage == "prod" ? 'https://labinsights.hlthclub.in/' : 'https://betalabinsights.hlthclub.in/';
}

function formatDate(date, format = "YYYY-MM-DD") {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;
  if (format == "DD-MM-YYYY") {
    return [day, month, year].join('-');
  }
  return [year, month, day].join('-');
}

function navigateUrls(page, data) {
  let url = ""
  switch (page) {
    case "vaccination_login":
      url = `/${data.account_id}/vaccination`
      break;
  }
  window.location = url
}

function getPaymentUrl(account_id, order_id) {
  let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1 || window.location.hostname.indexOf('d32uc620y3be9') !== -1) ? 'dev' : 'prod';
  let url = apiStage == "prod" ? 'https://pay.hlthclub.in' : "https://betapay.hlthclub.in"
  return `${url}/pay/${account_id}/${order_id}`
}

function checkHospConsult(location_props = {}) {
  
  let { pathname } = location_props
  return pathname && (pathname.includes("/ipd") || pathname.includes("/opd"))
}

function checkOPDConsult(location_props = {}) {
  
  let { pathname } = location_props
  return pathname && pathname.includes("/opd")
}

function navigatePrevPage(state, props) {
  let url = ""
  let account_id = props.match.params.account_id


  if (checkHospConsult(props.location)) {
    if (state && state.prevRoute) {
      switch (state.prevRoute) {
        case "otp":
          url = `/${account_id}/ipd`;
          break;
        default:
          url = `/${account_id}/ipd`
      }
    } else {
      url = `/${account_id}/ipd`
    }
  } else {
    if (state && state.prevRoute) {
      switch (state.prevRoute) {
        case "otp":
          url = `/${account_id}/vaccination`;
          break;
        default:
          url = `/${account_id}/vaccination`
      }
    } else {
      url = `/${account_id}/vaccination`
    }
  }
  window.location = url
}

let getAdmissionData = function (body) {
  let data = JSON.stringify(body);
  let config = {
    method: 'post',
    url: getBaseUrl() + '/onlinepatient/ipd/admission-data',
    headers: {
      'Content-Type': 'application/json'
    },
    data: data
  };
  return axios(config)
}

let isVaccAccount = function () {
  let acc_info = sessionStorage.getItem("acc_info")
  if (acc_info) {
    acc_info = JSON.parse(acc_info)
    return acc_info.is_vacc_account ? acc_info.is_vacc_account : false
  }
  return false
}

let onLogoutClick = (account_id, isHospConsult, props) => {
  let url = `/${account_id}/vaccination`
  if (isHospConsult) {
    url = `/${account_id}/ipd`
  }
  sessionStorage.clear()
  props.history.push(url);
}

let onOpdLogoutClick = (account_id, props) => {
  let url = `/${account_id}/opd`
  sessionStorage.clear()
  props.history.push(url);
}

export {
  generateOTP,
  verifyOTP,
  getPatientListFromPhoneOrPHID,
  getPhoneNumber,
  saveNewPatient,
  formatDate,
  bookAppointment,
  getAccInfo,
  getMasterData,
  navigateUrls,
  getPaymentUrl,
  fetchSiteListData,
  getProviderNameSetting,
  fetchLocationByCovin,
  checkHospConsult,
  navigatePrevPage,
  getAdmissionData,
  isVaccAccount,
  onLogoutClick,
  checkOPDConsult,
  generateOpdOtp,
  verifyOPDOTP,
  fetchListForOPD,
  onOpdLogoutClick
}