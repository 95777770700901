import React from 'react'
import SomethingWentWrongComponent from '../../Common/ErrorPage/SomethingWentWrongComponent';
import userService from '../../Services/users.service';
class ErrorBoundaryComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null
        };
        // this.logErrorToService('error','test message');
    }

    static getDerivedStateFromError(error) {
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        this.setState({
            error: error,
            errorInfo: errorInfo
        }, () => this.logErrorToService(error, errorInfo));
    }

    async logErrorToService(error, errorInfo) {
        try {
            // add service call here
            let data = {
                error: error,
                errorInfo: errorInfo
            }
            const response = await userService.logAppError(data);
            
        } catch (err) {
            console.log(err)
        }
    }

    render() {
        const { error, errorInfo, hasError } = this.state;
        // if (hasError) {
        // You can render any custom fallback UI
        return hasError ? <SomethingWentWrongComponent
            error={error}
            errorInfo={errorInfo}>
        </SomethingWentWrongComponent> : this.props.children;
        // }
        // return this.props.children;
    }
}

export default ErrorBoundaryComponent;