import React from "react";

/**
 * Searches in page in all columns of table data
 * according to search string
 * */
class SearchPageViewComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            completeTableData: this.props.dataToFilter,
            currentSearchString: ''
        }
    }

    // static getDerivedStateFromProps(nextProps, prevState) {
    //     if (prevState.dataToFilter !== nextProps.dataToFilter) {
    //       return ({  completeTableData: nextProps.dataToFilter }) // <- this is setState equivalent
    //     }
    //   }

    // componentDidUpdate(nextProps) {
    //     if (this.props.dataToFilter !== nextProps.dataToFilter) {
    //         this.setState({
    //             completeTableData: nextProps.dataToFilter
    //         });
    //     }
    // }

    // componentDidUpdate(prevProps) {
    //     if (prevProps.dataToFilter) {
    //         this.handleSearch(this.state.currentSearchString)
    //     }
    //   }

    handleSearch = event => {
        let targetValue = event.target ? event.target.value : event;
        this.state.currentSearchString = targetValue;
        this.setState(this.state)
        // let filteredData = [];
        // filteredData = this.state.completeTableData.filter(e => {
        //     let mathesItems = Object.values(e)
        //     let retVal = true;
        //     let matched = [];
        //     mathesItems.forEach(e => {
        //         const regex = new RegExp(targetValue, 'gi')
        //         if (typeof (e) === 'string') {
        //             retVal = e.match(regex)
        //         }
        //         if ((retVal && typeof (retVal) === 'object') && retVal.length > 0) {
        //             matched = retVal;
        //         }
        //     })
        //     if (matched.length > 0) {
        //         return matched
        //     }
        //     return retVal;
        // })
        this.props.renderFilteredData(targetValue)
    }

    onPatientListSearchTextChanged = (event) => {
        this.props.emitSearchTextData(event.target.value);
    }

    render() {
        const { type } = this.props;
        return (
            <div className="form-group mb-0">
                {/* {!this.props.hideLabel && <label htmlFor="" className="mt-2">Search</label>} */}
                {type ? (
                    <input type="search" className="form-control" placeholder="Search"
                        aria-controls="order-listing" onChange={this.onPatientListSearchTextChanged.bind(this)} />
                ) : (
                        <input type="search" className="form-control" placeholder="Search"
                            aria-controls="order-listing" onChange={this.handleSearch.bind(this)} />
                    )
                }
            </div>
        );
    }
}

export default SearchPageViewComponent;