import React, {
    useState, useRef, useEffect
} from 'react';
import { useHistory } from "react-router";
import QuestionFormComponent from "./Components/QuestionFormComponent";
import QuestionList from "./Components/QuestionList";
import SortingList from "./Components/SortingList";
import SweetAlertComponent from '../../Common/Alert/SweetAlertComponent';
import accountService from '../../Services/account.settings';

function QuestionnaireContainer(props) {

    const history = useHistory();

    let [accountId, setAccountId] = useState(props.match && props.match.params && props.match.params.providerName ? props.match.params.providerName : '');
    let vaccQuestionnair = props.details && props.details.vacc_questionnair ? props.details.vacc_questionnair : []
    let [questionsList, setQuestionList] = useState(vaccQuestionnair);
    let [optionsList, setOptionsList] = useState('');
    let [activeTab, setActiveTab] = useState('home');
    let [formData, setFormData] = useState({
        question: '',
        options: '',
        exp_answer: ''
    })
    const [isLoading, setIsloading] = useState(false);
    const [alert, setAlert] = useState({ title: '', type: '', message: '', show: false })



    useEffect(() => {

    }, []);


    const onFormValueChange = (event) => {
        let { name, value } = event.target;

        formData = {
            ...formData,
            [name]: value
        }
        setFormData(formData);
    }

    const getOutOfFocusEvent = (event) => {
        if (!formData.options) {
            return;
        }
        let data = []
        if (formData.options.indexOf(",") !== -1) {
            let splitArr = formData.options.split(",");
            data = splitArr;
        } else {
            data = [formData.options]
        }
        setOptionsList(data);
    }

    const addQuestion = () => {
        // questionsList.push(formData)
        // setQuestionList(questionsList)

        setQuestionList(prevArray => [...prevArray, formData])
    }

    const clearFormValues = (event) => {
        formData = {
            question: '',
            options: '',
            exp_answer: ''
        }
        setOptionsList('');
        setFormData(formData);
    }

    const emitUpdatedList = (event) => {
        setQuestionList(event);
        setActiveTab('home');
    }

    const onDeleteQuestion = (event) => {
        questionsList.splice(event, 1)
        setQuestionList([...questionsList]);
    }

    const saveQuestionnaire = async () => {
        try {
            setIsloading(true);
            let data = {
                "questions": questionsList
            }
            let response = await accountService.postQuestionsData(data, props.providerName);
            
            if (response && response.type === "success") {
                setAlert({ type: 'success', title: 'Success', text: "Question uploaded successfully!!!", show: true })
                setIsloading(false);
            } else {
                setAlert({ type: 'error', title: 'Error Occurred!', text: response ? typeof response === 'object' ? response.message : response : 'Error Occurred While Uploading Question Data.', show: true })
                setIsloading(false);
            }
            //  setAlert({ type: 'error', title: 'Error Occurred!', text: response ? typeof response === 'object' ? response.message : response : 'Please try again.', show: true });
        } catch (error) {
            console.log(error)
        }
    }

    // async fetchAccountData(key, term) {

    //     try {
    //          
    //         this.checkAccountListResponseData(AccountListResponse);
    //     } catch (error) {
    //         this.setState({
    //             loading: false
    //         })
    //         console.log('error');
    //     }
    // }

    const sweetAlertClose = () => {
        alert.show = false;
        setAlert(alert);
        if (alert.type === "success") {
            props.emitCancelEvent(true)
        }

    }

    return (
        <>
            <div className={`modal fullmodal fade ${props.open ? 'show' : ''}`} id="timeslotmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden={`${props.open ? true : false}`} style={{
                    display: props.open ? 'block' : 'none', paddingRight: props.open ? '17px' : '', overflowX: 'hidden', overflowY: 'auto', backgroundColor: '#716e6e54'
                }}>
                <div className={`modal-dialog ${props.fullModal ? 'modal-lg' : ''}`} role="document" style={{ marginTop: 'calc(70px + 0px)' }}>
                    <div className="modal-content bg-white">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{props.title ? props.title : 'Select your booking slot'}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => props.emitCancelEvent(false)}>
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body" style={{ background: "#f2edf3" }}>
                            <div className="row pt-0">

                                <div className="col-12 grid-margin">

                                    <div className="card">
                                        <div className="card-header header-sm align-items-center p-1 px-2 d-flex justify-content-between">

                                            <h4 className="card-title">Question For {props.providerName}Account</h4>
                                        </div>
                                        <div className="card-body">
                                            <QuestionFormComponent
                                                formData={formData}
                                                optionsList={optionsList}
                                                onValueChange={onFormValueChange}
                                                onOutOfFocusEvent={getOutOfFocusEvent}
                                                onClearFormValues={clearFormValues}
                                                onAddQuestion={addQuestion}>
                                            </QuestionFormComponent>
                                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className={`nav-link ${activeTab === "home" ? 'active' : ''}`} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true" onClick={() => setActiveTab('home')}>Questions List</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className={`nav-link ${activeTab === "sort" ? 'active' : ''}`} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false" onClick={() => setActiveTab('sort')}>Sort Questions List</button>
                                                </li>

                                            </ul>
                                            <div className="tab-content" id="myTabContent">
                                                <div className={`tab-pane fade ${activeTab === "home" ? 'show active' : ''}`} id="home" role="tabpanel" aria-labelledby="home-tab">
                                                    <QuestionList
                                                        isLoading={isLoading}
                                                        optionsList={optionsList}
                                                        emitSaveEvent={saveQuestionnaire}
                                                        questionsList={questionsList}
                                                        onDeleteQuestion={(e) => onDeleteQuestion(e)}></QuestionList>
                                                </div>
                                                <div className="tab-pane fade" className={`tab-pane fade ${activeTab === "sort" ? 'show active' : ''}`} id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                    <SortingList items={questionsList} emitUpdatedList={(e) => emitUpdatedList(e)}></SortingList>
                                                </div>

                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {alert.show &&
                <SweetAlertComponent
                    show={alert.show}
                    type={alert.type}
                    title={alert.title}
                    text={alert.text}
                    sweetAlertClose={() => sweetAlertClose()} />
            }
        </>
    )
}

export default QuestionnaireContainer;
