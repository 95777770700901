import React from "react";
// component imports
import HeaderContainer from '../../Common/Header/HeaderContainer';
import AccountCreateComponent from './Component/AccountCreateComponent';
// common imports
import SweetAlertComponent from '../../Common/Alert/SweetAlertComponent';
// additional plugin imports
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// service imports
import accountService from '../../Services/account.settings';


const $ = window["jQuery"];

class AccountCreateContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            type: 'creat',
            queryData: null,
            isFormLoading: false,
            alert: {
                type: '',
                title: '',
                text: ''
            }
        }
    }

    componentDidMount() {
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    }

    getEmitedAccountSettingsData = (event) => {
        if (!event) {
            return;
        }
        this.setState({
            isFormLoading: true
        }, () => this.createAccount(event))

    }

    async createAccount(data) {
        try {
            const response = await accountService.createAccountAndUser(data);
            this.checkAccountSaveResponse(response);
        } catch (error) {
            this.setState({
                isFormLoading: false
            })
            console.log(error);
        }
    }

    checkAccountSaveResponse(response) {
        if (!response) {
            this.setState({
                isFormLoading: false
            }, () => this.onError(response))
        }
        switch (response.type) {
            case 'error':
                this.setState({
                    isFormLoading: false
                }, () => this.onError(response))
                return;
            case 'success':
                this.setState(prevState => ({
                    show: true,
                    isFormLoading: false,
                    alert: {
                        ...prevState.alert,
                        type: 'success',
                        title: 'Success',
                        text: `Success In  ${this.state.type}ing Account`
                    }
                }));
                return;
            default:
                return;
        }
    }

    onError(response) {
        this.setState(prevState => ({
            show: true,
            isFormLoading: false,
            alert: {
                ...prevState.alert,
                type: 'error',
                title: 'Error',
                text: response.message ? response.message : 'Error'
            }
        }));
    }

    notify(message) {
        toast.success(message, { position: toast.POSITION.TOP_RIGHT, containerId: 'A' });
    }

    sweetAlertClose = (event) => {
        this.setState({ show: false });
        this.redirectToUserList('/account-list')
    }

    redirectToUserList(event) {
        if (this.state.alert.type === 'error') {
            return;
        }
        this.props.history.push(event);
    }

    componentWillUnmount() {
        $('[data-toggle="tooltip"]').tooltip('hide');
    }

    render() {
        return (
            <div>
                <HeaderContainer  {...this.props}></HeaderContainer>
                <AccountCreateComponent type={this.state.type} isFormLoading={this.state.isFormLoading} emitAccountSettingsData={this.getEmitedAccountSettingsData.bind(this)}> </AccountCreateComponent>
                {this.state.show &&
                    <SweetAlertComponent show={this.state.show} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.sweetAlertClose}></SweetAlertComponent>
                }
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
            </div>
        );
    }
}

export default AccountCreateContainer;
