import React from "react";
import { S3UPLOAD_KEY_NEW } from '../../Constants/s3Key';
import Spinner from "../Spinner/Spinner";
import { Storage } from 'aws-amplify';
import SweetAlertComponent from '../Alert/SweetAlertComponent';
import { ToastContainer, toast } from 'react-toastify';
import accountService from '../../Services/account.settings';
import "react-datepicker/dist/react-datepicker.css";
import prescriptionService from '../../Services/prescription.service';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import Papa from 'papaparse';
import { isThisHour } from "date-fns/esm";

class TranslationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    onEditClick = (event) => {
        this.props.emitEditEvent(event);
    }

    onEditCancel = (event) => {
        this.props.emitEventOnEditCancel()
    }

    onTextboxValueChange = (event) => {
        const { name, value } = event.target
        this.props.emitChangeDataOnEdit(name, value);
    }

    onEditDataSave = (event) => {
        this.props.emitEventOnEditDataSave();
    }

    onCategorySelectEvent = (event) => {
        this.props.emitCategorySelectEvent(event.target.value);
    }

    render() {
        let { translationData, isEditMode, translationKey, editDataConfig, selCategory ,accountInfo} = this.props;
        return (
            <div className="row p-2">
                {accountInfo && accountInfo.translation_categories && accountInfo.translation_categories.length &&
                    <div className="col-lg-4 col-sm-12">
                        <div className="form-group  mb-3">
                            <label for="exampleInputUsername1">Category</label>
                            <select className="form-control" name="selCategory" value={selCategory} id="exampleSelectGender" onChange={this.onCategorySelectEvent.bind(this)}>
                                <option value="">Select Category</option>
                                {accountInfo.translation_categories.map((indValue, i) =>
                                    <option key={i} value={indValue}>{indValue}</option>
                                )}
                            </select>
                        </div>
                    </div>
                }
                <div className="col-md-12 table-responsive">
                    <table className="table table-hover table-sm">
                        <thead className="thead-light">
                            <tr style={{ textTransform: 'capitalize' }}>
                                <th>Text</th>
                                <th>nepali</th>
                                <th>kannada</th>
                                <th>gujarati</th>
                                <th>marathi</th>
                                <th>tamil</th>
                                <th>bengali</th>
                                <th>urdu</th>
                                <th>punjabi</th>
                                <th>oriya</th>
                                <th>arabic</th>
                                {/* <th>assamese</th> */}
                                <th>telugu</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {translationData && translationData.length > 0 && translationData[0] && !!translationData[0].translation && Object.keys(translationData[0].translation).map((indTranslation, i) =>
                                <tr>
                                    <td>{translationData[0].translation[indTranslation].text}</td>
                                    <td>
                                        {(isEditMode && translationKey === indTranslation) ? (
                                            <input autocomplete="nope" type="text" placeholder="" className="form-control" name="nepali" value={editDataConfig.nepali} onChange={this.onTextboxValueChange.bind(this)} />
                                        ) : (
                                                <>
                                                    {translationData[0].translation[indTranslation].nepali}
                                                </>
                                            )}
                                    </td>
                                    <td>
                                        {(isEditMode && translationKey === indTranslation) ? (
                                            <input autocomplete="nope" type="text" placeholder="" className="form-control" name="kannada" value={editDataConfig.kannada} onChange={this.onTextboxValueChange.bind(this)} />
                                        ) : (
                                                <>
                                                    {translationData[0].translation[indTranslation].kannada}
                                                </>
                                            )}
                                    </td>
                                    <td>
                                        {(isEditMode && translationKey === indTranslation) ? (
                                            <input autocomplete="nope" type="text" placeholder="" className="form-control" name="gujarati" value={editDataConfig.gujarati} onChange={this.onTextboxValueChange.bind(this)} />
                                        ) : (
                                                <>
                                                    {translationData[0].translation[indTranslation].gujarati}
                                                </>
                                            )}
                                    </td>
                                    <td>
                                        {(isEditMode && translationKey === indTranslation) ? (
                                            <input autocomplete="nope" type="text" placeholder="" className="form-control" name="marathi" value={editDataConfig.marathi} onChange={this.onTextboxValueChange.bind(this)} />
                                        ) : (
                                                <>
                                                    {translationData[0].translation[indTranslation].marathi}
                                                </>
                                            )}
                                    </td>
                                    <td>
                                        {(isEditMode && translationKey === indTranslation) ? (
                                            <input autocomplete="nope" type="text" placeholder="" className="form-control" name="tamil" value={editDataConfig.tamil} onChange={this.onTextboxValueChange.bind(this)} />
                                        ) : (
                                                <>
                                                    {translationData[0].translation[indTranslation].tamil}
                                                </>
                                            )}
                                    </td>
                                    <td>
                                        {(isEditMode && translationKey === indTranslation) ? (
                                            <input autocomplete="nope" type="text" placeholder="" className="form-control" name="bengali" value={editDataConfig.bengali} onChange={this.onTextboxValueChange.bind(this)} />
                                        ) : (
                                                <>
                                                    {translationData[0].translation[indTranslation].bengali}
                                                </>
                                            )}
                                    </td>
                                    <td>
                                        {(isEditMode && translationKey === indTranslation) ? (
                                            <input autocomplete="nope" type="text" placeholder="" className="form-control" name="urdu" value={editDataConfig.urdu} onChange={this.onTextboxValueChange.bind(this)} />
                                        ) : (
                                                <>
                                                    {translationData[0].translation[indTranslation].urdu}
                                                </>
                                            )}
                                    </td>
                                    <td>
                                        {(isEditMode && translationKey === indTranslation) ? (
                                            <input autocomplete="nope" type="text" placeholder="" className="form-control" name="punjabi" value={editDataConfig.punjabi} onChange={this.onTextboxValueChange.bind(this)} />
                                        ) : (
                                                <>
                                                    {translationData[0].translation[indTranslation].punjabi}
                                                </>
                                            )}
                                    </td>
                                    <td>
                                        {(isEditMode && translationKey === indTranslation) ? (
                                            <input autocomplete="nope" type="text" placeholder="" className="form-control" name="oriya" value={editDataConfig.oriya} onChange={this.onTextboxValueChange.bind(this)} />
                                        ) : (
                                                <>
                                                    {translationData[0].translation[indTranslation].oriya}
                                                </>
                                            )}
                                    </td>
                                    <td>
                                        {(isEditMode && translationKey === indTranslation) ? (
                                            <input autocomplete="nope" type="text" placeholder="" className="form-control" name="arabic" value={editDataConfig.arabic} onChange={this.onTextboxValueChange.bind(this)} />
                                        ) : (
                                                <>
                                                    {translationData[0].translation[indTranslation].arabic}
                                                </>
                                            )}
                                    </td>
                                    {/* <td>
                                        {(isEditMode && translationKey === indTranslation) ? (
                                            <input autocomplete="nope" type="text" placeholder="" className="form-control" name="assamese" value={editDataConfig.assamese} onChange={this.onTextboxValueChange.bind(this)} />
                                        ) : (
                                                <>
                                                    {translationData[0].translation[indTranslation].assamese}
                                                </>
                                            )}
                                    </td> */}
                                    <td>
                                        {(isEditMode && translationKey === indTranslation) ? (
                                            <input autocomplete="nope" type="text" placeholder="" className="form-control" name="telugu" value={editDataConfig.telugu} onChange={this.onTextboxValueChange.bind(this)} />
                                        ) : (
                                                <>
                                                    {translationData[0].translation[indTranslation].telugu}
                                                </>
                                            )}
                                    </td>
                                    <td>
                                        {(isEditMode && translationKey === indTranslation) ? (
                                            <>
                                                <button className="btn btn-outline-success btn-sm" onClick={this.onEditCancel.bind(this)}>
                                                    Cancel
                                                </button>
                                                <button className="btn btn-outline-success btn-sm" onClick={this.onEditDataSave.bind(this, indTranslation)}>
                                                    Save
                                                </button>
                                            </>
                                        ) : (
                                                <button className="btn btn-outline-success btn-sm" onClick={this.onEditClick.bind(this, indTranslation)}>
                                                    Edit
                                                </button>
                                            )
                                        }
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        )
    }
}

export default TranslationList;

