import React from "react";
import prescriptionService from '../../../Services/prescription.service';
import { S3UPLOAD_KEY } from '../../../Constants/s3Key';
import { Storage } from 'aws-amplify';
import SweetAlertComponent from '../../../Common/Alert/SweetAlertComponent';
import Spinner from '../../../Common/Spinner/Spinner';

class BookingUploadModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            file: '',
            suggestion: '',
            moleculeData: [],
            uploadedFiles: {},
            isDisabled: true,
            isLoading: false,
            alert: { title: '', type: '', message: '', show: false },
        }
    }

    onFileUpload(e) {
        let file = e.target.files[0];
        if (!file) return;
        let re = /(\.xlsx)$/i;
        if (!re.exec(file.name.toLowerCase())) {
            // this.notify(`${file.name} is not allowed for upload`);
            return;
        }
        let { uploadedFiles } = this.state;
        uploadedFiles = { image: file, name: file.name };
        this.setState({ uploadedFiles }, () => this.checkIfFormDisabled());
    }

    saveImageToS3(file) {
        let { image, name } = file;
        name = name.toLowerCase().replace(/\s/g, '')
        Storage.configure({
            level: 'public',
            AWSS3: S3UPLOAD_KEY
        });

        let url = 'vaccination-bookings/';
        Storage.put(`${url}env-${sessionStorage.getItem('apiStage')}/acc-${sessionStorage.getItem('s3AccountId')}/${new Date().getTime()}/list/${name}`, image, {
            contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        }).then(result => {
            if (result.key) {
                let imgPath = `public/${result.key}`;
                this.saveS3ImagePath(imgPath);
            }

        }
        ).catch(err => {
            let { isLoading } = this.state;
            isLoading = false;
            this.setState({ isLoading });
            console.log(err)
        });
    }


    async saveS3ImagePath(finalData) {
        try {
            let data = {
                "account_id": this.props.accountId ? this.props.accountId : sessionStorage.getItem('s3AccountId'),
                "file_path": finalData
            }
            var response = await prescriptionService.uploadBookingList(data);
            this.checkForSaveResponse(response);
        } catch (error) {
            console.log(error);
        }
    }

    onSelectValueChange = (event) => {
        const { name, value } = event.target;
        let { type } = this.state;
        type = value;
        this.setState({ type }, () => this.checkIfFormDisabled())
    }

    onSubmit() {

        this.setState({
            isLoading: true
        }, () => this.saveImageToS3(this.state.uploadedFiles));
        // } else if (this.state.type === "molecule") {
        //     this.setState({
        //         isLoading: true
        //     }, () => {
        //         let finalData = this.state.moleculeData;
        //         this.saveS3ImagePath(finalData);
        //     });
        // } else {
        //     this.setState({
        //         isLoading: true
        //     }, () => {
        //         let finalData = this.getFinalData();
        //         this.saveS3ImagePath(finalData);
        //     });

        // }
    }

    checkIfFormDisabled = () => {
        let { isDisabled, type, suggestion, uploadedFiles } = this.state;

        isDisabled = false;
        this.setState({ isDisabled });


    }

    getFinalData = () => {
        if (!this.state.suggestion) {
            return;
        }
        if (this.state.suggestion.indexOf(",") !== -1) {
            let arr = this.state.suggestion.split(",");
            let final = []
            for (let i in arr) {
                let data = {
                    result_t: arr[i]
                }
                final.push(data)
            }
            return final;
        } else {
            return [{ result_t: this.state.suggestion }]
        }
    }

    checkForSaveResponse(response) {
        
        if (!response) {
            this.setState({ alert: { show: true, type: 'error', title: 'Error', text: 'Error Occured Try again!!!' }, isLoading: false });
            return;
        }

        switch (response.type) {
            case 'error':
                this.setState({ alert: { show: true, type: 'error', title: 'Error', text: 'Error Occured Try again!!!' }, isLoading: false });
                return;
            case 'success':
                this.setState({ alert: { show: true, type: 'success', title: 'Success', text: 'Successfully Uploaded Booking List.' }, isLoading: false });
                return;
        }
    }

    onTextValueChange = (event) => {
        const { name, value } = event.target;
        let { suggestion } = this.state;
        suggestion = value;
        this.setState({ suggestion }, () => this.checkIfFormDisabled());
    }

    onClose = () => {
        this.props.emitModalCloseEvent()
    }

    getEmitedMoleculeAddEvent = (event) => {
        let { moleculeData } = this.state;
        moleculeData.push(event);
        this.setState({ moleculeData }, () => this.checkIfFormDisabled());
    }

    getEmitedMoleculeDeleteEvent = (event) => {
        let { moleculeData } = this.state;
        moleculeData.splice(event, 1);
        this.setState({ moleculeData }, () => this.checkIfFormDisabled());
    }

    setAlert = (event) => {
        let { alert } = this.state;
        alert.show = false;
        this.setState({ alert }, () => {
            if (this.state.alert.type === "success") {
                this.onClose();
            }
        });
    }

    render() {
        const { type, suggestion, uploadedFiles, moleculeData, alert, isLoading } = this.state;
        return (
            <>

                <div className="row">
                    <div className="col-12">

                        <form method="post" action="#" id="#">
                            <ul className="pl-3 mt-2 text-small">
                                <li>Download sample file to upload :  <a href={`/files/VaccinationCSV_final.xlsx`} download><i className="mdi mdi-arrow-down-bold-circle-outline" data-tip data-for="drugs_file" /></a></li>
                                <li>Document type can be XLSX</li>
                                <li>Document size to be less than 20MB</li>
                            </ul>
                            <div className="form-group filedrag color">
                                <label>Upload Your File </label>
                                <input type="file" name="img" multiple onChange={e => this.onFileUpload(e)} onClick={(e) => { e.target.value = null }}></input>
                            </div>
                        </form>

                    </div>
                    <div className="col-12" style={{ textAlign: 'right' }}>
                        {isLoading && <div style={{ display: 'inline-block', 'marginLeft': '30px' }}><Spinner width={50} height={50}></Spinner></div>}
                        <button className="btn btn-outline-secondary btn-rounded" onClick={this.onClose.bind(this)}>Cancel</button>
                        <button type="submit" disabled={this.state.isDisabled} className="btn btn-success ml-2 btn-rounded" onClick={this.onSubmit.bind(this)}>Submit</button>

                    </div>
                </div>
                {alert.show && <SweetAlertComponent show={alert.show} type={alert.type} title={alert.title} text={alert.text} sweetAlertClose={this.setAlert.bind(this)} />}
            </>
        );
    }
}

export default BookingUploadModal;
