import React from "react";
import ValidationComponent from "./ValidationComponent";
// datepicker component
import DatePicker from "react-datepicker";
import { Portal } from 'react-overlays';
import "react-datepicker/dist/react-datepicker.css";
import Utils from '../../../Shared/utils/utils';
const CalendarContainer = ({ children }) => {
    const el = document.getElementById('calendar-portal')

    return (
        <Portal container={el}>
            {children}
        </Portal>
    )
}

class DateComponent extends React.Component {

    constructor(props) {
        super(props);
    }

    changeValue = (name, dateValue) => {
        let date = Utils.getFormatedDate(dateValue, 'date');
        this.props.emitTextboxValue({ name: name, value: date })
    }

    setErrorStatus = (errorStatus) => {
        this.props.emitErrorStatus(errorStatus);
    }

    checkForLabelValue(str) {
        if (!str || !str.trim()) {
            return false;
        }
        return true;
    }

    onFocus = (event) => {
        event.preventDefault();
        this.props.emitFocusEvent()
    }

    render() {
        // console.log('-----------------------------------date---------------------------------');
        const { name, placeholder, value, label, labelSize, size, options, offset, rowType, validation } = this.props;
        let selectedDate = value && value.split ? `${value.split('-')[1]}-${value.split('-')[0]}-${value.split('-')[2]}`: ''
        //debugger;
        //console.log("dateviewcomponent datepicker",selectedDate);
        return (
            <React.Fragment>
                {labelSize && <label className={`col-${labelSize} col-form-label mb-0`} style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}>
                    {label}
                </label>}
                <div className={`col-lg-${size}`} style={{ padding: rowType === "row" ? '' : '', margin: rowType === "row" ? '' : '' }}>
                    {!labelSize && <label className={`col-form-label mb-0`} style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}>
                        {label}
                    </label>}
                    {/* -auto */}
                    <div className="" style={{ marginBottom: '.5rem' }}>
                        <DatePicker
                            style={{ width: '100%' }}
                            onFocus={this.onFocus}
                            className="form-control"
                            minDate={validation && validation.disablePastDates ? new Date() : ''}
                            maxDate={validation && validation.disableFutureDates ? new Date() : ''}
                            peekNextMonth
                            dateFormat="dd-MM-yyyy"
                            popperContainer={CalendarContainer}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            selected={Utils.isValidDate(selectedDate) ? new Date(selectedDate) : validation && validation.required ? new Date() : ''}
                            onChange={this.changeValue.bind(this, name)}
                        />
                    </div>
                    {this.props.validation && <ValidationComponent value={value} checkForm={this.props.checkForm} isDirty={this.props.isDirty} validation={this.props.validation} emitErrorStatus={this.setErrorStatus.bind(this)} />}
                </div>
                {offset &&
                    <div className={offset}> </div>
                }
            </React.Fragment>
        );
    }
}

export default DateComponent;
