import React, { useState } from "react";

import SweetAlertComponent from '../../Common/Alert/SweetAlertComponent';
import { ToastContainer, toast } from 'react-toastify';
import accountService from '../../Services/account.settings';
import "react-datepicker/dist/react-datepicker.css";
import Papa from 'papaparse';
import doctorService from "../../Services/doctor.service";

class InvoiceConfigComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			invoice_config: null,
			isLoading: false,
			alert: { show: false, type: '', title: '', text: '' },
			headerConfig: ['type', 'name', 'description', 'price', 'slots required?', 'doctor'],
			headerNotPres: [],
			invoiceBody: null,
			userList: [],
			invoice_type: '',
			isEdit: false,
			accountDetail: null,
			isDirty: false
		}
	}

	componentDidMount() {
		if (this.props.details && this.props.details.invoice_settings) {
			let { invoiceBody } = this.state;
			invoiceBody = this.props.details.invoice_settings && this.props.details.invoice_settings.body ? this.props.details.invoice_settings.body : null;
			this.setState({ invoiceBody }, () => this.getUserList());
		}
	}

	async getUserList() {
		let { userList } = this.state;
		if (!this.props.details) return
		try {
			// this.state.speciality
			const response = await doctorService.getOnlineDoctor('', this.props.details && this.props.details.account_id, '', this.state.token);
			
			if (!response) {
				userList = [];
				this.setState({ userList })
				return;
			}
			switch (response.type) {
				case 'error':
					this.setState({ userList: [] })
					break;
				case 'success':
					this.setState({ userList: response.data.rows })
					break;
				default:
					break;
			}
		} catch (error) {
			console.log(error)
			this.setState({ userList: [] })
		}
	}

	sweetAlertClose = () => {
		this.setState({ alert: { show: false } }, () => this.props.emitCancelEvent(true));
	}

	onFileUpload(data) {
		let file = data.target.files[0];
		if (file != undefined) {
			Papa.parse(file, {
				// header: true,
				complete: this.setParsedFile
			});
		}
	}

	async submitInvoiceData() {
		try {
			// let finalData = [this.state.headerConfig]
			let data = {
				'invoice_settings': {
					header: this.state.headerConfig,
					body: this.state.invoiceBody
				}
			}
			this.setState({ isLoading: true })
			let response = await accountService.uploadAccountLogo(this.props.providerName, data);
			let { alert } = this.state;
			if (response && response.type === 'success') {
				alert = { show: true, type: 'success', title: 'Invoice Settings Updated succesfully' };
				this.setState({ alert, isLoading: false, isDirty: false });
			} else {
				alert = { show: true, type: 'error', title: 'Error', text: 'Error while uploading Invoice Settings! Please try again.' };
				this.setState({ isLoading: false, alert });
			}
		} catch (err) {
			let { alert } = this.state;
			alert = { show: true, type: 'error', title: 'Error', text: 'Error while uploading Invoice Settings! Please try again.' };
			this.setState({ isLoading: false, alert });
		}
	}

	setParsedFile = (results) => {
		
		let typeData = ["consultation", "pharmacy", "investigations", "others"]
		let { sheet, excel } = this.state;
		let finalSheetData = this.formatFinalSheetData(typeData, results.data);
		sheet = finalSheetData;
		excel = Papa.unparse(finalSheetData);
		this.setState({ sheet, excel, isDirty: true });

		this.checkForUnavailableAccounts(sheet);
	}

	formatFinalSheetData(typeData, data) {
		let finalData = []
		for (let i = 1; i < data.length; i++) {
			if (typeData.indexOf((data[i][0]).toLowerCase()) !== -1) {
				// data.splice(i, 1);
				finalData.push(data[i])
			}
		}
		return finalData;
	}

	checkForUnavailableAccounts(sheet) {
		if (!Array.isArray(sheet)) return;
		let header = sheet[0];
		
		// let HeaderDict = this.setMappedHeaderDict(header);
		// let excelBody = sheet.slice(1);
		// console.log(excelBody);
		this.checkForNoHeaderData(header, sheet);
	}

	onEditButtonEvent = (event) => {
		let { isEdit } = this.state;
		isEdit = !isEdit;
		this.setState({ isEdit });
	}

	checkForNoHeaderData(header, excelBody) {
		if (!header || header.length === 0) {
			return;
		}
		this.checkIfExcelBodyIsEmpty(excelBody);
	}

	checkIfExcelBodyIsEmpty(excelBody) {
		let { invoiceBody } = this.state;
		let finalBodyData = [];
		for (let i in excelBody) {
			if (!excelBody[i].length) {
				return;
			}
			let isPresent = false;
			for (let j in excelBody[i]) {
				if (excelBody[i][j]) {
					isPresent = true;
				}
			}
			if (isPresent) {
				finalBodyData.push(excelBody[i]);
			}
		}
		invoiceBody = finalBodyData;
		this.setState({ invoiceBody });
	}

	setSelectValueOnValue = (event) => {
		const { name, value } = event.target;
		this.setState({
			[name]: value
		})
	}

	onTextChangeEvent = (i, j, event) => {
		
		let { invoiceBody } = this.state;
		invoiceBody[i][j] = event.target.value;
		this.setState({ invoiceBody, isDirty: true });
	}

	onUserChangeEvent = (i, event) => {
		
		let { invoiceBody } = this.state;
		if (invoiceBody[i][5]) invoiceBody[i][5] = event.target.value
		else invoiceBody[i].push(event.target.value);
		this.setState({ invoiceBody, isDirty: true });
	}

	render() {
		const { open } = this.props;
		const { headerConfig, invoiceBody, isEdit, isDirty, isLoading, userList, invoice_type } = this.state;
		return (
			<div className={`modal fullmodal fade ${open ? 'show' : ''}`} id="timeslotmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
				aria-hidden={`${open ? true : false}`} style={{
					display: open ? 'block' : 'none', paddingRight: open ? '17px' : '', overflowX: 'hidden', overflowY: 'auto', backgroundColor: '#716e6e54'
				}}>
				<div className={`modal-dialog ${this.props.fullModal ? 'modal-lg' : ''}`} role="document" style={{ marginTop: 'calc(70px + 0px)' }}>
					<div className="modal-content bg-white">
						<div className="modal-header">
							<h5 className="modal-title" id="exampleModalLabel">{this.props.title ? this.props.title : 'Select your booking slot'}</h5>
							<button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.emitCancelEvent.bind(this, false)}>
								<span aria-hidden={true}>×</span>
							</button>
						</div>
						<div className="modal-body ">
							<section className="appointmentslot">
								<div className="row">
									<div className={`col-lg-12 col-sm-12`}>
										<div className="row">
											<div className="col-lg-6 col-sm-12">
												{/* For uploading Invoice CSV file */}
												<form method="post" action="#" id="#">

													<div className="form-group filedrag color">
														<label>{'Invoice CSV'}  <a href={`/files/invoice.csv`} download><i className="mdi mdi-arrow-down-bold-circle-outline" data-tip data-for="invoice_file" /></a></label>
														<input type="file" className="form-control" onChange={e => this.onFileUpload(e)} onClick={(e) => { e.target.value = null }}></input>
													</div>
													<ul className="pl-3 mt-2 text-small">
														<li>You can download defaultInvoice CSV from above download icon i.e., <i className="mdi mdi-arrow-down-bold-circle-outline" data-tip data-for="invoice_file" />.</li>
														<li>Supported Types :<strong> Consultation, Pharmacy, Investigations, Others</strong>.</li>
														<li>File size to be less than 15MB</li>
														<li>Document type can only be CSV</li>
													</ul>
												</form>
											</div>

										</div>

									</div>
									<div className={`col-lg-12 col-sm-12`}>
										{/* Invoice CSV table*/}
										<div className="row">
											<div className={`col-lg-12 col-sm-12`} style={{ display: 'flex' }}>
												<p className="m-1" style={{ flex: 1 }}>Invoice Data</p>
												<p className="m-1" onClick={this.onEditButtonEvent.bind(this)}><i className={`mdi ${isEdit ? 'mdi-eye' : 'mdi-table-edit'}`} data-tip data-for="invoice_edit" /></p>
											</div>
											<div className="col-lg-6 col-sm-12 ">
												<div className="form-group  mb-3">
													<label>Type*</label>
													<select className="form-control " name="invoice_type" value={invoice_type} onChange={this.setSelectValueOnValue.bind(this)}>
														{/*  ["consultation", "pharmacy", "investigations", "others"] */}
														<option value='' disabled>Select Type</option>
														<option value='consultation'>Consultation</option>
														<option value='pharmacy'>Pharmacy</option>
														<option value='investigations'>Investigations</option>
														<option value='others'>Others</option>
													</select>
												</div>
											</div>
											<div className={`col-lg-12 col-sm-12`}>
												<div className="table-responsive">
													<table className="table table-hover table-sm">
														<thead>
															<tr style={{ textTransform: "capitalize" }}>
																{headerConfig.map((indHeader, i) =>
																	<th key={i}>{indHeader}</th>
																)}
															</tr>
														</thead>
														<tbody>
															{!!invoiceBody ? (
																<>
																	{invoiceBody.map((indInvoiceObj, i) =>
																		<tr key={i}>
																			{indInvoiceObj.map((indItem, j) =>
																				j === 5 ? <></> :
																					<>
																						{isEdit ? (
																							<td key={j}>
																								<input type="text"
																									value={indItem}
																									onChange={this.onTextChangeEvent.bind(this, i, j)} className="form-control" id={`exampleInputUsername ${i, j}`} />
																							</td>
																						) : (
																								<td key={j}>{indItem}</td>
																							)}
																					</>
																			)}
																			{indInvoiceObj[4] === 'Y' && <>
																				{isEdit ? (
																					<td key={i} style={{ width: '100px' }}>
																						<select value={indInvoiceObj[5] || 'NIL'} onChange={this.onUserChangeEvent.bind(this, i)}
																							className="form-control" id={`exampleInputUsername ${i}`}>
																							<option value={'NIL'} disabled>None</option>
																							{userList && userList.map(user => <option value={user.doctor_id}>{user.doctor_name}</option>)}
																						</select>
																					</td>
																				) : (
																						<td style={{ width: '100px' }} key={i}>{indInvoiceObj.length === 6 ? indInvoiceObj[5] : 'Not selected'}</td>
																					)}
																			</>}
																		</tr>
																	)}
																</>
															) : (
																	<p>No data</p>
																)

															}
														</tbody>
													</table>
												</div>
											</div>

										</div>

									</div>
								</div>

							</section>
						</div>
						{isLoading &&
							<div className="clinytic-line-loader"></div>
						}
						<div className="modal-footer">
							<button type="submit" className="btn btn-success mr-2 btn-rounded" disabled={!isDirty || isLoading} onClick={this.submitInvoiceData.bind(this)}>Submit</button>
							<button type="button" className="btn btn-light" data-dismiss="modal" onClick={this.props.emitCancelEvent.bind(this, false)}>Cancel</button></div>
					</div>
				</div>
				{
					this.state.alert.show &&
					<SweetAlertComponent show={this.state.alert.show} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.sweetAlertClose}></SweetAlertComponent>
				}
				<ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
			</div >
		)
	}
}

export default InvoiceConfigComponent;

