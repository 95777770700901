import React from "react";
// component imports
import ColumnViewComponent from '../Column/ColumnViewComponent';

class ChildrenViewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            colErrorArray: []
        }
    }

    componentDidMount() {
        try {
            if (this.props.indChildrenData && this.props.indChildrenData.config &&
                this.props.indChildrenData.config.displaycondition && this.props.indChildrenData.config.displaycondition.length !== 0) {
                this.props.setConditionalHide({ class: this.props.indChildrenData.class, displaycondition: this.props.indChildrenData.config.displaycondition, childIndex: this.props.childIndex });
            }
        } catch (err) {
            console.log(err);
        }
    }

    componentDidUpdate(prevProps) {
        try {
            // reset config if childrow position changes
            if (this.props.indChildrenData && prevProps.indChildrenData && this.props.indChildrenData['rowId'] !== prevProps.indChildrenData['rowId'] && prevProps.indChildrenData.config &&
                prevProps.indChildrenData.config.displaycondition && prevProps.indChildrenData.config.displaycondition.length !== 0) {
                this.props.unsetConditionalHide({ childIndex: this.props.childIndex });
            }

            if (this.props.indChildrenData && this.props.indChildrenData.config && (this.props.indChildrenData.config !== prevProps.indChildrenData.config) &&
                this.props.indChildrenData.config.displaycondition && this.props.indChildrenData.config.displaycondition.length !== 0 &&
                this.props.indChildrenData.config.displaycondition !== prevProps.indChildrenData.config.displaycondition) {
                if (this.props.indChildrenData['rowId'] === prevProps.indChildrenData['rowId'] && (this.props.indChildrenData.config.displaycondition[0].source === '' || this.props.indChildrenData.config.displaycondition[0].value === '')) {
                    this.props.unsetConditionalHide({ childIndex: this.props.childIndex });
                }
                else {
                    this.props.setConditionalHide({ class: this.props.indChildrenData.class, displaycondition: this.props.indChildrenData.config.displaycondition, childIndex: this.props.childIndex });
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    getEmitedTextboxValue = (index, event) => {
        this.props.emitTextboxValue(index, event)
    }
    getEmitedChildTextboxValue = (index, event) => {
        this.props.emitChildTextboxValue(index, event)
    }

    getEmitedFocusEvent = (index, event) => {
        this.props.emitFocusEvent(index, event)
    }

    getEmitedChildAddNewRowEvent = (index, event) => {
        this.props.emitChildAddNewRowEvent(index, event)
    }

    getEmitedChildDeleteRowObjectData = (index, event) => {
        this.props.emitChildDeleteRowObjectData(index, event)
    }

    setConfigForFormula = (e) => {
        this.props.setConfigForFormula({ ...e, childIndex: this.props.childIndex })
    }

    setErrorForColumn = (colIndex, errorStatus) => {
        let { colErrorArray } = this.state;
        let index = colErrorArray.indexOf(colIndex);
        if (index !== -1 && !errorStatus) {
            colErrorArray.splice(index, 1);
        } else if (index === -1 && errorStatus) {
            colErrorArray.push(colIndex);
        }
        this.setState({ colErrorArray }, () => this.props.emitErrorStatus(colErrorArray.length ? true : false));
    }

    getEmitedLoadOptionsEvent = (index, event, callback) => {
        this.props.emitChildLoadOptionsEvent(index, event, callback)
    }

    getEmitedTypeAheadSuggestionObj=(event)=>{
        this.props.emitTypeAheadSuggestionObj(event);
    }

    // shouldComponentUpdate(nextProps, nextState) {
    //     if (nextProps.indChildrenData && nextProps.indChildrenData.config) {
    //         return true;
    //     }

    //     if (nextProps.fieldConfig) {
    //         // if (nextProps.fieldConfig.childIndex === "" || nextProps.fieldConfig.childIndex === nextProps.childIndex) {
    //         //     return true;
    //         // }
    //         // return false;
    //         return true;
    //     }
    //     return true;
    // }

    render() {
        
        const { indChildrenData } = this.props;
        let show = !indChildrenData.class || indChildrenData.class !== 'deferRendering hide';
        return show && <React.Fragment>
            {indChildrenData.type === "group" ? (
                <div className="row">
                    <div className="col-12">
                        {indChildrenData.columns && indChildrenData.columns.map((indColumnData, index) => {
                            return <React.Fragment key={index}>
                                <ColumnViewComponent
                                    showType={this.props.showType}
                                    checkForm={this.props.checkForm}
                                    fieldConfig={this.props.fieldConfig}
                                    emitErrorStatus={this.setErrorForColumn.bind(this, index)}
                                    colIndex={index}
                                    rowType={indChildrenData.type}
                                    rowId={indChildrenData.rowId}
                                    rowIndex={indChildrenData.index}
                                    indColumnData={indColumnData}
                                    setConfigForFormula={this.setConfigForFormula.bind(this)}
                                    emitFocusEvent={this.getEmitedFocusEvent.bind(this, index)}
                                    emitTypeAheadSuggestionObj={this.getEmitedTypeAheadSuggestionObj.bind(this)}
                                    emitTextboxValue={this.getEmitedChildTextboxValue.bind(this, index)}
                                    emitAddNewRowEvent={this.getEmitedChildAddNewRowEvent.bind(this, index)}
                                    emitLoadOptionsEvent={this.getEmitedLoadOptionsEvent.bind(this, index)}
                                    emitDeleteRowObjectData={this.getEmitedChildDeleteRowObjectData.bind(this, index)}
                                >
                                </ColumnViewComponent>
                            </React.Fragment>
                        })}
                    </div>
                </div>
            ) : (
                    <>
                        {indChildrenData.columns && indChildrenData.columns.map((indColumnData, index) => {
                            return <React.Fragment key={index}>
                                <ColumnViewComponent
                                    showType={this.props.showType}
                                    checkForm={this.props.checkForm}
                                    emitErrorStatus={this.setErrorForColumn.bind(this, index)}
                                    colIndex={index}
                                    fieldConfig={this.props.fieldConfig}
                                    setConfigForFormula={this.setConfigForFormula.bind(this)}
                                    rowType={indChildrenData.type}
                                    rowId={indChildrenData.rowId}
                                    rowIndex={indChildrenData.index}
                                    indColumnData={indColumnData}
                                    emitFocusEvent={this.getEmitedFocusEvent.bind(this, index)}
                                    emitTypeAheadSuggestionObj={this.getEmitedTypeAheadSuggestionObj.bind(this)}
                                    emitTextboxValue={this.getEmitedChildTextboxValue.bind(this, index)}
                                    emitAddNewRowEvent={this.getEmitedChildAddNewRowEvent.bind(this, index)}
                                    emitLoadOptionsEvent={this.getEmitedLoadOptionsEvent.bind(this, index)}
                                    emitDeleteRowObjectData={this.getEmitedChildDeleteRowObjectData.bind(this, index)}
                                >
                                </ColumnViewComponent>
                            </React.Fragment>
                        })}
                        <div className="col-1" style={{ padding: 0, display: (indChildrenData.ismultiple) ? '' : 'none' }}>
                            <div className="btn-group plus-group" role="group"
                                aria-label="Basic example">

                                {this.props.showDelete && <button type="button" className="btn btn-outline-danger btn-rounded" onClick={this.getEmitedChildDeleteRowObjectData.bind(this)}><i
                                    className="mdi mdi-minus " ></i></button>}
                                <button type="button" className="btn btn-outline-success btn-rounded" onClick={this.getEmitedChildAddNewRowEvent.bind(this)}><i
                                    className="mdi mdi-plus "></i></button>
                            </div>
                        </div>
                    </>
                )}
        </React.Fragment>
            ;

    }
}

export default ChildrenViewComponent;

