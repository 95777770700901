import React, { useEffect, useState } from "react";
// component imports
import ColumnViewComponent from './Column/ColumnViewComponent';
import ChildrenViewComponent from './Children/ChildrenViewComponent';
import Row from "../../../FormBuilder/Views/Schema/Row";

class RowViewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDirty: false,
            colErrorArray: [],
            childErrorArray: []
        }
        if (props.indRowData && props.indRowData.config &&
            props.indRowData.config.displaycondition && props.indRowData.config.displaycondition.length !== 0) {
            props.setConditionalHide({ class: props.indRowData.class, displaycondition: props.indRowData.config.displaycondition, rowIndex: props.rowIndex });
        }
    }

    componentDidUpdate(prevProps) {
        // reset config if row position changes
        if (this.props.indRowData && this.props.indRowData['rowId'] !== prevProps.indRowData['rowId'] && prevProps.indRowData.config &&
            prevProps.indRowData.config.displaycondition && prevProps.indRowData.config.displaycondition.length !== 0) {
            this.props.unsetConditionalHide({ rowIndex: this.props.rowIndex });
        }

        // set/unset config
        if (this.props.indRowData && this.props.indRowData.config && (this.props.indRowData.config !== prevProps.indRowData.config) &&
            this.props.indRowData.config.displaycondition && this.props.indRowData.config.displaycondition.length !== 0 &&
            this.props.indRowData.config.displaycondition !== prevProps.indRowData.config.displaycondition) {
            if (this.props.indRowData['rowId'] === prevProps.indRowData['rowId'] && (!this.props.indRowData.config.displaycondition[0].source === '' || this.props.indRowData.config.displaycondition[0].value === '')) {
                this.props.unsetConditionalHide({ rowIndex: this.props.rowIndex });
            } else {
                this.props.setConditionalHide({ class: this.props.indRowData.class, displaycondition: this.props.indRowData.config.displaycondition, rowIndex: this.props.rowIndex });
            }
        }
    }

    getEmitedChildTextboxValue = (childIndex, columnIndex, event) => {
        this.props.emitChildTextboxValue(childIndex, columnIndex, event);
    }

    getEmitedChildFocusEvent = (childIndex, columnIndex, event) => {
        //console.log(childIndex, columnIndex, event)
    }

    getEmitedTextboxValue = (index, isMultiple, event) => {
        if (!this.state.isDirty && isMultiple) {
            this.setState({ isDirty: true }, () => {
                if (event.name === "name") {
                    this.getEmitedAddNewRowEvent(this.props.rowIndex, event);
                }
                this.props.emitTextboxValue(index, event)
            });
        } else {
            this.props.emitTextboxValue(index, event);
        }
    }
    getEmitedTypeAheadSuggestionObj = (event) => {
        this.props.emitTypeAheadSuggestionObj(event);
    }

    getEmitedFocusEvent = (index, event) => {
        this.props.emitFocusEvent(index, event)
    }

    getEmitedChildAddNewRowEvent = (childIndex, columnIndex, event) => {
        this.props.emitChildAddNewRowEvent(childIndex, columnIndex, event)
    }

    getEmitedAddNewRowEvent = (index, event) => {
        this.props.emitAddNewRowEvent(index, event)
    }

    getEmitedCloneRowEvent = (index, event) => {
        this.props.emitCloneRowEvent(index, event);
    }

    getEmitedChildDeleteRowObjectData = (childIndex, columnIndex, event) => {
        this.props.emitChildDeleteRowObjectData(childIndex, columnIndex, event)
    }

    getEmitedDeleteRowObjectData = (index, event) => {
        this.props.emitDeleteRowObjectData(index, event)
    }

    shouldComponentUpdate(nextProps, nextState) {
        //console.log("nextProps",nextProps);
        if( JSON.stringify(nextProps.indRowData).indexOf('"nextAppointmentCalc":"yes"') > -1 ) {
            return true;
        }   
        if (nextProps.checkForm) {
            return true;
        }
        if (nextProps.collapse) {
            return false;
        }
        // if (!nextProps.isView) {
        if (nextProps.fieldConfig) {
            if (nextProps.collapse) {
                return false;
            }
            if (nextProps.fieldConfig.type === "group" && (nextProps.fieldConfig.sectionIndex === nextProps.sectionIndex)) {
                return true;
            } else if (nextProps.fieldConfig.type === "row" && (nextProps.fieldConfig.sectionIndex === nextProps.sectionIndex)) {
                return true;
            }
            return false;
        }
        return true;
        // }
        // return true;
    }

    setConfigForFormula = (e) => {
        this.props.setConfigForFormula({ ...e, rowIndex: this.props.rowIndex })
    }

    setConditionalHide = (e) => {
        this.props.setConditionalHide({ ...e, rowIndex: this.props.rowIndex })
    }

    unsetConditionalHide = (e) => {
        this.props.unsetConditionalHide({ ...e, rowIndex: this.props.rowIndex })
    }

    getEmitedLoadOptionsEvent = (index, event, callback) => {
        this.props.emitLoadOptionsEvent(index, event, callback)
    }

    getEmitedChildLoadOptionsEvent = (childIndex, columnIndex, event, callback) => {
        this.props.emitChildLoadOptionsEvent(childIndex, columnIndex, event, callback);
    }

    setErrorForChild = (childIndex, errorStatus) => {
        let { childErrorArray } = this.state;
        let index = childErrorArray.indexOf(childIndex);
        if (index !== -1 && !errorStatus) {
            childErrorArray.splice(index, 1);
        } else if (index === -1 && errorStatus) {
            childErrorArray.push(childIndex);
        }
        this.setState({ childErrorArray }, () => this.props.emitErrorStatus(childErrorArray.length ? true : false));
    }

    setErrorForColumn = (colIndex, errorStatus) => {
        let { colErrorArray } = this.state;
        let index = colErrorArray.indexOf(colIndex);
        if (index !== -1 && !errorStatus) {
            colErrorArray.splice(index, 1);
        } else if (index === -1 && errorStatus) {
            colErrorArray.push(colIndex);
        }
        this.setState({ colErrorArray }, () => this.props.emitErrorStatus(colErrorArray.length ? true : false));
    }

    render() {
       
        const { indRowData } = this.props;
        
        let show = !indRowData.class || indRowData.class !== 'deferRendering hide';
        return show &&
            <RowViewParent isEditable={this.props.isEditable} rowProps={this.props.rowProps} row={this.props.indRowData} idx={this.props.rowIndex} showType={this.props.showType}>
                {indRowData.type === "group" ? (
                    <div className="card-body col-lg-12">
                        <div className=" align-items-center prescription-list radius-0 mb-2 pb-1 pt-0 border bg-light">
                            <div className="card-title border-bottom mt-1 pb-2 mb-3 d-flex justify-content-between">
                                <div className="pt-2 text-success">{indRowData.name}</div>
                                <div>
                                    {indRowData.cloningAllowed &&
                                        <>
                                            {this.props.rowIndex > 0 && <button
                                                className="btn btn-outline-danger btn-rounded btn-icon mr-2 btn-rounded" onClick={this.getEmitedDeleteRowObjectData.bind(this, this.props.rowIndex)}>
                                                <i className="mdi mdi-delete"></i>
                                            </button>}
                                            <button
                                                className="btn btn-outline-success btn-icon btn-rounded mr-2" onClick={this.getEmitedAddNewRowEvent.bind(this, this.props.rowIndex)}>
                                                <i className="mdi mdi-plus"></i>
                                            </button>
                                            <button
                                                className="btn btn-outline-success btn-icon btn-rounded" onClick={this.getEmitedCloneRowEvent.bind(this, this.props.rowIndex)}>
                                                <i className="mdi mdi-content-copy"></i>
                                            </button>
                                        </>
                                    }

                                </div>
                            </div>
                            <div >

                                <div className="row">
                                    {indRowData.children && indRowData.children.map((indChildrenData, childIndex) => {
                                        return <div key={childIndex} className={`row form-group col-lg-${indChildrenData.spaceNeeded ? indChildrenData.spaceNeeded : '12'}`} style={{ display: indChildrenData.class && indChildrenData.class === "deferRendering hide" ? 'none' : '' }}>
                                            <React.Fragment key={childIndex}>
                                                <ChildrenViewComponent
                                                    viewType={this.props.viewType}
                                                    rowType={indRowData.type}
                                                    showType={this.props.showType}
                                                    rowId={indRowData.rowId}
                                                    rowIndex={this.props.rowIndex}
                                                    childIndex={childIndex}
                                                    fieldConfig={this.props.fieldConfig}
                                                    isView={this.props.isView}
                                                    indChildrenData={indChildrenData}
                                                    showDelete={indChildrenData.index !== 0}

                                                    setConditionalHide={this.setConditionalHide.bind(this)}
                                                    unsetConditionalHide={this.unsetConditionalHide.bind(this)}
                                                    emitFocusEvent={this.getEmitedChildFocusEvent.bind(this, childIndex)}
                                                    emitChildTextboxValue={this.getEmitedChildTextboxValue.bind(this, childIndex)}
                                                    emitChildLoadOptionsEvent={this.getEmitedChildLoadOptionsEvent.bind(this, childIndex)}
                                                    emitChildAddNewRowEvent={this.getEmitedChildAddNewRowEvent.bind(this, childIndex)}
                                                    emitChildDeleteRowObjectData={this.getEmitedChildDeleteRowObjectData.bind(this, childIndex)}
                                                    setConfigForFormula={this.setConfigForFormula.bind(this)}
                                                    checkForm={this.props.checkForm}
                                                    emitErrorStatus={this.setErrorForChild.bind(this, childIndex)}
                                                >
                                                </ChildrenViewComponent>
                                            </React.Fragment></div>
                                    })}</div>
                            </div>

                        </div>
                    </div>
                ) : (
                        <>
                            <div className={`col-lg-${indRowData.spaceNeeded ? indRowData.spaceNeeded : '12'}`}>
                                <div className={!this.props.hideShowOff ? `row  align-items-center prescription-list  radius-0 mb-2 pb-1 pt-0 ${this.props.showType === "compactTextbox" ? 'no-gutters' : ''}` : 'row no-gutters'}>
                                    {/* no-gutters compactTextbox*/}
                                    {this.props.hideShowOff &&
                                        <div className="col-sm col-lg-2 p-1 pt-3">{this.props.title} </div>
                                    }
                                    {this.props.showType === 'xyTable' &&
                                        <div className="col p-1 pt-3" style={{ textTransform: 'capitalize' }}><strong>{indRowData.label_x}</strong> </div>
                                    }
                                    {/* {this.props.ordered && <div className={`badge badge-pill badge-success badge-rounded`}>{this.props.rowIndex + 1}</div>} */}
                                    {indRowData.columns && indRowData.columns.map((indColumnData, index) => {
                                        return <React.Fragment key={index}>
                                            <ColumnViewComponent
                                                viewType={this.props.viewType}
                                                fieldConfig={this.props.fieldConfig}
                                                isView={this.props.isView}
                                                colIndex={index}
                                                checkForm={this.props.checkForm}
                                                emitErrorStatus={this.setErrorForColumn.bind(this, index)}
                                                rowType={indRowData.type}
                                                showType={this.props.showType}
                                                rowId={indRowData.rowId}
                                                rowIndex={this.props.rowIndex}
                                                indColumnData={indColumnData}
                                                setConfigForFormula={this.setConfigForFormula.bind(this)}
                                                emitFocusEvent={this.getEmitedFocusEvent.bind(this, index)}
                                                emitTypeAheadSuggestionObj={this.getEmitedTypeAheadSuggestionObj.bind(this)}
                                                emitTextboxValue={this.getEmitedTextboxValue.bind(this, index, indRowData.ismultiple)}
                                                emitAddNewRowEvent={this.getEmitedAddNewRowEvent.bind(this, index)}
                                                emitLoadOptionsEvent={this.getEmitedLoadOptionsEvent.bind(this, index)}
                                                emitDeleteRowObjectData={this.getEmitedDeleteRowObjectData.bind(this, index)}
                                            >
                                            </ColumnViewComponent>
                                        </React.Fragment>
                                    })}
                                    <div className="col-1" style={{ padding: 0, display: (indRowData.ismultiple) ? '' : 'none' }}>

                                        <div className="btn-group plus-group" role="group"
                                            aria-label="Basic example">
                                            {/* this.props.rowIndex > 0 ||  */}
                                            {(indRowData.index !== 0) && <button type="button" className="btn btn-outline-danger btn-rounded" style={{display:indRowData.index===0?'none':''}} onClick={this.getEmitedDeleteRowObjectData.bind(this, this.props.rowIndex)}><i
                                                className="mdi mdi-minus " ></i></button>}
                                            <button type="button" className="btn btn-outline-success btn-rounded" onClick={this.getEmitedAddNewRowEvent.bind(this, this.props.rowIndex)}><i
                                                className="mdi mdi-plus "></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                }
            </RowViewParent>
            ;
    }
}

export default RowViewComponent;

export function RowViewParent({ isEditable, children, row, idx, rowProps, showType }) {
    const [highlight, setHighlight] = useState(false)
    const [isRowEmpty, setRowEmpty] = useState((row.type === 'group' && row.children && row.children.length === 1 && row.children[0].columns && row.children[0].columns.length === 1 && !row.children[0].columns[0].hasOwnProperty('type'))
        || (row.type === 'row' && row.columns && row.columns.length === 1 && !row.columns[0].hasOwnProperty('type')));
    useEffect(() => {
        setRowEmpty((row.type === 'group' && row.children && row.children.length === 1 && row.children[0].columns && row.children[0].columns.length === 1 && !row.children[0].columns[0].hasOwnProperty('type'))
            || (row.type === 'row' && row.columns && row.columns.length === 1 && !row.columns[0].hasOwnProperty('type')));
    }, [row.columns])
    const [hideView, setHideView] = useState(isRowEmpty ? true : false);
    return !isEditable ? <>{children}</> : <div className={highlight ? 'highlight' : ''} onMouseEnter={() => setHighlight(true)} onMouseLeave={() => setHighlight(false)}>
        {(highlight || hideView || isRowEmpty) && <Row key={row['rowId']}
            currentRowId={row['rowId']}
            row={row}
            showType={showType}
            isChildRow={false}
            serialNo={idx + 1}
            isHoverView={true}
            expanded={hideView}
            hideView={(e) => setHideView(e)}
            {...rowProps}
        />}
        {!hideView && <div>{children}</div>}
    </div>
}
