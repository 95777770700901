import request from '../Api/request';
import userAuth from '../Authentication/authActions';
import Utils from '../Shared/utils/utils';

function formatQueryParams(queryString) {
    if (queryString.indexOf('&') !== -1) {
        let paramArray = queryString.split('&');
        let data = ''
        for (let index in paramArray) {
            if (!paramArray[index]) {
                continue;
            }
            data = data + "&" + paramArray[index];
        }

        return data;
        // return paramArray[1] !== "" ? `${paramArray[0]}&${paramArray[1]}` : `${paramArray[0]}`
    }
    return queryString;
}

function getPatientListData(queryParams, limit, lastEvaluatedKey) {
    let queryString = ""
    if (queryParams && Object.keys(queryParams).length !== 0) {
        queryString = `${queryParams.selectedFilter}=${queryParams.searchText}`;
    }
    if (lastEvaluatedKey) {
        queryString = queryString.concat("&last_evaluatedKey=" + encodeURI(JSON.stringify(lastEvaluatedKey)))
    }
    let formatedQueryParams = formatQueryParams((queryString));
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPatientApiStageUrl();

    if (!formatedQueryParams) {
        return request({
            baseURL: api,
            url:  "/patients?limit=10",
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    } else {
        return request({
            baseURL: api,
            url:"/patients?limit=10" + formatedQueryParams,
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    }
}

function createNewPatient(data) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPatientApiStageUrl();

    return request({
        baseURL: api,
        url:  "/patient",
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getPatientById(id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPatientApiStageUrl();

    return request({
        baseURL: api,
        url:  "/patient/" + id,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function deletePatientById(id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPatientApiStageUrl();

    return request({
        baseURL: api,
        url:  "/patient/" + id,
        method: 'delete',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function editExistingPatient(data, patient) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPatientApiStageUrl();

    return request({
        baseURL: api,
        url:  "/patient/" + patient.id,
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getPatientsDataBySearchParams(searchText,name='name') {
    if(name === "patient_hid"){
        name = "uhid"
    }
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPatientApiStageUrl();

    return request({
        baseURL: api,
        url:  "/patients/search?term=" + searchText + "&get_data_by="+name,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getPatientApiStageUrl() {
    let apiStage = sessionStorage.getItem('apiStage');
    if (apiStage === "prod") {
        return Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/patient` :`https://emrapi.hlthclub.in/patient`;
    } else {
        return `https://betaemrapi.hlthclub.in/patient`
    }
}

function getDoctors() {
    return request({
        url: "/doctors",
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function verifyOTP(otp, mobile) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPatientApiStageUrl();
    let data = {
        mobile, otp
    };
    return request({
        baseURL: api,
        url:  "/patients/vrfyotp",
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}
function generateOTP(mobile, email) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPatientApiStageUrl();
    let data = { mobile, email };
    return request({
        baseURL: api,
        url: "/patients/getotp",
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}
function getMessageTemplates(type) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPatientApiStageUrl();
    return request({
        baseURL: api,
        url: "/patients/doctor/messages?template_type=" + type,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}
function triggerSms(smsData) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPatientApiStageUrl();
    return request({
        baseURL: api,
        url:  "/patients/requestsms",
        method: 'post',
        data: smsData,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

const patientService = {
    getPatientListData,
    getPatientById,
    createNewPatient,
    editExistingPatient,
    deletePatientById,
    getDoctors,
    getPatientsDataBySearchParams,
    generateOTP,
    verifyOTP,
    triggerSms,
    getMessageTemplates
}

export default patientService;