import React from "react";

import SweetAlertComponent from '../Alert/SweetAlertComponent';
import { ToastContainer, toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import prescriptionService from '../../Services/prescription.service';

import TranslationList from './TranslationList';
import TranslationForm from './TranslationForm';

class TranslationComponent extends React.Component {
    constructor(props) {
        super(props);
        let accountInfo = sessionStorage.getItem('accountInfo') ? JSON.parse(sessionStorage.getItem('accountInfo')) : props.details ? props.details : '';
        this.state = {
            isLoading: false,
            alert: { show: false, type: '', title: '', text: '' },
            activeTab: 'translation-list',
            isDirty: false,
            isSaveLoading: false,
            translationData: null,
            isEditMode: false,
            translationKey: '',
            editDataConfig: {},
            accountInfo: accountInfo,
            selCategory: '',
            category: '',
            english: '',
            nepali: '',
            kannada: '',
            telugu: '',
            malayalam: '',
            gujarati: '',
            marathi: '',
            tamil: '',
            bengali: '',
            urdu: '',
            punjabi: '',
            oriya: '',
            arabic: '',
        }
    }

    componentDidMount() {
        this.setState({ isLoading: true }, () => this.fetchTranslationData());
    }

    // https://pesyxazr1d.execute-api.ap-south-1.amazonaws.com/dev/upload/translation

    sweetAlertClose = () => {
        this.setState({ alert: { show: false } });
        // , () => this.props.emitCancelEvent(true)
    }

    onCloseClickEvent = () => {
        this.props.emitCancelEvent(false)
    }

    notify(message) {
        toast.error(message, { position: toast.POSITION.TOP_RIGHT, containerId: 'A' });
    }

    onTabClicked = (event) => {
        let { activeTab } = this.state;
        activeTab = event;
        this.setState({ activeTab });
    }

    getEmitedDirtyEvent = (event) => {
        let { isDirty } = this.state;
        isDirty = true;
        this.setState({ isDirty });
    }

    getEmitChangeDataOnEdit = (name, value) => {
        let { editDataConfig, translationKey, translationData } = this.state;
        editDataConfig[name] = value;
        // translationData[0].translation[translationKey][name] = value;
        this.setState({ editDataConfig });
    }

    getEmitedEventOnEditCancel = (event) => {
        let { isEditMode, translationKey, editDataConfig } = this.state;
        isEditMode = false;
        translationKey = '';
        editDataConfig = ''
        this.setState({ isEditMode, translationKey, editDataConfig })
    }

    getEmitCategorySelectEvent = (event) => {
        let { selCategory } = this.state;
        selCategory = event;
        this.setState({ selCategory }, () => this.fetchTranslationData());
    }

    getEmitedEditEvent = (event) => {
        
        let { isEditMode, translationKey, editDataConfig, translationData } = this.state;
        isEditMode = true;
        editDataConfig = JSON.parse(JSON.stringify(translationData[0].translation[event]))
        translationKey = event;
        this.setState({ isEditMode, translationKey, editDataConfig });
    }

    getEmitedEventOnEditDataSave = (event) => {
        let { editDataConfig, translationData } = this.state;
        let data = {
            "translation_id": translationData[0].id,
            "records": [editDataConfig],
            "lang": "english",
            "category": translationData[0].category
        }
        this.setState({ isLoading: true }, () => this.saveTranslationContent(data, 'edit'))
    }

    clearFormData = () => {
        let { english, nepali, kannada, malayalam, gujarati, marathi, tamil, bengali, urdu, punjabi, oriya, arabic, telugu, category } = this.state;
        category = '';
        english = ''; nepali = ''; kannada = ''; malayalam = ''; gujarati = ''; marathi = ''; tamil = ''; bengali = ''; urdu = ''; punjabi = ''; oriya = ''; arabic = ''; telugu = '';
        this.setState({ english: english, nepali: nepali, kannada: kannada, malayalam: malayalam, gujarati: gujarati, marathi: marathi, tamil: tamil, bengali: bengali, urdu: urdu, punjabi: punjabi, oriya: oriya, arabic: arabic, telugu: telugu, category })
    }

    getFormData = () => {
        let { english, nepali, kannada, malayalam, gujarati, marathi, tamil, bengali, urdu, punjabi, oriya, arabic, telugu, category } = this.state;
        let data = {
            "category": category,
            "records": [
                {
                    "text": english,
                    "nepali": nepali,
                    "tamil": tamil,
                    "telugu": telugu,
                    "kannada": kannada,
                    "malayalam": malayalam,
                    "gujarati": gujarati,
                    "marathi": marathi,
                    "bengali": bengali,
                    "urdu": urdu,
                    "punjabi": punjabi,
                    "oriya": oriya,
                    "arabic": arabic,
                }],
            "lang": "english"
        }
        return data;
    }

    onTranslationSaveEvent = () => {
        let data = this.getFormData()
        if (!data) {
            return;
        }
        this.setState({ isLoading: true }, () => this.saveTranslationContent(data, ''))
    }

    onTextboxValueChange = (name, value) => {
        // const { name, value } = event.target
        this.setState({
            [name]: value
        }, () => {
            this.setState({ isDirty: true })
        })
    }


    async fetchTranslationData() {
        // getTranslationData
        try {
            let response = await prescriptionService.getTranslationData(this.props.providerName ? this.props.providerName : '', '', this.state.selCategory);
            
            let { translationData } = this.state;
            if (response.type === "success") {
                translationData = response.data;
            } else {
                translationData = [];
            }
            this.setState({ translationData, isLoading: false });
        } catch (error) {
            let { translationData } = this.state;
            translationData = [];
            this.setState({ translationData, isLoading: false });
            console.log(error)
        }
    }

    async saveTranslationContent(data, eventType) {
        try {
            let { translationData, category, isEditMode } = this.state;
            if (translationData && translationData[0] && translationData[0].translation && Object.keys(translationData[0].translation).length > 0 && !eventType) {
                if (isEditMode) {
                    for (let key in translationData[0].translation) {
                        data.records.push(translationData[0].translation[key]);
                    }
                }

            }
            let response = await prescriptionService.saveTranslationData(data, this.props.providerName)
            
            if (response.type === "success") {
                this.setState({
                    alert: {
                        show: true,
                        type: 'success',
                        text: `Translation saved successfully!!!`,
                        title: 'Success'
                    }
                });
                if (eventType) {
                    this.setState({ isLoading: true, isEditMode: false, translationKey: '', editDataConfig: '' }, () => { this.fetchTranslationData(); this.clearFormData() });

                } else {
                    this.setState({ isLoading: true, activeTab: 'translation-list' }, () => { this.fetchTranslationData(); this.clearFormData() });
                }

            } else {
                this.setState({
                    isLoading: false,
                    alert: {
                        show: true,
                        type: 'error',
                        text: "Error occored while saving translation!!!",
                        title: 'Error'
                    }
                });
                
            }
        } catch (error) {
            this.setState({
                isLoading: false,
                alert: {
                    show: true,
                    type: 'error',
                    text: "Error occored while saving translation!!!",
                    title: 'Error'
                }
            });
            console.log(error)
        }
    }

    getEmitedCategoryChangeEvent = (event) => {
        let { category } = this.state;
        category = event;
        this.setState({ category });
    }

    render() {
        const { open } = this.props;
        const { isLoading, activeTab, isDirty, translationData, isEditMode, translationKey, editDataConfig, english, nepali, kannada, malayalam, gujarati, marathi, tamil, bengali, urdu, punjabi, oriya, arabic, telugu, category, accountInfo } = this.state;
        return (
            <div className={`modal fullmodal fade ${open ? 'show' : ''}`} id="timeslotmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden={`${open ? true : false}`} style={{
                    display: open ? 'block' : 'none', paddingRight: open ? '17px' : '', overflowX: 'hidden', overflowY: 'auto', backgroundColor: '#716e6e54'
                }}>
                <div className={`modal-dialog ${this.props.fullModal ? 'modal-lg' : ''}`} role="document" style={{ marginTop: 'calc(70px + 0px)' }}>
                    <div className="modal-content bg-white">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{this.props.title ? this.props.title : 'Select your booking slot'}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.emitCancelEvent.bind(this, false)}>
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body ">
                            <div className="card-body" style={{ backgroundColor: "white", padding: 10, boxShadow: "0 0px 5px rgba(0,0,0,0.25), 0 2px 2px rgba(0,0,0,0.22)" }}>
                                <div className="nav-scroller">
                                    <ul className="nav nav-tabs appoitmenttab tickets-tab-switch" role="tablist">
                                        <li className="nav-item">
                                            <a className={`nav-link ${activeTab === "translation-list" ? 'active' : ''}`} id="refund" data-toggle="tab" href="#translation-list" role="tab" aria-controls="translation-list" aria-selected="true" onClick={this.onTabClicked.bind(this, 'translation-list')} style={{ padding: "5px 20px !important" }}>Translation</a>
                                        </li>
                                        <li className="nav-item">
                                            <a className={`nav-link ${activeTab === "add-translation" ? 'active' : ''}`} id="add-translation" data-toggle="tab" href="#add-translation" role="tab" aria-controls="add-translation" aria-selected="true" onClick={this.onTabClicked.bind(this, 'add-translation')} style={{ padding: "5px 20px !important" }}>Add New Text</a>
                                        </li>

                                    </ul>
                                </div>
                                {isLoading &&
                                    <div className="clinytic-line-loader"></div>
                                }
                                <div className="tab-content border-0 tab-content-basic p-0">
                                    <div className={`tab-pane fade ${activeTab === "translation-list" ? 'show active' : ''}`} role="tabpanel" aria-labelledby="translation-list">
                                        <TranslationList
                                            translationKey={translationKey}
                                            isEditMode={isEditMode}
                                            accountInfo={accountInfo}
                                            editDataConfig={editDataConfig}
                                            translationData={translationData}
                                            emitEventOnEditCancel={this.getEmitedEventOnEditCancel.bind(this)}
                                            emitChangeDataOnEdit={this.getEmitChangeDataOnEdit.bind(this)}
                                            emitEventOnEditDataSave={this.getEmitedEventOnEditDataSave.bind(this)}
                                            emitEditEvent={this.getEmitedEditEvent.bind(this)}
                                            emitCategorySelectEvent={this.getEmitCategorySelectEvent.bind(this)}>
                                        </TranslationList>
                                    </div>
                                    <div className={`tab-pane fade ${activeTab === "add-translation" ? 'show active' : ''}`} role="tabpanel" aria-labelledby="add-translation">
                                        <TranslationForm
                                            accountInfo={accountInfo}
                                            english={english}
                                            nepali={nepali}
                                            category={category}
                                            kannada={kannada}
                                            malayalam={malayalam}
                                            gujarati={gujarati} marathi={marathi} tamil={tamil} bengali={bengali} urdu={urdu} punjabi={punjabi} oriya={oriya} arabic={arabic} telugu={telugu}
                                            isDirty={isDirty}
                                            emitCategoryChangeEvent={this.getEmitedCategoryChangeEvent.bind(this)}
                                            emitTextboxValueChange={this.onTextboxValueChange.bind(this)}
                                            emitDirtyEvent={this.getEmitedDirtyEvent.bind(this)}
                                            emitSaveEventData={this.onTranslationSaveEvent.bind(this)}
                                            emitOnCloseClickEvent={this.onCloseClickEvent.bind(this)}>
                                        </TranslationForm>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                {
                    this.state.alert.show &&
                    <SweetAlertComponent show={this.state.alert.show} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.sweetAlertClose}></SweetAlertComponent>
                }
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
            </div >
        )
    }
}

export default TranslationComponent;

