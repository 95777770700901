import React from "react";
import UserEditViewComponent from './Edit/UserEditViewComponent';
import HeaderContainer from '../../Common/Header/HeaderContainer';
import usersService from '../../Services/users.service';
import Spinner from "../../Common/Spinner/Spinner";
// common imports
import SweetAlertComponent from '../../Common/Alert/SweetAlertComponent';
class UserEditContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            show: false,
            isFormLoading: false,
            queryData: this.props.location.search !== '' ? JSON.parse('{"' + decodeURI(this.props.location.search.substr(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : '',
            userData: null,
            doctorList: null,
            alert: {
                type: '',
                title: '',
                text: ''
            }
        }
        this.fetchDoctorList();
    }

    componentWillMount() {
        this.setState({
            type: (this.state.queryData && this.state.queryData.id) ? "edit" : "creat"
        }, () => this.checkIfIdPresentInQueryData());
    }

    checkIfIdPresentInQueryData() {
        if (this.state.queryData && this.state.queryData.id) {
            if (this.props.location && this.props.location.state && this.props.location.state.userData) {
                let { userData } = this.state;
                userData = { ...this.props.location.state.userData };
                this.setState({ userData });
            } else {
                this.fetchUserDetailById(this.state.queryData.id)
            }
        } else {
            let { userData } = this.state;
            userData = null;
            this.setState({ userData });
        }
    }

    // componentWillReceiveProps(nextProps) {

    //     if (nextProps.userData !== this.state.userData) {
    //         this.state.userData = nextProps.userData ? nextProps.userData : null;
    //     }
    //     this.state.queryData = nextProps.location.search !== '' ? JSON.parse('{"' + decodeURI(nextProps.location.search.substr(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : '';
    //     this.state.type = (this.state.queryData && this.state.queryData.id) ? "edit" : "creat";
    //     this.setState(this.state, () => this.checkIfIdPresentInQueryData());
    // }

    async fetchUserDetailById(id) {
        try {
            const editUserDataResponse = await usersService.getUserById(id)
            this.checkEditUserDataResponse(editUserDataResponse);
        } catch (error) {
            console.log(error);
        }
    }

    async fetchDoctorList() {
        try {
            const doctorResponse = await usersService.getDoctors()
            this.checkForDoctorResponse(doctorResponse);
        } catch (error) {
            console.log(error);
        }
    }

    checkForDoctorResponse(response) {
        if (!response) {
            this.onError({});
        }
        switch (response.type) {
            case 'error':
                this.onError();
                break;
            case 'success':
                let { doctorList } = this.state;
                doctorList = response.data && response.data.rows ? response.data.rows : [];
                this.setState({ doctorList });
                break;
            default:
                return
        }
    }

    checkEditUserDataResponse(response) {
        if (!response) {
            this.onError({});
        }
        switch (response.type) {
            case 'error':
                this.setState(prevState => ({
                    show: true,
                    isFormLoading: false,
                    alert: {
                        ...prevState.alert,
                        type: 'error',
                        title: 'Error',
                        text: 'Error Occures While getting User Data'
                    }
                }));
                break;
            case 'success':
                let { userData } = this.state;
                userData = response.data;
                this.setState({ userData });
                break;
            default:
                return
        }
    }
    checkForDoctorArray(doctorIdArray) {
        let updatedDoctorData = []
        if (!doctorIdArray) {
            return []
        }
        doctorIdArray.forEach(element => {
            let obj = {
                cognito_user_sub: element.value,
                name: element.label
            }
            updatedDoctorData.push(obj);
        });
        return updatedDoctorData
    }

    getEmitedFinalFormSubmit = (event) => {
        event['doctor_id'] = this.checkForDoctorArray(event['doctor_id']);
        if (this.state.type === "creat") {
            this.setState({
                isFormLoading: true
            }, () => this.actionToCreateNewUser(event));
        } else {
            const { state } = this.props.location;
            // if (state && state.userData) {
            //     const stateCopy = { ...state };
            //     delete stateCopy.userData;
            //     stateCopy['userData'] = event;
            //     this.props.history.replace({ state: stateCopy, ...this.props.history });
            // }
            this.setState({
                isFormLoading: true
            }, () => this.actionToEditUser(event));
        }
    }

    async actionToEditUser(event) {
        try {
            const editResponse = await usersService.editExistingUser(event, this.state.queryData);
            this.checkResponse(editResponse);
        } catch (error) {
            console.log(error);
            if (error.response && error.response.status && error.response.status === 400) {
                this.setState({
                    isFormLoading: false
                })
            }
        }
    }

    async actionToCreateNewUser(event) {
        try {
            const response = await usersService.createNewUser(event);
            this.checkResponse(response);
        } catch (error) {
            console.log(error);
            this.setState({
                isFormLoading: false
            })
        }
    }

    checkResponse(response) {
        if (!response) {
            this.onError({});
        }
        switch (response.type) {
            case 'success':
                this.onSuccess();
                break;
            case 'error':
            default:
                this.onError(response)
                break;
        }
    }

    onSuccess() {
        this.setState(prevState => ({
            show: true,
            isFormLoading: false,
            alert: {
                ...prevState.alert,
                type: 'success',
                title: 'Success',
                text: `Success In  ${this.state.type}ing User`
            }
        }));
    }

    onError(response) {
        this.setState(prevState => ({
            show: true,
            isFormLoading: false,
            alert: {
                ...prevState.alert,
                type: 'error',
                title: 'Error',
                text: (response && response.message) ? response.message : `Error Occures While ${this.state.type}ing User`
            }
        }));
    }

    sweetAlertClose = (event) => {
        this.setState({ show: false });
        this.redirectToUserList('/user-view')
    }

    redirectToUserList(event) {
        if (this.state.alert.type === 'error') {
            return;
        }
        this.props.history.push(event);
    }

    getEmitedBackButtonEvent = (event) => {
        this.props.history.push(event);
    }

    isContentLoading = () => {
        return !this.state.doctorList || (this.state.type === 'edit' && !this.state.userData);
    }

    render() {
        return (
            <div>
                <HeaderContainer {...this.props}></HeaderContainer>

                <div className="container-fluid page-body-wrapper">
                    <div className="main-panel">
                        <div className="content-wrapper" style={{ padding: '4.50rem 1.25rem  2.25rem  1.25rem' }}>
                            <div className="row">
                                <div className="col-12 grid-margin">
                                    {this.isContentLoading() &&
                                        <Spinner width={75} height={75}></Spinner>}
                                    {!this.isContentLoading() &&
                                        <UserEditViewComponent
                                            formType={this.state.type}
                                            isFormLoading={this.state.isFormLoading}
                                            userData={this.state.userData}
                                            doctorList={this.state.doctorList}
                                            emitFinalFormSubmit={this.getEmitedFinalFormSubmit.bind(this)}
                                            emitBackButtonEvent={this.getEmitedBackButtonEvent.bind(this)}>
                                        </UserEditViewComponent>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.show &&
                    <SweetAlertComponent show={this.state.show} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.sweetAlertClose}></SweetAlertComponent>
                }
            </div>
        );
    }
}

export default UserEditContainer;
