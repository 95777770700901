import React from "react";
// datepicker component
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DateInput extends React.PureComponent {
    // constructor(props) {
    //     super(props);
    // }

    onInputChange = (date) => {
        this.props.onInputChange({ name: this.props.inputData.name, value: date, type: this.props.inputData.type })
    }

    render() {
        return <DatePicker
            style={{ width: '100%' }}
            dateFormat="dd/MM/yy"
            className={`form-control`}
            minDate={this.props.inputData.minDateFn()}
            maxDate={this.props.inputData.maxDateFn()}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            selected={this.props.dynamicValue ? this.props.dynamicValue : ''}
            onChange={this.onInputChange.bind(this)}
        />
    }
}

export default DateInput;
