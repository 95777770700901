import React from "react";
import axios from 'axios';

class LoadRxComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            listIndex: false
        }
    }

    onRxTemplateSelect = (event, index) => {
        
        this.setState({ loading: true, listIndex: index }, () => this.getTemplateData(event.formpath));

    }

    async getTemplateData(url) {
        delete axios.defaults.headers.common["Authorization"];
        axios({
            method: 'get',
            url: url
        })
            .then((response) => {
                
                if (response && response.data) {
                    this.setState({ loading: false })
                    let formDataJson = this.isJson(response.data) ? JSON.parse(response.data) : typeof response.data === 'object' ? response.data : null;
                    
                    if (formDataJson.sections) {
                        this.props.emitSelectedRxData(formDataJson.sections);
                    }
                }
            })
            .catch((error) => {
                this.setState({ loading: false })
                console.log(error);
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    render() {
        const { rxListData } = this.props;
        return (
            <>
                {/* <ul className="list-group">
                    {!!rxListData &&
                        <>
                            {rxListData.map((indRxListData, index) =>
                                <li className="list-group-item" onClick={this.onRxTemplateSelect.bind(this, indRxListData)}>{indRxListData.filename ? indRxListData.filename : `Template ${index + 1}`}</li>
                            )}
                        </>
                    }
                </ul> */}
                <div>
                    <div>
                        {!!rxListData &&
                            <>
                                {rxListData.map((indRxListData, index) =>
                                    <>
                                        <div className="row m-1 pb-1" key={index} style={{ borderBottom: '1px solid #e2dfdf' }}>
                                            <div className="col-8 p-0">
                                                <div className="d-flex justify-content-between">
                                                    <small>{indRxListData.filename ? indRxListData.filename : `Template ${index + 1}`}</small>
                                                </div>
                                            </div>
                                            <div className="col-4">
                                                <button className="btn btn-sm btn-success radius-0" type="button" style={{ width: '100%' }} onClick={this.onRxTemplateSelect.bind(this, indRxListData, index)}>Select</button>
                                            </div>
                                        </div>
                                        <div className="clinytic-line-loader" style={{ display: (this.state.loading && this.state.listIndex === index) ? '' : 'none' }}></div>
                                    </>
                                )}
                            </>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default LoadRxComponent;
