import request from '../Api/request';
import userAuth from '../Authentication/authActions';
import Utils from '../Shared/utils/utils';
import {
    appVersion
} from '../Constants/AppVersion';

function triggerSms(status, data) {
    let api = getApiStageDataUrl_Communication();
    return request({
        baseURL: api,
        url: "/appointment/trigger_sms/" + status,
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

// {{patient url}}/filter-invoice/patient/1611919408d8d03a6c?appointment_id=teleheal:2021-01-29$appt:56fb1df3-f301-49a0-97a9-cb28686efb1d:2021-01-29T11:23:30:406cc87&account_id=teleheal

function getAppointmentList(date, queryParams, doctor_id = null) {

    let isStaff = sessionStorage.getItem('userRole').indexOf('staff') !== -1;
    let api = getApiStageDataUrl();
    if (doctor_id) {
        let url = "/appointments?appointment_date=" + date + "&doctor_id=" + doctor_id;
        if (queryParams && Object.keys(queryParams).length > 0) {
            let queryString = `${queryParams.selectedFilter}=${queryParams.searchText}`;
            let formatedQueryParams = formatQueryParams((queryString).toLowerCase());
            url = url + "?" + formatedQueryParams;
        }
        return request({
            baseURL: api,
            url: url,
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    } else {
        // return userAuth.getAuthUserId().then((userId) => {
        let url = "";
        if (isStaff) {
            url = "/appointments?appointment_date=" + date;
        } else {
            url = "/appointments/doctor_id/" + sessionStorage.getItem('userId') + "/appointment_date/" + date;
        }
        if (queryParams && Object.keys(queryParams).length > 0) {
            let queryString = `${queryParams.selectedFilter}=${queryParams.searchText}`;
            let formatedQueryParams = formatQueryParams((queryString).toLowerCase());
            url = url + "?" + formatedQueryParams;
        }

        return request({
            baseURL: api,
            url: url,
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
        // });
    }
}

function getOnlineAppointmentList(date) {
    let api = getApiStageDataUrl();
    let url = "";
    url = "/appointments/doctor_id/" + sessionStorage.getItem('userId') + "/appointment_date/" + date + '?appointment_type=online';
    return request({
        baseURL: api,
        url: url,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getAppointmentById(id) {
    let api = getApiStageDataUrl();
    return request({
        baseURL: api,
        url: "/appointment/" + id,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getDoctorsList(data, user) {
    return request({
        url: "/doctors",
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function formatQueryParams(queryString) {
    if (queryString.indexOf('&') !== -1) {
        let paramArray = queryString.split('&');
        return paramArray[1] !== "" ? `${paramArray[0]}&${paramArray[1]}` : `${paramArray[0]}`
    }
    return queryString;
}

function createAppointment(data) {
    let api = getApiStageDataUrl();
    return request({
        baseURL: api,
        url: "/appointment",
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getPatientSummary(patient_id) {
    let api = getApiStageDataUrl();
    return request({
        baseURL: api,
        url: "/appointments/patient_id/" + patient_id,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function fetchAppVersion() {
    let apiStage = sessionStorage.getItem('apiStage');
    apiStage = apiStage ? apiStage : window.location.hostname === 'localhost' || window.location.hostname.includes('beta') ? 'dev' : 'prod';
    let api = getApiStageDataUrl();

    return request({
        baseURL: api,
        url: "/onlineappointment/get-account-info?get_version=true",
        method: 'get'
    }, 'users');
}

function updateAppVersion() {
    let apiStage = sessionStorage.getItem('apiStage');
    let value = sessionStorage.getItem('appVersion');
    value = value ? value : appVersion.version;
    value = JSON.stringify(parseInt(value.split('.').join('')) + 1).split('').slice(0, 4).join('.');
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/admin` :`https://emrapi.hlthclub.in/admin`;
        //api = `https://emrapi.hlthclub.in/admin`;
    } else {
        api = `https://betaemrapi.hlthclub.in/admin`;
    }

    return request({
        baseURL: api,
        url: `/settings/account/prod?version=${value}`,
        method: 'put',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}


function fetchSettingsQc() {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://12vwe151nh.execute-api.ap-south-1.amazonaws.com/`;
    }
    let account_id = sessionStorage.getItem('s3AccountId');
    return request({
        baseURL: api,
        url: apiStage + "/users?role=doctor&sort=name&account_id=" + account_id,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'users');
}


function fetchSettings(value) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://12vwe151nh.execute-api.ap-south-1.amazonaws.com/`;
    }
    if (sessionStorage.getItem('userRole').indexOf("staff") !== -1) {

        if (value) {
            return request({
                baseURL: api,
                url: apiStage + "/users?role=doctor&sort=name&branch=" + value,
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + userAuth.getToken()
                }
            }, 'users');
        } else {
            return request({
                baseURL: api,
                url: apiStage + "/users?role=doctor&sort=name",
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + userAuth.getToken()
                }
            }, 'users');
        }
        //implies that the user is staff

    } else {
        // return request({
        //     url: "/user/filters",
        //     method: 'get',
        //     headers: {
        //         'Authorization': 'Bearer ' + userAuth.getToken(),
        //         // "x-platform": "web"
        //     }
        // }, 'users');
    }
}

function updateAppointment(data, user) {
    let api = getApiStageDataUrl();
    return request({
        baseURL: api,
        url: "/appointment/" + user.id,
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function editAppointment(data, id) {
    let api = getApiStageDataUrl()
    return request({
        baseURL: api,
        url: "/appointment/" + id,
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function updateAppointmentWithRescheduledData(appointmentId, data) {
    let api = getApiStageDataUrl();
    return request({
        baseURL: api,
        url: "/appointment/" + appointmentId + "/reschedule",
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function transferAppt(appointmentId, data) {
    let api = getApiStageDataUrl();
    return request({
        baseURL: api,
        url: "/appointment/" + appointmentId + "/transfer",
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getDoctorLinkedToUser() {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://12vwe151nh.execute-api.ap-south-1.amazonaws.com/`
    }
    return request({
        baseURL: api,
        url: apiStage + "/user/projections/" + sessionStorage.getItem('userId') + "?projection=doctor_id,doctor_name",
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function formatCommDate(cdate) {
    let date = (Utils.getFormatedDate(cdate)).split('-');
    return encodeURI(date[1] + '/' + date[2] + '/' + date[0][2] + date[0][3]);
}

function formatCommMonth(cdate) {
    let date = (Utils.getFormatedDate(cdate)).split('-');
    return encodeURI(Math.ceil(date[1]) + '_' + date[0]);
}

function getSmsStatusList(cdate, id) {
    let role = sessionStorage.getItem('userRole');
    let comm_month = formatCommMonth(cdate)
    let comm_date = formatCommDate(cdate)
    let doctor_id = id ? encodeURI(id) : null;
    let queryParams = `comm_date=${comm_date}&comm_month=${comm_month}`;
    if (role && role.indexOf('staff') !== -1 && doctor_id) {
        queryParams = queryParams + '&' + `doctor_id=${doctor_id}`;
    }
    let api = getApiStageDataUrl_Communication()
    return request({
        baseURL: api,
        url: `/appointment/sms/get_sms_status?${queryParams}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getApptLogs(date, account_id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://rc2k7qw4qk.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://gnunqbtpv5.execute-api.ap-south-1.amazonaws.com/`
    }
    return request({
        baseURL: api,
        url: apiStage + `/support/appointments/${account_id}?appointment_date=${date}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getIndApptLogs(row, type) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://rc2k7qw4qk.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://gnunqbtpv5.execute-api.ap-south-1.amazonaws.com/`
    }

    return request({
        baseURL: api,
        url: apiStage + `/support/logs/${row.account_id}?appointment_id=${row.id}&log_type=${type.toLowerCase()}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getApiStageDataUrl() {
    let apiStage = sessionStorage.getItem('apiStage');
    if (apiStage === "prod") {
        return Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/appointment` :`https://emrapi.hlthclub.in/appointment`;
    } else {
        return `https://betaemrapi.hlthclub.in/appointment`
    }
}

function getApiStageDataUrl_Communication() {
    let apiStage = sessionStorage.getItem('apiStage');
    if (apiStage === "prod") {
        return Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/comm` :`https://emrapi.hlthclub.in/comm`;
    } else {
        return `https://betaemrapi.hlthclub.in/comm`
    }
}

const appointmentService = {
    getAppointmentList,
    getOnlineAppointmentList,
    createAppointment,
    editAppointment,
    getAppointmentById,
    getDoctorsList,
    updateAppointment,
    getApptLogs,
    getIndApptLogs,
    fetchSettings,
    updateAppointmentWithRescheduledData,
    getDoctorLinkedToUser,
    getSmsStatusList,
    triggerSms,
    getPatientSummary,
    fetchAppVersion,
    updateAppVersion,
    transferAppt,
    fetchSettingsQc
}

export default appointmentService;