import React from "react";
import userFormData from "../../../Constants/Forms/userFormData";
import "react-datepicker/dist/react-datepicker.css";
import Utils from '../../../Shared/utils/utils';
// import usersService from '../../../Services/users.service';
import Table from "../../../Common/FormInputs/GridComponents/Table";
import Spinner from "../../../Common/Spinner/Spinner";
import { UserFormRequiredFields as RequiredFields } from '../../../Constants/RequiredFields';
// import HooksComponent from './HooksComponent';

// const $ = window["jQuery"];

class UserEditViewComponent extends React.Component {
    constructor(props) {
        super(props);
        if (userFormData && userFormData.sections && userFormData.sections[0] && userFormData.sections[0].rows && userFormData.sections[0].rows[0][2]) {
            userFormData.sections[0].rows[0][2].hidden = window.location.search && window.location.search.indexOf('id') !== -1 ? false : true;
        }

        this.state = {
            userFormData: userFormData,
            isFormLoading: this.props.isFormLoading,
            doctorList: this.props.doctorList,
            code: '+977',
            uiData: userFormData.uiData,
            formType: this.props.formType,
            localFormData: {},
            formErrors: { // put property name same as localFormData
                'email': false,
                'phone': false,
                'name': false
            }
        }
        this.prepareDynamicData();
        this.dateofbirth = React.createRef();
        this.onUserCreateFormSubmit = this.onUserCreateFormSubmit.bind(this);
    }

    prepareDynamicData() {
        let { uiData, localFormData } = this.state; // for rendering view using common component
        let docList = [];
        for (let index in this.state.doctorList) {
            docList.push({
                value: this.state.doctorList[index].cognito_user_sub,
                label: this.state.doctorList[index].name
                // key: this.state.doctorList[index].value,
                // value: this.state.doctorList[index].display_value
            });
        }
        uiData['doctors'] = docList

        if (this.props.formType === 'edit' && this.props.userData) {
            this.state.localFormData = this.autoFillForm(this.props.userData);
        } else if (this.props.formType === 'creat') {
            this.state.localFormData = this.createLocalForm();
        }
        this.setState((prevState) => {
            return {
                uiData: uiData,
                localFormData: this.state.localFormData
            }
        });
    }

    createLocalForm() {
        let localFormData = { //data with exact mapping of fields present in view
            name: { value: '', hasErrors: false },
            phone: { value: '', hasErrors: false },
            country_code: '+977',
            email: { value: '', hasErrors: false },
            role: '',
            department: '',
            env: '',
            country: 'nepal',
            state: '',
            city: '',
            zipcode: '',
            line1: '',
            line2: '',
            date_of_birth: '',
            age: '',
            gender: '',
            speciality: '',
            features: ''
        }
        return localFormData;
    }

    autoFillForm(userData) {
        let defaultEnv =  window.location.hostname.indexOf('localhost') === -1 && window.location.hostname.indexOf('beta') === -1 ? 'e:prod' : 'e:dev';
        let localFormData = { //data with exact mapping of fields present in view
            name: { value: userData['name'] ? userData['name'] : '', hasErrors: false },
            phone: { value: userData['phone'], hasErrors: false },
            country_code: userData['country_code'] ? userData['country_code'] : '+977',
            email: { value: userData['email'] ? userData['email'] : '', hasErrors: false },
            role: this.getRole(userData['role']),
            doctor_name: userData['doctor_name'],
            features: userData['features'] ? userData['features'] : '',
            doctor_id: userData['doctor_id'] ? this.formatDoctorIdToMultiSelectData(userData['doctor_id']):'',
            env: (userData && userData['env']) ? userData['env'] : defaultEnv,
            department: userData['department'],
            country: userData.address ? userData.address['country'] : '',
            state: userData.address ? userData.address['state'] : '',
            city: userData.address ? userData.address['city'] : '',
            zipcode: userData.address ? userData.address['zipcode'] : '',
            line1: userData.address ? userData.address['line1'] : '',
            line2: userData.address ? userData.address['line2'] : '',
            date_of_birth: (userData['date_of_birth'] && userData['date_of_birth'] !== "-") ? new Date(userData['date_of_birth']) : '',
            age: userData['age'],
            gender: userData['gender'],
            speciality: userData['speciality']
        }
        return localFormData;
        // custom:role: "r:staff,e:dev,f:presc,f:tmpl"
    }

    formatDoctorIdToMultiSelectData(doctorIdArray, doctorName) {
        let updatedDoctorData = []
        if (!doctorIdArray) {
            return []
        }
        if (!Array.isArray(doctorIdArray)) {

            return this.convertDoctorDataIfItsString(doctorIdArray, doctorName);
        }
        doctorIdArray.forEach(element => {
            let obj = {
                value: element.cognito_user_sub,
                label: element.name
            }
            updatedDoctorData.push(obj);
        });
        return updatedDoctorData
    }

    convertDoctorDataIfItsString(doctorId, doctorName) {
        return [{
            value: doctorId,
            label: doctorName
        }]
    }

    getRole(role) {
        let newRole = role
            .toLowerCase()
            .split(',')
            .filter(key => (key.indexOf('e:') === -1 && key.indexOf('f:') === -1))
            .map(key => {
                if (key.indexOf('r:') === -1) {
                    return 'r:' + key;
                }
                return key;
            })
            .join(',');
        return newRole;
    }

    componentWillReceiveProps(nextProps) {
        let { doctorList, formType, isFormLoading, localFormData, uiData } = this.state;
        if (nextProps.doctorList !== this.state.doctorList) {
            doctorList = nextProps.doctorList;
            let uiData = { ...this.state.uiData, doctors: '' }; // for rendering view using common component

            for (let index in doctorList) {
                uiData['doctors'].push({
                    value: this.state.doctorList[index].cognito_user_sub,
                    label: this.state.doctorList[index].name
                    // key: doctorList[index].value,
                    // value: doctorList[index].display_value
                });
            }
        }

        if (nextProps.formType !== this.state.formType) {
            formType = nextProps.formType;
        }

        if (this.state.isFormLoading !== nextProps.isFormLoading) {
            isFormLoading = nextProps.isFormLoading;
        }

        if (nextProps.userData !== this.props.userData) {
            let userData = nextProps.userData;
            if (userData) {
                localFormData = this.autoFillForm(userData);
            } else {
                localFormData = this.clearLocalForm();
            }
        }

        this.setState({ doctorList, isFormLoading, uiData, formType, localFormData });
    }

    clearLocalForm() {
        let { localFormData, formErrors } = this.state;
        for (var property in localFormData) {
            if (formErrors.hasOwnProperty(property)) {
                localFormData[property] = { value: '', hasErrors: false };
            } else if (localFormData.hasOwnProperty(property)) {
                localFormData[property] = '';
            }
        }
        return localFormData;
    }

    areAllFieldsValid() {
        let formErrors = Object.values(this.state.formErrors);
        for (const index in formErrors) {
            if (formErrors[index]) { return false; }
        }
        return true;
    }

    setFormValueAfterFieldValidation = (inputResponse) => {
        const { name, value } = inputResponse;
        let hasErrors;
        switch (name) {
            case 'email':
                hasErrors = value ? !Utils.validateEmail(value) : false;
                break;
            case 'name':
                hasErrors = value ? !Utils.validateName(value) : false;
                break;
            case 'phone':
                // case 'alternate_phone':
                hasErrors = value ? !Utils.validateMobile(value) : false;
                break;
            default:
        }
        this.setState(prevState => ({
            formErrors: {
                ...prevState.formErrors,
                [name]: hasErrors
            },
            localFormData: {
                ...prevState.localFormData,
                [name]: { value: value, hasErrors: hasErrors }
            }
        }));
    }

    getEmitedAutoSelectionData = (name, data) => {
        this.setState(prevState => ({
            localFormData: {
                ...prevState.localFormData,
                [name]: data
            }
        }));
    }

    // sets value in state
    setFormValueInState = (inputResponse) => {
        const { name, value } = inputResponse;

        if (name === 'email' || name === 'phone' || name === 'name') {
            this.setFormValueAfterFieldValidation(inputResponse);
            return;
        }

        this.setState(prevState => ({
            localFormData: {
                ...prevState.localFormData,
                [name]: value
            }
        }));

        this.checkForAdditionalChanges(inputResponse);
    }

    handleDOBChange = (dob) => {
        // set Age automatically
        if (dob) {
            this.setFormValueInState({ name: 'age', value: Utils.getAge(dob) });
        }
    }

    onDoctorSelectValueChange = (value) => {
        for (let index in this.state.doctorList) {
            if (value === this.state.doctorList[index].value) {
                let { doctor_id, doctor_name } = this.state.localFormData;
                doctor_id = this.state.doctorList[index].value;
                doctor_name = this.state.doctorList[index].display_value;
                this.setState({
                    localFormData: {
                        ...this.state.localFormData, doctor_id, doctor_name
                    }
                })
                return;
            }
        }
    }

    checkForAdditionalChanges(inputResponse) {
        if (inputResponse.type === 'date' && inputResponse.name === 'date_of_birth') {
            this.handleDOBChange(inputResponse.value);
        } else if (inputResponse.type === 'selection' && inputResponse.name === 'doctor_id') {
            this.onDoctorSelectValueChange(inputResponse.value);
        } else if (inputResponse.name === 'phone') {
            // this.onMobileValueChange(inputResponse);
        }
    }

    onMobileValueChange = (inputResponse) => {
        const { value } = inputResponse;
        let pattern = /^\d+$/;

        if (!pattern.test(value) || value.length > 10) {
            return
        }
        this.setFormValueInState(inputResponse);
    }

    checkForUserRoleValue() {
        if (this.state.localFormData.role === "admin" || this.state.localFormData.role === "doctor") {
            return false;
        }
        return true;
    }

    onUserCreateFormSubmit = (event) => {
        event.preventDefault();
        if (!this.areRequiredFieldsEmpty() && this.areAllFieldsValid()) {
            let data = Object.assign({}, this.formUserData());
            this.setState({
                isFormLoading: true
            }, () => this.props.emitFinalFormSubmit(data))
        }
    }
    // check if any required field is empty
    areRequiredFieldsEmpty() {
        let requiredFields = RequiredFields;
        // if one of selected userrole is 'doctor', then remove doctor selection from requiredfields
        if (this.state.localFormData['role'].split(',').indexOf('r:doctor') !== -1) requiredFields = requiredFields.filter(f => f !== 'doctor_id');
        let fieldValue;
        for (const index in requiredFields) {
            if (this.state.formErrors.hasOwnProperty(requiredFields[index])) {
                fieldValue = this.state.localFormData[requiredFields[index]].value;
            } else {
                fieldValue = this.state.localFormData[requiredFields[index]];
            }
            if (fieldValue === '' || fieldValue === null || fieldValue === undefined) {
                return true;
            }
        }
        return false;
    }

    isSubmitDisabled = (isFormLoading = false) => {
        return this.areRequiredFieldsEmpty() || !this.areAllFieldsValid() || isFormLoading;
    }

    checkForCountryCodeInPhoneField() {
        let phone = (this.state.localFormData.phone ? this.state.localFormData.phone.value : this.state.localFormData.phone)
        if (phone.indexOf(this.state.localFormData['country_code']) !== -1) {
            return phone
        }
        phone = this.state.localFormData['country_code'] + phone;
        return phone;
        // (this.state.localFormData['country_code'] ? this.state.localFormData['country_code'] : '+977') + (this.state.localFormData.phone ? this.state.localFormData.phone.value : this.state.localFormData.phone)
    }

    formUserData() {
        let dateOfBirth = this.state.localFormData['date_of_birth'] ? Utils.getFormatedDate(this.state.localFormData['date_of_birth']) : '';
        let env = window.location.hostname.indexOf('localhost') === -1 && window.location.hostname.indexOf('beta') === -1 ? 'e:prod' : this.state.localFormData && this.state.localFormData['env'] ? this.state.localFormData['env'] : 'e:dev';
        return {
            name: this.state.localFormData.name ? this.state.localFormData.name.value : this.state.localFormData.name,
            phone: this.checkForCountryCodeInPhoneField(),
            // this.state.localFormData.phone ? this.state.localFormData.phone.value : this.state.localFormData.phone,
            email: this.state.localFormData.email ? this.state.localFormData.email.value : this.state.localFormData.email,
            env: env,
            country_code: this.state.localFormData['country_code'] ? this.state.localFormData['country_code'] : '+977',
            role: this.state.localFormData.role,
            features: this.state.localFormData.features ? this.state.localFormData.features.split(',') : [],
            gender: this.state.localFormData.gender ? this.state.localFormData.gender.toLowerCase() : '',
            "cognito_username": this.checkForCountryCodeInPhoneField(),
            department: this.state.localFormData.department,
            'doctor_id': this.checkForUserRoleValue() ? this.state.localFormData['doctor_id'] : '',
            'doctor_name': this.checkForUserRoleValue() ? this.state.localFormData['doctor_name'] : '',
            address: {
                country: 'nepal',
                state: this.state.localFormData.state,
                city: this.state.localFormData.city,
                zipcode: this.state.localFormData.zipcode,
                line1: this.state.localFormData.line1,
                line2: this.state.localFormData.line2
            },
            date_of_birth: dateOfBirth,
            age: this.state.localFormData['age'],
            speciality: this.state.localFormData.speciality
        }
    }

    onBackButtonClick = (event) => {
        this.props.emitBackButtonEvent('/user-view');
    }

    render() {
        const isRoleDoctor = this.state.localFormData['role'].split(',').indexOf('r:doctor') !== -1;
        let userFormData = this.state.userFormData;
        return (<>
            {(this.state.localFormData !== {}) &&
                <div className="card">
                    <div className="card-body">
                        <div className="d-flex justify-content-between">
                            <div>
                                <h4 className="card-title">{userFormData.formName}</h4>
                            </div>
                            <div>
                                <button title="Back"   className="btn btn-outline-success btn-icon btn-rounded" onClick={this.onBackButtonClick.bind(this)}>
                                    <i className="mdi mdi-arrow-left"></i>
                                </button>
                            </div>
                        </div>

                        <form className="form-sample">
                            {userFormData && userFormData.sections.map((section, idx) =>
                                <React.Fragment key={idx}>
                                    <p className="card-description">
                                        {section.sectionName}
                                    </p>
                                    <Table
                                        rows={section.rows}
                                        uiData={this.state.uiData}
                                        dynamicValues={this.state.localFormData}
                                        onInputChange={this.setFormValueInState}
                                        isRoleDoctor={isRoleDoctor}
                                        emitAutoSelectionData={this.getEmitedAutoSelectionData.bind(this)} />
                                </React.Fragment>
                            )}
                            <div className="row col-md-6 col-lg-9">
                                <button type="button" disabled={this.isSubmitDisabled(this.state.isFormLoading)} className="mr-2" className={this.isSubmitDisabled(this.state.isFormLoading) ? 'btn btn-outline-secondary btn-rounded' : 'btn btn-success btn-rounded'} onClick={this.onUserCreateFormSubmit}>{this.state.formType === 'edit' ? 'Update' : 'Submit'}</button>
                                {this.state.isFormLoading && <div style={{ display: 'inline-block', 'marginLeft': '30px' }}><Spinner width={50} height={50}></Spinner></div>}
                            </div>
                            <h1 className="mt-4 text-danger" style={{ fontSize: '12px' }}>*Required</h1>
                        </form>
                    </div>
                    {/* <div className="col-lg-12">
                        <HooksComponent></HooksComponent>
                    </div> */}
                </div>}
            {this.state.localFormData === {} && <Spinner width={75} height={75}></Spinner>}
        </>);
    }
}

export default UserEditViewComponent;