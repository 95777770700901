import request from '../Api/request';
import userAuth from '../Authentication/authActions';
import {
    getFCMToken
} from '../push-notification';
import Utils from '../Shared/utils/utils';  
import axios from "axios"

function getApiStageType() {
    return userAuth.getApiStageType()
}

function getPatientApiStageUrl() {
    return userAuth.getPatientApiUrl()
}

function getApiStageNApiForOnline() {
    let apiStage = getApiStageType();
    let api = userAuth.getEmrNotificationApiUrl()

    return {
        apiStage,
        api
    };
}


function getApiStageNApiForVoiceCall() {
    let api = userAuth.getEmrPaymentApiUrl()
    let apiStage = getApiStageType();
    return {
        apiStage,
        api
    };
}



function submitPatientInfo(patientInfo, isOPDConsult) {
    let api = '';
    if (isOPDConsult) {
        api = userAuth.getOnlinePatientApiStageUrl();
    } else {
        api = userAuth.getNewPatientApiUrl();
    }
    let url = '';
    if (isOPDConsult) {
        url = `onlinepatient?invoked=true`
    } else {
        url = `onlinepatient`
    }
    delete axios.defaults.headers.common["Authorization"];
    return request({
        baseURL: api,
        url: url,
        method: 'post',
        data: patientInfo,

    }, 'appointment');
}

function registerFCMIdForDoctor(res, account_id) {
    let token = getFCMToken();
    if (token === '-') {
        return {
            type: 'error'
        }
    };
    // let api = userAuth.getEmrNotificationApiUrl();
    // let apiStage = getApiStageType()
    let apiStage = sessionStorage.getItem('apiStage');
    let api = (apiStage == 'dev') ? 'https://482wiwire5.execute-api.ap-south-1.amazonaws.com/' : "https://fs4llg3jbf.execute-api.ap-south-1.amazonaws.com/";
    let device_id = sessionStorage.getItem('deviceId');
    let data = {
        "sub_id": res.id,
        "device_type": "web",
        "registration_id": token,
        "account_id": account_id ? account_id : '',
        "role": res.role,
        "mobile": res.country_code + res.phone,
        "os_type": "windows",
        "device_id": device_id
    }
    return request({
        baseURL: api,
        url: apiStage + '/register_fcm_id',
        method: 'post',
        data: data
    });
}

function registerFCMId(res, account_id) {
    let token = getFCMToken();
    if (token === '-') {
        return {
            type: 'error'
        }
    };
    let api = userAuth.getEmrNotificationApiUrl();
    let apiStage = getApiStageType()
    let device_id = sessionStorage.getItem('deviceId');
    let data = {
        "patient_id": res.id,
        "device_type": "web",
        "registration_id": token,
        "account_id": account_id ? account_id : '',
        "role": res.role,
        "mobile": res.country_code + res.phone,
        "os_type": "windows",
        "device_id": device_id
    }
    return request({
        baseURL: api,
        url: apiStage + '/register_fcm_id',
        method: 'post',
        data: data
    });
}

function requestDemo(res) {
    let api = userAuth.getEmrNotificationApiUrl();
    let apiStage = getApiStageType();
    return request({
        baseURL: api,
        url: apiStage + '/requestDemo',
        method: 'post'
    });
}

function getAccountInfo(providerName, getVideoSettings, docId) {
    let api = userAuth.getAppointmentApiUrl();
    let apiStage = getApiStageType();
    let url = `/onlineappointment/get-account-info?account_id=${providerName}` + (getVideoSettings ? `&video_settings=true` : '') + (docId ? `&doctor_id=${docId}` : '');
    return request({
        baseURL: api,
        url: url,
        method: 'get'
    });
}

function sendEmailAlert(data) {
    let apiStage = getApiStageType();
    let api = apiStage === "dev" ? 'https://7ide3wu2ng.execute-api.ap-south-1.amazonaws.com/dev/' : 'https://589z89anl1.execute-api.ap-south-1.amazonaws.com/prod/';
    return request({
        baseURL: api,
        url: "comm/send-email-alert",
        data: data,
        method: 'post'
    });
    // https://7ide3wu2ng.execute-api.ap-south-1.amazonaws.com/dev/comm/send-email-alert
}




function fetchPaymentLink(data, patientId) {
    let apiStage = getApiStageType();
    let api = userAuth.getAppointmentApiUrl();

    return request({
        baseURL: api,
        url: `/onlineappointment/get-payment-link/${patientId}`,
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

//send doctor's msg to patient
function sendDoctorMsgToPatient(data) {
    let {
        api,
        apiStage
    } = getApiStageNApiForOnline();
    return request({
        baseURL: api,
        url: apiStage + `/send-doctor-chat-message`,
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

// send patient's msg to doctor
function sendPatientMsgToDoctor(data) {
    let {
        api,
        apiStage
    } = getApiStageNApiForOnline();
    return request({
        baseURL: api,
        url: apiStage + `/send-patient-chat-message`,
        method: 'post',
        data: data
    }, 'appointment');
}

//get patient's chat with doctor : pre & post consultation in waiting area
function getPatientDocChat(data) {
    let {
        api,
        apiStage
    } = getApiStageNApiForOnline();
    let {
        account_id,
        doctor_id,
        patient_id,
        room_id
    } = data;
    return request({
        baseURL: api,
        url: apiStage + `/get-patient-doctor-chat?account_id=${account_id}&doctor_id=${doctor_id}&patient_id=${patient_id}&room_id=${room_id}`,
        method: 'get'
    });
}

//get patient specific chat for doctor login
function getDoctorPatChat(data) {
    let {
        api,
        apiStage
    } = getApiStageNApiForOnline();
    let {
        account_id,
        doctor_id,
        patient_id
    } = data;
    return request({
        baseURL: api,
        url: apiStage + `/get-doctor-patient-chat?account_id=${account_id}&doctor_id=${doctor_id}&patient_id=${patient_id}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

//get all resolved patients' queries for doctor
function getResolvedDoctorChats(data) {
    let {
        api,
        apiStage
    } = getApiStageNApiForOnline();
    let {
        account_id,
        doctor_id
    } = data;
    return request({
        baseURL: api,
        url: apiStage + `/get-doctor-chats?account_id=${account_id}&doctor_id=${doctor_id}&fetch_all=1`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

//get all unresolved/open patients' queries for doctor
function getOpenDoctorChats(data) {
    let {
        api,
        apiStage
    } = getApiStageNApiForOnline();
    let {
        account_id,
        doctor_id
    } = data;
    return request({
        baseURL: api,
        url: apiStage + `/get-doctor-chats?account_id=${account_id}&doctor_id=${doctor_id}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function checkPatientCallStatus(data) {
    let {
        api,
        apiStage
    } = getApiStageNApiForVoiceCall();
    let {
        account_id,
        doctor_id,
        patient_id,
        room_id,
        appointment_id
    } = data;
    return request({
        baseURL: api,
        url: `/onlineconsult/check-patient-call-status?account_id=${account_id}&doctor_id=${doctor_id}&patient_id=${patient_id}&room_id=${room_id}&appointment_id=${appointment_id}`,
        method: 'get'
    });
}

function voiceCallDoctor(data) {
    let {
        api,
        apiStage
    } = getApiStageNApiForVoiceCall();
    return request({
        baseURL: api,
        url: `/onlineconsult/call-doctor`,
        method: 'post',
        data: data
    });
}

function voiceCallPatient(data) {
    let {
        api,
        apiStage
    } = getApiStageNApiForVoiceCall();
    return request({
        baseURL: api,
        url: `/onlineconsult/call-patient`,
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function getCountries() {
    return request({
        baseURL: "https://betaemrapi.hlthclub.in/",
        url: `onlinepatient/get-tp-master-data?account_id=breathefree&filter_type=country`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}
function getStates(data) {
    return request({
        baseURL: "https://betaemrapi.hlthclub.in/",
        url: `onlinepatient/get-tp-master-data?account_id=breathefree&filter_type=state&country_name=nepal`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}
function getCites(data) {
    let {
        country_id,
        state_id,
    } = data;
    return request({
        baseURL: "https://betaemrapi.hlthclub.in/",
        url: "onlinepatient/get-tp-master-data?account_id=breathefree&filter_type=city&country_name=nepal&state_name="+state_id,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

const onlineConsultationService = {
    submitPatientInfo,
    registerFCMId,
    requestDemo,
    getAccountInfo,
    fetchPaymentLink,
    sendDoctorMsgToPatient,
    sendPatientMsgToDoctor,
    getResolvedDoctorChats,
    getOpenDoctorChats,
    getDoctorPatChat,
    getPatientDocChat,
    registerFCMIdForDoctor,
    voiceCallDoctor,
    voiceCallPatient,
    checkPatientCallStatus,
    sendEmailAlert,
    getCountries,
    getStates,
    getCites
}

export default onlineConsultationService;