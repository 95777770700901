import React from "react";
import ValidationComponent from "./ValidationComponent";
class CompactSelectCOmponent extends React.Component {

    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue = (event) => {
        event.preventDefault();
        this.props.emitTextboxValue({ name: event.target.name, value: event.target.value })
    }

    setErrorStatus = (errorStatus) => {
        this.props.emitErrorStatus(errorStatus);
    }

    render() {
        // console.log('-----------------------------------select---------------------------------');
        const { type, name, classname, placeholder, value, label, labelSize, size, options, offset,rowType } = this.props;
        return (
            <React.Fragment>
                
                    <div className="form-group mb-1">
                        <select
                            className="form-control"
                            name={name}
                            value={value}
                            onChange={this.changeValue}>
                            <option>{placeholder}</option>
                            {options &&
                                <React.Fragment>
                                    {options.map((indOption, index) =>
                                        <option key={index} value={indOption.value}>{indOption.value}</option>
                                    )
                                    }
                                </React.Fragment>
                            }
                        </select>
                        {this.props.validation && <ValidationComponent value={value === placeholder ? "": value} checkForm={this.props.checkForm} isDirty={this.props.isDirty} validation={this.props.validation} emitErrorStatus={this.setErrorStatus.bind(this)}/>}
                    </div>
              
                {offset &&
                    <div className={offset}> </div>
                }
            </React.Fragment>
        );
    }
}

export default CompactSelectCOmponent;


{/* <div className="form-group mb-0">
                
                      
                <select className="form-control form-control-sm" id="exampleFormControlSelect3">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select></div> */}