/* eslint-disable */
import React, { Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import { Auth } from "aws-amplify";
import Amplify from 'aws-amplify';
import awsmobile from '../../aws-exports';
Amplify.configure(awsmobile)
Amplify.Logger.LOG_LEVEL = 'DEBUG';

import userAuth from '../../Authentication/authActions';
import appRoutes from '../../Routes/appRoutes';
import adminRoutes from '../../Routes/adminRoutes';
import NewChatRoomContainer from "../../Views/NewChat/NewChatRoomContainer";

const UsersViewContainer = lazy(() => import('../../Views/Users/UsersViewContainer'));
const AppointmentsViewContainer = lazy(() => import('../../Views/Appointments/AppointmentsViewContainer'));
// const AppointmentsCreateContainer = lazy(() => import('../../Views/Appointments/AppointmentsCreateContainer'));
const UserEditContainer = lazy(() => import('../../Views/Users/UserEditContainer'));
const BranchViewContainer = lazy(() => import('../../Views/Branch/BranchViewContainer'));
const SettingsContainer = lazy(() => import('../../Views/Settings/SettingsContainer'));
const SettingsEditContainer = lazy(() => import('../../Views/Settings/SettingsEditContainer'));
const AccountCreateContainer = lazy(() => import('../../Views/Account/AccountCreateContainer'));
const BulkUploadContainer = lazy(() => import('../../Views/Account/BulkUploadContainer'));
const AccountEditContainer = lazy(() => import('../../Views/Account/AccountEditContainer'));
const AccountListContainer = lazy(() => import('../../Views/Account/AccountListContainer'));
const AccountUserEditContainer = lazy(() => import('../../Views/Account/AccountUserEditContainer'));
const AccountUserListContainer = lazy(() => import('../../Views/Account/AccountUserListContainer'));
const AddNewUserFormContainer = lazy(() => import('../../Views/Account/AddNewUserFormContainer'));
const QCAnalystContainer = lazy(() => import('../../Views/QCAnalyst/QCAnalystContainer'))

const PaymentMgmtContainer = lazy(() => import('../../Views/PaymentMgmt/PaymentMgmtContainer'));

const ErrorLogsContainer = lazy(() => import('../../Views/ErrorLog/ErrorLogsContainer'));
const CallLogsContainer = lazy(() => import('../../Views/CallLog/CallLogsContainer'));

const DataEntryListViewContainer = lazy(() => import('../../Views/DataEntry/DataEntryListViewContainer'));
const DataEntryCreateUserContainer = lazy(() => import('../../Views/DataEntry/DataEntryCreateUserContainer'));

const DepartmentContainer = lazy(() => import('../../Views/Department/DepartmentContainer'));
const BranchEditContainer = lazy(() => import('../../Views/Branch/BranchEditContainer'));
const AppointmentEditContainer = lazy(() => import('../../Views/Appointments/AppointmentEditContainer'));
const PatientListViewContainer = lazy(() => import('../../Views/Patient/PatientListViewContainer'));
const PatientCreateContainer = lazy(() => import('../../Views/Patient/PatientCreateContainer'));
const PrescriptionListViewContainer = lazy(() => import('../../Views/Prescription/PrescriptionListViewContainer'));
const DoctorMultiChatContainer = lazy(() => import('../../Views/ChatPanel/DoctorMultiChatContainer'));

const ChangePasswordComponent = lazy(() => import('../../Views/ChangePassword/ChangePasswordComponent.js'));
const DynamicFormContainer = lazy(() => import('../../Views/DynamicForms/DynamicFormContainer.js'));
const PrescriptionMasterDetailContainer = lazy(() => import('../../Views/Prescription/PrescriptionMasterDetailContainer.js'));
// const PrescriptionDetailContainer = lazy(() => import('../../Views/Prescription/PrescriptionDetailContainer.js'));
// 
const UserProfileContainer = lazy(() => import('../../Views/UserProfile/UserProfileContainer.js'));

const FormBuilderComponent = lazy(() => import('../../Views/FormBuilder/FormBuilderComponent.js'));
const UploadPresciptionTemplateContainer = lazy(() => import('../../Views/UploadPresciptionTemplate/UploadPresciptionTemplateContainer.js'))

const AppointmentStatusContainer = lazy(() => import('../../Views/Appointments/AppointmentStatusContainer.js'))

const SmsStatusContainer = lazy(() => import('../../Views/Appointments/SmsStatus/SmsStatusContainer'));
const AdminSettingsContainer = lazy(() => import('../../Views/AdminSettings/AdminSettingsContainer'))
const DoctorCreateContainer = lazy(() => import('../../Views/Doctor/DoctorCreateContainer'));
const BriefInsightContainer = lazy(() => import('../../Views/Insight/BriefInsightContainer'));
const DoctorWiseReportContainer = lazy(() => import('../../Views/Insight/DoctorWiseReportContainer'));
const ApptLogsContainer = lazy(() => import('../../Views/Support/ApptLogsContainer'));
const SupportContainer = lazy(() => import('../../Views/Support/SupportContainer'));
const PaymentContainer = lazy(() => import('../../Views/Transactions/Payment/PaymentContainer'));
const EnquiryContainer = lazy(() => import('../../Views/Transactions/Enquiry/EnquiryContainer'));
const PreviousTransactionContainer = lazy(() => import('../../Views/Transactions/PreviousTransaction/PreviousTransactionContainer'));

const SMSCreditDetailContainer = lazy(() => import('../../Views/Transactions/SMSCredit/SMSCreditDetailContainer'));

const RefundContainer = lazy(() => import('../../Views/Transactions/Refund/RefundContainer'));
const SmsCartContainer = lazy(() => import('../../Views/Transactions/SMSCart/SmsCartContainer'));

const RxAnalysisContainer = lazy(() => import('../../Views/RxAnalysis/RxAnalysisContainer'));
const ApptSummaryContainer = lazy(() => import('../../Views/Support/ApptSummaryContainer'));
const AppointmentSummaryContainer = lazy(() => import('../../Views/Summary/AppointmentSummaryContainer'));
const MonthlyApptContainer = lazy(() => import('../../Views/Transactions/MonthlyAppts/MonthlyApptContainer'));
const InvoiceListContainer = lazy(() => import('../../Views/Invoice/InvoiceListContainer'));
// const UnorderedInvoiceListContainer = lazy(() => import('../../Views/Invoice/UnorderedInvoiceListContainer'));

const InvoiceStepperContainer = lazy(() => import('../../Views/InvoiceStepper/InvoiceStepperContainer'));
const CreateInvoiceContainer = lazy(() => import('../../Views/InvoiceVaccination/CreateInvoiceContainer'));

const InvoiceReportContainer = lazy(() => import('../../Views/InvoiceReport/InvoiceReportContainer'));
const BookingContainer = lazy(() => import('../../Views/Booking/BookingContainer'));
// const InventoryManagementContainer = lazy(() => import('../../Views/Inventory/InventoryManagementContainer'));

// InventoryManagementContainer
const InvoiceReportDetailContainer = lazy(() => import('../../Views/InvoiceReportDetail/InvoiceReportDetailContainer'));

// InvoiceReportDetailContainer

import ErrorBoundaryComponent from './ErrorBoundaryComponent';
import onlineConsultationService from '../../Services/onlineConsultation.service';

class Main extends React.Component {
    clinyticsRoutes = null
    constructor(props) {
        super(props);
        this.state = {
            videoRoom: false,
            apptData: null,
            dummyLocationState: null,
            pipMode: false,
            room_id: null,
            providerName: null,
            videoRoomHash: ''
        }
        this.checkForBreathefreeRoutes();
        this.getCurrentUserCredentialsData();
        this.checkIfDoctorLogin();
    }

    componentDidMount() {
        userAuth.setTokenAfterHalfHr();
        userAuth.checkUserVisibilityOnApp();
    }

    checkIfDoctorLogin() {
        let profileData = sessionStorage.getItem('profileInfo') ? JSON.parse(sessionStorage.getItem('profileInfo')) : null;
        if (!profileData) {
            return;
        }
        if (profileData.role.indexOf('r:doctor') !== -1 || profileData.role.indexOf('r:staff') !== -1) {
            let providerName = sessionStorage.getItem("s3AccountId");
            this.enableNotificationsAndRedirectToDocList(profileData, providerName);
        }
    }

    async enableNotificationsAndRedirectToDocList(profileData, providerName) {

        try {
            let finalData = {
                id: profileData.cognito_user_sub,
                country_code: profileData.country_code ? profileData.country_code : "+977",
                phone: profileData.phone,
                role: profileData.role
            }

            let response = await onlineConsultationService.registerFCMIdForDoctor(finalData, providerName);
            if (response && response.type === 'success') {
                sessionStorage.setItem('fcm_registered', true);
            } else {
                sessionStorage.setItem('fcm_registered', '');
            }
        } catch (err) {
            sessionStorage.setItem('fcm_registered', '');
        }
    }

    checkForBreathefreeRoutes() {
        let clinyticsRoutes = sessionStorage.getItem('isSuperUser') ? adminRoutes : this.filterAppRoutes();
        this.clinyticsRoutes = clinyticsRoutes;
    }

    async getCurrentUserCredentialsData() {
        try {
            var user = await Auth.currentCredentials();
            if (user) {
                sessionStorage.setItem('identityId', user.data.IdentityId);
            }
        } catch (e) {
            console.log(e.message);
        }
    }

    filterAppRoutes() {
        for (let index in appRoutes) {
            if (appRoutes[index].redirect) {
                continue;
            }
            if (appRoutes[index].key === "Users") {
                if (sessionStorage.getItem('showUser') !== "") {
                    appRoutes[index].isHide = false;
                } else {
                    appRoutes[index].isHide = true;
                }
            } else {
                appRoutes[index].isHide = false;
            }
        }
        return appRoutes;
    }

    onVideoRoomStateReceive = (videoRoomState) => { // contains locationState, room_id, providerName        
        sessionStorage.setItem('currentVideoApptId', videoRoomState ? videoRoomState.dummyLocationState.appointmentId : '');
        window.location.hash = videoRoomState.videoRoomHash;
        this.setState({ ...videoRoomState, videoRoom: true });
        window.addEventListener("unload", (event) => {
            
            if (this.state.videoRoom) {
                event.preventDefault();
                this.onCloseVideoRoom();
            }
        });
    }
    onCloseVideoRoom = () => { // contains locationState, room_id, providerName
        this.setState({ videoRoom: false })
    }
    onRedirectFromVideoRoom = (path, locationstate) => { // contains locationState, room_id, providerName
        this.onCloseVideoRoom()
        this.props.history.push(path, locationstate);
        if (path.includes('appointments')) {
            window.location.reload(); // to show reconnect-call pop up
        }
    }
    onPipModeToggle = (pipModeOn) => { // contains locationState, room_id, providerName
        window.location.hash = pipModeOn ? '' : this.state.videoRoomHash;
        if (pipModeOn && !window.location.pathname.includes('prescription')) {
            this.props.history.push(`/prescription-detail?patientId=${this.state.apptData['patient_id']}&appointmentId=${this.state.apptData['id']}&type=appointment`, this.state.apptData)
        }
        this.setState({ pipMode: pipModeOn })
    }
    onPipModeClose = () => {
        if (document.pictureInPictureElement) {
            document.exitPictureInPicture()
                .catch(error => {
                    // Video failed to leave Picture-in-Picture mode.
                });
        }
    }

    render() {
        let { videoRoom, dummyLocationState, pipMode } = this.state;
        return (
            <div className="container-scroller">
                <Suspense>
                    <Switch>
                        <ErrorBoundaryComponent>
                            <div className={pipMode ? 'hidden-video-room' : ''}>
                                {videoRoom && dummyLocationState && <NewChatRoomContainer location={{ state: dummyLocationState }} notificationData={this.props.nfn}
                                    pipModeOn={this.onPipModeToggle.bind(this)} closeVideoRoom={this.onCloseVideoRoom.bind(this)} redirectTo={this.onRedirectFromVideoRoom.bind(this)}
                                    room_id={this.state.room_id} providerName={this.state.providerName} />}
                            </div>
                            <div className={!videoRoom || pipMode ? '' : 'hidden-app-content'}>
                                <Route path="/edit-account" component={AccountEditContainer} />
                                <Route path="/create-account" component={AccountCreateContainer} />
                                <Route path="/bulk-upload" component={BulkUploadContainer} />
                                <Route path="/account-list" component={AccountListContainer} />
                                <Route path="/create-account-user" component={AddNewUserFormContainer} />
                                <Route path="/account-users" component={AccountUserListContainer} />
                                <Route path="/account-user-edit" component={AccountUserEditContainer} />
                                <Route path={`/user-view`} component={UsersViewContainer} />
                                <Route path="/create-user" component={UserEditContainer} />
                                <Route path="/qc-users-list" component={DataEntryListViewContainer} />
                                <Route path="/qc-create-user" component={DataEntryCreateUserContainer} />
                                <Route path="/create-invoice" component={InvoiceStepperContainer} />
                                <Route path="/vaccination-invoice" component={CreateInvoiceContainer} />
                                <Route path="/invoice-list" component={InvoiceListContainer} />
                                {/* <Route path="/unordered-invoice-list" component={UnorderedInvoiceListContainer} /> */}
                                <Route path="/invoice-report-detail" component={InvoiceReportDetailContainer} />
                                <Route path="/invoice-report" component={InvoiceReportContainer} />
                                <Route path="/booking-view" component={BookingContainer} />
                                {/* <Route path="/inventory-management" component={InventoryManagementContainer} /> */}
                                {/* Inventory Management */}
                                <Route path="/appointment-summary" component={ApptSummaryContainer} />
                                <Route path="/app-summary" component={AppointmentSummaryContainer} />
                                {/* app-summary */}
                                <Route path="/monthly-appointments" component={MonthlyApptContainer} />

                                <Route path="/payment-mgmt" component={PaymentMgmtContainer} />

                                <Route path="/error-logs" component={ErrorLogsContainer} />
                                {/*  */}
                                <Route path="/rx-analysis" component={RxAnalysisContainer} />
                                <Route path="/call-logs" component={CallLogsContainer} />
                                <Route path="/appointment-logs" component={ApptLogsContainer} />
                                <Route path="/call-logs-detail" component={CallLogsContainer} />
                                <Route path="/error-logs-detail" component={CallLogsContainer} />
                                {/* <Route path="/appointments-view" component={AppointmentsViewContainer} /> */}
                                <Route path="/appointments-view" render={(props) => {
                                    return <AppointmentsViewContainer {...props} notificationData={this.props.nfn} isPipModeOn={this.state.pipMode}
                                        emitVideoRoomState={this.onVideoRoomStateReceive.bind(this)} closePipMode={this.onPipModeClose.bind(this)} />
                                }} />

                                <Route path="/edit-appointment" component={AppointmentEditContainer} />
                                <Route path="/create-appointment" component={AppointmentEditContainer} />
                                <Route path="/appointment-status" component={AppointmentStatusContainer} />
                                <Route path="/qc-analyst" component={QCAnalystContainer} />
                                <Route path="/refund-status" component={RefundContainer} />
                                <Route path="/sms-credit-detail" component={SMSCreditDetailContainer} />
                                <Route path="/previous-transactional-detail" component={PreviousTransactionContainer} />
                                <Route path="/sms-cart" component={SmsCartContainer} />
                                {/* /sms-credit-detail */}
                                <Route path="/payment-status" component={PaymentContainer} />
                                <Route path="/enquiry" component={EnquiryContainer} />
                                {/* <Route path="/patient-queries" render={(props) => {
                                return <DoctorMultiChatContainer {...props} nfn={this.props.nfn} />
                            }} /> */}
                                <Route path="/patient-queries" component={(props) => {
                                    return <DoctorMultiChatContainer {...props} nfn={this.props.nfn} />
                                }} />

                                <Route path="/patient-view" component={PatientListViewContainer} />
                                <Route path="/create-patient" component={PatientCreateContainer} />
                                <Route path="/edit-patient" component={PatientCreateContainer} />

                                <Route path="/account-setting" component={SettingsContainer} />
                                <Route path="/edit-account-setting" component={SettingsEditContainer} />
                                <Route path="/branch-setting" component={BranchViewContainer} />
                                <Route path="/create-branch" component={BranchEditContainer} />
                                <Route path="/department-setting" component={DepartmentContainer} />

                                <Route path="/upload-prescription" component={UploadPresciptionTemplateContainer} />
                                {/* <Route path="/support" component={SupportContainer} /> */}
                                <Route path="/brief-insight" component={BriefInsightContainer} />
                                <Route path="/doctor-wise-report" component={DoctorWiseReportContainer} />
                                <Route path="/prescription-link" component={PrescriptionListViewContainer} />
                                <Route path="/prescription-detail" render={(props) => {
                                    return <PrescriptionMasterDetailContainer {...props} nfn={this.props.nfn} />
                                }} />
                                <Route path="/create-admin-doctor" component={DoctorCreateContainer} />
                                <Route path="/change-password" component={ChangePasswordComponent} />
                                <Route path="/user-profile" component={UserProfileContainer} />

                                {/* AdminSettings Route AdminSettingsContainer*/}
                                <Route path="/settings" component={AdminSettingsContainer} />
                                <Route path="/form-builder" component={FormBuilderComponent} />
                                <Route path="/admin-form-builder" component={FormBuilderComponent} />
                                <Route path="/dynamic-form" component={DynamicFormContainer}  ></Route>

                                <Route path={`/sms`} component={SmsStatusContainer} />
                            </div>
                        </ErrorBoundaryComponent>

                    </Switch>
                </Suspense>
            </div>
        );
    }
}

export default Main;


