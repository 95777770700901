import React from "react"; 
import SweetAlertComponent from '../Alert/SweetAlertComponent';
import { ToastContainer, toast } from 'react-toastify';
import accountService from '../../Services/account.settings';
import "react-datepicker/dist/react-datepicker.css";
import prescriptionService from '../../Services/prescription.service';
import axios from 'axios'; 

class BannerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            alert: { show: false, type: '', title: '', text: '' },
            isEdit: false,
            isDirty: false,
            banners: [],
            uploadType: 'url',
            url: '',
            alt: '',
            html: '',
            imageKey: '',
            bannerData: this.props.details.banners ? this.props.details.banners : []
            // [{
            //     type:"url"/"image"/"HTML",
            //     value:
            //     bucket:""}]
        }
    }

    componentDidMount() {
        if (this.props.details && this.props.details.banners) {
            let { bannerData } = this.state;
            bannerData = this.props.details && this.props.details.banners ? this.props.details.banners : [];
            this.setState({ bannerData });
        }
    }

    sweetAlertClose = () => {
        this.setState({ alert: { show: false } }, () => this.props.emitCancelEvent(true));
    }

    onFileUpload(e) {
        let file = e.target.files[0];
        if (!file) return;
        let re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png)$/i;
        if (!re.exec(file.name.toLowerCase())) {
            this.setState({ imageKey: '' });
            this.notify(`File type not supported for upload!!!`);
            return;
        }
        this.getUploadedFileUrl(file)

    }

    async getUploadedFileUrl(file) {
        try {
            let data = {

                "filename": (file.name).toLowerCase().replace(/\s/g, ''),
                "content_type": file.type,
                "account_id": this.props.providerName,
                "banners": true
            }

            let response = await prescriptionService.fetchPrescriptionGenerateUploadUrl(data);
            
            if (!response || response.type === 'error') {
                let { isLoading } = this.state;
                isLoading = false;
                this.setState({ isLoading }, () => this.props.enqueueSnackbar(response.message));
                return;
            } else if (response.type === 'success') {
                let url = response && response.data && response.data.url ? response.data.url : '';
                let key = response && response.data && response.data.key ? response.data.key : '';
                if (!url || !key) {
                    alert(url, key)
                    return;
                }
                this.setState({
                    imageKey: key
                }, () => this.saveImageToS3(file, url, key))
            }
        } catch (error) {
            console.log(error);
        }
    }

    saveImageToS3(file, url, key) {

        let name = file.name.toLowerCase().replace(/\s/g, '')
        this.fileUpload(file, url).then((response) => {
            
            if (response.status === 200) {
                
                // this.setState({
                //     imageKey: response.data
                // })
            }
        })
    }

    async fileUpload(file, url) {
        const config = {
            headers: {
                'Content-Type': file.type
            }
        }
        delete axios.defaults.headers.common["Authorization"];
        return axios.put(url, file, config)
            .then((response) => {
                return response
            })
            .catch((error) => {
                console.log(error)
                return error
            });
    }

    notify(message) {
        toast.error(message, { position: toast.POSITION.TOP_RIGHT, containerId: 'A' });
    }

    checkForButtonDisablity = () => {
        let { uploadType, url, html, imageKey, alt } = this.state;
        if (uploadType === "url") {
            return url ? false : true
        } else if (uploadType === "html") {
            return html ? false : true
        } else if (uploadType === "image") {
            return imageKey && alt ? false : true
        }
    }

    addItem = (event) => {
        let { bannerData, uploadType, url, html, imageKey, alt, isDirty } = this.state;
        let valueData = (uploadType === "url") ? url : (uploadType === "html") ? html : imageKey
        let obj = {
            type: uploadType,
            value: valueData,
            bucket: '',
            alt: (uploadType === "image") ? alt : ''
        }
        isDirty = true;
        bannerData.push(obj);
        this.setState({ bannerData, isDirty })
    }

    async submitBannersData() {
        try {
            let data = {
                'banners': this.state.bannerData
            }
            this.setState({ isLoading: true })
            let response = await accountService.uploadAccountLogo(this.props.providerName, data);
            let { alert } = this.state;
            if (response && response.type === 'success') {
                alert = { show: true, type: 'success', title: 'Banner Uploaded succesfully' };
                this.setState({ alert, isLoading: false, isDirty: false });
            } else {
                alert = { show: true, type: 'error', title: 'Error', text: 'Error while uploading Invoice Settings! Please try again.' };
                this.setState({ isLoading: false, alert });
            }
        } catch (err) {
            let { alert } = this.state;
            alert = { show: true, type: 'error', title: 'Error', text: 'Error while uploading Banner! Please try again.' };
            this.setState({ isLoading: false, alert });
        }
    }

    onTextChangeEvent = (event) => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    onSelectValueChange = (event) => {
        const { name, value } = event.target
        this.setState({
            [name]: value
        })
    }

    deleteFeeData = (idx) => {
        let { bannerData } = this.state;
        bannerData.splice(idx, 1);
        this.setState({ bannerData })
    }

    // services_fees.splice(idx, 1);
    render() {
        const { open } = this.props;
        const { isEdit, isDirty, isLoading, uploadType, url, html, bannerData, alt } = this.state;
        return (
            <div className={`modal fade ${open ? 'show' : ''}`} id="timeslotmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden={`${open ? true : false}`} style={{
                    display: open ? 'block' : 'none', paddingRight: open ? '17px' : '', overflowX: 'hidden', overflowY: 'auto', backgroundColor: '#716e6e54'
                }}>
                <div className={`modal-dialog ${this.props.fullModal ? 'modal-lg' : ''}`} role="document" style={{ marginTop: 'calc(70px + 0px)' }}>
                    <div className="modal-content bg-white">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{this.props.title ? this.props.title : 'Select your booking slot'}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.emitCancelEvent.bind(this, false)}>
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body ">
                            <section className="appointmentslot">
                                <div className="row">
                                    <div className="col-lg-5 col-xs-12">
                                        <ul className="pl-3 mt-2 text-small">
                                            <li>You can upload 3 types of banner i.e., Html, Url, Image(Upload image.</li>
                                            <li>Supported Image Types :<strong> JPEG,JPG,PNG.</strong>.</li>
                                            <li>File size to be less than 5MB</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-7 col-xs-12">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group">
                                                    <label className="col-form-label">Banner Type*</label>

                                                    <select className="form-control"
                                                        name="uploadType"
                                                        value={uploadType}
                                                        onChange={this.onSelectValueChange.bind(this)}
                                                    >
                                                        <option>Select Banner Type</option>
                                                        <option value={"html"}>Html</option>
                                                        <option value={"url"}>Url</option>
                                                        <option value={'image'}>Image</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-12">

                                                {uploadType === "html" &&
                                                    <textarea className="form-control" id="exampleTextarea1" rows="10" spellcheck="false" name="html" value={html}
                                                        placeholder="Past Html" onChange={this.onTextChangeEvent.bind(this)} ></textarea>
                                                }
                                                {uploadType === "image" &&
                                                    <form method="post" action="#" id="#">

                                                        <div className="form-group filedrag color">
                                                            <label>{'Upload Image'} </label>
                                                            <input type="file" className="form-control" onChange={e => this.onFileUpload(e)} onClick={(e) => { e.target.value = null }}></input>
                                                        </div>
                                                    </form>
                                                }
                                                {uploadType === "url" &&
                                                    <input type="text" name="url" className="form-control" placeholder="Enter Url" value={url} onChange={this.onTextChangeEvent.bind(this)} />
                                                }
                                            </div>
                                            <div className="col-12">
                                                {uploadType === "image" &&
                                                    <input type="text" name="alt" className="form-control" placeholder="Enter comma(, ) seperated tags" value={alt} onChange={this.onTextChangeEvent.bind(this)} />
                                                }

                                            </div>
                                            <div className="col-12 mt-2">
                                                <button className="btn btn-sm btn-success radius-0" disabled={this.checkForButtonDisablity()} type="button" style={{ width: '100%' }} onClick={this.addItem.bind(this)}>+Add</button>
                                            </div>
                                            <div className="col-12 mt-2">
                                                <p className="m-1"><strong>Uploaded Banner Data</strong></p>
                                                <hr className="m-1" />
                                            </div>
                                            <div className="col-12 mt-2">
                                                {bannerData && bannerData.length > 0 ? (
                                                    <>
                                                        {bannerData.map((indBanner, i) =>
                                                            <div className="form-group mb-3 border-bottom">
                                                                <ul className="list-group">
                                                                    <li className="list-group-item d-flex justify-content-between align-items-center p-1"><p className="m-1">{indBanner.type} : {indBanner.value}</p>
                                                                        <span className="badge badge-danger badge-pill" style={{ cursor: "pointer" }} onClick={this.deleteFeeData.bind(this, i)}>X</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </>

                                                ) : (
                                                        <p>No Data</p>
                                                    )}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </section>
                        </div>
                        {isLoading &&
                            <div className="clinytic-line-loader"></div>
                        }
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success mr-2 btn-rounded" disabled={!isDirty || isLoading} onClick={this.submitBannersData.bind(this)}>Submit</button>
                            <button type="button" className="btn btn-light" data-dismiss="modal" onClick={this.props.emitCancelEvent.bind(this, false)}>Cancel</button></div>
                    </div>
                </div>
                {
                    this.state.alert.show &&
                    <SweetAlertComponent show={this.state.alert.show} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.sweetAlertClose}></SweetAlertComponent>
                }
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
            </div >
        )
    }
}

export default BannerComponent;

