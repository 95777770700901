import React from "react";

class HeaderViewComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: this.props.filters,
            selectedFilter: {}
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.filters !== nextProps.filters) {
            this.setState({
                filters: nextProps.filters
            })
        }
    }

    onFilterValueClicked = (value, key, event) => {
        event.preventDefault();
        if (!this.state.selectedFilter || !this.state.selectedFilter[key]) {
            this.state.selectedFilter[key] = value;
            this.setState(this.state);
        } else {
            let selectFilerValue = this.state.selectedFilter[key];
            if (selectFilerValue !== value) {
                this.state.selectedFilter[key] = value;
                this.setState(this.state);
            } else {
                delete this.state.selectedFilter[key];
                this.setState(this.state);
            }
        }
        this.props.emitSelectedFilerValues(this.state.selectedFilter)
    }


    render() {
        return (
            <div className="template-demo float-right">
                {(this.state.filters && this.state.filters.length > 0) &&
                    <React.Fragment>
                        {this.state.filters.map((indFilter, index) =>
                            <div className="dropdown" key={index}>
                                {indFilter.options && indFilter.options.length > 0 &&
                                    <React.Fragment key={index}>
                                        <button className="btn btn-outline-success dropdown-toggle btn-rounded" type="button" id={"dropdownMenuButton1" + indFilter.key}
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{ textTransform: 'capitalize' }}> {indFilter.display_value} </button>
                                        <div className="dropdown-menu" aria-labelledby={"dropdownMenuButton1" + indFilter.key} style={{ maxHeight: 200, overflowY: 'auto' }}>
                                            {indFilter.options.map((indFilterOption, jndex) =>
                                                <div key={jndex} className="dropdown-item" style={{ cursor: 'pointer' }} onClick={this.onFilterValueClicked.bind(this, indFilterOption.value, indFilter.key)}>
                                                    {indFilterOption.display_value}
                                                    <i className="mdi mdi-check" aria-hidden="true" style={{ display: (this.state.selectedFilter && this.state.selectedFilter[indFilter.key] && this.state.selectedFilter[indFilter.key] === indFilterOption.value) ? '' : 'none', color: '#36a2a1' }}/>
                                                </div>
                                            )}
                                        </div>
                                    </React.Fragment>
                                }
                            </div>
                        )}
                    </React.Fragment>
                }
            </div>
        );
    }
}

export default HeaderViewComponent;
