import React, { Component } from 'react';
import Video from 'twilio-video';
import axios from 'axios';

export default class WaitingComponent extends Component {
    constructor(props) {
        super(props);

    }

    joinRoom=()=> {
        this.props.emitJoinRoomEvent()
    }

    render() {

        return (
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            <div className="card profile-card">
                                <div className="card-body">
                                    <div className="text-center ">
                                        <h5 className="client-name text-success  mb-2 ">Dr. Rajeev</h5>
                                        <p className=" mb-2 mt-0">Online<label style={{ marginLeft: 5 }}
                                            className="badge badge-dark d-inline-block"> </label></p>
                                        <div className="border-top text-left py-2 mt-1 text-center">
                                            <strong>Hi Vedant,</strong>
                                            <span className="d-none d-md-block ">
                                            </span> Please click on check In, To notify doctor you are ready for call. <span className="d-none d-md-block "> </span>Doctor will call you....
                                                   </div>
                                    </div>

                                    <div className="d-flex align-self-center justify-content-between border-top pt-3 ">
                                        <button type="button" onClick={this.joinRoom.bind(this)}
                                            className="btn btn-outline-success btn-fw m-auto animated infinite pulse delay-1s">Click here to Check In</button>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}