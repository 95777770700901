import React from 'react';
import { PrimaryColor } from '../../providerEnum';
import ReactTooltip from 'react-tooltip';

const AccountDetails = ({ ...props }) => {
    let { details } = props;

    let getEmitedDetailChange = (e) => {
        props.getEmitedDetailChange(e);
    }

    let getVideoTimerSettings = (e) => {
        props.getVideoTimerSettings(e)
    }

    let getIvrsSettings = (e) => {
        props.getIvrsSettings(e)
    }

    let getIvrsCheckboxSettings = (e) => {
        props.getIvrsCheckboxSettings(e)
    }

    let getVideoTimerCheckboxSettings = (e) => {
        props.getVideoTimerCheckboxSettings(e)
    }

    let getEmitedRadioChange = (e) => {
        props.getEmitedRadioChange(e);
    }

    let getCheckboxEvent = (e) => {
        props.getCheckboxEvent(e);
    }

    let onServiceDetailAddition = (name, event) => {
        props.emitOnServiceDetailAddition(name, event);
    }

    let getThemeSelectionData = (theme) => {
        let themeData = [
            {
                key: 'default',
                color: '#36a2a1',
                value: "Default",
                selected: false
            },
            {
                key: 'blue',
                color: '#004f71',
                value: "Blue",
                selected: false
            },
            {
                key: 'navyblue',
                color: '#174c91',
                value: "Navy Blue",
                selected: false
            },
            {
                key: 'purple',
                color: '#594082',
                value: "Purple",
                selected: false
            },
            {
                key: 'brown',
                color: '#BD2431',
                value: "Brown",
                selected: false
            },
            {
                key: 'red',
                color: '#ed1c24',
                value: "Red",
                selected: false
            },
            {
                key: 'pink',
                color: '#F56076',
                value: "Pink",
                selected: false
            },
            {
                key: 'orange',
                color: '#ea8137',
                value: "orange",
                selected: false
            }
        ]
        if (!theme) {
            return themeData;
        }
        for (let index in themeData) {
            let indPlaceholder = themeData[index];
            if (theme === indPlaceholder.key) {
                indPlaceholder.selected = true;
                return themeData;
            }
        }

        return themeData;
    }

    let getPlaceholderSelectionData = (message) => {
        let placeholderData = [
            {
                key: 'patient_name',
                selected: false
            },
            {
                key: 'doctor_name',
                selected: false
            }, {
                key: 'patient_name',
                selected: false
            }, {
                key: 'appt_date',
                selected: false
            }, {
                key: 'appt_slot',
                selected: false
            }, {
                key: 'payment_url',
                selected: false
            }, {
                key: 'url',
                selected: false
            }, {
                key: 'payment_order_id',
                selected: false
            }, {
                key: 'account_id',
                selected: false
            }, {
                key: 'room_id',
                selected: false
            }, {
                key: 'prescription_link',
                selected: false
            }
        ]
        if (!message) {
            return placeholderData;
        }
        for (let index in placeholderData) {
            let indPlaceholder = placeholderData[index];
            if (message.indexOf(indPlaceholder.key) !== -1) {
                indPlaceholder.selected = true;
            }
        }

        return placeholderData;
        // patient_name, doctor_name, patient_name, appt_date, appt_slot, payment_url, payment_order_id, url, account_id, room_id, prescription_link
    }

    let onPlaceholderClicked = (messageFieldType, indPlaceholder) => {
        props.emitClickedPlaceholderData(messageFieldType, indPlaceholder);
    }

    let onThemeClicked = (indThemeData) => {
        props.emitClickedThemeData(indThemeData);
    }

    let onDelete = (event) => {
        props.emitDeleteFeeDataEvent(event)
    }

    let TooltipEnum = {
        clinic_name: 'Sets the name of the clinic in patient registration page, patient prescription',
        emergency_info: 'Sets help info in patient registration page',
        emergency_text: 'Sets help info in patient registration page',
        disclaimer: 'Sets disclaimer in patient registration page',
        allow_patient_to_enter_speciality: 'Click on the checkbox to allow patient to enter details for the speciality on patient registration page',
        speciality_required: 'Click on the checkbox to make speciality on patient registration page mandatory',
        complaints_speciality_mapping: 'Based on complaint, doctors will be filtered out and shown on online doctors page',
        specialities: 'Sets the list of speciality of an institution',
        branch_list: 'Enable multi chain of the organization in user profile and patient registration page',
        support_link: 'Sets the info in patient registration page',
        patient_file_upload_text: 'Customizes the text displayed on patient videoroom link for uploading documents',
        redirect_to_videoapp_seconds: 'Redirects to clinytics video app via play store after entered time(seconds) automatically',
        redirection_to_patient_app_off: 'Click the checkbox to disable redirection to clinytics video app',
        slots_required: 'Shows slots of doctor at staff level',
        enable_tele_call: 'Click to enable tele call functionality',
        copy_prescription_to_email: 'Send a copy of prescription to the entered email id at staff level',
        enable_institution: 'Option to enter institution or hospital name',
        allow_patient_to_enter_hospital_id: 'Automatic generation of UHID based on rights, Manual HID to be entered by Patient which can be seen on the info icon',
        select_theme: 'Can select mutiple colour themes and preview them for logo',
        customizable_sms: 'Different message can be customized as per client',
        free_followup_days: 'Configure free days for follow up followed by mutiple fees addition: Eg:- for 3 days to 50% of the fees and 3-5 days 25% and then 100%',
        service_details: 'Can add multiple services additions and cost apart from consultation ( staff level )',
        video_timer_settings: 'Can add video pop time settings.'
    }

    return <>
        <div className="row">
            {/* is_smswallet: false,
                price_per_sms: '', */}
            <div className="form-group mb-3 col-12 col-md-6">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label className="form-check-label" htmlFor="name">
                        <input type="checkbox" className="form-check-input" name="is_smswallet" checked={!!details.is_smswallet}
                            onClick={getCheckboxEvent.bind(this)} /> Is SMSwallet? <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="is_smswallet" /></label>
                </div>
            </div>
            <div className="form-group mb-3 col-12 col-md-6">
                <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Price Per SMS <i className="mdi mdi-information-outline" data-tip data-for="price_per_sms" /></label>
                <input type="number" name="price_per_sms" className="form-control" placeholder={details.price_per_sms ? details.price_per_sms : 'Enter Price Per SMS'} value={details.clinic_name ? details.price_per_sms : ''}
                    onChange={getEmitedDetailChange.bind(this)}
                    aria-controls={details.price_per_sms ? details.price_per_sms : 'Enter Price Per SMS'} />
            </div>
            <div className="form-group mb-3 col-12 col-md-3">
                <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Clinic Name <i className="mdi mdi-information-outline" data-tip data-for="clinic_name" /></label>
                <input type="text" name="clinic_name" className="form-control" placeholder={details.clinic_name ? details.clinic_name : 'Enter clinic name'} value={details.clinic_name ? details.clinic_name : ''}
                    onChange={getEmitedDetailChange.bind(this)}
                    aria-controls={details.clinic_name ? details.clinic_name : 'Enter report name'} />
            </div>
            <div className="form-group mb-3 col-12 col-md-3">
                <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Emergency Info <i className="mdi mdi-information-outline" data-tip data-for="emergency_info" /></label>
                <input type="text" name="emergency_info" className="form-control" placeholder={details.emergency_info ? details.emergency_info : 'Enter emergency info'} value={details.emergency_info ? details.emergency_info : ''}
                    onChange={getEmitedDetailChange.bind(this)}
                    aria-controls={details.emergency_info ? details.emergency_info : 'Enter emergency info'} />
            </div>
            <div className="form-group mb-3 col-12 col-md-3">
                <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Emergency Text <i className="mdi mdi-information-outline" data-tip data-for="emergency_text" /></label>
                <input type="text" name="emergency_txt" className="form-control" placeholder={details.emergency_txt ? details.emergency_txt : 'Enter emergency text'} value={details.emergency_txt ? details.emergency_txt : ''}
                    onChange={getEmitedDetailChange.bind(this)}
                    aria-controls={details.emergency_txt ? details.emergency_txt : 'Enter emergency text'} />
            </div>
            <div className="form-group mb-3 col-12 col-md-3">
                <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Disclaimer <i className="mdi mdi-information-outline" data-tip data-for="disclaimer" /></label>
                <input type="text" name="disclaimer" className="form-control" placeholder={details.disclaimer ? details.disclaimer : 'Enter disclaimer'} value={details.disclaimer ? details.disclaimer : ''}
                    onChange={getEmitedDetailChange.bind(this)}
                    aria-controls={details.disclaimer ? details.disclaimer : 'Enter disclaimer'} />
            </div>
        </div>
        <div className="row border-bottom mb-3">
            <div className="form-group mb-3 col-12 col-md-4">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Transaction Report To Email</label>
                    <input type="text" name="transaction_report_to_email"
                        className="form-control"
                        placeholder={'Enter Support EmailId'}
                        value={details.transaction_report_to_email ? details.transaction_report_to_email : ''}
                        onChange={getEmitedDetailChange.bind(this)}
                        aria-controls={'Enter Support EmailId'} />

                </div>
            </div>
            <div className="form-group mb-3 col-12 col-md-3">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label className="form-check-label" htmlFor="name">
                        <input type="checkbox" className="form-check-input" name="use_third_party" checked={!!details.use_third_party}
                            onClick={getCheckboxEvent.bind(this)} /> Use Third Party? <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="use_third_party" /></label>
                </div>
            </div>
            <div className="form-group mb-3 col-12 col-md-3">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label className="form-check-label" htmlFor="name">
                        <input type="checkbox" className="form-check-input" name="is_patient_speciality" checked={!!details.is_patient_speciality}
                            onClick={getCheckboxEvent.bind(this)} /> Allow Patient to enter Speciality? <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="allow_patient_to_enter_speciality" /></label>
                </div>
            </div>
            {details.is_patient_speciality && <div className="form-group mb-3 col-12 col-md-3">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label className="form-check-label" htmlFor="name">
                        <input type="checkbox" className="form-check-input" name="is_speciality_req" checked={details.is_speciality_req ? true : false}
                            onClick={getCheckboxEvent.bind(this)} /> Speciality Required? <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="speciality_required" /></label>
                </div>
            </div>}
            <div className="form-group mb-3 col-12 col-md-3">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label className="form-check-label" htmlFor="name">
                        <input type="checkbox" className="form-check-input" name="ask_patient_symptoms" checked={details.ask_patient_symptoms ? true : false}
                            onClick={getCheckboxEvent.bind(this)} />Show Patient Symptoms? <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="ask_patient_symptoms
                            " /></label>
                </div>
            </div>
            <div className="form-group mb-3 col-12 col-md-3">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Translation Categories <i className="mdi mdi-information-outline" data-tip data-for="translation_categories" /></label>
                    <input type="text" name="translation_categories" className="form-control" placeholder={details.translation_categories ? details.translation_categories : 'Enter emergency info'} value={details.translation_categories ? details.translation_categories : ''}
                        onChange={getEmitedDetailChange.bind(this)}
                        aria-controls={details.translation_categories ? details.translation_categories : 'Enter emergency info'} />
                </div>
            </div>
            {/* translation_categories */}
            {details.is_patient_speciality && <div className="form-group mb-3 col-12 col-md-3">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label className="form-check-label" htmlFor="name">
                        <input type="checkbox" className="form-check-input" name="speclty_complaints" checked={details.speclty_complaints.show ? true : false}
                            onClick={props.onSpeciCompCheckClick.bind(this)} /> Complaints/Speciality Mapping? <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="complaints_speciality_mapping" /></label>
                </div>
            </div>}
            {details.is_patient_speciality && <div className="form-group mb-3 col-12 col-md-3">
                <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Specialities* <i className="mdi mdi-information-outline" data-tip data-for="specialities" /><small className="text-muted error-text"> (',' separated i.e.internal medicine,urology...)</small></label>
                <textarea type="text" rows="2" name="specialities" className="form-control" placeholder={details.specialities ? details.specialities : 'Enter specialities'} value={details.specialities ? details.specialities : ''}
                    onChange={getEmitedDetailChange.bind(this)}
                    aria-controls={details.specialities ? details.specialities : 'Enter specialities'} />
                {(!details.specialities || !details.specialities.trim()) && <small className="error-text text-danger">*Required</small>}
            </div>}
            {details.is_patient_speciality && details.speclty_complaints.show && <>
                <div className="form-group mb-3 border-bottom col-12">
                    <div className="row">
                        <div className="col-12 pr-0">
                            <label htmlFor="name" style={{ textTransform: 'capitalize' }}><strong>Speciality {`<->`} Complaints</strong></label>
                            <hr className="m-1"></hr>
                        </div>
                        {details && details.speclty_complaints && details.speclty_complaints.map && (details.speclty_complaints.map).map((item, idx) => <>
                            <div className="col-3 pr-0">
                                <input type="text" name="speci" className="form-control" value={item.speci}
                                    onChange={props.onSpeciCompAddition.bind(this, idx)} placeholder="Enter speciality" />
                            </div>
                            <div className="col-5 pr-0">
                                <input type="text" name="complaint" className="form-control" value={item.complaint}
                                    onChange={props.onSpeciCompAddition.bind(this, idx)} placeholder="Enter complaints Eg: Fever/Headache/Vomiting" />
                            </div>
                            <div className="col-2 pl-1">
                                <button className="btn btn-sm btn-outline-info radius-0" type="button" onClick={props.incSpeciComp.bind(this, idx)}><i className="mdi mdi-plus" /></button>
                                {idx > 0 && <button className="btn btn-sm btn-outline-danger radius-0" type="button" onClick={props.decSpeciComp.bind(this, idx)}><i className="mdi mdi-minus" /></button>}
                            </div>
                        </>)}
                    </div>
                </div>
            </>}
            <div className="form-group mb-3 col-12 col-md-3">
                <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Branch List <small className="text-muted error-text">(',' separated i.e.Gurgaon,delhi...) <i className="mdi mdi-information-outline" data-tip data-for="branch_list" /></small></label>
                <textarea type="text" rows="2" name="branches" className="form-control" placeholder={details.branches ? details.branches : 'Enter Branch List'} value={details.branches ? details.branches : ''}
                    onChange={getEmitedDetailChange.bind(this)}
                    aria-controls={details.branches ? details.branches : 'Enter Branch List'} />
            </div>
            {details.branches && details.branches !== '-' &&
                <div className="form-group mb-3 col-12 col-md-12">
                    <BranchPincodeMap branches={details.branches ? Array.isArray(details.branches) ? details.branches : details.branches.split(',') : []} value={details.branches ? details.branches : ''}
                        name="branch_pincode" onChange={getEmitedDetailChange.bind(this)} value={details.branch_pincode ? details.branch_pincode : {}} />
                </div>}
        </div>
        <div className="row border-bottom">
            <div className="form-group mb-3 col-12 col-md-3">
                <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Support Link (WhatsApp Number) <i className="mdi mdi-information-outline" data-tip data-for="support_link" /></label>
                <input type="text" name="support_link" className="form-control" placeholder={details.support_link ? details.support_link : 'Enter Support Link'} value={details.support_link ? details.support_link : ''}
                    onChange={getEmitedDetailChange.bind(this)}
                    aria-controls={details.support_link ? details.support_link : 'Enter Support Link'} />
            </div>
            <div className="form-group mb-3 col-12 col-md-3">
                <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Patient's File Upload Text <i className="mdi mdi-information-outline" data-tip data-for="patient_file_upload_text" /></label>
                <textarea rows="2" type="text" name="upload_text" className="form-control" placeholder={details.upload_text ? details.upload_text : 'Enter Upload Text'} value={details.upload_text ? details.upload_text : ''}
                    onChange={getEmitedDetailChange.bind(this)}
                    aria-controls={details.upload_text ? details.upload_text : 'Enter Upload Text'} />
            </div>
            <div className="form-group mb-3 col-12 col-md-3">
                <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Redirect to Videoapp in: <i className="mdi mdi-information-outline" data-tip data-for="redirect_to_videoapp_seconds" /></label>
                <input type="text" name="redirect_time" className="form-control" placeholder={details.redirect_time ? details.redirect_time : '15'} value={details.redirect_time ? details.redirect_time : ''}
                    onChange={getEmitedDetailChange.bind(this)}
                    aria-controls={details.redirect_time ? details.redirect_time : 'Enter redirection time to video app'} /><label>seconds</label>
            </div>
            <div className="form-group mb-3 col-12 col-md-3">
                <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                    <label className="form-check-label" htmlFor="name">
                        <input type="checkbox" className="form-check-input" name="redirectOff" checked={details.redirectOff ? true : false}
                            onClick={getCheckboxEvent.bind(this)} /> Redirection To Patient App Off (For chinese browser) <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="redirection_to_patient_app_off" /></label>
                </div>
            </div>
        </div>
        <div className="d-flex flex-wrap border-bottom mb-3">
            <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                <label className="form-check-label" htmlFor="name" style={{ textTransform: 'uppercase' }}>
                    <input type="checkbox" className="form-check-input" name="slots_required" id="slots_required" checked={details.slots_required ? true : false}
                        onClick={getCheckboxEvent.bind(this)} /> Slots Required <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="slots_required" /> </label>
            </div>
            <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                <label className="form-check-label" htmlFor="name" style={{ textTransform: 'uppercase' }}>
                    <input type="checkbox" className="form-check-input" name="enable_tele_call" id="enable_tele_call" checked={details.enable_tele_call ? true : false}
                        onClick={getCheckboxEvent.bind(this)} /> Enable Tele Call <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="enable_tele_call" /> </label>
            </div>
            <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                <label className="form-check-label" htmlFor="name" style={{ textTransform: 'uppercase' }}>
                    <input type="checkbox" className="form-check-input" name="is_copy_email" checked={details.is_copy_email ? true : false}
                        onClick={getCheckboxEvent.bind(this)} /> Copy Prescription to email? <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="copy_prescription_to_email" /> </label>
            </div>
            <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                <label className="form-check-label" htmlFor="name" style={{ textTransform: 'uppercase' }}>
                    <input type="checkbox" className="form-check-input" name="institution" checked={details.institution ? true : false}
                        onClick={getCheckboxEvent.bind(this)} /> Enable institution? <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="enable_institution" /> </label>
            </div>
            <div className="form-check form-check-danger" style={{ marginRight: '40px', maxWidth: '200px' }}>
                <label className="form-check-label" htmlFor="name" style={{ textTransform: 'uppercase' }}>
                    <input type="checkbox" className="form-check-input" name="is_patient_hid" checked={!!details.is_patient_hid}
                        onClick={getCheckboxEvent.bind(this)} /> Allow Patient to enter Hospital ID? <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="allow_patient_to_enter_hospital_id" /> </label>
            </div>

        </div>
        <div className="form-group mb-3 border-bottom mb-3">
            <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Select Theme <i className="mdi mdi-information-outline" data-tip data-for="select_theme" /></label>
            <div className="ml-0 p-2">
                {getThemeSelectionData(details.theme).map((indPlaceholder, index) =>
                    <span key={index} className={`badge mr-1`} style={{ cursor: 'pointer', color: 'white', backgroundColor: indPlaceholder.color, border: indPlaceholder.selected && '4px solid red' }} onClick={onThemeClicked.bind(this, indPlaceholder)}>{indPlaceholder.value}</span>
                )}
                <label className="text-muted mr-3" style={{ marginLeft: '30px' }}>
                    Logo with white BG
                    <img style={{ cursor: 'pointer', display: 'block', border: details.logo_bg_color === 'white' && '4px solid red', width: "120px" }} src="/images/clinytics_logo.png" onClick={props.setLogoBg.bind(this, 'white')} />
                </label>
                <label className="text-muted mr-3">
                    Logo with theme BG
                    <img style={{ cursor: 'pointer', display: 'block', border: details.logo_bg_color === 'theme' && '4px solid red', backgroundColor: details.theme && PrimaryColor[details.theme] || PrimaryColor['default'], width: '120px' }} src="/images/logo.png" onClick={props.setLogoBg.bind(this, 'theme')} />
                </label>
                <label className="text-muted">
                    No Logo BG
                    <div style={{ cursor: 'pointer', height: '35px', display: 'block', border: details.logo_bg_color === 'none' && '4px solid red' || '2px solid grey', width: '120px' }} onClick={props.setLogoBg.bind(this, 'none')}>No BG</div>
                </label>
            </div>
        </div>
        <div className="form-group mb-3 border-bottom mb-3">
            <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Payment Message <i className="mdi mdi-information-outline" data-tip data-for="customizable_sms" /></label>
            <div className="ml-0 p-2">
                {getPlaceholderSelectionData(details.payment_message).map((indPlaceholder, index) =>
                    <span key={index} className={`badge ${indPlaceholder.selected ? 'badge-gradient-info' : 'badge-outline-info'} mr-1`} style={{ cursor: 'pointer' }} onClick={onPlaceholderClicked.bind(this, 'payment_message', indPlaceholder)}>{indPlaceholder.key}</span>
                )}
            </div>
            <textarea rows="4" type="text" name="payment_message" className="form-control" placeholder={details.payment_message ? details.payment_message : 'Enter Payment Message'} value={details.payment_message ? details.payment_message : ''}
                onChange={getEmitedDetailChange.bind(this)}
                aria-controls={details.payment_message ? details.payment_message : 'Enter Payment Message'} />
        </div>
        <div className="form-group mb-3 border-bottom mb-3">
            <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Chat Message <i className="mdi mdi-information-outline" data-tip data-for="customizable_sms" /></label>
            <div className="ml-0 p-2">
                {getPlaceholderSelectionData(details.chat_message).map((indPlaceholder, index) =>
                    <span key={index} className={`badge ${indPlaceholder.selected ? 'badge-gradient-info' : 'badge-outline-info'} mr-1`} style={{ cursor: 'pointer' }} onClick={onPlaceholderClicked.bind(this, 'chat_message', indPlaceholder)}>{indPlaceholder.key}
                    </span>
                )}
            </div>
            <textarea rows="4" type="text" name="chat_message" className="form-control" placeholder={details.chat_message ? details.chat_message : 'Enter Chat Message'} value={details.chat_message ? details.chat_message : ''}
                onChange={getEmitedDetailChange.bind(this)}
                aria-controls={details.chat_message ? details.chat_message : 'Enter Chat Message'} />
        </div>
        <div className="form-group mb-3 border-bottom mb-3">
            <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Prescription Message <i className="mdi mdi-information-outline" data-tip data-for="customizable_sms" /></label>
            <div className="ml-0 p-2">
                {getPlaceholderSelectionData(details.prescription_message).map((indPlaceholder, index) =>
                    <span key={index} className={`badge ${indPlaceholder.selected ? 'badge-gradient-info' : 'badge-outline-info'} mr-1`} style={{ cursor: 'pointer' }}
                        onClick={onPlaceholderClicked.bind(this, 'prescription_message', indPlaceholder)}>{indPlaceholder.key}</span>
                )}
            </div>
            <textarea rows="4" type="text" name="prescription_message" className="form-control" placeholder={details.prescription_message ? details.prescription_message : 'Enter Prescription Message'} value={details.prescription_message ? details.prescription_message : ''}
                onChange={getEmitedDetailChange.bind(this)}
                aria-controls={details.prescription_message ? details.prescription_message : 'Enter Prescription Message'} />
        </div>
        <div className="form-group mb-3 border-bottom mb-3">
            <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Doctor Appointment Message <i className="mdi mdi-information-outline" data-tip data-for="customizable_sms" /></label>
            {/* <span className="badge badge-gradient-info mr-1" style="cursor: pointer;">patientName</span> */}
            <div className="ml-0 p-2">
                {getPlaceholderSelectionData(details.doctor_appointment_message).map((indPlaceholder, index) =>
                    <span key={index} className={`badge ${indPlaceholder.selected ? 'badge-gradient-info' : 'badge-outline-info'} mr-1`} style={{ cursor: 'pointer' }}
                        onClick={onPlaceholderClicked.bind(this, 'doctor_appointment_message', indPlaceholder)}>{indPlaceholder.key}</span>
                )}
            </div>
            <textarea rows="4" type="text" name="doctor_appointment_message" className="form-control" placeholder={details.doctor_appointment_message ? details.doctor_appointment_message : 'Enter Doctor Appointment Message'} value={details.doctor_appointment_message ? details.doctor_appointment_message : ''}
                onChange={getEmitedDetailChange.bind(this)}
                aria-controls={details.doctor_appointment_message ? details.doctor_appointment_message : 'Enter Doctor Appointment Message'} />
        </div>
        {/* payment_message, chat_message, prescription_message, doctor_appointment_message */}
        <div className="form-group mb-3">
            <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Free Follow-up Days <i className="mdi mdi-information-outline" data-tip data-for="free_followup_days" /></label>
            <input type="text" name="free_follow_days" className="form-control" placeholder={details.free_follow_days ? details.free_follow_days : 'Enter Free Follow-up Days'} value={details.free_follow_days ? details.free_follow_days : ''}
                onChange={getEmitedDetailChange.bind(this)}
                aria-controls={details.free_follow_days ? details.free_follow_days : 'Enter Free Follow-up Days'} />
        </div>
        <div className="form-group mb-3 border-bottom mb-3">
            <div className="row">
                <div className="col-12 pr-0">
                    <label htmlFor="name" style={{ textTransform: 'capitalize' }}><strong>Service Details</strong> <i className="mdi mdi-information-outline" data-tip data-for="service_details" /></label>
                    <hr className="m-1"></hr>
                </div>
                {/* serviceName: '',
            serviceCost: '' */}
                <div className="col-5 pr-0">
                    {/* <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Service Name</label> */}
                    <input type="text" name="serviceName" className="form-control" placeholder={details.serviceName ? details.serviceName : 'Enter Service Name'} value={details.serviceName ? details.serviceName : ''}
                        onChange={onServiceDetailAddition.bind(this, 'serviceName')}
                        aria-controls={details.serviceName ? details.serviceName : 'Enter Free Follow-up Days'} />
                </div>
                <div className="col-5 pr-0">
                    {/* <label htmlFor="name" style={{ textTransform: 'capitalize' }}>Service Fee</label> */}
                    <input type="text" name="serviceCost" className="form-control" placeholder={details.serviceCost ? details.serviceCost : 'EnterService Fee'} value={details.serviceCost ? details.serviceCost : ''}
                        onChange={onServiceDetailAddition.bind(this, 'serviceCost')}
                        aria-controls={details.serviceCost ? details.serviceCost : 'Enter Service Fee'} />
                </div>
                <div className="col-2 pl-1">
                    <button className="btn btn-sm btn-outline-success radius-0" disabled={!details.serviceName || !details.serviceCost} type="button" onClick={onServiceDetailAddition.bind(this, '')}>+Add</button>
                </div>
            </div>
        </div>
        <div className="form-group mb-3 border-bottom">
            <div className="row">
                <div className="col-12 pr-0">
                    <label htmlFor="name" style={{ textTransform: 'capitalize' }}><strong>IVRS Reminder Settings</strong> <i className="mdi mdi-information-outline" data-tip data-for="ivrs_reminder_settings" /></label>
                    <hr className="m-1"></hr>
                </div>
                <div className="col-4 mt-2 mb-2">
                    <label htmlFor="name" style={{ textTransform: 'uppercase' }}> Expiry Gap</label>
                    <input type="number" name="expiry_gap" className="form-control" placeholder={(details.ivrs_reminder_settings && details.ivrs_reminder_settings.expiry_gap) ? details.ivrs_reminder_settings.expiry_gap : 'Enter Expiry Gap'} value={(details.ivrs_reminder_settings && details.ivrs_reminder_settings.expiry_gap) ? details.ivrs_reminder_settings.expiry_gap : ''}
                        onChange={getIvrsSettings.bind(this)}
                        aria-controls={(details.ivrs_reminder_settings && details.ivrs_reminder_settings.expiry_gap) ? details.ivrs_reminder_settings.expiry_gap : 'Enter Expiry Gap'} />
                </div>
                <div className="col-4 mt-2 mb-2">
                    <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Gap</label>
                    <input type="number" name="gap" className="form-control" placeholder={(details.ivrs_reminder_settings && details.ivrs_reminder_settings.gap) ? details.ivrs_reminder_settings.gap : 'Enter Gap'} value={(details.ivrs_reminder_settings && details.ivrs_reminder_settings.gap) ? details.ivrs_reminder_settings.gap : ''}
                        onChange={getIvrsSettings.bind(this)}
                        aria-controls={(details.ivrs_reminder_settings && details.ivrs_reminder_settings.gap) ? details.ivrs_reminder_settings.gap : 'Enter Gap'} />
                </div>
                <div className="col-4 mt-2 mb-2">
                    <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Voice Message</label>
                    <textarea type="text" rows="2" name="voice_message" className="form-control" placeholder={(details.ivrs_reminder_settings && details.ivrs_reminder_settings.voice_message) ? details.ivrs_reminder_settings.voice_message : 'Enter Voice Message'} value={(details.ivrs_reminder_settings && details.ivrs_reminder_settings.voice_message) ? details.ivrs_reminder_settings.voice_message : ''}
                        onChange={getIvrsSettings.bind(this)}
                        aria-controls={(details.ivrs_reminder_settings && details.ivrs_reminder_settings.voice_message) ? details.ivrs_reminder_settings.voice_message : 'Enter Voice Message'} />
                </div>
                <div className="form-group mb-3 col-12 col-md-3">
                    <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                        <label className="form-check-label" htmlFor="name">
                            <input type="checkbox" className="form-check-input" name="is_active" checked={(details.ivrs_reminder_settings && details.ivrs_reminder_settings.is_active) ? true : false}
                                onClick={getIvrsCheckboxSettings.bind(this)} />Is Active? <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="is_active
                            " /></label>
                    </div>
                </div>
            </div>
        </div>

        <div className="form-group mb-3 border-bottom">
            <div className="row">
                <div className="col-12 pr-0">
                    <label htmlFor="name" style={{ textTransform: 'capitalize' }}><strong>Video  Timer Settings</strong> <i className="mdi mdi-information-outline" data-tip data-for="video_timer_settings" /></label>
                    <hr className="m-1"></hr>

                </div>
                <div className="col-4 mt-2 mb-2">
                    <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Slot Time</label>
                    <input type="number" name="slot_time" className="form-control" placeholder={(details.video_timer_settings && details.video_timer_settings.slot_time) ? details.video_timer_settings.slot_time : 'Enter Slot Time'} value={(details.video_timer_settings && details.video_timer_settings.slot_time) ? details.video_timer_settings.slot_time : ''}
                        onChange={getVideoTimerSettings.bind(this)}
                        aria-controls={(details.video_timer_settings && details.video_timer_settings.slot_time) ? details.video_timer_settings.slot_time : 'Enter Slot Time'} />
                </div>
                <div className="col-4 mt-2 mb-2">
                    <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Popup Time</label>
                    <input type="number" name="popup_time" className="form-control" placeholder={(details.video_timer_settings && details.video_timer_settings.popup_time) ? details.video_timer_settings.popup_time : 'Enter Popup Time'} value={(details.video_timer_settings && details.video_timer_settings.popup_time) ? details.video_timer_settings.popup_time : ''}
                        onChange={getVideoTimerSettings.bind(this)}
                        aria-controls={(details.video_timer_settings && details.video_timer_settings.popup_time) ? details.video_timer_settings.popup_time : 'Enter Popup Time'} />
                </div>
                <div className="col-4 mt-2 mb-2">
                    <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Doc Alert Time</label>
                    <input type="number" name="doc_alert_time" className="form-control" placeholder={(details.video_timer_settings && details.video_timer_settings.doc_alert_time) ? details.video_timer_settings.doc_alert_time : 'Enter Popup Time'} value={(details.video_timer_settings && details.video_timer_settings.doc_alert_time) ? details.video_timer_settings.doc_alert_time : ''}
                        onChange={getVideoTimerSettings.bind(this)}
                        aria-controls={(details.video_timer_settings && details.video_timer_settings.doc_alert_time) ? details.video_timer_settings.doc_alert_time : 'Enter Popup Time'} />
                </div>
                <div className="col-4 mt-2 mb-2">
                    <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Popup Message</label>
                    <textarea type="text" rows="2" name="popup_message" className="form-control" placeholder={(details.video_timer_settings && details.video_timer_settings.popup_message) ? details.video_timer_settings.popup_message : 'Enter Popup Message'} value={(details.video_timer_settings && details.video_timer_settings.popup_message) ? details.video_timer_settings.popup_message : ''}
                        onChange={getVideoTimerSettings.bind(this)}
                        aria-controls={(details.video_timer_settings && details.video_timer_settings.popup_message) ? details.video_timer_settings.popup_message : 'Enter Popup Message'} />
                </div>

                <div className="col-4 mt-2 mb-2">
                    <label htmlFor="name" style={{ textTransform: 'uppercase' }}>Alert Emails</label>
                    <input type="text" name="alert_emails" className="form-control" placeholder={(details.video_timer_settings && details.video_timer_settings.alert_emails) ? details.video_timer_settings.alert_emails : 'Enter Alert Emails'} value={(details.video_timer_settings && details.video_timer_settings.alert_emails) ? details.video_timer_settings.alert_emails : ''}
                        onChange={getVideoTimerSettings.bind(this)}
                        aria-controls={(details.video_timer_settings && details.video_timer_settings.alert_emails) ? details.video_timer_settings.alert_emails : 'Enter Alert Emails'} />
                </div>
                <div className="form-group mb-3 col-12 col-md-3">
                    <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                        <label className="form-check-label" htmlFor="name">
                            <input type="checkbox" className="form-check-input" name="enable_popup" checked={(details.video_timer_settings && details.video_timer_settings.enable_popup) ? true : false}
                                onClick={getVideoTimerCheckboxSettings.bind(this)} />Enable Popup? <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="enable_popup
                            " /></label>
                    </div>
                </div>
                <div className="form-group mb-3 col-12 col-md-3">
                    <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                        <label className="form-check-label" htmlFor="name">
                            <input type="checkbox" className="form-check-input" name="is_active" checked={(details.video_timer_settings && details.video_timer_settings.is_active) ? true : false}
                                onClick={getVideoTimerCheckboxSettings.bind(this)} />is_active? <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="is_active
                            " /></label>
                    </div>
                </div>
                <div className="form-group mb-3 col-12 col-md-3">
                    <div className="form-check form-check-danger" style={{ marginRight: '40px' }}>
                        <label className="form-check-label" htmlFor="name">
                            <input type="checkbox" className="form-check-input" name="enable_doc_alert" checked={(details.video_timer_settings && details.video_timer_settings.enable_doc_alert) ? true : false}
                                onClick={getVideoTimerCheckboxSettings.bind(this)} />Enable Doc Alert? <i className="input-helper"></i> <i className="mdi mdi-information-outline" data-tip data-for="enable_doc_alert
                            " /></label>
                    </div>
                </div>
            </div>
        </div>
        <div className="form-group mb-3 border-bottom">
            <ul className="list-group">
                {details.services_fees && details.services_fees.length > 0 &&
                    <>
                        {details.services_fees.map((indFee, index) =>
                            <li className="list-group-item d-flex justify-content-between align-items-center p-1" key={index}>{indFee.serviceName} : {indFee.serviceCost} <span className="badge badge-danger badge-pill" onClick={onDelete.bind(this, index)} style={{ cursor: 'pointer' }}>X</span></li>
                        )}
                    </>
                }
            </ul>
        </div>
        {Object.keys(TooltipEnum).map(key => <ReactTooltip id={key} effect='solid'>
            <span>{TooltipEnum[key]}</span>
        </ReactTooltip>)}
    </>;
}

export default AccountDetails;

export const BranchPincodeMap = ({ branches, onChange, value }) => {
    const onPincodeChange = (branch, event) => {
        if (!value || !value[branch]) {
            value = { ...value, [branch]: [] }
        }
        value[branch] = event.target.value ? event.target.value.split(',').map(pin => pin ? pin.trim() : pin) : [];
        onChange({ target: { name: 'branch_pincode', value: value } })
    }
    return <>
        <div>
            <label htmlFor="name" style={{ textTransform: 'capitalize' }}><strong>Branch Pincodes</strong></label>
            <hr className="m-1"></hr>
        </div>
        {branches && branches.length > 0 && branches.map(branch =>
            branch && branch.trim() &&
            <div className="row mb-2">
                <div className="col-2">
                    <label htmlFor="name" style={{ textTransform: 'capitalize' }}>{branch}</label>
                </div>
                <div className="col-8">
                    <input type="text" name="pincode" className="form-control" placeholder={"Enter comma(',') separated pincodes"} value={value[branch] ? value[branch].join() : ''}
                        onChange={onPincodeChange.bind(this, branch)} />
                </div>
            </div>)}
    </>;
}