import React from "react";

class Radio extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            options: this.props.optionData.options //array of options
        }
        if (this.props.dynamicValue) {
            this.changeCheckedStatus(this.props.dynamicValue);
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.dynamicValue){
            this.changeCheckedStatus(nextProps.dynamicValue);
        }
    }

    onInputChange = (e) => {
        this.changeCheckedStatus(e.target.value);
        this.props.onInputChange({ name: this.props.inputData.name, value: e.target.value, type: this.props.inputData.type })
    }

    changeCheckedStatus = (value) => {
        const { options } = this.state;
        for (let index in options) {
            if (options[index].value === value) {
                options[index].checked = true;
            } else {
                options[index].checked = false;
            }
        }
        this.setState({ options });
    }

    componentWillUnmount() {
        let { options } = this.state;
        for (let index in options) {
            options[index].checked = false;
        }
        this.setState({ options });
    }

    render() {
        const { name, optionColSize } = this.props.inputData;
        const {accountInfo} = this.props
       
        return (
            <React.Fragment>
                <div className="row">
                   
                   {
                       accountInfo && accountInfo.enable_opd_flow === true ? this.state.options && this.state.options.slice(0,2).map((option, index) =>
                       <div key={index} className={`col-lg-${optionColSize}`}>
                           <div className="form-check">
                               <label className="form-check-label" style={{ fontWeight: 500 }}>
                                   <input type="radio" className="form-check-input" name={name} id={name} value={option.value || ''} checked={this.state.options[index].checked} onChange={this.onInputChange} />
                                   {option.value}
                                   <i className="input-helper"></i>
                               </label>
                           </div>
                       </div>) : this.state.options && this.state.options.map((option, index) =>
                        <div key={index} className={`col-lg-${optionColSize}`}>
                            <div className="form-check">
                                <label className="form-check-label" style={{ fontWeight: 500 }}>
                                    <input type="radio" className="form-check-input" name={name} id={name} value={option.value || ''} checked={this.state.options[index].checked} onChange={this.onInputChange} />
                                    {option.value}
                                    <i className="input-helper"></i>
                                </label>
                            </div>
                        </div>)
                   }
                    
                </div>
            </React.Fragment>
        );
    }
}

export default Radio;
