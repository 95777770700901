import request from '../Api/request';
import userAuth from '../Authentication/authActions';
import Utils from "../Shared/utils/utils";
function saveUserSupportData(data) {
    return request({
        url: "/user/support",
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function postDoctorComplaint(data) {
    let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1 || window.location.hostname.indexOf('d32uc620y3be9') !== -1 || window.location.hostname.indexOf('v4abbottclinytics') !== -1) ? 'dev' : 'prod';
    let api;
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/payments/` : `https://emrapi.hlthclub.in/payments/`;
        //api = `https://emrapi.hlthclub.in/payments/`;
    } else {
        api = `https://betaemrapi.hlthclub.in/payments/`
    }
    return request({
        baseURL: api,
        url:  "/onlineconsult/doctor-complaint",
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function postPatientComplaint(data) {
    let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1 || window.location.hostname.indexOf('d32uc620y3be9') !== -1 || window.location.hostname.indexOf('v4abbottclinytics') !== -1) ? 'dev' : 'prod';
    let api;
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/payments/` : `https://emrapi.hlthclub.in/payments/`;
        //api = `https://emrapi.hlthclub.in/payments/`;
    } else {
        api = `https://betaemrapi.hlthclub.in/payments/`
    }
    return request({
        baseURL: api,
        url: "/onlineconsult/patient-complaint",
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}
// POST - https://1vpstx4hnk.execute-api.ap-south-1.amazonaws.com/prod/user/support (base url common for dev, prod users)
// Body json payload - "subject" - string
//                                  "query"   - string

function initiateRefund(data) {
    let api = userAuth.getRefundApiUrl('url');
    return request({
        baseURL: api,
        url: `/onlineconsult/initiate-refund`,
        method: 'post',
        data: data
    });
}

const supportService = {
    saveUserSupportData,
    postPatientComplaint,
    postDoctorComplaint,
    initiateRefund
}

export default supportService;