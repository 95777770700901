const paths = {
    abbott: {
        logodark: '/images/abbott/logo-dark.png',
        logolight: '/images/abbott/logo-light.png',
        minilogodark: '/images/abbott/logo-mini-dark.png',
        minilogolight: '/images/abbott/logo-mini-dark.png',
        sadface: '/images/abbott/no-prescription.png',
        favicon: '/images/abbott/favicon.png',
        loginslide: '/images/abbott/Vector Smart Object1@2x.png',
        defaultBgColor: '#004f71',
        faqBgColor: '#004f71',
        faqText: '#ffffff',
        loginSlideLabel: 'Your personal Virtual Clinic Manager is here',
        loginSlideSublabel: 'For any help, write to us at ',
        email: 'docclinicsupport@thb.co.in',
        isTermsBinding: true,
        aboutUsHeading: 'Welcome to DocClinic!',
        aboutUsContent: 'DocClinic is your Virtual Practice Manager that will enable you to consult with your patient seamlessly in person and through video-consultation. With DocClinic you can write prescriptions, in your preferred way – your regular prescription pad, Pen and Paper, Tablet, or Web Based.\
        DocClinic is more than just a means for the patient-clinician to connect. It allows you to become a part of the major initiatives and programs that are launched digitally. We keep you abreast with the digital launches of new products, new patient support programs, and even new clinical research studies and facilitates your participation in an easy to manage way.\
        THB (Sekhmet Technologies Pvt. Ltd.) is the proprietary developer and owner of DocClinic, which shall mean the mobile application by the name DocClinic, and all variants, tools, editions, add-ons, and ancillary products or services of DocClinic including all files and images contained therein or generated by the software, and accompanying data.\
         Wish you good health!',
        privacyUrl: 'https://docclinicemrdocs.s3.ap-south-1.amazonaws.com/docs/privacy.html',
        termsUrl: 'https://docclinicemrdocs.s3.ap-south-1.amazonaws.com/docs/terms_condition.html',
        disclaimerUrl: 'https://docclinicemrdocs.s3.ap-south-1.amazonaws.com/docs/disclaimer.html'
    },
    thb: {
        logodark: '/images/clinytics_logo.png',
        logolight: '/images/logo.png',
        minilogodark: '/images/clinytics-small.png',
        minilogolight: '/images/logo-mini.png',
        sadface: '/images/sad-face.png',
        favicon: '/images/pwaico.png',
        loginslide: '/images/intro1.png',
        defaultBgColor: 'green',
        faqText: '#222',
        faqBgColor: '#f4f4f4',
        email: '',
        isTermsBinding: false,
        loginSlideLabel: 'Digital solution Providing  access to manage your healthcare 24 hours a day',
        loginSlideSublabel: 'Book appointments, Digitize handwritten Prescription,follow-up reminders . Available 24/7, wherever you are, on desktop, tablet, or mobile devices',
        aboutUsHeading: 'Welcome to Breath!',
        aboutUsContent: 'Breathefree app has been developed by THB. Our purpose is to seamlessly digitize handwritten prescriptions in the form of digital records that can be accessed anytime. We are committed to help providers deliver excellent patient care even after the consultation ends. THB (Technology | Healthcare | Big Data Analytics) is India’s leading clinical intelligence company. We create products and services to enable innovation and research in healthcare for positive patient outcomes.',
        privacyUrl: 'https://healthylabs.s3.ap-southeast-1.amazonaws.com/clinytics/docs/breathefree/patient_privacy.html',
        privacyUrlEducator: 'https://healthylabs.s3.ap-southeast-1.amazonaws.com/clinytics/docs/breathefree/educator_privacy.html',
        termsUrl: 'https://healthylabs.s3.ap-southeast-1.amazonaws.com/clinytics/docs/breathefree/educator_tnc.html',
        disclaimerUrl: 'https://healthylabs.s3.ap-southeast-1.amazonaws.com/clinytics/docs/breathefree/patient_tnc.html'
        /*
        https://healthylabs.s3.ap-southeast-1.amazonaws.com/clinytics/docs/breathefree/patient_privacy.html
        https://healthylabs.s3.ap-southeast-1.amazonaws.com/clinytics/docs/breathefree/patient_tnc.html
        https://healthylabs.s3.ap-southeast-1.amazonaws.com/clinytics/docs/breathefree/educator_privacy.html
        https://healthylabs.s3.ap-southeast-1.amazonaws.com/clinytics/docs/breathefree/educator_tnc.html
        */
    },
    meitra: {
        logodark: '/images/clinytics_logo.png',
        logolight: '/images/logo.png',
        minilogodark: '/images/clinytics-small.png',
        minilogolight: '/images/logo-mini.png',
        sadface: '/images/sad-face.png',
        favicon: '/images/pwaico.png',
        loginslide: '/images/intro1.png',
        defaultBgColor: 'green',
        faqText: '#222',
        faqBgColor: '#f4f4f4',
        email: '',
        isTermsBinding: false,
        loginSlideLabel: 'Digital solution Providing  access to manage your healthcare 24 hours a day',
        loginSlideSublabel: 'Book appointments, Digitize handwritten Prescription,follow-up reminders . Available 24/7, wherever you are, on desktop, tablet, or mobile devices',
        aboutUsHeading: 'Welcome to Breathefree!',
        aboutUsContent: 'Breathefree app has been developed by THB. Our purpose is to seamlessly digitize handwritten prescriptions in the form of digital records that can be accessed anytime. We are committed to help providers deliver excellent patient care even after the consultation ends. THB (Technology | Healthcare | Big Data Analytics) is India’s leading clinical intelligence company. We create products and services to enable innovation and research in healthcare for positive patient outcomes.',
        privacyUrl: 'https://healthylabs.s3-ap-southeast-1.amazonaws.com/clinytics/docs/privacy.html',
        termsUrl: 'https://healthylabs.s3-ap-southeast-1.amazonaws.com/clinytics/docs/terms_condition.html',
        disclaimerUrl: 'https://healthylabs.s3-ap-southeast-1.amazonaws.com/clinytics/docs/disclaimer.html'
    }
}
var provider = '';

export function setProvider(prov) {
    provider = prov;
}

export function getProvider() {
    return provider;
}

export function getImgPath(imgKey) {
    if (['logodark', 'logolight', 'minilogodark', 'minilogolight'].indexOf(imgKey) !== -1) return getLogo(imgKey);
    let accountInfo;
    
    if(imgKey=='disclaimerUrl'){
        //console.log("disclaimerUrl",);
        accountInfo =JSON.parse(sessionStorage.getItem("accountInfo"));
        if(accountInfo && (typeof accountInfo.disclaimer_url !== undefined) && accountInfo.disclaimer_url !== ""){
            //console.log("returned disclaimer",accountInfo,accountInfo['disclaimer_url']);
            return accountInfo.disclaimer_url;
        } 
    }
    return paths[provider][imgKey];
}

// get Client Logo for doctor/staff
function getLogo(imgKey) {
    let accInfo = sessionStorage.getItem('accountInfo');
    accInfo = accInfo ? JSON.parse(accInfo) : null;
    let role = sessionStorage.getItem('userRole');
    if (role && (role.indexOf('doctor') !== -1 || role.indexOf('staff') !== -1) && accInfo && accInfo.alt_cliny_logo && accInfo.alt_cliny_logo !== '-') {
        return accInfo.alt_cliny_logo;
    }
    return paths[provider][imgKey];
}