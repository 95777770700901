import React from "react";
import axios from 'axios';

class TemplateListComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    onTemplateSection = (indTemplate) => {
        
    }

    onViewButtonClicked = (indTemplate, event) => {
        event.preventDefault();
        this.props.emitFormViewEvent(indTemplate);
        
    }

    render() {
        const { templateList } = this.props;
        return (

            <div className="row">
                <div className="col-12">

                    {templateList && templateList.length > 0 ?(
                        <React.Fragment>
                            {templateList.map((indTemplate, index) =>
                                <div className=" align-items-center prescription-list prescription-view  mb-2" key={index}>
                                    <table className="table">
                                        <tr>
                                            <td width="2%"> {index + 1}. </td>
                                            <td width="60%" className="patientinfo text-danger white-space-0">
                                                <p>{indTemplate.name}</p>
                                            </td>
                                            <td width="10%" align="right"><span className="text-success btn btn-light" onClick={this.onViewButtonClicked.bind(this, indTemplate)}>
                                                <i className="mdi mdi-eye"> </i>Select </span>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            )}
                        </React.Fragment>
                    ):(
                        <p>No Template List</p>
                    )
                        
                    }
                </div>
            </div>
        );
    }
}

export default TemplateListComponent;
