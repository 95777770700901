import React from "react";
import TimeKeeper from 'react-timekeeper';

class StageUserFormModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userData: this.props.userData,
            userType: 'mobile'
        }

    }

    onTextValueChange = (event) => {
        const { name, value } = event.target;
        this.setState((prevState) => ({
            userData: {
                ...prevState.userData,
                [name]: value
            }
        }));
    }

    componentDidMount() {
        this.updateCognitoUserNameValue();
    }

    onUserTypeChanged = name => (e) => {
        const value = name;
        this.setState((prevState) => ({
            userType: value
        }), () => this.updateCognitoUserNameValue())
    }

    updateCognitoUserNameValue() {
        if (!this.state.userData) {
            return;
        }
        if (this.state.userType === 'mobile') {
            const mobile = this.checkForCountryCodeInPhoneField(this.state.userData.phone);
            this.setState((prevState) => ({
                userData: {
                    ...prevState.userData,
                    'cognito_username': mobile
                }
            }));
        } else {
            const email = this.state.userData.email;
            this.setState((prevState) => ({
                userData: {
                    ...prevState.userData,
                    'cognito_username': email
                }
            }));
        }
    }

    checkForCountryCodeInPhoneField(phone) {
        if (phone.indexOf("+977") !== -1) {
            return phone
        }
        phone = "+977" + phone;
        return phone;
    }

    onSaveClicked = (event) => {
        if (!this.state.userData || !this.state.userData['cognito_username']) {
            return;
        }
        let finalUserData = { ...this.state.userData };
        this.props.emitStageUserData(finalUserData);
    }


    render() {
        return (
            <div>
                <div className="row">
                    {/* <div className="col-md-12"> */}

                    {/* <div className="form-group row">
                            <label className="col-lg-4 col-form-label">Activate User By </label>
                            <div className="col-md-4">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="membershipmale" id="membershipmale" value="" checked={this.state.userType === "mobile"}
                                            onChange={this.onUserTypeChanged('mobile')} />
                                        Mobile
                                            <i className="input-helper"></i></label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="membershipfemale" id="membershipfemale"
                                            checked={this.state.userType === "email"} value="" onChange={this.onUserTypeChanged('email')} />
                                        Email
                                            <i className="input-helper"></i></label>
                                </div>
                            </div>
                        </div> */}

                    {/* </div> */}
                    {this.state.userType === "email" &&
                        <div className="col-md-12">
                            <div className="form-group ">
                                <label>Email*</label>
                                <input className="form-control" name="cognito_username" placeholder="Enter Email" value={this.state.userData['cognito_username']} onChange={this.onTextValueChange.bind(this)} />
                            </div>
                        </div>
                    }
                    {this.state.userType === "mobile" &&
                        <div className="col-md-12">
                            <div className="form-group ">
                                <label>User Name*</label>
                                <input className="form-control" name="cognito_username" placeholder="Enter Phone Number" value={this.state.userData['cognito_username']} onChange={this.onTextValueChange.bind(this)} />
                                {/* <input className="form-control" name="phone" placeholder="Enter Phone Number" value={this.state.userData.phone} onChange={this.onTextValueChange.bind(this)} /> */}
                            </div>
                        </div>
                    }
                    {/* <div className="col-md-12">

                        <div className="form-group ">
                            <label>Email Id</label>
                            <input className="form-control" name="email" value={this.state.userData.email} placeholder="Enter Email Id" onChange={this.onTextValueChange.bind(this)} />
                        </div>
                    </div> */}

                </div>
                <button className="btn btn-success mr-2 btn-rounded" onClick={this.onSaveClicked.bind(this)}>
                    Save
                </button>
            </div>
        );
    }
}

export default StageUserFormModalComponent;
