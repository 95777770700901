import React from "react";
import HeaderViewComponent from './Common/HeaderViewComponent';
import Utils from '../../../Shared/utils/utils';
import CommonModalView from '../../../Common/Modal/CommonModalView';
import StageUserFormModalComponent from '../../../Common/Modal/StageUserFormModalComponent';
import SetUserPasswordComponent from './SetUserPasswordComponent';
import AccountActions from "./Common/AccountActions";
// import accountService from '../../../Services/account.settings';
// import SweetAlertComponent from '../../../Common/Alert/SweetAlertComponent';


const $ = window["jQuery"];

class AccountUserListViewComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            userAccounts: this.props.userAccountList.rows,
            filters: this.props.userAccountList.filters,
            visibleHeader: this.props.userAccountList.visible,
            userAccountList: this.props.userAccountList,
            formatedHeader: null,
            finalTableData: [],
            isSupport: sessionStorage.getItem('isSupport'),
            filteredData: [],
            loading: this.props.loading,
            isStageModalOpen: false,
            stageModalData: null,
            selectedUser: null
        }
        this.formatHeader();
        this.formTableBodyData();
    }

    formatHeader() {
        if (!this.state.visibleHeader || this.state.visibleHeader.length === 0) {
            return;
        }
        let finalHeader = [];
        // let { formatedHeader } = this.state;
        for (let index in this.state.visibleHeader) {
            if (this.state.visibleHeader[index].indexOf("_") !== -1) {
                let strArray = this.stringSpiltForHeader(this.state.visibleHeader[index], '_');
                let indObj = {
                    key: this.state.visibleHeader[index],
                    value: strArray.join(" ")
                }
                finalHeader.push(indObj);
            } else {
                let strArray = this.state.visibleHeader[index];
                let indObj = {
                    key: this.state.visibleHeader[index],
                    value: strArray
                }
                finalHeader.push(indObj);
            }
        }
        this.state.formatedHeader = finalHeader;
        this.setState(this.state);
    }

    stringSpiltForHeader(value, seperate) {
        return value.split(seperate);
    }

    formTableBodyData() {
        if (!this.state.userAccounts || (this.state.userAccounts && this.state.userAccounts.length === 0)) {
            this.state.finalTableData = [];
            this.state.filteredData = [];
            this.setState(this.state);
            return;
        }
        this.state.finalTableData = [];
        this.setState(this.state);
        this.state.visibleHeader.unshift('is_stage');
        this.state.visibleHeader.unshift('id');
        this.setState(this.state);
        for (let index in this.state.userAccounts) {
            let indBodyData = new Array(this.state.visibleHeader.length).fill('-');
            let indUserData = this.state.userAccounts[index];
            for (let key in indUserData) {

                if (this.state.visibleHeader.indexOf(key) !== -1) {
                    let dataIndex = this.state.visibleHeader.indexOf(key);
                    if (typeof indUserData[key] === "object") {
                        let indObjData = indUserData[key];
                        for (let values in indObjData) {
                            let str = '';
                            str = str + '' + values + " : " + indObjData[values] + " ,";
                            indBodyData[dataIndex] = str;
                        }
                    } else {
                        if (key === "is_stage") {
                            let cognitoUserIndex = this.state.visibleHeader.indexOf('cognito_username');
                            if (indUserData['cognito_user_sub'] === "-" || !indUserData['cognito_user_sub']) {
                                indBodyData[dataIndex] = true;
                            }
                        } else if (key === "mfa_enabled") {
                            indBodyData[dataIndex] = indUserData[key] ? "Enabled" : "-"
                        } else {
                            indBodyData[dataIndex] = indUserData[key];
                        }
                        // indBodyData[dataIndex] = indUserData[key] ? indUserData[key] : '-';
                    }
                }
            }
            this.state.finalTableData.push(indBodyData);
            this.state.filteredData.push(indBodyData);
            this.setState(this.state);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.loading !== this.state.loading) {
            this.state.loading = nextProps.loading;
            this.setState(this.state);
        }
        if (this.state.userAccountList !== nextProps.userAccountList) {
            this.setState({
                userAccounts: nextProps.userAccountList.rows,
                filters: nextProps.userAccountList.filters,
                visibleHeader: nextProps.userAccountList.visible
            }, () => this.formTableBodyData(), this.formatHeader())

        }
    }

    getEmitedModalCloseEvent = (event) => {
        this.setState({
            isStageModalOpen: false
        })
    }

    onUserEditEvent = (indData, dataIndex, event) => {
        let idIndex = this.state.visibleHeader.indexOf("account_id");
        let patintIdIndex = this.state.visibleHeader.indexOf("id");
        let id = indData[patintIdIndex];
        let accountId = indData[idIndex];
        let userData = this.props.userAccountList && this.props.userAccountList.rows ? this.props.userAccountList.rows.find(u => u.id === id) : null;
        this.props.emitAccountUserEditEvent(`account-user-edit?id=${id}&accountId=${accountId}`, { accountUserData: userData, accountDetailData: this.props.accountDetailData });
    }

    onStageUser = (indData, event) => {
        
        let idIndex = this.state.visibleHeader.indexOf("account_id");
        let patintIdIndex = this.state.visibleHeader.indexOf("id");
        let emailIndex = this.state.visibleHeader.indexOf("email");
        let phoneIndex = this.state.visibleHeader.indexOf("phone");
        let email = indData[emailIndex];
        let id = indData[patintIdIndex];
        let accountId = indData[idIndex];
        let phone = indData[phoneIndex];
        let data = {
            account_id: accountId,
            id: id,
            cognito_username: '',
            email: email,
            phone: phone
        }
        this.setState(prevState => ({
            isStageModalOpen: true,
            stageModalData: data
        }))
    }

    getEmitedStageUserData = (event) => {
        // updateStageUserById
        this.props.emitStageUserData(event);
    }

    getEmitedSelectedFilerValues = (event) => {
        this.props.emitSelectedFilerValues(event)
    }

    componentDidMount() {
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
    }

    componentWillUnmount() {
        $('[data-toggle="tooltip"]').tooltip('hide');
    }

    handleSearch = event => {
        //const { data } = this.state
        //console.log(this.state);
        let filteredDatas = []
        filteredDatas = this.state.filteredData.filter(e => {
            let mathesItems = Object.values(e)
            let retVal = true;
            let matched = [];
            mathesItems.forEach(e => {
                const regex = new RegExp(event.target.value, 'gi')
                if (typeof (e) === 'string') {
                    retVal = e.match(regex)
                }
                if ((retVal && typeof (retVal) === 'object') && retVal.length > 0) {
                    matched = retVal;
                }
            })
            if (matched.length > 0) {
                return matched
            }
            return retVal;
        })
        this.setState({ finalTableData: filteredDatas, searchValue: event.target.value })
    }

    formatDataIfDateIsPresent(value) {
        let timestamp = Date.parse(value);
        if (isNaN(timestamp) === false) {
            var d = new Date(timestamp);
            return Utils.getFormatedDate(d);
        }
        return value;
    }

    onBackButtonClicked = (event) => {
        this.props.emitBackButtonClick('/account-list');
    }
    // account-list

    onPasswordEmit = (password) => {
        this.props.emitPassword(password, this.state.selectedUser);
        this.setState({ openSetPasswordModal: false, selectedUser: null });
    }

    onSetPasswordBtnClick = (user_index, indData) => {
        let idIndex = this.state.visibleHeader.indexOf("account_id");
        let patintIdIndex = this.state.visibleHeader.indexOf("id");
        let cognitoUsernameIndex = this.state.visibleHeader.indexOf("cognito_username");
        let accountId = indData[idIndex];
        let patientId = indData[patintIdIndex];
        let cognitoUsername = indData[cognitoUsernameIndex];
        let user = {
            cognito_username: cognitoUsername,
            account_id: accountId,
            id: patientId
        };
        //  this.props.userAccountList && this.props.userAccountList.rows && this.props.userAccountList.rows[user_index] ? this.props.userAccountList.rows[user_index] : null;
        this.setState({ openSetPasswordModal: true, selectedUser: user })
    }

    onEnableMFAClick = (user_index, indData) => {
        let idIndex = this.state.visibleHeader.indexOf("account_id");
        let mfaFieldIndex = this.state.visibleHeader.indexOf("mfa_enabled")
        let patintIdIndex = this.state.visibleHeader.indexOf("id");
        let cognitoUsernameIndex = this.state.visibleHeader.indexOf("cognito_username");
        let accountId = indData[idIndex];
        let patientId = indData[patintIdIndex];
        let mfaData = indData[mfaFieldIndex]
        let cognitoUsername = indData[cognitoUsernameIndex];
        let user = {
            cognito_username: cognitoUsername,
            account_id: accountId,
            id: patientId,
            mfa: mfaData === "-" ? false : true
        };
        this.props.emitEnableMFA(user);
    }

    render() {
        let { isSupport } = this.state;
        return (
            <>
                <div className="row ">
                    {/* <div className="col-md-3 form-group mb-2">
                                    <label htmlFor="">Search</label>
                                    <input type="search" className="form-control" placeholder="Search"
                                        aria-controls="order-listing" onChange={this.handleSearch.bind(this)} />
                                </div>
                                <div className="col-md-9 align-self-end">
                                    <HeaderViewComponent emitSelectedFilerValues={this.getEmitedSelectedFilerValues.bind(this)} filters={this.state.filters}></HeaderViewComponent>
                                </div> */}

                </div>
                <div className="clinytic-line-loader" style={{ display: this.state.loading ? '' : 'none' }}></div>
                <div className="row">

                    <div className="col-12 grid-margin stretch-card">

                        <div className="card ecrf-card-table">
                            <div className="card-header header-sm align-items-center pb-0 pt-0">
                                <div className="row justify-content-between" style={{ alignItems: 'center' }}>
                                    <div className="col  justify-content-between">
                                        <button className="btn btn-outline-success btn-icon btn-rounded" onClick={this.onBackButtonClicked.bind(this)}>
                                            <i className="mdi mdi-arrow-left"></i>
                                        </button>  <h4 style={{ display: 'inline-block' }} className="card-title ml-2">Account Users</h4>
                                    </div>
                                    <div className="col-md-3 form-group mb-2">
                                        <label htmlFor="">Search</label>
                                        <input type="search" className="form-control" placeholder="Search"
                                            aria-controls="order-listing" onChange={this.handleSearch.bind(this)} />
                                    </div>

                                    {this.props.accountDetailData && !isSupport && <AccountActions style={{ padding: '15px 15px 0 0' }} accountData={this.props.accountDetailData} userList history={this.props.history} />}

                                </div>
                            </div>
                            <div className="card-body">
                                {/* <div className="row">
                                                <div className="col-lg-12 ">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <h4 className="card-title">Account Users</h4>
                                                        </div>
                                                        <div>

                                                            <button className="btn btn-outline-success btn-icon" onClick={this.onBackButtonClicked.bind(this)}>
                                                                <i className="mdi mdi-arrow-left"></i>
                                                            </button>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="dropdown-divider"> </div> */}
                                <div className="table-responsive">
                                    <table className="table table-hover table-sm">
                                        <thead>
                                            {this.state.formatedHeader &&
                                                <tr style={{ textTransform: 'capitalize' }}>
                                                    {this.state.formatedHeader.map((indHeader, index) =>
                                                        <th key={index} style={{ display: (indHeader.key === "id" || indHeader.key === "is_stage") ? 'none' : '' }}>{indHeader.value}</th>
                                                    )}
                                                    <th>Actions</th>
                                                </tr>
                                            }
                                        </thead>
                                        <tbody>
                                            {this.state.finalTableData &&
                                                <React.Fragment>
                                                    {this.state.finalTableData.map((indFinalData, index) =>
                                                        <tr key={index}>
                                                            {indFinalData.map((indRow, jndex) =>
                                                                <td key={jndex} style={{ display: (jndex === 0 || jndex === 1) ? 'none' : '' }} className={jndex === 2 ? "" : ""}>{indRow}</td>
                                                            )}
                                                            {!isSupport && <td>
                                                                {indFinalData[1] === true &&
                                                                    <button className="btn btn-inverse-dark btn-icon btn-rounded mr-1" onClick={this.onStageUser.bind(this, indFinalData)}
                                                                        title="Set User Details"
                                                                    ><i className="mdi mdi-table-edit btn-icon-append" ></i>
                                                                    </button>

                                                                }
                                                                <button className="btn btn-inverse-dark btn-icon btn-rounded  mr-1" onClick={this.onUserEditEvent.bind(this, indFinalData, index)}
                                                                    title="Edit"
                                                                ><i className="mdi mdi-pencil btn-icon-append" ></i>
                                                                </button>

                                                                <button className="btn btn-inverse-dark btn-icon btn-rounded" onClick={this.onSetPasswordBtnClick.bind(this, index, indFinalData)}
                                                                    title="Set user password"
                                                                ><i className="mdi mdi-lock-outline btn-icon-append"></i>
                                                                </button>

                                                                <button className="btn btn-inverse-dark btn-icon btn-rounded" onClick={this.onEnableMFAClick.bind(this, index, indFinalData)}
                                                                    title="Enable Multi factor Auth"
                                                                ><i className="mdi mdi-fingerprint btn-icon-append"></i>
                                                                </button>

                                                            </td>
                                                                ||
                                                                <td>
                                                                    <button className="btn btn-inverse-dark btn-icon btn-rounded  mr-1" onClick={this.onUserEditEvent.bind(this, indFinalData, index)}
                                                                        title="Edit"
                                                                    ><i className="mdi mdi-pencil btn-icon-append" ></i>
                                                                    </button>
                                                                </td>
                                                            }
                                                        </tr>
                                                    )}
                                                </React.Fragment>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {
                    (this.state.isStageModalOpen && this.state.stageModalData) &&
                    <CommonModalView open={this.state.isStageModalOpen} emitModalCloseEvent={this.getEmitedModalCloseEvent.bind(this)} title={'Set User Properties'}>
                        <StageUserFormModalComponent userData={this.state.stageModalData} emitStageUserData={this.getEmitedStageUserData.bind(this)}></StageUserFormModalComponent>
                    </CommonModalView>
                }
                {
                    (this.state.openSetPasswordModal) &&
                    <CommonModalView open={this.state.openSetPasswordModal} emitModalCloseEvent={() => this.setState({ openSetPasswordModal: false })} title={'Set User Password'}>
                        <SetUserPasswordComponent emitPassword={this.onPasswordEmit.bind(this)} />
                    </CommonModalView>
                }
            </>
        );
    }
}

export default AccountUserListViewComponent;
