import React from "react";
// component imports
import SectionViewComponent from './Component/SectionViewComponent';
// common imports

import SweetAlertComponent from '../../Common/Alert/SweetAlertComponent';
// additional plugin imports
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import appointmentService from '../../Services/appointment.service';

import prescriptionService from '../../Services/prescription.service';
// common upload
import { S3UPLOAD_KEY } from '../../Constants/s3Key';
import { Storage } from 'aws-amplify';
import Utils from '../../Shared/utils/utils';
import UAParser from 'ua-parser-js'
import axios from 'axios';

class DynamicFormContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            prescriptionData: this.props.prescriptionData,
            templateName: this.props.templateName,
            saveInProgress: false,
            qcStatus: '',
            templateId: this.props.templateId,
            "vitals": null,
            "labtests": null,
            qcComment: '',
            userIpAddress: null,
            appointmentData: null,
            viewType: this.props.viewType ? this.props.viewType : null,
            queryData: this.props.queryData ? this.props.queryData : (this.props.location && this.props.location.search !== '') ? JSON.parse('{"' + decodeURI(this.props.location.search.substr(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : '',
        }
    }

    componentDidMount() {
        this.getIpAddress()
        // make api call here to get prescription data
        let obj = sessionStorage.getItem('suggestions');
        let savedSuggObj = obj ? JSON.parse(obj) : '';
        let { templateId } = this.props;
        
        // if (!savedSuggObj) {
        this.fetchAndSaveSuggestions(templateId, savedSuggObj);
        // }
        this.fetchAutoCompleteData();
        this.getPatientLabTestPrescriptionData('vitals');
        this.getPatientLabTestPrescriptionData('lab_test');
        if(this.props?.queryData?.appointmentId){
            this.fetchAppointmentDetailById(this.props.queryData.appointmentId);
        }
       
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.templateId !== prevState.templateId) {
            return { templateId: nextProps.templateId };
        }
        else return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.templateId !== this.props.templateId) {
            //Perform some operation here
            let obj = sessionStorage.getItem('suggestions');
            let savedSuggObj = obj ? JSON.parse(obj) : '';
            let { templateId } = this.props;
            if (!savedSuggObj) {
                this.fetchAndSaveSuggestions(templateId, savedSuggObj);
            }
            this.fetchAutoCompleteData();
            // this.getPatientLabTestPrescriptionData('vitals');
            // this.getPatientLabTestPrescriptionData('lab_test');
        }
    }

    async fetchAppointmentDetailById(event) {
        try {
            var response = await appointmentService.getAppointmentById(event);
            this.checkAppointmentResponseByIdData(response);
        } catch (error) {
            console.log('error');
        }
    }

    checkAppointmentResponseByIdData(response) {
        if (!response) {
            this.onError(response);
            return;
        }
        switch (response.type) {
            case "error":
                this.onError(response);
                break;
            case "success":
                let { appointmentData } = this.state;
                appointmentData = response.data;
                this.setState({ appointmentData });
                break;
        }
    }

    async getPatientLabTestPrescriptionData(type) {
        // id is patient Id 
        try {
            let id = (this.state.queryData && this.state.queryData.patientId) ? this.state.queryData.patientId : this.props.patientId ? this.props.patientId : ""
            if (!id) {
                return;
            }
            var response = await prescriptionService.fetchPatientVitalPrescriptionData(id, type);
            this.checkForVitalsPrescriptionResponse(response, type);
        } catch (error) {
            // this.onError('No Data Found');
        }
    }

    checkForVitalsPrescriptionResponse(response, type) {
        switch (response.type) {
            case "error":
                // this.onError('No Data Found')
                break;
            case "success":
                //
                if (response.data && response.data.rows) {
                    let result = response.data.rows && response.data.rows[0] && response.data.rows[0].result ? response.data.rows[0].result : [];
                    let { vitals, labtests } = this.state;
                    if (type === "vitals") {
                        vitals = result
                    } else {
                        labtests = result
                    }
                    this.setState({ vitals, labtests });
                }
                break;
            default:
                return;
        }
    }

    async fetchAutoCompleteData() {
        const allowedTypes = ['symptoms', 'diagnosis', 'drugs', 'investigations'];
        let autoCompleteData = {}
        let existing = localStorage.getItem('autocomplete');
        if (existing) {
            return
        }
        for (let allowedType of allowedTypes) {
            let res = await prescriptionService.fetchSuggestions(allowedType, 'suggestions', "");
            if (res && res.type === 'success' && res.data && res.data.result && res.data.result.length) {
                // let options = res.data.result.map(v => ({ label: v, value: v }));
                autoCompleteData[allowedType] = res.data.result
            }
        }
        localStorage.setItem('autocomplete', JSON.stringify(autoCompleteData));

    }

    async fetchAndSaveSuggestions(templateId, savedSuggObj) {
        let formnames = this.props.formDataJson.sections.map(s => s.formname).filter(name => name).map(n => n.toLowerCase()).join(',');
        try {
            let res = await prescriptionService.fetchSuggestionObject("suggestions", formnames);
            if (res && res.type === 'success' && res.data) {
                if (!savedSuggObj || Object.keys(savedSuggObj).length === 0) savedSuggObj = {};
                let suggestions = res.data
                savedSuggObj = suggestions
                sessionStorage.setItem('suggestions', JSON.stringify(savedSuggObj));
            }
            if (res && res.type === 'error' && !res.data) {

                sessionStorage.setItem('suggestions', '');
            }
        } catch (e) {
            //error
            sessionStorage.setItem('suggestions', '');
        }
    }
    findNestedObj(entireObj, keyToFind, valToFind) {
        let foundObj;
        JSON.stringify(entireObj, (_, nestedValue) => {
          if (nestedValue && nestedValue[keyToFind] === valToFind) {
            foundObj = nestedValue;
          }
          return nestedValue;
        });
        return foundObj;
      };
    savePrescriptionDataToS3 = (event, patientDetail) => {
        //console.log("savePrescriptionDataToS3 keshav",event)
        //console.log(this.findNestedObj(event, 'nextAppointment', 'pending'));

        let { saveInProgress } = this.state;
        saveInProgress = true;
        this.setState({ saveInProgress });
        Storage.configure({
            level: 'private',
            AWSS3: S3UPLOAD_KEY
        });
        Storage.put(`env-${sessionStorage.getItem('apiStage')}/acc-${sessionStorage.getItem('s3AccountId')}/prescription/${Utils.getFormatedDate(new Date())}/form/${(new Date()).getTime()}`, JSON.stringify(event), {
            contentType: 'application/json'
        }).then(result => {
           // console.log(result);
            /*
            code for next appointment starts keshav
            */
           //console.log("state dynamicformcontainer",this.state);
           //console.log("props dynamicformcontainer",this.props);
            let pendingAppointment=this.findNestedObj(event, 'nextAppointment', 'pending');
            if(pendingAppointment){
                if(pendingAppointment.value){
                    var dateArr= (pendingAppointment.value).split("-");
                    let patientDetails=this.props.patientDetail;
                    delete patientDetails.account_id;
                    let nextAppointmentData = {
                        'appointment_date':dateArr[2]+'-'+dateArr[1]+'-'+dateArr[0],
                        "remarks": '',
                        "copy_email":  '',
                        "institution": '',
                        'appointment_slot': '',
                        'slot_type': '',
                        'rescheduled_appointment_date': '',
                        'rescheduled_appointment_slot': '',
                        'appointment_type': this.state.appointmentData.appointment_type?this.state.appointmentData.appointment_type:'offline',
                        'service_name': '',
                        'appointment_status': 'Intent',
                        'invoice_amount': '',
                       
                        'patient': patientDetails,
                        "doctor": {
                            "doctor_name": this.state.prescriptionData.doctor_name ? this.state.prescriptionData.doctor_name : '',
                            "doctor_id": this.state.prescriptionData.doctor_id ? this.state.prescriptionData.doctor_id : '',
                            "department": this.state.prescriptionData.department ? this.state.prescriptionData.department : '',
                            "branch": this.state.prescriptionData.branch ? this.state.prescriptionData.branch : '',
                            "account_id": sessionStorage.getItem("s3AccountId")
                        },
                        'patient_hid': this.state.prescriptionData.patient_hid ? this.state.prescriptionData.patient_hid : '',
                        'patient_id':this.state.prescriptionData.patient_id ? this.state.prescriptionData.patient_id : '',
                        'doctor_id': this.state.prescriptionData.doctor_id ? this.state.prescriptionData.doctor_id : '',
                        'doctor_name': this.state.prescriptionData.doctor_name ? this.state.prescriptionData.doctor_name : '',
                        'department': this.state.prescriptionData.department ? this.state.prescriptionData.department : '',
                        'referred_doctor_name': '',
                        'referred_doctor_id':  ''
                    };
    
                    let details = new UAParser().getResult();
                    nextAppointmentData = {
                        ...nextAppointmentData,
                        ip_address: this.state.userIpAddress,
                        browser_info: {
                            browser: details.browser,
                            OS: details.os,
                            device: details.device,
                            cpu: details.cpu,
                            engine: details.engine
                        }
                        
                    }
                    var appointmentResponse =  appointmentService.createAppointment(nextAppointmentData);
                    //console.log("next appointment response",appointmentResponse);
                }
                
            }
            /**code for next appointment ends */
            this.checkIfEventIsSaveOrEdit(result, patientDetail);
        }
        ).catch(err => {
            let { saveInProgress } = this.state;
            saveInProgress = false;
            this.setState({ saveInProgress });
            console.log(err);
            this.onS3FileUploadError(err);
        });
    }

    checkIfEventIsSaveOrEdit(result, patientDetail) {
        if (this.props.renderFrom === "ocr") {
            this.props.emitFormPathData(`private/${sessionStorage.getItem('identityId')}/${result.key}`);
            // this.setState({ saveInProgress: false }, () => this.props.emitFormPathData(`private/${sessionStorage.getItem('identityId')}/${result.key}`));            
            // return;
        }
        if (this.props.renderFrom === "case_history") {
            // this.props.emitFormPathData(`private/${sessionStorage.getItem('identityId')}/${result.key}`);
            if (this.props.caseHistory && this.props.caseHistory.id) {
                let data = {};
                data = {
                    ...data,
                    "case_history_path": `private/${sessionStorage.getItem('identityId')}/${result.key}`
                }
                if (patientDetail) {
                    let dob = Utils.getFormatedDate(patientDetail['date_of_birth'])
                    data['patient'] = { ...patientDetail };
                    data['patient']['date_of_birth'] = dob;
                }
                this.editCaseHistoryData(data, this.props.caseHistory.id);
            } else {
                this.savePrescriptionData(result, patientDetail);
            }
            this.setState({ saveInProgress: false });
            return;
        }

        if (sessionStorage.getItem('userRole').indexOf('dataops') !== -1 || sessionStorage.getItem('userRole').indexOf('dataadmin') !== -1 || sessionStorage.getItem('userRole').indexOf('dataqc') !== -1) {
            if (this.state.prescriptionData && Object.keys(this.state.prescriptionData).length) {
                //NOTE: QC user will always edit the prescription to change formpath , prescription_date => ANKIT
                // earlier code => if (this.props.mode === "edit" || (this.props.mode === "create" && !this.props.dataUserPrescriptionDate)) {
                    if (this.props.mode === "edit" || (this.props.mode === "create")) {
                    let data = {};
                    // dataUserPrescriptionDate
                    // prescription_date: "2020-07-22"
                    data.formpath = `private/${sessionStorage.getItem('identityId')}/${result.key}`;
                    data['template_id'] = this.state.prescriptionData.template_id ? this.state.prescriptionData.template_id : null
                    data["prescription_date"]= this.props.dataUserPrescriptionDate ? Utils.getFormatedDate(this.props.dataUserPrescriptionDate) : ''

                    if (patientDetail) {
                        let dob = Utils.getFormatedDate(patientDetail['date_of_birth'])
                        data['patient'] = { ...patientDetail };
                        data['patient']['date_of_birth'] = dob;
                    }
                    this.editPrescriptionData(data);
                } else {
                    this.saveDataPrescriptionData(result, patientDetail);
                }
            } else {
                this.saveDataPrescriptionData(result, patientDetail, 'mixrole');
            }
        } else {
            if (this.props.mode === "edit" && this.state.prescriptionData && this.state.prescriptionData.id) {
                let data = {};
                data.formpath = `private/${sessionStorage.getItem('identityId')}/${result.key}`;
                data['template_id'] = this.state.prescriptionData.template_id ? this.state.prescriptionData.template_id : null
                if (patientDetail) {
                    let dob = Utils.getFormatedDate(patientDetail['date_of_birth'])
                    data['patient'] = { ...patientDetail };
                    data['patient']['date_of_birth'] = dob;
                }
                this.editPrescriptionData(data);
            } else {
                this.savePrescriptionData(result, patientDetail);
            }
        }
    }

    async saveDataPrescriptionData(result, patientDetail, newrole) {
        //console.log("saveDataPrescriptionData identified by keshav");
        try {
            let data = {}
            if (this.state.queryData && this.state.queryData.type === "patient") {
                data = {
                    "file_type": this.props.templateFileType ? this.props.templateFileType : "prescription",
                    "file_source": "form",
                    "template_id": this.props.templateId,
                    "patient_id": (this.state.queryData && this.state.queryData.patientId) ? this.state.queryData.patientId : this.props.patientId ? this.props.patientId : ""
                }
                if (patientDetail) {
                    let dob = Utils.getFormatedDate(patientDetail['date_of_birth'])
                    data['patient'] = { ...patientDetail };
                    data['patient']['date_of_birth'] = dob;
                }
                if (sessionStorage.getItem('userRole').indexOf("dataadmin") !== -1 || sessionStorage.getItem('userRole').indexOf("dataqc") !== -1 || sessionStorage.getItem('userRole').indexOf("dataops") !== -1) {
                    data['doctor_id'] = this.state.prescriptionData && this.state.prescriptionData.doctor_id ? this.state.prescriptionData.doctor_id : ''
                }
            }

            if (newrole) {
                data["file_type"] = this.props.templateFileType ? this.props.templateFileType : "prescription";
                data["file_source"] = "form";
                data["template_id"] = this.props.templateId;
                data["patient_id"] = (this.state.queryData && this.state.queryData.patientId) ? this.state.queryData.patientId : this.props.patientId ? this.props.patientId : ""
            }

            data = {
                ...data,
                "prescription_date": this.props.dataUserPrescriptionDate ? Utils.getFormatedDate(this.props.dataUserPrescriptionDate) : '',
                "imgpath": this.state.prescriptionData && this.state.prescriptionData.imgpath ? decodeURIComponent(this.getImagePathKey(this.state.prescriptionData.imgpath)) : '',
                "formpath": `private/${sessionStorage.getItem('identityId')}/${result.key}`
            }

            let details = new UAParser().getResult();
            data = {
                ...data,
                ip_address: this.state.userIpAddress,
                browser_info: {
                    browser: details.browser,
                    OS: details.os,
                    device: details.device,
                    cpu: details.cpu,
                    engine: details.engine
                }
            }
            const response = await prescriptionService.createPrescription(data);
            //
            this.checkForSavePrescriptionResponse(response);
        } catch (error) {
            console.log(error);
        }
    }

    getImagePathKey(url) {
        let extension = url.split("?")[0].split("#")[0].split('.').pop();
        let res = url.split(`.${extension}`);
        var res1 = res[0].split(".com/");
        return res1[1] + `.${extension}`;
    }

    async editPrescriptionData(data) {
        //console.log("editPrescriptionData identified by keshav");
        try {
            const response = await prescriptionService.updatePrescription(data, this.state.prescriptionData.id);
            //
            this.checkForPrescriptionEditResponse(response);
        } catch (error) {
            console.log(error)
        }
    }
    async editCaseHistoryData(data, id) {
        try {
            const response = await prescriptionService.updatePrescription(data, id);
            //
            this.checkForPrescriptionEditResponse(response);
        } catch (error) {
            console.log(error)
        }
    }

    checkForPrescriptionEditResponse(response) {
        switch (response.type) {
            case 'error':
                let { saveInProgress } = this.state;
                saveInProgress = false;
                this.setState({ saveInProgress }, () => this.onError(response));

                break;
            case 'success':
                this.setState(prevState => ({
                    show: true,
                    saveInProgress: false,
                    alert: {
                        ...prevState.alert,
                        type: 'success',
                        title: 'Success',
                        text: response.message ? response.message : 'Success'
                    }
                }), () => this.props.emitEventOnTemplateSaveSuccess('edit'));
                break;
            default:
                break;
        }

    }

    async getIpAddress() {
        delete axios.defaults.headers.common["Authorization"];
        axios.get('https://api.ipify.org')
            .then((response) => {
                let { userIpAddress } = this.state;
                userIpAddress = response.data;
                this.setState({ userIpAddress });
            })
            .catch((error) => {
                let { userIpAddress } = this.state;
                userIpAddress = '';
                this.setState({ userIpAddress });
            });
    }

    async savePrescriptionData(result, patientDetail) {
        try {
            let data = {}
            if (this.state.queryData && this.state.queryData.type === "patient") {
                // FILE_TYPES = ['prescription', 'report', 'form']FILE_SOURCES = ['writing_pad', 'upload']
                data = {
                    "file_type": this.props.templateFileType ? this.props.templateFileType : "prescription",
                    "file_source": "form",
                    "template_id": this.props.templateId,
                    "patient_id": (this.state.queryData && this.state.queryData.patientId) ? this.state.queryData.patientId : this.props.patientId ? this.props.patientId : "1569580658b695e530"
                }
                if (patientDetail) {
                    let dob = Utils.getFormatedDate(patientDetail['date_of_birth'])
                    data['patient'] = { ...patientDetail };
                    data['patient']['date_of_birth'] = dob;
                }
                if (this.props.copiedPrescripId) {
                    data['copied_prescription_id'] = this.props.copiedPrescripId;
                }
                if (sessionStorage.getItem('userRole').indexOf("dataadmin") !== -1 || sessionStorage.getItem('userRole').indexOf("dataqc") !== -1 || sessionStorage.getItem('userRole').indexOf("dataops") !== -1) {
                    data['doctor_id'] = this.state.prescriptionData && this.state.prescriptionData.doctor_id ? this.state.prescriptionData.doctor_id : ''
                }
            } else {
                data = {
                    "file_type": this.props.templateFileType ? this.props.templateFileType : "prescription",
                    "file_source": "form",
                    "template_id": this.props.templateId,
                    "patient_id": (this.state.queryData && this.state.queryData.patientId) ? this.state.queryData.patientId : this.props.appointmpatientIdentId ? this.props.patientId : "",
                    "appointment_id": (this.state.queryData && this.state.queryData.appointmentId) ? this.state.queryData.appointmentId : this.props.appointmentId ? this.props.appointmentId : ""
                }
                if (this.props.copiedPrescripId) {
                    data['copied_prescription_id'] = this.props.copiedPrescripId;
                }
            }
            if (this.props.renderFrom === 'ocr') {
                data = {
                    ...data,
                    "basic_formpath": `private/${sessionStorage.getItem('identityId')}/${result.key}`
                }
            } else if (this.props.renderFrom === 'case_history') {
                data = {
                    ...data,
                    "case_history_path": `private/${sessionStorage.getItem('identityId')}/${result.key}`
                }

            } else {
                data = {
                    ...data,
                    "formpath": `private/${sessionStorage.getItem('identityId')}/${result.key}`
                }
            }
            let details = new UAParser().getResult();
            data = {
                ...data,
                ip_address: this.state.userIpAddress,
                browser_info: {
                    browser: details.browser,
                    OS: details.os,
                    device: details.device,
                    cpu: details.cpu,
                    engine: details.engine
                }
            }
            const response = await prescriptionService.createPrescription(data);
            //
            this.checkForSavePrescriptionResponse(response);
        } catch (error) {
            console.log(error);
            let { saveInProgress } = this.state;
            saveInProgress = false;
            this.setState({ saveInProgress }, () => this.onError(''));
        }
    }

    checkForSavePrescriptionResponse(response) {
        switch (response.type) {
            case 'error':
                let { saveInProgress } = this.state;
                saveInProgress = false;
                this.setState({ saveInProgress }, () => this.onError(response));

                break;
            case 'success':
                this.setState(prevState => ({
                    show: true,
                    saveInProgress: false,
                    alert: {
                        ...prevState.alert,
                        type: 'success',
                        title: 'Success',
                        text: response.message ? response.message : 'Success'
                    }
                }), () => this.props.emitEventOnTemplateSaveSuccess('new'));
                break;
            default:
                break;
        }
    }

    onS3FileUploadError(error) {
        this.setState(prevState => ({
            show: true,
            alert: {
                ...prevState.alert,
                type: 'error',
                title: 'Error',
                text: error.message ? error.message : 'Error'
            }
        }));
    }

    checkForUserListResponse(response) {
        if (!response) {
            alert('Api Failed');
            return;
        }
        switch (response.type) {
            case 'error':
                this.onError(response);
                break;
            case 'success':
                //console.log(response.data);
                break;
            default:
                return;
        }
    }

    onError(response) {
        this.setState(prevState => ({
            show: true,
            loading: false,
            alert: {
                ...prevState.alert,
                type: 'error',
                title: 'Error',
                text: response.message ? response.message : 'Error'
            }
        }));
    }

    onQcDataChange = (event) => {
        //console.log(event);
        const { name, value } = event.target;
        this.setState(prevState => ({
            [name]: value
        }));
    }

    notify(message) {
        toast.success(message, { position: toast.POSITION.TOP_RIGHT, containerId: 'A' });
    }

    sweetAlertClose = (event) => {
        this.setState({ show: false });
    }

    getEmitedQcStatusSaveEvent = (event) => {
        let data = {
            'qc_comment': this.state.qcComment,
            "qc_status": this.state.qcStatus
        }
        this.editPrescriptionData(data);
    }

    getEmitedEventIfFormValueChange = (event) => {
        this.props.emitFormChangeEvent(event);
    }

    getEmitedPartialPrescFormData = (event) => {
        this.props.emitPartialPrescFormData(event);
    }

    render() {
        const { patientDetail, renderFrom, templateId,currentSectionId } = this.props;
        const { prescriptionData, vitals, labtests, queryData } = this.state;
        return (
            <React.Fragment>
                <SectionViewComponent
                    currentSectionId={currentSectionId}
                    uniquePrescId={this.props.prescriptionData ? this.props.prescriptionData.id : 'new'}
                    viewType={this.state.viewType}
                    patientDetail={patientDetail}
                    renderFrom={renderFrom}
                    parentContainer={this.props.parentContainer}
                    mode={this.props.mode}
                    vitals={vitals}
                    labtests={labtests}
                    hideSugg={this.props.hideSugg}
                    qcStatus={this.state.qcStatus}
                    patient={prescriptionData && prescriptionData.patient ? prescriptionData.patient : null}
                    qcComment={this.state.qcComment}
                    queryData={queryData}
                    emitPrescriptionStatus={this.onQcDataChange.bind(this)}
                    emitQcStatusSaveEvent={this.getEmitedQcStatusSaveEvent.bind(this)}
                    saveInProgress={this.state.saveInProgress}
                    dynamicFormData={this.props.formDataJson}
                    emitS3PrescriptionSaveEvent={this.savePrescriptionDataToS3.bind(this)}
                    template_id={templateId}
                    emitEventIfFormValueChange={this.getEmitedEventIfFormValueChange.bind(this)}
                    emitPartialPrescFormData={this.getEmitedPartialPrescFormData.bind(this)}>
                </SectionViewComponent>
                {this.state.show &&
                    <SweetAlertComponent show={this.state.show} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.sweetAlertClose}></SweetAlertComponent>
                }
            </React.Fragment>
        );
    }
}

export default DynamicFormContainer;
