
const openUrlAuthRoutes = [{
    type: 'single',
    route: `/inventory-management`,
    isActive: false,
    display: true,
    navbarName: "Inventory Management ",
    key: "invoice-report"
}, {
    type: 'single',
    navbarName: "Bookings",
    key: "service-sold",
    display: true,
    isActive: false,
    route: `/bookings-list`
}];


export default openUrlAuthRoutes;