import Utils from "../Shared/utils/utils";

function getApiStageType() {
  let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1 || window.location.hostname.indexOf('d32uc620y3be9') !== -1) ? 'dev' : 'prod';
  return apiStage
}



function getRootUrl(req) {
  var hostName = window.location.hostname;
  // const ROOT_URL = (hostName.indexOf('localhost') !== -1 || hostName.indexOf('beta') !== -1) ? `https://9jwwgz0y1i.execute-api.ap-south-1.amazonaws.com/dev` : `https://1vpstx4hnk.execute-api.ap-south-1.amazonaws.com/prod`;
  var apiStage = getApiStageType()
  const ROOT_URL = apiStage == 'dev' ? `https://betaemrapi.hlthclub.in/admin` : isUatEnvir() ? "https://uatemrapi.hlthclub.in/admin" : `https://emrapi.hlthclub.in/admin`;

  return ROOT_URL;
}

function isUatEnvir() {
  let apiStage = (window.location.hostname.indexOf('uatclinytics') !== -1 || window.location.hostname.indexOf('localhost') !== -1) ? true : false;
  return apiStage
}

function getNewUsersRootUrl(req) {
  let apiStage = sessionStorage.getItem('apiStage') ? sessionStorage.getItem('apiStage') : 'prod'
  if (apiStage === "prod") {
    return `https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/prod/`;
  } else {
    return `https://12vwe151nh.execute-api.ap-south-1.amazonaws.com/dev`
  }
}

function getNewPatientRootUrl(req) {
  let apiStage = sessionStorage.getItem('apiStage') ? sessionStorage.getItem('apiStage') : 'prod'
  if (apiStage === "prod") {
    if (isUatEnvir()) {
      return "https://uatemrapi.hlthclub.in/patient"
    }
    return `https://emrapi.hlthclub.in/patient`;
  } else {
    return `https://betaemrapi.hlthclub.in/patient`
  }
}

function getAppointmentRootUrl(req) {
  
  //   Base url for prod - https://vswli5erc5.execute-api.ap-south-1.amazonaws.com/prod
  // base url for dev - https://ird52191oa.execute-api.ap-south-1.amazonaws.com/dev

  let apiStage = sessionStorage.getItem('apiStage') ? sessionStorage.getItem('apiStage') : 'prod'
  if (apiStage === "prod") {
    if (isUatEnvir()) {
      return "https://uatemrapi.hlthclub.in/appointment"
    }
    return `https://emrapi.hlthclub.in/appointment`;
  } else {
    return `https://betaemrapi.hlthclub.in/appointment`
  }
  // return ROOT_URL;
}


let ROOT_URL = {
  getRootUrl,
  getAppointmentRootUrl,
  getNewUsersRootUrl,
  getNewPatientRootUrl
}

export default ROOT_URL;