import React, { useState } from "react";
import { } from "./vaccinationService";
import VaccinationHeader from "./vaccinationHeaders";
import SweetAlertComponent from "../../Common/Alert/SweetAlertComponent"
import Utils from "../../Shared/utils/utils";
import { bookAppointment, getPaymentUrl, navigatePrevPage, onLogoutClick, checkHospConsult } from "./vaccinationService"

import PlainTermsAndConditions from "../../Common/TermsAndCondition/PlainTermsAndConditions";
import MoolchandPlainTermsAndConditions from '../../Common/TermsAndCondition/MoolchandPlainTermsAndConditions';
import CommonModalView from "../../Common/Modal/CommonModalView";


class HospitalConsultCheckout extends React.Component {
    constructor(props) {
        super(props)
        let patient = {};
        let accInfo = sessionStorage.getItem("acc_info");
        let open_url_images = {};
        if (accInfo) {
            accInfo = JSON.parse(accInfo);
            open_url_images = accInfo.open_url_images;

        }
        let { admissionDetails = {}, selectedPatient = {}, prevRoute } = props.location.state ? props.location.state : {}
        if (!accInfo || Object.keys(accInfo).length == 0 || !prevRoute) {
            navigatePrevPage(this.state, props)
            return
        }
        let account_id = ""
        if (this.props.match.params && this.props.match.params.account_id) {
            account_id = this.props.match.params.account_id
        }



        this.state = {
            account_id: account_id,
            vaccineLogo: open_url_images["vaccination-img"],
            profileUrl: open_url_images["business-logo"],
            logominiUrl: open_url_images["logo-mini"],
            logoUrl: open_url_images["logo"],
            patient: selectedPatient,
            isHospConsult: checkHospConsult(props.location),
            admissionDetails,
            accInfo,
            showAlert: false,
            loading: false,
            isTerms: false,
            openTerms: false,
            alert: {
                type: '',
                title: '',
                text: '',
                step: ''
            }
        }
    }

    handlePayment = () => {
        let { min_ipd_amount = 0, max_ipd_amount } = this.state.accInfo
        if (!this.state.isTerms) {
            this.setState({ loading: false, showAlert: true, alert: { title: "Error", text: `Please Select Terms and Conditions`, onClose: () => { this.setState({ alert: null, showAlert: false }) } } })

            return;
        }
        if (!this.state.amount || this.state.amount < min_ipd_amount) {
            this.setState({ loading: false, showAlert: true, alert: { title: "Error", text: `Amount should be greater than ${this.state.accInfo.min_ipd_amount} Rs.`, onClose: () => { this.setState({ alert: null, showAlert: false }) } } })
            return
        }
        if (this.state.amount && this.state.amount > max_ipd_amount) {
            this.setState({
                loading: false, showAlert: true, alert:
                {
                    title: "Error", text: `Amount should be less than ${this.state.accInfo.max_ipd_amount} Rs.`,
                    onClose: () => { this.setState({ alert: null, showAlert: false }) }
                }
            })
            return
        }
        let payload = {
            "invoice_amount": this.state.amount,
            "payment_option": "online",
            "invoice_details": [
                {
                    "category": "ipd",
                    "name": this.state.admissionDetails && Object.keys(this.state.admissionDetails).length > 0 ? "ipd_advance" : "booking_advance",
                    "cost": this.state.amount,
                    "total": this.state.amount
                }
            ],

            "booking_date": Utils.getFormatedDate(new Date),
            "patient_id": this.state.patient.id,
            "account_id": this.state.patient.account_id,
            "device_id": sessionStorage.getItem("deviceId"),
            "branch": this.state.patient.branch,
            "new_patient": this.state.patient.new_patient,
            "admission_details": this.state.admissionDetails,
            "pay_now": true
        }
        this.setState({ loading: true })
        bookAppointment(payload).then(
            res => {
                this.setState({
                    loading: false
                })
                let paymentUrl = getPaymentUrl(this.state.patient.account_id, res.data.order_id)
                window.location = paymentUrl

            }
        ).catch(e => {
            this.setState({ loading: false, showAlert: true, alert: { title: "Error", text: "Something went wrong! Please try after some time", onClose: () => { this.setState({ showAlert: false }) } } })
        })
    }

    render() {
        const accountInfo = JSON.parse(sessionStorage.getItem("acc_info"))
        // opd/new-patient
        let hospital = sessionStorage.getItem("vaccinationAccountId")
        return (
            <>
                <div>
                    <div className="container-scroller">
                        <nav className={hospital == "moolchandhospital"?"white navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row ":"navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row "} >
                            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                                <a className="navbar-brand brand-logo"><img src={this.state.logoUrl} alt="logo" /></a>
                                <a className="navbar-brand brand-logo-mini"><img src={this.state.logominiUrl} alt="logo" /></a>
                            </div>
                            <div className="navbar-menu-wrapper d-flex align-items-stretch">
                                <ul className="navbar-nav navbar-nav-right">
                                    <li className="nav-item nav-profile dropdown">
                                        <a className="nav-link dropdown-toggle" id="profileDropdown" data-toggle="dropdown"
                                            aria-expanded="false">
                                            <div className="nav-profile-img">
                                                <img src={this.state.profileUrl} alt="image" />
                                                <span className="availability-status online"></span>
                                            </div>
                                            <div className="nav-profile-text">
                                                <p className="mb-1 text-white">{this.state.patient.name}</p>
                                            </div>
                                        </a>
                                        <div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
                                            <div className="dropdown-divider"></div>
                                            <a className="dropdown-item" style={{ cursor: 'pointer' }} onClick={() => onLogoutClick(this.state.account_id, this.state.isHospConsult, this.props)}>
                                                <i className="mdi mdi-logout mr-2 text-primary"></i>
                                                Signout
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <VaccinationHeader isVisible={true} title="Admission Checkout" loading={this.state.loading} alert={this.state.alert} favicon={this.state.logominiUrl} />

                        </nav>
                        <div className="container-fluid page-body-wrapper">
                            <div className="main-panel">
                                <div className="content-wrapper doctor-search-wrapper">
                                    <div className="row row-grid d-none">
                                        <div className="col-12">
                                            <h5>Shopping Cart</h5>
                                        </div>
                                    </div>
                                    <section>
                                        <div className="row">
                                            <div className="col-lg-8 stretch-card">
                                                <div className="card mb-4">
                                                    {this.state.isInSettingLoading &&
                                                        <div className="clinytic-line-loader"></div>
                                                    }
                                                    <div className="card-body">

                                                        <div className=" wish-list">
                                                            <div className="row">
                                                                {this.state.admissionDetails && Object.keys(this.state.admissionDetails).length != 0 ? (
                                                                    <div className="col-lg-8">
                                                                        <h5 className="mb-4">Admission Details  </h5>
                                                                        <div class="row">
                                                                            <div class="col-5 ">Name : </div><div class="col-7">{this.state.admissionDetails.name}</div>
                                                                            <div class="col-5 ">Doctor Name : </div><div class="col-7">{this.state.admissionDetails.doctorName}</div>
                                                                            <div class="col-5 ">Hospital UHID : </div><div class="col-7">{this.state.patient.patient_hid}</div>
                                                                            <div class="col-5 ">Bed No. : </div><div class="col-7">{this.state.admissionDetails.bedNo}</div>
                                                                            <div class="col-5 ">IP Number : </div><div class="col-7">{this.state.admissionDetails.encounterNo}</div>

                                                                        </div>
                                                                    </div>) :
                                                                    (
                                                                        <div className="col-lg-8">
                                                                            <h5 className="mb-4">Booking details  </h5>
                                                                            <div class="row">
                                                                                <div class="col-5 ">Name : </div><div class="col-7">{this.state.patient.name}</div>
                                                                                <div class="col-5 ">Hospital UHID : </div><div class="col-7">{this.state.patient.patient_hid}</div>

                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }

                                                                <div className="col-lg-4" >
                                                                    {this.state.admissionDetails && Object.keys(this.state.admissionDetails).length != 0 && <div className="form-group mt-2 mb-2">

                                                                        <h5 > Admission Date</h5>
                                                                        <div >
                                                                            {this.state.admissionDetails.admissionDate}
                                                                        </div>
                                                                    </div>}
                                                                </div>
                                                            </div>

                                                            <div className="row row-grid">

                                                                <div className="col-md-6 col-lg-4 col-xl-4 stretch-card">

                                                                </div>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-4 stretch-card">

                                                <div className="card mb-4">
                                                    {this.state.isPriceLoading &&
                                                        <div className="clinytic-line-loader"></div>
                                                    }
                                                    <div className="card-body">
                                                        <div className="form-group">
                                                            <label>Payment amount</label>
                                                            <input type="number" className="form-control"  placeholder="Amount" name="amount" value={this.state.amount}  onKeyPress={(event)=>{if(event.key==='.'){event.preventDefault()}}} onChange={e => {
                                                                this.setState({
                                                                    amount: e.target.value.replace(/[^0-9]*/g,'')
                                                                })
                                                            }} />
                                                            <small><b>Note:</b> During check-out, any payment gateway/convenience charges are not collected by the hospital.</small>
                                                        </div>
                                                        <div className="form-check form-check-danger">
                                                            <label className="form-check-label" htmlFor="name" >
                                                                <input type="checkbox" className="form-check-input"
                                                                    name="isTerms" checked={this.state.isTerms ? true : false}
                                                                    onClick={() => this.setState({ isTerms: !this.state.isTerms })}
                                                                />
                                                                <i className="input-helper"></i>
                                                                {/* <i className="mdi mdi-information-outline" data-tip data-for="is_smswallet" /> */}
                                                            </label>
                                                            <span className="form-check-label" style={{ marginLeft: "2rem", cursor: 'pointer' }} onClick={() => this.setState({ openTerms: !this.state.openTerms })}
                                                            >I accept all <a style={{ color: "blue" }}> Terms & Conditions</a> of this payment</span>
                                                        </div>
                                                        <button type="button" className="btn btn-primary btn-block"
                                                            onClick={this.handlePayment} disabled={this.state.loading || !this.state.isTerms || !this.state.amount}>Pay Now</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <a className="text-decoration-none  back d-none" id="settings-trigger" href="#" data-toggle="tooltip"
                                        data-placement="right" title="" data-original-title="Back to page">Back</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.openTerms &&
                    <CommonModalView open={this.state.openTerms}
                        emitModalCloseEvent={() => this.setState({ openTerms: !this.state.openTerms })}
                        title={'Terms and Conditions'} size="lg">
                            
                        {accountInfo?.enable_opd_flow === true ?<MoolchandPlainTermsAndConditions/>:<PlainTermsAndConditions/>  }    
                        
                        
                    </CommonModalView>
                }


                {(this.state.showAlert) &&
                    <SweetAlertComponent show={this.state.showAlert} type={"danger"} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.state.alert.onClose}></SweetAlertComponent>
                }


            </>
        )
    }
}

export default HospitalConsultCheckout


