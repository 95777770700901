import React from "react";
import prescriptionService from '../../../Services/prescription.service';
import { Storage } from 'aws-amplify';
import Spinner from "../../../Common/Spinner/Spinner";
import { S3UPLOAD_KEY } from '../../../Constants/s3Key';
import SweetAlertComponent from '../../../Common/Alert/SweetAlertComponent';
import { ToastContainer, toast } from 'react-toastify';
import TemplateListViewContainer from './TemplateList/TemplateListViewContainer';
import templateService from '../../../Services/template.service';
class AfterCallSelectionModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templateList: [],
            uploadedFiles: [],
            alert: {
                type: '',
                title: '',
                text: ''
            },
            onPrescriptionUpload: false,
        }
    }

    getEmitedTemplateJsonData = (event, id, subType, templates = this.state.templateList) => {
        let { templateSelectionModal, formDataJson, viewType, hideFloatingButton, formDataJsonId, isFormActive, templateFileType, selected_template } = this.state;
        formDataJson = null;
        formDataJson = event;
        isFormActive = true;
        //console.log(subType);
        templateFileType = subType === "certificate" ? "certificate" : "";
        hideFloatingButton = true
        templateSelectionModal = false;
        //console.log("templates",templates);
        this.setState({
            selected_template: event.sections[0],
            templateSelectionModal, formDataJson, viewType, formDataJsonId: id,
            hideFloatingButton, loadAddButton: false, templateList: templates, shouldTemplateModalLoad: false, isFormActive, templateFileType
        });
        this.mainScrollView.current.scrollIntoView({ behavior: 'smooth' });
    }


    async componentDidMount() {

        try {
            const templatesList = await templateService.getTemplateListOfTypePrescriptionForm();
            this.checkForTemplateResponse(templatesList);
        }
        catch (error) {

        }

    }


    checkForTemplateResponse(response) {
        let { templateList } = this.state;
        if (!response) {
            return null;
        }
        switch (response.type) {
            case 'error':
                templateList = null;
                this.setState({ templateList, shouldTemplateModalLoad: true, loadAddButton: false });
                break;
            case 'success':
                let cachedTemplates = sessionStorage.getItem('templateResponse');
                if (!cachedTemplates && response.data.templates && response.data.templates.length) {
                    sessionStorage.setItem('templateResponse', JSON.stringify(response));
                }
                let taggedTemplateList = (response.data && response.data.templates && response.data.templates.length > 0) ? response.data.templates.filter(t => t.subtype === 'tagged') : [];

                if (taggedTemplateList.length) {
                    this.getTemplateData(taggedTemplateList[0].url, taggedTemplateList[0].id, response.data.templates);
                } else {

                    this.setState({
                        templateList: response.data.templates.filter(t => t.subtype !== 'instant-presc'), shouldTemplateModalLoad: false, templateSelectionModal: true, loadAddButton: false,
                        instantTemplates: response.data.templates.filter(t => t.subtype === 'instant-presc')
                    });
                }
                break;
            default:
                break;
        }
    }

    onEditAppointment = (event) => {
        this.props.emitEditAppointmentEvent('/edit-appointment');
    }

    onCloseEvent = (event) => {
        this.props.emitAfterCallModalClose('close');
    }

    onCallReconnectEvent = (event) => {
        this.props.emitOnCallReconnectEvent(this.props.appointmentData);
    }

    onFileUpload(e) {
        let file = e.target.files[0];
        if (!file) return;
        let re = /(\.jpg|\.jpeg|\.bmp|\.gif|\.png|\.pdf)$/i;
        let fileSize = (file.size / 1024 / 1024);
        if (fileSize > 15) {
            return;
        }
        if (!re.exec(file.name.toLowerCase())) {
            this.notify(`${file.name} is not allowed for upload`);
            return;
        }
        let { uploadedFiles } = this.state;
        uploadedFiles.push({ image: file, name: file.name, size: fileSize });
        this.setState({ uploadedFiles });
    }

    notify(message) {
        toast.warning(message, { position: toast.POSITION.TOP_RIGHT, containerId: 'A' });
    }

    deleteFromFiles(idx) {
        let { uploadedFiles } = this.state;
        uploadedFiles.splice(idx, 1);
        this.setState({ uploadedFiles });
    }

    onPrescriptionButtonClicked = (item) => {
        let indData = JSON.parse(sessionStorage.getItem("indData"))
        let id = indData['id'];
        let patientId = indData['patient_id'];
        this.props.emitPrescriptionClickedEvent(`/prescription-detail?patientId=${patientId}&appointmentId=${id}&type=appointment`, indData, item);
        this.emitEventOnPrescriptionClick(indData)
    }

    emitEventOnPrescriptionClick(data) {
        const nativeMode = sessionStorage.getItem('nativeMode');
        if (nativeMode === 'true' || nativeMode === true) {
            try {
                let obj = {
                    "req": data,
                    "action": "view-appointment",
                    "path": window.location.href
                }
                window.external.notify(JSON.stringify(obj));
            }
            catch (e) {
                console.log("Reload notify threw error!");
            }
        }
    }

    onSubmitReport() {
        let { uploadedFiles } = this.state;
        let PromiseArr = [];
        let isLast;
        let identityId = sessionStorage.getItem('identityId');
        if (uploadedFiles && uploadedFiles.length === 0 && !this.props.appointmentData) {
            return;
        }
        let { isLoading } = this.state;
        isLoading = true
        this.setState({ isLoading });
        let imgData = {
            file_type: 'prescription',
            file_source: 'upload',
            doctor_id: this.props.appointmentData && this.props.appointmentData.doctor_id ? this.props.appointmentData.doctor_id : '',
            doctor_name: this.props.appointmentData && this.props.appointmentData.doctor_name ? this.props.appointmentData.doctor_name : '',
            imgpath: '',
            date_of_report: this.props.date,
            account_id: this.props.appointmentData && this.props.appointmentData.account_id ? this.props.appointmentData.account_id : null,
            patient_id: this.props.appointmentData && this.props.appointmentData.patient_id ? this.props.appointmentData.patient_id : 'anonymous',
            appointment_id: this.props.appointmentData && this.props.appointmentData.id ? this.props.appointmentData.id : null,
        }


        uploadedFiles.forEach((file, idx) => {
            isLast = idx === uploadedFiles.length - 1;
            this.saveImageToS3(file, imgData, isLast);
        });
    }

    saveImageToS3(file, imgData) {
        let { image, name } = file;
        name = name.toLowerCase().replace(/\s/g, '')
        Storage.configure({
            level: 'private',
            AWSS3: S3UPLOAD_KEY
        });
        Storage.put(`env-${sessionStorage.getItem('apiStage')}/acc-${sessionStorage.getItem('s3AccountId')}/${new Date().getTime()}/templates/${name}`, image, {
            contentType: file.image.type
            // application/pdf
        }).then(res => {
            if (res.key) {
                let imgPath = `private/${sessionStorage.getItem('identityId')}/${res.key}`;
                imgData = { ...imgData, imgpath: imgPath };
                this.saveS3ImagePath(imgData);
            }
        }
        ).catch(err => {
            //error
        });
    }

    async saveS3ImagePath(imgData) {
        try {
            let response = await prescriptionService.uploadPrescriptionImage(imgData);
            let { alert, onPrescriptionUpload } = this.state;
            if (response) {
                onPrescriptionUpload = true;
                alert = { type: 'success', title: 'Document uploaded' };
                this.setState({ alert, isLoading: false, onPrescriptionUpload });
            } else {
                onPrescriptionUpload = true;
                alert = { type: 'error', title: 'Error', text: 'Error while uploading! Please try again.' };
                this.setState({ isLoading: false, alert, onPrescriptionUpload });
            }
        } catch (err) {
            let { alert, onPrescriptionUpload } = this.state;
            onPrescriptionUpload = true;
            alert = { type: 'error', title: 'Error', text: 'Error while uploading! Please try again.' };
            this.setState({ isLoading: false, alert, onPrescriptionUpload });
        }
    }

    onPrescriptionUploadClose = (event) => {
        this.setState({ onPrescriptionUpload: false });
    }

    render() {
        const { uploadedFiles, isLoading } = this.state;
        let isInValid = uploadedFiles && uploadedFiles.length === 0;
        const accountInfo = JSON.parse(sessionStorage.getItem("accountInfo"))
        let isBreathefree = sessionStorage.getItem("s3AccountId");

        
        return (
            <>
                <div className="row statusbox">
                    <div className="col-md-12 stretch-card">
                        <div className="card bg-gradient-success card-img-holder text-white">
                            <div className="card-body" style={{ display: 'flex', flex: 1, alignItems: 'flex-end', textAlign: 'center', cursor: 'pointer' }} onClick={this.onCallReconnectEvent.bind(this)}>
                                <img src="images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
                                <h5 className="font-weight-normal" style={{ width: '100%' }}>Re-Connect Call  </h5>
                            </div>
                        </div>
                    </div>
                    {/* <div className="col-md-6 stretch-card">
                        <div className="card bg-gradient-info card-img-holder text-white">
                            <div className="card-body" style={{ display: 'flex', flex: 1, alignItems: 'flex-end', textAlign: 'center', cursor: 'pointer' }} onClick={this.onEditAppointment.bind(this)}>
                                <img src="images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
                                <h5 className="font-weight-normal mt-0" style={{ width: '100%', margin: 10 }}>Reschedule Appointment</h5>
                            </div>
                        </div>
                    </div> */}

                    {
                        isBreathefree === "breathefree" ? "" : <div className="col-12 mt-3">
                            <form method="post" action="#" id="#">
                                {
                                    accountInfo.account_id !== "moolchandhospital" && <ul className="pl-3 mt-2 text-small">
                                        <li>   You can upload maximum 10 documents</li>
                                        <li>Each document size to be less than 5MB</li>
                                        <li> Document type can be Photo, PDF</li>
                                    </ul>
                                }



                                {
                                    accountInfo.enable_opd_flow === true ? <div className="form-group  color">
                                        <label>Choose Prescpription </label>
                                        {
                                            this.state.templateList.map((item) => {
                                                if (item.templateType === "general") {
                                                    return <p>  {item.name}   <button style={{ float: "right" }} className="btn btn-outline-success btn-icon-text  btn-sm" onClick={() => this.onPrescriptionButtonClicked(item)}>Add</button>   </p>
                                                }
                                            })
                                        }
                                    </div> : <div className="form-group filedrag color">
                                        <label>Upload Your File </label>

                                        <input type="file" name="img" multiple className="form-control" onChange={e => this.onFileUpload(e)} onClick={(e) => { e.target.value = null }}></input>
                                    </div>
                                }


                            </form>

                            {
                                accountInfo.enable_opd_flow === true ? "" : <>
                                    {uploadedFiles.length === 0
                                        ?
                                        <>No files uploaded as yet.</>
                                        :
                                        <>
                                            <div className="col-md-12 d-none d-md-block">
                                                <div className="row">
                                                    {uploadedFiles.map((file, idx) =>
                                                        <div key={idx} className="col-md-6">
                                                            <div className="row no-gutters  mb-3 pb-3">
                                                                <div className="col-3 col-md-3">
                                                                    <a className="lightbox" href="#file1">
                                                                        <img src="/images/file.svg" alt="" width="40px" />
                                                                    </a>
                                                                    <div className="lightbox-target" id="file1">
                                                                        <img src="/images/file.svg" alt="" width="300px" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-9 col-md-9">
                                                                    <div className="d-flex justify-content-between">
                                                                        <small>{file.name}{file.size &&
                                                                            <>
                                                                                ({(file.size).toFixed(2)}MB)</>
                                                                        }</small>
                                                                        <small> <a onClick={(e) => this.deleteFromFiles(idx)}> <i className="mdi mdi-close"></i></a></small>
                                                                    </div>
                                                                    <div className="progress progress-lg mt-2">
                                                                        <div className="progress-bar bg-info" role="progressbar" style={{ width: "100%" }}>100%</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </>}
                                </>
                            }

                        </div>
                    }

                    <div className="col-12 mt-3">
                        <button type="submit" className="btn btn-success mr-2 btn-rounded" onClick={this.onCloseEvent.bind(this)}>Close</button>
                        { isBreathefree !== "breathefree" && !accountInfo.enable_opd_flow && accountInfo.enable_opd_flow === undefined && <button type="submit" disabled={isLoading || isInValid} className="btn btn-success mr-2 btn-rounded" onClick={() => this.onSubmitReport()}>Submit</button>} {isLoading && <div style={{ display: 'inline-block', 'marginLeft': '30px' }}><Spinner width={50} height={50}></Spinner></div>}
                    </div>
                    <div className="col-md-12 pt-5 d-block d-md-none">
                        <div className="row">
                            {uploadedFiles.map((file, idx) =>
                                <div key={idx} className="col-md-6">
                                    <div className="row no-gutters border-bottom mb-3 pb-3">
                                        <div className="col-3 col-md-3">
                                            <a className="lightbox" href="#file1">
                                                <img src="/images/file.svg" alt="" width="40px" />
                                            </a>
                                            <div className="lightbox-target" id="file1">
                                                <img src="/images/file.svg" alt="" width="300px" />
                                            </div>
                                        </div>
                                        <div className="col-9 col-md-9">
                                            <div className="d-flex justify-content-between">
                                                <small>{file.name}{file.size &&
                                                    <>
                                                        ({(file.size).toFixed(2)}MB)</>
                                                }</small>
                                                <small> <a onClick={(e) => this.deleteFromFiles(idx)}> <i className="mdi mdi-close"></i></a></small>
                                            </div>
                                            <div className="progress progress-lg mt-2">
                                                <div className="progress-bar bg-info" role="progressbar" style={{ width: "100%" }}>100%</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {this.state.onPrescriptionUpload &&
                    <SweetAlertComponent show={this.state.onPrescriptionUpload} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.onPrescriptionUploadClose}></SweetAlertComponent>
                }
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
            </>
        );
    }
}

export default AfterCallSelectionModal;
