import React from "react";
// component imports
import ColumnTitleComponent from './Title/ColumnTitleComponent';
import ID from '../../../Views/FormBuilder/Utils/UUID';
import RowViewComponent from './Rows/RowViewComponent';
import CompactTableRowComponent from './Rows/CompactTableRowComponent';
import SuggestionNewViewComponent from '../Common/SuggestionNewViewComponent';
import PatientInfoComponent from '../Common/PatientInfoComponent';
import Spinner from "../../../Common/Spinner/Spinner";
import prescriptionService from "../../../Services/prescription.service";
import userService from '../../../Services/users.service';
import SweetAlertComponent from '../../../Common/Alert/SweetAlertComponent';
import TextEditor from "../../../Common/TextEditor";
import SectionActionComponent from './SectionActionComponent';
import CommonModalView from '../../../Common/Modal/CommonModalView';
import SaveRxComponent from './Actions/SaveRxComponent';
import LoadRxComponent from './Actions/LoadRxComponent';
import { withSnackbar } from 'notistack';
import { S3UPLOAD_KEY } from '../../../Constants/s3Key';
import { Storage } from 'aws-amplify';
import Utils from '../../../Shared/utils/utils';
import UAParser from 'ua-parser-js';
import { Link } from 'react-scroll'

var Parser = require('expr-eval').Parser;
const parser = new Parser();
// service imports


class SectionViewComponent extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         dynamicFormData: this.props.dynamicFormData,
         viewType: this.props.viewType ? this.props.viewType : '',
         isSuggestionActive: false,
         suggestionList: [],
         show: false,
         suggestionIndexDetails: null,
         showPatient: false,
         fieldConfig: null,
         errorStatus: false,
         patientData: null,
         checkFormTrigger: 0,
         isDirty: false,
         crashSavedData: null,
         isSaveRx: false,
         isRxLoading: false,
         isLoadRx: false,
         selectedRxSection: null,
         rxIndex: '',
         calcNextAppointment:false,
         alert: {
            show: false,
            type: '',
            title: '',
            confirmBtnText: ``,
            text: ``,
            alertType: ''
         },
         formulaConfig: [ //array of objects containing config of each column which implements a formula
            // {
            //       colIndex: '',
            //       rowIndex: '',
            //       childIndex: '',
            //       sectionIndex: '',
            //       formula: '',
            //       localvar: '' 
            // }
         ],
         conditionalHideConfig: [ //array of objects containing config of target row which requires class change on change in source
            // {
            //    displaycondition: '',
            //    class: '',
            //    rowIndex: '',
            //    childIndex: '',
            //    sectionIndex: ''
            // }
         ],
         sectionRowErrorArray: [
            // {sectionIndex: '', rowIndex: ''}
         ],
         rowConfig: null,
         groupConfig: null,
         isView: this.props.isView ? this.props.isView : false
      }
      
     
      this.checkForUiDataDicWRTColumns()
   }

   checkForNextVisitDate(){
      //console.log("checkForNextVisitDate",JSON.stringify(this.state.dynamicFormData),JSON.stringify(this.state.dynamicFormData).indexOf('"nextAppointmentCalc":"yes"'));
     let {calcNextAppointment}=this.state;
      if( JSON.stringify(this.state.dynamicFormData).indexOf('"nextAppointmentCalc":"yes"') > -1 ) {
         calcNextAppointment=true;
         this.setState({calcNextAppointment});
     }
     else{
      //this.setState({calcNextAppointment:false});
     }
   }

   componentDidMount() {
      // let obj = sessionStorage.getItem('suggestions');
      // let savedSuggObj = obj ? JSON.parse(obj) : '';
      // let { template_id } = this.props;
      // if (!savedSuggObj || !savedSuggObj[template_id]) {
      //    this.fetchAndSaveSuggestions(template_id, savedSuggObj);
      // }

      this.crashSavedDataLoad();
      this.getRxListData();
      this.checkForNextVisitDate();
   }



   crashSavedDataLoad() {
      // fetch previously saved data after a crash
      // autofill if : 
      // current user is the user for whom the crash happened
      // currently loaded parent is where the last crash happened
      // & if form has same id as that of crashed form
      let previouslySavedData = localStorage.getItem('savedPrescData');
      previouslySavedData = previouslySavedData ? JSON.parse(previouslySavedData) : null;
      let profileInfo = sessionStorage.getItem('profileInfo');
      profileInfo = profileInfo ? JSON.parse(profileInfo) : null;
      if (previouslySavedData && profileInfo
         && previouslySavedData.cognito_user_sub === profileInfo.cognito_user_sub
         && previouslySavedData.parentContainer === this.props.parentContainer
         && previouslySavedData.id === this.props.uniquePrescId) {
         let { crashSavedData, alert } = this.state;
         crashSavedData = previouslySavedData.dynamicFormData;
         alert = {
            show: true,
            type: 'confirmation',
            title: 'Previous work available!',
            confirmBtnText: `Yes, reload it!`,
            text: `There are some previously filled details saved in your browser. Do you want to reload that information?`
         }
         localStorage.setItem('savedPrescData', '');
         this.setState({ crashSavedData, alert });
      }
   }

   sweetAlertClose = (event) => {
      let { alert, dynamicFormData, crashSavedData } = this.state;
      if (alert.type === 'confirmation' && !alert.alertType) {
         alert = { show: false };
         dynamicFormData = crashSavedData;
         crashSavedData = null;
         this.setState({ alert, dynamicFormData, crashSavedData });
      } else if (alert.type === 'confirmation' && (alert.alertType && alert.alertType === "rx")) {
         alert = { show: false, formName: alert.formName, tempId: alert.tempId };
         this.setState({ alert }, () => this.onUpdateRxDataEvent());
      } else {
         alert = { show: false }
         this.setState({ alert });
      }

   }

   sweetAlertCancelEvent = (event) => {
      this.setState({ alert: { show: false } });
   }

   componentDidCatch(error, errorInfo) {
      try {
         let profileInfo = sessionStorage.getItem('profileInfo');
         profileInfo = profileInfo ? JSON.parse(profileInfo) : null;
         let savedData = {
            parentContainer: this.props.parentContainer,
            dynamicFormData: this.state.dynamicFormData,
            id: this.props.uniquePrescId,
            cognito_user_sub: profileInfo ? profileInfo.cognito_user_sub : null
         }
         localStorage.setItem('savedPrescData', JSON.stringify(savedData));
      } catch (err) {
         //maybe caused if localstorage is full
         console.log('Error thrown in SectionViewComponent error boundary!');
      }
      this.logErrorToService(error, errorInfo);
   }

   async logErrorToService(error, errorInfo) {
      try {
         // add service call here
         let data = {
            error: error,
            errorInfo: errorInfo
         }
         const response = await userService.logAppError(data);
         //
      } catch (err) {
         console.log(err)
      }
   }

   updateNewSuggestions(data, sectionName) {
      let existingSuggestions = JSON.parse(sessionStorage.getItem('suggestions'));
      let newSuggestions = data['suggestions'];
      let messages = data['messages'];
      let allNewSuggestions = {}
      let keys = Object.keys(newSuggestions);
      let existingKeys = Object.keys(existingSuggestions);
      existingKeys = existingKeys.filter((e) => { return keys.indexOf(e) == -1 })
      for (let k of keys) {
         if (!allNewSuggestions[k]) {
            allNewSuggestions[k] = {}
         }
         if (newSuggestions[k] && newSuggestions[k].length && newSuggestions[k].length > 0) {
            for (let s of newSuggestions[k]) {
               var newKey = s.replace(/\s+/g, '').toLowerCase();

               if (existingSuggestions[k] && existingSuggestions[k][newKey]) {
                  allNewSuggestions[k][newKey] = existingSuggestions[k][newKey]
               }
               else {
                  allNewSuggestions[k][newKey] = { 'level1': k, 'result_t': s }
               }
            }
         }
         if (existingSuggestions[k] && Object.keys(existingSuggestions[k]).length > 0) {
            for (let e of Object.keys(existingSuggestions[k])) {
               if (!allNewSuggestions[k][e]) {
                  allNewSuggestions[k][e] = existingSuggestions[k][e]
               }
            }
         }

      }
      for (let e of existingKeys) {
         allNewSuggestions[e] = existingSuggestions[e]
      }
      let rKey = sectionName
      if (messages[rKey] && allNewSuggestions[rKey]) {
         // allNewSuggestions["messages"] = messages[rKey]
         allNewSuggestions[rKey]["messages"] = messages[rKey]
      }
      sessionStorage.setItem('suggestions', JSON.stringify(allNewSuggestions))
      let dataArr = {
         [rKey]: allNewSuggestions[rKey]
      }
      if (dataArr[rKey]) {
         dataArr[rKey]["messages"] = messages[rKey];
      }
      // sectionName == 'complaints' ? 'symptoms' :
      return { [rKey]: allNewSuggestions[rKey], messages: messages[rKey] };
   }



   async fetchAndSaveSuggestions(finalPayloadData, template_id, selectionSection, rowIndex, sectionIndex, e, str) {
      // let formnames = this.state.dynamicFormData.sections.map(s => s.formname).filter(name => name).map(n => n.toLowerCase()).join(',');
      try {
         // if (this.state.dynamicFormData.sections) {
         //    finalPayloadData['vitals'] = this.props.vitals;
         // }
         let sectionName = this.state.dynamicFormData.sections[sectionIndex].formname;
         if (this.props.labtests) {
            finalPayloadData['labtests'] = this.props.labtests;
         }
         let res = await prescriptionService.getSuggestionDataBySection(finalPayloadData, template_id, selectionSection);
         if (res && res.type === 'success' && res.data) {
            let isChild = e.childIndex !== undefined;
            let { suggestionList, isSuggestionActive, suggestionIndexDetails } = this.state;
            let config = {
               type: isChild ? 'child' : 'row',
               sectionIndex: sectionIndex,
               rowIndex: rowIndex,
               childIndex: e.childIndex,
               columnIndex: e.columnIndex
            };

            isSuggestionActive = true;
            suggestionList = this.updateNewSuggestions(res.data, sectionName);
            suggestionIndexDetails = config;

            this.setState({ suggestionList, suggestionIndexDetails, isSuggestionActive });
         }
         if (res && res.type === 'error' && !res.data) {

            let { suggestionList } = this.state;
            suggestionList = null;
            this.setState({ suggestionList });
         }
      } catch (e) {
         let { suggestionList } = this.state;
         suggestionList = null;
         this.setState({ suggestionList });
      }
   }

   checkForUiDataDicWRTColumns() {
      if (!this.state.dynamicFormData) {
         return;
      }
      this.mapUiDataDicToColumnData();
   }

   static getDerivedStateFromProps(nextProps, prevState) {
      if (nextProps.dynamicFormData !== prevState.dynamicFormData) {
         return {
            viewType: nextProps.viewType ? nextProps.viewType : '',
            dynamicFormData: nextProps.dynamicFormData
         };
      }
      else return null;
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.dynamicFormData !== this.state.dynamicFormData) {
         //Perform some operation here
         this.checkForUiDataDicWRTColumns()
      }
   }

   mapUiDataDicToColumnData() {
      let sectionsData = this.state.dynamicFormData.sections;
      if (!sectionsData || sectionsData.length === 0) {
         return;
      }
      for (let i = 0; i < this.state.dynamicFormData.sections.length; i++) {
         let uiDataDic = this.state.dynamicFormData.sections[i].uiDataDic;
         for (let j = 0; j < this.state.dynamicFormData.sections[i].rows.length; j++) {
            if (this.state.dynamicFormData.sections[i].rows[j].type === "row" || this.state.dynamicFormData.sections[i].rows[j].type === "compactRow") {
               this.mapTypeRowDataToDFdata(uiDataDic, i, j);
            } else if (this.state.dynamicFormData.sections[i].rows[j].type === "group") {
               this.mapTypeGroupDataToDFdata(uiDataDic, i, j);
            } else if (this.state.dynamicFormData.sections[i].rows[j].type === "subSection") {
               this.mapTypeGroupDataToDFdata(uiDataDic, i, j);
            }
         }
      }
   }

   mapTypeRowDataToDFdata(uiDataDic, i, j) {
      for (let k = 0; k < this.state.dynamicFormData.sections[i].rows[j].columns.length; k++) {
         let index = 0
         const { dynamicFormData } = this.state;
         let indColumn = this.state.dynamicFormData.sections[i].rows[j].columns[k];
         if (indColumn.name === "name" && (indColumn.type === "textbox" || indColumn.type === "textarea" || indColumn.type === 'autoComplete' || indColumn.type === 'typeahead')) {
            dynamicFormData.sections[i].rows[j]['ismultiple'] = true;
            if (!dynamicFormData.sections[i].rows[j]['index']) {
               dynamicFormData.sections[i].rows[j]['index'] = index;
               index = index + 1
            }

            dynamicFormData.sections[i].rows[j] = JSON.parse(JSON.stringify(dynamicFormData.sections[i].rows[j]))
            this.setState({ dynamicFormData });
         }
         let uiDatakey = this.state.dynamicFormData.sections[i].rows[j].columns[k].uiData;
         if (!uiDatakey) {
            continue;
         }
         for (let key in uiDataDic) {
            if (key === uiDatakey) {
               dynamicFormData.sections[i].rows[j].columns[k]['options'] = JSON.parse(JSON.stringify(uiDataDic[key].options));
               this.setState({ dynamicFormData });
            }
         }
      }
   }

   checkIfConfigIsEmpty(data) {
      let config = data.config;
      if (!config || Object.keys(config).length === 0) {
         return true;
      }
      return false;
   }

   mapTypeGroupDataToDFdata(uiDataDic, i, j) {
      try {

         for (let k = 0; k < this.state.dynamicFormData.sections[i].rows[j].children.length; k++) {
            let index = 0;
            // let isConfigEmpty = ''
            // this.checkIfConfigIsEmpty(this.state.dynamicFormData.sections[i].rows[j].children[k])
            // if (isConfigEmpty) {
            //    this.state.dynamicFormData.sections[i].rows[j].children[k]['class'] = '';
            // }

            for (let l = 0; l < this.state.dynamicFormData.sections[i].rows[j].children[k].columns.length; l++) {
               const { dynamicFormData } = this.state;
               let indColumn = this.state.dynamicFormData.sections[i].rows[j].children[k].columns[l];
               // if (isConfigEmpty && !indColumn.colId && indColumn.class === "displayparent") {
               //    indColumn['class'] = '';
               // }
               if (indColumn.name === "name" && (indColumn.type === "textbox" || indColumn.type === "textarea" || indColumn.type === 'autoComplete' || indColumn.type === 'typeahead')) {
                  dynamicFormData.sections[i].rows[j].children[k]['ismultiple'] = true;
                  if (!dynamicFormData.sections[i].rows[j].children[k]['index']) {
                     dynamicFormData.sections[i].rows[j].children[k]['index'] = index;
                     index = index + 1
                  }

                  dynamicFormData.sections[i].rows[j].children[k] = JSON.parse(JSON.stringify(dynamicFormData.sections[i].rows[j].children[k]))
                  this.setState({ dynamicFormData });
               }
               let uiDatakey = this.state.dynamicFormData.sections[i].rows[j].children[k].columns[l].uiData;
               if (!uiDatakey) {
                  continue;
               }
               for (let key in uiDataDic) {
                  if (key === uiDatakey) {
                     dynamicFormData.sections[i].rows[j].children[k].columns[l]['options'] = JSON.parse(JSON.stringify(uiDataDic[key].options));
                     this.setState({ dynamicFormData });
                  }
               }
            }
         }
      }
      catch (err) {
         console.log(err);
         this.logErrorToService(err);
      }
   }

   getEmitedHeaderCollapseEvent = (event) => {
      if (!event) {
         console.log(`---- No formname for this event!!! ----`);
         return;
      }
      let formname = event;
      for (let index in this.state.dynamicFormData.sections) {
         if (this.state.dynamicFormData.sections[index].formname === formname) {
            const { dynamicFormData } = this.state;
            dynamicFormData.sections[index].collapse = !this.state.dynamicFormData.sections[index].collapse;
            this.setState({ dynamicFormData });
            return;
         }
      }
   }

   getSuggestionData = (selectionSection, rowIndex, sectionIndex, e, str) => {
      const allowedTypes = ['drugs', 'diagnosis', 'symptoms', 'investigation', 'investigations', 'complaints', 'advice', "vitals"];
      this.checkForFilledSections(allowedTypes, selectionSection, rowIndex, sectionIndex, e, str);
   }

   checkForFilledSections(allowedTypes, selectionSection, rowIndex, sectionIndex, e, str) {
      let { dynamicFormData } = this.state;
      let finalPayloadData = {};
      for (let index in dynamicFormData.sections) {
         let indSection = dynamicFormData.sections[index];
         // if (indSection.formname === selectionSection) {
         //    continue;
         // }
         if (indSection.status === "filled" && allowedTypes.indexOf(indSection.formname) !== -1) {
            // finalPayloadData[indSection.formname]
            let indSectionName = (indSection.formname === "complaints") ? 'symptoms' : (indSection.formname === "testRequired") ? "investigations" : indSection.formname
            let valueStr = ''
            for (let rowIndex in indSection.rows) {
               let indRow = indSection.rows[rowIndex];
               if (indRow.type === "row") {

                  for (let colIndex in indRow.columns) {
                     let indCol = indRow.columns[colIndex];
                     if (indSection.formname === "vitals") {
                        valueStr = indCol.value ? valueStr + `${indCol.label}:${indCol.value} ` + `${(colIndex === indRow.columns.length - 1) ? '' : ','}` : valueStr + ''
                     } else {
                        valueStr = indCol.value ? valueStr + indCol.value + `${(colIndex === indRow.columns.length - 1) ? '' : ','}` : valueStr + ''
                     }

                  }
               }
            }
            str = str.substring(0, str.length - 1);
            if (!valueStr || valueStr == str) {
               continue
            }
            let valArr = (valueStr.indexOf(",") !== -1) ? valueStr.split(",") : [valArr];
            valArr = valArr.filter(e => e !== str && e !== "");
            finalPayloadData[indSectionName] = valArr;
         }
      }
      this.fetchAndSaveSuggestions(finalPayloadData, this.props.template_id, selectionSection, rowIndex, sectionIndex, e, str)
   }

   fetchSuggestions(rowIndex, sectionIndex, e, str) {
      let sectionName = this.state.dynamicFormData.sections[sectionIndex].formname;
      let isChild = e.childIndex !== undefined;
      let res = this.fetchSuggestionsFromSS(sectionName);
      if (res) {
         let config = {
            type: isChild ? 'child' : 'row',
            sectionIndex: sectionIndex,
            rowIndex: rowIndex,
            childIndex: e.childIndex,
            columnIndex: e.columnIndex
         };
         this.setState({
            isSuggestionActive: true,
            suggestionList: res,
            suggestionIndexDetails: config
         })
      } else {
         this.setState({
            isSuggestionActive: false,
            suggestionList: {},
            suggestionIndexDetails: null
         })
      }
   }

   fetchSuggestionsFromSS(sectionName) {
      let obj = sessionStorage.getItem('suggestions');
      let suggObj = obj ? JSON.parse(obj) : '';
      let { template_id } = this.props;
      // if (sectionName == 'complaints') {
      //    sectionName = 'symptoms'
      // }
      if (suggObj && suggObj.hasOwnProperty(sectionName)) {
         return { [sectionName]: suggObj[sectionName] };
      }
   }

   updateChildFieldConfigData(rowIndex, sectionIndex, childIndex, columnIndex) {
      let { fieldConfig } = this.state;
      fieldConfig = {
         type: 'group',
         sectionIndex: sectionIndex,
         rowIndex: rowIndex,
         childIndex: childIndex,
         columnIndex: columnIndex
      }
      this.setState({ fieldConfig });

   }

   updateFieldConfigData(rowIndex, sectionIndex, columnIndex) {
      let { fieldConfig } = this.state;
      fieldConfig = {
         type: 'row',
         sectionIndex: sectionIndex,
         rowIndex: rowIndex,
         columnIndex: columnIndex
      }
      this.setState({ fieldConfig });
   }

   getEmitedChildFormalLocalVar = (rowIndex, sectionIndex, childIndex, columnIndex, event) => {
      //console.log(rowIndex, sectionIndex, childIndex, columnIndex, event);
      if (!event) {
         return;
      }
      try {
         let childrenData = [...this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children];
         //console.log(childrenData);
         let localvarArray = event.localvar.split(',');
         let data = {};
         for (let i = 0; i < localvarArray.length; i++) {
            data[localvarArray[i]] = '';
         }

         for (let i = 0; i < localvarArray.length; i++) {
            let indLocalVar = localvarArray[i];
            for (let j = 0; j < childrenData.length; j++) {
               if (childIndex === j) {
                  continue;
               }
               let indChild = childrenData[j];
               for (let k = 0; k < indChild.columns.length; k++) {
                  let indColumn = indChild.columns[k];
                  if (!indColumn.colId) {
                     continue;
                  }
                  if (indColumn.colId === indLocalVar) {
                     data[indLocalVar] = indColumn.value;
                  }
               }
            }
         }
         let computedValue = this.mapFormulaWithLocalVarValue(event.formula, data);
         if (!computedValue && computedValue !== '') {
            return;
         }
         const { dynamicFormData } = this.state;
         dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].columns[columnIndex].value = computedValue !== '' ? computedValue.toFixed(3) : '';

         this.setState({ dynamicFormData }, () => this.updateChildFieldConfigData(rowIndex, sectionIndex, childIndex, columnIndex));
      } catch (err) {
         console.log(err);
         this.logErrorToService(err);
      }
   }

   getEmitedFormalLocalVar = (rowIndex, sectionIndex, columnIndex, event) => {
      //console.log("eventdata: - " + "rowIndex: " + rowIndex + ",sectionIndex:-" + sectionIndex + "columnIndex:- " + columnIndex, event);
      //console.log(this.state.dynamicFormData);
      if (!event) {
         return;
      }
      let rowsData = [...this.state.dynamicFormData.sections[sectionIndex].rows];
      let localvarArray = event.localvar.split(',');
      let data = {};
      for (let i = 0; i < localvarArray.length; i++) {
         data[localvarArray[i]] = '';
      }
      for (let i = 0; i < localvarArray.length; i++) {
         let indLocalVar = localvarArray[i];
         for (let j = 0; j < rowsData.length; j++) {
            // if (rowIndex === j) {
            //    continue;
            // } dont know about it
            let indRow = rowsData[j];
            for (let k = 0; k < indRow.columns.length; k++) {
               let indColumn = indRow.columns[k];
               if (!indColumn.colId) {
                  continue;
               }
               if (indColumn.colId === indLocalVar) {
                  data[indLocalVar] = indColumn.value;
               }
            }
         }
      }
      let computedValue = this.mapFormulaWithLocalVarValue(event.formula, data);
      if (!computedValue && computedValue !== '') {
         return;
      }
      const { dynamicFormData } = this.state;
      dynamicFormData.sections[sectionIndex].rows[rowIndex].columns[columnIndex].value = computedValue !== '' ? computedValue.toFixed(3) : '';

      this.setState({ dynamicFormData }, () => this.updateFieldConfigData(rowIndex, sectionIndex, columnIndex));
   }

   setColumnsWithColIdToEmitOnUpdate = (sectionIndex, e) => {
      let { formulaConfig } = this.state;
      let config = { ...e, sectionIndex };
      let repeatIdx = formulaConfig.findIndex(c => {
         return ((!config.childIndex && c.sectionIndex === config.sectionIndex && c.colIndex === config.colIndex && c.rowIndex === config.rowIndex) ||
            (config.childIndex && c.sectionIndex === config.sectionIndex && c.colIndex === config.colIndex && c.rowIndex === config.rowIndex && c.childIndex === config.childIndex));
      });
      if (repeatIdx !== -1) formulaConfig.splice(repeatIdx, 1);
      formulaConfig.push(config);
      this.setState({ formulaConfig });
   }

   changeFormulaDependentValue = (target) => {
      if (target.childIndex || target.childIndex === 0) {
         this.getEmitedChildFormalLocalVar(target.rowIndex, target.sectionIndex, target.childIndex, target.colIndex, { localvar: target.localvar.join(), formula: target.formula });
      } else {
         this.getEmitedFormalLocalVar(target.rowIndex, target.sectionIndex, target.colIndex, { localvar: target.localvar.join(), formula: target.formula });
      }
   }

   extractFirstText(str) {
      const matches = str.match(/"(.*?)"/);
      return matches ? matches[1] : str;
   }

   mapFormulaWithLocalVarValue(formula, data) {
      if (!data || !formula) {
         return;
      }
      let newFormula = ''
      for (let key in data) {
         if (!data[key]) {
            return '';
         }
         // formula= formula.split(`${key}`).join(data[key])
      }
      try {
         let expr = parser.parse(formula);
         return expr.evaluate(data)
      } catch (error) {
         console.log('wrong values entered in formula field');
      }
   }

   getEmitedDeleteRowObjectData = (rowIndex, sectionIndex, columnIndex, event) => {
      let rowsData = JSON.parse(JSON.stringify(this.state.dynamicFormData.sections[sectionIndex].rows))
      let data = rowsData.splice(rowIndex, 1);
      const { dynamicFormData } = this.state;
      dynamicFormData.sections[sectionIndex].rows = JSON.parse(JSON.stringify(rowsData));
      this.setState({ dynamicFormData }, () => this.updateFieldConfigData(rowIndex, sectionIndex, columnIndex));
      //console.log(this.state.dynamicFormData);

   }

   getEmitedChildAddNewRowEvent = (rowIndex, sectionIndex, childIndex, columnIndex) => {
      try {
         let childrenData = JSON.parse(JSON.stringify(this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children));
         childrenData[childIndex].index = childIndex + 1;
         childrenData[childIndex].rowId = ID.uuid();
         if (childrenData[childIndex].hasOwnProperty("index")) {
            childrenData[childIndex]['index'] = JSON.parse(JSON.stringify(childrenData[childIndex]['index'] + 1))
         }
         childrenData[childIndex].status = "empty";
         for (let i = 0; i < childrenData[childIndex].columns.length; i++) {
            if (childrenData[childIndex].columns[i].type === "header") {
               childrenData[childIndex].columns[i].readOnly = false;
            }
            childrenData[childIndex].columns[i].value = ''
         }
         const { dynamicFormData } = this.state;
         dynamicFormData.sections[sectionIndex].rows[rowIndex].children.splice(childIndex + 1, 0, childrenData[childIndex]);
         this.setState({ dynamicFormData });
         this.updateChildFieldConfigData(rowIndex, sectionIndex, childIndex, columnIndex);
         // updateChildFieldConfigData
      } catch (err) {
         console.log(err);
         this.logErrorToService(err);
      }
   }

   getEmitedChildDeleteRowObjectData = (rowIndex, sectionIndex, childIndex, columnIndex) => {
      try {
         let childrenData = [...this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children]
         let data = childrenData.splice(childIndex, 1);
         const { dynamicFormData } = this.state;
         dynamicFormData.sections[sectionIndex].rows[rowIndex].children = childrenData;
         this.setState({ dynamicFormData });
         //console.log(this.state.dynamicFormData);
         this.updateChildFieldConfigData(rowIndex, sectionIndex, childIndex, columnIndex);
      } catch (err) {
         console.log(err);
         this.logErrorToService(err);
      }
   }

   getEmitedChildLoadOptionsEvent = (rowIndex, sectionIndex, childIndex, columnIndex, str, callback) => {
      //console.log(rowIndex, sectionIndex, columnIndex, childIndex, str, callback);
      //console.log(this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex])
      // this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children[columnIndex].columns[childIndex]

      let autoKey = this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].columns[columnIndex].auto ? this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].columns[columnIndex].autoKey : ''
      if (autoKey) {
         this.asyncSelectloadAutocompleteList(str, autoKey).then(result => {
            callback(result)
         })
      }
   }

   getEmitedLoadOptionsEvent = (rowIndex, sectionIndex, columnIndex, str, callback) => {

      //console.log(this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex]);
      let autoKey = this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].columns[columnIndex].auto ? this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].columns[columnIndex].autoKey : ''
      if (autoKey) {
         this.asyncSelectloadAutocompleteList(str, autoKey).then(result => {
            callback(result)
         })
      }

   }

   async asyncSelectloadAutocompleteList(str, autoKey) {
      let existingSuggestions = JSON.parse(sessionStorage.getItem('suggestions'))
      let autoComplete = JSON.parse(localStorage.getItem('autocomplete'));
      let newKey = autoKey;
      let autoOptions = [];
      let allKeys = []
      if (autoKey == 'complaints') {
         newKey = 'symptoms'
      }
      if (autoKey == "drug") {
         newKey = 'drugs'
      }

      let suggestionForSection = existingSuggestions && Object.keys(existingSuggestions).length > 0 ? existingSuggestions[newKey] : {};
      if (suggestionForSection)
         allKeys = Object.keys(suggestionForSection);
      let formattedStr = str.replace(/\s+/g, '').toLowerCase();
      let newOptions = allKeys.filter((e) => { return e.startsWith(formattedStr, 0) }).map(v => ({ label: suggestionForSection[v].result_t, value: suggestionForSection[v].result_t }));
      if (autoComplete && autoComplete[newKey]) {
         autoComplete = autoComplete[newKey];
         autoOptions = autoComplete.filter((e) => { return e.startsWith(formattedStr, 0) }).map(v => ({ label: v, value: v }));
      }

      var allOptions = newOptions.concat(autoOptions);
      let jsonObject = allOptions.map(JSON.stringify);

      //console.log(jsonObject);

      let uniqueSet = new Set(jsonObject);
      var uniqueArray = Array.from(uniqueSet).map(JSON.parse);

      //console.log(uniqueArray);
      if (uniqueArray.length > 5) {
         return uniqueArray;
      }


      let key = autoKey;
      try {
         const allowedTypes = ['patient', 'symptoms', 'disease', 'diagnosis', 'drugs', 'vitals', 'biomarkers', 'investigations'];
         let matchingTypeIdx = allowedTypes.findIndex(t => t.includes(key.toLowerCase()) || key.toLowerCase().includes(t));
         let allowedType = allowedTypes[matchingTypeIdx];
         if (matchingTypeIdx === -1) {
            if (('test required').includes(key.toLowerCase()) || key.toLowerCase().includes('test required')) {
               allowedType = 'labtest';
            } else if (('complaint').includes(key.toLowerCase()) || key.toLowerCase().includes('complaint')) {
               allowedType = 'symptoms';
            }
         }
         let res = await prescriptionService.fetchSuggestions(allowedType, 'suggestions', str);
         if (res && res.type === 'success' && res.data && res.data.result && res.data.result.length) {
            let options = res.data.result.map(v => ({ label: v, value: v }));
            allOptions = allOptions.concat(options);
            let jsonObject = allOptions.map(JSON.stringify);

            //console.log(jsonObject);

            let uniqueSet = new Set(jsonObject);
            let uniqueArray = Array.from(uniqueSet).map(JSON.parse);

            //console.log(uniqueArray);
            return uniqueArray;
         } else {
            if (uniqueArray && uniqueArray.length > 0) {
               return uniqueArray
            }
            return [{ value: 'initial', label: 'No results', isDisabled: true }];
         }
      } catch (err) {
         return [{ value: 'initial', label: 'No results', isDisabled: true }];
      }
   }

   getEmitedAddNewRowEvent = (rowIndex, sectionIndex, columnIndex, event) => {
      // if (!event) {
      //    return;
      // }
      let rowsData = JSON.parse(JSON.stringify(this.state.dynamicFormData.sections[sectionIndex].rows));
      if (!rowsData || rowsData[rowIndex].type === "subSection") {
         return;
      }

      try {
         rowsData[rowIndex].index = rowIndex + 1;
         rowsData[rowIndex].rowId = ID.uuid();
         rowsData[rowIndex].status = "empty";
         if (rowsData[rowIndex].hasOwnProperty("index")) {
            rowsData[rowIndex]['index'] = JSON.parse(JSON.stringify(rowsData[rowIndex]['index'] + 1))
         }
         if (rowsData[rowIndex]) {
            if (rowsData[rowIndex].type === "row" && rowsData[rowIndex].columns) {
               let columns = rowsData[rowIndex].columns
               for (let index in columns) {
                  columns[index]['value'] = '';
               }
               rowsData[rowIndex].columns = columns;
               // [columnIndex] && rowsData[rowIndex].columns[columnIndex]['value']
               // rowsData[rowIndex].columns[columnIndex]['value'] = '';
            } else if (rowsData[rowIndex].type === "group") {
               let childrenData = rowsData[rowIndex].children;
               for (let j = 0; j < childrenData.length; j++) {
                  for (let i = 0; i < childrenData[j].columns.length; i++) {
                     childrenData[j].columns[i].value = '';
                     childrenData[j].columns[i].values = [];
                  }
               }
               rowsData[rowIndex].children = childrenData;
            } else if (rowsData[rowIndex].type === "compactRow" && rowsData[rowIndex].columns) {
               rowsData[rowIndex].columns[0]['readOnly'] = false;
               for (let index in rowsData[rowIndex].columns) {
                  rowsData[rowIndex].columns[index]['value'] = '';
               }
               // rowsData[rowIndex].columns[columnIndex]['value'] = '';
            }
         }
         const { dynamicFormData } = this.state;
         if (rowsData[rowIndex].type === "compactRow") {
            dynamicFormData.sections[sectionIndex].rows.push(rowsData[rowIndex]);
         } else {
            if (dynamicFormData.sections[sectionIndex].formname === "drugs") {
               dynamicFormData.sections[sectionIndex].rows.push(rowsData[rowIndex]);
               //edited by keshav
               
               /*let nextVisitCountType=((event.value).toLowerCase()).replace(/[0-9]| /g, '');
               let nextVisitCountNum=parseInt((event.value).replace(/\D/g, ""));
               let nextVisitCountDays=0;
               let currentIns=this;
               if(nextVisitCountType&&nextVisitCountNum){
                  switch(nextVisitCountType){
                     case 'day':
                     case 'days':
                        nextVisitCountDays+=nextVisitCountNum;
                        break;
                     case 'week':
                     case 'weeks':
                        nextVisitCountDays+=nextVisitCountNum*7;
                        break;      
                     case 'month':
                     case 'months':
                        nextVisitCountDays+=nextVisitCountNum*30;
                        break;
                     case 'year':
                     case 'years':
                        nextVisitCountDays+=nextVisitCountNum*365;
                        break;                  
                  }
                  //console.log("nextVisitCountDays",nextVisitCountDays);
                  if(parseInt(nextVisitCountDays)>0){
                     const { dynamicFormData } = this.state;
                     //console.log("dynamicFormData",dynamicFormData);
                     (dynamicFormData.sections).forEach(function (section, sectionIndex) {
                        
                        if(section.rows){
                           (section.rows).forEach(function(sectionRow,SectionRowIndex){
                                 if(sectionRow.columns){
                                    (sectionRow.columns).forEach(function(sectionRowColumn,SectionRowColumnIndex){
                                       if(typeof (sectionRowColumn.nextAppointmentCalc)!='undefined'&&sectionRowColumn.nextAppointmentCalc=='yes'){
                                          dynamicFormData["sections"][sectionIndex]["rows"][SectionRowIndex]['status']=dynamicFormData["sections"][sectionIndex]["status"]='filled';
                                          dynamicFormData["sections"][sectionIndex]["rows"][SectionRowIndex]["columns"][SectionRowColumnIndex]['value']=sectionRowColumn.value=currentIns.dateToYMD(new Date(new Date().getTime()+(nextVisitCountDays*24*60*60*1000)));
                                          //console.log('dynamicFormData["sections"][sectionIndex]["rows"][SectionRowIndex]["columns"][SectionRowColumnIndex]["value"]', dynamicFormData["sections"][sectionIndex]["rows"][SectionRowIndex]["columns"][SectionRowColumnIndex]['value']);
                                       }
                                    });
                                 }
                           });

                        }
                     });
                     //console.log("dynamicFormData",dynamicFormData);
                     //this.setState({ dynamicFormData });
                  }
               }*/
               //editing ends
            } else {
               dynamicFormData.sections[sectionIndex].rows.splice(rowIndex + 1, 0, rowsData[rowIndex]);
            }
         }
         this.setState({ dynamicFormData }, () => {
            if (dynamicFormData.sections[sectionIndex].rows[rowIndex].type === 'group') this.updateChildFieldConfigData(rowIndex + 1, sectionIndex, '', columnIndex);
            else this.updateFieldConfigData(rowIndex + 1, sectionIndex, columnIndex)
         });
      } catch (err) {
         console.log(err);
         this.logErrorToService(err);
      }
   }
   getEmitedCloneRowEvent = (rowIndex, sectionIndex, columnIndex, event) => {
      if (!event) {
         return;
      }
      let rowsData = JSON.parse(JSON.stringify(this.state.dynamicFormData.sections[sectionIndex].rows));
      if (!rowsData || rowsData[rowIndex].type === "subSection" || !rowsData[rowIndex]) {
         return;
      }

      rowsData[rowIndex].index = rowIndex + 1;
      rowsData[rowIndex].rowId = ID.uuid();
      if (rowsData[rowIndex].hasOwnProperty("index")) {
         rowsData[rowIndex]['index'] = JSON.parse(JSON.stringify(rowsData[rowIndex]['index'] + 1))
      }
      const { dynamicFormData } = this.state;
      if (rowsData[rowIndex].type === "compactRow") {
         dynamicFormData.sections[sectionIndex].rows.push(rowsData[rowIndex]);
      } else {
         dynamicFormData.sections[sectionIndex].rows.splice(rowIndex + 1, 0, rowsData[rowIndex]);
      }
      this.setState({ dynamicFormData }, () => {
         if (dynamicFormData.sections[sectionIndex].rows[rowIndex].type === 'group') this.updateChildFieldConfigData(rowIndex + 1, sectionIndex, '', columnIndex);
         else this.updateFieldConfigData(rowIndex + 1, sectionIndex, columnIndex)
      });

   }

   getEmitedAddNewTableRowEvent = (rowIndex, sectionIndex, event) => {
      let rowsData = JSON.parse(JSON.stringify(this.state.dynamicFormData.sections[sectionIndex].rows));
      if (!rowsData || rowsData[rowIndex].type === "subSection") {
         return;
      }
      rowsData[rowIndex].index = rowIndex + 1;
      rowsData[rowIndex].status = "empty";
      if (rowsData[rowIndex])
         if (rowsData[rowIndex].type === "compactRow" && rowsData[rowIndex].columns) {
            rowsData[rowIndex].columns[0]['readOnly'] = false;
            for (let index in rowsData[rowIndex].columns) {
               rowsData[rowIndex].columns[index]['value'] = '';
            }
            // rowsData[rowIndex].columns[columnIndex]['value'] = '';
         }
      const { dynamicFormData } = this.state;
      if (rowsData[rowIndex].type === "compactRow") {
         dynamicFormData.sections[sectionIndex].rows.splice(rowIndex + 1, 0, rowsData[rowIndex]);
      }
      this.setState({ dynamicFormData });
      this.updateFieldConfigData(rowIndex, sectionIndex, '')
   }

   updateChildCompactTableRow(sectionIndex, rowIndex, childIndex, columnIndex, event) {
      try {
         let indChild = JSON.parse(JSON.stringify(this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex]));
         for (let index in indChild.columns) {
            if (indChild.columns[index].type === "header") {
               indChild.columns[index].readOnly = false;
            }
            indChild.columns[index].value = "";
         }
         let { dynamicFormData } = this.state;
         indChild.columns[columnIndex].value = event.value;
         dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].columns[columnIndex].value = event.value;
         dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].columns, 'column');
         dynamicFormData.sections[sectionIndex].rows[rowIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows[rowIndex].children, "compactTable");
         dynamicFormData.sections[sectionIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows, 'row');
         this.setState({ dynamicFormData })
         return;
      } catch (err) {
         console.log(err);
         this.logErrorToService(err);
      }
   }

   getEmitedChildTextboxValue = (rowIndex, sectionIndex, childIndex, columnIndex, event) => {
      //console.log(rowIndex, sectionIndex, childIndex, columnIndex, event);
      if (!event) {
         return;
      }
      this.checkForIsDirtyData();
      this.updateChildFieldConfigData(rowIndex, sectionIndex, childIndex, columnIndex);
      if (this.state.dynamicFormData.sections[sectionIndex].showType === "compactTable") {
         let indColumnData = this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].columns[columnIndex];
         if (indColumnData.auto && event.value.length >= 3) {
            this.fetchSuggestions(rowIndex, sectionIndex, { columnIndex, childIndex }, event.value);
         }
         this.updateChildCompactTableRow(sectionIndex, rowIndex, childIndex, columnIndex, event);
      } else {
         try {
            let indColumnData = this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].columns[columnIndex];
            if (indColumnData.type && indColumnData.type === 'date' && indColumnData.primaryDate) {
               this.setPrimaryDate(this.state.dynamicFormData.sections[sectionIndex].formname, event.value);
            }
            if (indColumnData.auto && event.value.length >= 3) {
               this.fetchSuggestions(rowIndex, sectionIndex, { columnIndex, childIndex }, event.value);
            }
            if (!indColumnData.auto && this.state.isSuggestionActive) {
               this.setState({ isSuggestionActive: false });
            }
            if (event.type && event.type === "radio") {
               for (var j = 0; j < indColumnData.options.length; j++) {
                  if (indColumnData.options[j].value !== event.value) {
                     indColumnData.options[j]["checked"] = false;
                     continue;
                  }
                  indColumnData.options[j]["checked"] = (indColumnData.value === event.value) ? false : true
               }
               indColumnData.value = (indColumnData.value === event.value) ? "" : event.value;
            } else if (event.type && event.type === "checkbox") {
               if (indColumnData.values.length > 0 && indColumnData.values.indexOf(event.value) !== -1) {
                  let valueIndex = indColumnData.values.indexOf(event.value);
                  indColumnData.values.splice(valueIndex, 1);
               } else {
                  indColumnData.values.push(event.value);
               }
            } else {
               indColumnData.value = event.value;
            }
            const { dynamicFormData } = this.state;
            let child = dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex];
            child.columns[columnIndex] = indColumnData;
            child.status = this.getFilledStatus(child.columns, 'column');
            dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex] = child;
            dynamicFormData.sections[sectionIndex].rows[rowIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows[rowIndex].children, 'row');
            dynamicFormData.sections[sectionIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows, 'row');
            this.setState({ dynamicFormData }, () => {
               if (indColumnData.colId) { // this column's value is used in some formula
                  let { formulaConfig } = this.state;
                  formulaConfig.forEach(config => {
                     if (config.localvar.indexOf(indColumnData.colId) !== -1) { // this column's value is used in this formula
                        this.changeFormulaDependentValue(config);
                     }
                  });
               }
               if ((event.type === 'checkbox' || event.type === 'radio' || event.type === 'selection' || event.type === 'typeahead') && (indColumnData && indColumnData.class && indColumnData.class.indexOf('displayparent') !== -1)) {
                  let values = event.type === 'checkbox' ? indColumnData.values : [indColumnData.value];
                  this.renderConditionalClass(indColumnData.colId, values, { rowIndex, sectionIndex, childIndex, columnIndex });
               }
            });
         } catch (err) {
            console.log(err);
            this.logErrorToService(err);
         }
      }
   }

   checkForIsDirtyData() {
      let { isDirty } = this.state;
      isDirty = true;
      this.setState({ isDirty }, () => this.props.emitEventIfFormValueChange(true));
   }

   getEmitedFocusEvent = (rowIndex, sectionIndex, columnIndex, event) => {
      let indColumnData = this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].columns[columnIndex];
      if (indColumnData.auto || indColumnData.name === "name") {
         // let isChild = childIndex !== undefined;
         let sectionName = (this.state.dynamicFormData.sections[sectionIndex].formname).toLowerCase();
         let res = this.fetchSuggestionsFromSS(sectionName);
         let { suggestionList, isSuggestionActive, suggestionIndexDetails } = this.state;

         suggestionList = res
         let config = {
            type: 'row',
            key: indColumnData.autoKey,
            sectionIndex: sectionIndex,
            rowIndex: rowIndex,
            // childIndex: e.childIndex,
            columnIndex: columnIndex
         };
         if (suggestionIndexDetails && JSON.stringify(suggestionIndexDetails) === JSON.stringify(config)) {
            suggestionIndexDetails = config;
            this.setState({ suggestionList, suggestionIndexDetails, isSuggestionActive });
         } else {
            isSuggestionActive = true;
            suggestionIndexDetails = config;
            this.setState({ suggestionList, suggestionIndexDetails, isSuggestionActive });
         }

      } else {
         let { suggestionList, isSuggestionActive, suggestionIndexDetails } = this.state;
         isSuggestionActive = false;
         suggestionIndexDetails = null;
         suggestionList = null
         this.setState({ suggestionList, suggestionIndexDetails, isSuggestionActive });
      }
   }


   euroCalculation(rowIndex, selection, ChangedRow,) {
      if(selection.value === ""){
         ChangedRow.columns[rowIndex + 1].value = ""
         return
      }
      if (ChangedRow.rowId === "age_1_years") {
         if (parseInt(selection.value) === 0 || selection.value === "") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
        else if (selection.value < 61) {
            ChangedRow.columns[rowIndex + 1].value = 0.0285181
         }
         else {
            ChangedRow.columns[rowIndex + 1].value = (selection.value - 59) * 0.0285181
         }
      }
      if (ChangedRow.rowId === "gender") {
         if (selection.value === "Male" ) {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else {
            ChangedRow.columns[rowIndex + 1].value = 0.2196434
         }
      }
      if (ChangedRow.rowId === "renal_impairment") {
         if (selection.value === "normal (CC >85ml/min)") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else if (selection.value === "moderate (CC >50 & <85)") {
            ChangedRow.columns[rowIndex + 1].value = .303553
         }
         else if (selection.value === "severe (CC <50)") {
            ChangedRow.columns[rowIndex + 1].value = .8592256
         }
         else if (selection.value === "dialysis (regardless of CC)") {
            ChangedRow.columns[rowIndex + 1].value = .6421508
         }
      }
      if (ChangedRow.rowId === "extracardiac_arteriopathy") {
         if (selection.value === "No") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else {
            ChangedRow.columns[rowIndex + 1].value = .5360268
         }
      }
      if (ChangedRow.rowId === "poor_mobility") {
         if (selection.value === "No") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else {
            ChangedRow.columns[rowIndex + 1].value = .2407181
         }
      }

      if (ChangedRow.rowId === "previous_cardiac_surgery") {
         if (selection.value === "No") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else {
            ChangedRow.columns[rowIndex + 1].value = 1.118599
         }
      }
      if (ChangedRow.rowId === "chronic_lung_disease") {
         if (selection.value === "No") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else {
            ChangedRow.columns[rowIndex + 1].value = .1886564
         }
      }
      if (ChangedRow.rowId === "active_endocarditis") {
         if (selection.value === "No") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else {
            ChangedRow.columns[rowIndex + 1].value = 0.6194522
         }
      }
      if (ChangedRow.rowId === "critical_preoperative_state") {
         if (selection.value === "No") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else {
            ChangedRow.columns[rowIndex + 1].value = 1.086517
         }
      }
      if (ChangedRow.rowId === "diabetes_on_insulin") {
         if (selection.value === "No") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else {
            ChangedRow.columns[rowIndex + 1].value = .3542749
         }
      }
      if (ChangedRow.rowId === "nyha_0091") {
         if (selection.value === "I") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else if (selection.value === "II") {
            ChangedRow.columns[rowIndex + 1].value = .1070545
         }
         else if (selection.value === "III") {
            ChangedRow.columns[rowIndex + 1].value = .2958358
         }
         else if (selection.value === "IV") {
            ChangedRow.columns[rowIndex + 1].value = .5597929
         }
      }
      if (ChangedRow.rowId === "ccs_class_4_angina") {
         if (selection.value === "No") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else {
            ChangedRow.columns[rowIndex + 1].value = .2226147
         }
      }
      if (ChangedRow.rowId === "lv_function") {
         if (selection.value === "Good (LVEF > 50%)") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else if (selection.value === "moderate (LVEF 31%-50%)") {
            ChangedRow.columns[rowIndex + 1].value = .3150652
         }
         else if (selection.value === "poor (LVEF 21%-30%)") {
            ChangedRow.columns[rowIndex + 1].value = .8084096
         }
         else if (selection.value === "very poor (LVEF 20% or less)") {
            ChangedRow.columns[rowIndex + 1].value = .9346919
         }
      }
      if (ChangedRow.rowId === "recent_mi") {
         if (selection.value === "No") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else {
            ChangedRow.columns[rowIndex + 1].value = .1528943
         }
      }
      if (ChangedRow.rowId === "pulmonary_hypertension") {
         if (selection.value === "No") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else if (selection.value === "31  -  55 mmHg (Moderate)") {
            ChangedRow.columns[rowIndex + 1].value = .1788899
         }
         else if (selection.value === " >55mmHg (Severe)") {
            ChangedRow.columns[rowIndex + 1].value = .3491475
         }
      }
      if (ChangedRow.rowId === "urgency_453") {
         if (selection.value === "Elective") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else if (selection.value === "Urgent") {
            ChangedRow.columns[rowIndex + 1].value = .3174673
         }
         else if (selection.value === "Emergency") {
            ChangedRow.columns[rowIndex + 1].value = .7039121
         }
         else if (selection.value === "Salvage") {
            ChangedRow.columns[rowIndex + 1].value = 1.362947
         }
      }
      if (ChangedRow.rowId === "weight_of_the_intervention") {
         if (selection.value === "Isolated CABG") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else if (selection.value === "Single non CABG") {
            ChangedRow.columns[rowIndex + 1].value = .0062118
         }
         else if (selection.value === "2 Procedures") {
            ChangedRow.columns[rowIndex + 1].value = .5521478
         }
         else if (selection.value === "3 Procedures") {
            ChangedRow.columns[rowIndex + 1].value = .9724533
         }
      }
      if (ChangedRow.rowId === "surgery_on_thoracic_aorta") {
         if (selection.value === "No") {
            ChangedRow.columns[rowIndex + 1].value = 0
         }
         else {
            ChangedRow.columns[rowIndex + 1].value = .6527205
         }
      }
   }

    dateToYMD(date) {
      var d = date.getDate();
      var m = date.getMonth() + 1; //Month from 0 to 11
      var y = date.getFullYear();
      return '' + (d <= 9 ? '0' + d : d)+ '-' + (m<=9 ? '0' + m : m)  + '-' + y;
  }
  

   getEmitedTextboxValue = (rowIndex, sectionIndex, columnIndex, event) => {
      const { currentSectionId } = this.props
     //keshav pandit changes for next date
     //console.log("calcNextAppointment",this.state.calcNextAppointment);
     //console.log("dynamicFormData",this.state.dynamicFormData);
     //console.log("getEmitedTextboxValue",rowIndex,sectionIndex,columnIndex,event);
     if(event.name=="duration"&&event.type=="typeahead"){
         //console.log(((event.value).toLowerCase()).replace(/[0-9]| /g, ''));
         //console.log((event.value).replace(/\D/g, ""));
         let nextVisitCountType=((event.value).toLowerCase()).replace(/[0-9]| /g, '');
         let nextVisitCountNum=parseInt((event.value).replace(/\D/g, ""));
         let nextVisitCountDays=0;
         let currentIns=this;
         if(nextVisitCountType&&nextVisitCountNum){
            switch(nextVisitCountType){
               case 'day':
               case 'days':
                  nextVisitCountDays+=nextVisitCountNum;
                  break;
               case 'week':
               case 'weeks':
                  nextVisitCountDays+=nextVisitCountNum*7;
                  break;      
               case 'month':
               case 'months':
                  nextVisitCountDays+=nextVisitCountNum*30;
                  break;
               case 'year':
               case 'years':
                  nextVisitCountDays+=nextVisitCountNum*365;
                  break;                  
            }
            //console.log("nextVisitCountDays",nextVisitCountDays);
            if(parseInt(nextVisitCountDays)>0){
               const { dynamicFormData } = this.state;
               //console.log("dynamicFormData",dynamicFormData);
               (dynamicFormData.sections).forEach(function (section, sectionIndex) {
                  
                  if(section.rows){
                     (section.rows).forEach(function(sectionRow,SectionRowIndex){
                           if(sectionRow.columns){
                              (sectionRow.columns).forEach(function(sectionRowColumn,SectionRowColumnIndex){
                                 if(typeof (sectionRowColumn.nextAppointmentCalc)!='undefined'&&sectionRowColumn.nextAppointmentCalc=='yes'){
                                    dynamicFormData["sections"][sectionIndex]["rows"][SectionRowIndex]['status']=dynamicFormData["sections"][sectionIndex]["status"]='filled';
                                    dynamicFormData["sections"][sectionIndex]["rows"][SectionRowIndex]["columns"][SectionRowColumnIndex]['value']=sectionRowColumn.value=currentIns.dateToYMD(new Date(new Date().getTime()+(nextVisitCountDays*24*60*60*1000)));
                                    //console.log('dynamicFormData["sections"][sectionIndex]["rows"][SectionRowIndex]["columns"][SectionRowColumnIndex]["value"]', dynamicFormData["sections"][sectionIndex]["rows"][SectionRowIndex]["columns"][SectionRowColumnIndex]['value']);
                                 }
                              });
                           }
                     });

                  }
                });
                //console.log("dynamicFormData",dynamicFormData);
               this.setState({ dynamicFormData });
            }
         }
     }
      if (currentSectionId !== undefined) {
         if (currentSectionId.type === "euro_Calculator") {
            let currentRow = currentSectionId.rows[rowIndex]
            this.euroCalculation(columnIndex, event, currentRow)


            let finalScore = currentSectionId.rows.find(element => element.rowId === "euroscore_ii")
            let total = 0
            currentSectionId.rows.map((item) => {
               if (item.rowId !== "header") {
                  if(item.columns.length !== 1){
                     total += item.columns[1].value === "" ? 0 :item.columns[1].value
                  } 
               }
            })
            let constant_value = -5.324537 + parseFloat(total) 
            finalScore.columns[0].value = parseFloat((Math.exp(constant_value) / (1+ Math.exp(constant_value))) * 100).toFixed(2) 
         
            const { dynamicFormData } = this.state
            dynamicFormData.sections[0] = currentSectionId
            this.setState({ dynamicFormData })
         }
      }



      if (!event) {
         return;
      }

      this.checkForIsDirtyData();
      this.updateFieldConfigData(rowIndex, sectionIndex, columnIndex);

      try {
         let indColumnData = this.state.dynamicFormData.sections[sectionIndex].rows[rowIndex].columns[columnIndex];
         
       
         if (indColumnData.auto && event.value.length >= 3) {
            this.getSuggestionData(this.state.dynamicFormData.sections[sectionIndex].formname, rowIndex, sectionIndex, { columnIndex }, event.value);
            this.fetchSuggestions(rowIndex, sectionIndex, { columnIndex }, event.value);
         }
         // if (!indColumnData.auto && this.state.isSuggestionActive) {
         //    this.setState({ isSuggestionActive: false });
         // }
         if (indColumnData.type && indColumnData.type === 'date' && indColumnData.primaryDate) {
            this.setPrimaryDate(this.state.dynamicFormData.sections[sectionIndex].formname, event.value);
         }
         if (event.type && event.type === "radio") {
            for (var j = 0; j < indColumnData.options.length; j++) {
               if (indColumnData.options[j].value !== event.value) {
                  indColumnData.options[j]["checked"] = false;
                  continue;
               }
               indColumnData.options[j]["checked"] = (indColumnData.value === event.value) ? false : true
            }
           
            indColumnData.value = (indColumnData.value === event.value) ? "" : event.value;
         } else if (event.type && event.type === "checkbox") {
            if (indColumnData.values.length > 0 && indColumnData.values.indexOf(event.value) !== -1) {
               let valueIndex = indColumnData.values.indexOf(event.value);
               indColumnData.values.splice(valueIndex, 1);
            } else {
               indColumnData.values.push(event.value);
            }
         } else {
            if (indColumnData.autoKey && (indColumnData.autoKey === "complaints" || indColumnData.autoKey === "diagnosis" || indColumnData.autoKey === "investigations" || indColumnData.autoKey === "drugs")) {
               indColumnData.value = event.value.toUpperCase();
            } else {
               
               indColumnData.value = event.value;
            }
         }

         const { dynamicFormData } = this.state;
         let row = dynamicFormData.sections[sectionIndex].rows[rowIndex];
         row.columns[columnIndex] = indColumnData;
         row.status = this.getFilledStatus(row.columns, 'column');
         dynamicFormData.sections[sectionIndex].rows[rowIndex] = row;
         dynamicFormData.sections[sectionIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows, 'row');
         
         this.setState({ dynamicFormData }, () => {
            if (indColumnData.colId) { // this column's value is used in some formula
               let { formulaConfig } = this.state;
               formulaConfig.forEach(config => {
                  if (config.localvar.indexOf(indColumnData.colId) !== -1) { // this column's value is used in this formula
                     this.changeFormulaDependentValue(config);
                  }
               });
            }
            if ((event.type === 'checkbox' || event.type === 'radio' || event.type === 'selection' || event.type === 'typeahead')
               && (indColumnData && indColumnData.class && indColumnData.class.indexOf('displayparent') !== -1)) {
               let values = event.type === 'checkbox' ? indColumnData.values : [indColumnData.value];
               this.renderConditionalClass(indColumnData.colId, values, { rowIndex, sectionIndex, columnIndex });
            }
         });
      } catch (err) {
         console.log(err);
         this.logErrorToService(err);
      }
      // this.setState({...this.state})
   }

   // changing html for section with text editor
   changeFreeText = (sectionIndex, html) => {
      try {
         const { dynamicFormData } = this.state;
         dynamicFormData.sections[sectionIndex].html = html;
         dynamicFormData.sections[sectionIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].html, 'freeText');
         this.setState({ dynamicFormData }, () => {
            if (!this.props.emitFreeTextData) {
               return;
            }
            this.props.emitFreeTextData(html, sectionIndex)
         });
      } catch (err) {
         console.log(err);
         this.logErrorToService(err);
      }
   }

   setPrimaryDate(sectionName, date) {
      let { dynamicFormData } = this.state;
      if (!dynamicFormData.config) {
         dynamicFormData.config = {}
      }
      if (!dynamicFormData.config.hasOwnProperty(sectionName)) {
         dynamicFormData.config = {
            ...dynamicFormData.config,
            [sectionName]: {}
         }
      }
      dynamicFormData.config[sectionName] = {
         ...dynamicFormData.config[sectionName],
         primaryDate: date
      }
      this.setState({ dynamicFormData });
   }

   getFilledStatus(arr, level) {
      switch (level) {
         case 'freeText':
            return arr ? 'filled' : 'empty';
         case "compactTable":
         case 'subSection':
         case 'column':
            // return arr.some(col => ((col.value !== "" && col.value !== null) || (col.values.length !== 0 || !col.values))) ? 'filled' : 'empty';
            let allThreeExist = 0;
            let allThreeHaveValues = 0;
            arr.forEach(col => {
               if (col.name === 'name' || col.name === 'value' || col.name === 'comment')
                  allThreeExist += 1;
               if ((col.value !== "" && col.value !== null) || (col.values && col.values.length !== 0)) {
                  if (col.name !== "drugType") {
                     allThreeHaveValues += 1
                  }
               }
            })
            let nameValueCommCheckPassed = allThreeHaveValues !== 0

            // if()

            return arr.some(col => ((col.value !== "" && col.value !== null) || (col.values.length !== 0 || !col.values))) &&
               nameValueCommCheckPassed ? 'filled' : 'empty';
         case 'row':
         case 'compactRow':
            return arr.some(row => row.status === 'filled') ? 'filled' : 'empty';
      }
   }

   onSuggestionButtonClicked = (event) => {
      event.preventDefault();
      this.setState({
         isSuggestionActive: true
      })
   }

   onSuggestionCloseEvent = (event) => {
      event.preventDefault();
      if (event)
         this.setState({
            isSuggestionActive: false
            //  suggestionIndexDetails: null
         })
   }

   getSectionIndex = (key) => {
      let { dynamicFormData } = this.state;
      // key = (key === "symptoms") ? "complaints" : key;
      for (let index in dynamicFormData.sections) {
         let formName = dynamicFormData.sections[index].formname.toLowerCase();
         if (formName === key) {
            return index;
         }
      }
   }

   getColumnIndex = (label, rowData) => {
      for (let index in rowData.columns) {
         let indCol = rowData.columns[index];
         if (((indCol.label).toLowerCase()).indexOf(label.level2) !== -1) {
            return index;
         }
      }
   }

   getTemplateWiseSuggestionData = (event, sectionKey) => {
      if (!event) {
         return;
      }
      let { dynamicFormData, suggestionIndexDetails } = this.state;
      let { sectionIndex, columnIndex, rowIndex, childIndex, type } = suggestionIndexDetails;
      try {
         
         let sectionIndex = this.getSectionIndex(sectionKey);
         if ((rowIndex || rowIndex === 0) && !childIndex) {
            if (typeof event === 'object') {
               let rowData = JSON.parse(JSON.stringify(dynamicFormData.sections[sectionIndex].rows[rowIndex]));
               rowData = this.mapDataWithSuggestionEvent(rowData, event);
               rowData.columns[columnIndex].value = event.result_t ? event.result_t.toUpperCase() : event.result_date ? event.result_date : '';
               if (rowData.hasOwnProperty("index")) {
                  rowData['index'] = JSON.parse(JSON.stringify(rowData['index'] + 1))
               }
               if (rowData.hasOwnProperty("index")) {
                  rowData['index'] = JSON.parse(JSON.stringify(rowData['index'] + 1))
               }
               rowData.status = this.getFilledStatus(rowData.columns, 'column');
               dynamicFormData.sections[sectionIndex].rows.push(rowData);
               dynamicFormData.sections[sectionIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows, 'row');
               this.setState({ dynamicFormData });
            } else {
               let rowData = JSON.parse(JSON.stringify(dynamicFormData.sections[sectionIndex].rows[rowIndex]));
               rowData.columns[columnIndex].value = event.toUpperCase();
               if (rowData.hasOwnProperty("index")) {
                  rowData['index'] = JSON.parse(JSON.stringify(rowData['index'] + 1))
               }
               if (rowData.hasOwnProperty("index")) {
                  rowData['index'] = JSON.parse(JSON.stringify(rowData['index'] + 1))
               }
               // 
               rowData.status = this.getFilledStatus(rowData.columns, 'column');
               dynamicFormData.sections[sectionIndex].rows.push(rowData);
               dynamicFormData.sections[sectionIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows, 'row');
               this.setState({ dynamicFormData });
            }

         } else if (rowIndex && childIndex) {
            if (typeof event === 'object') {
               let childrenData = JSON.parse(JSON.stringify(dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex]));
               let columnIndex = this.getColumnIndex(event, childrenData);
               // childrenData = this.mapDataWithSuggestionEvent(childrenData, event);
               childrenData.columns[columnIndex].value = event.result_t;
               dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex] = childrenData;
            } else {
               let childrenData = JSON.parse(JSON.stringify(dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex]));
               let columnIndex = this.getColumnIndex(event, childrenData);
               dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].columns[columnIndex].value = event;
            }
            this.setState({ dynamicFormData });
         }
      } catch (err) {
         console.log(err);
         this.logErrorToService(err);
      }
   }

   getEmitedSuggestionData = (event, sectionType) => {
      if (!event || !this.state.suggestionIndexDetails) {
         return;
      }
      let { dynamicFormData, suggestionIndexDetails } = this.state;
      let { rowIndex, sectionIndex, columnIndex, childIndex, type, key } = suggestionIndexDetails;
      try {
         //console.log("event",event);
         if (type === "row") {
            if (typeof event === 'object') {
               let rowData = JSON.parse(JSON.stringify(dynamicFormData.sections[suggestionIndexDetails.sectionIndex].rows[suggestionIndexDetails.rowIndex]));
               rowData.status = this.getFilledStatus(rowData.columns, 'column');
               rowData = this.mapDataWithSuggestionEvent(rowData, event);
               rowData.columns[columnIndex].value = (event.level1 === "drugs") ? event.result_t.toUpperCase() : event.result_t.toUpperCase();
               // (rowData.columns[columnIndex].value + ", " + event.result_t);
               //edited by keshav
               
               /*let nextVisitCountType=((event.value).toLowerCase()).replace(/[0-9]| /g, '');
               let nextVisitCountNum=parseInt((event.value).replace(/\D/g, ""));
               let nextVisitCountDays=0;
               let currentIns=this;
               if(nextVisitCountType&&nextVisitCountNum){
                  switch(nextVisitCountType){
                     case 'day':
                     case 'days':
                        nextVisitCountDays+=nextVisitCountNum;
                        break;
                     case 'week':
                     case 'weeks':
                        nextVisitCountDays+=nextVisitCountNum*7;
                        break;      
                     case 'month':
                     case 'months':
                        nextVisitCountDays+=nextVisitCountNum*30;
                        break;
                     case 'year':
                     case 'years':
                        nextVisitCountDays+=nextVisitCountNum*365;
                        break;                  
                  }
                  //console.log("nextVisitCountDays",nextVisitCountDays);
                  if(parseInt(nextVisitCountDays)>0){
                     const { dynamicFormData } = this.state;
                     //console.log("dynamicFormData",dynamicFormData);
                     (dynamicFormData.sections).forEach(function (section, sectionIndex) {
                        
                        if(section.rows){
                           (section.rows).forEach(function(sectionRow,SectionRowIndex){
                                 if(sectionRow.columns){
                                    (sectionRow.columns).forEach(function(sectionRowColumn,SectionRowColumnIndex){
                                       if(typeof (sectionRowColumn.nextAppointmentCalc)!='undefined'&&sectionRowColumn.nextAppointmentCalc=='yes'){
                                          dynamicFormData["sections"][sectionIndex]["rows"][SectionRowIndex]['status']=dynamicFormData["sections"][sectionIndex]["status"]='filled';
                                          dynamicFormData["sections"][sectionIndex]["rows"][SectionRowIndex]["columns"][SectionRowColumnIndex]['value']=sectionRowColumn.value=currentIns.dateToYMD(new Date(new Date().getTime()+(nextVisitCountDays*24*60*60*1000)));
                                          //console.log('dynamicFormData["sections"][sectionIndex]["rows"][SectionRowIndex]["columns"][SectionRowColumnIndex]["value"]', dynamicFormData["sections"][sectionIndex]["rows"][SectionRowIndex]["columns"][SectionRowColumnIndex]['value']);
                                       }
                                    });
                                 }
                           });

                        }
                     });
                     //console.log("dynamicFormData",dynamicFormData);
                     //this.setState({ dynamicFormData });
                  }
               }*/
               //editing ends
               dynamicFormData.sections[sectionIndex].rows[rowIndex] = rowData;
               dynamicFormData.sections[sectionIndex].status = this.getFilledStatus(dynamicFormData.sections[sectionIndex].rows, 'row');
            } else {
               dynamicFormData.sections[sectionIndex].rows[rowIndex].columns[columnIndex].value = event;
            }
            this.setState({ dynamicFormData }, () => {
               if (event && event.level1 && event.level1 === 'drugs') {
                  this.getEmitedAddNewRowEvent(rowIndex, sectionIndex, columnIndex, '')
               }

            });
         } else if (type === "child") {
            if (typeof event === 'object') {
               let childrenData = JSON.parse(JSON.stringify(dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex]));
               childrenData = this.mapDataWithSuggestionEvent(childrenData, event);
               childrenData.columns[columnIndex].value = event.result_t;
               dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex] = childrenData;
            } else {
               dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex].columns[columnIndex].value = event;
            }
            this.setState({ dynamicFormData });
         }
      } catch (err) {
         console.log(err);
         this.logErrorToService(err);
      }
   }

   mapDataWithSuggestionEvent(row, event) {
      let keys = Object.keys(event);
      row.columns.forEach(c => {
         let key = keys.find(k => k === (c.name ? c.name.toLowerCase() : ''));
         if (key) {
            if (key === 'drugtype') {
               c.value = event[key].replace(event[key][0], event[key][0].toUpperCase());
            } else if (key === "othercolumns") {
               c.value = event['duration'] ? event['duration'] : event['othercolumns'] && event['othercolumns']['duration'] ? event['othercolumns']['duration'] : '';
            } else if ((key === "frequency")) {
               if (event[key].indexOf("0") !== -1 || event[key].indexOf("1") !== -1) {
                  c.value = event[key].replace(/\s/g, '');
               } else {
                  c.value = event[key] ? event[key].toUpperCase() : '';
               }
            } else if ((key === "duration")) {
               c.value = event[key] ? event[key].toUpperCase() : '';
            } else if (key === "comment") {
               c.value = event[key] ? event[key].toUpperCase() : '';
            } else {
               c.value = event[key];
            }
         }
      });
      return row;
   }

   onSavePrescriptionDataToS3 = (event) => {
      //console.log(event);
      if (this.state.sectionRowErrorArray.length > 0) {
         this.setState({ errorStatus: true, checkFormTrigger: true });
      } else {
         if (!this.checkIfEmptyPrescription()) {
            this.props.enqueueSnackbar('No data filled for this prescription.', { variant: 'error' })
            // assslert('No data filled for this prescription');
            return;
         } else {
            this.props.emitS3PrescriptionSaveEvent(this.state.dynamicFormData, this.state.patientData || null);
         }

      }
   }

   checkIfEmptyPrescription() {
      let { dynamicFormData } = this.state;
      let isFilled = false;
      for (let i in dynamicFormData.sections) {
         if (dynamicFormData.sections[i].status === "filled") {
            if (dynamicFormData.sections[i].formname !== "drugs") {
               isFilled = true;
            } else {
               let rows = dynamicFormData.sections[i].rows;
               let isDrugFilled = false;
               let type = '';
               for (let j in rows) {
                  if (rows[j].type === "row") {
                     type = "row";
                     let columns = rows[j].columns;
                     for (let k in columns) {
                        if (columns[k].type === "date") {
                           isDrugFilled = true;
                        }
                        if ((columns[k].name === "drug" || columns[k].name === "value" || columns[k].name === "name") && columns[k].value) {
                           isDrugFilled = true;
                        }
                     }
                  } else {
                     type = "group";
                  }
               }

               if (type === "row") {
                  if (!isDrugFilled) {
                     dynamicFormData.sections[i].status = "empty";
                     isFilled = false;
                     this.setState({ dynamicFormData })
                  } else {
                     isFilled = true;
                  }
               } else {
                  if (dynamicFormData.sections[i].status === "filled") {
                     isFilled = true;
                  }
               }
            }
         }
      }
      return isFilled;
   }

   getEmitedSelectedRxData = (event) => {
      //console.log()
      let { rxIndex, dynamicFormData, isLoadRx } = this.state
      let totalCount = dynamicFormData.sections[rxIndex].rows.length;
      let rowsData = [];
      for (let i = 0; i < event.rows.length; i++) {
         let indData = event.rows[i];
         totalCount = totalCount + 1;
         indData.index = totalCount;
         rowsData.push(indData)
      }
      // totalCount
      dynamicFormData.sections[rxIndex].rows = dynamicFormData.sections[rxIndex].rows.concat(rowsData);
      dynamicFormData.sections[rxIndex].status = "filled";
      isLoadRx = false;
      this.setState({ dynamicFormData, isLoadRx });
   }

   checkForSameFilename = (name) => {
      let { rxListData } = this.state;
      if (rxListData.length === 0) {
         return false;
      }

      for (let index in rxListData) {
         let fileName = rxListData[index].filename ? (rxListData[index].filename).toLowerCase() : '';
         if (fileName && fileName.indexOf(name.toLowerCase()) !== -1) {
            return true;
         }
      }
      return false;
      // filename
   }

   onUpdateRxDataEvent = () => {
      let data = {
         "sections": this.state.selectedRxSection
      }

      Storage.configure({
         level: 'private',
         AWSS3: S3UPLOAD_KEY
      });
      Storage.put(`env-${sessionStorage.getItem('apiStage')}/acc-${sessionStorage.getItem('s3AccountId')}/prescription/${Utils.getFormatedDate(new Date())}/form/${(new Date()).getTime()}`, JSON.stringify(data), {
         contentType: 'application/json'
      }).then(result => {
         //console.log(result);
         let title = this.state.alert.formName;
         this.setState({ isRxLoading: true }, () => this.saveRxData(result, title, 'update'))

      }
      ).catch(err => {
         console.log(err)
      });
   }

   getTemplateId = (name) => {
      let { rxListData } = this.state;
      for (let index in rxListData) {
         let fileName = rxListData[index].filename ? (rxListData[index].filename).toLowerCase() : '';
         if (fileName && fileName.indexOf(name.toLowerCase()) !== -1) {
            return rxListData[index].id;
         }
      }
   }

   checkIfDataExist() {
      //console.log(this.state.selectedRxSection);
      let rows = this.state.selectedRxSection.rows;
      let isValuePresent = false;
      for (let i in rows) {
         let col = rows[i].columns;
         for (let j in col) {
            let colData = col[j];
            if ((colData.name === "value" || colData.name === "name" || colData.name === "drug") && colData.value) {
               isValuePresent = true;
            }
         }
      }
      return isValuePresent;
   }

   getSaveRxData = (event) => {
      //console.log(event);
      if (this.state.selectedRxSection && this.state.selectedRxSection.status === "empty") {
         alert('no data');
         return;
      }

      if (!this.checkIfDataExist()) {
         alert('no data');
         return;
      }
      if (this.checkForSameFilename(event)) {
         let { alert } = this.state;
         let tempId = this.getTemplateId(event)
         alert = {
            show: true,
            type: 'confirmation',
            title: 'Duplicate Rx Template Name!',
            confirmBtnText: `Yes, update it!`,
            text: `There are some previously saved Rx template with the same name. Do you want to Update?`,
            formName: event,
            alertType: 'rx',
            tempId: tempId
         }
         this.setState({ alert })
         return;
      }

      let data = {
         "sections": this.state.selectedRxSection
      }

      Storage.configure({
         level: 'private',
         AWSS3: S3UPLOAD_KEY
      });
      Storage.put(`env-${sessionStorage.getItem('apiStage')}/acc-${sessionStorage.getItem('s3AccountId')}/prescription/${Utils.getFormatedDate(new Date())}/form/${(new Date()).getTime()}`, JSON.stringify(data), {
         contentType: 'application/json'
      }).then(result => {
         //console.log(result);
         this.setState({ isRxLoading: true }, () => this.saveRxData(result, event, 'save'))

      }
      ).catch(err => {
         console.log(err)
      });
   }

   async saveRxData(result, event, actionType) {
      try {
         let data = {}
         if (this.props.queryData) {
            data = {
               "file_type": "prescription",
               "file_source": "upload",
               "filename": event,
               "template_id": this.props.template_id,
               "patient_id": (this.props.queryData && this.props.queryData.patientId) ? this.props.queryData.patientId : ""
            }
            if (this.props.patientDetail) {
               let dob = Utils.getFormatedDate(this.props.patientDetail['date_of_birth'])
               data['patient'] = { ...this.props.patientDetail };
               data['patient']['date_of_birth'] = dob;
            }
         }

         data = {
            ...data,
            "formpath": `private/${sessionStorage.getItem('identityId')}/${result.key}`
         }

         let details = new UAParser().getResult();
         data = {
            ...data,
            ip_address: this.state.userIpAddress,
            browser_info: {
               browser: details.browser,
               OS: details.os,
               device: details.device,
               cpu: details.cpu,
               engine: details.engine
            }
         }
         let response;

         if (actionType === "update") {
            response = await prescriptionService.saveMiniTemplate(data, this.state.alert.tempId);
         } else {
            response = await prescriptionService.saveMiniTemplate(data, '');
         }


         //
         let { alert, isSaveRx, isRxLoading } = this.state;
         if (response.type === "success") {
            isSaveRx = false;
            isRxLoading = false;
            alert = {
               show: true,
               type: 'success',
               title: 'Success',
               text: 'Successfully Uploaded Rx Group Data'
            }
            this.setState({ alert, isSaveRx, isRxLoading }, () => {
               this.getRxListData();
            });
         } else {
            isRxLoading = false;
            alert = {
               show: true,
               type: 'error',
               title: 'Error',
               text: `Error While Uploaded Rx Group Data`
            }
            this.setState({ alert, isRxLoading });
         }
      } catch (error) {
         let { alert, isSaveRx, isRxLoading } = this.state;
         isRxLoading = false;
         alert = {
            show: true,
            type: 'error',
            title: 'Error',
            text: `Error While Uploaded Rx Group Data`
         }
         this.setState({ alert, isRxLoading });
      }
   }

   setErrorStatus(rowIndex, sectionIndex, errorStatus) {
      let arr = this.state.sectionRowErrorArray;
      let isFound = false;
      arr.forEach((o, idx) => {
         if (o.sectionIndex === sectionIndex && o.rowIndex === rowIndex) {
            if (!errorStatus) {
               arr.splice(idx, 1);
               let s = { sectionRowErrorArray: arr };
               if (this.state.checkFormTrigger === true && arr.length === 0) {
                  s = { ...s, errorStatus: false }
               }
               this.setState(s);
            }
            isFound = true;
         }
      });
      if (!isFound && errorStatus) {
         arr.push({ sectionIndex, rowIndex });
         let s = { sectionRowErrorArray: arr };
         if (this.state.checkFormTrigger === true && arr.length === 1) {
            s = { ...s, errorStatus: true }
         }
         this.setState(s);
      }
   }

   onQcDataChange = (event) => {
      this.props.emitPrescriptionStatus(event);
   }

   onPrescriptionStatusUpdateEvent = (event) => {
      this.props.emitQcStatusSaveEvent(true);
   }

   setConditionalHide = (sectionIndex, target) => {
      let { conditionalHideConfig } = this.state;
      let config = {
         ...target,
         sectionIndex
      };
      let idx = conditionalHideConfig.findIndex(c => c.sectionIndex === sectionIndex && c.rowIndex === target.rowIndex && (c.childIndex === target.childIndex || (!c.childIndex && !target.childIndex)));
      if (idx === -1) {
         conditionalHideConfig.push(config);
      } else {
         conditionalHideConfig[idx] = config;
      }
      //console.log(conditionalHideConfig);
      this.setState({ conditionalHideConfig });
   }

   unsetConditionalHide = (sectionIndex, target) => {
      let { conditionalHideConfig } = this.state;
      let idx = conditionalHideConfig.findIndex(c => c.sectionIndex === sectionIndex && c.rowIndex === target.rowIndex && (c.childIndex === target.childIndex || (!c.childIndex && !target.childIndex)));
      if (idx !== -1) conditionalHideConfig.splice(idx, 1);
      this.setState({ conditionalHideConfig });
   }

   renderConditionalClass(colId, values, indexes) {
      let { conditionalHideConfig, dynamicFormData } = this.state;
      try {
         conditionalHideConfig.forEach(config => {
            let { sectionIndex, rowIndex, childIndex, displaycondition } = config;
          
            if (displaycondition && displaycondition[0].source === colId) {
               if (childIndex) {
                  if (indexes.sectionIndex !== sectionIndex || indexes.rowIndex !== rowIndex) return
               } else {
                  if (indexes.sectionIndex !== sectionIndex) return
               }
               let display = displaycondition[0].value.split(',').every(v => (values.indexOf(v) !== -1));
               if (config.childIndex !== undefined) {
                  let child = dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex];
                  if (!child) {
                     return;
                  }
                  child['class'] = display ? '' : config.class ? config.class : "deferRendering hide";
                  dynamicFormData.sections[sectionIndex].rows[rowIndex].children[childIndex] = child;
                  this.updateChildFieldConfigData(rowIndex, sectionIndex, childIndex);
               } else {
                  let row = dynamicFormData.sections[sectionIndex].rows[rowIndex];
                  if (row) {
                     row['class'] = display ? '' : config.class ? config.class : "deferRendering hide";
                     // to make row value empty when field get hidden by formula 
                     if(row['class']=='deferRendering hide'){
                        row['status']='empty'
                        let cols=row['columns']
                        for(let col in cols){
                           row['columns'][col]['value']=''
                           row['columns'][col]['status']='empty'
                        }

                     }
                     dynamicFormData.sections[sectionIndex].rows[rowIndex] = row;
                     this.updateFieldConfigData(rowIndex, sectionIndex);
                  }
               }
            }
         });
         this.setState({ dynamicFormData });
         
      } catch (err) {
         console.log(err);
         this.logErrorToService(err);
      }
   }

   getVerifiedHeader(indSection) {
      return indSection.header.split(",")
   }

   checkIfRoleIsDataAdminType() {
      if (sessionStorage.getItem('userRole').indexOf("dataadmin") !== -1 || sessionStorage.getItem('userRole').indexOf("dataqc") !== -1 || sessionStorage.getItem('userRole').indexOf("dataops") !== -1) {
         return true;
      }
      return false;
   }

   togglePatientDetilSection() {
      let { showPatient } = this.state;
      showPatient = !showPatient;
      this.setState({ showPatient });
   }

   getEmitedPatientData = (event) => {
      let { patientData } = this.state;
      patientData = event;
      this.setState({ patientData });
   }

   getEmitedSaveModalCloseEvent = () => {
      let { isSaveRx } = this.state;
      isSaveRx = false;
      this.setState({ isSaveRx })
   }

   getEmitedLoadModalCloseEvent = (event) => {
      let { isLoadRx } = this.state;
      isLoadRx = false;
      this.setState({ isLoadRx })
   }

   async getRxListData() {
      try {
         const response = await prescriptionService.getMiniTemplate();
         //
         let { rxListData } = this.state;
         if (response.type === "success") {
            rxListData = response.data && response.data.rows && response.data.rows.length > 0 ? response.data.rows : []
            this.setState({ rxListData })
         } else {
            rxListData = []
            this.setState({ rxListData })
         }
      } catch (error) {
         console.log(error);
         let { rxListData } = this.state;
         rxListData = [];
         this.setState({ rxListData })
      }
   }

   getEmitedSaveEvent = (event) => {
      let { isSaveRx, selectedRxSection } = this.state;
      isSaveRx = true;
      selectedRxSection = event;
      this.setState({ isSaveRx, selectedRxSection })
   }

   getEmitedLoadEvent = (index, event) => {
      let { isLoadRx, selectedRxSection, rxIndex } = this.state;
      selectedRxSection = event;
      isLoadRx = true;
      rxIndex = index;
      this.setState({ isLoadRx, selectedRxSection, rxIndex })
   }
   checkListVisibility = () => {
      if (!sessionStorage.getItem('dataUser')) {
         return true;
      }
      return false;
   }

   checkListVisibility = () => {
      if (this.props.renderFrom === "case_history") {
         return false
      }
      if (sessionStorage.getItem('dataUser')) {
         return false;
      }

      return true;
   }

   render() {
      const { patientDetail, patient, renderFrom, isEditable } = this.props;
      if (!this.state.dynamicFormData) {
         return '';
      }
      if (this.state.dynamicFormData) {
         this.props.emitPartialPrescFormData(this.state.dynamicFormData)
      }
      return (
         <>

            <div className="card profile-card">
               <div className={`card-body ${renderFrom ? '' : 'p-0'}`}>
                  {this.state.alert.show &&
                     <SweetAlertComponent show={this.state.alert.show} confirmBtnText={this.state.alert.confirmBtnText} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.sweetAlertClose.bind(this)} onCancel={this.sweetAlertCancelEvent}></SweetAlertComponent>
                  }
                  <div className="row">
                     {this.checkListVisibility() &&
                        <>
                           {/* {JSON.stringify(this.props)} */}
                           {!isEditable &&
                              <div className={`col-2`} style={{ padding: 0 }}>
                                 {/* <div > */}
                                 <h5 class="m-0">Section List</h5>
                                 <ul class="list-group" style={{
                                    overflowY: "auto",
                                    maxHeight: "45vh"
                                 }}>
                                    {this.state.dynamicFormData.sections &&
                                       this.state.dynamicFormData.sections.map((indList) =>
                                          <Link to={indList.title} offset={-150} spy={true} smooth={true}>
                                             <li class="list-group-item" style={{
                                                padding: 10,
                                                fontSize: 13,
                                                textTransform: "capitalize",
                                                cursor: 'pointer'
                                             }}>{indList.title}</li>
                                          </Link>
                                       )}
                                 </ul>
                                 {/* </div> */}
                              </div>
                           }
                        </>
                     }
                     <div className={`col-md-${this.props.mode === "edit" ? this.checkListVisibility() ? '8' : '10' : this.checkListVisibility() ? '10' : '12'} ${renderFrom ? '' : 'entry-section p-0'}`} style={{ height: (this.props.mode === "edit") ? "90vh" : '', overflowY: 'auto' }}>
                        <div className="accordion visible-overflow accordion-solid-content accordion-solid-white" id="accordion-5"
                           role="tablist">
                           <div className="card" style={{ display: this.checkIfRoleIsDataAdminType() ? '' : 'none' }}>
                              <div className="card-header" style={{ cursor: 'pointer' }} onClick={this.togglePatientDetilSection.bind(this)}>
                                 <h6 className="mb-0 mt-0">
                                    <a data-toggle="collapse" aria-expanded={`${this.state.showPatient ? 'false' : 'true'}`}
                                       aria-controls="collapse-13" className="p-2" style={{ fontSize: 14 }}>Patient Info </a>
                                 </h6>
                              </div>
                              {patientDetail &&
                                 <div  className={`collapse card-body ${this.state.showPatient ? 'show' : ''}`}>
                                    <PatientInfoComponent
                                       patient={patient}
                                       patientDetail={patientDetail}
                                       emitPatientData={this.getEmitedPatientData.bind(this)}></PatientInfoComponent>
                                 </div>
                              }
                           </div>
                           {this.state.dynamicFormData.sections.map((indSection, index) =>
                              <div id={indSection.title} className={!indSection.hideShowOff ? "card" : ""} key={index}>
                                 
                                 <ColumnTitleComponent hideShowOff={indSection.hideShowOff} indSection={indSection} emitHeaderCollapseEvent={this.getEmitedHeaderCollapseEvent.bind(this)}></ColumnTitleComponent>
                                 <div id="collapse-13" className={`collapse ${indSection.collapse ? '' : 'show'}`} role="tabpanel"
                                    aria-labelledby="heading-13">
                                    {indSection.showType === 'freeText' && <TextEditor html={indSection.html} onEditTextChange={this.changeFreeText.bind(this, index)} />}
                                    {indSection.showType === 'compactTable' ?
                                       <>
                                          {indSection.splitTable ? (
                                             <>
                                                <div className="row">
                                                   {indSection.rows.map((indRowData, jndex) =>
                                                      <div className={`col-${indRowData.subSectionSize} mb-2`} key={jndex}>
                                                         <div id="table" className="table-editable ">
                                                            <table className="table table-bordered table-responsive-md table-hover text-center table-sm table-striped">
                                                               <thead className="thead-light" style={{ textTransform: 'capitalize' }}>
                                                                  <tr>
                                                                     {indRowData.header && this.getVerifiedHeader(indRowData).map(headerLabel => <th className="text-center">{headerLabel}</th>)}
                                                                     <th className="text-center">Actions</th>
                                                                  </tr>
                                                               </thead>
                                                               <tbody>
                                                                  <CompactTableRowComponent
                                                                     uiDataDic={indSection.uiDataDic}
                                                                     collapse={indSection.collapse}
                                                                     indRowData={indRowData}
                                                                     sectionIndex={index}
                                                                     isView={this.state.isView}
                                                                     rowIndex={jndex}
                                                                     setConditionalHide={this.setConditionalHide.bind(this, index)}
                                                                     unsetConditionalHide={this.unsetConditionalHide.bind(this, index)}
                                                                     setConfigForFormula={this.setColumnsWithColIdToEmitOnUpdate.bind(this, index)}
                                                                     emitChildTextboxValue={this.getEmitedChildTextboxValue.bind(this, jndex, index)}
                                                                     emitTypeAheadSuggestionObj={this.getEmitedSuggestionData.bind(this)}
                                                                     emitTextboxValue={this.getEmitedTextboxValue.bind(this, jndex, index)}
                                                                     emitChildAddNewRowEvent={this.getEmitedChildAddNewRowEvent.bind(this, jndex, index)}
                                                                     emitChildDeleteRowObjectData={this.getEmitedChildDeleteRowObjectData.bind(this, jndex, index)}
                                                                     emitAddNewRowEvent={this.getEmitedAddNewRowEvent.bind(this, jndex, index)}
                                                                     emitDeleteRowObjectData={this.getEmitedDeleteRowObjectData.bind(this, jndex, index)}

                                                                     fieldConfig={this.state.fieldConfig}
                                                                     checkForm={this.state.checkFormTrigger}
                                                                     emitErrorStatus={this.setErrorStatus.bind(this, jndex, index)}>
                                                                  </CompactTableRowComponent>
                                                               </tbody>
                                                            </table>
                                                         </div>
                                                      </div>
                                                   )}
                                                </div>
                                             </>
                                          ) : (
                                             <div id="table" className="table-editable mb-2">
                                                <table className="table table-bordered table-responsive-md table-hover text-center table-sm table-striped">
                                                   <thead className="thead-light" style={{ textTransform: 'capitalize' }}>
                                                      <tr>
                                                         {indSection.header && this.getVerifiedHeader(indSection).map(headerLabel => <th className="text-center">{headerLabel}</th>)}
                                                         <th className="text-center">Actions</th>
                                                      </tr>
                                                   </thead>
                                                   <tbody>
                                                      {indSection.rows.map((indRowData, jndex) =>
                                                         <>
                                                            <tr>
                                                               <React.Fragment key={jndex}>
                                                                  <CompactTableRowComponent
                                                                     uiDataDic={indSection.uiDataDic}
                                                                     collapse={indSection.collapse}
                                                                     indRowData={indRowData}
                                                                     sectionIndex={index}
                                                                     isView={this.state.isView}
                                                                     rowIndex={jndex}
                                                                     setConditionalHide={this.setConditionalHide.bind(this, index)}
                                                                     unsetConditionalHide={this.unsetConditionalHide.bind(this, index)}
                                                                     setConfigForFormula={this.setColumnsWithColIdToEmitOnUpdate.bind(this, index)}
                                                                     emitChildTextboxValue={this.getEmitedChildTextboxValue.bind(this, jndex, index)}
                                                                     emitTextboxValue={this.getEmitedTextboxValue.bind(this, jndex, index)}
                                                                     emitChildAddNewRowEvent={this.getEmitedChildAddNewRowEvent.bind(this, jndex, index)}
                                                                     emitChildDeleteRowObjectData={this.getEmitedChildDeleteRowObjectData.bind(this, jndex, index)}
                                                                     emitAddNewRowEvent={this.getEmitedAddNewRowEvent.bind(this, jndex, index)}
                                                                     emitDeleteRowObjectData={this.getEmitedDeleteRowObjectData.bind(this, jndex, index)}
                                                                     fieldConfig={this.state.fieldConfig}
                                                                     checkForm={this.state.checkFormTrigger}
                                                                     emitErrorStatus={this.setErrorStatus.bind(this, jndex, index)}>
                                                                  </CompactTableRowComponent>
                                                               </React.Fragment>
                                                               <td style={{ padding: '0.1rem', width: '10%' }}>
                                                                  {indSection.rows.length > 1 && <span className="table-remove">
                                                                     <span className="link-icon mdi mdi-delete-forever  text-dark table-remove" onClick={this.getEmitedDeleteRowObjectData.bind(this, jndex, index)}> </span>
                                                                  </span>}
                                                                  <span className="table-remove">
                                                                     <span className="link-icon mdi mdi-plus text-dark table-remove" onClick={this.getEmitedAddNewTableRowEvent.bind(this, jndex, index)}> </span>
                                                                  </span>
                                                               </td>
                                                            </tr>
                                                         </>
                                                      )}
                                                   </tbody>
                                                </table>
                                             </div>
                                          )}
                                       </>
                                       :
                                       <div className={"card-body row"} style={{ padding: '.5rem 1rem', width: '100%' }}>
                                          <RowParent isEditable={isEditable}>
                                             {indSection.showType === 'xyTable' && indSection.rows && indSection.rows.length > 0 && <XyTableColumnHeader section={indSection} />}
                                             {indSection.rows.map((indRowData, jndex) =>
                                                <RowSubParent key={jndex} idx={jndex} isEditable={isEditable} onDragOver={() => this.props.onDragOver ? this.props.onDragOver(jndex) : {}} onDragStart={(e) => this.props.onDragStart ? this.props.onDragStart(e, jndex) : {}} onDragEnd={(e) => this.props.onDragEnd ? this.props.onDragEnd(e) : {}}>
                                                   <RowViewComponent
                                                      title={indSection.title}
                                                      rowProps={this.props.rowProps}
                                                      isEditable={isEditable}
                                                      ordered={indSection.ordered}
                                                      hideShowOff={indSection.hideShowOff}
                                                      uiDataDic={indSection.uiDataDic}
                                                      collapse={indSection.collapse}
                                                      emitTypeAheadSuggestionObj={this.getEmitedSuggestionData.bind(this)}
                                                      indRowData={indRowData}
                                                      sectionIndex={index}
                                                      isView={this.state.isView}
                                                      rowIndex={jndex}
                                                      showType={indSection.showType}
                                                      setConditionalHide={this.setConditionalHide.bind(this, index)}
                                                      unsetConditionalHide={this.unsetConditionalHide.bind(this, index)}
                                                      setConfigForFormula={this.setColumnsWithColIdToEmitOnUpdate.bind(this, index)}
                                                      emitChildTextboxValue={this.getEmitedChildTextboxValue.bind(this, jndex, index)}
                                                      emitFocusEvent={this.getEmitedFocusEvent.bind(this, jndex, index)}
                                                      emitTextboxValue={this.getEmitedTextboxValue.bind(this, jndex, index)}
                                                      emitChildAddNewRowEvent={this.getEmitedChildAddNewRowEvent.bind(this, jndex, index)}
                                                      emitChildDeleteRowObjectData={this.getEmitedChildDeleteRowObjectData.bind(this, jndex, index)}
                                                      emitAddNewRowEvent={this.getEmitedAddNewRowEvent.bind(this, jndex, index)}
                                                      emitCloneRowEvent={this.getEmitedCloneRowEvent.bind(this, jndex, index)}
                                                      emitDeleteRowObjectData={this.getEmitedDeleteRowObjectData.bind(this, jndex, index)}

                                                      fieldConfig={this.state.fieldConfig}
                                                      checkForm={this.state.checkFormTrigger}
                                                      template_id={this.props.template_id}
                                                      emitErrorStatus={this.setErrorStatus.bind(this, jndex, index)}
                                                      emitChildLoadOptionsEvent={this.getEmitedChildLoadOptionsEvent.bind(this, jndex, index)}
                                                      emitLoadOptionsEvent={this.getEmitedLoadOptionsEvent.bind(this, jndex, index)}>
                                                   </RowViewComponent>
                                                </RowSubParent>
                                             )}
                                          </RowParent>
                                       </div>
                                    }
                                 </div>
                                 <SectionActionComponent
                                    indSection={indSection}
                                    emitLoadEvent={this.getEmitedLoadEvent.bind(this, index)}
                                    emitSaveEvent={this.getEmitedSaveEvent.bind(this)}>
                                 </SectionActionComponent>
                              </div>
                           )}
                        </div>
                     </div>
                     <div className="col-md-12 pt-2">
                        <button type="button" disabled={this.state.errorStatus} className="btn btn-success mr-2 btn-rounded" onClick={this.onSavePrescriptionDataToS3.bind(this)} style={{ display: this.props.isView ? 'none' : '' }}>Submit </button>{this.props.saveInProgress && <div style={{ display: 'inline-block', 'marginLeft': '30px' }}><Spinner width={15} height={15} color="#ffffff"></Spinner></div>}
                        {this.state.errorStatus && <small className="text-danger error-text" >Check form for errors!</small>}
                     </div>
                  </div>
               </div>
               {this.props.mode && (this.props.mode === "create" || this.props.mode === "edit") &&
                  <SuggestionNewViewComponent
                     mode={this.props.mode}
                     hideSugg={this.props.hideSugg}
                     template_id={this.props.template_id}
                     suggestionIndexDetails={this.state.suggestionIndexDetails}
                     isSuggestionActive={this.state.isSuggestionActive}
                     emitSuggestionCloseEvent={this.onSuggestionCloseEvent.bind(this)}
                     suggestionList={this.state.suggestionList}
                     emitTemplateWiseSuggestionData={this.getTemplateWiseSuggestionData.bind(this)}
                     emitSuggestionData={this.getEmitedSuggestionData.bind(this)}></SuggestionNewViewComponent>
               }
            </div>
            {
               this.state.isSaveRx &&
               <CommonModalView open={this.state.isSaveRx} emitModalCloseEvent={this.getEmitedSaveModalCloseEvent.bind(this)} title={'Create Rx-Group'}>

                  <SaveRxComponent isRxLoading={this.state.isRxLoading} emitSaveRxData={this.getSaveRxData.bind(this)}></SaveRxComponent>
               </CommonModalView>
            }
            {
               this.state.isLoadRx &&
               <CommonModalView open={this.state.isLoadRx} emitModalCloseEvent={this.getEmitedLoadModalCloseEvent.bind(this)} title={'Load Rx-Group'}>
                  <LoadRxComponent
                     rxListData={this.state.rxListData}
                     emitSelectedRxData={this.getEmitedSelectedRxData.bind(this)}>
                  </LoadRxComponent>
               </CommonModalView>
            }
         </>
      );

   }
}

export default withSnackbar(SectionViewComponent);

export const RowParent = ({ isEditable, children }) => {
   return isEditable ? <ul className="w-100">{children}</ul>
      : <>{children}</>;
}
export const RowSubParent = ({ isEditable, children, idx, onDragOver, onDragStart, onDragEnd }) => {
   return isEditable ? <li key={idx} onDragOver={() => onDragOver()}>
      <div className="mb-3 ml-3" draggable onDragStart={e => onDragStart(e)} onDragEnd={onDragEnd.bind(this)}>{children}</div></li>
      :
      <>{children}</>;
}
export const XyTableColumnHeader = ({ section }) => {
   const row = section.rows[0]
   const columnHeaders = section.colHeaders && section.colHeaders.split(',') || [];
   return <>
      <div className={`col-lg-${row.spaceNeeded ? row.spaceNeeded : '12'}`}>
         <div className={!section.hideShowOff ? `row align-items-center prescription-list  radius-0 mb-2 pb-1 pt-0` : 'row no-gutters'}>
            {section.hideShowOff &&
               <div className="col p-1 pt-3" style={{ visibility: 'hidden' }}>{section.title} </div>
            }
            <div className="col p-1 pt-3" style={{ visibility: 'hidden' }}><strong>{row.label_x}</strong> </div>
            {row.columns && row.columns.map((indColumnData, index) => <div className={`col`} style={{ textTransform: 'capitalize' }}>
               {/* ${indColumnData.size ? "-" + indColumnData.size : ''} */}
               <strong>{columnHeaders[index] || '-'}</strong>
            </div>)}
            {/* <div className="col-1" style={{ padding: 0, display: (row.ismultiple) ? '' : 'none' }}>
               <div className="btn-group plus-group" role="group"
                  aria-label="Basic example">
                  {(row.index !== 0) && <button type="button" className="btn btn-outline-danger btn-rounded" onClick={this.getEmitedDeleteRowObjectData.bind(this, this.props.rowIndex)}><i
                     className="mdi mdi-minus " ></i></button>}
                  <button type="button" className="btn btn-outline-success btn-rounded" onClick={this.getEmitedAddNewRowEvent.bind(this, this.props.rowIndex)}><i
                     className="mdi mdi-plus "></i></button>
               </div>
            </div> */}
         </div>
      </div>
   </>;
}