import React from 'react';
import View from '../ChatPanel/ViewHelper';
import service from '../../Services/onlineConsultation.service';
import Utils from '../../Shared/utils/utils';
import Spinner from '../../Common/Spinner/Spinner';

const MAX_PATIENT_MSGS = 5;
const MAX_DOCTOR_MSGS = 10;
class PatientTextChat extends React.Component {
    constructor(props) {
        super(props)
        let role = sessionStorage.getItem('userRole');
        this.state = {
            newMsg: '',
            showError: '',
            replySending: false,
            chatLoadingFirstTime: false,
            // lastDocNfnId: '',
            // restrictMsgSending: false //restrict if in 1st obj of originally received chat history, doctor_messages are null or patient is in post_consultation
        }
    }

    setNewMessage = e => {
        this.setState({ newMsg: e.target.value });
    }

    sendNewMsg = () => {
        this.props.sendNewMsg(this.state.newMsg);
        this.setState({ newMsg: '' });
    }

    checkRemainingMsgs = () => {
        let { chatHistory, isDoctor } = this.props;
        let remMsgs = isDoctor && MAX_DOCTOR_MSGS || MAX_PATIENT_MSGS;
        if (!chatHistory) return remMsgs;
        let l = chatHistory.length;
        while (l-- && remMsgs) {
            if (chatHistory[l].isSent) {
                remMsgs -= 1
            } else {
                return remMsgs;
            }
        }
        return remMsgs
    }

    render() {
        let { replySending, newMsg, showError } = this.state;
        let { isDoctor, chatHistory, chatLoading, prevChat, labels } = this.props;
        let remMsgs = this.checkRemainingMsgs();
        let invalidMsgLength = newMsg.length === 0;
        // newMsg.length < 20 || newMsg.length > 1000;
        let completeChat = prevChat ? prevChat.concat(chatHistory) : chatHistory;
        return <View.OuterLayout onHide={this.props.onHide.bind(this)} title={`${isDoctor && 'Chat with patient' || labels.chatWithEdu}`}>
            <View.ChatHistory>
                {chatLoading && <div><Spinner width={50} height={50} /></div>}
                {completeChat && completeChat.map(query => <>
                    {query.isSent &&
                        <View.OutgoingMsg>
                            <p>{query.msg}</p>
                        </View.OutgoingMsg>
                    }
                    {!query.isSent && <View.IncomingMsg>
                        <div className="incoming_msg_img">
                            <img src={`/images/faces-clipart/${isDoctor ? 'pic-4' : 'doctor-male'}.png`} alt="" className="img-fluid" />
                        </div>
                        <div className="received_msg">
                            <div className="received_withd_msg">
                                <p>{query.msg}</p>
                                {/* <ChatAttachment/> */}
                                {/* <span className="time_date"> {new Date(query.created).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}    |    {Utils.getFormatedDate(new Date(query.created))}</span> */}
                            </div>
                        </div>
                    </View.IncomingMsg>}
                </>)}
                {/* {showError && <small className="text-danger float-right">{showError}</small>} */}
            </View.ChatHistory>
            {remMsgs < 1 && <View.HelperAlert msg={isDoctor && `Your max message limit is over. Please wait until Patient replies.` || labels.maxLimitOver} />}
            {remMsgs > 0 && <View.MsgType>
                <textarea rows="3" cols="50" className="custom-control" value={newMsg} style={{ paddingRight: '50px' }} placeholder={labels.typeMsg} onChange={this.setNewMessage.bind(this)}> </textarea>
                {!invalidMsgLength ? <button className="msg_send_btn shadow-sm" type="button" onClick={this.sendNewMsg.bind(this)}>
                    {replySending ? <Spinner width={15} height={15} /> : <span className="mdi mdi-send" />}
                </button>
                    :
                    <button className="msg_send_btn shadow-sm" style={{ backgroundColor: 'grey', cursor: 'no-drop' }} type="button" disabled>
                        <span className="mdi mdi-send" />
                    </button>}
            </View.MsgType>}
            {remMsgs > 0 && <small className="error-text text-danger">{`${labels.msgRemain}${remMsgs}/${isDoctor && MAX_DOCTOR_MSGS || MAX_PATIENT_MSGS}`}</small>}
            {/* {(newMsg && invalidMsgLength) && <small className="error-text text-danger">Message length should be between 20 and 1000 characters</small>} */}
        </View.OuterLayout>
    }
}

export default PatientTextChat;