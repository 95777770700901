
import React, { Suspense, lazy } from "react";
import { Route, Redirect, Switch,withRouter } from "react-router-dom";

import Main from './Main/Main';
import Spinner from "../Common/Spinner/Spinner";
import Utils from "../Shared/utils/utils";
import HospitalConsultationCheckout from "../Views/vaccination/HospitalConsultationCheckout";
import onlineConsultationService from '../Services/onlineConsultation.service';
// component import 
const Homepage = lazy(() => import('../Views/Login/Homepage'));
const OnlineConsultationLanding = lazy(() => import('../Views/Login/OnlineConsultationLanding'));
const OnlineConsultPatientInfoContainer = lazy(() => import('../Views/OnlineConsultation/OnlineConsultPatientInfoContainer'));
const ConsultDoctorInfoContainer = lazy(() => import('../Views/OnlineConsultation/ConsultDoctorInfoContainer'));

const ArticleBuilderContainer = lazy(() => import('../Views/ArticleBuilder/ArticleBuilderContainer'))
const BooktestOnline = lazy(() => import('../Views/BookTest/BooktestOnline'));
const BookTestListing = lazy(() => import('../Views/BookTest/BookTestListing'));
const LoginContainer = lazy(() => import('../Views/Login/LoginContainer'));
const BookingContainer = lazy(() => import('../Views/Booking/BookingContainer'));
const ForgotPasswordComponent = lazy(() => import('../Views/ForgotPassword/ForgotPasswordComponent'));
const PageNotFoundComponent = lazy(() => import('../Common/PageNotFound/PageNotFoundComponent'));
const TermsAndConditionComponent = lazy(() => import('../Common/TermsAndCondition/TermsAndConditionComponent'));
const FaqViewComponent = lazy(() => import('../Common/Faq/FaqViewComponent'));
const AboutUsComponent = lazy(() => import('../Common/AboutUs/AboutUsComponent'))
const AboutUsViewComponent = lazy(() => import('../Common/AboutUs/AboutUsViewComponent'))
const FaqsComponent = lazy(() => import('../Common/Faq/FaqsComponent'))
const TermsConditionComponent = lazy(() => import('../Common/TermsAndCondition/TermsConditionComponent'));
const SurveyTermsConditionComponent = lazy(() => import('../Common/TermsAndCondition/SurveyTermsConditionComponent'));
const SurveyAbountUsComponent = lazy(() => import('../Common/TermsAndCondition/SurveyAbountUsComponent'));
const SurveyFaqComponent = lazy(() => import('../Common/TermsAndCondition/SurveyFaqComponent'));
const PatientPrescriptionPageComponent = lazy(() => import('../Common/DirectLinkPages/PatientPrescriptionPageComponent'));
const PostPaymentInvoicePage = lazy(() => import('../Common/DirectLinkPages/PostPaymentInvoicePage'));
const OnlineDoctorListContainer = lazy(() => import('../Views/OnlineDoctor/OnlineDoctorListContainer'));
const BookingConfirmatiomComponent = lazy(() => import('../Views/BookingConfirmation/BookingConfirmatiomComponent'));
const InventoryManagementContainer = lazy(() => import('../Views/Inventory/InventoryManagementContainer'));
const ChatRoomContainer = lazy(() => import('../Views/ChatRoom/ChatRoomContainer'));
const OtpVerificationContainer = lazy(() => import('../Views/OTPVerfication/OtpVerificationContainer'));
const NewChatRoomContainer = lazy(() => import('../Views/NewChat/NewChatRoomContainer'));
const WaitingAreaContainer = lazy(() => import('../Views/WaitingArea/WaitingAreaContainer'))
const AndroidAreaContainer = lazy(() => import('../Views/AppArea/AndroidAreaContainer'))

const VideoDetailingWaitContainer = lazy(() => import('../Views/DetailingWait/VideoDetailingWaitContainer'))

const VideoDetailingCallContainer = lazy(() => import('../Views/DetailingCall/VideoDetailingCallContainer'))
const UnorderedInvoiceListContainer = lazy(() => import('../Views/Invoice/UnorderedInvoiceListContainer'));

const VaccinationLoginContainer = lazy(() => import("../Views/vaccination/vaccinationLoginContainer"));

const PatientOPDList = lazy(() => import("../Views/vaccination/OPD/PatientOPDList"))

const vaccinationRegisterationContainer = lazy(() => import("../Views/vaccination/vaccinationRegisterationContainer"))
const vaccinationCheckout = lazy(() => import("../Views/vaccination/vaccinationCheckout"));

const PaymentSuccess = lazy(() => import("../Views/vaccination/PaymentSuccess/PaymentSuccess"));
const PayFailure = lazy(() => import("../Views/Payment/PayFailure"));
const PaySuccess = lazy(() => import("../Views/Payment/PaySuccess"));
const VaccineCenterContainer = lazy(() => import("../Views/VaccineCenter/VaccineCenterContainer"));
const NoPatientFound = lazy(() => import("../Views/vaccination/NoPatientFound"));
const CovidFaq = lazy(() => import("../Views/vaccination/CovidFaq/CovidFaq"));

//   FaqsComponent  TermsConditionComponent
/**
 * Custom Route for protected resources
 */
const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (sessionStorage.getItem('emrToken') ? (
        <Component {...props} {...rest} />
    ) : (
        <Redirect to={{
            pathname: `/doctor-login`,
            state: { referrer: props.location }
        }} />
    )
    )}
    />
);

const MoolchandProtectedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (sessionStorage.getItem('emrToken') ? (
        <Component {...props} {...rest} />
    ) : (
        <Redirect to={{
            pathname: `/moolchandhospital/opd`,
            state: { referrer: props.location }
        }} />
    )
    )}
    />
);


export  class RootRouters extends React.Component {

    constructor(props) {
        super(props);
this.state={
    videoRoom: false,
}
    }

    componentDidMount() {
        this.getDefaultUrl();
        
        this.getAccountInfoRaw();

    }
    async getAccountInfoRaw() {
        try {
            let account_id = sessionStorage.getItem('s3AccountId');
            let user_id = sessionStorage.getItem('userId');
            let res = await onlineConsultationService.getAccountInfo(account_id, 'video', user_id);
            
            if (res && res.data) {
                sessionStorage.setItem('accountInfo', JSON.stringify(res.data));
                return res.data;
            }
        } catch (err) {
            console.log(err);
        }
    }

    getDefaultUrl() {
        if (window.location.href.split('/').pop()) {
            return;
        }
        if (window.location.href.indexOf('localhost') > -1) {
            if (sessionStorage.getItem('emrToken')) {
                if (sessionStorage.getItem("active_view") && sessionStorage.getItem("active_view") != "") {
                    let nextRoute = Utils.getAdminAndDataUserRoute()
                    window.location.href = nextRoute;
                } else if (sessionStorage.getItem('isSuperUser')) {
                    window.location.href = "http://localhost:3000" + `/account-list`;
                } else if (sessionStorage.getItem('isSupport')) {
                    window.location.href = "http://localhost:3000" + `/call-logs`;
                } else {
                    if (sessionStorage.getItem('showUser') === "") {
                        window.location.href = "http://localhost:3000" + `/appointments-view`;
                    } else {
                        window.location.href = "http://localhost:3000" + `/user-view`;
                    }
                }
            } else {
                window.location.href = "http://localhost:3000/doctor-login"
            }

        } else {
            if (sessionStorage.getItem('emrToken')) {
                if (sessionStorage.getItem("active_view") && sessionStorage.getItem("active_view") != "") {
                    let nextRoute = Utils.getAdminAndDataUserRoute()
                    window.location.href = nextRoute;
                } else if (sessionStorage.getItem('isSuperUser')) {
                    window.location.href = "https://" + window.location.hostname + "/account-list";
                } else if (sessionStorage.getItem('isSupport')) {
                    window.location.href = "http://localhost:3000" + `/call-logs`;
                } else {
                    if (sessionStorage.getItem('showUser') === "") {
                        window.location.href = "https://" + window.location.hostname + "/appointments-view";
                    } else {
                        window.location.href = "https://" + window.location.hostname + "/user-view";
                    }
                }
            } else {
                window.location.href = "https://" + window.location.hostname + "/doctor-login";
            }
        }
    }

    onCloseVideoRoom = () => { // contains locationState, room_id, providerName
        this.setState({ videoRoom: false })
    }
    onRedirectFromVideoRoom = (path, locationstate) => { // contains locationState, room_id, providerName
        this.onCloseVideoRoom()
        this.props.history.push(path, locationstate);
        if (path.includes('appointments')) {
            window.location.reload(); // to show reconnect-call pop up
        }
    }
    onPipModeToggle = (pipModeOn) => { // contains locationState, room_id, providerName
        window.location.hash = pipModeOn ? '' : this.state.videoRoomHash;
        if (pipModeOn && !window.location.pathname.includes('prescription')) {
            this.props.history.push(`/prescription-detail?patientId=${this.state.apptData['patient_id']}&appointmentId=${this.state.apptData['id']}&type=appointment`, this.state.apptData)
        }
        this.setState({ pipMode: pipModeOn })
    }
    onPipModeClose = () => {
        if (document.pictureInPictureElement) {
            document.exitPictureInPicture()
                .catch(error => {
                    // Video failed to leave Picture-in-Picture mode.
                });
        }
    }
    
    
    render() {
        return (
            <Suspense fallback={<Spinner></Spinner>}>
                <Switch>
                    <Route path={`/doctor-login`} component={LoginContainer} />
                    {/* <Route path={`/online-appointment`} component={OnlineConsultationLanding} /> */}
                    {/* <Route path='/:providerName/online-appointment' component={OnlineConsultationLanding} /> */}
                    <Route path={`/login`} component={LoginContainer} /> {/*Route added for windows app  */}

                    <Route path={`/doctor-login`} component={LoginContainer} />
                    <Route path={`/:providerName/doctor-login`} component={LoginContainer} />
                    {/* <Route path={`/consult-online`} component={OnlineConsultPatientInfoContainer} /> */}
                    <Route path={`/:providerName/consult-online`} component={OnlineConsultPatientInfoContainer} />
                    <Route path={`/:providerName/opd/new-patient`} component={OnlineConsultPatientInfoContainer} />
                    <Route path={`/:providerName/consult-doctor`} component={ConsultDoctorInfoContainer} />
                    <Route path="/unordered-invoice-list" component={UnorderedInvoiceListContainer} />
                    <Route path={`/forgot-password`} component={ForgotPasswordComponent} />
                    {/* <Route path="/clinytics-terms" component={TermsAndConditionComponent} /> */}
                    <Route path="/terms-conditions" component={TermsConditionComponent} />
                    <Route path="/survey-terms-conditions" component={SurveyTermsConditionComponent} />
                    <Route path="/clinytics-faqsurvey" component={SurveyFaqComponent} />
                    <Route path="/clinytics-aboutus" component={SurveyAbountUsComponent} />
                    <Route path="/clinytics-faq" component={FaqViewComponent} />
                    <Route path="/clinytics-faqs" component={FaqsComponent} />
                    <Route path="/about-us" component={AboutUsComponent} />
                    <Route path="/aboutus" component={AboutUsViewComponent} />
                    <Route path="/article-builder" component={ArticleBuilderContainer} />
                    <Route path="/patient-prescription" component={PatientPrescriptionPageComponent} />
                    <Route path="/:providerName/consultation-invoice/:invoiceId" component={PostPaymentInvoicePage} />
                    <Route path="/:providerName/inventory-management" component={InventoryManagementContainer} />
                    <Route path={`/booking-confirmation`} component={BookingConfirmatiomComponent} />
                    <Route path={`/payment-success`} component={PaySuccess} />
                    <Route path={`/payment-failure`} component={PayFailure} />
                    <Route path={`/service-sold`} component={BookingContainer} />
                    <Route path={`/:providerName/bookings-list`} component={BookingContainer} />
                    <Route path={`/:providerName/booking-confirmation`} component={BookingConfirmatiomComponent} />
                    <Route path={`/chat-room/:id`} component={ChatRoomContainer} />
                    <Route path={`/:providerName/chat-room/:id`} component={ChatRoomContainer} />
                    {/* <Route path="/chat-room" component={ChatRoomContainer} /> */}
                    <Route path={`/otp-verification`} component={OtpVerificationContainer} />
                    <Route path={`/:providerName/otp-verification`} component={OtpVerificationContainer} />
                    {/* /:providerName/waiting-area/:id */}
                    {/* <Route path={`/:providerName/video-room/:id`} component={NewChatRoomContainer} state={this.props.nfn} /> */}

                    <Route path={`/:providerName/video-room/:id`} render={(props) => {
                        return <NewChatRoomContainer {...props} 
                        pipModeOn={this.onPipModeToggle.bind(this)} closeVideoRoom={this.onCloseVideoRoom.bind(this)} redirectTo={this.onRedirectFromVideoRoom.bind(this)}
                        providerName={this.state.providerName}  notificationData={this.props.nfn}   />
                    }} />

                    <Route path={`/android-area/:id`} component={AndroidAreaContainer} ></Route>

                    <Route path={`/:providerName/waiting-area/:id`} render={(props) => {
                        return <WaitingAreaContainer {...props} notificationData={this.props.nfn} />
                    }} />
                    <Route path={`/:providerName/video-detailing/wait/:id`} render={(props) => {
                        return <VideoDetailingWaitContainer {...props} notificationData={this.props.nfn} />
                    }} />
                    <Route path={`/:providerName/video-detailing/call/:id`} render={(props) => {
                        return <VideoDetailingCallContainer {...props} notificationData={this.props.nfn} />
                    }} />
                    {/* <Route path={`/:providerName/waiting-area/:id`} component={WaitingAreaContainer} nfn={this.props.nfn} /> */}
                    {/* thb_test/waiting-area/bcf1186524922 */}
                    <Route path="/online-doctors" render={(props) => <OnlineDoctorListContainer {...props} nfn={this.props.nfn} />} />
                    <Route path={`/:providerName/opd/doctors`} render={(props) => <OnlineDoctorListContainer {...props} nfn={this.props.nfn} />} />
                    <Route path={`/:providerName/online-doctors`} render={(props) => <OnlineDoctorListContainer {...props} nfn={this.props.nfn} />} />
                    <Route path={`/:providerName/book-test`} render={(props) => <BookTestListing {...props} nfn={this.props.nfn} />} />
                    <Route path={`/:providerName/book-test-form`} render={(props) => <BooktestOnline {...props} nfn={this.props.nfn} />} />
                    <Route path="/:providerName/vaccination-center" component={VaccineCenterContainer}></Route>
                    <Route path="/:account_id/vaccination/error" component={NoPatientFound}></Route>
                    <Route path="/:account_id/vaccination/covid-faq" component={CovidFaq}></Route>
                    <Route path="/:account_id/vaccination/register" component={vaccinationRegisterationContainer}></Route>
                    <Route path="/:account_id/vaccination/checkout" component={vaccinationCheckout}></Route>
                    <Route path="/:account_id/vaccination/thank-you" component={PaymentSuccess}></Route>
                    <Route path="/:account_id/vaccination" component={VaccinationLoginContainer}></Route>
                    {/* opd/patients */}
                    <MoolchandProtectedRoute path="/:account_id/opd/patients" component={PatientOPDList}></MoolchandProtectedRoute>

                    <Route path="/:account_id/ipd/patients" component={vaccinationRegisterationContainer}></Route>
                    <Route path="/:account_id/ipd/checkout" component={HospitalConsultationCheckout}></Route>
                    <Route path="/:account_id/opd" component={VaccinationLoginContainer}></Route>
                    <Route path="/:account_id/ipd" component={VaccinationLoginContainer}></Route>


                    <Route path={`/404`} component={PageNotFoundComponent} />
                    <ProtectedRoute path="/" component={Main} nfn={this.props.nfn} />
                </Switch>
            </Suspense>
        )
    }
}

export default withRouter(RootRouters);
