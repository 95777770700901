import React from "react";


class Select extends React.Component {

    // constructor(props) {
    //     super(props);
    // }

    onInputChange = (event) => {
        event.preventDefault();
        this.props.onInputChange({ name: event.target.name, value: event.target.value, type: this.props.inputData.type })
    }

    render() {
        const { name, placeholder, multiple } = this.props.inputData;
        let isUserHimselfDoctor = !!(name === 'doctor_id' && this.props.isRoleDoctor);
        return (
            <>
                {!isUserHimselfDoctor &&
                    <select
                        multiple={multiple ? true : false}
                        className="form-control"
                        name={name}
                        value={this.props.dynamicValue ? this.props.dynamicValue : 'initial'}
                        onChange={this.onInputChange}>
                        <option disabled value="initial">{placeholder}</option>
                        {this.props.optionData && this.props.optionData.map((option, index) =>
                            <option key={index} value={option.key}>{option.value}</option>)
                        }
                    </select>
                }
                {isUserHimselfDoctor && <></>}
            </>
        );
    }
}

export default Select;
