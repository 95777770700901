// import firebase from 'firebase';
import * as firebase from "firebase/app";
// var event = new Event('build');
var fcmToken = "-";
var notificationList = [];

export const askForPermissionToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    fcmToken = token;
    // console.log('-------------------------------------------------> FCM token:', token);
    console.log(firebase);
    return token;
  } catch (error) {
    console.error(error);
    // console.log('------------------------------------------------->Error in FCM Token:');
  }
}

export function getFCMToken() {
  return fcmToken;
}

export function setNotifications(nfn) {
  notificationList.push(nfn);
}

export function getNotifications() {
  return notificationList;
}

 

