import React, {
    useState, useRef, useEffect
} from 'react';
import { useHistory } from "react-router";


function QuestionList(props) {

    const [isActiveTab, setIsActiveTab] = useState('0')

    return (
        <>
            <div className="faq-section">
                {props.questionsList && props.questionsList.length > 0 &&
                    <>
                        {props.questionsList.map((indQuestion, i) =>
                            <div className="accordion accordion-solid-content" id={`accordion-${i}`} role="tablist">
                                <div className="card">
                                    <div className="card-header" role="tab" id={`heading-${i}`} onClick={(e) => setIsActiveTab(i)}>
                                        <h6 className="m-0">
                                            <a data-toggle="collapse" href="#collapse-13" aria-expanded="true" aria-controls="collapse-13" className="" style={{ padding: 15 }}> Question No. {i + 1} <span className="ml-1 mr-1" onClick={(e) => props.onDeleteQuestion(i)}><i className="mdi mdi-delete-circle" data-tip /></span></a>
                                        </h6>
                                    </div>
                                    <div id={`heading-${i}`} className={`collapse ${isActiveTab == i ? 'show' : ''}`} role="tabpanel" aria-labelledby={`heading-${i}`} data-parent={`#accordion-${i}`} style={{ padding: 15 }}>
                                        <div className="card-body">
                                            <div className="form-group pb-0 border-bottom mb-0">
                                                <h6 className="card-heading m-0  mb-2">{indQuestion.question}</h6>
                                                {props.optionsList && props.optionsList.map((indOption, j) =>
                                                    <button type="button" className="btn btn-inverse-success m-2 btn-sm">{indOption}</button>
                                                )}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="row">
                            <div className="col-12 mb-4">
                                {props.isLoading &&
                                    <div class="clinytic-line-loader"></div>
                                }

                                <hr style={{ marginTop: 0 }} />
                                <button type="submit" className="btn btn-success mr-2" disabled={props.isLoading} onClick={() => props.emitSaveEvent()} >Save</button>
                            </div>
                        </div>
                    </>

                }



            </div>
        </>
    )
}

export default QuestionList;
