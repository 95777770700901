import React, { Component, useRef } from 'react';

class AudioVisualiser extends Component {
  
  constructor(props) {
    super(props);
    this.canvas = React.createRef();
    this.SIZE = props.size;
    // this.ref = useRef();
    this.clipId = 0;
    // this.SVGClipElement = this.ref.current;
    
  }
 
  getUniqueClipId(){ return this.clipId++};

  componentDidUpdate() {
    this.draw();
  }

  draw() {
    let values = 0;
    let sampleArray = this.props.sampleArray;
    
        const length = sampleArray.length;
        for (let i = 0; i < length; i++) {
          values += sampleArray[i];
        }

        const volume = Math.min(21, Math.max(0, Math.log10(values / length / 3) * 14));
        this.clipPathId = `audio-level-clip-${this.getUniqueClipId().toString()}`;
        // console.log(this.clipPathId);
        // console.log(volume)
        this.refs.svgElement.setAttribute('y', String(21 - volume));
  }

  render() {
    this.clipPathId = `audio-level-clip-${this.getUniqueClipId()}`;
    return (
      <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true" height={`${this.SIZE}px`} width={`${this.SIZE}px`}>
        <defs>
          <clipPath id={this.clipPathId}>
            <rect ref="svgElement" x="0" y="21" width="24" height="24" />
          </clipPath>
        </defs>
        <path
          fill={'rgba(255, 255, 255, 0.1)'}
          d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"
        ></path>
        <path
          fill="#0c0"
          clipPath={`url(#${this.clipPathId})`}
          d="M12 14c1.66 0 2.99-1.34 2.99-3L15 5c0-1.66-1.34-3-3-3S9 3.34 9 5v6c0 1.66 1.34 3 3 3zm5.3-3c0 3-2.54 5.1-5.3 5.1S6.7 14 6.7 11H5c0 3.41 2.72 6.23 6 6.72V21h2v-3.28c3.28-.48 6-3.3 6-6.72h-1.7z"
        ></path>
      </svg>
    )
  }
}

export default AudioVisualiser;