import React from "react";
import appointmentFormData from "../../../Constants/Forms/appointmentFormData";
// common components
import CommonModalView from '../../../Common/Modal/CommonModalView';
import TimeSlot from './TimeSlot';
import Utils from '../../../Shared/utils/utils';
import DatePicker from "react-datepicker";
import Table from "../../../Common/FormInputs/GridComponents/Table";
import MultiAutocompleteComponent from "../../../Common/FormFields/MultiAutocompleteComponent";
import PatientTableViewComponent from './PatientTableViewComponent';
import Spinner from "../../../Common/Spinner/Spinner";
import { withSnackbar } from 'notistack';
import OTPVerification from './OTPVerification';
import patientService from '../../../Services/patient.service';
import onlineConsultationService from '../../../Services/onlineConsultation.service';
import doctorService from "../../../Services/doctor.service";
import appointmentService from '../../../Services/appointment.service';
import SweetAlertComponent from '../../../Common/Alert/SweetAlertComponent';
import { getMasterData } from "../../vaccination/vaccinationService";
// jQuery initilization
const $ = window["jQuery"];

class AppointmentFormComponent extends React.Component {
    constructor(props) {
        super(props);
        let role = sessionStorage.getItem('userRole');
        let accountInfo = sessionStorage.getItem('accountInfo') ? JSON.parse(sessionStorage.getItem('accountInfo')) : '';
        let doc = role && role.indexOf('doctor') !== -1 ? sessionStorage.getItem('profileInfo') ? JSON.parse(sessionStorage.getItem('profileInfo')) : '' : '';

        this.state = {
            isStaff: role && role.indexOf('staff') !== -1,
            isOfflineOnlineFeatureEnabled: role && role.indexOf('f:onoff') !== -1,
            docIdForSlots: doc ? doc.cognito_user_sub : '',
            branch: '',
            accountInfo: accountInfo,
            doctorList: this.props.doctorList,
            showPresetSlots: (accountInfo && accountInfo.slots_required),
            formType: this.props.type,
            otp: '',
            otploading: false,
            otpWrong: false,
            department: '',
            doctor: '',
            openModal: false,
            isPatientSelection: false,
            requiredFeilds: null,
            remarks: '',
            copy_email: '',
            isAppointmentDateDisable: false,
            openRescheduledDatePicker: false,
            isFormDisabled: true,
            isAppointmentStatusDisable: false,
            isValidEmail: true,
            isValidMobile: true,
            isFormLoading: this.props.isFormLoading,
            patientList: null,
            openPatientListModal: false,
            isApptTypeModalOpen: true,
            openOTPRegistrationModal: false,
            appointmentFormData: {},
            appointmentType: 'offline',
            feesOptions: null,
            selectedSlot: '',
            slotType: null,
            isSlotLoading: false,
            isSlotModalOpen: false,
            showClock: true,
            slotData: null,
            show: false,
            alert: {
                type: '',
                title: '',
                text: ''
            },
            countries: [], states: [], cities: [], locationDropdown: []
        }
        this.onPatientDataChange = this.onPatientDataChange.bind(this);
        this.onTextChangeView = this.onTextChangeView.bind(this);
        this.onSelectValueChange = this.onSelectValueChange.bind(this);
    }

    async getSlotData(doctorId, slotDate) {
        let apptType = "online"
        if ((typeof this.state.appointmentFormData['appointment_type']) != "undefined") {
            apptType = this.state.appointmentFormData['appointment_type'];
            //return;
        }
        this.setState({ isSlotLoading: true });
        //console.log("appointmentFormData 11",typeof this.state.appointmentFormData['appointment_type']);
        try {
            let date = Utils.getFormatedDate(slotDate)
            const response = await doctorService.fetchDoctorSlotForAppointment(doctorId, date, apptType, null);
            this.checkForSlotData(response);
        } catch (error) {
            console.log(error)
            let { isSlotLoading } = this.state;
            isSlotLoading = false;
            this.setState({ isSlotLoading });
        }


    }

    checkForSlotData(response) {
        let { isSlotLoading, slotData, showClock } = this.state;
        if (!response || !response.data) {
            isSlotLoading = false;
            slotData = []
            showClock = true;
            this.setState({ isSlotLoading, slotData, showClock });
            // this.props.enqueueSnackbar('Error Occurred.')
            return;
        }
        switch (response.type) {
            case 'error':
                isSlotLoading = false;
                slotData = [];
                showClock = true;
                this.setState({ isSlotLoading, slotData, showClock });
                break;
            case 'success':
                
                this.transformSlotData(response.data);
                break;
            default:
                break;
        }
    }

    transformSlotData(data) {
        let { slotData, isSlotLoading, showClock } = this.state;
        if (!data) {
            isSlotLoading = false;
            slotData = [];
            showClock = true;
            this.setState({ isSlotLoading, slotData, showClock });
            return;
        }
        let slotArray = []
        let sequence = ['morning', 'evening', 'afternoon']
        for (let key in data) {

            if (sequence.indexOf(key) !== -1) {
                let indSlotObj = { name: key }
                let index = sequence.indexOf(key)
                indSlotObj["slots"] = data[key]

                slotArray.splice(index, 0, indSlotObj);
            }
        }
        slotData = slotArray;
        isSlotLoading = false;
        showClock = !slotData || slotData.length === 0 || !this.state.showPresetSlots;
        // if (slotData && slotData.length === 0) {
        //     this.props.enqueueSnackbar('No Slots Available!')
        // }
        this.setState({ slotData, isSlotLoading, showClock })
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let { formType, appointmentType, appointmentFormData, openRescheduledDatePicker, isAppointmentDateDisable, doctorList, isFormLoading, feesOptions } = this.state;

        if (appointmentFormData) {
            //console.log("appointmentFormData here",appointmentFormData );
            if (typeof appointmentFormData['appointment_type'] == 'undefined') {
                let role = sessionStorage.getItem('userRole');
                //console.log("role.indexOf('f:payment')",role.indexOf('f:payment') );
                //console.log("role.indexOf('f:online')",role.indexOf('f:online') );
                if (role && (role.indexOf('f:payment') !== -1 || role.indexOf('f:online') !== -1) && role.indexOf('f:onoff') == -1) {
                    appointmentType = 'online';
                    //console.log("here on condition");
                }
            }
        }
        if (nextProps.appointmentData) {
            if (nextProps.appointmentData !== this.props.appointmentData) {
                formType = nextProps.type;
                // doctorList = nextProps.doctorList;
                if (appointmentFormData['appointment_status'] && nextProps.appointmentData['appointment_status'] !== appointmentFormData['appointment_status']) {
                    nextProps.appointmentData['appointment_status'] = appointmentFormData['appointment_status'];
                }
                appointmentFormData = this.autoFillForm(nextProps.appointmentData);
                if (nextProps.appointmentData && nextProps.appointmentData['doctor_id']) {
                    let doc = nextProps.doctorList.find(d => d.cognito_user_sub === nextProps.appointmentData['doctor_id']);
                    feesOptions = doc && doc.multi_consultation_fee && Array.isArray(doc.multi_consultation_fee) ? doc.multi_consultation_fee : null;
                }
                if (nextProps.appointmentData && nextProps.appointmentData['appointment_status'] === "Re-Scheduled") {
                    openRescheduledDatePicker = true;
                    appointmentFormData['rescheduled_appointment_date'] = (nextProps.appointmentData['rescheduled_appointment_date'] && nextProps.appointmentData['rescheduled_appointment_date'] !== "-") ? new Date(nextProps.appointmentData['rescheduled_appointment_date']) : ''
                } else {
                    openRescheduledDatePicker = false;
                    appointmentFormData['rescheduled_appointment_date'] = '';
                }
                appointmentFormData['remarks'] = nextProps.appointmentData['remarks'] ? nextProps.appointmentData['remarks'] : '';
                appointmentFormData['copy_email'] = nextProps.appointmentData['copy_email'] ? nextProps.appointmentData['copy_email'] : '';
                appointmentFormData['appointment_date'] = (nextProps.appointmentData['appointment_date'] && nextProps.appointmentData['appointment_date'] !== "-") ? new Date(nextProps.appointmentData['appointment_date']) : ''
                appointmentFormData['date_of_birth'] = (nextProps.appointmentData['patient'] && nextProps.appointmentData['patient']['date_of_birth'] && nextProps.appointmentData['patient']['date_of_birth'] !== "-") ? new Date(nextProps.appointmentData['patient']['date_of_birth']) : '';

                if (nextProps.appointmentData && nextProps.appointmentData['doctor_id'] && nextProps.appointmentData['appointment_date']) {
                    this.getSlotData(nextProps.appointmentData['doctor_id'], nextProps.appointmentData['appointment_date']);
                }

                if (!nextProps.appointmentData['patient']['address']) {
                    appointmentFormData['city'] = '';
                }
                //console.log("appointmentFormData",appointmentFormData );
                isAppointmentDateDisable = (nextProps.type === "edit" && nextProps.appointmentData['appointment_date'] && nextProps.appointmentData['appointment_date'] !== "-") ? true : false;
                this.setState({ formType, appointmentFormData, openRescheduledDatePicker, isAppointmentDateDisable, doctorList });
                this.checkForAppointmentStatus(nextProps.appointmentData);
                this.checkFormValues();
            }

            doctorList = nextProps.doctorList;
        } else {
            isAppointmentDateDisable = (nextProps.type === "edit" && nextProps.appointmentData && nextProps.appointmentData['appointment_date'] && nextProps.appointmentData['appointment_date'] !== "-") ? true : false;
            doctorList = nextProps.doctorList;
        }
        if (isFormLoading !== nextProps.isFormLoading) {
            isFormLoading = nextProps.isFormLoading;
        }
        if (nextProps.doctorList && nextProps.doctorList.rows && nextProps.doctorList.rows.length === 1) {
            // nextProps.doctorList !== this.props.doctorList &&
            //  
            feesOptions = nextProps.doctorList.rows[0].multi_consultation_fee && Array.isArray(nextProps.doctorList.rows[0].multi_consultation_fee) ? nextProps.doctorList.rows[0].multi_consultation_fee : null;
            appointmentFormData['doctor_id'] = nextProps.doctorList.rows[0].cognito_user_sub;
            appointmentFormData['doctor_name'] = nextProps.doctorList.rows[0].name;
        }
        this.setState({ formType, appointmentType, openRescheduledDatePicker, isAppointmentDateDisable, doctorList, isFormLoading, feesOptions });
    }

    stringSpiltForHeader(value, seperate) {
        return value.split(seperate);
    }

    checkForAppointmentStatus(appointmentData) {
        switch (appointmentData['appointment_status']) {
            case 'Booked':
            case 'No-Show':
            case 'Checked-In':
            case 'Consulting':
            case 'Re-Scheduled':
                this.state.isAppointmentStatusDisable = false;
                this.setState(this.state);
                break;
            case 'Done':
            case 'Cancelled':
                this.state.isAppointmentStatusDisable = true;
                this.setState(this.state);
                break;
        }
    }

    clearFormData() {
        let { appointmentFormData } = this.state;
        for (var property in appointmentFormData) {
            if (property === 'email' || property === 'phone' || property === 'name' || property === 'age' || property === 'patient_hid') {
                appointmentFormData[property] = { value: property === 'age' ? { no_of_years: '', no_of_months: '', no_of_days: 0 } : '', hasErrors: false };
            } else if (appointmentFormData.hasOwnProperty(property)) {
                appointmentFormData[property] = '';
            }
        }
        this.setState({ appointmentFormData });
    }

    createLocalForm() {
        let apt_slot = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
        if (!apt_slot.toUpperCase().includes('AM') && !apt_slot.toUpperCase().includes('PM')) {
            apt_slot = Utils.formatTimeDetails(apt_slot)
        }
        let appointmentFormData = { //data with exact mapping of fields present in view
            'appointment_date': new Date(),
            'appointment_slot': apt_slot,
            'rescheduled_appointment_date': '',
            'rescheduled_appointment_slot': '',
            'appointment_status': '',
            'invoice_amount': '',
            'name': { value: '', hasErrors: false },
            'age': { value: { no_of_years: '', no_of_months: '', no_of_days: 0 }, hasErrors: false },
            'date_of_birth': '',
            'country_code': sessionStorage.getItem('s3AccountId') === "breathefree" ? "+977" :'+977',
            'gender': '',
            'phone': { value: '', hasErrors: false },
            'guardian_name': { value: '', hasErrors: false, hidden: true },
            'guardian_phone': { value: '', hasErrors: false, hidden: true },
            'email': { value: '', hasErrors: false },
            'country': 'nepal',
            "service_name": '',
            'state': '',
            'city': '',
            'zipcode': '',
            'line1': '',
            'line2': '',
            'patient_hid': { value: '', hasErrors: false },
            'doctor_id': '',
            'doctor_name': '',
            'department': '',
            'referred_doctor_name': '',
            'referred_doctor_id': '',
            "remarks": '',
            "copy_email": '',
            'institution': ''
        }
        if (this.state.doctorList && this.state.doctorList.rows && this.state.doctorList.rows.length === 1) {
            let { feesOptions } = this.state;
            // 
            //console.log("appointmentFormData",appointmentFormData );
            feesOptions = this.state.doctorList.rows[0].multi_consultation_fee && Array.isArray(this.state.doctorList.rows[0].multi_consultation_fee) ? this.state.doctorList.rows[0].multi_consultation_fee : null;
            this.setState({ feesOptions });
            appointmentFormData = {
                ...appointmentFormData,
                'doctor_id': this.state.doctorList.rows[0].cognito_user_sub,
                'doctor_name': this.state.doctorList.rows[0].name
            }
        }
        return appointmentFormData;
    }

    autoFillForm(data) {
        let appointmentFormData = {
            ...data,
            'doctor_id': data['doctor_id'],
            "service_name": data['service_name'] ? data['service_name'] : '',
            'doctor_name': data['doctor_name'],
            'appointment_date': data['appointment_date'],
            'appointment_slot': data['appointment_slot'],
            'slot_type': this.state['slotType'],
            'rescheduled_appointment_date': data['appointment_status'] === "Re-Scheduled" ? Utils.getFormatedDate(data['rescheduled_appointment_date']) : '',
            'rescheduled_appointment_slot': data['rescheduled_appointment_slot'],
            'appointment_type': data['appointment_type'],
            'appointment_status': data['appointment_status'],
            'appointment_status': data['invoice_amount'] ? data['invoice_amount'] : '',
            'name': { value: data['patient'] && data['patient']['name'] ? data['patient']['name'] : '', hasErrors: false },
            'age': { value: data['patient'] ? data['patient']['age'] : '', hasErrors: false },
            'date_of_birth': data['patient'] && data['patient']['date_of_birth'] ? data['patient']['date_of_birth'] : '',
            'country_code': data['patient'] && data['patient']['country_code'] ? data['patient']['country_code'] : '+977',
            'gender': data['patient'] && data['patient']['gender'] ? data['patient']['gender'] : '',
            'phone': { value: data['patient'] && data['patient']['phone'] ? data['patient']['phone'] : '', hasErrors: false },
            'country': data['patient'] && data['patient']['address'] && data['patient']['address']['country'] ? data['patient']['address']['country'] : '',
            'state': data['patient'] && data['patient']['address'] && data['patient']['address']['state'] ? data['patient']['address']['state'] : '',
            'city': data['patient'] && data['patient']['address'] && data['patient']['address']['city'] ? data['patient']['address']['city'] : '',
            'zipcode': data['patient'] && data['patient']['address'] && data['patient']['address']['zipcode'] ? data['patient']['address']['zipcode'] : '',
            'line1': data['patient'] && data['patient']['address'] && data['patient']['address']['line1'] ? data['patient']['address']['line1'] : '',
            'line2': data['patient'] && data['patient']['address'] && data['patient']['address']['line2'] ? data['patient']['address']['line2'] : '',
            'email': { value: data['patient'] && data['patient']['email'] ? data['patient']['email'] : '', hasErrors: false },
            'patient_hid': { value: data['patient'] && data['patient']['patient_hid'] ? data['patient']['patient_hid'] : '', hasErrors: false },
            'department': data['department'],
            'referred_doctor_name': data['referred_doctor_name'],
            'referred_doctor_id': data['referred_doctor_id'],
            'invoice_amount': data['invoice_amount'] ? data['invoice_amount'] : '',
            "remarks": data['remarks'] ? data['remarks'] : '',
            "copy_email": data['copy_email'] ? data['copy_email'] : false,
            "institution": data['institution'] ? data['institution'] : '',
        };
        //console.log("appointmentFormData",appointmentFormData );
        if (this.state.doctorList && this.state.doctorList.rows && this.state.doctorList.rows.length === 1 && !data['doctor_id']) {
            let { feesOptions } = this.state;
            feesOptions = this.state.doctorList.rows[0].multi_consultation_fee && Array.isArray(this.state.doctorList.rows[0].multi_consultation_fee) ? this.state.doctorList.rows[0].multi_consultation_fee : null;
            this.setState({ feesOptions });
            appointmentFormData = {
                ...appointmentFormData,
                'doctor_id': this.state.doctorList.rows[0].cognito_user_sub,
                'doctor_name': this.state.doctorList.rows[0].name
            }
        }
        return appointmentFormData;
    }

    autoFillUsingPatientSearch(data) { // < -- here
        let appointmentFormData = {
            'name': { value: data['name'] ? data['name'] : '', hasErrors: false, readOnly: data['name'] ? true : false },
            'patient_hid': { value: data['patient_hid'] ? data['patient_hid'] : '', hasErrors: false, readOnly: data['patient_hid'] ? true : false },
            'age': { value: data['age'] ? data['age'] : '', hasErrors: false },
            'guardian_name': { value: data['guardian_name'] ? data['guardian_name'] : '', hasErrors: false, hidden: data['guardian_name'] ? false : true },
            'guardian_phone': { value: data['guardian_phone'] ? data['guardian_phone'] : '', hasErrors: false, hidden: data['guardian_phone'] ? false : true },
            'date_of_birth': data['date_of_birth'] ? new Date(data["date_of_birth"]) : '',
            'country_code': data['country_code'] ? data['country_code'] : sessionStorage.getItem('s3AccountId') === "breathefree" ? "+977" : '+977',
            'gender': data['gender'] ? data['gender'] : '',
            'phone': { value: data['phone'] ? data['phone'] : '', hasErrors: false, originalPhone: data['phone'] ? data['phone'] : '' },
            'country': data['address'] && data['address']['country'] ? data['address']['country'] : '',
            'state': data['address'] && data['address']['state'] ? data['address']['state'] : '',
            'city': data['address'] && data['address']['city'] ? data['address']['city'] : '',
            'zipcode': data['address'] && data['address']['zipcode'] ? data['address']['zipcode'] : '',
            'line1': data['address'] && data['address']['line1'] ? data['address']['line1'] : '',
            'line2': data['address'] && data['address']['line2'] ? data['address']['line2'] : '',
            'email': { value: data['email'] ? data['email'] : '', hasErrors: false },
        };
        return appointmentFormData;
    }

    clearFormDataForPatient() {
        let appointmentFormData = {
            'name': { value: '', hasErrors: false, readOnly: false },
            'guardian_name': { value: '', hasErrors: false, hidden: true },
            'guardian_phone': { value: '', hasErrors: false, hidden: true },
            'age': { value: { no_of_years: '', no_of_months: '', no_of_days: 0 }, hasErrors: false },
            'date_of_birth': '',
            'country_code': sessionStorage.getItem('s3AccountId') === "breathefree" ? "+977" : '+977',
            'gender': '',
            'phone': { value: '', hasErrors: false, originalPhone: '' },
            'country': '',
            'state': '',
            'city': '',
            'zipcode': '',
            'line1': '',
            'line2': '',
            'email': { value: '', hasErrors: false },
            'patient_hid': { value: '', hasErrors: false, readOnly: false }
        };
        return appointmentFormData;
    }

    setFormValueAfterFieldValidation = (inputResponse) => {
        let { name, value, patientError } = inputResponse;
        let { appointmentFormData } = this.state;
        let hasErrors;
        switch (name) {
            case 'email':
                hasErrors = false;
                break;
            case 'docter_id':
                hasErrors = false;
                break;
            case 'age:years':
                name = 'age';
                value = { ...appointmentFormData.age.value, no_of_years: value }
                hasErrors = (value.no_of_years !== '' || value.no_of_months !== '') &&
                    (value.no_of_years >= 0 && value.no_of_months >= 0) ? false : true;
                break;
            case 'age:months':
                name = 'age';
                value = { ...appointmentFormData.age.value, no_of_months: value }
                hasErrors = (value.no_of_years !== '' || value.no_of_months !== '') &&
                    (value.no_of_years >= 0 && value.no_of_months >= 0) ? false : true;
                break;
            case 'name':
                hasErrors = value ? !Utils.validateNamePlusUHID(value) || !value.trim() : false;
                break;
            case 'phone':
                hasErrors = value ? !Utils.validateMobile(value) : false;
                break;
            case 'guardian_name':
                hasErrors = value ? !Utils.validateName(value) || !value.trim() : true;
                break;
            case 'guardian_phone':
                hasErrors = value ? !Utils.validateMobile(value) : true;
                break;
            case 'patient_hid':
                hasErrors = false;
                break;
            default:
        }
        let extra = {};
        if (name === 'age' && value.no_of_years < 18) {
            extra = {
                guardian_name: { ...appointmentFormData['guardian_name'], hasErrors: appointmentFormData['guardian_name'].value ? !Utils.validateName(appointmentFormData['guardian_name'].value) || !appointmentFormData['guardian_name'].value.trim() : true, hidden: false },
                guardian_phone: { ...appointmentFormData['guardian_phone'], hasErrors: appointmentFormData['guardian_phone'].value ? !Utils.validateMobile(appointmentFormData['guardian_phone'].value) || !appointmentFormData['guardian_phone'].value.trim() : true, hidden: false },
            }
        } else if (name === 'age' && value.no_of_years >= 18) {
            extra = {
                guardian_name: { ...appointmentFormData['guardian_name'], hidden: true, hasErrors: false },
                guardian_phone: { ...appointmentFormData['guardian_phone'], hidden: true, hasErrors: false }
            }
        }
        //console.log("appointmentFormData",appointmentFormData );
        this.setState(prevState => ({
            appointmentFormData: {
                ...prevState.appointmentFormData,
                ...extra,
                [name]: { ...appointmentFormData[name], value: value, hasErrors: hasErrors, patientError: '' }
            }
        }), () => this.checkFormValues());
    }

    onCountryCodeChange = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            appointmentFormData: {
                ...prevState.appointmentFormData,
                patient: {
                    ...prevState.appointmentFormData.patient,
                    [name]: value
                }
            }
        }), () => this.checkFormValues())
    }

    onPatientMobileDataChange = (event) => {
        const { name, value } = event.target;
        let pattern = /^\d+$/;
        if (value === "") {
            this.setState(prevState => ({
                appointmentFormData: {
                    ...prevState.appointmentFormData,
                    patient: {
                        ...prevState.appointmentFormData.patient,
                        [name]: value
                    }
                }
            }), () => this.checkFormValues())
            return;
        }
        if (!pattern.test(value) || value.length > 10) {
            return
        }
        this.setState(prevState => ({
            appointmentFormData: {
                ...prevState.appointmentFormData,
                patient: {
                    ...prevState.appointmentFormData.patient,
                    [name]: value
                }
            }
        }), () => this.checkFormValues())
    }

    onPatientDataChange = (event) => {
        const { name, value } = event.target;

        if (value.length > 30) {
            return
        }
        this.setState(prevState => ({
            appointmentFormData: {
                ...prevState.appointmentFormData,
                patient: {
                    ...prevState.appointmentFormData.patient,
                    [name]: value
                }
            }
        }), () => this.checkFormValues())
    }

    onTextChangeView = (event) => {
        const { name, value } = event.target;
        this.setState(prevState => ({
            appointmentFormData: {
                ...prevState.appointmentFormData,
                [name]: value
            }
        }), () => this.checkFormValues())
    }

    onDoctorSelectValueChange = (docId) => {
        for (let index in this.state.doctorList.rows) {
            if (docId === this.state.doctorList.rows[index].cognito_user_sub) {
                let { doctor_id, doctor_name } = this.state.appointmentFormData;
                doctor_id = this.state.doctorList.rows[index].cognito_user_sub;
                doctor_name = this.state.doctorList.rows[index].name;
                let { feesOptions, docIdForSlots } = this.state;
                docIdForSlots = doctor_id;
                feesOptions = this.state.doctorList.rows[index].multi_consultation_fee && Array.isArray(this.state.doctorList.rows[index].multi_consultation_fee) ? this.state.doctorList.rows[index].multi_consultation_fee : null;
                let appointmentFormData = {
                    ...this.state.appointmentFormData, doctor_id, doctor_name
                }
                this.setState({
                    appointmentFormData,
                    feesOptions,
                    docIdForSlots
                })
                break;
            }
        }

        // fetch preset slots
        if (docId) {
            if (this.state.isAppointmentDateDisable)
                this.getSlotData(docId, this.state.appointmentFormData['rescheduled_appointment_date']);
            else
                this.getSlotData(docId, this.state.appointmentFormData['appointment_date']);
        }
        this.checkFormValues()
    }

    onBranchSelection = (event) => {
        const { name, value } = event.target;
        let { branch } = this.state;
        branch = value
        // 'doctor_id': this.state.appointmentFormData['doctor_id'],
        // 'doctor_name': this.state.appointmentFormData['doctor_name']
        // this.setState({ branch }, () => this.fetchDoctorList(value));
        this.setState(prevState => ({
            branch,
            appointmentFormData: {
                ...prevState.appointmentFormData,
                "doctor_id": '',
                "doctor_name": ''
            }
        }), () => this.fetchDoctorList(value))
    }

    async fetchDoctorList(value) {
        try {
            let key = (value === "All") ? '' : value
            const settingsResponse = await appointmentService.fetchSettings(key);
            this.checkSettingsResponse(settingsResponse);
        } catch (error) {
            console.log('error');
        }
    }

    checkSettingsResponse(response) {
        switch (response.type) {
            case "error":
                this.state.doctorList = [];
                this.setState(this.state);
                break;
            case "success":
                this.state.doctorList = response.data ? response.data : [];
                this.setState(this.state);
                break;
        }
    }

    onServiceSelection = (event) => {
        const { name, value } = event.target;
        let serviceValue = this.state.accountInfo.services_fees.find(k => k.serviceCost === value);
        this.setState(prevState => ({
            appointmentFormData: {
                ...prevState.appointmentFormData,
                "service_name": serviceValue.serviceName,
                "invoice_amount": value
            }
        }))
    }

    onSelectValueChange = (event) => {
        let { name, value } = event.target;
        this.setState(prevState => ({
            appointmentFormData: {
                ...prevState.appointmentFormData,
                [name]: value
            }
        }), () => this.checkIfValueIsReschedule(value))
    }
    onSelectValueChange_doc = (event) => {
        let { label, value } = event;
        this.onDoctorSelectValueChange(value);
        this.setState(prevState => ({
            appointmentFormData: {
                ...prevState.appointmentFormData,
                doctor_id: value,
                doctor_name: label
            }
        }), () => this.checkIfValueIsReschedule(value))
    }




    checkIfValueIsReschedule(value) {
        if (this.state.formType === "edit") {
            if (value === "Re-Scheduled") {
                this.setState({
                    openRescheduledDatePicker: true
                }, () => this.checkFormValues())
            } else {
                this.setState({
                    openRescheduledDatePicker: false
                }, () => this.checkFormValues())
            }
        }
        this.checkFormValues()
    }

    handleRescheduledAppointmentSlotChange = (event) => {
        // this.state.isAppointmentDateDisable
        const value = event;
        this.setState(prevState => ({
            appointmentFormData: {
                ...prevState.appointmentFormData,
                'rescheduled_appointment_slot': value
            }
        }), () => this.checkFormValues())
    }

    handleAppointmentSlotChange = (event) => {
        // this.state.isAppointmentDateDisable
        const value = event;
        this.setState(prevState => ({
            appointmentFormData: {
                ...prevState.appointmentFormData,
                'appointment_slot': value
            }
        }), () => this.checkFormValues())
    }

    handleAppointmentDateChange = (event) => {
        let value = event;
        this.setState(prevState => ({
            selectedSlot: '',
            appointmentFormData: {
                ...prevState.appointmentFormData,
                'appointment_date': value
            }
        }), () => { this.props.emitAppointmentDate(value); this.checkFormValues() })
        // fetch preset slots
        let docId = this.state.docIdForSlots ? this.state.docIdForSlots : this.state.appointmentFormData['doctor_id'] ? this.state.appointmentFormData['doctor_id'] : '';
        if (docId && value) {
            this.getSlotData(docId, value);
        }
    }

    handleRescheduledAppointmentDate = (event) => {
        const value = event
        this.setState(prevState => ({
            appointmentFormData: {
                ...prevState.appointmentFormData,
                'rescheduled_appointment_date': value
            }
        }), () => this.checkFormValues())

        // fetch preset slots
        let docId = this.state.docIdForSlots ? this.state.docIdForSlots : this.state.appointmentFormData['doctor_id'] ? this.state.appointmentFormData['doctor_id'] : '';
        if (docId && value) {
            this.getSlotData(docId, value);
        }
    }

    getEmitedTimeSlotData = (event) => {
        this.setState({ slotType: '', selectedSlot: event.time });
    }

    emitSlotSelectionConfirmation = () => {
        let value = ''
        if (this.state.selectedSlot) {
            value = this.state.selectedSlot;
        } else {
            value = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        }
        if (this.state.isAppointmentDateDisable) {
            this.setState(prevState => ({
                isSlotModalOpen: false,
                appointmentFormData: {
                    ...prevState.appointmentFormData,
                    'rescheduled_appointment_slot': value,
                    slot_type: this.state.slotType
                }
            }), () => this.checkFormValues())
        } else {
            this.setState(prevState => ({
                isSlotModalOpen: false,
                appointmentFormData: {
                    ...prevState.appointmentFormData,
                    'appointment_slot': value,
                    slot_type: this.state.slotType
                }
            }), () => this.checkFormValues())
        }
    }

    // sets value in state
    setFormValueInState = (inputResponse) => {
        const { name, value } = inputResponse;
        if (inputResponse.type === 'date' && inputResponse.name === 'date_of_birth') {
            this.handleDOBChange(inputResponse.value);
            return;
        }
        const nameArr = ['email', 'phone', 'name', 'patient_hid', 'age:years', 'age:months', 'age:days', 'guardian_name', 'guardian_phone']
        if (nameArr.indexOf(name) !== -1) {
            this.setFormValueAfterFieldValidation(inputResponse);
            return;
        }

        this.setState(prevState => ({
            appointmentFormData: {
                ...prevState.appointmentFormData,
                [name]: value
            }
        }), () => this.checkFormValues());
    }

    handleDOBChange = (dob) => {
        // set Age automatically
        let { appointmentFormData } = this.state;
        if (dob) {
            var sdt = new Date(dob);
            var difdt = new Date(new Date() - sdt);
            let age = {
                ...this.state.appointmentFormData['age'],
                hasErrors: false,
                value: { no_of_years: difdt.toISOString().slice(0, 4) - 1970, no_of_months: difdt.getMonth(), no_of_days: 0 }
            };
            let extra = {}
            if (age.value.no_of_years < 18) {
                extra = {
                    guardian_name: { ...appointmentFormData['guardian_name'], hasErrors: appointmentFormData['guardian_name'].value ? !Utils.validateName(appointmentFormData['guardian_name'].value) || !appointmentFormData['guardian_name'].value.trim() : true, hidden: false },
                    guardian_phone: { ...appointmentFormData['guardian_phone'], hasErrors: appointmentFormData['guardian_phone'].value ? !Utils.validateMobile(appointmentFormData['guardian_phone'].value) || !appointmentFormData['guardian_phone'].value.trim() : true, hidden: false },
                }
            } else {
                extra = {
                    guardian_name: { ...appointmentFormData['guardian_name'], hidden: true, hasErrors: false },
                    guardian_phone: { ...appointmentFormData['guardian_phone'], hidden: true, hasErrors: false }
                }
            }
            this.setState({ appointmentFormData: { ...appointmentFormData, 'age': age, ...extra, 'date_of_birth': dob } }, () => this.checkFormValues());
        }
    }

    sweetAlertClose = (event) => {
        this.setState({ show: false }, () => this.createAppointment());
    }

    onAlertClose = (event) => {
        this.setState({ show: false })
    }

    onAppointmentCreateClicked = (paylater) => {
        let { appointmentFormData } = this.state;
        if (sessionStorage.getItem('s3AccountId') !== 'blk_hospital') {
            if (appointmentFormData['patient_hid']['value']) {
                this.fetchPatients(appointmentFormData['patient_hid']['value'], 'patient_hid', 'search');
            } else {
                this.createAppointment(paylater);
            }
            return;
        }

        // follow otp registration path only for blk_hospital if phone number was modified
        if (appointmentFormData['phone'] && appointmentFormData['phone'].value === appointmentFormData['phone'].originalPhone) {
            this.createAppointment(paylater);
        } else {
            this.getEmitedResendOtpButtonEvent();
            this.setState({ openOTPRegistrationModal: true });
        }
    }

    createAppointment(event) {
        let form = this.state.appointmentFormData;
        //console.log("appointmentFormData",appointmentFormData );
        this.props.emitAppointmentDate(form['appointment_date']);

        const { accountInfo } = this.state


        if(sessionStorage.getItem('s3AccountId') === "breathefree") {
            if (form.referred_doctor_name.value === "") {
                this.props.enqueueSnackbar('Please enter Referred Doctor Name');
                return
            } 
        }

        if (accountInfo.enable_opd_flow === true) {
            if (form.email.value === "") {
                this.props.enqueueSnackbar('Please enter email');
                return
            }

            if (form.gender === "") {
                this.props.enqueueSnackbar('Please choose gender');
                return
            }
            if (form.country === "") {
                this.props.enqueueSnackbar('Please enter country name');
                return
            }

            if (form.state === "") {
                this.props.enqueueSnackbar('Please enter state name');
                return
            }

            if (form.city === "") {
                this.props.enqueueSnackbar('Please enter city name');
                return
            }
        }



        let data = {
            'appointment_date': Utils.getFormatedDate(form['appointment_date']),
            "remarks": form['remarks'] ? form['remarks'] : '',
            "copy_email": form['copy_email'] ? form['copy_email'] : '',
            "institution": form['institution'] ? form['institution'] : '',
            'appointment_slot': form['appointment_slot'],
            'slot_type': this.state['slotType'],
            'rescheduled_appointment_date': form['appointment_status'] === "Re-Scheduled" ? Utils.getFormatedDate(form['rescheduled_appointment_date']) : '',
            'rescheduled_appointment_slot': form['rescheduled_appointment_slot'],
            'appointment_type': sessionStorage.getItem('s3AccountId') !== "breathefree" ? form['appointment_type'] : "online",
            'service_name': form['service_name'],
            'appointment_status': form['appointment_status'],
            'invoice_amount': form['invoice_amount'],
            'patient': {
                'name': form['name'] ? form['name'].value : '',
                'age': form['age'].value,
                'branch' : form['branch'] === undefined ? this.state.locationDropdown[0] : this.state.branch,
                'date_of_birth': form['date_of_birth'] ? Utils.getFormatedDate(form['date_of_birth']) : '',
                'country_code': form['country_code'] ? form['country_code'] : sessionStorage.getItem('s3AccountId') === "breathefree" ? "+977" : '+977',
                'gender': form['gender'] ? form['gender'] : '',
                'phone': form['phone'] ? form['phone'].value : '',
                'address': {
                    'country': form['country'] ? form['country'] : '',
                    'state': form['state'] ? form['state'] : '',
                    'city': form['city'] ? form['city'] : '',
                    'zipcode': form['zipcode'] ? form['zipcode'] : '',
                    'line1': form['line1'] ? form['line1'] : '',
                    'line2': form['line2'] ? form['line2'] : ''
                },
                'email': form['email'] ? form['email'].value : '',
                'guardian_name': form['age'].value.no_of_years < 18 && form['guardian_name'] ? form['guardian_name'].value : '',
                'guardian_phone': form['age'].value.no_of_years < 18 && form['guardian_phone'] ? form['guardian_phone'].value : '',
            },
            "doctor": {
                "doctor_name": form['doctor_name'],
                "doctor_id": form['doctor_id'],
                "department": form['department'],
                // "branch": "demo-branch-1",
                "account_id": sessionStorage.getItem("s3AccountId")
            },
            'patient_hid': form['patient_hid'] ? form['patient_hid'].value : '',
            'doctor_id': form['doctor_id'],
            'doctor_name': form['doctor_name'],
            'department': form['department'],
            'referred_doctor_name': form['referred_doctor_name'],
            'referred_doctor_id': form['referred_doctor_id']
        };

        if (this.state.formType === 'edit') {
            data = {
                ...data,
                'patient_id': this.props.appointmentData['patient_id']
            };
        }
        if (event !== undefined) {
            data.pay_later = event
        }

        let isSafe = true;
        if (isSafe) {
            this.setState({
                isFormLoading: true,
                isFormDisabled: true
            }, () => this.props.emitAppointmentFormData(data))
        }
    }

    checkForNoDataValue() {
        let requiredFeilds = [];
        if (!this.state.appointmentFormData['appointment_date']) {
            requiredFeilds.push('appointment_date');
        }
        if (this.state.appointmentFormData['appointment_status'] === "Re-Scheduled") {
            if (!this.state.appointmentFormData['rescheduled_appointment_slot']) {
                requiredFeilds.push('rescheduled_appointment_slot')
            }
        } else {
            let index = this.state.requiredFeilds ? this.state.requiredFeilds.indexOf('rescheduled_appointment_slot') : -1;
            if (index !== -1) {
                this.state.requiredFeilds.splice(index, 1);
                this.setState(this.state);
            }
        }

        if (this.state.appointmentFormData['phone'] && !this.state.appointmentFormData['phone'].value) {
            requiredFeilds.push('phone')
        }

        if (this.state.appointmentFormData['docter_id'] && !this.state.appointmentFormData['docter_id'].value) {
            requiredFeilds.push('docter_id')
        }


        if (this.state.appointmentFormData['age'].value.no_of_years === '' && this.state.appointmentFormData['age'].value.no_of_months === '') {
            requiredFeilds.push('age')
        }

        if (this.state.appointmentFormData['guardian_name'].hasErrors) {
            requiredFeilds.push('guardian_name')
        }

        if (this.state.appointmentFormData['guardian_phone'].hasErrors) {
            requiredFeilds.push('guardian_phone')
        }

        if (!this.state.appointmentFormData['country_code']) {
            requiredFeilds.push('country_code')
        }

        if (this.state.appointmentFormData['name'] && !this.state.appointmentFormData['name'].value) {
            requiredFeilds.push('name')
        }
        if (!this.state.appointmentFormData['appointment_slot']) {
            requiredFeilds.push('appointment_slot')
        }
        if (!this.state.appointmentFormData['referred_doctor_name']) {
            requiredFeilds.push('referred_doctor_name')
        }
        if (requiredFeilds.length > 0) {
            return true;
        }
        return false;
    }

    componentDidMount() {
        $(function () {
            $('[data-toggle="tooltip"]').tooltip()
        })
        let { appointmentFormData } = this.state;
        if (this.props.type === 'edit' && this.props.appointmentData) {
            appointmentFormData = this.autoFillForm(this.props.appointmentData);
        } else if (this.props.type === 'creat') {
            appointmentFormData = this.createLocalForm();
        }

        // this.state = { ...this.state, appointmentFormData: appointmentFormData };
        this.setState({ appointmentFormData }, () => this.checkFormValues());
        this.checkToGetSlots()

        if (!this.state.accountInfo) {
            this.getAccountInfo();
        }
        this.getCountries()
        getMasterData(sessionStorage.getItem("s3AccountId")).then(
            res => {
                if (res.status == 200) {
                    let data = res.data;
                    this.mapMasterDataToState(data);
                }
            }
        )
    }

    async getCountries() {
        let response = await onlineConsultationService.getCountries();
        this.setState({
            countries: response.data
        })

        let response2 = await onlineConsultationService.getStates("nepal");
        this.setState({
            states: response2.data
        })

    }


    async getAccountInfo() {
        try {
            let res = await onlineConsultationService.getAccountInfo(sessionStorage.getItem('s3AccountId'));
            if (res && res.data) {
                sessionStorage.setItem('accountInfo', JSON.stringify(res.data));
                this.setState({ accountInfo: res.data });
            }

        } catch (err) {

        }
    }

    mapMasterDataToState(data) {
        let { locationDropdown } = this.state;
        for (let i in data) {
            if (data[i].location) {
                locationDropdown = data[i].location;
            }
        }
        this.setState({ locationDropdown })
    }

    checkToGetSlots() {
        if (this.state.isStaff) return;
        if (this.state.showPresetSlots) {
            let docId = this.state.docIdForSlots ? this.state.docIdForSlots : this.props.appointmentData ? this.props.appointmentData['doctor_id'] : '';
            if (docId) {
                let date = this.props.type === "edit" && this.props.appointmentData && this.props.appointmentData['appointment_date'] && this.props.appointmentData['appointment_date'] !== "-" ?
                    this.props.appointmentData['appointment_date'] : new Date();
                this.getSlotData(docId, date);
            }
        }
    }

    componentWillUnmount() {
        $('[data-toggle="tooltip"]').tooltip('hide');
    }

    checkForRequiredData(name) {
        if (!this.state.requiredFeilds || this.state.requiredFeilds.length === 0) {
            return false;
        }
        if (this.state.requiredFeilds.indexOf(name) !== -1) {
            return true;
        }
        return false;
    }

    onBackButtonClick = (event) => {
        if (this.state.formType === 'edit') {
            this.props.emitBackButtonEvent('/appointments-view', this.state.appointmentFormData['appointment_date']);
        } else {
            this.props.emitBackButtonEvent('/appointments-view');
        }
    }

    clearFormFields = (event) => {
        event.preventDefault();
        this.clearFormData();
    }

    checkFormValues() {
        let requiredFeilds = this.checkForNoDataValue();
        if (!this.state.isFormLoading) {

            if (this.state.isStaff === true && this.state.appointmentFormData['doctor_id'] === "") {
                //this.state.isFormDisabled = true;
                //this.setState(this.state);
                console.log("here on doctor id condition",this.state.isFormDisabled,requiredFeilds,this.state.isFormLoading);
                this.setState({ isFormDisabled: true });
                return
            }
            if (this.state.appointmentFormData &&
                (this.state.appointmentFormData['email'] && this.state.appointmentFormData['email'].hasErrors) ||
                (this.state.appointmentFormData['phone'] && this.state.appointmentFormData['phone'].hasErrors) ||
                (this.state.appointmentFormData['age'] && this.state.appointmentFormData['age'].hasErrors) ||
                (this.state.appointmentFormData['name'] && this.state.appointmentFormData['name'].hasErrors)) {
                //this.state.isFormDisabled = true;
                //this.setState(this.state);
                this.setState({ isFormDisabled: true });
                return
            }
            console.log("requiredFeilds",this.state.isFormDisabled,requiredFeilds,this.state.isFormLoading);
            if (!requiredFeilds) {
                //this.state.isFormDisabled = false;
                //this.setState(this.state);
                this.setState({ isFormDisabled: false });
                console.log("is form disabled",this.state.isFormDisabled,requiredFeilds,this.state.isFormLoading);
                return
            }
            //this.state.isFormDisabled = true;
            //this.setState(this.state);
            this.setState({ isFormDisabled: true });
            return
        } else {
            //this.state.isFormDisabled = true;
            //this.setState(this.state);
            this.setState({ isFormDisabled: true });
        }
    }

    getEmitedSearchFiledData = (name, data) => {
        if (!data || data.length<3) {
            let { appointmentFormData } = this.state;
            appointmentFormData[name] = { ...appointmentFormData[name], isLoading: false, patientError: 'Enter atleast three character!' };
            this.setState({ appointmentFormData });
            return;
            
        }
        let { appointmentFormData } = this.state;
        appointmentFormData[name] = { ...appointmentFormData[name], isLoading: true, patientError: '' };
        this.setState({ appointmentFormData });
        this.fetchPatients(data, name)
    }

    // patient_hid

    async fetchPatients(data, name, type) {
        try {
            var patientListResponse = await patientService.getPatientsDataBySearchParams(data, name);
            this.checkPatientListResponse(patientListResponse, name, type);
        } catch (error) {
            console.log('error occured while fetching patients');
        }
    }

    checkPatientListResponse(response, name, type) {
        switch (response.type) {
            case "error":
                if (type) {
                    return;
                }
                this.setState({
                    openPatientListModal: false
                })
                let { appointmentFormData } = this.state;
                appointmentFormData[name] = { ...appointmentFormData[name], isLoading: false, patientError: 'Error Occurred!' };
                this.setState({ appointmentFormData });
                break;
            case "success":
                if (response.data && response.data.rows && response.data.rows.length === 0) {
                    if (type) {
                        this.createAppointment()
                        return;
                    }
                    let { appointmentFormData } = this.state;
                    appointmentFormData[name] = { ...appointmentFormData[name], isLoading: false, patientError: 'No Patient Found!' };
                    this.setState({
                        openPatientListModal: false,
                        patientList: response.data.rows
                    })
                } else {
                    let { appointmentFormData } = this.state;
                    let patientName = ''
                    if (appointmentFormData["patient_hid"] && appointmentFormData["patient_hid"].value) {
                        let data = response.data.rows;
                        if (data && data.length > 0) {
                            for (let index in data) {
                                if (data[index].patient_hid === appointmentFormData["patient_hid"].value) {
                                    patientName = data[index].name;
                                }
                            }
                        }
                    }

                    appointmentFormData[name] = { ...appointmentFormData[name], isLoading: false, patientError: '' };

                    this.setState({
                        show: type ? true : false,
                        alert: {
                            type: 'confirmation',
                            title: `${patientName ? patientName : (appointmentFormData["name"] && appointmentFormData["name"].value) ? appointmentFormData["name"].value : 'User'} exists on this UHID`,
                            text: "Do you want to override the record?"
                        },
                        openPatientListModal: type ? false : true,
                        patientList: response.data.rows,
                        appointmentFormData
                    })
                }
                break;
            default:
                return;
        }
    }

    onPatientListModalCloseEvent = (event) => {
        let { openPatientListModal, appointmentFormData } = this.state;
        openPatientListModal = false;
        appointmentFormData['name'] = { ...appointmentFormData['name'], readOnly: false };
        appointmentFormData['patient_hid'] = { ...appointmentFormData['patient_hid'], readOnly: false };
        this.setState({ openPatientListModal, appointmentFormData });
    }

    onOTPModalCloseEvent = (event) => {
        let { openOTPRegistrationModal } = this.state;
        openOTPRegistrationModal = false;
        this.setState({ openOTPRegistrationModal });
    }

    getSelectedPatientData = (event) => {
        let patientDetail;

        let { appointmentFormData, openPatientListModal, isPatientSelection } = this.state;
        if (event && event.value !== 'none') {
            for (let index in this.state.patientList) {
                if (this.state.patientList[index].patient_id === event.id) {
                    patientDetail = this.state.patientList[index];
                }
            }

            appointmentFormData['patient_hid'] = { ...appointmentFormData['patient_hid'], value: patientDetail.patient_hid ? patientDetail.patient_hid : '' };

            appointmentFormData['name'] = { ...appointmentFormData['name'], value: patientDetail.name ? patientDetail.name : '', readOnly: true };

            isPatientSelection = true;
            patientDetail = this.autoFillUsingPatientSearch(patientDetail);
        } else {
            appointmentFormData['name'] = { ...appointmentFormData['name'], value: '', readOnly: false };
            isPatientSelection = false;
            patientDetail = this.clearFormDataForPatient();
        }
        openPatientListModal = false;
        appointmentFormData = Object.assign({}, appointmentFormData, patientDetail);
        this.setState({ appointmentFormData, openPatientListModal, isPatientSelection }, () => this.checkFormValues());
    }

    getEmitedOtpTextChangeEvent = (event) => {
        if (event.length > 6) {
            return;
        }
        let { otp } = this.state;
        otp = event;
        this.setState({ otp, otpWrong: false });
    }

    getEmitedResendOtpButtonEvent = (event) => {
        let { email, phone } = this.state.appointmentFormData;
        this.resendOtpRequest(email ? email.value : '', phone ? phone.value : '');
    }

    async resendOtpRequest(email, mobile) {
        try {
            let response = await patientService.generateOTP(mobile, email);
            
        } catch (err) {
        }
    }

    getEmitedFinalOtpSubmit = (event) => {
        if (!this.state.otp || this.state.otp.length !== 6) {
            return;
        }
        this.verifyOtpRequest();
    }

    async verifyOtpRequest() {
        try {
            this.setState({ otploading: true });
            let response = await patientService.verifyOTP(this.state.otp, this.state.appointmentFormData['phone'].value);
            if (response && response.type === 'success' && response.data && response.data.Status) {
                this.setState({ otploading: false, openOTPRegistrationModal: false }, () => this.createAppointment());
            } else {
                this.setState({ otpWrong: true, otploading: false });
            }
        } catch (err) {
            this.setState({ otpWrong: true, otploading: false });
        }
    }

    getEmitedSlotSelectionEvent = (event, slotType) => {
        let { selectedSlot } = this.state;
        selectedSlot = event;
        this.setState({ selectedSlot, slotType });
    }

    getEmitedSlotModalCloseEvent = () => {
        let { isSlotModalOpen } = this.state;
        isSlotModalOpen = false;
        this.setState({ isSlotModalOpen });
    }

    onButtonClick = () => {
        let { isRequestConsultation, isSlotModalOpen } = this.state;
        isSlotModalOpen = true;
        this.setState({ isRequestConsultation, isSlotModalOpen });
    }

    onApptTypeModalCloseEvent = (event) => {
        let { isApptTypeModalOpen } = this.state;
        if (this.state.appointmentFormData['appointment_type']) {
            isApptTypeModalOpen = false;
            this.setState({ isApptTypeModalOpen });

        } else {
            this.props.enqueueSnackbar('Please select appointment type');
        }
    }

    setApptType = (e) => {
        let { checked, name } = e.target ? e.target : e;
        //console.log("setApptType",this.state);

        this.setState(prevState => ({
            appointmentFormData: {
                ...prevState.appointmentFormData,
                appointment_type: checked ? name : ''
            }
        }))
        let currentInstance = this;
        //console.log("drs slots missing",currentInstance);
        setTimeout(function () {
            currentInstance.getSlotData(currentInstance.state.docIdForSlots, currentInstance.state.appointmentFormData.appointment_date);
        }, 300);
    }






    onAddressSelectValueChange = async (event) => {
        let { name, value } = event.target;
        this.setState(prevState => ({
            appointmentFormData: {
                ...prevState.appointmentFormData,
                [name]: value
            }
        }))
        if (name === "country") {
            let response = await onlineConsultationService.getStates(value);
            this.setState({
                states: response.data
            })
        }
        if (name === "state") {
            let data = {
                country_id: this.state.appointmentFormData['country'], state_id: value
            }
            let response = await onlineConsultationService.getCites(data);
            this.setState({
                cities: response.data
            })
        }
    }

    render() {
        const { accountInfo, countries, states, cities, locationDropdown } = this.state
        const formattedPatientList = this.state.patientList ? ([]).concat(this.state.patientList.map(obj => ({ id: obj.patient_id, name: obj.name, phone: obj.phone, patient_hid: obj.patient_hid }))) : [];
        const capitalize = s => s && s[0].toUpperCase() + s.slice(1)

        return (<>

            <div className="card-body">
                <div className="d-flex justify-content-between">
                    <div>
                        <h4 className="card-title">{appointmentFormData.formName}</h4>
                    </div>
                    <div>
                        <button data-toggle="tooltip" data-placement="right" title="" data-original-title="Back" className="btn btn-outline-success btn-icon btn-rounded" onClick={this.onBackButtonClick.bind(this)}>
                            <i className="mdi mdi-arrow-left"></i>
                        </button>
                    </div>
                </div>

                <form className="form-sample" >
                    <p className="card-description"> Booking info </p>

                    <div className="row">
                        {/* Style Added By Mukesh 27Jan and 29Jan */}

                        {this.state.isStaff && this.state.accountInfo && this.state.accountInfo.enable_opd_flow ?
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Branch</label>
                                    <div className="col-sm-8">
                                        <select
                                            className="form-control"
                                            name="branch"
                                            style={{ textTransform: "capitalize" }}
                                            value={this.state.branch}
                                            onChange={this.onBranchSelection}>
                                            {this.state.locationDropdown.map(branch =>
                                                <option value={branch} style={{ textTransform: "capitalize" }}>{branch}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div> : <>
                                {this.state.isStaff && this.state.accountInfo && this.state.accountInfo.branches && Array.isArray(this.state.accountInfo.branches) && this.state.accountInfo.branches.length > 0 &&
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label className="col-lg-3 col-form-label">Branch</label>
                                            <div className="col-sm-8">
                                                <select
                                                    className="form-control"
                                                    name="branch"
                                                    style={{ textTransform: "capitalize" }}
                                                    value={this.state.branch}
                                                    onChange={this.onBranchSelection}>
                                                    <option>All</option>
                                                    {this.state.accountInfo.branches.map(branch =>
                                                        <option value={branch} style={{ textTransform: "capitalize" }}>{branch}</option>)}
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }




                        {(this.state.doctorList && this.state.doctorList.rows && this.state.doctorList.rows.length > 0) && this.state.isStaff &&
                            <>
                                {
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label className="col-lg-3 col-form-label" style={{ paddingTop: '5px' }}> {sessionStorage.getItem("s3AccountId") == "breathefree" ? "Educator" : "Doctor"}  <span className="text-danger" style={{ fontSize: '20px' }}>*</span></label>
                                            <div className="col-lg-8">
                                                <MultiAutocompleteComponent closeMenuOnSelect={true} isMulti={false}
                                                    optionsData={this.state.doctorList.rows.map(doc => ({ label: doc.name, value: doc.cognito_user_sub }))}
                                                    emitSelectedAutoCompleteData={this.onSelectValueChange_doc.bind(this)}></MultiAutocompleteComponent>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }

                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label" style={{ paddingTop: '5px' }}>Appointment Date<span className="text-danger" style={{ fontSize: '20px' }}>*</span></label>
                                <div className="col-lg-8">
                                    <DatePicker
                                        disabled={this.state.isAppointmentDateDisable}
                                        style={{ width: '100%' }}
                                        dateFormat="dd MMMM, yy"
                                        className="form-control"
                                        id={'appointment_date'}
                                        minDate={new Date()}

                                        placeholderText="Select Appointment Date"
                                        selected={this.state.appointmentFormData['appointment_date'] ? this.state.appointmentFormData['appointment_date'] : null}
                                        onChange={this.handleAppointmentDateChange.bind(this)}
                                    />
                                    {!!this.state.requiredFeilds &&
                                        <small className="text-danger error-text" style={{ display: this.checkForRequiredData('appointment_date') ? '' : 'none' }}>*Required Field</small>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label" style={{ paddingTop: '5px' }}>Appointment Slot<span className="text-danger" style={{ fontSize: '20px' }}>*</span>
                                </label>
                                <div className="col-lg-8">
                                    <input className="form-control" type="text" id={'appointment_slot'} disabled={this.state.isAppointmentDateDisable} value={this.state.appointmentFormData['appointment_slot']} placeholder="Select Appointment Slot" onClick={this.onButtonClick.bind(this)} />
                                    {!!this.state.requiredFeilds &&
                                        <small className="text-danger error-text" style={{ display: this.checkForRequiredData('appointment_slot') ? '' : 'none' }}>*Required Field</small>
                                    }
                                </div>
                            </div>
                        </div>

                        {this.state.isOfflineOnlineFeatureEnabled && <div className="col-md-6">
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label" style={{ paddingTop: '5px' }}>Appointment Type<span className="text-danger" style={{ fontSize: '20px' }}>*</span></label>
                                <div className="col-lg-8">
                                    <select className="form-control" name='appointment_type' value={this.state.appointmentFormData['appointment_type']}
                                        onChange={e => this.setApptType({ checked: true, name: e.target.value })}>
                                       {
                                        sessionStorage.getItem('s3AccountId') !== "breathefree" &&  <option value="offline">Face to face Visit Doctor</option>
                                       }
                                       
                                        <option value="online">Online Video Call</option>
                                    </select>
                                </div>
                            </div>
                        </div>}
                        <div className="col-md-6">
                            <div className="form-group row">
                                <label className=" col-lg-3 col-form-label">Referring  Doctor {sessionStorage.getItem('s3AccountId') === "breathefree" && <span className="text-danger" style={{ fontSize: '20px' }}>*</span>}   </label>
                                <div className="col-lg-8">
                                    <input autoComplete="nope" type="text" placeholder="Enter Doctor Name" className="form-control" name='referred_doctor_name' value={this.state.appointmentFormData['referred_doctor_name']} onChange={this.onTextChangeView} />
                                </div>
                            </div>
                        </div>
                        {this.state.accountInfo && this.state.accountInfo.institution &&
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className=" col-lg-3 col-form-label">Institution:</label>
                                    <div className="col-lg-8">
                                        <input type="text" placeholder="Enter institution" className="form-control" name='institution' value={this.state.appointmentFormData['institution']} onChange={this.onTextChangeView} />
                                    </div>
                                </div>
                            </div>
                        }
                        {this.state.isStaff && <>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className=" col-lg-3 col-form-label">Remarks</label>
                                    <div className="col-lg-8">
                                        <textarea rows="4" autoComplete="nope" type="text" placeholder="Enter Remarks" className="form-control" name='remarks' value={this.state.appointmentFormData['remarks']} onChange={this.onTextChangeView} />
                                    </div>
                                </div>
                            </div>
                            {this.state.accountInfo && this.state.accountInfo.is_copy_email &&
                                <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className=" col-lg-3 col-form-label">Copy Email to:</label>
                                        <div className="col-lg-8">
                                            <input type="text" placeholder="abc@gmail.com" className="form-control" name='copy_email' value={this.state.appointmentFormData['copy_email']} onChange={this.onTextChangeView} />
                                        </div>
                                    </div>
                                </div>
                            }
                        </>}

                        <div className="col-md-6" style={{ display: this.state.formType === "edit" ? '' : 'none' }}>
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label">Appointment Status</label>
                                <div className="col-sm-8">
                                    <select
                                        className="form-control"
                                        name="appointment_status"
                                        value={this.state.appointmentFormData['appointment_status']}
                                        disabled={this.state.isAppointmentStatusDisable}
                                        onChange={this.onSelectValueChange}>
                                        <option>Select Appointment Status</option>
                                        <option value={'Booked'}>Booked</option>
                                        <option value={'Checked-In'}>Checked-In</option>
                                        <option value={'Done'}>Done</option>
                                        <option value={'Cancelled'}>Cancelled</option>
                                        <option value={'Re-Scheduled'}>Re-Scheduled</option>
                                        <option value={'No-Show'}>No-Show</option>
                                    </select>
                                </div>

                            </div>
                        </div>

                        {this.state.isStaff && this.state.accountInfo && this.state.accountInfo.services_fees && this.state.accountInfo.services_fees.length > 0 ? (
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-lg-3 col-form-label">Services</label>
                                    <div className="col-sm-8">
                                        <select
                                            className="form-control"
                                            name="invoice_amount"
                                            value={this.state.appointmentFormData["invoice_amount"]}
                                            onChange={this.onServiceSelection}>
                                            <option>Select Service</option>
                                            {this.state.accountInfo.services_fees.map(fee =>
                                                <option value={fee.serviceCost}>{fee.serviceName}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>

                                {this.state.isStaff && this.state.feesOptions && (this.state.appointmentFormData['appointment_type'] == "online" || (this.state.appointmentType && this.state.appointmentType == 'online')) && <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-lg-3 col-form-label">Invoice Amount</label>
                                        <div className="col-sm-8">
                                            <select
                                                className="form-control"
                                                name="invoice_amount"
                                                value={this.state.appointmentFormData['invoice_amount']}
                                                onChange={this.onSelectValueChange}>
                                                <option>Select Fees</option>
                                                {this.state.feesOptions.map(fee =>
                                                    <option value={fee}>{fee}</option>)}
                                            </select>
                                        </div>

                                    </div>
                                </div>}
                            </>
                        )}
                    </div>
                    <div className="row">
                        <div className="col-md-6" style={{ display: (this.state.formType === "edit" && this.state.openRescheduledDatePicker) ? '' : 'none' }}>
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label">Rescheduled Appointment Date</label>
                                <div className="col-sm-8">
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        className="form-control"
                                        dateFormat="dd MMMM, yy"
                                        id={'rescheduled_appointment_date'}
                                        minDate={new Date()}
                                        placeholderText="Select Rescheduled Appointment Date"
                                        selected={this.state.appointmentFormData['rescheduled_appointment_date'] ? this.state.appointmentFormData['rescheduled_appointment_date'] : ''}
                                        onChange={this.handleRescheduledAppointmentDate.bind(this)}
                                    />
                                </div>

                            </div>
                        </div>
                        <div className="col-md-6" style={{ display: (this.state.formType === "edit" && this.state.openRescheduledDatePicker) ? '' : 'none' }}>
                            <div className="form-group row">
                                <label className="col-lg-3 col-form-label" style={{ paddingTop: '5px' }}>Rescheduled Appointment Slot<span className="text-danger" style={{ fontSize: '20px' }}>*</span></label>
                                <div className="col-lg-8">
                                    <input className="form-control" type="text" id={'rescheduled_appointment_slot'} value={this.state.appointmentFormData['rescheduled_appointment_slot']} placeholder="Select Rescheduled Appointment Slot" onClick={this.onButtonClick.bind(this)} />
                                    {!!this.state.requiredFeilds &&
                                        <small className="text-danger error-text" style={{ display: this.checkForRequiredData('rescheduled_appointment_slot') ? '' : 'none' }}>*Required Field</small>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>


                    {appointmentFormData && appointmentFormData.sections.map((section, idx) =>
                        <React.Fragment key={idx}>
                            <div className="row">
                                <p className="card-description col-md-2">
                                    {section.sectionName}
                                </p>
                            </div>
                            <Table accountInfo={accountInfo} rows={section.rows} uiData={appointmentFormData.uiData} dynamicValues={this.state.appointmentFormData} emitSearchFiledData={this.getEmitedSearchFiledData.bind(this)} onInputChange={this.setFormValueInState} />
                        </React.Fragment>
                    )
                    }

                    {
                        this.state.accountInfo && this.state.accountInfo.enable_opd_flow === true ? <div className="row">


                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className=" col-lg-3 col-form-label">Country:</label>
                                    <div className="col-lg-8">
                                        <select className="form-control " name="country" value={capitalize(this.state.appointmentFormData['country'])} onChange={(evt) => this.onAddressSelectValueChange(evt)}>
                                            <option value={""}>{"Choose one"}</option>
                                            {
                                                countries.map((item) => {
                                                    return <option value={item}>{item}</option>
                                                })
                                            }
                                        </select>
                                        {<small className="text-danger error-text">{this.state.appointmentFormData['country'] !== "" ? "" : "Required*"}</small>}
                                    </div>
                                </div>
                            </div>




                            <div className="col-md-6">
                                <div className="form-group  row">
                                    <label className=" col-lg-3 col-form-label">State:</label>
                                    <div className="col-lg-8">
                                        <select className="form-control " disabled={this.state.appointmentFormData['country'] === "" ? true : false} name="state" value={this.state.appointmentFormData['state']} onChange={(evt) => this.onAddressSelectValueChange(evt)}>
                                            <option value={""}>{"Choose one"}</option>
                                            {
                                                states.map((item) => {
                                                    return <option value={item}>{item}</option>
                                                })
                                            }</select>

                                        {<small className="text-danger error-text">{this.state.appointmentFormData['state'] !== "" ? "" : "Required*"}</small>}
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6 ">
                                <div className="form-group  row" >
                                    <label className=" col-lg-3 col-form-label">City:</label>
                                    <div className="col-lg-8">
                                        <select className="form-control " disabled={this.state.appointmentFormData['state'] === "" ? true : false} name="city" value={this.state.appointmentFormData['city']} onChange={(evt) => this.onAddressSelectValueChange(evt)}>
                                            <option value={""}>{"Choose one"}</option>
                                            {
                                                cities.map((item) => {
                                                    return <option value={item}>{item}</option>
                                                })
                                            }


                                        </select>
                                        {<small className="text-danger error-text">{this.state.appointmentFormData['city'] !== "" ? "" : "Required*"}</small>}
                                    </div>
                                </div>
                            </div>
                        </div> : ""
                    }


                    <div className="row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-lg-9">
                                {console.log("is form disabled in log",this.state.isFormDisabled ,this.state.isFormLoading,this.state.isFormDisabled || this.state.isFormLoading)}
                                    {
                                        
                                        !accountInfo?.allow_pay_now_or_later ? <button type="button"  className={"mr-2 "+((this.state.isFormDisabled || this.state.isFormLoading) ? 'btn btn-outline-secondary btn-rounded' : 'btn btn-success btn-rounded')} disabled={this.state.isFormDisabled || this.state.isFormLoading}
                                            onClick={() => this.onAppointmentCreateClicked()}>{this.state.formType === 'edit' ? 'Update' : 'Submit'}</button> :
                                            accountInfo.allow_pay_now_or_later === true ? <>


                                                {
                                                    this.state.appointmentFormData['appointment_type'] === "online" ? <button type="button"  className={"mr-2"+(this.state.isFormDisabled || this.state.isFormLoading) ? 'btn btn-outline-secondary btn-rounded' : 'btn btn-success btn-rounded'} onClick={() => this.onAppointmentCreateClicked(false)} disabled={this.state.isFormDisabled || this.state.isFormLoading}>Pay Now</button>
                                                        : this.state.appointmentFormData['appointment_type'] === "offline" ?
                                                            <>
                                                                <button type="button"  className={"mr-2"+(this.state.isFormDisabled || this.state.isFormLoading) ? 'btn btn-outline-secondary btn-rounded' : 'btn btn-success btn-rounded'} disabled={this.state.isFormDisabled || this.state.isFormLoading} onClick={() => this.onAppointmentCreateClicked(false)}>Pay Now</button>
                                                                <button type="button"  className={"mr-2"+(this.state.isFormDisabled || this.state.isFormLoading) ? 'btn btn-outline-secondary btn-rounded' : 'btn btn-success btn-rounded'} disabled={this.state.isFormDisabled || this.state.isFormLoading} onClick={() => this.onAppointmentCreateClicked(true)}>Pay Later</button>
                                                            </> : ""
                                                }
                                            </>
                                                : ""
                                    }


                                    {this.state.isFormLoading && <div style={{ display: 'inline-block', 'marginLeft': '30px' }}><Spinner width={50} height={50}></Spinner></div>}
                                    <h1 className="mt-4 text-danger" style={{ fontSize: '12px' }}>*Required</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            {
                this.state.isApptTypeModalOpen && this.state.isOfflineOnlineFeatureEnabled &&
                <CommonModalView hideCloseButton
                    open={this.state.isApptTypeModalOpen}
                    emitModalCloseEvent={this.onApptTypeModalCloseEvent.bind(this)}
                    title='Select appointment type'>
                    <small className="text-danger error-text">Required*</small>
                    <div className="form-group">
                        <div className="form-check">
                            <label className="form-check-label pointer-cursor d-inline">
                                <input type="radio" className="form-check-input" id="optionsRadios1" onChange={this.setApptType.bind(this)} name="online" checked={this.state.appointmentFormData['appointment_type'] === 'online'} />Online Video Call <i className="input-helper"></i></label>
                        </div>
                        {
                            sessionStorage.getItem('s3AccountId') !== "breathefree" && <div className="form-check">
                            <label className="form-check-label pointer-cursor d-inline">
                                <input type="radio" className="form-check-input" id="optionsRadios1" onChange={this.setApptType.bind(this)} name="offline" checked={this.state.appointmentFormData['appointment_type'] === 'offline'} />  Face to Face Visit Doctor <i className="input-helper"></i></label>
                        </div>
                        }
                        
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-success" disabled={!this.state.appointmentFormData['appointment_type']} onClick={this.onApptTypeModalCloseEvent.bind(this)}>Continue</button>
                    </div>
                </CommonModalView>
            }
            {this.state.isSlotModalOpen &&
                <TimeSlot
                    slotData={this.state.slotData}
                    emitSlotModalCloseEvent={this.getEmitedSlotModalCloseEvent.bind(this)}
                    selectedSlot={this.state.selectedSlot}
                    date={this.state.appointmentFormData ? this.state.appointmentFormData['appointment_date'] : ''}
                    isSlotLoading={this.state.isSlotLoading}
                    showPresetSlots={this.state.showPresetSlots}
                    selectedDoctor={{ doctor_name: this.state.appointmentFormData ? this.state.appointmentFormData['doctor_name'] : '' }}
                    emitSlotSelectionConfirmation={this.emitSlotSelectionConfirmation.bind(this)}
                    emitSlotSelectionEvent={this.getEmitedSlotSelectionEvent.bind(this)}
                    showClock={this.state.showClock}
                    toggleClock={() => this.setState({ showClock: !this.state.showClock })}
                    open={this.state.isSlotModalOpen}
                    emitTimeSlotData={this.getEmitedTimeSlotData.bind(this)}>
                </TimeSlot>
            }
            {
                this.state.openPatientListModal &&
                <CommonModalView open={this.state.openPatientListModal} emitModalCloseEvent={this.onPatientListModalCloseEvent.bind(this)} title={'Select Patient'}>
                    {/* <p>{JSON.stringify(formattedPatientList)}</p> */}
                    <PatientTableViewComponent formattedPatientList={formattedPatientList} emitDataOnPatientSelect={this.getSelectedPatientData.bind(this)}></PatientTableViewComponent>
                </CommonModalView>
            }
            {
                this.state.openOTPRegistrationModal &&
                <CommonModalView open={this.state.openOTPRegistrationModal} emitModalCloseEvent={this.onOTPModalCloseEvent.bind(this)} title={'Verify OTP'}>
                    <OTPVerification otp={this.state.otp} otpWrong={this.state.otpWrong} otploading={this.state.otploading} patientDetail={{ email: this.state.appointmentFormData.email.value, phone: this.state.appointmentFormData.phone.value, country_code: this.state.appointmentFormData.country_code }}
                        emitOtpTextChangeEvent={this.getEmitedOtpTextChangeEvent.bind(this)}
                        emitResendOtpButtonEvent={this.getEmitedResendOtpButtonEvent.bind(this)}
                        emitFinalOtpSubmit={this.getEmitedFinalOtpSubmit.bind(this)}
                    />
                </CommonModalView>
            }
            {/* <a id="settings-trigger" onClick={this.clearFormData.bind(this)} data-toggle="tooltip" data-placement="right" title="" data-original-title="Clear Form" style={{ cursor: 'pointer' }}><i className="mdi mdi-format-paint"></i></a> */}
            {!this.state.isFormDisabled && accountInfo.allow_pay_now_or_later === false ? <a id="settings-trigger" onClick={this.onAppointmentCreateClicked.bind(this)} data-toggle="tooltip" data-placement="right" title="Save Form" data-original-title="Save Form" style={{ cursor: 'pointer' }}>{this.state.isFormLoading ? <Spinner width={20} height={20}></Spinner> : <i className="mdi mdi-content-save"></i>}</a> : ""}
            {this.state.show &&
                <SweetAlertComponent show={this.state.show} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} onCancel={this.onAlertClose.bind(this)} sweetAlertClose={this.sweetAlertClose} confirmBtnText={"Yes, override it."}></SweetAlertComponent>
            }

        </>);
    }
}

export default withSnackbar(AppointmentFormComponent);


