import React from "react";
import HeaderContainer from '../../Common/Header/HeaderContainer';
import AppointmentFormComponent from './CreateComponent/AppointmentFormComponent';
import appointmentService from '../../Services/appointment.service';
// common component import
import SweetAlertComponent from '../../Common/Alert/SweetAlertComponent';
import { Helmet } from "react-helmet";
class AppointmentsCreateContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: '',
            queryData: this.props.location.search !== '' ? JSON.parse('{"' + decodeURI(this.props.location.search.substr(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : '',
            appointmentData: null,
            settingsList: null,
            appointmentDate: new Date(),
            isFormLoading: false,
            isFormDisabled: true,
            alert: {
                type: '',
                title: '',
                text: ''
            }
        }
    }

    componentWillMount() {
        this.setState({
            type: (this.state.queryData && this.state.queryData.id) ? "edit" : "creat"
        }, () => this.checkIfIdPresentInQueryData());
    }

    checkIfIdPresentInQueryData() {
        if (this.state.queryData && this.state.queryData.id) {
            this.fetchAppointmentDetailById(this.state.queryData.id)
        } else {
            this.state.appointmentData = null;
            this.setState(this.state);
            this.fetchSettingList();
        }
    }

    getEmitedAppointmentFormData = (event) => {
        if (this.state.type === "creat") {
            this.setState({
                isFormLoading: true,
                isFormDisabled: true,
            }, () => this.actionToCreateNewAppointment(event))
        } else {
            this.setState({
                isFormLoading: true,
                isFormDisabled: true,
            }, () => this.actionToEditAppointment(event))
        }
    }

    async fetchAppointmentDetailById(event) {
        try {
            var response = await appointmentService.getAppointmentById(event);
            this.checkAppointmentResponseByIdData(response);
        } catch (error) {
            console.log('error');
        }
    }

    async fetchSettingList(event) {
        try {
            const settingsResponse = await appointmentService.fetchSettings(event);
            this.checkSettingsResponse(settingsResponse);
        } catch (error) {
            console.log('error');
        }
    }

    async actionToEditAppointment(event) {
        try {
            var response = await appointmentService.updateAppointment(event, this.state.queryData);
            this.checkAppointmentResponseData(response);
        } catch (error) {
            if (error.response && error.response.status && error.response.status === 400) {
                this.state.isFormLoading = false;
                this.state.isFormDisabled = false;
                this.state.show = true;
                this.state.alert.type = 'error';
                this.state.alert.title = 'Error';
                this.state.alert.text = error.response.data.error;
                this.setState(this.state);
            }
            console.log('error');
        }
    }

    async actionToCreateNewAppointment(event) {
        try {
            var response = await appointmentService.createAppointment(event);
            this.checkAppointmentResponseData(response);
        } catch (error) {
            this.setState({
                isFormLoading: false,
                isFormDisabled: false
            })
            console.log('error');
        }
    }


    checkSettingsResponse(response) {
        switch (response.type) {
            case "error":
                this.state.settingsList = [];
                this.setState(this.state);
                break;
            case "success":
                this.state.settingsList = response.data ? response.data : [];
                this.setState(this.state);
                break;
        }
    }

    checkAppointmentResponseByIdData(response) {
        switch (response.type) {
            case "error":
                this.state.show = true;
                this.state.alert.type = 'error';
                this.state.alert.title = 'Error';
                this.state.alert.text = response.message ? response.message : 'Error Occures While Getting Appointment Data';
                this.setState(this.state);
                break;
            case "success":
                this.state.appointmentData = response.data;
                this.setState(this.state);
                break;
        }
    }

    checkAppointmentResponseData(response) {
        switch (response.type) {
            case "error":
                this.state.isFormLoading = false;
                this.state.isFormDisabled = false;
                this.state.show = true;
                this.state.alert.type = 'error';
                this.state.alert.title = 'Error';
                this.state.alert.text = response.message ? response.message : `Error Occures While ${this.state.type}ing Appointment`;
                this.setState(this.state);
                break;
            case "success":
                this.state.isFormLoading = false;
                this.state.isFormDisabled = false;
                this.state.show = true;
                this.state.alert.type = 'success';
                this.state.alert.title = 'Success';
                this.state.alert.text = `Success In  ${this.state.type}ing Appointment`;
                this.setState(this.state);
                break;
        }
    }

    sweetAlertClose = (event) => {
        this.setState({ show: false });
        this.redirectToAppointmentList('/appointments-view')
    }

    getEmitedBackButtonEvent = (event, appointmentDate) => {
        if (appointmentDate) {
            this.props.history.push({
                pathname: event,
                state: { appointmentDate: appointmentDate }
            });
        } else {
            this.props.history.push({
                pathname: event
            });
        }
    }

    redirectToAppointmentList(event) {
        if (this.state.alert.type === 'error') {
            return;
        }
        this.props.history.push({
            pathname: event,
            state: { appointmentDate: this.state.appointmentDate }
        });
    }

    getEmitedAppointmentDate = (event) => {
        this.state.appointmentDate = event;
        this.setState(this.state);
    }

    render() {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Appointment Create</title>
                </Helmet>
                <div className="container-fluid page-body-wrapper">
                    <HeaderContainer {...this.props}></HeaderContainer>
                    <AppointmentFormComponent
                        isFormLoading={this.state.isFormLoading}
                        type={this.state.type}
                        isFormDisabled={this.state.isFormDisabled}
                        appointmentData={this.state.appointmentData}
                        settingsList={this.state.settingsList}
                        emitAppointmentFormData={this.getEmitedAppointmentFormData.bind(this)}
                        emitAppointmentDate={this.getEmitedAppointmentDate.bind(this)}
                        emitBackButtonEvent={this.getEmitedBackButtonEvent.bind(this)}></AppointmentFormComponent>
                    {this.state.show &&
                        <SweetAlertComponent show={this.state.show} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.sweetAlertClose}></SweetAlertComponent>
                    }
                </div>
            </>
        );
    }
}

export default AppointmentsCreateContainer;
