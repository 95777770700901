import axios from 'axios'
import ROOT_URL from './getRootUrl';
import userAuth from '../Authentication/authActions';

const HTTP_STATUS_CODES = {
    '200': 'OK',
    '201': 'Created',
    '202': 'Accepted',
    '203': 'Non-Authoritative Information',
    '204': 'No Content',
    '205': 'Reset Content',
    '206': 'Partial Content',
    '300': 'Multiple Choices',
    '301': 'Moved Permanently',
    '302': 'Found',
    '303': 'See Other',
    '304': 'Not Modified',
    '305': 'Use Proxy',
    '307': 'Temporary Redirect',
    '400': 'Bad Request',
    '401': 'Unauthorized',
    '402': 'Payment Required',
    '403': 'Forbidden',
    '404': 'Not Found',
    '405': 'Method Not Allowed',
    '406': 'Not Acceptable',
    '407': 'Proxy Authentication Required',
    '408': 'Request Timeout',
    '409': 'Conflict',
    '410': 'Gone',
    '411': 'Length Required',
    '412': 'Precondition Failed',
    '413': 'Request Entity Too Large',
    '414': 'Request-URI Too Long',
    '415': 'Unsupported Media Type',
    '416': 'Requested Range Not Satisfiable',
    '417': 'Expectation Failed',
    '500': 'Internal Server Error',
    '501': 'Not Implemented',
    '502': 'Bad Gateway',
    '503': 'Service Unavailable',
    '504': 'Gateway Timeout',
    '505': 'HTTP Version Not Supported'
};

const client = axios.create({
    baseURL: ROOT_URL.getRootUrl(),
    headers: {
        "Authorization": "Bearer " + userAuth.getToken()
        // "x-platform": "web"
    }
});

const client2 = axios.create({
    headers: {
        'Authorization': 'Bearer ' + userAuth.getToken()
    }
});

const newUser = axios.create({
    baseURL: ROOT_URL.getNewUsersRootUrl(),
    headers: {
        'Authorization': 'Bearer ' + userAuth.getToken()
    }
});

const newPatient = axios.create({
    baseURL: ROOT_URL.getNewPatientRootUrl(),
    headers: {
        'Authorization': 'Bearer ' + userAuth.getToken()
    }
});

const request = function (options, baseUrlType) {
    const errorHandlingForApiResponse = function (response) {
        // var status = response.data.status;
        if (response.status === 200) {
            return {
                type: 'success',
                title: HTTP_STATUS_CODES[response.status],
                message: 'Success',
                data: response.data
            }
        } else if (response.status === 400) {
            return {
                type: 'error',
                title: 'Error',
                message: response.data.error,
                data: null
            }
        } else if (response.status === 401 || response.status === 403) {
            userAuth.updateAuthHeaderWhenExpire();
            return;
            // return {
            //     type: 'error',
            //     title: 'Error',
            //     message: 'Request Failed',
            //     data: null
            // }
        } else if (response.status === 404) {
            setTimeout(function () {
                window.location = window.location.origin + '/404'
            }, 100);
        } else if (response.status === 502 || response.status === 500 || response.status === 501) {
            return {
                type: 'error',
                title: HTTP_STATUS_CODES[response.status],
                message: 'Request Failed',
                data: null
            }
        } else {
            return {
                type: 'error',
                title: 'Error',
                message: 'Request Failed',
                data: null
            }
        }
    }

    const onErrorResponse = function (error) {
        console.log(error);
        if (!error.response) {
            userAuth.updateAuthHeaderWhenExpire();
            return;
        }
        if (error.response && error.response.status === 400) {
            return {
                type: 'error',
                title: 'Error',
                message: error.response.data.error,
                data: null
            }
        } else if (error.response && error.response.status === 401) {
            userAuth.updateAuthHeaderWhenExpire();
            return;
        } else {
            return {
                type: 'error',
                title: 'Error',
                message: 'Request Failed',
                data: null
            }
        }
    }
    if (baseUrlType && baseUrlType === "appointment") {
        return client2(options)
            .then(errorHandlingForApiResponse)
            .catch(onErrorResponse)

    } else if (baseUrlType === "users") {
        return newUser(options)
            .then(errorHandlingForApiResponse)
            .catch(onErrorResponse)
    } else if (baseUrlType === "patients") {
        return newPatient(options)
            .then(errorHandlingForApiResponse)
            .catch(onErrorResponse)
    } else {
        return client(options)
            .then(errorHandlingForApiResponse)
            .catch(onErrorResponse)
    }

}

export default request;