import React from 'react';

function AccountActions(props) {

    const onAccountEditEvent = () => {
        props.history.push('/edit-account?id=' + props.accountData.account_id, { accountData: props.accountData });
    }

    const onAddNewUserAddButtonClicked = () => {
        props.history.push(`/create-account-user?id=${props.accountData.account_id}&name=${props.accountData.account_name}`, { accountData: props.accountData })
    }

    const onAddTemplateButtonClicked = () => {
        props.history.push(`/upload-prescription?accountId=${props.accountData.account_id}&settinglevel=account&templatetype=prescription_form`, {accountData: props.accountData})
    }

    const onCreateTemplateButtonClicked = () => {
        props.history.push(`/form-builder?accountId=${props.accountData.account_id}`, {accountData: props.accountData});
    }

    const onViewAccountUserList = () => {
        props.history.push(`/account-users?accountId=${props.accountData.account_id}`, { accountData: props.accountData })
    }

    const onPaymentsClick = () => {
        props.history.push(`/payment-status`, { providerName: props.accountData.account_id, accountData: props.accountData });
    }

    return <>
        <div className="dropdown" style={props.style}>
            <button className="btn btn-success btn-sm dropdown-toggle" type="button" id="dropdownMenuSizeButton3" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> Actions </button>
            <div className="dropdown-menu" aria-labelledby="dropdownMenuSizeButton3" x-placement="bottom-end" style={{ position: "absolute", willChange: "transform", top: "0px", left: "0px", transform: "translate3d(0px, 32px, 0px)" }}>
                {!props.editAccount && <><a className="dropdown-item pointer-cursor " onClick={onAccountEditEvent.bind(this)}><i className="mdi mdi-pencil-box btn-icon-append" ></i>  Edit Account</a>
                    <div className="dropdown-divider" /></>}
                {!props.userList && <><a className="dropdown-item pointer-cursor" onClick={onViewAccountUserList.bind(this)}><i className="mdi mdi-view-list btn-icon-append"  ></i>  View User List</a>
                    <div className="dropdown-divider" /></>}
                {!props.payments && <><a className="dropdown-item pointer-cursor" onClick={onPaymentsClick.bind(this)}><i className="mdi mdi-currency-inr btn-icon-append"  ></i>  View Payments</a>
                    <div className="dropdown-divider" /></>}
                {!props.addNewUser && <><a className="dropdown-item pointer-cursor" onClick={onAddNewUserAddButtonClicked.bind(this)}><i className="mdi mdi-account-multiple-plus btn-icon-append"></i>  Add New User</a>
                    <div className="dropdown-divider" /></>}
                {!props.uploadTemplate && <><a className="dropdown-item pointer-cursor" onClick={onAddTemplateButtonClicked.bind(this)}><i className="mdi  mdi-arrow-expand-down btn-icon-append"></i>  Upload Templates</a>
                    <div className="dropdown-divider" /></>}
                {!props.createTemplate && <a className="dropdown-item pointer-cursor" onClick={onCreateTemplateButtonClicked.bind(this)} ><i className="mdi  mdi-folder-plus btn-icon-append"></i>  Create Templates</a>}
            </div>
        </div>
    </>
}

export default AccountActions;