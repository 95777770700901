import accountService from "../../Services/account.settings";
import { ThemeFileEnum } from '../../providerEnum';
const $ = window["jQuery"];
function formatDataWithType(type) {
    return;
}

function getFormatedDOBData(dateob) {
    var sdt = new Date(dateob);
    var difdt = new Date(new Date() - sdt);
    let yr = (difdt.toISOString().slice(0, 4) - 1970);
    let mnths = (difdt.getMonth());
    let days = (difdt.getDate() - 1);
    if (yr < 3) {
        let age = [];
        if (yr) age.push(yr + " Yrs");
        if (mnths) age.push(mnths + " Months");
        if (days) age.push(days + " Days");
        return age.join(', ');
    } else {
        return yr + " Yrs";
    }
}

function formatTimeDetails(time) {

    if (time.indexOf(":") == -1) {
        return
    }
    let splitTime = time.split(":");
    let hours = parseInt(splitTime[0]);
    let minutes = parseInt(splitTime[1]);
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let hrs = hours % 12;
    hrs = hrs ? hrs : 12;
    hrs = hrs < 10 ? '0' + hrs : hrs;
    let ampm = hours >= 12 ? 'PM' : 'AM';
    return hrs + ':' + minutes + ' ' + ampm
}

function formatAMPM(date, onlyTime) {
    date = new Date(date);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    month = month >= 10 ? month : `0${month}`;
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = onlyTime ? hours + ':' + minutes + ' ' + ampm : year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

function getFormatedDateWithTimeStamp(data) {
    if (data.pres_formatted_date) {
        return data.pres_formatted_date;
    } else {
        return data.created_at
    }
    // let time  = data.created;
    // let u = new Date(time * 1000);
    // let dateString = u.toDateString();

    // let timeStr = ', ' + ('0' + u.getUTCHours()).slice(-2) +
    //     ':' + ('0' + u.getUTCMinutes()).slice(-2)
    // return dateString + timeStr;
};


function calculatePatientAge(patientDetail) {
    if (patientDetail['date_of_birth']) {
        return getFormatedDOBData(patientDetail['date_of_birth'])
    } else if (patientDetail['age']) {
        let yr = patientDetail['age']['no_of_years'];
        let mnths = patientDetail['age']['no_of_months'];
        let days = patientDetail['age']['no_of_days'];
        if (yr < 3) {
            let age = [];
            if (yr) age.push(yr + " Yrs");
            if (mnths) age.push(mnths + " Months");
            if (days) age.push(days + " Days");
            return age.join(', ');
        } else {
            return yr + " Yrs";
        }
    } else if (patientDetail["year_of_birth"]) {
        let year = (new Date().getFullYear()) - patientDetail["year_of_birth"];
        return year + " Yrs";
    } else {
        return ''
    }
}

function getFormatedTime(date) {
    if (!date) return;
    let dateStr = new Date(date).toLocaleTimeString([], {timeStyle: 'short'});
    //console.log("getFormatedTime",dateStr);
    /*
     fixed by keshav pandit(keshav@thb.co.in)
     if you found any bug in this please inform me also because i am also using this
    */
    return dateStr;
    if (dateStr.indexOf(":00 PM") !== -1) {
        return dateStr.replace(":00 PM", " PM")
    } else if (dateStr.indexOf(":00 AM") !== -1) {
        return dateStr.replace(":00 PM", " AM")
    }
}

function removeSpecialCharFromString(str) {
    return str.replace(/[^\w\s]/gi, '')
}

function isValidDate(date) {
    if (!date) {
        return false;
    }
    let d = new Date(date);
    return d instanceof Date && !isNaN(d);
}

function getNewFormatedDate(date, pref) {
    if (!date) {
        return
    }
    let formatedDate = new Date(date);
    if (formatedDate instanceof Date) {
        var startDate = formatedDate;
        var sdate = startDate.getDate();
        var smonth = startDate.getMonth() + 1;
        var syear = startDate.getFullYear();
        if (sdate < 10) {
            sdate = '0' + sdate;
        }
        if (smonth < 10) {
            smonth = '0' + smonth;
        }

        return sdate + "-" + (smonth) + '-' + syear;
    }
    return date;
}

function getTimeFormated(date) {
    if (!date) {
        return
    }
    let formatedDate = new Date(date);
    if (formatedDate instanceof Date) {
        var startDate = formatedDate;
        var month = startDate.getMonth() + 1;
        month = month >= 10 ? month : `0${month}`;
        var day = startDate.getDate();
        var hours = startDate.getHours();
        var minutes = startDate.getMinutes();

        return hours + ":" + (minutes);
    }
    return '';

}

function getFormatedMonthYear(date, pref) {
    
    if (!date) {
        return
    }
    if (date instanceof Date) {
        var startDate = date;
        var sdate = startDate.getDate();
        var smonth = startDate.getMonth() + 1;
        var syear = startDate.getFullYear();
        if (sdate < 10) {
            sdate = '0' + sdate;
        }
        if (smonth < 10) {
            smonth = '0' + smonth;
        }

        return syear + "-" + (smonth)
    }
    return date;
}

function getFormatedDate(date, pref) {
    if (!date) {
        return
    }
    if (date instanceof Date) {
        var startDate = date;
        var sdate = startDate.getDate();
        var smonth = startDate.getMonth() + 1;
        var syear = startDate.getFullYear();
        if (sdate < 10) {
            sdate = '0' + sdate;
        }
        if (smonth < 10) {
            smonth = '0' + smonth;
        }

        return (pref === "date") ? sdate + "-" + (smonth) + '-' + syear : syear + "-" + (smonth) + '-' + sdate
    }
    return date;
}

function getLongFormatedDate(date) {
    if (!date) {
        return
    }
    if (date instanceof Date) {
        return date.toLocaleDateString('en-US', {
            day: '2-digit',
            month: 'short',
            year: 'numeric'
        });
    }
}

function getAge(date) {
    if (!date) {
        return
    }
    var diff_ms = Date.now() - date.getTime();
    var age_dt = new Date(diff_ms);
    let age = Math.abs(age_dt.getUTCFullYear() - 1970);
    if (!age) return '0';
    return age;
}

function getDateValueByApiFormat(date) {
    if (!date) {
        return;
    }
    if (date.indexOf('/') === -1) {
        return date;
    }
    let dateArray = date.split("/");
    // year-month-date
    return dateArray[2] + "-" + dateArray[0] + "-" + dateArray[1];
}

function transform_yyyy_MM_dd_To_dd_MM_yyyy(date) {
    if (!date) {
        return;
    }

    let dateArray = date.indexOf('-') !== 0 ? date.split("-") : date.split('/');
    // date-month-year
    return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
}

function formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

function checkHospConsult() {
    let pathname = window.location.pathname
    return pathname && (pathname.includes("/ipd") || pathname.includes("/opd"))
}

function formatDateValueToMonthAndYear(date) {
    
    if (!date) {
        return;
    }
    if (date.indexOf('-') === -1) {
        return date;
    }
    let dateArray = date.split("-");
    return dateArray[0] + "-" + dateArray[1];
}

function checkIfFeatureVaccination(field = null) {
    let userRole = sessionStorage.getItem('userRole');
    if (field && ["custom_filter", "create_invoice", "stat", "cancel_dropdown"].find(f => field == f) && userRole.indexOf("f:ipd_opd") !== -1) {
        return false
    }
    if (userRole.indexOf("f:vaccination") !== -1) {
        return true;
    }
    return false
}

function formatDateValueByDash(date) {
    if (!date) {
        return;
    }
    if (date.indexOf('-') === -1) {
        return date;
    }
    let dateArray = date.split("-");
    return dateArray[2] + "/" + dateArray[1] + "/" + dateArray[0];
}

function debounce(func, wait, immediate) {
    let timeout;
    return function () {
        const context = this;
        const args = arguments;
        const later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
}

function ifValuedExistInArray(value, array) {
    if (value != null && array.indexOf(value) >= 0) {
        return true;
    }
    return false;
}

function getStatusViewData(text) {
    let data = text.toLowerCase()
    if (data.indexOf('done') !== -1) {
        return `<label className="badge badge-success">${data.toUpperCase()}</label> `
    } else if (data.indexOf('booked') !== -1) {
        return `<label className="badge badge-warning">${data.toUpperCase()}</label>`
    } else if (data.indexOf('cancelled') !== -1) {
        return ` <label className="badge badge-danger">${data.toUpperCase()}</label>`
    } else if (data.indexOf('re-scheduled') !== -1) {
        return ` <label className="badge badge-info">${data.toUpperCase()}</label>`
    } else if (data.indexOf('checked-in') !== -1) {
        return ` <label className="badge badge-primary">${data.toUpperCase()}</label>`
    } else if (data.indexOf('consulting') !== -1) {
        return ` <label className="badge badge-gradient-blue">${data.toUpperCase()}</label>`
    } else {
        return ` <label className="badge badge-gradient-default">${data.toUpperCase()}</label>`
    }
}

// function convertSecToMinutes(seconds) {
//     let time = seconds;
//     time = (seconds / 60 / 60).toFixed(2);
//     time = `${time}`.split('.');
//     if (time[0] != 0) {
//         time = `${time[0]} Hour ${time[1]} Minute`;
//     } else {
//         time = `${time[1]} Minute`;
//     }
//     return time;
// }

function scrollTo(element, to, duration) {
    if (duration < 0) return;
    const difference = to - element.scrollTop;
    const perTick = difference / duration * 10;

    setTimeout(() => {
        element.scrollTop += perTick;
        scrollTo(element, to, duration - 10);
    }, 10);
}

function validateEmail(email) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) === false) {
        return false;
    }
    return true;
}

function get_time_difference(earlierDate, laterDate) {
    var oDiff = new Object();


    var nTotalDiff = laterDate - earlierDate;

    oDiff.days = Math.floor(nTotalDiff / 1000 / 60 / 60 / 24);
    nTotalDiff -= oDiff.days * 1000 * 60 * 60 * 24;

    oDiff.hours = Math.floor(nTotalDiff / 1000 / 60 / 60);
    nTotalDiff -= oDiff.hours * 1000 * 60 * 60;

    oDiff.minutes = Math.floor(nTotalDiff / 1000 / 60);
    nTotalDiff -= oDiff.minutes * 1000 * 60;

    oDiff.seconds = Math.floor(nTotalDiff / 1000);
    var hourtext = '00';
    if (oDiff.days > 0) {
        oDiff.hours += oDiff.days * 24
        oDiff.days = 0
    }
    if (oDiff.hours > 0) {
        hourtext = String(oDiff.hours);
    }
    if (hourtext.length == 1) {
        hourtext = '0' + hourtext
    };

    //  Format Minutes
    var mintext = '00';
    if (oDiff.minutes > 0) {
        mintext = String(oDiff.minutes);
    }
    if (mintext.length == 1) {
        mintext = '0' + mintext
    };

    //  Format Seconds
    var sectext = '00';
    if (oDiff.seconds > 0) {
        sectext = String(oDiff.seconds);
    }
    if (sectext.length == 1) {
        sectext = '0' + sectext
    };

    //  Set Duration
    var sDuration = hourtext + ':' + mintext + ':' + sectext;
    oDiff.duration = sDuration;
    //  -------------------------------------------------------------------  //

    return oDiff;
}

//no special characters must be allowed
function validateName(name) {
    var reg = /^([a-zA-Z\s]*)$/;
    if (reg.test(name) === false) {
        return false;
    }
    return true;
}

function validateNamePlusUHID(name) {
    var reg = /^([a-zA-Z0-9\s]*)$/;
    if (reg.test(name) === false) {
        return false;
    }
    return true;
}

function validateMobile(phone) {
    var val = phone
    if (/^\d{10}$/.test(val)) {
        return true
    } else {
        return false
    }
}

function getDateDifference(start, end) {
    var date1 = new Date(start);
    var date2 = new Date(end);
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days;
}

function validatePatientMobile(countryCode, phone) {
    if (countryCode.indexOf("+") !== -1) {
        let country_code = countryCode.replace("+", "");
        let totalCount = parseInt(country_code.length) + 10
        var val = phone
        if (countryCode.indexOf("977") !== -1) {
            if (totalCount === phone.length) {
                return true
            }
        } else {
            if (phone.length > (parseInt(country_code.length) + 8)) {
                return true
            }
        }
        return false

    } else {
        let totalCount = parseInt(countryCode.length) + 10
        var val = phone
        // if (totalCount === phone.length) {
        //     return true
        // }
        if (countryCode.indexOf("977") !== -1) {
            if (totalCount === phone.length) {
                return true
            }
        } else {
            if (phone.length > (parseInt(countryCode.length) + 5)) {
                return true
            }
        }
        return false
    }

}

function validateAge(phone) {
    var val = phone
    if (/^[0-9]*$/.test(val)) {
        return true
    } else {
        return false
    }
}

function getRoleData(role) {
    if (!role) {
        return '';
    }
    if (role.indexOf(",") !== -1) {
        let roleArr = role.split(',')
        let str = '';
        for (let index in roleArr) {
            if (roleArr[index].indexOf('r:') !== -1) {
                str = str + roleArr[index] + `${(roleArr.length - 1) == index ? '' : ','}`
            }
        }
        return str;
    }
}

// both dates in milliseconds
function getDifferenceByDays(date1, date2) {
    return (date1 - date2) / (24 * 60 * 60 * 1000);
}

function getMaskedEmail(email) {
    let maskid = "";
    let prefix = email.substring(0, email.lastIndexOf("@"));
    let postfix = email.substring(email.lastIndexOf("@"));

    for (let i = 0; i < prefix.length; i++) {
        if (i == 0 || i == prefix.length - 1) {
            maskid = maskid + prefix[i].toString();
        } else {
            maskid = maskid + "*";
        }
    }
    return maskid + postfix;
}

function getMaskedPhone(phone) {
    if (!phone || phone.length <= 4) return '';
    let maskid = "";
    let prefix = phone.substring(0, phone.length - 4);
    let postfix = phone.substring(phone.length - 4);

    for (let i = 0; i < prefix.length; i++) {
        maskid = maskid + "*";
    }
    return maskid + postfix;
}

function toTitleCase(str) {
    if (!str) return '';
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

function TDate(created) {
    let currentTimeStamp = Date.now();
    return (currentTimeStamp - (created * 1000)) < 1 * 24 * 60 * 60 * 1000;
}

function searchFor(toSearch, objects) {

    var results = [];
    toSearch = trimString(toSearch.toLowerCase()); // trim it
    for (var i = 0; i < objects.length; i++) {
        for (var key in objects[i]) {
            if (objects[i][key] && objects[i][key].toString().toLowerCase().indexOf(toSearch) != -1) {
                if (!itemExists(results, objects[i])) results.push(objects[i]);
            }
        }
    }
    return results
}

function trimString(s) {
    var l = 0,
        r = s.length - 1;
    while (l < s.length && s[l] == ' ') l++;
    while (r > l && s[r] == ' ') r -= 1;
    return s.substring(l, r + 1);
}

function itemExists(haystack, needle) {
    for (var i = 0; i < haystack.length; i++)
        if (compareObjects(haystack[i], needle)) return true;
    return false;
}

function compareObjects(o1, o2) {
    var k = '';
    for (k in o1)
        if (o1[k] != o2[k]) return false;
    for (k in o2)
        if (o1[k] != o2[k]) return false;
    return true;
}

function sortListing(data, sortBy, desc) {
    if (desc) {
        data = data.sort((a, b) => (a[sortBy].toString().toLowerCase() < b[sortBy].toString().toLowerCase()) ? 1 : ((b[sortBy].toString().toLowerCase() < a[sortBy].toString().toLowerCase() ? -1 : 0)));
    } else {
        data = data.sort((a, b) => (a[sortBy].toString().toLowerCase() > b[sortBy].toString().toLowerCase()) ? 1 : ((b[sortBy].toString().toLowerCase() > a[sortBy].toString().toLowerCase()) ? -1 : 0));
    }
    
    return data;
}

let formatViewType = (data) => {
    if (data.includes("range")) {
        let dataArr = data.split("$$");
        data = `${dataArr[1]} to ${dataArr[2]}`
    }
    return data;
}

/**
 * 
 * @param {string} keyName : Name of key to find
 * @param {string} type : object|array|string|number
 * @param {string} storage :local|session
 */
let getValueFromStorage = (keyName, type, storage = "session") => {
    let val = null;
    if (storage == "session") {
        val = sessionStorage.getItem(keyName)
    } else {
        if (storage == "session")
            val = sessionStorage.getItem(keyName)
    }
    switch (type) {
        case "object":
            return val ? JSON.parse(val) : null;
        case "array":
            return val ? val.split(",") : null;
        default:
            return val;
    }
}

//++++ Get account list functions start+++
let checkAccountListResponseData = (response) => {
    if (!response) {
        this.onError(response);
        return;
    }
    switch (response.type) {
        case "error":
            return []
        case "success":
            return response.data ? response.data.rows : []
        default:
            return;
    }
}
let formatAccountList = (accountList) => {
    let formattedAccountList = []
    accountList.forEach(element => {
        let obj = {
            key: element.account_id,
            value: element.account_name
        }
        formattedAccountList.push(obj)
    });
    return formattedAccountList
}

let fetchAllAccountData = async (queryParams) => {
    try {
        var AccountListResponse = await accountService.getAllAccountData(queryParams);
        return formatAccountList(checkAccountListResponseData(AccountListResponse));
    } catch (error) {
        console.log('error', error);
        return {
            loading: false,
            paginationDisable: false,
            Error: true
        }
    }
}
//++++ Get account list functions end+++++

let dataUsers = ["r:dataadmin", "r:dataops", "r:dataqc"]

let isAdminAndDataUser = (customRole) => {
    let customRoleSet = new Set(customRole.split(","));
    let roleIntersection = dataUsers.filter(d => customRoleSet.has(d))
    if (customRoleSet.has("r:admin") && roleIntersection.length > 0) {
        return roleIntersection[0]
    }
    return false

}

let getAdminAndDataUserRoute = () => {
    let nextRoute = "";
    switch (sessionStorage.getItem("active_view")) {
        case "r:admin":
            nextRoute = "/user-view";
            break;
        case "r:dataadmin":
            nextRoute = "/qc-users-list";
            break;
        default:
            nextRoute = "/qc-analyst";

    }
    return nextRoute;
}

function isUatEnvir() {
        let apiStage = (window.location.hostname.indexOf('uatclinytics') !== -1||window.location.hostname.indexOf('localhost') !== -1) ? true : false;
        return apiStage
}
function setThemeData(accInfo) {

    if (accInfo) {
        if (!accInfo.theme || (accInfo.theme && accInfo.theme === "default")) {
            if (accInfo.theme) {
                $(`link[src="${ThemeFileEnum[accInfo.theme]}"]`).remove();
            }
            return;
        }
        setTimeout(() => {
            const cssUrl = ThemeFileEnum[accInfo.theme];
            addStyle(cssUrl, accInfo.theme);
        }, 0)
    }
}

function addStyle(url, id) {
    const style = document.createElement("link");
    style.href = url;
    style.id = id;
    style.rel = "stylesheet";
    style.async = true;

    document.head.appendChild(style);
};

function hardRefresh(){
    
    if (sessionStorage.getItem('hardRefresh') !== "true") {
        //sessionStorage.setItem('hardRefresh', 'true');
        //window.location.reload();
    } 
    
}

const Utils = {
    setThemeData,
    formatDataWithType,
    formatAMPM,
    getMaskedEmail,
    getMaskedPhone,
    getAge,
    debounce,
    ifValuedExistInArray,
    scrollTo,
    getStatusViewData,
    validateEmail,
    validateName,
    validateNamePlusUHID,
    validateMobile,
    validateAge,
    getRoleData,
    getFormatedTime,
    getDifferenceByDays,
    removeSpecialCharFromString,
    formatDateValueByDash,
    getFormatedDate,
    transform_yyyy_MM_dd_To_dd_MM_yyyy,
    getFormatedDOBData,
    calculatePatientAge,
    isValidDate,
    getDateValueByApiFormat,
    getLongFormatedDate,
    get_time_difference,
    validatePatientMobile,
    toTitleCase,
    TDate,
    searchFor,
    sortListing,
    getNewFormatedDate,
    getTimeFormated,
    getDateDifference,
    getFormatedDateWithTimeStamp,
    formatViewType,
    fetchAllAccountData,
    getValueFromStorage,
    isAdminAndDataUser,
    getAdminAndDataUserRoute,
    getFormatedMonthYear,
    formatDateValueToMonthAndYear,
    isUatEnvir,
    checkIfFeatureVaccination,
    formatDate,
    formatTimeDetails,
    checkHospConsult,
    hardRefresh
}

export default Utils;


// var validation = {
//     isEmailAddress:function(str) {
//         var pattern =/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
//         return pattern.test(str);  // returns a boolean
//     },
//     isNotEmpty:function (str) {
//         var pattern =/\S+/;
//         return pattern.test(str);  // returns a boolean
//     },
//     isNumber:function(str) {
//         var pattern = /^\d+$/;
//         return pattern.test(str);  // returns a boolean
//     },
//     isSame:function(str1,str2){
//         return str1 === str2;
//     }
// }; 