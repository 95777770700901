import React from "react";
import { getImgPath } from "../../../providerConfig";

function BrandViewComponent(props) {
    let aI = sessionStorage.getItem('accountInfo')
    let bgColor = aI && JSON.parse(aI).logo_bg_color === 'white' && 'white' || ''
    return (
        <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center" style={{ backgroundColor: bgColor }}>
            {props.showBackButton ? <div style={{ cursor: 'pointer' }} onClick={props.emitBackClickEvent.bind(this)} className="navbar-brand brand-logo"><i className="mdi mdi-arrow-left btn-icon-append" style={{ marginRight: '10px', color: 'white' }} ></i><img src={getImgPath('logolight')} alt="logo" style={{ width: "inherit" }} /></div>
                : <div className="navbar-brand brand-logo " style={{ cursor: 'pointer' }} onClick={props.emitEventonNavLogoClick}><img src={getImgPath('logolight')} alt="logo" style={{ width: "inherit" }} /></div>}
            <div className="navbar-brand brand-logo-mini" style={{ cursor: 'pointer' }} onClick={props.emitEventonNavLogoClick}><img src={getImgPath('minilogolight')} alt="logo" /></div>
            {props.navType &&
                <div className="nav-link topmenuslide" id="menu-toggle" onClick={props.emitSidebarClickEvent.bind(this)} style={{ color: '#8fe5e4', fontFamily: 'Lato', textDecoration: 'none', cursor: 'pointer' }}>
                    <i className="mdi mdi-format-line-spacing"></i>
                </div>
            }
        </div>
    );
}

export default BrandViewComponent;