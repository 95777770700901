import React from "react";

class SectionActionComponent extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    onSaveAction = (event) => {
        this.props.emitSaveEvent(event);
    }

    onLoadAction = (event) => {
        this.props.emitLoadEvent(event);
    }

    render() {
        const { indSection } = this.props;
        return (
            <>
                {indSection.formname === "drugs" &&
                    <>
                        {(indSection.save || indSection.load) &&
                            <nav aria-label="Page navigation example ">
                                <ul className="pagination  float-right mb-2">
                                    {indSection.save &&
                                        <li className="page-item" >
                                            <a className="page-link" href="#" data-toggle="modal" data-target="#downloadsection" onClick={this.onSaveAction.bind(this, indSection)}> <i className="mdi mdi-content-save"> </i>  Save</a>
                                        </li>
                                    }
                                    {indSection.load &&
                                        <li className="page-item">
                                            <a className="page-link" href="#" data-toggle="modal" data-target="#downloadsection" onClick={this.onLoadAction.bind(this, indSection)}><i className="mdi mdi-folder-upload"> </i>  Load</a>
                                        </li>
                                    }
                                </ul>
                            </nav>
                        }
                    </>

                }


            </>
        );
    }
}

export default SectionActionComponent;
