import React, { useState } from "react";
import Papa from 'papaparse';

class FileUploadModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            headerConfig: ['type', 'name', 'description', 'price', 'slots required?', 'doctor'],
            invoiceBody: null,
            isDirty: false
        }
    }

    componentDidMount() {

    }

    onFileUpload(data) {
        let file = data.target.files[0];
        if (file != undefined) {
            Papa.parse(file, {
                complete: this.setParsedFile
            });
        }
    }

    setParsedFile = (results) => {
        
        let typeData = ["consultation", "pharmacy", "investigations", "others"]
        let { sheet, excel } = this.state;
        let finalSheetData = this.formatFinalSheetData(typeData, results.data);
        sheet = finalSheetData;
        excel = Papa.unparse(finalSheetData);
        this.setState({ sheet, excel, isDirty: true });
        this.checkForUnavailableAccounts(sheet);
    }

    formatFinalSheetData(typeData, data) {
        let finalData = []
        for (let i = 1; i < data.length; i++) {
            // if (typeData.indexOf((data[i][0]).toLowerCase()) !== -1) {
                // data.splice(i, 1);
                finalData.push(data[i])
            // }
        }
        return finalData;
    }

    checkForUnavailableAccounts(sheet) {
        if (!Array.isArray(sheet)) return;
        let header = sheet[0];
        
        // let HeaderDict = this.setMappedHeaderDict(header);
        // let excelBody = sheet.slice(1);
        // console.log(excelBody);
        this.checkForNoHeaderData(header, sheet);
    }

    checkForNoHeaderData(header, excelBody) {
        if (!header || header.length === 0) {
            return;
        }
        this.checkIfExcelBodyIsEmpty(excelBody);
    }

    checkIfExcelBodyIsEmpty(excelBody) {
        let { invoiceBody } = this.state;
        let finalBodyData = [];
        for (let i in excelBody) {
            if (!excelBody[i].length) {
                return;
            }
            let isPresent = false;
            for (let j in excelBody[i]) {
                if (excelBody[i][j]) {
                    isPresent = true;
                }
            }
            if (isPresent) {
                finalBodyData.push(excelBody[i]);
            }
        }
        invoiceBody = finalBodyData;
        this.setState({ invoiceBody }, () => {
            this.props.emitFileFinalUpdateData(finalBodyData, this.state.sheet, this.state.excel);
        });
    }

    render() {
        const { open } = this.props;

        return (
            <div className={`modal fade ${open ? 'show' : ''}`} id="timeslotmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden={`${open ? true : false}`} style={{
                    display: open ? 'block' : 'none', paddingRight: open ? '17px' : '', overflowX: 'hidden', overflowY: 'auto', backgroundColor: '#716e6e54'
                }}>
                <div className={`modal-dialog ${this.props.fullModal ? 'modal-lg' : ''}`} role="document" style={{ marginTop: 'calc(70px + 0px)' }}>
                    <div className="modal-content bg-white">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">{this.props.title ? this.props.title : 'Upload Inventory File'}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.props.emitCancelEvent.bind(this, false)}>
                                <span aria-hidden={true}>×</span>
                            </button>
                        </div>
                        <div className="modal-body ">
                            <section className="appointmentslot">
                                <div>
                                    <div>
                                        <form method="post" action="#" id="#">

                                            <div className="form-group filedrag color">
                                                <label>{'Service Management CSV'}  <a href={`/files/service.csv`} download><i className="mdi mdi-arrow-down-bold-circle-outline" data-tip data-for="service" /></a></label>
                                                <input type="file" className="form-control" onChange={e => this.onFileUpload(e)} onClick={(e) => { e.target.value = null }}></input>
                                            </div>
                                            <ul className="pl-3 mt-2 text-small">
                                                <li>You can download service CSV from above download icon i.e., <i className="mdi mdi-arrow-down-bold-circle-outline" data-tip data-for="service" />.</li>
                                                {/* <li>Supported Types :<strong> Consultation, Pharmacy, Investigations, Others</strong>.</li> */}
                                                <li>File size to be less than 15MB</li>
                                                <li>Document type can only be CSV</li>
                                            </ul>
                                        </form>
                                    </div>
                                </div>
                            </section>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-light" data-dismiss="modal" onClick={this.props.emitCancelEvent.bind(this, false)}>Cancel</button></div>
                    </div>
                </div>

            </div >
        )
    }
}

export default FileUploadModal;

