import {
  CountryCodesJson
} from "../CountryCodes";

let enable_opd_flow = sessionStorage.getItem('accountInfo') ? JSON.parse(sessionStorage.getItem('accountInfo')) : ''

// {appointment_date: "2019-09-24", appointment_slot: "03:44 PM", rescheduled_appointment_date: "",…}
// appointment_date: "2019-09-24"
// appointment_slot: "03:44 PM"
// appointment_status: "Done"
// doctor_id: "62e5936e-c397-4c2e-8bb2-02645877bfe4"
// patient: {name: "Rahul", age: {no_of_years: "25", no_of_months: "0", no_of_days: "6"},…}
// address: {country: "cnghhjjhghh", state: "chcnhhh", city: "vhfbghh", zipcode: "565656", line1: "ghfgfggghh",…}
// age: {no_of_years: "25", no_of_months: "0", no_of_days: "6"}
// country_code: "+977"
// date_of_birth: "2019-09-18"
// email: "trerrst@gmail.com"
// gender: "Female"
// name: "Rahul"
// phone: "9990966886"
// referred_doctor_name: "disha"
// rescheduled_appointment_date: ""
// array of columns
const bookingRow1 = [{
  "label": "Appointment Date",
  "labelSize": 3,
  "colSize": 8,
  "isRequired": true,
  "name": "appointment_date",
  "type": "date",
  "maxDateFn": () => {},
  "minDateFn": () => {}
}, {
  "label": "Appointment Slot",
  "labelSize": 3,
  "colSize": 8,
  "isRequired": true,
  "name": "appointment_slot",
  "type": "timeslot"
}];

const bookingRow2 = [{
  "isRequired": sessionStorage.getItem('s3AccountId') !== "breathefree" ? false :true,
  "name": "referred_doctor_name",
  "placeholder": "Enter referred doctor",
  "colSize": 8,
  "type": "textbox",
  "label": "Referred Doctor",
  "labelSize": 3,
}, {
  "isRequired": false,
  "name": "appointment_status",
  "placeholder": "Select Appointment Status",
  "colSize": 8,
  "type": "selection",
  "dataKey": "appointmentStatus",
  "label": "Appointment Status",
  "labelSize": 3,
}];

const personRow1 = [{
  "isRequired": true,
  "name": "name",
  "placeholder": "Enter name",
  "hasErrorsMessage": "*Only letters allowed",
  "colSize": 8,
  "type": "textbox",
  "label": "Full Name",
  "labelSize": 3,
  "isSearch": enable_opd_flow.enable_opd_flow== true ? false : true

},
{
  "isRequired": false,
  "name": "patient_hid",
  "placeholder": "Enter Patient UHID",
  "colSize": 8,
  "type": "textbox",
  "label": "Patient UHID",
  "labelSize": 3,
  "isSearch": true
}];

const personRow2 = [{
  "label": "Date of Birth",
  "labelSize": 3,
  "colSize": 8,
  "isRequired": true,
  "children": [{
      "name": "date_of_birth",
      "colSize": 2,
      "className": 'pr-0',
      "type": "date",
      "maxDateFn": () => new Date(),
      "minDateFn": () => {}
    },
    {
      "view": 'separate',
      "name": "age",
      "placeholder": "Enter age",
      "hasErrorsMessage": "*Invalid age",
      "colSize": 6,
      "type": "textbox",
    }
  ]
}, {
  "isRequired": enable_opd_flow.enable_opd_flow === true ? true : false,
  "name": "gender",
  "colSize": 8,
  "optionColSize": 4,
  "type": "radio",
  "dataKey": "gender",
  "label": "Gender",
  "labelSize": 3,
}];

const personRow3 = [{
    "label": "Phone",
    "labelSize": 3,
    "isRequired": true,
    "children": [{
        "name": "country_code",
        "placeholder": "Select country code",
        "colSize": 3,
        "dataKey": "countryCodes",
        "optionKey": "dialCode",
        "displayValueFn": (obj) => {
          return obj.dialCode + "(" + obj.name + ")"
        },
        "type": "autocomplete",
      },
      {
        "name": "phone",
        "hasErrorsMessage": "*Invalid number, It must be ten digits",
        "placeholder": "Enter phone",
        "colSize": 5,
        "type": "textbox",
        "isSearch": true
      }
    ]
   
  },
  {
    "isRequired": enable_opd_flow.enable_opd_flow === true ? true : false,
    "name": "email",
    "placeholder": "Enter email",
    "hasErrorsMessage": "*Invalid email format",
    "colSize": 8,
    "type": "textbox",
    "label": "Email",
    "labelSize": 3,
    "isSearch": enable_opd_flow.enable_opd_flow === true ? true : false
  }
];
const personRow4 = [
  {
    "isRequired": false,
    "name": "guardian_name",
    "placeholder": "Enter guardian name",
    "hasErrorsMessage": "*Required for children below 18",
    "colSize": 8,
    "type": "textbox",
    "label": "Guardian Name",
    "labelSize": 3,
  },
  {
    "isRequired": false,
    "name": "guardian_phone",
    "placeholder": "Enter guardian phone",
    "hasErrorsMessage": "*Required for children below 18 [10 digits]",
    "colSize": 8,
    "type": "textbox",
    "label": "Guardian Phone",
    "labelSize": 3,
  }
];

const addrRow1 = [{
  "isRequired": false,
  "name": "line1",
  "placeholder": "Enter Address Line 1",
  "colSize": 8,
  "type": "textbox",
  "label": "Address Line 1",
  "labelSize": 3,
}, {
  "isRequired": false,
  "name": "line2",
  "placeholder": "Enter Address Line 2",
  "colSize": 8,
  "type": "textbox",
  "label": "Address Line 2",
  "labelSize": 3,
}];

const addrRow2 = [{
  "isRequired": false,
  "name": "zipcode",
  "placeholder": "Enter pincode",
  "colSize": 8,
  "type": "textbox",
  "label": "Pincode",
  "labelSize": 3,
}, 
// {
//   "isRequired": false,
//   "name": "country",
//   "dataKey": "countryCodes",
//   "optionKey": "name",
//   "displayValueFn": (obj) => {
//     return  obj.name
//   },
//   "type": "autocomplete",
//   "placeholder": "Select Country",
//   "colSize": 8,
//   "label": "Country",
//   "labelSize": 3,
//   "isRequired": true,
// }
];

const addrRow3 = [{
  "name": "state",
  "placeholder": "Select State",
  "colSize": 8,
  "type": "selection",
  "label": "State",
  "labelSize": 3,
  "isRequired": true,
}, {
  "name": "city",
  "placeholder": "Select City",
  "colSize": 8,
  "type": "selection",
  "label": "city",
  "labelSize": 3,
  "isRequired": true,
}];

const addrRow4 = [{
  "isRequired": false,
  "name": "display_address",
  "placeholder": "Enter Display address",
  "colSize": 8,
  "type": "textbox",
  "label": "Display Address",
  "labelSize": 3,
}];
const uiData = {
  "countryCodes": CountryCodesJson, //get from static JSON
  // "appointmentStatus": [{key: 'booked', value: 'Booked'}, {key: 'checkedin', value: 'Checked-In'}, {key: 'done', value: 'Done'}, {key: 'rescheduled', value: 'Re-Scheduled'}, {key: 'cancelled', value: 'Cancelled'}, {key: 'noshow', value: 'No Show'}],
  "gender": {
    options: [{
        "value": "male",
        "key": "male",
        "checked": false
      },
      {
        "value": "female",
        "key": "female",
        "checked": false
      },
      {
        "value": "others",
        "key": "others",
        "checked": false
      }
    ]
  }
}

const bookingInfo = {
  sectionName: 'Booking Info',
  rows: [bookingRow1, bookingRow2]
};


const personalInfo = {
  sectionName: 'Patient Info',
  rows: [personRow1, personRow2, personRow3, personRow4]
};
const addressInfo = {
  sectionName: 'Patient Address',
  rows: [addrRow1, addrRow2],
  uiData: uiData
}

const appointmentFormData = {
  formName: "Appointment Form",
  sections: [personalInfo, addressInfo],
  uiData: uiData
};


export default appointmentFormData;