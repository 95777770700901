import request from '../Api/request';
import userAuth from '../Authentication/authActions';
import Utils from '../Shared/utils/utils';

// const USE_REAL_API = true;

function getApiStageType() {
    return userAuth.getApiStageType()
}

function getUserApiStageUrl() {
    return userAuth.getUserApiUrl()
}

function getAppointmentApiUrl() {
    return userAuth.getAppointmentApiUrl()
}

function getNewPatientApiUrl() {
    return userAuth.getNewPatientApiUrl()
}

function formatQueryParams(queryString) {
    if (queryString.indexOf('&') !== -1) {
        let paramArray = queryString.split('&');
        return paramArray[1] !== "" ? `${paramArray[0]}&${paramArray[1]}` : `${paramArray[0]}`
    }
    return queryString;
}

// https://o1apqc0fj1.execute-api.ap-south-1.amazonaws.com/

function fetchDoctorSlotByDate(doctorId, date, token, slot_type, account_id, instant, patient_hid) {
    let api = getAppointmentApiUrl();
    let keyData = slot_type ? `&slot_type=${slot_type}` : '';
    keyData = keyData ? keyData + (account_id ? `&account_id=${account_id}` : '') : (account_id ? `&account_id=${account_id}` : '');
    let _instant = instant === true ? true : false;
    if (token) {
        if (patient_hid !== "") {
            return request({
                baseURL: api,
                url: `/onlineappointment/getslots/${doctorId}?date=${date}` + keyData + "&get_instant_slots=" + _instant + "&patient_hid=" + patient_hid,
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }, 'appointment');
        }
        else {
            return request({
                baseURL: api,
                url: `/onlineappointment/getslots/${doctorId}?date=${date}` + keyData + "&get_instant_slots=" + _instant,
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }, 'appointment');
        }
    } else {
        return request({
            baseURL: api,
            url: `/onlineappointment/getslots/${doctorId}?date=${date}`,
            method: 'get'
        }, 'appointment');
    }

}

function fetchDoctorSlotForAppointment(doctorId, date, slot_type, patient_hid) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    // getAppointmentApiUrl()
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/appointment` : `https://emrapi.hlthclub.in/appointment`;
    } else {
        api = `https://betaemrapi.hlthclub.in/appointment`
    }
    let _url = ""
    if (patient_hid === null) {
        _url = `/onlineappointment/slots/${doctorId}?date=${date}&slot_type=${slot_type}`
    }
    else {
        _url = `/onlineappointment/slots/${doctorId}?date=${date}&slot_type=${slot_type}&patient_hid=${patient_hid}`
    }
    return request({
        baseURL: api,
        url: _url,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getOnlineDoctorForOpd(account_id, doctor_id, fetch_all_type, token, doctorHid, branch, instant, docterId) {
    let apiStage = getApiStageType();
    let api = getUserApiStageUrl();
    let queryParam = '';
    if (account_id) {
        queryParam = '?' + 'account_id=' + account_id;
    }
    if (docterId) {
        queryParam = queryParam ? queryParam + '&doctor_hid=' + docterId : '?' + 'doctor_hid=' + docterId;
    }
    if (!fetch_all_type && docterId === "") {
        queryParam = queryParam ? queryParam + '&fetch_all_type=' + true : '?' + 'fetch_all_type=' + true;
    }
    if (fetch_all_type) {
        queryParam = queryParam;
    }
    if (doctor_id) {
        queryParam = queryParam ? queryParam + '&doctor_id=' + doctor_id : '?' + 'doctor_id=' + doctor_id;
    }
    if (doctorHid) {
        queryParam = queryParam ? queryParam + '&doctor_hid=' + doctorHid : '?' + 'doctor_hid=' + doctorHid;
    }
    if (branch) {
        queryParam = queryParam ? queryParam + '&branch=' + branch : '?' + 'branch=' + branch;
    }

    if (instant) {
        queryParam = queryParam + '&instant=1';
    }
    return request({
        baseURL: api,
        url: apiStage + `/onlinedoctor` + queryParam,
        headers: {
            'Authorization': 'Bearer ' + token
        },
        method: 'get',
    }, 'appointment');
}

// /onlinepatient/consult/patient:2020-04-01$onlinept:2020-03-31T19:25:11:871e7fb
function getOnlineDoctor(speciality, account_id, branch, token) {
    let apiStage = getApiStageType()

    let api = getUserApiStageUrl();
    let queryParam = '';
    if (account_id) {
        queryParam = '?' + 'account_id=' + account_id;
    }
    if (speciality) {
        let filterData = speciality.replace(/\s+/g, '').toLowerCase();
        queryParam = queryParam ? queryParam + '&speciality=' + speciality : '?' + 'speciality=' + filterData;
    }
    if (branch) {
        let filterData = branch.replace(/\s+/g, '').toLowerCase();
        queryParam = queryParam ? queryParam + '&branch=' + branch : '?' + 'branch=' + branch;
    }

    if (token) {
        return request({
            baseURL: api,
            url: apiStage + `/onlinedoctor` + queryParam,
            // url: 'https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/prod/onlinedoctor?account_id=thb_test',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            method: 'get',
        }, 'appointment');
    } else {
        return request({
            baseURL: api,
            url: apiStage + `/onlinedoctor` + queryParam,
            // url: 'https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/prod/onlinedoctor?account_id=thb_test',
            method: 'get',
        }, 'appointment');
    }

}

// /onlinepatient/consult/patient:2020-04-01$onlinept:2020-03-31T19:25:11:871e7fb
// 
function requestOfflineConsultation(data, patientId, token) {
    let api = getAppointmentApiUrl()
    if (token) {
        return request({
            baseURL: api,
            url: `/offlineappt/patient/create/${patientId}`,
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: data
        }, 'appointment');
    }
}
// offlineappt/patient/create
function requestConsultation(data, patientId, token) {
    let api = getNewPatientApiUrl()
    if (token) {
        return request({
            baseURL: api,
            url: `/onlinepatient/consult/${patientId}`,
            method: 'post',
            headers: {
                'Authorization': 'Bearer ' + token
            },
            data: data
        }, 'appointment');
    } else {
        return request({
            baseURL: api,
            url: `/onlinepatient/consult/${patientId}`,
            method: 'post',
            data: data
        }, 'appointment');
    }

}

const doctorService = {
    getOnlineDoctor,
    requestConsultation,
    fetchDoctorSlotByDate,
    fetchDoctorSlotForAppointment,
    getOnlineDoctorForOpd,
    requestOfflineConsultation
}

export default doctorService;