import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Utils from '../../../Shared/utils/utils';
import TimeKeeper from 'react-timekeeper';

class TimeSlot extends React.Component {
    onSlotSelectionConfirmation = (event) => {
        this.props.emitSlotSelectionConfirmation()
    }

    onSlotModalClose = (event) => {
        this.props.emitSlotModalCloseEvent()
    }

    onSlotSelection = (event, slotName) => {
        this.props.emitSlotSelectionEvent(event, slotName)
    }

    handleChange = (event) => {
        this.props.emitSlotDateChangeEvent(event);
    }

    onTimeSlotSelection = (newTime) => {
        const formatedTime = newTime.formatted;
        this.props.emitTimeSlotData({ time: formatedTime });
    }

    render() {
        const { selectedDoctor, open, selectedSlot, slotData, isSlotLoading, showClock, date } = this.props;
        let defaultTime = this.props.selectedSlot ?
            this.props.selectedSlot : new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            
        return (
            <React.Fragment>
                <div className={`modal   fade ${open ? 'show' : ''}`} id="timeslotmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                    aria-hidden={`${open ? true : false}`} style={{
                        display: open ? 'block' : 'none', paddingRight: open ? '17px' : '', overflowX: 'hidden', overflowY: 'auto', backgroundColor: '#716e6e54'
                    }}>
                    <div className="modal-dialog modal-lg" role="document" style={{ marginTop: 'calc(70px + 0px)' }}>
                        <div className="modal-content bg-white">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Select your booking slot</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onSlotModalClose.bind(this)}>
                                    <span aria-hidden={true}>×</span>
                                </button>
                            </div>
                            <div className="modal-body " style={{ paddingTop: 0 }}>
                                {this.props.showPresetSlots && <section className="appointmentslot">
                                    <div className="row align-items-center justify-content-end ">
                                        <div className="col-12 col-sm-4 col-md-12">
                                            <h5 className="mb-1" style={{ textAlign: 'center' }}>{new Date(date).toLocaleDateString([], { year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' })}</h5>
                                            {/* <p className="text-muted pb-1 mb-1 mt-1">Monday</p> */}
                                        </div>
                                        {this.props.emitSlotDateChangeEvent && <div className="col-12 col-sm-8 col-md-3 ml-auto text-sm-right">
                                            <div className="form-group mb-0">
                                                <DatePicker
                                                    className="form-control"
                                                    dateFormat="dd-MM-yy"
                                                    selected={this.props.date}
                                                    onChange={this.handleChange.bind(this)}
                                                />
                                            </div>
                                        </div>}
                                    </div>
                                    {!isSlotLoading ? <div className="row ">
                                        <div className="col-md-12 ">

                                            <div className="card booking-schedule schedule-widget">
                                                <div className=" card-body schedule-cont p-3" style={{ paddingTop: '0px !important' }}>
                                                    <hr style={{ margin: '0px 0px 5px' }} />
                                                    {slotData && slotData.length > 0 ?
                                                        <div className="row timeslot">
                                                            {slotData.map((indSlot, index) =>
                                                                <div className="col-4 col-md-4 border-right" key={index}>
                                                                    <h5 style={{ marginTop: 0, textTransform: 'capitalize' }}> {indSlot.name}</h5>
                                                                    {indSlot.slots.map((slot, jndex) => (
                                                                        <button key={jndex}  type="button" className={selectedSlot === slot ? "btn  btn-sm selected" : "btn btn-outline-success btn-sm"} style={{ marginRight: 5 }} onClick={this.onSlotSelection.bind(this, slot, indSlot.name)}>{slot}</button>
                                                                    )
                                                                    )}
                                                                </div>
                                                            )}
                                                        </div>
                                                        :
                                                        !slotData ? <p className="text-danger m-0" style={{ textAlign: 'center' }}>Error Occurred to find available slots. Please try a different date!</p> : <p className="text-danger m-0" style={{ textAlign: 'center' }}>No available slots! Select a time below.</p>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                        :
                                        <div className="clinytic-line-loader"></div>}
                                </section>}
                                <section>
                                    {(slotData && this.props.showPresetSlots) &&
                                        <>
                                            <hr style={{ margin: '5px 0px 0px' }} />
                                            <p style={{ margin: 0, textAlign: 'center' }}>Or</p>
                                            <hr style={{ margin: '5px 0px 0px' }} />
                                        </>
                                    }
                                    <div className="card mt-1">
                                        <div className="card-header pointer-cursor text-primary" id="heading-13" onClick={this.props.toggleClock.bind(this)}>
                                            Select Custom Time <i className="mdi mdi-plus" />
                                        </div>
                                        {showClock && <div className="card-body p-3">
                                            <small className="text-info">*Please confirm with doctor or coordinator before selecting an appointment time here.</small>
                                            <div className="row timeslot mb-3 mt-3" style={{ justifyContent: 'center' }}>
                                                <TimeKeeper
                                                    time={defaultTime}
                                                    onChange={this.onTimeSlotSelection.bind(this)}
                                                    style={{ with: '100% !important' }}
                                                    switchToMinuteOnHourSelect={true}
                                                />
                                            </div>
                                        </div>}
                                    </div>
                                </section>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-light" data-dismiss="modal" onClick={this.onSlotModalClose.bind(this)}>Cancel</button>
                                <button type="button" className="btn btn-success" onClick={this.onSlotSelectionConfirmation.bind(this)} disabled={!selectedSlot}>Continue</button>
                            </div>
                        </div>
                        <div className="clinytic-line-loader" style={{ display: this.props.isRequestConsultation ? '' : 'none' }}></div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default TimeSlot;
