// let showTemplateGenerator = sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole').indexOf("support") !== -1 ? true : false;
const  pharmacistNavBarRoutes = [{
    type: 'single',
    navbarName: "Bookings",
    key: "service-sold",
    display: true,
    isActive: false,
    route: `/service-sold`
}, {
    type: 'multi',
    navbarName: "Transactions",
    key: "transactions",
    display: false,
    isActive: false,
    icon: "transactions",
    route: `/refund-status,/payment-status`,
    options: [{
        type: 'single',
        route: `/refund-status`,
        navbarName: "Refund",
        key: "refund-status",
        icon: "refund-status"
    }, {
        type: 'single',
        route: `/payment-status`,
        navbarName: "Payment",
        key: "payment-status",
        icon: "payment-status"
    }]
}];


export default pharmacistNavBarRoutes;