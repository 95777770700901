import React from "react";

function PlainTermsAndConditions(props) {
    return (
        <>
            <div className="row">
                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <h4 className="card-title">USER AGREEMENT --</h4>
                            Welcome to Breathefree mobile application.
                            <p className="c1">
                                <span className="c0">This
                                    document is an electronic record in terms of Information Technology
                                    Act, 2000 and rules there under as applicable and the amended provisions
                                    pertaining to electronic records in various statutes as amended by the
                                    Information Technology Act, 2000. This electronic record is generated by
                                    a computer system and does not require any physical or digital
                                    signatures.
                                </span></p>
                            <p className="c1"><span className="c0">This document is
                                published in accordance with the provisions of Rule 3 (1) of the
                                Information Technology (Intermediaries guidelines) Rules, 2011 that
                                require publishing the rules and regulations, privacy policy and Terms
                                of Use for access or usage of </span><span className="c0 c4"><a className="c6"
                                    >breathefreeeducator.com</a></span><span
                                        className="c0">&nbsp;</span><span className="c0 c4">&nbsp;</span><span className="c0">website and its mobile
                                            applications (“Platform”).</span></p>
                            <p className="font-weight-bold">General</p>

                            <p className="c1"><span className="c0">Platform is an Internet based portal owned and operated by </span><span
                                className="c0">Sekhmet
                                Technologies Private Limited (“company”), a company incorporated under
                                the laws of nepal. Use of the Platform is offered to You, subject to
                                acceptance of all the terms, conditions and notices contained in these
                                Terms including applicable policies which are incorporated herein by
                                reference, along with any amendments / modifications made by company at
                                its sole discretion and posted on the Platform, including by way of
                                imposing an additional charge for access to or use of a service(s).</span></p>
                            <p className="c1"><span className="c0">For
                                the purpose of these Terms of Use, wherever the context so requires
                                "You" or "User" shall mean any natural or legal person who has agreed to
                                become a buyer on the Platform by providing Account Information
                                (defined below) while registering on the Platform as a registered User
                                using the computer systems.</span></p>
                            <p className="c1"><span className="c0">company
                                shall not be required to notify You, whether as a registered user or
                                not, of any changes made to the Terms and Conditions ("Terms"). The
                                revised Terms shall be made available on the Platform. Your use of the
                                Platform and the Services is subject to the most current version of the
                                Terms made available on the Platform, at the time of such use. You are
                                requested to regularly visit the Platform to view the most current
                                Terms. It shall be your responsibility to check the Terms periodically
                                for changes. company may require You to provide Your consent to the
                                updated Terms in a specified manner prior to any further use of the
                                Platform and the Services, provided on the Platform. If no such separate
                                consent is sought, Your continued use of the Platform, following
                                changes to the Terms, will constitute your express acceptance of those
                                changes.</span></p>
                            <p> By clicking on the ‘Sign in’ button or accessing or using this Application,
                                you hereby accept the Terms of Use (as amended from time to time) and the Privacy Policy (as amended
                                from time to time) and agree to be bound by their terms. </p>

                            <p> If you do not agree to be bound by their terms, then please do not access or use the Application.
                            </p>
                            <p>
                                Further, by ‘Sign in’ button, you hereby consent to the collection, storage, use, processing,
                                disclosure and transfer of your personal information in accordance with the provisions of the
                                Privacy Policy (as amended from time to time).
                            </p>
                            <p> If you are accessing the Breathefree mobile application, then there may be additional terms (such as
                                the terms imposed by application stores) which may also govern the use of the mobile application. By
                                clicking on the ‘I Accept’ button at the end of this page or accessing or using the mobile
                                application, you agree to be bound by the aforementioned additional terms as may be amended from
                                time to time as well.
                            </p>
                            <p className="c1"><span className="c0">By (i) using this
                                Platform or any facility or service provided by this Platform in any
                                way; or (ii) merely browsing the Platform, You agree that you have read,
                                understood and agreed to be bound by these Terms and the Platform's
                                Privacy Policy available at the link on the Platform.</span></p>

                            <p className="font-weight-bold">Products</p>

                            <p><span className="c0">The
                                Platform is a platform that facilitates the online requisition by the
                                user for purchase of medicines and wellness / health related products
                                offered by company's various registered merchants / vendors
                                ("Products"). The sale &amp; purchase / transaction between the
                                registered merchants/vendors and You, of Products , facilitated by the
                                requisition placed by You on the Platform shall be governed by these
                                Terms.</span></p>
                            <p className="font-weight-bold"><span className="c2">Eligibility to Use</span></p>
                            <p className="c1"><span className="c0">Use
                                of the Platform is available only to persons who can form legally
                                binding contracts under Indian Contract Act, 1872. Persons who are
                                "incompetent to contract" within the meaning of the Indian Contract Act,
                                1872 including minors, un-discharged insolvents etc. are not eligible
                                to use the Platform. The Products shall also not be available to any
                                Users suspended or removed from the company’s system for any reason
                                whatsoever. If You do not conform to the above qualification, You will
                                not be permitted to put a requisition for the Products through the
                                Platform. By accessing and using this Platform, You represent that You
                                are of legal age to form a binding contract and are not a person barred
                                from receiving services under the laws as applicable in nepal.
                                Notwithstanding the foregoing, if You are below the age of eighteen (18)
                                years, You may avail the services provided by the Platform, through
                                Your legal guardian in accordance with the applicable laws. company
                                reserves the right to terminate your membership and / or refuse to
                                provide you with access to the Platform if it is brought to company’s
                                notice or if it is discovered that you are under the age of 18 years.</span></p>
                            <p className="c1"><span className="c0">company
                                reserves the right to refuse access to use the services offered at the
                                Platform to new Users or to terminate access granted to existing Users
                                at any time without according any reasons for doing so and You shall
                                have no right to object to the same.</span></p>
                            <p className="c1"><span className="c0">You
                                shall not have more than one active Account on the Platform.
                                Additionally, You are prohibited from selling, trading, or otherwise
                                transferring Your Account to another party.</span></p>

                            <p className="font-weight-bold"><span className="c2">User Account, Password, and Security</span></p>

                            <p className="c1"><span className="c0">company
                                shall enable You to make the requisition of Products available to You
                                through the Platform, only if You have provided company certain required
                                User information and created an account ("Account") through company ID
                                and password or other log-in ID and password, which can include a
                                facebook, gmail, yahoo ID or any other valid email ID (collectively, the
                                "Account Information") . The transaction and delivery of the Products
                                by the registered merchants/vendors may also be subject to procedures
                                for use of the Platform, Terms, uploaded guidelines, rules, additional
                                terms of service, or other disclaimer &amp; notices, if any ("Additional
                                Terms"). If there is any conflict between the Terms and the Additional
                                Terms, the Additional Terms shall take precedence in relation to that
                                service.</span></p>
                            <p className="c1"><span className="c0">The Platform requires
                                You to register as a User by creating an Account in order to avail of
                                the services provided by the Platform. You will be responsible for
                                maintaining the confidentiality of the Account Information, and are
                                fully responsible for all activities that occur under Your Account. You
                                agree to (a) immediately notify company of any unauthorized use of Your
                                Account or Account Information or any other breach of security, and (b)
                                ensure that You exit / log out from Your Account at the end of each
                                session. company cannot and will not be liable for any loss or damage
                                arising from Your failure to comply with this Section. You may be held
                                liable for losses incurred by company or any other User of or visitor to
                                the Platform due to authorized or unauthorized use of Your Account as a
                                result of Your failure in keeping Your Account Information secure and
                                confidential or otherwise.</span></p>
                            <p className="c1"><span className="c0">The Platform also allows / shall allow restricted access to the
                                Platform for unregistered Users.</span></p>
                            <p className="c1"><span className="c0">You
                                shall ensure that the Account Information provided by You in the
                                Platform's registration form is true, complete, accurate and up-to-date.
                                Use of another User's Account Information for availing the services
                                offered by company is expressly prohibited.</span></p>
                            <p className="c1"><span className="c0">If
                                You provide any information that is untrue, inaccurate, not current or
                                incomplete (or becomes untrue, inaccurate, not current or incomplete),
                                or company has reasonable grounds to suspect that such information is
                                untrue, inaccurate, not current or incomplete, company has the right to
                                suspend or terminate Your Account and refuse any and all current or
                                future use of the Platform by You.</span></p>
                            <p className="c1"><span className="c0">You
                                confirm that you are the authorised holder of the credit card or the
                                original account holder used in the transactions he/she makes using the
                                Platform. company will not be responsible for any financial loss,
                                inconvenience or mental agony resulting from misuse of the Your
                                ID/password/credit card number/account details number for using the
                                Platform.</span></p>
                            <p className="c1"><span className="c2">Pricing Information and Payment</span></p>
                            <p className="c1"><span className="c0">company
                                strives to provide You with the best prices possible on the Products
                                You require from the Platform. The pricing details for purchase of
                                Products from the Platform are detailed under these Terms.</span></p>
                            <p className="font-weight-bold"><span className="c0">Further:</span></p>
                            <p className="c1"><span className="c0">(a)
                                All commercial terms such as price, delivery, dispatch of Products
                                and/or services are as per principal to principal bipartite contractual
                                obligations between User and the Vendor.</span></p>
                            <p className="c1"><span className="c0">(b)
                                Your relationship with company is on a principal to principal basis and
                                by accepting these Terms of Use you agree that company is an
                                independent contractor for all purposes, and does not have control of or
                                liability for the products or services that are listed/ offered on
                                company's Platform. company does not guarantee the identity of any
                                Vendor nor does it ensure that a User or a Vendor will complete a
                                transaction.</span></p>
                            <p className="c1"><span className="c0">(c) You, as a
                                User, understand that upon initiating a requisition on the Platform that
                                You are entering into a legally binding and enforceable contract with
                                the Vendor to purchase the Products and /or services from the Vendor on a
                                cash on delivery basis or such the mode as may be specified by company.</span></p>
                            <p className="c1"><span className="c0">(d)
                                You, as a User, shall hand over a copy of the prescription, at the time
                                of delivery, for the delivery of Products which as per law requires a
                                valid prescription signed by a medical practitioner. You acknowledge
                                that delivery of such Products shall be made only if the Vendor or its
                                agent/employee is satisfied that the prescription is in compliance with
                                applicable norms.</span></p>
                            <p className="c1"><span className="c0">(e) You, as a
                                User, shall electronically notify company using the appropriate
                                company’s Platform features immediately upon Delivery or non-Delivery
                                within the time period as provided in these Terms of Use. Non
                                notification by You of delivery or non-delivery within the time period
                                specified in the these Terms of Use shall be construed as a deemed
                                delivery in respect of that particular order.</span></p>
                            <p className="c1"><span className="c0">(f)
                                You, as a User, shall be entitled to claim a refund of the transaction
                                price (as Your sole and exclusive remedy) in case You do not receive the
                                Delivery within the time period agreed in the transaction or within the
                                time period as provided in these Terms of Use, whichever is earlier. In
                                case you do not raise a refund claim using Platform features within the
                                stipulated time than this would make You ineligible for a refund.</span></p>
                            <p className="c1"><span className="c0">(g) Refund shall be made in Indian Rupees only and shall be equivalent
                                to the Transaction Price received in Indian Rupees.</span></p>
                            <p className="c1"><span className="c0">(h) Refund shall be subject to User complying with these Terms of
                                Use.</span></p>
                            <p className="c1"><span className="c0">(i)
                                company reserves the right to refuse to process orders/requisitions
                                from Users with a prior history of questionable charges including
                                without limitation breach of any agreements by User with company or
                                breach of any policy.</span></p>
                            <p className="c1"><span className="c0">(j) The
                                User and Vendor acknowledge that company will not be liable for any
                                damages, interests or claims etc. resulting from not processing a
                                Transaction/Transaction Price or any delay in processing a
                                Transaction/Transaction Price which is beyond control of company.</span></p>
                            <p className="c1"><span className="c0">(k)
                                You acknowledge that company is a mere facilitator and does not fall
                                within the purview of the Food Safety and Standards Act, 2006 and the
                                rules framed thereunder.</span></p>
                            <p className="c1"><span className="c0">(l)
                                You acknowledge that company is a mere facilitator and does not fall
                                within the purview of the Drugs and Cosmetics Act, 1940 and the rules
                                framed thereunder.</span></p>

                            <p className="font-weight-bold"><span className="c2">User Obligations</span></p>

                            <p className="c1"><span className="c0">Subject
                                to compliance with these Terms, company grants You a personal,
                                non-exclusive, non-transferable, limited privilege to access and use
                                this Platform and the services provided herein.</span></p>
                            <p className="c1"><span className="c0">You
                                agree to use the services, Platform and the materials provided therein
                                only for purposes that are permitted by: (a) the Terms; and (b) any
                                applicable law, regulation or generally accepted practices or guidelines
                                in the relevant jurisdictions.</span></p>
                            <p className="c1"><span className="c0">You
                                agree to adhere to all limitations on dissemination, use and
                                reproduction of any materials (such as the Product catalogues) that You
                                access on the Platform in accordance with the Section below on “User of
                                Materials”.</span></p>
                            <p className="c1"><span className="c0">You agree not to
                                access (or attempt to access) the Platform and the materials or services
                                by any means other than through the interface that is provided by
                                company. You shall not use any deep-link, robot, spider or other
                                automatic device, program, algorithm or methodology, or any similar or
                                equivalent manual process, to access, acquire, copy or monitor any
                                portion of the Platform or Content (as defined below), or in any way
                                reproduce or circumvent the navigational structure or presentation of
                                the Platform, materials or any Content, to obtain or attempt to obtain
                                any materials, documents or information through any means not
                                specifically made available through the Platform.</span></p>
                            <p className="c1"><span className="c0">You
                                acknowledge and agree that by accessing or using the Platform or
                                Services, You may be exposed to content from other users that You may
                                consider offensive, indecent or otherwise objectionable. company
                                disclaims all liabilities arising in relation to such offensive content
                                on the Platform. Further, You may report such offensive content in the
                                manner prescribed under Section “Report Abuse” herein.</span></p>
                            <p className="c1"><span className="c0">If
                                Platform allows You to post and upload any material on the Platform,
                                You hereby undertake to ensure that such material is not offensive and
                                in accordance with applicable laws. Further, You undertake not to:</span></p>
                            <p className="c1"><span className="c0">Defame,
                                abuse, harass, threaten or otherwise violate the legal rights of
                                others;Impersonate any person or entity, or falsely state or otherwise
                                misrepresent Your affiliation with a person or entity;Publish, post,
                                upload, distribute or disseminate any inappropriate, profane,
                                defamatory, infringing, obscene, indecent or unlawful topic, name,
                                material or information through any bookmark, tag or keyword;Upload
                                files that contain software or other material protected by applicable
                                intellectual property laws unless You own or control the rights thereto
                                or have received all necessary consents;Upload or distribute files that
                                contain viruses, corrupted files, or any other similar software or
                                programs that may damage the operation of the Platform or another's
                                computer;Engage in any activity that interferes with or disrupts access
                                to the Platform or the services (or the servers and networks which are
                                connected to the Platform);Attempt to gain unauthorized access to any
                                portion or feature of the Platform, any other systems or networks
                                connected to the Platform, to any company server, or to any of the
                                services offered on or through the Platform, by hacking, password mining
                                or any other illegitimate means;Probe, scan or test the vulnerability
                                of the Platform or any network connected to the Platform, nor breach the
                                security or authentication measures on the Platform or any network
                                connected to the Platform. You may not reverse look-up, trace or seek to
                                trace any information on any other user, of or visitor to, the
                                Platform, or any other customer of company, including any company
                                Account not owned by You, to its source, or exploit the Platform or
                                service or information made available or offered by or through the
                                Platform, in any way whether or not the purpose is to reveal any
                                information, including but not limited to personal identification
                                information, other than Your own information, as provided for by the
                                Platform;Disrupt or interfere with the security of, or otherwise cause
                                harm to, the Platform, systems resources, accounts, passwords, servers
                                or networks connected to or accessible through the Platforms or any
                                affiliated or linked sites;Collect or store data about other users in
                                connection with the prohibited conduct and activities set forth in this
                                Section.Use any device or software to interfere or attempt to interfere
                                with the proper working of the Platform or any transaction being
                                conducted on the Platform, or with any other person's use of the
                                Platform;Use the Platform or any material or Content for any purpose
                                that is unlawful or prohibited by these Terms, or to solicit the
                                performance of any illegal activity or other activity which infringes
                                the rights of company or other third parties;Conduct or forward surveys,
                                contests, pyramid schemes or chain letters;Download any file posted by
                                another user of a service that you know, or reasonably should know,
                                cannot be legally distributed in such manner;Falsify or delete any
                                author attributions, legal or other proper notices or proprietary
                                designations or labels of the origin or source of software or other
                                material contained in a file that is uploaded;Violate any code of
                                conduct or other guidelines, which may be applicable for or to any
                                particular Service;Violate any applicable laws or regulations for the
                                time being in force within or outside nepal;Violate the Terms including
                                but not limited to any applicable Additional Terms of the Platform
                                contained herein or elsewhere; andReverse engineer, modify, copy,
                                distribute, transmit, display, perform, reproduce, publish, license,
                                create derivative works from, transfer, or sell any information or
                                software obtained from the Platform.</span></p>
                            <p className="c1"><span className="c0">company
                                has no obligation to monitor such communications by You. However,
                                company reserves the right to review materials posted by You and to
                                remove any materials in its sole discretion. company reserves the right
                                to terminate the User's access to any or all of such communication
                                services provided by company at any time without notice for any reason
                                whatsoever.</span></p>
                            <p className="c1"><span className="c0">company reserves
                                the right at all times to disclose any information as is necessary to
                                satisfy or comply with any applicable law, regulation, legal process or
                                governmental request, or to edit, refuse to post or to remove any
                                information or materials, in whole or in part, in company's sole
                                discretion.</span></p>
                            <p className="c1"><span className="c0">company does not
                                control or endorse the content, messages or information found in any
                                communication service and, therefore, company specifically disclaims any
                                liability or responsibility whatsoever with regard to the communication
                                services and any actions resulting from the user's participation in any
                                communication service.</span></p>
                            <p className="c1"><span className="c0">You
                                agree that You are solely responsible to company and to any third party
                                for any breach of Your obligations under the Terms and for the
                                consequences (including any loss or damage which company or its
                                affiliates or its vendors may suffer) for any such breach.</span></p>
                            <p className="c1"><span className="c0">You
                                agree and acknowledge that company is not the Vendor of the Products
                                and company shall in no manner be deemed to be the Vendor of the
                                Products on this Platform. company is only facilitating purchase of the
                                Products by You from the vendors by providing the services to You.</span></p>
                            <p className="c1"><span className="c0">You
                                agree that company may, at any time, modify or discontinue all or part
                                of the Platform, charge, modify or waive fees required to use the
                                Platform, or offer opportunities to some or all Platform Users.</span></p>

                            <p className="font-weight-bold"><span className="c2">Use of Materials</span></p>

                            <p className="c1"><span className="c0">Except
                                as expressly indicated to the contrary in any applicable Additional
                                Terms, company hereby grants You a personal, non-exclusive, freely
                                revocable (upon notice from company), non-transferable access to view,
                                download and print product catalogues or any other materials available
                                on the Platform, subject to the following condition:</span></p>
                            <p className="c1"><span className="c0">You
                                may access and use the materials solely for personal, informational,
                                and internal purposes, in accordance with the Terms;You may not modify
                                or alter Product catalogues or any other materials available on the
                                Platform;You may not distribute or sell, rent, lease, license or
                                otherwise make the Product catalogues or any other materials available
                                on the Platform available to others; and You may not remove any text,
                                copyright or other proprietary notices contained in the Product
                                catalogues or any other materials available on the Platform.</span></p>
                            <p className="c1"><span className="c0">The
                                rights granted to You in the Product catalogues or any other materials
                                as specified above are not applicable to the design, layout or look and
                                feel of the Platform. Such elements of the Platform are protected by
                                intellectual property rights and may not be copied or imitated in whole
                                or in part. The Product catalogues or any other materials available on
                                the Platform shall not be copied or retransmitted unless expressly
                                permitted by company.</span></p>
                            <p className="c1"><span className="c0">Any
                                software that is available on the Platform is the property of company or
                                its vendors. You may not use, download or install any software
                                available at the Platform, unless otherwise expressly permitted by these
                                Terms or by the express written permission of company.</span></p>
                            <p className="c1"><span className="c0">Any
                                purchase of the merchandise or Services from the Platform will be
                                strictly for personal use of the User. The User hereby expressly agrees
                                that any merchandize or Services purchased by the User will not be sold,
                                resold, bartered or in any way used for any commercial purposes or for
                                profit. The User hereby acknowledges that the Services or merchandize
                                purchased is not transferrable to any third party for profit.</span></p>

                            <p className="font-weight-bold"><span className="c2">Usage Conduct</span></p>

                            <p className="c1"><span className="c0">You
                                shall solely be responsible for maintaining the necessary computer
                                equipments and Internet connections that may be required to access, use
                                and transact on the Platform.</span></p>
                            <p className="c1"><span className="c0">You
                                are also under an obligation to use this Platform for reasonable and
                                lawful purposes only, and shall not indulge in any activity that is not
                                envisaged through the Platform.</span></p>
                            <p className="c1"><span className="c0">You
                                shall use this Platform, and any voucher/ coupons purchased through it,
                                for personal, non-commercial use only and shall not re-sell the same to
                                any other person.</span></p>

                            <p className="font-weight-bold">Intellectual Property Rights</p>

                            <p className="c1"><span className="c0">The
                                Platform and the processes, and their selection and arrangement,
                                including but not limited to all text, graphics, user interfaces, visual
                                interfaces, sounds and music (if any), artwork and computer code
                                (collectively, the "Content") on the Platform is owned and controlled by
                                company and the design, structure, selection, coordination, expression,
                                look and feel and arrangement of such Content is protected by
                                copyright, patent and trademark laws, and various other intellectual
                                property rights.</span></p>
                            <p className="c1"><span className="c0">The
                                trademarks, logos and service marks displayed on the Platform ("Marks")
                                are the property of company or their vendors or respective third
                                parties. You are not permitted to use the Marks without the prior
                                consent of company, the relevant vendor or the third party that may own
                                the Marks.</span></p>
                            <p className="c1"><span className="c0">Unless otherwise
                                indicated or anything contained to the contrary or any proprietary
                                material owned by a third party and so expressly mentioned, company owns
                                all intellectual property rights to and into the trademark </span><span className="c0 c4"><a
                                    className="c6"  >breathefreeeducator.com</a></span><span
                                        className="c0">,
                                    and the Platform, including, without limitation, any and all rights,
                                    title and interest in and to copyright, related rights, patents, utility
                                    models, designs, know-how, trade secrets and inventions (patent
                                    pending), goodwill, source code, meta tags, databases, text, content,
                                    graphics, icons, and hyperlinks.</span></p>
                            <p className="c1"><span className="c0">Except
                                as expressly provided herein, You acknowledge and agree that You shall
                                not copy, republish, post, display, translate, transmit, reproduce or
                                distribute any Content through any medium without obtaining the
                                necessary authorization from company or third party owner of such
                                Content.</span></p>

                            <p className="font-weight-bold">Third Party Content</p>

                            <p className="c1"><span className="c0">The
                                Platform makes available general third party information such as,
                                Product catalogues, lists of vendors, technology and features,
                                advertisements including videos, images and photographs of the products
                                and other data from external sources ("Third Party Content"). Similar
                                Third Party Content would also be available to You on the email received
                                by You from company. The provision of Third Party Content is for
                                general informational purposes only. You acknowledge that the Third
                                Party Content provided to You is obtained from sources believed to be
                                reliable. company does not provide any guarantee with respect to any the
                                Third Party Content and company shall not be held liable for any loss
                                suffered by You based on Your reliance on or use of such data.</span></p>

                            <p className="font-weight-bold">Disclaimer Of Warranties &amp; Liability</p>

                            <p className="c1"><span className="c0">You expressly understand and agree that, to the maximum extent
                                permitted by applicable law:</span></p>
                            <p className="c1"><span className="c0">the
                                website, services and other materials are provided by company on an "as
                                is" basis without warranty of any kind, express, implied, statutory or
                                otherwise, including the implied warranties of title, non-infringement,
                                merchantability or fitness for a particular purpose. without limiting
                                the foregoing, company makes no warranty that (i) the Platform or the
                                services will meet your requirements or your use of the Platform or the
                                services will be uninterrupted, timely, secure or error-free; (ii) the
                                results that may be obtained from the use of the Platform, services or
                                materials will be effective, accurate or reliable; (iii) the quality of
                                the Platform, services or other materials will meet your expectations;
                                or that (iv) any errors or defects in the Platform, services or other
                                materials will be corrected. No advice or information, whether oral or
                                written, obtained by you from company or through or from use of the
                                services shall create any warranty not expressly stated in the terms.</span></p>
                            <p className="c1"><span className="c0">to
                                the maximum extent permitted by applicable law, company will have no
                                liability related to user content arising under intellectual property
                                rights, libel, privacy, publicity, obscenity or other laws. company also
                                disclaims all liability with respect to the misuse, loss, modification
                                or unavailability of any user content.</span></p>
                            <p className="c1"><span className="c0">company
                                will not be liable for any loss that you may incur as a consequence of
                                unauthorized use of Your Account or Account information in connection
                                with the Platform or any services or materials, either with or without
                                your knowledge. company has endeavored to ensure that all the
                                information on the Platform is correct, but company neither warrants nor
                                makes any representations regarding the quality, accuracy or
                                completeness of any data, information, product or service. company shall
                                not be responsible for the delay or inability to use the website or
                                related functionalities, the provision of or failure to provide
                                functionalities, or for any information, software, products,
                                functionalities and related graphics obtained through the Platform, or
                                otherwise arising out of the use of the Platform, whether based on
                                contract, tort, negligence, strict liability or otherwise. further,
                                company shall not be held responsible for non-availability of the
                                website during periodic maintenance operations or any unplanned
                                suspension of access to the website that may occur due to technical
                                reasons or for any reason beyond company's control. The user understands
                                and agrees that any material or data downloaded or otherwise obtained
                                through the Platform is done entirely at their own discretion and risk
                                and they will be solely responsible for any damage to their computer
                                systems or loss of data that results from the download of such material
                                or data. company is not responsible for any typographical error leading
                                to an invalid coupon. company accepts no liability for any errors or
                                omissions, with respect to any information provided to you whether on
                                behalf of itself or third parties.</span></p>
                            <p className="c1"><span className="c0">company
                                shall not be liable for any third party product or services. the
                                advertisement available on e-mail or website with respect to the third
                                party website or the Products are for information purpose only.</span></p>
                            <p className="c1"><span className="c0">You expressly agree that Your use of the Platform is at Your
                                risk.</span></p>
                            <p className="c1"><span className="c2">Indemnification and Limitation of Liability</span></p>
                            <p className="c1"><span className="c0">You
                                agree to indemnify, defend and hold harmless company including but not
                                limited to its affiliates, vendors, agents and employees from and
                                against any and all losses, liabilities, claims, damages, demands, costs
                                and expenses (including legal fees and disbursements in connection
                                therewith and interest chargeable thereon) asserted against or incurred
                                by company that arise out of, result from, or may be payable by virtue
                                of, any breach or non-performance of any representation, warranty,
                                covenant or agreement made or obligation to be performed by You pursuant
                                to these Terms. Further, You agree to hold company harmless against any
                                claims made by any third party due to, or arising out of, or in
                                connection with, Your use of the Platform, any claim that Your material
                                caused damage to a third party, Your violation of the Terms, or Your
                                violation of any rights of another, including any intellectual property
                                rights.</span></p>
                            <p className="c1"><span className="c0">Notwithstanding
                                anything to contrary, company's entire liability to You under this Terms
                                or otherwise shall be the refund of the money charged from You for any
                                specific voucher or Product or service, under which the unlikely
                                liability arises.</span></p>
                            <p className="c1"><span className="c0">In no event
                                shall company, its officers, directors, employees, partners or suppliers
                                be liable to You, the vendor or any third party for any special,
                                incidental, indirect, consequential or punitive damages whatsoever,
                                including those resulting from loss of use, data or profits, whether or
                                not foreseeable or whether or not company has been advised of the
                                possibility of such damages, or based on any theory of liability,
                                including breach of contract or warranty, negligence or other tortious
                                action, or any other claim arising out of or in connection with Your use
                                of or access to the Platform, services or materials.</span></p>
                            <p className="c1"><span className="c0">company
                                is not responsible for any non-performance or breach of any contract
                                entered into between Buyers and Sellers. company cannot and does not
                                guarantee that the concerned Buyers and/or Sellers will perform any
                                &lt;no transactions are entered into&gt; on the Platform. company shall
                                not and is not required to mediate or resolve any dispute or
                                disagreement between Buyers and Sellers.</span></p>
                            <p className="c1"><span className="c0">company
                                does not at any point of time during any transaction between Buyer and
                                Seller on the Platform come into or take possession of any of the
                                products or services offered by Seller nor does it at any point gain
                                title to or have any rights or claims over the products or services
                                offered by Seller to Buyer.</span></p>
                            <p className="c1"><span className="c0">It
                                no time shall company hold any right, title or interest over the
                                products nor shall company have any obligations or liabilities in
                                respect of such contract entered into between Buyers and Sellers.
                                company is not responsible for unsatisfactory or delayed performance of
                                services or damages or delays as a result of products which are out of
                                stock, unavailable or back ordered.</span></p>
                            <p className="c1"><span className="c0">The limitations and exclusions in this section apply to the maximum
                                extent permitted by applicable law.</span></p>

                            <p className="font-weight-bold">Violation of the Terms</p>

                            <p className="c1"><span className="c0">You
                                agree that company may, in its sole discretion and without prior
                                notice, terminate Your access to the Platform and block Your future
                                access to the Platform if company determines that You have violated
                                these Terms or Additional Terms. You also agree that any violation by
                                You of these Terms will constitute an unlawful and unfair business
                                practice, and will cause irreparable harm to company, for which monetary
                                damages would be inadequate, and You consent to company obtaining any
                                injunctive or equitable relief that company deems necessary or
                                appropriate in such circumstances. These remedies are in addition to any
                                other remedies company may have at law or in equity.</span></p>
                            <p className="c1"><span className="c0">You
                                agree that company may, in its sole discretion, and without prior
                                notice, terminate Your access to the Platform, for cause, which includes
                                (but is not limited to): (1) requests by law enforcement or other
                                government agencies; (2) a request by You (self-initiated account
                                deletions); (3) discontinuance or material modification of the Platform
                                or any service offered on or through the Platform; or (4) unexpected
                                technical issues or problems.</span></p>
                            <p className="c1"><span className="c0">If
                                company does take any legal action against You as a result of Your
                                violation of these Terms, company will be entitled to recover from You,
                                and You agree to pay, all reasonable attorneys' fees and costs of such
                                action, in addition to any other relief granted to company.</span></p>

                            <p className="font-weight-bold"><span>Termination</span></p>

                            <p className="c1"><span className="c0">The
                                Terms will continue to apply until terminated by either You or company
                                as set forth below. If You want to terminate Your agreement with
                                company, You may do so by (i) not accessing the Platform; or (ii)
                                closing Your accounts for all of the services that You use, where
                                company has made this option available to You.</span></p>
                            <p className="c1"><span className="c0">company
                                may, at any time, with or without notice, terminate the Terms (or
                                portion thereof, such as any individual Additional Terms) with You if:</span></p>
                            <p className="c1"><span className="c0">You
                                breach any of the provisions of the Terms, the Privacy Policy or any
                                other terms, conditions, or policies that may be applicable to You from
                                time to time (or have acted in a manner that clearly shows that You do
                                not intend to, or are unable to, comply with the same);company is
                                required to do so by law (for example, where the provision of the
                                services hereunder, to You is, or becomes, unlawful);The provision of
                                the services to You, by company is, in company's opinion, no longer
                                commercially viable;company has elected to discontinue, with or without
                                reason, access to the Platform, the services (or any part thereof); or</span></p>
                            <p className="c1"><span className="c0">company
                                may also terminate or suspend all or a portion of Your account or
                                access to the services with or without reason. Except as may be set
                                forth in any Additional Terms applicable to a particular service,
                                termination of Your Account may include: (i) removal of access to all
                                offerings within the Platform or with respect to the services; (ii)
                                deletion of Your materials and Account Information, including Your
                                personal information, log-in ID and password, and all related
                                information, files and materials associated with or inside Your Account
                                (or any part thereof); and (iii) barring of further use of the Services.</span></p>
                            <p className="c1"><span className="c0">You
                                agree that all terminations shall be made in company's sole discretion
                                and that company shall not be liable to You or any third party for any
                                termination of Your Account (and accompanying deletion of Your Account
                                Information), or Your access to the Platform and the services offered
                                thereunder.</span></p>
                            <p className="c1"><span className="c0">Notwithstanding the foregoing, these Terms will survive indefinitely
                                unless and until company chooses to terminate them.</span></p>
                            <p className="c1"><span className="c0">If
                                You or company terminates Your use of the Platform, company may delete
                                any content or other materials relating to Your use of the Platform and
                                company will have no liability to You or any third party for doing so.</span></p>

                            <p className="font-weight-bold">Governing Law</p>

                            <p className="c1"><span className="c0">These
                                Terms and all transactions entered into on or through the Platform and
                                the relationship between You and company shall be governed in accordance
                                with the laws of nepal without reference to conflict of laws
                                principles.</span></p>
                            <p className="c1"><a name="h.gjdgxs"></a><span className="c0">You
                                agree that all claims, differences and disputes arising under or in
                                connection with or in relation hereto the Platform, the Terms or any
                                transactions entered into on or through the Platform or the relationship
                                between You and company shall be subject to the exclusive jurisdiction
                                of the courts at Gurgaon, nepal and You hereby accede to and accept the
                                jurisdiction of such courts.</span></p>

                            <p className="font-weight-bold">Report Abuse</p>

                            <p className="c1"><span className="c0">In
                                the event You come across any abuse or violation of these Terms or if
                                You become aware of any objectionable content on the Platform, please
                                report to company’s customer support team.</span></p>

                            <p className="font-weight-bold">Privacy Policy</p>

                            <p className="c1"><span className="c0">company
                                views protection of Your privacy as a very important principle. We
                                store and process Your Account Information including any sensitive
                                personal / financial information collected (as defined under the
                                Information Technology Act, 2000), if any, on computers that may be
                                protected by physical as well as reasonable technological security
                                measures and procedures in accordance with Information Technology Act
                                2000 and Rules there under. company’s current Privacy Policy is
                                available at </span><span className="c0 c4"><a className="c6"
                                     >breathefreeeducator.com</a></span><span className="c0">. If You
                                        object to Your Information being transferred or used in this way please do not use
                                        Platform.</span></p>
                            <p className="c1"><span className="c0">company
                                will share / sell / transfer / license / covey some or all of your
                                personal information with another business entity should we (or our
                                assets) plan to merge with or are acquired by that business entity, or
                                re-organization, amalgamation, restructuring of business or for any
                                other reason whatsoever. Should such a transaction or situation occur,
                                the other business entity or the new combined entity will be required to
                                follow the privacy policy with respect to Your personal information.
                                Once You provide Your information to us, You provide such information to
                                company and affiliates of company and company and its affiliate may use
                                such information to provide You various services with respect to Your
                                transaction whether such transaction are conducted on </span><span className="c0 c4"><a className="c6"
                                     >breathefreeeducator.com</a></span><span className="c0">&nbsp;or
                                        with third party merchant's or third party merchant's website.</span></p>
                            <p className="c1"><span className="c0">The
                                User hereby consents, expresses and agrees that he has read and fully
                                understands the Privacy Policy of company in respect of the Platform.
                                You further consent that the terms and contents of such Privacy Policy
                                are acceptable to You.</span></p>

                            <p className="font-weight-bold">Communications</p>

                            <p className="c1"><span className="c0">You
                                hereby expressly agree to receive communications by way of SMS, e-mails
                                from company, phone calls relating to the Products offered through the
                                Platform.</span></p>
                            <p className="c1"><span className="c0">A User can unsubscribe / opt-out from receiving communications from
                                company through SMS and e-mail anytime by:</span></p>
                            <p className="c1"><span className="c0">Visiting </span><span className="c0 c4"><a className="c6"
                                 >breathefreeeducator.com</a></span><span className="c0">&nbsp;to
                                    unsubscribe from messages/ SMS; andNewsletters sent daily at the registered email address:</span>
                            </p>
                            <p className="c1"><span className="c0">by clicking on the unsubscribe option attached at the bottom of
                                newsletter received through e-mail</span></p>

                            <p className="font-weight-bold">General Provisions</p>

                            <p className="c1"><span className="c0">Notice:
                                All notices of company will be served by email or by general
                                notification on the Platform. Any notice provided to company pursuant to
                                the Terms should be sent to </span><span className="c5"><a className="c6"
                                    href="mailto:info@thb.co.in">info</a></span><span className="c5 c7"><a className="c6"
                                        href="mailto:info@thb.co.in">@thb.co.in</a></span><span className="c0">&nbsp; &nbsp;</span></p>
                            <p className="c1"><span className="c0">Assignment:
                                You cannot assign or otherwise transfer the Terms, or any rights
                                granted hereunder to any third party. Company's rights under the Terms
                                are freely transferable by company to any third parties without the
                                requirement of seeking Your consent.</span></p>
                            <p className="c1"><span className="c0">Severability:
                                If, for any reason, a court of competent jurisdiction finds any
                                provision of these Terms, or portion thereof, to be unenforceable, that
                                provision shall be enforced to the maximum extent permissible so as to
                                give effect to the intent of the parties as reflected by that provision,
                                and the remainder of the Terms shall continue in full force and effect.</span></p>
                            <p className="c1"><span className="c0">Waiver:
                                Any failure by company to enforce or exercise any provision of the
                                Terms, or any related right, shall not constitute a waiver by company of
                                that provision or right.</span></p>


                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default PlainTermsAndConditions;