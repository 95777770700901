// let showTemplateGenerator = sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole').indexOf("support") !== -1 ? true : false;
const supportNavBarRoutes = [{
    type: 'single',
    navbarName: "Error Logs",
    key: "error-logs",
    display: true,
    isActive: false,
    route: `/error-logs`
}, {
    type: 'single',
    navbarName: "Call Logs",
    key: "call-logs",
    display: true,
    isActive: false,
    route: `/call-logs`
}, {
    type: 'single',
    navbarName: "Appointment Logs",
    key: "appointment-logs",
    display: true,
    isActive: false,
    route: `/appointment-logs`
}, {
    type: 'single',
    navbarName: "Account",
    key: "Account",
    display: true,
    isActive: false,
    icon: "Account",
    route: `/account-list`
}, {
    type: 'single',
    navbarName: "Appointment Summary",
    key: "appt-summary",
    display: true,
    isActive: false,
    icon: "",
    route: `/appointment-summary`
}];


export default supportNavBarRoutes;