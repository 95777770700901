import React from "react";
import { Modal, Container } from 'react-bootstrap';


class CommonModalView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: this.props.open
        }
    }

    onCloseEventClick = (event) => {
        this.props.emitModalCloseEvent(false)
    }

    render() {
        return (
            <Modal
            
                size={this.props.size || '' }
                show={this.props.open}
                onHide={this.onCloseEventClick.bind(this)}>
                <Modal.Header closeButton={!this.props.hideCloseButton}>
                    <Modal.Title className="modal-title">{this.props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container style={{ padding: 0 }}>
                        {this.props.children}
                    </Container>
                </Modal.Body>
            </Modal>

        )
    }
}

export default CommonModalView;