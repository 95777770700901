import React from "react";
// component imports
import CompactTableColumnViewComponent from './Column/CompactTableColumnViewComponent';
import CompactTableChildrenViewComponent from './Children/CompactTableChildrenViewComponent';

class CompactTableRowComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            colErrorArray: [],
            childErrorArray: []
        }
        if (props.indRowData && props.indRowData.config &&
            props.indRowData.config.displaycondition && props.indRowData.config.displaycondition.length !== 0) {
            props.setConditionalHide({ class: props.indRowData.class, displaycondition: props.indRowData.config.displaycondition, rowIndex: props.rowIndex });
        }
    }

    componentDidMount() {

    }


    getEmitedChildTextboxValue = (childIndex, columnIndex, event) => {
        this.props.emitChildTextboxValue(childIndex, columnIndex, event);
    }

    getEmitedTextboxValue = (index, isMultiple, event) => {
        this.props.emitTextboxValue(index, event);
    }

    getEmitedChildAddNewRowEvent = (childIndex, columnIndex, event) => {
        this.props.emitChildAddNewRowEvent(childIndex, columnIndex, event)
    }

    getEmitedAddNewRowEvent = (index, event) => {
        this.props.emitAddNewRowEvent(index, event)
    }

    getEmitedChildDeleteRowObjectData = (childIndex, columnIndex, event) => {
        this.props.emitChildDeleteRowObjectData(childIndex, columnIndex, event)
    }

    getEmitedDeleteRowObjectData = (index, event) => {
        this.props.emitDeleteRowObjectData(index, event)
    }

    getEmitedChildSuggestionEvent = (childIndex, event) => {
        this.props.emitChildSuggestionEvent(childIndex, event);
    }

    getEmitedSuggestionEvent = (index, event) => {
        this.props.emitSuggestionEvent(index, event);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.checkForm) {
            return true;
        }
        // if (!nextProps.isView) {
        if (nextProps.fieldConfig) {
            if (nextProps.fieldConfig.type === "group" && (nextProps.fieldConfig.sectionIndex === nextProps.sectionIndex && nextProps.fieldConfig.rowIndex === nextProps.rowIndex)) {
                return true;
            } else if (nextProps.fieldConfig.sectionIndex === nextProps.sectionIndex && nextProps.fieldConfig.rowIndex === nextProps.rowIndex) {
                return true;
            }
            return false;
        }
        return true;
        // }
        // return true;
    }

    setConfigForFormula = (e) => {
        this.props.setConfigForFormula({ ...e, rowIndex: this.props.rowIndex })
    }

    setConditionalHide = (e) => {
        this.props.setConditionalHide({ ...e, rowIndex: this.props.rowIndex })
    }

    setErrorForChild = (childIndex, errorStatus) => {
        let { childErrorArray } = this.state;
        let index = childErrorArray.indexOf(childIndex);
        if (index !== -1 && !errorStatus) {
            childErrorArray.splice(index, 1);
        } else if (index === -1 && errorStatus) {
            childErrorArray.push(childIndex);
        }
        this.setState({ childErrorArray }, () => this.props.emitErrorStatus(childErrorArray.length ? true : false));
    }

    setErrorForColumn = (colIndex, errorStatus) => {
        let { colErrorArray } = this.state;
        let index = colErrorArray.indexOf(colIndex);
        if (index !== -1 && !errorStatus) {
            colErrorArray.splice(index, 1);
        } else if (index === -1 && errorStatus) {
            colErrorArray.push(colIndex);
        }
        this.setState({ colErrorArray }, () => this.props.emitErrorStatus(colErrorArray.length ? true : false));
    }

    render() {
       
        const { indRowData } = this.props;
        let show = !indRowData.class || indRowData.class !== 'deferRendering hide';
        return show &&
            <React.Fragment>
                {indRowData.type === "subSection" ? (
                    <>
                        {indRowData.children && indRowData.children.map((indChildrenData, childIndex) =>


                            <CompactTableChildrenViewComponent
                                rowType={indRowData.type}
                                rowId={indRowData.rowId}
                                rowIndex={indRowData.index}
                                childIndex={childIndex}
                                fieldConfig={this.props.fieldConfig}
                                indChildrenData={indChildrenData}
                                showDelete={indRowData.children.length > 1}

                                setConditionalHide={this.setConditionalHide.bind(this)}
                                emitChildTextboxValue={this.getEmitedChildTextboxValue.bind(this, childIndex)}
                                emitChildAddNewRowEvent={this.getEmitedChildAddNewRowEvent.bind(this, childIndex)}
                                emitChildDeleteRowObjectData={this.getEmitedChildDeleteRowObjectData.bind(this, childIndex)}
                                setConfigForFormula={this.setConfigForFormula.bind(this)}
                                checkForm={this.props.checkForm}
                                emitErrorStatus={this.setErrorForChild.bind(this, childIndex)}
                                emitChildSuggestionEvent={this.getEmitedChildSuggestionEvent.bind(this, childIndex)}>
                            </CompactTableChildrenViewComponent>
                        )}
                    </>
                ) : (
                        <>
                            {indRowData.columns && indRowData.columns.map((indColumnData, index) => {
                                return <React.Fragment key={index}>
                                    <CompactTableColumnViewComponent
                                        colIndex={index}
                                        checkForm={this.props.checkForm}
                                        emitErrorStatus={this.setErrorForColumn.bind(this, index)}
                                        rowType={indRowData.type}
                                        rowId={indRowData.rowId}
                                        rowIndex={indRowData.index}
                                        indColumnData={indColumnData}
                                        setConfigForFormula={this.setConfigForFormula.bind(this)}
                                        emitTextboxValue={this.getEmitedTextboxValue.bind(this, index, indRowData.ismultiple)}
                                        emitAddNewRowEvent={this.getEmitedAddNewRowEvent.bind(this, index)}
                                        emitDeleteRowObjectData={this.getEmitedDeleteRowObjectData.bind(this, index)}
                                        emitSuggestionEvent={this.getEmitedSuggestionEvent.bind(this, index)}>
                                    </CompactTableColumnViewComponent>
                                </React.Fragment>
                            })}
                        </>
                    )

                }

            </React.Fragment>
            ;
    }
}

export default CompactTableRowComponent;
