function logout(history) {

    const nativeMode = sessionStorage.getItem('nativeMode');
    const accInfo = JSON.parse(sessionStorage.getItem('indData'))
    let loginUrl = accInfo?.account_id === "breathefree" ? "/breathefree/doctor-login" : (nativeMode === 'true' || nativeMode === true) ? '/login' : '/doctor-login';
    const logoutString = sessionStorage.getItem('logoutString');
    if ((nativeMode === 'true' || nativeMode === true) && !!logoutString) {
        loginUrl += '?' + logoutString;
        try {
            let obj = {
                "req": {},
                "action": "logout",
                "path": window.location.href
            }
            window.external.notify(JSON.stringify(obj));
        }
        catch (e) {
            console.log("Logout notify threw error!");
        }
    }
    // default action
    history.push(loginUrl);
}

function clearSS() {
    sessionStorage.setItem('showUser', '');
    sessionStorage.setItem('apiStage', '');
    sessionStorage.setItem('emrToken', '');
    sessionStorage.setItem('isSuperUser', '');
    sessionStorage.setItem('s3AccountId', '');
    sessionStorage.setItem('templateResponse', '');
    sessionStorage.setItem('userId', '');
    sessionStorage.setItem('hideprescription', '');
    sessionStorage.setItem('identityId', '');
    sessionStorage.setItem('dataAdmin', '');
    sessionStorage.setItem('dataUser', '');
    sessionStorage.setItem('substaff', '');
    sessionStorage.setItem('hardRefresh', '');
}

function callLogoutEvents(history) {
    clearSS();
    logout(history);
}

const logoutService = {
    logout,
    clearSS,
    callLogoutEvents
}

export default logoutService;