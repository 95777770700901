import React from "react";
import HeaderViewComponent from './Common/HeaderViewComponent';
import View from '../../../Shared/utils/CommonTableView';
const $ = window["jQuery"];

class AccountViewComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			accounts: this.props.accountListData.rows,
			filters: this.props.accountListData.filters,
			visibleHeader: this.props.accountListData.visible,
			accountListData: this.props.accountListData,
			formatedHeader: null,
			isSupport: sessionStorage.getItem('isSupport'),
			finalTableData: [],
			filteredData: [],
			loading: this.props.loading
		}
		this.formatHeader()
		this.formTableBodyData();
	}

	formatHeader() {
		if (!this.state.visibleHeader || this.state.visibleHeader.length === 0) {
			return;
		}
		let finalHeader = [];
		for (let index in this.state.visibleHeader) {
			if (this.state.visibleHeader[index].indexOf("_") !== -1) {
				let strArray = this.stringSpiltForHeader(this.state.visibleHeader[index], '_');
				let indObj = {
					key: this.state.visibleHeader[index],
					value: strArray.join(" ")
				}
				finalHeader.push(indObj);
			} else {
				let strArray = this.state.visibleHeader[index];
				let indObj = {
					key: this.state.visibleHeader[index],
					value: strArray
				}
				finalHeader.push(indObj);
			}
		}
		this.state.formatedHeader = finalHeader;
		this.setState(this.state);
	}

	stringSpiltForHeader(value, seperate) {
		return value.split(seperate);
	}

	formTableBodyData() {
		if (!this.state.accounts || (this.state.accounts && this.state.accounts.length === 0)) {
			this.state.finalTableData = [];
			this.state.filteredData = [];
			this.setState(this.state);
			return;
		}
		this.state.finalTableData = [];
		this.setState(this.state);
		let finalData = []
		for (let index in this.state.accounts) {
			let indBodyData = new Array(this.state.visibleHeader.length).fill('-');
			let indUserData = this.state.accounts[index];
			for (let key in indUserData) {
				if (this.state.visibleHeader.indexOf(key) !== -1) {
					let dataIndex = this.state.visibleHeader.indexOf(key);
					if (typeof indUserData[key] === "object") {
						let indObjData = indUserData[key];
						for (let values in indObjData) {
							let str = '';
							str = str + '' + values + " : " + indObjData[values] + " ,";
							indBodyData[dataIndex] = str;
						}
					} else {
						indBodyData[dataIndex] = indUserData[key] ? indUserData[key] : '-';
					}
				}
			}
			finalData.push(indBodyData);
		}
		this.state.finalTableData = finalData;
		this.state.filteredData = finalData;
		this.setState(this.state);
	}

	// static getDerivedStateFromProps(nextProps, prevState) {
	//     if (nextProps.accountListData !== prevState.accountListData) {
	//         return {
	//             accounts: nextProps.accountListData.rows,
	//             filters: nextProps.accountListData.filters,
	//             visibleHeader: nextProps.accountListData.visible
	//         };
	//     }
	//     else return null;
	// }

	// componentDidUpdate(prevProps, prevState) {
	//     if (prevProps.accountListData !== this.state.accountListData) {
	//         //Perform some operation here
	//         this.formTableBodyData()
	//     }
	// }

	componentWillReceiveProps(nextProps) {
		// if (nextProps.loading !== this.state.loading) {
		//     this.state.loading = nextProps.loading;
		//     this.setState(this.state);
		// }
		if (this.state.accountListData !== nextProps.accountListData) {
			this.setState({
				accounts: nextProps.accountListData.rows,
				filters: nextProps.accountListData.filters,
				visibleHeader: nextProps.accountListData.visible
			}, () => this.formTableBodyData())

		}
	}

	onAccountEditEvent = (indData, dataIndex, event) => {
		let index = this.state.visibleHeader.indexOf("account_id");
		let accountId = indData[index];
		let acData = this.props.accountListData && this.props.accountListData.rows ? this.props.accountListData.rows.find(u => u.account_id === accountId) : null;
		this.props.emitAccountEditEvent('/edit-account?id=' + accountId, acData);
	}

	onAddNewUserAddButtonClicked = (indData, event) => {
		
		let index = this.state.visibleHeader.indexOf("account_id");
		let nameIndex = this.state.visibleHeader.indexOf("account_name");
		let accountId = indData[index];
		let accountName = indData[nameIndex];
		let acData = this.props.accountListData && this.props.accountListData.rows ? this.props.accountListData.rows.find(u => u.account_id === accountId) : null;
		this.props.emitAddNewUserEventWithAccountId(accountId, accountName, acData);
	}

	onAddTemplateButtonClicked = (indData) => {
		let index = this.state.visibleHeader.indexOf("account_id");
		let nameIndex = this.state.visibleHeader.indexOf("account_name");
		let accountId = indData[index];
		let accountName = indData[nameIndex];
		let acData = this.props.accountListData && this.props.accountListData.rows ? this.props.accountListData.rows.find(u => u.account_id === accountId) : null;
		this.props.emitAddTemplateWithAccountId(accountId, accountName, acData);
	}

	onUploadLogo = (indData) => {
		let index = this.state.visibleHeader.indexOf("account_id");
		let accountId = indData[index];
		let acData = this.props.accountListData && this.props.accountListData.rows ? this.props.accountListData.rows.find(u => u.account_id === accountId) : null;
		this.props.onUploadLogo(accountId, acData);
	}

	checkForInvoiceVisibility(indData) {
		let index = this.state.visibleHeader.indexOf("account_type");
		let accountType = indData[index];
		return true;
		// if (accountType === "Clinic") {
		//     return true;
		// }
		// return false; 
	}

	onInvoiceSettingsUpdate = (indData) => {
		let index = this.state.visibleHeader.indexOf("account_id");
		let accountId = indData[index];
		let acData = this.props.accountListData && this.props.accountListData.rows ? this.props.accountListData.rows.find(u => u.account_id === accountId) : null;
		this.props.onInvoiceSettingsUpdate(accountId, acData);
	}

	onBannerSettingsUpdate = (indData) => {
		let index = this.state.visibleHeader.indexOf("account_id");
		let accountId = indData[index];
		let acData = this.props.accountListData && this.props.accountListData.rows ? this.props.accountListData.rows.find(u => u.account_id === accountId) : null;
		this.props.onBannerSettingsUpdate(accountId, acData);
	}

	onCreateTemplateButtonClicked = (indData) => {
		let index = this.state.visibleHeader.indexOf("account_id");
		let accountId = indData[index];
		let acData = this.props.accountListData && this.props.accountListData.rows ? this.props.accountListData.rows.find(u => u.account_id === accountId) : null;
		this.props.emitCreateTemplateWithAccountId(accountId, acData);
	}

	onQuestionnaireButtonClicked = (indData) => {
		let index = this.state.visibleHeader.indexOf("account_id");
		let accountId = indData[index];
		let acData = this.props.accountListData && this.props.accountListData.rows ? this.props.accountListData.rows.find(u => u.account_id === accountId) : null;
		this.props.emitQuestionnaireWithAccountId(accountId, acData);
	}

	onTranslationButtonClicked = (indData) => {
		let index = this.state.visibleHeader.indexOf("account_id");
		let accountId = indData[index];
		let acData = this.props.accountListData && this.props.accountListData.rows ? this.props.accountListData.rows.find(u => u.account_id === accountId) : null;
		this.props.emitTranslationUpload(accountId, acData);
	}

	onViewAccountUserList = (indData) => {
		let index = this.state.visibleHeader.indexOf("account_id");
		let accountId = indData[index];
		let acData = this.props.accountListData && this.props.accountListData.rows ? this.props.accountListData.rows.find(u => u.account_id === accountId) : null;
		this.props.emitEventForAccountUserList(accountId, acData);
	}

	onBookingUploadEvent=(indData)=>{
		let index = this.state.visibleHeader.indexOf("account_id");
		let accountId = indData[index];
		let acData = this.props.accountListData && this.props.accountListData.rows ? this.props.accountListData.rows.find(u => u.account_id === accountId) : null;
		this.props.emitBookingUpload(accountId, acData);
		
	}

	onPaymentsClick = (indData) => {
		let index = this.state.visibleHeader.indexOf("account_id");
		let accountId = indData[index];
		let acData = this.props.accountListData && this.props.accountListData.rows ? this.props.accountListData.rows.find(u => u.account_id === accountId) : null;
		this.props.emitEventForPaymentClick(accountId, acData);
	}

	getEmitedSelectedFilerValues = (event) => {
		this.props.emitSelectedFilerValues(event)
	}

	componentDidMount() {
		$(function () {
			$('[data-toggle="tooltip"]').tooltip()
		})
	}

	componentWillUnmount() {
		$('[data-toggle="tooltip"]').tooltip('hide');
	}

	handleSearch = event => {
		this.props.emitSearchAcc(event.target.value);
	}

	checkForApiStage() {
		if (sessionStorage.getItem('apiStage') === "dev") {
			return true;
		}
		return false;
	}

	onMoveToProdEvent = (indData) => {
		let index = this.state.visibleHeader.indexOf("account_id");
		let accountId = indData[index];
		let acData = this.props.accountListData && this.props.accountListData.rows ? this.props.accountListData.rows.find(u => u.account_id === accountId) : null;
		this.props.emitMoveToProdEvent(accountId, acData);
	}

	render() {
		return (
			<div>
				<div className="container-fluid page-body-wrapper" >
					<div className="main-panel">
						<div className="content-wrapper" style={{ padding: '4.50rem 1.25rem  2.25rem  1.25rem' }}>
							{/* <div className="row "> */}
							{/* <div className="col-md-3 form-group mb-2">
                                    <label htmlFor="">Search</label>
                                    <input type="search" className="form-control" placeholder="Search"
                                        aria-controls="order-listing" onChange={this.handleSearch.bind(this)} />
                                </div>
                                <div className="col-md-9 align-self-end">
                                    <HeaderViewComponent emitSelectedFilerValues={this.getEmitedSelectedFilerValues.bind(this)} filters={this.state.filters}></HeaderViewComponent>
                                </div> */}

							{/* </div> */}
							{this.props.loading && <div className="clinytic-line-loader"></div>}
							<div className="row">

								<div className="col-12 grid-margin stretch-card">

									<div className="card ecrf-card-table">
										<div className="card-header header-sm align-items-center pb-0 pt-0">
											<div className="row justify-content-between" style={{ alignItems: 'center' }}>
												<div className="col  justify-content-between">
													<h4 style={{ display: 'inline-block' }} className="card-title">Account List</h4>
												</div>
												<div className="col-md-3 form-group mb-2">
													<label htmlFor="">Search</label>
													<input type="search" className="form-control" placeholder="Search by account id"
														aria-controls="order-listing" onChange={this.handleSearch.bind(this)} />
												</div>
												<div className="col-md-3 align-self-end">
													<HeaderViewComponent emitSelectedFilerValues={this.getEmitedSelectedFilerValues.bind(this)} filters={this.state.filters}></HeaderViewComponent>
												</div>

											</div>
										</div>
										<div className="card-body">
											{/* <h4 className="card-title">Account List</h4> */}

											<div className="table-responsive">
												<table className="table table-hover table-sm">
													<thead>
														{this.state.formatedHeader &&
															<tr style={{ textTransform: 'capitalize' }}>
																{this.state.formatedHeader.map((indHeader, index) =>
																	<th key={index}>{indHeader.value}</th>
																)}
																<th key={'actions'}>Actions</th>
															</tr>
														}

													</thead>
													<tbody>
														{this.state.finalTableData &&
															<React.Fragment>
																{this.state.finalTableData.map((indFinalData, index) =>
																	<tr key={index}>
																		{indFinalData.map((indRow, jndex) =>
																			<td key={jndex} className={jndex === 0 ? "" : ""}>{indRow}</td>
																		)}
																		{!this.state.isSupport ? <td>
																			<button className="btn btn-inverse-dark btn-icon btn-rounded mr-1" onClick={this.onAccountEditEvent.bind(this, indFinalData, index)}
																				title="Edit Account"><i className="mdi mdi-pencil-box btn-icon-append" ></i>
																			</button>

																			<button className="btn btn-inverse-dark btn-icon btn-rounded mr-1" onClick={this.onViewAccountUserList.bind(this, indFinalData)}
																				title="View User List"><i className="mdi mdi-view-list btn-icon-append"  ></i>
																			</button>
																			<button className="btn btn-inverse-dark btn-icon btn-rounded mr-1" onClick={this.onPaymentsClick.bind(this, indFinalData)}
																				title="View Payments"><i className="mdi mdi-currency-inr btn-icon-append"  ></i>
																			</button>
																			<button className="btn btn-inverse-dark btn-icon btn-rounded mr-1" onClick={this.onUploadLogo.bind(this, indFinalData, index)}
																				title="Upload logo & A/C info"><i className="mdi mdi-settings btn-icon-append"  ></i>
																			</button>
																			<button className="btn btn-inverse-dark btn-icon btn-rounded mr-1" onClick={this.onAddNewUserAddButtonClicked.bind(this, indFinalData)} title="Add New User"><i className="mdi mdi-account-multiple-plus btn-icon-append"></i></button>

																			{/* add template button */}

																			<button className="btn btn-inverse-dark btn-icon btn-rounded mr-1" onClick={this.onAddTemplateButtonClicked.bind(this, indFinalData)} title="Add Template"><i className="mdi  mdi-arrow-expand-down btn-icon-append"></i></button>
																			{/* invoice settings */}
																			{this.checkForInvoiceVisibility(indFinalData) &&
																				<button className="btn btn-inverse-dark btn-icon btn-rounded mr-1" onClick={this.onInvoiceSettingsUpdate.bind(this, indFinalData)} title="Invoice Settings"><i className="mdi  mdi-clipboard-text btn-icon-append"></i></button>
																			}
																			<button className="btn btn-inverse-dark btn-icon btn-rounded mr-1" onClick={this.onBannerSettingsUpdate.bind(this, indFinalData)} title="Banner Settings"><i className="mdi mdi-image-filter-frames btn-icon-append"></i></button>

																			{/* create new template button */}
																			<button className="btn btn-inverse-dark btn-icon btn-rounded mr-1" title="Translation Upload" onClick={this.onTranslationButtonClicked.bind(this, indFinalData)}><i className="mdi  mdi-google-translate btn-icon-append"></i></button>
																			{/* onBookingUploadEvent */}
																			<button className="btn btn-inverse-dark btn-icon btn-rounded mr-1" title="Booking Upload" onClick={this.onBookingUploadEvent.bind(this, indFinalData)}><i className="mdi  mdi-book-open btn-icon-append"></i></button>

																			<button className="btn btn-inverse-dark btn-icon btn-rounded mr-1" onClick={this.onCreateTemplateButtonClicked.bind(this, indFinalData)} title="Create New Template"><i className="mdi  mdi-folder-plus btn-icon-append"></i></button>
																			<button className="btn btn-inverse-dark btn-icon btn-rounded mr-1" onClick={this.onQuestionnaireButtonClicked.bind(this, indFinalData)} title="Create Question"><i className="mdi  mdi-playlist-check btn-icon-append"></i></button>

																			{/* {this.checkForApiStage() &&
                                                                                <button className="btn btn-inverse-dark btn-icon btn-rounded" onClick={this.onMoveToProdEvent.bind(this, indFinalData)} title="Move beta settings to prod"><i className="mdi  mdi-arrow-left-bold-circle btn-icon-append"></i></button>
                                                                            } */}


																		</td>
																			:
																			<td>
																				<button className="btn btn-inverse-dark btn-icon btn-rounded mr-1" onClick={this.onViewAccountUserList.bind(this, indFinalData)}
																					title="View User List"><i className="mdi mdi-view-list btn-icon-append"  ></i>
																				</button>
																			</td>
																		}
																	</tr>
																)}
															</React.Fragment>
														}
													</tbody>
												</table>
											</div>
											<div className="dropdown-divider"> </div>
											<div className="row">
												<div className="col-md-6"></div>
											</div>

										</div>
										<View.Paginate MAX_RECORDS={this.props.MAX_RECORDS} paginationDisable={this.props.paginationDisable} lastEvaluatedKey={this.props.lastEvaluatedKey}
											onLastEvaluatedKeyChange={this.props.setLastEvaluatedKey.bind(this)} />
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default AccountViewComponent;
