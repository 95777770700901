import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { subDays, addDays } from "date-fns";

// props
// maxDate : new Date() format
// minDate : new Date() format
// dateFormat : e.g dd-MM-yy
// class : e.g col-md-12 or 'no-class' if wrapper requires no class
// date : selected date
// handleChange : with return arg in new Date() format
function DateFilter(props) {
    const [currentDate, setCurrentDate] = useState(props.date ? new Date(Date.parse(props.date)) : new Date());

    const handleChange = (date) => {
        setCurrentDate(date);
        props.handleChange(date);
    }
    const onPrevClick = () => {
        let date = subDays(currentDate, 1);
        setCurrentDate(date);
        props.handleChange(date);
    }
    const onNextClick = () => {
        let date = addDays(currentDate, 1);
        setCurrentDate(date);
        props.handleChange(date);
    }

    const compareDate=(d1,d2)=>{
        return d1 && d2 &&  d1.getTime()>=d2.getTime()?true:false
    }

    return <div className={`${props.class ? props.class : 'col-12'} form-group mb-0`} style={{ maxWidth: "260px" }}>
        <nav className="ml-auto">
            <ul className="pagination pagination-success datepagination text-center mb-0">
                <li className="page-item active"  >
                    <a className={compareDate(props.minDate,currentDate)?"btn btn-sm btn-secondary disableElement":"page-link"} 
                        href="#" onClick={onPrevClick.bind(this)}>
                        <i className="mdi mdi-chevron-left"></i>
                    </a>
                </li>
                <li className="page-item  w-100" >
                    <DatePicker
                        disabled={props.disabled}
                        maxDate={props.maxDate}
                        minDate={props.minDate}
                        dateFormat={props.dateFormat ? props.dateFormat : "dd MMMM, yy"}
                        className="form-control"
                        selected={currentDate}
                        onChange={handleChange.bind(this)}
                    />
                </li>
                <li className="page-item active"  >
                    <a className={compareDate(currentDate,props.maxDate)?"btn btn-sm btn-secondary disableElement":"page-link"} 
                    href="#" onClick={onNextClick.bind(this)}> 
                    <i className="mdi mdi-chevron-right"></i>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
}

export default DateFilter;