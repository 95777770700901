export const PatientFormRequiredFields =   sessionStorage.getItem('s3AccountId') == "breathefree" ?  [
    'name',
    'country_code', 
    'phone', 
    'age', 
    'referred_doctor_name'
    // 'line1',
    // 'state',
    // 'country',
    // 'zipcode'
] :[
    'name',
    'country_code', 
    'phone', 
    'age', 
   'doctor_name',
] ;

export const UserFormRequiredFields = [
    'name',
    'country_code', 
    'role',
    'phone', 
    'age', 
    'doctor_id',
    // 'speciality'
];