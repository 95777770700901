import React, { Component } from 'react';
import Video from 'twilio-video';
import axios from 'axios';

export default class VideoChatRoomComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            identity: null,
            roomName: '',
            roomNameErr: false, // Track error for room name TextField
            previewTracks: null,
            localMediaAvailable: false,
            hasJoinedRoom: false,
            activeRoom: '' // Track the current active room
        };
        this.joinRoom = this.joinRoom.bind(this);
        this.handleRoomNameChange = this.handleRoomNameChange.bind(this);
        this.roomJoined = this.roomJoined.bind(this);
    }

    handleRoomNameChange(e) {
        let roomName = e.target.value;
        this.setState({ roomName });
    }
    
    joinRoom() {
        if (!this.state.roomName.trim()) {
            this.setState({ roomNameErr: true });
            return;
        }

        console.log("Joining room-- '" + this.state.roomName + "'...");
        let connectOptions = {
            name: this.state.roomName,
            region: 'in1',
            audio: true,
            video: { height: 480, frameRate: 24, width: 640 }
        };

        if (this.state.previewTracks) {
            connectOptions.tracks = this.state.previewTracks;
        }

        // Join the Room with the token from the server and the
        // LocalParticipant's Tracks.
        Video.connect(this.state.token, connectOptions).then(this.roomJoined, error => {
            alert('Could not connect to Twilio: ' + error.message);
        });
    }

    attachTracks(tracks, container) {
        tracks.forEach(track => {
            container.appendChild(track.attach());
        });
    }

    // Attaches a track to a specified DOM container
    attachParticipantTracks(participant, container) {
        var tracks = Array.from(participant.tracks.values());
        this.attachTracks(tracks, container);
    }

    detachTracks(tracks) {
        tracks.forEach(track => {
            track.detach().forEach(detachedElement => {
                detachedElement.remove();
            });
        });
    }

    detachParticipantTracks(participant) {
        var tracks = Array.from(participant.tracks.values());
        this.detachTracks(tracks);
    }

    roomJoined(room) {
        // Called when a participant joins a room
        console.log("Joined as '" + this.state.identity + "'");
        this.setState({
            activeRoom: room,
            localMediaAvailable: true,
            hasJoinedRoom: true
        });

        // Attach LocalParticipant's Tracks, if not already attached.
        var previewContainer = this.refs.localMedia;
        /* if (!previewContainer.querySelector('video')) {
            this.attachParticipantTracks(room.localParticipant, previewContainer);
        } */
        if (!previewContainer.querySelector('video')) {
            this.attachParticipantTracks(room.localParticipant, previewContainer);
        }

        // Attach the Tracks of the room's participants.
        room.participants.forEach(participant => {
            console.log("Already in Room: '" + participant.identity + "'");
            var previewContainer = this.refs.remoteMedia;
            this.attachParticipantTracks(participant, previewContainer);
        });

        // Participant joining room
        room.on('participantConnected', participant => {
            console.log("Joining: '" + participant.identity + "'");
        });

        // Attach participant’s tracks to DOM when they add a track
        room.on('trackAdded', (track, participant) => {
            console.log(participant.identity + ' added track: ' + track.kind);
            var previewContainer = this.refs.remoteMedia;
            this.attachTracks([track], previewContainer);
        });

        // Detach participant’s track from DOM when they remove a track.
        room.on('trackRemoved', (track, participant) => {
            this.log(participant.identity + ' removed track: ' + track.kind);
            this.detachTracks([track]);
        });

        // Detach all participant’s track when they leave a room.
        room.on('participantDisconnected', participant => {
            console.log("Participant '" + participant.identity + "' left the room");
            this.detachParticipantTracks(participant);
        });

        // Once the local participant leaves the room, detach the Tracks
        // of all other participants, including that of the LocalParticipant.
        room.on('disconnected', () => {
            if (this.state.previewTracks) {
                this.state.previewTracks.forEach(track => {
                    track.stop();
                });
            }
            this.detachParticipantTracks(room.localParticipant);
            room.participants.forEach(this.detachParticipantTracks);
            this.state.activeRoom = null;
            this.setState({ hasJoinedRoom: false, localMediaAvailable: false });
        });
    }

    componentDidMount() {
        // axios.get('http://localhost:8090/token?identity=ankur&roomName=4920986507396').then(results => {
        //     const { identity, token } = results.data;
        //     this.setState({ identity, token });
        // });
        const { identity, token } = { "identity": "Vedant", "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCIsImN0eSI6InR3aWxpby1mcGE7dj0xIn0.eyJqdGkiOiJTS2E4NDNiOTUwMTViN2Q4N2Y1ZmRhODg1YTk1MDU3YzE2LTE1ODY1MTQ2ODIiLCJncmFudHMiOnsiaWRlbnRpdHkiOiJWZWRhbnQiLCJ2aWRlbyI6eyJyb29tIjoiNDkyMDk4NjUwNzM5NiJ9fSwiaWF0IjoxNTg2NTE0NjgyLCJleHAiOjE1ODY1MjkwODIsImlzcyI6IlNLYTg0M2I5NTAxNWI3ZDg3ZjVmZGE4ODVhOTUwNTdjMTYiLCJzdWIiOiJBQ2E1Nzk4MzRiNGY4ZDVlYjYyNzdlYzQ2M2UxNzNjNTZhIn0.wGex5mrDJcX0DncFQBkjAqZVuoz_nT2ZuW_nUtHSebQ" };
        this.setState({ identity, token });
    }

    render() {
        // Only show video track after user has joined a room
        let showLocalTrack = this.state.localMediaAvailable ? (
            <div className="flex-item">
                <div ref="localMedia" />
            </div>
        ) : '';
        // Hide 'Join Room' button if user has already joined a room.
        let joinOrLeaveRoomButton = this.state.hasJoinedRoom ? (
            <button type="button" className="btn btn-success" onClick={() => alert("Leave Room")}>Leave Room</button>

        ) : (
                <button type="button" className="btn btn-success" onClick={this.joinRoom.bind(this)}>Join Room</button>

            );
        return (
            <div className="card">
                <div>
                    <div className="flex-container">
                        {showLocalTrack}
                        <div className="flex-item">
                            <input
                                placeholder="Room Name"
                                onChange={this.handleRoomNameChange}
                                errorText={this.state.roomNameErr ? 'Room Name is required' : undefined}
                            />
                            <br />
                            {joinOrLeaveRoomButton}
                        </div>
                        <div className="flex-item" ref="remoteMedia" id="remote-media" />
                    </div>
                </div>
            </div>
        );
    }
}