import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Helmet from "react-helmet";
import { getImgPath } from "../../providerConfig";
import { subDays, addDays } from "date-fns";

const ActionsColumnMobile = (props) => {
    return <>
        <span className="text-success float-right">
            <div className="dropdown">
                <button style={{ fontSize: '20px' }} className="btn btn-transparent icon-btn p-0 text-success" type="button" id="dropdownMenuIconButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    More  <i className="mdi mdi-apps text-lg"></i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuIconButton1">
                    {props.children}
                </div>
            </div>
        </span>
    </>;
}

function DateFilter(props) {
    const [currentDate, setCurrentDate] = useState(props.date ? props.date : new Date());

    const handleChange = (date) => {
        setCurrentDate(date);
        props.handleChange(date);
    }
    const onPrevClick = () => {
        let date = subDays(currentDate, 1);
        setCurrentDate(date);
        props.handleChange(date);
    }
    const onNextClick = () => {
        let date = addDays(currentDate, 1);
        setCurrentDate(date);
        props.handleChange(date);
    }

    return <div title={props.title ? props.title : ''} className={`${props.class ? props.class : 'col-12 col-md-2 col-lg-3'} form-group mb-0`} style={{ maxWidth: "260px" }}>
        <nav className="ml-auto">
            <ul className="pagination pagination-success datepagination text-center mb-0">
                <li className="page-item active" style={{ marginTop: '1px' }} onClick={onPrevClick.bind(this)}><a className="page-link" href="#"><i className="mdi mdi-chevron-left"></i>
                </a></li>
                <li className="page-item  w-100" >
                    <DatePicker
                        disabled={props.disabled}
                        maxDate={props.maxDate}
                        minDate={props.minDate}
                        dateFormat={props.dateFormat ? props.dateFormat : "dd MMMM, yy"}
                        className="form-control"
                        selected={currentDate}
                        onChange={handleChange.bind(this)}
                    />
                </li>
                <li className="page-item active" style={{ marginTop: '1px' }} onClick={onNextClick.bind(this)}><a className="page-link" href="#"> <i className="mdi mdi-chevron-right"></i></a></li>
            </ul>
        </nav>
    </div>
}

const MonthFilter = (props) => {
    return <div className="col-12 col-md-2 col-lg-3 form-group mb-0">
        <DatePicker
            dateFormat="MMMM, yyyy"
            showMonthYearPicker
            className="form-control"
            selected={props.date}
            onChange={props.handleChange.bind(this)}
        />
    </div>;
}
const DateFilterOld = (props) => {
    return <div className={`col-12 ${props.class ? props.class : 'col-md-2 col-lg-3'} form-group mb-0`}>
        <DatePicker
            dateFormat="dd MMMM, yy"
            className="form-control"
            selected={props.date}
            onChange={props.handleChange.bind(this)}
        />
    </div>;
}

const SearchFilter = (props) => {
    return <div className={`col-12 ${props.class ? props.class : 'col-12 col-md-3 col-lg-3'} form-group mb-0`} style={{ padding: props.padding ? props.padding : '' }}>
        <div className="input-group">
            <input type="text" className="form-control" onChange={props.onValueChange.bind(this)} placeholder={props.placeholder} aria-label={props.placeholder} aria-describedby="basic-addon2" />
        </div>
    </div>
}

const SearchSelectFilter = (props) => {
    return <div className={`col-12 ${props.class ? props.class : 'col-12 col-md-3 col-lg-3'} form-group mb-0`}>
        <div className="input-group">
            <select
                className="form-control"
                name={props.name}
                value={props.value}
                onChange={props.onValueSelection.bind(this)}>
                <option disabled >Select {props.placeholder ? props.placeholder : props.name}</option>
                <option value={''}>All {props.placeholder ? props.placeholder : props.name}</option>
                {props.options.map((indOption, i) =>
                    <option value={props.type ? indOption.key : indOption}>{props.type ? indOption.value : indOption}</option>
                )}
            </select>
        </div>
    </div>
}

function ClickSearch(props) {
    const { searchStr, setSearchStr } = useState('');
    return <div className="col-12 col-md-3 col-lg-3 form-group mb-0">
        <div className="input-group">
            <input type="text" className="form-control" onChange={(e) => setSearchStr(e.target.value)} placeholder={props.placeholder} aria-label={props.placeholder} />
            <div className="input-group-append">
                <button className="btn btn-sm btn-outline-success radius-0" type="button" onClick={props.onSearchClicked.bind(this, searchStr)}> <i className="mdi mdi-magnify"></i></button>
            </div>
        </div>
    </div>
}

const NoAppointmentAvailable = (props) => {
    return <div className="alert alert-light alert-elevate fade show alert alert-warning" role="alert">
        <div className="alert-text text-center">
            <h4 className="text-success">Nothing here!</h4>
            <img src={getImgPath('sadface')} alt="logo" className="center-align img-fluid" />
            <p></p>
            {props.redirectToEditView && <a className="btn btn-outline-success font-weight-bold" onClick={props.redirectToEditView.bind(this)}>Click here for create</a>}
        </div>
    </div>
}

const MainLayout = (props) => {
    return <div className="main-panel">
        <div className="content-wrapper">
            <div className="page-header crumb-header">
                <div className="row">
                    <div className="col-12 grid-margin">
                        <div className="card cardtable">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {props.footer && <Footer footer={props.footer} />}
    </div>;
}

const ContainerLayout = (props) => {
    return <div className="main-panel">
        <div className="content-wrapper">
            {props.children}
        </div>
    </div>;
}

// use this layout for footer 
const Footer = (props) => {
    return <footer className="footer">
        <div className="row justify-content-center">
            <div className="col-md-12 text-center ">
                {/* {props.footer.brand_list && props.footer.brand_list.map((brand, i) =>
                    <img src={brand} className="img-fluid shadow-sm m-1" key={i} />
                )} */}
                {/* <img src="images/efnocar-logo.png" className="img-fluid shadow-sm" alt="" /> */}
            </div>
        </div>
    </footer>;
}

const ScreenLayout = (props) => {
    return <div className="table-responsive d-none d-md-block">
        {props.children}
    </div>;
}

const MobileLayout = (props) => {
    return <div className="mobile-appt-container paycard d-block d-md-none d-md-lg">{props.children}</div>;
}

const MobileIndCardWrapper = (props) => {
    return <div className="card apptcard p-2 mb-2">
        <div className="wrapper d-flex align-items-center pt-1 pb-2">
            <div className="wrapper  w-100">
                {props.children}
            </div>
        </div>
    </div>;
}

const DocumentTitle = (props) => {
    return <Helmet>
        <meta charSet="utf-8" />
        <title>{props.title} v.2.4</title>
        <link rel="icon" type="image/png" href={getImgPath('logolight')} sizes="16x16" />
    </Helmet>
}

const StatusClassEnum = {
    processed: 'badge-gradient-success',
    initiated: 'badge-gradient-success',
    cancelled: 'badge-gradient-danger',
    ready: 'badge-gradient-primary',
    pending: 'badge-gradient-warning'
};
const ApptStatusClass = (status) => {
    if (!status) return ''
    let data = status.toLowerCase();
    if (data.indexOf('done') !== -1) {
        return 'badge-success';
    } else if (data.indexOf('booked') !== -1) {
        return 'badge-warning';
    } else if (data.indexOf('cancelled') !== -1) {
        return 'badge-danger';
    } else if (data.indexOf('re-scheduled') !== -1) {
        return 'badge-info';
    } else if (data.indexOf('checked-in') !== -1) {
        return 'badge-primary';
    } else if (data.indexOf('consulting') !== -1) {
        return 'badge badge-gradient-blue';
    } else {
        return 'badge-gradient-default';
    }
};
const PaymentStatusClassEnum = {
    Done: 'badge-gradient-success',
    Pending: 'badge-gradient-warning',
    processed: 'badge-gradient-success',
    ready: 'badge-gradient-warning',
    failed: 'badge-gradient-danger'
};

const Paginate = (props) => {
    let MAX_RECORDS = props.MAX_RECORDS || 10
    let lastEvaluatedKeyLength = props.lastEvaluatedKey ? props.lastEvaluatedKey.length : 0;

    const onPrevClicked = () => {
        let { lastEvaluatedKey } = props;
        lastEvaluatedKey.pop();
        lastEvaluatedKey.pop();
        props.onLastEvaluatedKeyChange(lastEvaluatedKey);
    }

    const onNextClicked = () => {
        props.onLastEvaluatedKeyChange(props.lastEvaluatedKey)
    }

    return <div className="row m-3">
        <div className="col align-self-start text-muted text-small p-0">
            {!!lastEvaluatedKeyLength &&
                <>
                    Page {lastEvaluatedKeyLength} showing {(lastEvaluatedKeyLength - 1) * MAX_RECORDS + 1} - {lastEvaluatedKeyLength * MAX_RECORDS} records
                </>
            }
        </div>
        <div className="col align-self-end text-right">
            <a className="text-success mr-1" style={{ cursor: 'pointer', visibility: (lastEvaluatedKeyLength > 1) ? '' : 'hidden', fontSize: 16 }}
                onClick={props.paginationDisable ? () => { } : onPrevClicked.bind(this)} ><i className="mdi mdi-arrow-left"></i> Previous </a>
            <a className="text-success ml-1" style={{ cursor: 'pointer', visibility: (lastEvaluatedKeyLength > 0 && props.lastEvaluatedKey[lastEvaluatedKeyLength - 1] !== null) ? '' : 'hidden', fontSize: 16 }}
                onClick={props.paginationDisable ? () => { } : onNextClicked.bind(this)}>  Next <i className="mdi mdi-arrow-right"></i>  </a>
        </div>
    </div>
}

const PaymentHelper = {
    DocumentTitle,
    MainLayout,
    ContainerLayout,
    MobileLayout,
    ScreenLayout,
    MobileIndCardWrapper,
    MonthFilter,
    DateFilter,
    SearchFilter,
    SearchSelectFilter,
    ClickSearch,
    ActionsColumnMobile,
    NoAppointmentAvailable,
    StatusClassEnum,
    Footer,
    ApptStatusClass,
    Paginate,
    PaymentStatusClassEnum
}
export default PaymentHelper;