import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchPageViewComponent from '../../../Common/SearchPage/SearchPageViewComponent';
import { getImgPath } from "../../../providerConfig";
import Utils from "../../../Shared/utils/utils";
const appointmentStatusEnum = {
    all: 'All',
    booked: 'Booked',
    checked: 'Checked-In',
    done: 'Done',
    Intent: 'Intent'
};
const appointmentStatusEnumOPD = {
    all: 'All',
    booked: 'Booked',
    checked: 'Checked-In',
    done: 'Done',
    payment_done:'Payment Done',
    payment_pending:'Payment Pending',
    Intent: 'Intent'
};

const ApptStatusEnum = {
    all: 'All',
    booked: 'Booked',
    checked: 'Checked-In',
    done: 'Done',
    rescheduled: 'Re-Scheduled',
    pending: 'Pending',
    consulting: 'Consulting',
    cancelled: 'Cancelled',
    pending: 'Pending',
    transferred: 'Transferred',
    Intent: 'Intent'
};

const appointmentStatusClassEnum = {
    all: 'badge-gradient-dark',
    booked: 'badge-gradient-warning',
    checked: 'badge-gradient-primary',
    done: 'badge-gradient-success',
    payment_done:'badge-gradient-success',
    payment_pending:'badge-gradient-secondary',
    Intent: 'badge-gradient-secondary'
};

const ViewStatusClass = (status) => {
    let data = status.toLowerCase();
    if (data.indexOf('done') !== -1) {
        return 'badge-success';
    } else if (data.indexOf('booked') !== -1) {
        return 'badge-warning';
    } else if (data.indexOf('cancelled') !== -1) {
        return 'badge-danger';
    } else if (data.indexOf('re-scheduled') !== -1) {
        return 'badge-info';
    } else if (data.indexOf('checked-in') !== -1) {
        return 'badge-primary';
    } else if (data.indexOf('consulting') !== -1) {
        return 'badge badge-gradient-blue';
    } else if (data.indexOf('pending') !== -1) {
        return 'badge badge-gradient-dark';
    } else if (data.indexOf('transferred') !== -1) {
        return 'badge badge-gradient-default';
    } else {
        return 'badge badge-secondary';
    }
};

const appointmentStatusOutlineClassEnum = {
    all: 'badge-outline-dark',
    booked: 'badge-outline-warning',
    checked: 'badge-outline-primary',
    done: 'badge-outline-success',
    next:'badge-outline-secondary',
    payment_done:'badge-outline-success',
    payment_pending:'badge-outline-secondary',
    Intent:'badge-outline-danger'
};

const FilterTabs = (props) => {
    let { selectedStatus,accountInfo } = props;
  let   _accountInfo = accountInfo || sessionStorage.getItem("accountInfo")?JSON.parse(sessionStorage.getItem("accountInfo")) : {}
 
  if( _accountInfo.enable_opd_flow === true){
        return <>
        {Object.keys(appointmentStatusEnumOPD).map((key, index) =>
            <div key={index} className={`mr-1 badge ${selectedStatus === key ? appointmentStatusClassEnum[key] : appointmentStatusOutlineClassEnum[key]}`} style={{ cursor: 'pointer' }}
                onClick={props.filterByAppointmentStatus.bind(this, key)}>{appointmentStatusEnumOPD[key]} {props.getCountByStatus(key)}
            </div>)}
    </>;
    }
    else{
        return <>
        {Object.keys(appointmentStatusEnum).map((key, index) =>
            <div key={index} className={`mr-1 badge ${selectedStatus === key ? appointmentStatusClassEnum[key] : appointmentStatusOutlineClassEnum[key]}`} style={{ cursor: 'pointer' }}
                onClick={props.filterByAppointmentStatus.bind(this, key)}>{appointmentStatusEnum[key]} {props.getCountByStatus(key)}
            </div>)}
    </>;
    }
    
}

const DateFilter = (props) => {
    return <div className="col-6 col-md-3 form-group mb-0">
        <div className="form-group mb-0">
            <DatePicker
                className="form-control"
                selected={props.date}
                onChange={props.handleChange.bind(this)}
            />
        </div>
    </div>;
}

const SearchFilter = (props) => {
    return <div className={`${props.class?'props.class':'col-6 col-md-3 form-group mb-0'}`}>
        <SearchPageViewComponent hideLabel={true}
            renderFilteredData={props.renderFilteredDataFromSearch.bind(this)}
            dataToFilter={props.filteredData}></SearchPageViewComponent>
    </div>
}

// Condition added to remove create button on 0 Appointments (for Moolchand only) - Mukesh 
const NoAppointmentAvailable = (props) => {
    return <div className="alert alert-light alert-elevate fade show alert alert-warning" role="alert">
        <div className="alert-text text-center">
            <h4 className="text-success">No appointments to display!</h4>
            <img src={getImgPath('sadface')} alt="logo" className="center-align img-fluid" />
            <p>Try changing the filters above</p>
           {sessionStorage.getItem('s3AccountId')=='meitra_hospital'?"":props.enable_opd_flow === undefined ?
                <a className="btn btn-outline-success font-weight-bold" onClick={props.redirectToEditView.bind(this)}>Click here for create appointment</a> 
            : props.enable_opd_flow &&  !props.isRoleDoctor &&  
                <a className="btn btn-outline-success font-weight-bold" onClick={props.redirectToEditView.bind(this)}>Click here for create appointment</a>
            } 
        </div>
    </div>
}

const ActionsColumnMobile = (props) => {
    let { index, checkForVideoButtonStyle } = props;
    return <>
        <span className="text-success float-right">
            <div className="dropdown">
                <button style={{ fontSize: '20px' }} className="btn btn-transparent icon-btn p-0 text-success" type="button" id="dropdownMenuIconButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    More  <i className="mdi mdi-apps text-lg"></i>
                </button>
                <div className="dropdown-menu p-0" aria-labelledby="dropdownMenuIconButton1">
                    {props.isEditApptEnabled && <><a key={index + 'editappointment'} className="dropdown-item text-success py-1 pt-3" onClick={props.onAppointmentStatusButtonClicked.bind(this)}><i className="mdi mdi-pencil mr-1"></i> Edit Appointment</a><div className="dropdown-divider"></div></>}

                    {!props.hidePrescription && props.isPrescEnabled && <><a className="dropdown-item text-success py-1" onClick={props.onPrescriptionButtonClicked.bind(this)}><i className="mdi mdi-clipboard-text mr-1 "></i> Prescription</a><div className="dropdown-divider"></div></>}

                    {props.isShootSmsEnabled && <><a key={index + 'shootsms'} className="dropdown-item text-success py-1" onClick={props.onSmsStatusUpdateEvent.bind(this)}><i className="mdi mdi-message-text-outline mr-1"></i> Shoot SMS</a><div className="dropdown-divider"></div></>}

                    {props.isVideoButtonEnabled && <><a key={index + 'videocall'} className="dropdown-item text-success py-1" onClick={props.onRedirectToVideoCall.bind(this)} style={{
                        backgroundColor: checkForVideoButtonStyle ? '#09ce21' : '',
                        color: checkForVideoButtonStyle ? 'white' : '',
                        borderColor: checkForVideoButtonStyle ? '#09ce21' : ''
                    }}> <i className="mdi mdi-video btn-icon-append"></i>Video</a><div className="dropdown-divider"></div></>}
                    {props.enableLinkButton && <>
                        <a key={index + 'paymentorderid'} className="dropdown-item text-success py-1" onClick={props.onLinkButtonClicked.bind(this)}><i className="mdi mdi-message-text-outline mr-1"></i> Links</a><div className="dropdown-divider"></div></>
                    }
                    {props.data && props.data.appointment_mode === 'new' ?
                        <a key={index + 'appt-mode'} className="dropdown-item py-1 pb-3"><i className="mdi mdi-currency-inr"></i>{props.data.invoice_amount ? props.data.invoice_amount : '0'} <span className="badge badge-pill badge-success ml-2">New </span></a>
                        :
                        <a key={index + 'appt-mode'} className="dropdown-item py-1 pb-3"><span className="badge badge-pill badge-light">Followup </span></a>
                    }
                    {props.data && props.data.appointment_type === 'online' ?
                        <a key={index + 'appt-type'} className="dropdown-item py-1 pb-3"><i className="mdi mdi-video mr-1"></i> Online Video Call</a>
                        :
                        <a key={index + 'appt-type'} className="dropdown-item py-1 pb-3"><i className="mdi mdi-clipboard-account mr-1"></i> Offline Visit</a>
                    }

                </div>
            </div>
        </span>
    </>;
}

function getCreatedByName(createdById) {
    let subId = sessionStorage.getItem('profileInfo') ? JSON.parse(sessionStorage.getItem('profileInfo')).cognito_user_sub : null
    let subName = sessionStorage.getItem('profileInfo') ? JSON.parse(sessionStorage.getItem('profileInfo')).name : null
    if (createdById === subId) {
        return subName;
    } else {
        return "Staff"
    }

}

const ApptTableColMobile = (props) => {
    let obj = props.data;
    let isRoleDoctor = props.isRoleDoctor;
    let { hidePrescription } = props;
    let doctor_name = obj.doctor_name ? obj.doctor_name : obj.patient ? obj.patient.doctor_name : '';
    let referred_doctor_name = obj.referred_doctor_name ? obj.referred_doctor_name : obj.patient ? obj.patient.referred_doctor_name : '';
    return <>
        <h6 className="ml-1 mb-1 mt-0">
            <i className={`mdi mdi-${obj && obj.appointment_type === 'online' ? 'video' : 'clipboard-account'} mr-1`}></i>
            <span onClick={!hidePrescription && props.isPrescEnabled ? props.onPrescriptionButtonClicked.bind(this) : () => { }}
                style={{ textDecoration: !hidePrescription && props.isPrescEnabled ? 'underline' : '' }}>{obj && obj.patient && obj.patient.name ? obj.patient.name : ''}{
                    obj && obj.appointment_mode === 'new' ?
                        <label className="badge badge-success badge-pill badge-rounded mb-0 btn-small btn-rounded btn-icon ml-1">N</label>
                        :
                        <label className="badge badge-light badge-pill mb-0 btn-small btn-rounded btn-icon ml-1">F</label>
                }</span>
            <ActionsColumnMobile {...props} />
        </h6>
        {obj && <>
            {obj.patient && <>
                <small className="text-muted  mr-1 "><i className="mdi mdi-account mr-1"></i>
                    {`${Utils.calculatePatientAge(obj.patient)}`}
                    {obj.patient.gender ? ',' + obj.patient.gender : ''}
                </small>
            </>}
            <small className="text-muted  mr-1 ">
                <i className="mdi mdi-account-multiple mr-1"></i> Q No:{obj.appointment_queue_number} </small>
            <small className="text-muted mr-1">
                <i className="mdi mdi-clock text-muted mr-1 "></i>{obj.appointment_slot} </small>
            <div className="pt-1">
                {obj.created_at && <small className="text-muted "><i className="mdi mdi-calendar text-muted mr-1 "></i>Book: {obj.created_at}</small>}
                {referred_doctor_name && <small className="text-muted mr-1"><i className="mdi mdi-nature-people text-muted mr-1 "></i>Ref: {referred_doctor_name}</small>}
            </div>
            {obj.old_appointment_slot && obj.old_appointment_date && <div className="pt-1">
                {obj.is_rescheduled ? <label className="badge badge-success badge-pill"><i className="mdi mdi-calendar mr-1 "></i>Rescheduled of: {obj.old_appointment_date} @ {obj.old_appointment_slot}</label>
                    : <label className="mr-1 badge badge-light badge-pill"><i className="mdi mdi-calendar mr-1 "></i>Follow up of: {obj.old_appointment_date} @ {obj.old_appointment_slot}</label>}
            </div>}
            <div className="pt-1">
                {!isRoleDoctor && doctor_name && <small className="text-muted mr-1"><i className="mdi mdi-nature-people text-muted mr-1 "></i>Doc: {doctor_name}</small>}
                {obj.createdby && <small className="text-muted "><i className="mdi mdi-account-outline text-muted mr-1 "></i>Created By: {obj.createdby}</small>}
                {obj.appointment_status && <label className={`ml-1 badge ${ViewStatusClass(obj.appointment_status)} m-0 pt-1`}>{obj.appointment_status}</label>}
            </div>
            <div className="pt-1">
                {obj.patient && (obj.patient.patient_hid || obj.patient_hid) && <small className="text-muted mr-1"><i className="mdi mdi-plus text-muted mr-1 "></i>Patient HID: {obj.patient.patient_hid ? obj.patient.patient_hid : obj.patient_hid}</small>}
                {obj.patient && obj.patient.guardian_name && <small className="text-muted mr-1"><i className="mdi mdi-account text-muted mr-1 "></i>Guardian: {obj.patient.guardian_name}, {obj.patient.guardian_phone}</small>}
            </div>
        </>}
    </>;
}

const MainLayout = (props) => {
    return <div className="main-panel">
        <div className="content-wrapper">
            <div className="page-header crumb-header">
                {props.children}
            </div>
        </div>
        {props.footer && <Footer footer={props.footer} />}
    </div>;
}

const Footer = (props) => {
    return <footer className="footer">
        <div className="row justify-content-center">
            <div className="col-md-12 text-center ">
                {props.footer.brand_list && props.footer.brand_list.map((brand, i) =>
                    <img src={brand} className="img-fluid shadow-sm m-1" key={i} />
                )}
                {/* <img src="images/efnocar-logo.png" className="img-fluid shadow-sm" alt="" /> */}
            </div>
        </div>
    </footer>;
}

const ScreenLayout = (props) => {
    return <div className="row d-none d-md-block">{props.children}</div>;
}

const MobileLayout = (props) => {
    return <div className="mobile-appt-container d-block d-md-none d-md-lg">{props.children}</div>;
}

const MobileIndCardWrapper = (props) => {
    return <div className="card apptcard p-2 mb-2">
        <div className="wrapper d-flex align-items-center pt-1 pb-2">
            <div className="wrapper  w-100">
                {props.children}
            </div>
        </div>
    </div>;
}

const TableViewHelper = {
    MainLayout,
    MobileLayout,
    ScreenLayout,
    MobileIndCardWrapper,
    ApptTableColMobile,
    DateFilter,
    FilterTabs,
    SearchFilter,
    ActionsColumnMobile,
    NoAppointmentAvailable,
    ViewStatusClass,
    ApptStatusEnum,
    appointmentStatusEnum,
    Footer
}
export default TableViewHelper;