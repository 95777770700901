import React from "react";
import Row from "./Row";

class Table extends React.Component {

    // constructor(props) {super(props);}

    onInputChange = (e) => {
        this.props.onInputChange(e);
    }

    getEmitedSearchFiledData=(name,data)=>{
        this.props.emitSearchFiledData(name,data);
    }

    getEmitedAutoSelectionData=(name,data)=>{
        this.props.emitAutoSelectionData(name,data);
    }

    render() {
        return this.props.rows && this.props.rows.map((row, idx) => <Row key={idx} row={row}
         dynamicValues={this.props.dynamicValues} 
         emitSearchFiledData={this.getEmitedSearchFiledData.bind(this)}
         onInputChange={this.onInputChange} 
         uiData={this.props.uiData}
         isRoleDoctor={this.props.isRoleDoctor}
         accountInfo={this.props.accountInfo}
         emitAutoSelectionData={this.getEmitedAutoSelectionData.bind(this)}/>)
    }
}

export default Table;