import React from "react";
import TemplateListComponent from './Component/TemplateListComponent';
import DynamicFormContainer from '../../../DynamicForms/DynamicFormContainer';
import templateService from '../../../../Services/template.service';
import prescriptionService from '../../../../Services/prescription.service';
import axios from 'axios';
import Spinner from "../../../../Common/Spinner/Spinner";


class TemplateListViewContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            templateList: this.props.templateList || null,
            type: 'list',
            isLoading: this.props.loading !== undefined ? this.props.loading : true
        }
        if (this.props.type && this.props.type === 'vital') this.getVitalTemplateData();
        else this.getTemplateList();
        // if (!this.props.templateList)
    }

    filterTemplateListByView(templateList=[],type){
        /* 
        NOTE: dont show mobile template in web to doctor while writing prescription
        */
        let list=templateList;
        if(type=='patient' || type=='appointment'){
           list= templateList.filter(t=>t.templateType!=='mobile')
        }
        return list
     }

    async getVitalTemplateData() {
        // id is patient Id 
        try {
            var response = await prescriptionService.getPrescriptionVitalTemplateList();
            if (response.data) console.log(response.data);
            this.checkForTemplateResponse(response);
        } catch (error) {
            this.onError('No Data Found')
            console.log('error');
        }
    }

    async getTemplateList() {
        try {
            const response = await templateService.getTemplateListOfTypePrescriptionForm();
            this.checkForTemplateResponse(response);
            
        } catch (error) {
            console.log(error);
        }
    }

    checkForTemplateResponse(response) {
        let { templateList } = this.state;
        if (!response) {
            templateList = null;
            this.setState({ templateList, isLoading: false });
        }
        switch (response.type) {
            case 'error':
                templateList = null;
                this.setState({ templateList, isLoading: false });
                break;
            case 'success':
                // this.setState({ templateList: response.data.templates.filter(t => t.subtype !== "instant-presc"), instantTemplates: response.data.templates.filter(t => t.subtype === 'instant-presc') });
                let data = (response.data && response.data.templates && response.data.templates.length > 0) ? response.data.templates : [];
                if(data.length){
                    templateList = this.props.templateView ? data.filter(t => t.subtype !== "instant-presc") : data.filter(t => t.subtype === "instant-presc")
                }
               
                this.setState({ templateList, isLoading: false });
                break;
            default:
                break;
        }
    }

    getEmitedFormViewEvent = (event) => {
        this.getTemplateData(event.url, event.id, event.subtype)
    }

    async getTemplateData(u, id, subtype) {
        let url = u;
        delete axios.defaults.headers.common["Authorization"];
        axios({
            method: 'get',
            url: url
        }).then((response) => {
                if (response && response.data) {
                    let formDataJson = this.isJson(response.data) ? JSON.parse(response.data) : typeof response.data === 'object' ? response.data : null;
                    this.props.emitTemplateJsonData(formDataJson, id, subtype);
                }
            }).catch((error) => {
                console.log(error);
            });
    }

    isJson(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    render() {
        const { templateList, type, formDataJson } = this.state;
        return (
            <>
                <div className="page-header crumb-header">
                    {/* <div className="row ">
                        <div className="col-md-12 form-group mb-0"><label htmlFor="">Search by </label>
                            <input type="search"
                                className="form-control" placeholder="Template"
                                aria-controls="order-appointment by name, doctor, phone, Hid " /></div>
                    </div> */}
                </div>
                {this.state.isLoading && <Spinner width={60} height={60}></Spinner>}
                {templateList &&
                    <TemplateListComponent
                        emitFormViewEvent={this.getEmitedFormViewEvent.bind(this)}
                        templateList={this.filterTemplateListByView(templateList,this.props.query_type)}>
                    </TemplateListComponent>
                }
            </>
        );
    }
}

export default TemplateListViewContainer;
