import React, { Component } from 'react';
import Video, { LocalDataTrack } from 'twilio-video';
import axios from 'axios';
import { withSnackbar } from 'notistack';
import VideoChatRoomComponent from './Component/VideoChatRoomComponent';
import WaitingComponent from './Component/WaitingComponent';
import Fullscreen from "react-full-screen";
import chatRoomService from "../../Services/chatroom.service";
import AudioAnalyser from './Component/AudioAnalyser ';
import Utils from '../../Shared/utils/utils';
import userService from '../../Services/users.service';
import labelFile from '../../Constants/WaitingAreaLabel';
import SweetAlertComponent from '../../Common/Alert/SweetAlertComponent';
import onlineConsultationService from '../../Services/onlineConsultation.service';
import UAParser from 'ua-parser-js';
import SupportContainer from '../Support/SupportContainer'
import PatientSmsStatusComponent, { source as shootSMSSource } from '../Patient/Component/PatientSmsStatusComponent';
import patientService from '../../Services/patient.service';
import CommonModalView from '../../Common/Modal/CommonModalView';
import PatientTextChat from './PatientTextChat';
import DoctorNotesComponent from './DoctorNotesComponent';
import { useBlocker } from 'react-router-dom';
import Spinner from '../../Common/Spinner/Spinner';
import CallAlertModalComponent from '../WaitingArea/Common/CallAlertModalComponent';
import prescriptionService from '../../Services/prescription.service';
import TimerComponent from './TimerComponent';

var _paq = window._paq = window._paq || [];
class NewChatRoomContainer extends Component {
    constructor(props) {
        super(props);
        let notificationData = props.notificationData
        let locationState = this.props.location ? this.props.location.state : null;
        let accountInfo = sessionStorage.getItem('accountInfo');
        let role = sessionStorage.getItem('userRole');
        this.parser = new UAParser();
      
        this.recognition = this.parser && this.parser.getResult().browser.name === 'Chrome' && window && window.webkitSpeechRecognition && new window.webkitSpeechRecognition() || null;
        if (this.recognition) this.recognition.continous = true;
        this.state = {
            isStatsMenu: false,
            pipModeActive: false,
            isUserVideoLoading: true,
            showTimer: false,
            isSMSFeatureEnabled: role ? role.indexOf('sms') !== -1 : '',
            labels: {
                ...labelFile[locationState && locationState.lang || 'english'].videoRoomLabels,
                chatLabels: labelFile[locationState && locationState.lang || 'english'].chatLabels,
                pleaseWaitModal: labelFile[locationState && locationState.lang || 'english'].pleaseWaitModal,
            },
            accountInfo: accountInfo ? JSON.parse(accountInfo) : null,
            chatRoomId: this.props.match && this.props.match.params && this.props.match.params.id ? this.props.match.params.id : this.props.room_id ? this.props.room_id : null,
            providerName: this.props.match && this.props.match.params && this.props.match.params.providerName ? this.props.match.params.providerName : this.props.providerName ? this.props.providerName : null,
            isDoctorNotes: false,
            identity: 'ankur',
            isDoctorComplaints: false,
            qualityLogs: [],
            roomName: '1234',
            dataTrack: (new LocalDataTrack()),
            dataTrackPublished: null,
            roomNameErr: false, // Track error for room name TextField
            previewTracks: null,
            localMediaAvailable: false,
            hasJoinedRoom: false,
            chatOnline: false,
            notificationData: notificationData,
            activeRoom: '', // Track the current active room,
            token: locationState ? locationState.token : null,
            patientDetail: locationState ? locationState.patient : {},
            isMute: false,
            isVideoOff: false,
            audios: null,
            doctorDetail: locationState ? locationState.doctor : '',
            appointmentId: locationState ? locationState.appointmentId : '',
            appointmentDate: locationState ? locationState.appointmentDate : '',
            appointmentSlot: locationState ? locationState.appointmentSlot : '',
            isDoctor: locationState && locationState.doctor && locationState.doctor.sourceView === 'appointment' ? true : false,
            quality: locationState && locationState.quality || '',
            videoSettings: locationState && locationState.video_settings || '',
            room: null,
            isFull: false,
            userIpAddress: null,
            play: false,
            isSupportActive: false,
            select: null,
            availableVideoDevices: [],
            selectedDeviceIndex: 0,
            selectedCameraViewType: 'front',
            loading: false,
            qualityIndex: 2,
            remoteQualityIndex: 2,
            packetsReceived: 0,
            enable_popup: false,
            packetsLost: 0,
            startTime: (new Date()).getTime(),
            video: null,
            chatHistory: [],
            prevChat: null,
            time: {},
            seconds: 5,
            showChat: false,
            remoteHasTracks: false,
            callEndId: (locationState && locationState.callEndId) ? locationState.callEndId : '',
            showMuteToast: false,
            alert: {
                show: false,
                title: '',
                type: '',
                text: ''
            }
        };
        this.audio = new Audio("https://breathefreeeducator.com/notification.mp3")
        this.joinRoom = this.joinRoom.bind(this);
        this.roomJoined = this.roomJoined.bind(this);
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
        this.getIpAddress();
        this.menuRef = React.createRef();
    }

    async getTemplateData() {
        if (this.state.isDoctorNotes) {
            return;
        }
        let data = {
            "api_key": "0ED10A7AFB3D44DEBB96",
            "conversation_id": this.state.chatRoomId
        }
        let url = "https://api.arintra.com/symptoms/conversationStatus"
        delete axios.defaults.headers.common["Authorization"];
        axios.post(url, data)
            .then((response) => {
                
                if (response && response.data && response.data.status === "COMPLETED") {
                    let { isDoctorNotes } = this.state;
                    isDoctorNotes = this.state.accountInfo && this.state.accountInfo.ask_patient_symptoms ? true : false;
                    this.setState({ isDoctorNotes: isDoctorNotes })
                    clearTimeout(this.apptTimeOut);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async getMicrophone() {
        const audios = new MediaStream([this.state.participantTrack.mediaStreamTrack.clone()]);
        this.setState({ audios });
    }

    getLocalMicrophone() {
        let track = this.getTypeTracksForParticipant(this.state.activeRoom.localParticipant, 'audio')
        if (!track) return;
        const localAudios = new MediaStream([track.mediaStreamTrack.clone()])
        this.setState({ localAudios });
    }

    getTypeTracksForParticipant(participant, kind) {
        const tracks = this.getTracks(participant);
        if (!tracks) return null;
        for (let t of tracks) {
            if (t.kind == kind) {
                return t
            }
        }

    }

    speachDataConfig = () => {
        if (!this.recognition) return;
        this.recognition.start()
        this.recognition.onspeechstart = event => {
            let { showMuteToast } = this.state;
            showMuteToast = true;
            this.setState({ showMuteToast })
        }

        this.recognition.onsoundstart = event => {
            let { showMuteToast } = this.state;
            showMuteToast = true;
            this.setState({ showMuteToast })
            this.props.enqueueSnackbar('Are you talking? Your mic is off. Click on unmute button to start speaking.', { variant: 'error' })
        }

        this.recognition.onend = event => {
            let { showMuteToast } = this.state;
            showMuteToast = false;
            this.setState({ showMuteToast })
        }
    }

    recognitionEnd = () => {
        if (!this.recognition) return;
        this.recognition.stop()
        this.recognition.onend = event => {
            let { showMuteToast } = this.state;
            showMuteToast = false;
            this.setState({ showMuteToast })
        }
    }

    stopMicrophone() {
        this.state.audios.getTracks().forEach(track => track.stop());
        this.setState({ audios: null });
    }

    toggleMicrophone() {
        if (!this.state.participantTrack) {
            return;
        }
        if (this.state.audios) {
            this.stopMicrophone();
        } else {
            this.getMicrophone();
        }
    }

    handleClick = e => {
        if (!e || (this.menuRef && this.menuRef.contains && this.menuRef.contains(e.target))) {
            return;
        }
        this.handleClickOutside();
    };

    handleClickOutside = () => {
        let { isStatsMenu } = this.state;
        isStatsMenu = false;
        this.setState({ isStatsMenu })
    }

    postLogs = async () => {
        let payload = {
            log_type: 'quality',
            'online_patientid': this.state.patientDetail.id,
            'doctor_id': this.state.doctorDetail.doctor_id,
            "room_id": this.state.chatRoomId,
            appointment_id: this.state.appointmentId,
            call_duration: Utils.get_time_difference(this.state.startTime, (new Date()).getTime()),
            lost_percent: 100 * this.state.packetsLost / this.state.packetsReceived,
            total_received: this.state.packetsReceived,
            total_lost: this.state.packetsLost,
            call_starttime: this.state.startTime,
            track_attach_time: '',
            logs: this.state.qualityLogs,
            account_id: this.state.providerName
        }
        if (this.state.isDoctor) {
            await prescriptionService.postLogs_doctor(payload)
        } else {
            prescriptionService.postLogs_patient(payload)
        }
    }

    componentWillUnmount() {
        if (this.state.isDoctor) {
            window.location.hash = ''
            sessionStorage.setItem('currentVideoApptId', '');
            if (this.props.closeVideoRoom) this.props.closeVideoRoom();
        }
        _paq.push(['trackEvent', this.state.providerName, 'callDropped', this.state.chatRoomId]);
        _paq.push(['trackPageView']);
        window.removeEventListener("mousedown", this.handleClick, false);
        this.audio.removeEventListener('ended', () => this.setState({ play: false }));
        if (this.autotimer) clearInterval(this.autotimer);
        this.postLogs();
    }


    static getDerivedStateFromProps(nextProps, prevState) {
        // do things with nextProps.someProp and prevState.cachedSomeProp
        return {
            notificationData: nextProps.notificationData,
            // ... other derived state properties
        };
    }


    componentDidUpdate(prevProps, prevState) {
        if (this.state.notificationData !== prevProps.notificationData) {
            this.checkForAppointmentIdMatchWithNotifData();
        }
    }

    checkForAppointmentIdMatchWithNotifData() {
        if (!this.state.notificationData || this.state.notificationData.length === 0) {
            return;
        }
    }

    joinRoom() {
        if (!this.state.roomName.trim()) {
            this.setState({ roomNameErr: true });
            return;
        }

        let connectOptions = {
            name: this.state.roomName,
            // region: 'in1',
            tracks: [],
            // audio: true,
            maxAudioBitrate: 16000,
            video: { height: 540, frameRate: 15, width: 720 }
        };
        let { dataTrack } = this.state;
        // connectOptions['tracks'] = []

        if (this.state.previewTracks) {
            connectOptions.tracks = this.state.previewTracks;
            
        }
        connectOptions['tracks'].push(dataTrack);
        console.log("Joining room '" + this.state.roomName + "'...");
        Video.connect(this.state.token, connectOptions).then(this.roomJoined, error => {
            // alert('Could not connect to Video');
            this.notifyCallEndRecord();
            this.logPermissionResponse('video_connection_issue', error.message, 'error');
        });
    }

    toggleCameraView = (event, index) => {
        let { selectedDeviceIndex, selectedCameraViewType } = this.state;

        let indDevice = event;
        selectedDeviceIndex = index
        this.setState({ selectedDeviceIndex, selectedCameraViewType }, () => this.updateVideoDevice(indDevice));
    }

    setQuality = (e) => {
        this.setState({ quality: e.target.value })
        this.updateQuality(this.state.videoSettings.qualities.findIndex(q => q.key === e.target.value));
        const videoTrack = this.getTypeTracksForParticipant(this.state.activeRoom.localParticipant, 'video')
        if (!videoTrack) return;
        videoTrack.mediaStreamTrack.applyConstraints({
            width: 640,
            height: 360
        }).catch(e => {
            console.error('Error while applying capture constraints:', e.message);
        });
    }

    updateVideoDevice = (event) => {
        const select = event.key;
        const localParticipant = this.state.activeRoom.localParticipant;
        if (select !== '') {
            Video.createLocalVideoTrack({
                deviceId: { exact: select }
            }).then((localVideoTrack) => {
                // const tracks = Array.from(localParticipant.videoTracks);
                let tracks = this.getTracks(this.state.activeRoom.localParticipant)
                if (!tracks) return;
                let track = tracks.filter((e) => {
                    return e.kind == 'video'
                })
                localParticipant.unpublishTracks(track);
                this.detachTracks(track);

                localParticipant.publishTrack(localVideoTrack);
                console.log(localParticipant.identity + " added track: " + localVideoTrack.kind);
                const previewContainer = this.refs.localMedia;
                this.attachTracks([localVideoTrack], previewContainer);
            });
        }
    }

    async logPermissionResponse(type, info, reType) {
        try {
            let data = {
                type, info, reType,
                source: (this.state.isDoctor ? 'Doctor' : 'Patient') + 'Video Chat Room',
                room_id: this.state.chatRoomId,
                doctor: this.state.doctorDetail ? this.state.doctorDetail : {},
                patient: this.state.patientDetail ? this.state.patientDetail : {},
                appt: {
                    appointment_id: this.state.appointmentId,
                    appointment_date: this.state.appointmentDate,
                    appointment_slot: this.state.appointmentSlot
                },
                account_id: this.state.providerName,
                devices: this.state.availableVideoDevices,
                ip: this.state.userIpAddress
            }
            const response = await userService.chatlogAppError(data);
            
        } catch (err) {
            console.log(err)
        }
    }


    attachTracks(tracks, container) {
        if (!tracks) return;
        tracks.forEach(track => {
            let name = track.kind
            if (track.kind === 'data') return;
            this.setState({
                [name]: track
            })

            // container.className = "uservideo"
            if (track.kind === "video") {
                container.appendChild(track.attach()).setAttribute("class", "uservideo");
            } else {
                container.appendChild(track.attach());
            }

        });
    }

    mute(event) {
        // isMute: false,
        // isVideoOff: false,
        let { isMute } = this.state;

        let track = this.state.audio;
        if (!track) return;
        isMute = track.isEnabled ? true : false;
        this.setState({ isMute }, () => {
            if (!this.state.isMute) {
                this.recognitionEnd();
            } else {
                this.speachDataConfig();
            }
        });
        track.isEnabled ? track.disable() : track.enable()
    }
    disableVideo(event) {
        let { isVideoOff } = this.state;
        let track = this.state.video;
        if (!track) return;
        isVideoOff = track && track.isEnabled ? true : false;
        this.setState({ isVideoOff })
        track.isEnabled ? track.disable() : track.enable()
    }

    attachTrack(track, container) {
        if (track.kind === "data" || !container) return;
        container.appendChild(track.attach()).setAttribute("class", "demovideo");
    }

    detachTrack(track) {
        if (track.kind === "data") return;
        track.detach().forEach(function (element) {
            element.remove();
        });
    }
    trackPublished(publication, container) {
        var that = this;
        if (publication.isSubscribed) {
            that.attachTrack(publication.track, container);
        }
        publication.on('subscribed', function (track) {
            console.log('Subscribed to ' + publication.kind + ' track');
            if (track.kind == 'audio') {
                that.setState({
                    participantTrack: track
                }, () => { that.getMicrophone() })

            }
            that.attachTrack(track, container);
        });
        publication.on('unsubscribed', that.detachTrack);
    }

    appendName(identity, container) {
        // const name = document.createElement('p');
        // name.id = `participantName-${identity}`;
        // name.className = 'instructions';
        // name.textContent = identity;
        // container.appendChild(name);
    }

    trackUnpublished(publication) {
        console.log(publication.kind + ' track was unpublished.');
    }

    playNotificationSound() {

        this.setState({ play: true }, () => {
            this.audio.play()
        });

        setTimeout(() => {
            this.setState({ play: false }, () => {
                this.audio.pause();
            });
        }, 5000);
    }

    participantConnected(participant, container) {
        if (!participant) return;
        var that = this;
        this.playNotificationSound();
        this.setState({ showPipMode: true });

        participant.tracks.forEach(function (publication) {
            if (publication.kind === 'data') {
                that.setState({ remoteHasTracks: true })
            }
            that.trackPublished(publication, container);
        });
        this.getStatsData();
        console.log(participant.networkQualityLevel)
        participant.on('trackPublished', function (publication) {
            that.trackPublished(publication, container);
        });
        participant.on('networkQualityLevelChanged', this.handleNewtorkQualityLevelChange);
        participant.off('networkQualityLevelChanged', this.handleNewtorkQualityLevelChange);
        participant.on('trackUnpublished', this.trackUnpublished);
        participant.on('reconnecting', () => {
            // assert.equal(remoteParticipant.state, 'reconnecting');
            // console.log(`${remoteParticipant.identity} has reconnected the signaling connection to the Room!`);
            that.setState({ remoteConnecting: true });
        });

        participant.on('reconnected', () => {
            // assert.equals(remoteParticipant.state, 'connected');
            // console.log(`${remoteParticipant.identity} has reconnected the signaling connection to the Room!`);
            that.setState({ remoteConnecting: false })
        });
    }

    handleNewtorkQualityLevelChange(p) {
        console.log(p)
    }

    getTracks(participant) {
        if (!participant) return;
        return Array.from(participant.tracks.values()).filter(function (publication) {
            return publication.track ? publication.track : publication;
        }).map(function (publication) {
            return publication.track ? publication.track : publication;
        });
    }

    // Attaches a track to a specified DOM container
    attachParticipantTracks(participant, container) {
        // var tracks = Array.from(participant.tracks.values());

        // var tracks = this.getTracks(participant)
        // this.attachTracks(tracks, container);
        // this.logPermissionResponse('track attached', this.state.isDoctor ? 'Doctor track attached ' : 'Patient  track attached', 'success');

        var tracks = this.getTracks(participant)
        if (!tracks) return;
        if (tracks) {
            // this.state.dataTrack = new LocalDataTrack();
            tracks.push(this.state.dataTrack)
        } else {
            tracks = []
            // this.state.dataTrack = new LocalDataTrack();
            tracks.push(this.state.dataTrack)
            this.setState(this.state);
        }
        this.attachTracks(tracks, container);
        this.logPermissionResponse('track attached', this.state.isDoctor ? 'Doctor track attached ' : 'Patient  track attached', 'success');
    }

    detachTracks(tracks) {
        tracks.forEach(track => {
            if (track.detach) {
                track.detach().forEach(detachedElement => {
                    detachedElement.remove();
                });
            }

        });
    }

    detachParticipantTracks(participant) {
        var tracks = this.getTracks(participant);
        if (!tracks) return;
        this.detachTracks(tracks);
    }

    preAutoDisconnect() {
        if (!this.state.isDoctor) {
            this.setState({ showThankyou: true });
        }
        clearInterval(this.autotimer);
    }

    onParticipantRejoinDuringLeaveconfirmation() {
        if (this.autotimer) clearInterval(this.autotimer);
        this.setState({ alert: { show: false, title: '', text: '', type: '', confirmBtnText: '' } });
    }

    roomJoined(room) {
        if (room && room.participants && room.participants.size !== 0) {
            this.setState({ showChat: true, showTimer: true })
        }
        // Called when a participant joins a room
        console.log("Joined as '" + this.state.identity + "'");
        this.logPermissionResponse('call connected', this.state.isDoctor ? 'Doctor joined' : 'Patient Joined', 'success');
        this.setState({
            activeRoom: room,
            localMediaAvailable: true,
            isUserVideoLoading: false,
            hasJoinedRoom: true
        });
        var that = this;
        window.navigator.mediaDevices.enumerateDevices().then(this.gotDevices);
        // let { select } = this.state;
        // select = document.getElementById('video-devices');
        // select.addEventListener('change', this.updateVideoDevice);

        // this.setState({ select })


        // Attach LocalParticipant's Tracks, if not already attached.
        var previewContainer = this.refs.localMedia;
        /* if (!previewContainer.querySelector('video')) {
            this.attachParticipantTracks(room.localParticipant, previewContainer);
        } */
        if (previewContainer && !previewContainer.querySelector('video')) {
            this.attachParticipantTracks(room.localParticipant, previewContainer);
        }


        // Attach the Tracks of the room's participants.
        room.participants.forEach(participant => {
            console.log("Already in Room: '" + participant.identity + "'");
            var previewContainer = this.refs.remoteMedia;
            this.participantConnected(participant, previewContainer);
        });
        this.getLocalMicrophone()

        // Participant joining room
        room.on('participantConnected', participant => {
            if (room.participants.size !== 0) {
                this.setState({ showChat: true, showTimer: true })
            }
            // if user is about to leave the room using confirmation when  no other participants exist & some participant rejoins
            if (room.participants.size !== 0 && ((this.state.alert.show && this.state.alert.type === 'confirmation') || (this.autotimer))) {
                this.onParticipantRejoinDuringLeaveconfirmation();
            }

            console.log("Joining: '" + participant.identity + "'");
            var previewContainer = this.refs.remoteMedia;
            that.participantConnected(participant, previewContainer)
        });

        // Attach participant’s tracks to DOM when they add a track
        room.on('trackAdded', (track, participant) => {
            console.log(participant.identity + ' added track: ' + track.kind);
            if (track.kind === 'data') {
                return;
            } else {
                var previewContainer = this.refs.secondMedia;
                that.attachTracks([track], previewContainer);
            }
        });

        room.on('reconnecting', error => {
            // assert.equal(room.state, 'reconnecting');
            that.setState({ reconnecting: true });
        });

        room.on('reconnected', () => {
            that.setState({ reconnecting: false, showLiveStatus: true });
            setTimeout(() => {
                that.setState({ showLiveStatus: false })
            }, 3000)
            // assert.equal(room.state, 'connected');
        });

        room.on('trackSubscribed', (track) => {
            track.on('message', (message) => {
                
                that.getMessageData(message)
            });
        });

        // Detach participant’s track from DOM when they remove a track.
        room.on('trackRemoved', (track, participant) => {
            console.log(participant.identity + ' removed track: ' + track.kind);
            that.detachTracks([track]);
        });

        const { labels } = this.state;
        // Detach all participant’s track when they leave a room.
        room.on('participantDisconnected', participant => {
            if (room.participants.size === 0) {
                this.autotimer = setInterval(() => {
                    if (this) {
                        this.preAutoDisconnect();
                        this.disconnect();
                    }
                }, 60000);
            }
            if (room.participants.size === 0) {
                that.setState({ showChat: false, showTimer: false, showPipMode: false, alert: { show: true, title: labels.wishToLeave, text: labels.onlyOneLeft, type: 'confirmation', confirmBtnText: labels.yes } });
            }
            console.log("Participant '" + participant.identity + "' left the room");
            // that.detachParticipantTracks(participant);
        });

        // Once the local participant leaves the room, detach the Tracks
        // of all other participants, including that of the LocalParticipant.
        room.on('disconnected', () => {
            if (that.state.previewTracks) {
                that.state.previewTracks.forEach(track => {
                    if (track.kind === 'data') return;
                    track.stop();
                });
            }
            that.detachParticipantTracks(room.localParticipant);
            // room.participants.forEach(this.detachParticipantTracks);
            that.state.activeRoom = null;
            that.state.token = null;
            that.setState({ hasJoinedRoom: false, localMediaAvailable: false });
            // this.state.select.removeEventListener('change', this.updateVideoDevice);
        });

        const dataTrackPublished = {};

        dataTrackPublished.promise = new Promise((resolve, reject) => {
            dataTrackPublished.resolve = resolve;
            dataTrackPublished.reject = reject;
        });

        this.setState({ dataTrackPublished });

        room.on('trackPublished', publication => {
            if (publication.track === this.state.dataTrack) {
                dataTrackPublished.resolve();
            }
        });

        room.on('trackPublicationFailed', (error, track) => {
            if (track === this.state.dataTrack) {
                dataTrackPublished.reject(error);
            }
        });
    }

    getMessageData = (message) => {
        let { chatHistory, doctorDetail, patientDetail, isDoctor, glowBtn, chatOnline, labels } = this.state;
        let msgData = JSON.parse(message)
        if (msgData.type === "quality") {
            this.onQualityTypeMessEvent(msgData);
            return;
        }
        glowBtn = !chatOnline;
        chatHistory.push({ isSent: false, msg: msgData || labels.chatLabels.newMsgError })
        this.setState({ chatHistory, glowBtn });
        let trimmedMsg = msgData.length > 15 ? msgData.slice(0, 15) + '...' : msgData;
        this.props.enqueueSnackbar(msgData ? `${isDoctor ? patientDetail.name : doctorDetail.doctor_name}: ` + trimmedMsg : labels.chatLabels.newMsgError, { variant: 'info' })
    }

    sendNewMsg(message) {
        try {
            let { chatHistory } = this.state;
            chatHistory.push({ msg: message, isSent: true });
            this.setState({ chatHistory });
            this.state.dataTrack.send(JSON.stringify(message))
        } catch (error) {
            console.log(error);
        }
    }

    onQualityTypeMessEvent(msgData) {

        if (msgData && msgData.data && msgData.data.quality) {
            this.checkQualityAuto(msgData.data.quality)
        }

        
    }
    checkQualityAuto(quality) {

        // const track = this.getTypeTracksForParticipant(this.state.activeRoom.localParticipant, 'video')
        quality = this.state.qualityIndex + quality;
        if (quality > 0 && quality >= this.state.videoSettings.default_index && quality < this.state.videoSettings.qualities.length) {
            this.updateQuality(quality);
        }

    }

    updateQuality(quality) {
        if (!this.state.activeRoom || !this.state.activeRoom.localParticipant) return;
        const track = [...this.state.activeRoom.localParticipant.tracks.values()].filter((e) => {
            return e.kind == 'video'
        })
        track[0]._signaling._trackTransceiver.track.applyConstraints({
            width: this.state.videoSettings.qualities[quality].value.width,
            height: this.state.videoSettings.qualities[quality].value.height,
            frameRate: this.state.videoSettings.qualities[quality].value.frameRate
        }).catch(e => {
            console.error('Error while applying capture constraints:', e.message);
        });
        
        this.setState({ qualityIndex: quality }, () => { this.getStatsData() })
    }

    sendMessageData(remoteQualityIndex) {
        let obj = {
            type: 'quality',
            data: {
                quality: remoteQualityIndex
            }
        }
        this.state.dataTrack.send(JSON.stringify(obj))
    }

    disconnect() {
        if (this.state.activeRoom) {
            this.state.activeRoom.disconnect();
        }
        this.notifyCallEndRecord('duration');
        this.onCallDisconnectRedirection()
    }

    onCameraSwitchButtonClicked = (event) => {
        const constraints = {
            video: true,
            audio: false
        };
        window.navigator.mediaDevices
            .getUserMedia(constraints)
            .then(stream => {

                this.video.srcObject = stream;
            })
            .catch(error => {
                console.error(error);
            });
    }

    onCallDisconnectRedirection() {
        let { isDoctor } = this.state;
        // token = null;
     
        if (isDoctor) {
            // appointmentDate
            this.props.redirectTo(`/appointments-view`, { ...this.props.location.state, showCallEndModal: true });
        } else {
            let data = {
                isCallDone: true, isCallEnd: true,
                showThankyou: this.state.showThankyou,
                lang: this.props.location.state && this.props.location.state.lang
            }
            this.props.redirectTo(`/${this.state.providerName}/waiting-area/${this.state.chatRoomId}`, data);
        }
    }

    secondsToTime(secs) {
        // doc_alert_time
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    checkIfTimeIsGreaterThen() {
        let currentDate = new Date().getTime();
        currentDate = currentDate / 1000;
        if (this.state.appointmentSlot > currentDate) {
            return true
        }
        return false;
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        if (this.checkIfTimeIsGreaterThen()) {
            return;
        }
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        }, () => {
            if (this.state.showTimer) {
                clearInterval(this.timer);
                return;
            }
        });

        // activeRoom
        // Check if we're at zero.
        if (seconds == 0) {
            if (!this.state.isDoctor) {
                this.updateOnDoctorNotConnected();
            }
            clearInterval(this.timer);
        }
    }

    checkVideoSettings() {
        if (this.state.accountInfo) {
            let videoSetting = this.state.accountInfo.video_timer_settings;
            let { enable_popup } = this.state;
            enable_popup = videoSetting?.enable_popup ? true : false
            this.setState({ enable_popup });
        }
    }

    checkForDocWaitTime() {
        if (this.state.accountInfo) {
            let videoSetting = this.state.accountInfo.video_timer_settings;
            let doc_alert_time = videoSetting?.doc_alert_time;
            if(!doc_alert_time){
                return
            }
            let miniTime = this.convertMinutestoSeconds(doc_alert_time);
            this.setState({ seconds: miniTime }, () => {
                let timeLeftVar = this.secondsToTime(miniTime);
                this.setState({ time: timeLeftVar }, () => this.startTimer());
            })
        }
    }

    convertMinutestoSeconds(minutes) {
        return Math.floor(minutes * 60);
    }

    componentDidMount() {
        if (!this.state.accountInfo) {
            this.getAccountInfo();
        }
        this.checkForDocWaitTime();
        // this.checkVideoSettings();
        if (this.state.isDoctor) {
            this.notesTimeOut = setTimeout(() => {
                this.getTemplateData();
            }, 3000);

        }
        window.addEventListener("mousedown", this.handleClick, false);
        let localTrackPromise = Video.createLocalTracks();
        localTrackPromise.then(tracks => {
            // Video.createLocalVideoTrack({ height: 540, frameRate: 15, width: 720 }).then(videoTrack =>{
            // tracks.push(videoTrack)
            let { previewTracks } = this.state;
            for (let i = 0; i < tracks.length; i++) {
                if (tracks[i].kind == 'video') {
                    tracks[i].mediaStreamTrack.applyConstraints({ height: 540, frameRate: 15, width: 720 })
                }
            }
            previewTracks = tracks;
            this.setState({ previewTracks }, () => {
                if (!this.state.token || !this.state.patientDetail || !this.state.doctorDetail || !this.state.appointmentId) {

                    return;
                }
                this.joinRoom()
            })
        })
        this.fetchPrevChats();
    }

    async getAccountInfo() {
        try {
            let res = await onlineConsultationService.getAccountInfo(this.state.providerName);
            if (res && res.data) {
                sessionStorage.setItem('accountInfo', JSON.stringify(res.data));
                this.setState({ accountInfo: res.data });
            }
        } catch (err) {
            console.log(err)
        }
    }

    async updateOnDoctorNotConnected() {
        try {
            let { doctorDetail, isDoctor, patientDetail, appointmentDate, appointmentSlot } = this.state;
            let data = {
                "appointment_id": this.state.appointmentId,
                "doctor_id": doctorDetail.doctor_id,
                "online_patientid": isDoctor ? patientDetail.online_patientid : patientDetail.id,
                "doctor_name": doctorDetail.doctor_name,
                "appointment_date": appointmentDate,
                "account_id": this.state.providerName,
                "appointment_slot": appointmentSlot,
                "missed_by": "doctor"
            }
            let res = await onlineConsultationService.sendEmailAlert(data);
            
        } catch (err) {
            console.log(err)
        }
    }

    async fetchPrevChats() {
        let { prevChat, doctorDetail, patientDetail, chatLoading, isDoctor } = this.state;
        this.setState({ chatLoading: true });
        let data = {
            "account_id": this.state.providerName,
            "patient_id": isDoctor ? patientDetail.online_patientid : patientDetail.id,
            "doctor_id": doctorDetail.doctor_id
        };
        let res = ''
        try {
            if (isDoctor) {
                res = await onlineConsultationService.getDoctorPatChat(data);
            } else {
                data = {
                    ...data,
                    "room_id": this.state.chatRoomId
                };
                res = await onlineConsultationService.getPatientDocChat(data);
            }
            if (res && res.type === 'success' && res.data && res.data.chatHistory) {
                // showError = '';
                prevChat = res.data.chatHistory;
                if (prevChat.length > 1 && prevChat[0].created > prevChat[1].created) {
                    prevChat.reverse()
                }
                let dummy = []
                prevChat.forEach(q => {
                    if (q.patient_messages) {
                        q.patient_messages.forEach(
                            m => dummy.push({ isSent: !isDoctor, msg: m })
                        )
                    }
                    if (q.doctor_messages) {
                        q.doctor_messages.forEach(
                            m => dummy.push({ isSent: isDoctor, msg: m })
                        )
                    }
                });
                prevChat = dummy;
            }
            chatLoading = false;
        } catch (err) {
            chatLoading = false
        }
        this.setState({ prevChat, chatLoading });
    }

    share() {
        var that = this;
        if (this.state.screen) {
            that.state.activeRoom.localParticipant.unpublishTrack(this.state.screen);
            // TODO: remove this if the SDK is updated to emit this event
            that.state.activeRoom.localParticipant.emit('trackUnpublished', this.trackPublication);
            this.state.screen.stop();
            this.setState({
                screen: null
            })
        }
        else {
            if (window.navigator && window.navigator.mediaDevices && window.navigator.mediaDevices
                .getDisplayMedia && typeof window.navigator.mediaDevices
                    .getDisplayMedia === "function") {
                window.navigator.mediaDevices
                    .getDisplayMedia({
                        audio: false,
                        video: true,
                    })
                    .then(stream => {
                        const track = stream.getVideoTracks()[0];

                        // All video tracks are published with 'low' priority. This works because the video
                        // track that is displayed in the 'MainParticipant' component will have it's priority
                        // set to 'high' via track.setPriority()
                        this.state.activeRoom.localParticipant
                            .publishTrack(track, {
                                name: 'screen', // Tracks can be named to easily find them later
                                priority: 'low', // Priority is set to high by the subscriber when the video track is rendered
                            })
                            .then(trackPublication => {
                                /* this.stopScreenShareRef.current = () => {
                                    this.state.activeRoom.localParticipant.unpublishTrack(track);
                                    // TODO: remove this if the SDK is updated to emit this event
                                    this.state.activeRoom.localParticipant.emit('trackUnpublished', trackPublication);
                                    track.stop();
                                    // setIsSharing(false);
                                }; */
                                var that = this;
                                this.setState({
                                    screen: track
                                })

                                track.onended = () => {
                                    that.state.activeRoom.localParticipant.unpublishTrack(track);
                                    // TODO: remove this if the SDK is updated to emit this event
                                    that.state.activeRoom.localParticipant.emit('trackUnpublished', that.trackPublication);
                                    track.stop();
                                    // setIsSharing(false);
                                };
                                // setIsSharing(true);
                            })
                            .catch('onError');
                    })
                    .catch(error => {
                        // Don't display an error if the user closes the screen share dialog
                        if (error.name !== 'AbortError' && error.name !== 'NotAllowedError') {
                            // onError(error);
                        }
                    });
            }


        }
    }

    async getIpAddress() {
        axios.get('https://api.ipify.org')
            .then((response) => {
                let { userIpAddress } = this.state;
                userIpAddress = response.data;
                this.setState({ userIpAddress });
                // 
            })
            .catch((error) => {
                let { userIpAddress } = this.state;
                userIpAddress = '';
                this.setState({ userIpAddress });
            });
    }

    async callrecordInitiatePatientEvent() {
        try {
            let details = this.parser.getResult();
            let connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection;
            let data = {
                'appointment_id': this.state.appointmentId,
                'status': 'initiated',
                'online_patientid': this.state.patientDetail.id,
                'doctor_id': this.state.doctorDetail.doctor_id,
                "room_id": this.state.chatRoomId,
                "account_id": this.state.providerName,
                'info': {
                    ip: this.state.userIpAddress,
                    "network_type": connection && connection.effectiveType ? connection.effectiveType : '',
                    browserInfo: {
                        browser: details.browser,
                        OS: details.os,
                        device: details.device,
                        cpu: details.cpu,
                        engine: details.engine
                    }
                }
            }
            if (this.state.providerName) {
                data['account_id'] = this.state.providerName
            }
            this.setState({ loading: true })
            let type = '';
            let id = ''
            const response = await chatRoomService.callRecordReceiverPatient(data, type, id);
            this.setState({ loading: false })
            
            this.checkCallRecordInitiateResponse(response)
        } catch (error) {
            console.log(error)
        }
    }

    async notifyCallEndRecord(durationType) {
        try {
            let timeDuration = Utils.get_time_difference(this.state.startTime, (new Date()).getTime())
            let details = this.parser.getResult();
            let connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection;
            let data = {
                "status": (this.state.isDoctor) ? "ended_by_doctor" : "ended_by_patient",
                "account_id": this.state.providerName,
                'info': {
                    ip: this.state.userIpAddress,
                    "network_type": connection && connection.effectiveType ? connection.effectiveType : '',
                    browserInfo: {
                        browser: details.browser,
                        OS: details.os,
                        device: details.device,
                        cpu: details.cpu,
                        engine: details.engine
                    }
                }
            }
            if (durationType) {
                data['duration'] = timeDuration.duration
            }
            if (this.state.providerName) {
                data['account_id'] = this.state.providerName
            }
            let type = (this.state.isDoctor) ? 'doctor' : 'patient';
            const response = await chatRoomService.callRecordEndDoctorPatient(data, type, this.state.callEndId);
            
            _paq.push(['trackEvent', this.state.providerName, 'callEnded', this.state.chatRoomId]);
            _paq.push(['trackPageView']);
        } catch (error) {
            console.log(error)
        }
    }

    gotDevices = (mediaDevices) => {
        // const select = document.getElementById('video-devices');
        // select.innerHTML = '';
        // select.appendChild(document.createElement('option'));
        let count = 1;
        let { availableVideoDevices } = this.state;
        mediaDevices.forEach(mediaDevice => {
            let data = {
                key: '',
                value: ''
            }
            if (mediaDevice.kind === 'videoinput') {
                // const option = document.createElement('option');
                data['key'] = mediaDevice.deviceId;
                data['value'] = mediaDevice.label || `Camera ${count}`;
                availableVideoDevices.push(data);
                this.setState({ availableVideoDevices });
                // option.value = mediaDevice.deviceId;
                // const label = mediaDevice.label || `Camera ${count}`;
                // const textNode = document.createTextNode(label);
                // option.appendChild(textNode);
                // select.appendChild(option);
            }
        });
    }

    checkCallRecordInitiateResponse(response) {
        let { isCheckIn, token, roomName, callEndId } = this.state;
        if (!response) {
            isCheckIn = false
            token = null
            this.setState({ isCheckIn, token })
            return;
        }

        switch (response.type) {
            case "error":
                isCheckIn = false
                token = null
                this.setState({ isCheckIn, token })
                break;
            case "success":
                isCheckIn = false
                roomName = 'User';
                callEndId = response && response.data && response.data.id ? response.data.id : null;
                token = response && response.data && response.data.token ? response.data.token : null;
                this.setState({ isCheckIn, token, roomName, callEndId }, () => this.joinRoom())
                
                break;

            default:
                break;
        }
    }
    // token = response && response.data && response.data.token ? response.data.token : null;
    goFull = () => {
        let { isFull } = this.state;
        isFull = !isFull;
        this.setState({ isFull });
    }

    getEmitedEventToOpenSupport = (event) => {
        let { isSupportActive } = this.state;
        isSupportActive = true;
        this.setState({ isSupportActive });
    }

    getEmitedSupportCloseEvent = (event) => {
        let { isSupportActive } = this.state;
        isSupportActive = false;
        this.setState({ isSupportActive });
    }

    connectVoiceCall = () => {
        let { isDoctor } = this.state;
        let data = {
            'appointment_id': this.state.appointmentId,
            'patient_id': this.state.patientDetail.id,
            'doctor_id': this.state.doctorDetail.doctor_id,
            "room_id": this.state.chatRoomId,
            "account_id": this.state.providerName
        }
        if (isDoctor) {
            this.voiceCallPatient(data);
        } else {
            this.checkForPatientCallStatus(data);
        }
    }

    async voiceCallPatient(data) {
        try {
            let res = await onlineConsultationService.voiceCallPatient(data);
            if (res && res.type === 'success') {
                this.setState({
                    alert: {
                        show: true,
                        title: 'Request for call successful!',
                        type: 'success',
                        text: 'You will receive call info shortly!'
                    }
                })
            } else {
                this.onError(res);
            }
        } catch (err) {
            this.onError(err);
        }
    }

    async voiceCallDoctor(data) {
        try {
            let res = await onlineConsultationService.voiceCallDoctor(data);
            if (res && res.type === 'success') {
                this.setState({
                    alert: {
                        show: true,
                        title: 'Request for call successful!',
                        type: 'success',
                        text: 'You will receive call info shortly!'
                    }
                })
            } else {
                this.onError(res);
            }
        } catch (err) {
            this.onError(err);
        }
    }

    async checkForPatientCallStatus(data) {
        try {
            let res = await onlineConsultationService.checkPatientCallStatus(data);
            if (res && res.type === 'success' && res.data) {
                if (res.data.status) {
                    this.voiceCallDoctor(data);
                } else {
                    this.setState({
                        alert: {
                            show: true,
                            title: 'Cannot call Doctor now',
                            type: 'error',
                            text: res.data.message
                        }
                    })
                }
            } else {
                this.onError(res);
            }
        } catch (err) {
            this.onError(err);
        }
    }

    visibilityShare() {
        return window.innerWidth > 600 ? true : false
    }

    onError(res) {
        this.setState({
            isTriggerLoading: false,
            isSmsModalOpen: false,
            alert: {
                show: true,
                title: 'Error',
                type: 'error',
                text: typeof res === 'object' ? res.message : res ? res : 'Error occurred'
            }
        })
    }

    sweetAlertClose = (status) => {
        if (status && this.state.alert.type === 'confirmation') {
            // confirmation to leave room after everyone has left
            this.disconnect();
        }
        this.setState({ alert: { show: false } });
    }

    getEmitedSmsStatusUpdateEvent = (event) => {
        if (!event) {
            return;
        }
        let { isSmsModalOpen } = this.state;
        isSmsModalOpen = true;
        this.setState({ isSmsModalOpen });
    }
    onSmsModalCloseEvent = (event) => {
        let { isSmsModalOpen } = this.state;
        isSmsModalOpen = false;
        this.setState({ isSmsModalOpen });
    }

    setStats = () => {
        if (!this.state.activeRoom) return;
        let { isStatsMenu } = this.state;
        isStatsMenu = !isStatsMenu;
        this.setState({ isStatsMenu })
    }

    getStatsData = () => {

        
        if (!this.state.activeRoom) return;
        this.state.activeRoom.getStats().then(stats => {
            
            this.setState({
                stats: {
                    localaudio: stats && stats.length && stats[0].localAudioTrackStats[0] ? stats[0].localAudioTrackStats[0] : '',
                    localvideo: stats && stats.length && stats[0].localVideoTrackStats[0] ? stats[0].localVideoTrackStats[0] : '',
                    remoteaudio: stats && stats.length && stats[0].remoteAudioTrackStats[0] ? stats[0].remoteAudioTrackStats[0] : '',
                    remotevideo: stats && stats.length && stats[0].remoteVideoTrackStats[0] ? stats[0].remoteVideoTrackStats[0] : ''
                }
            });


            if (stats && stats.length && stats[0].remoteVideoTrackStats[0]) {
                let remoteVideoStats = stats[0].remoteVideoTrackStats[0]
                let lost = remoteVideoStats.packetsLost - this.state.packetsLost;
                let received = remoteVideoStats.packetsReceived - this.state.packetsReceived
                let percentChange = 0;
                let poorConnection = false;
                let remoteQualityIndex = this.state.remoteQualityIndex
                if (received == 0) {
                    poorConnection = true
                }
                if (lost > 0) {
                    percentChange = lost / received * 100;
                    if (percentChange > 5) {
                        if (this.state.videoSettings) {
                            remoteQualityIndex += 1
                            this.sendMessageData(1)
                        }
                    }
                }
                else if (lost == 0) {
                    remoteQualityIndex -= 1;
                    this.sendMessageData(-1)

                    /* }
                    if(this.state.packetsReceived > 2000){
                        this.sendMessageData(-1) */
                }
                let { qualityLogs } = this.state;
                let lastQuality = this.state.qualityLogs[0]
                let currentQuality = stats && stats.length && stats[0].localVideoTrackStats[0] ? stats[0].localVideoTrackStats[0] : ''; //get
                if (!lastQuality || !this.equals(lastQuality, currentQuality)) {
                    this.state.qualityLogs.push(currentQuality ? { frameRate: currentQuality.frameRate, dimensions: currentQuality.dimensions } : '');
                }
                this.setState({
                    remoteQualityIndex: remoteQualityIndex,
                    poorConnection: poorConnection,
                    packetsLost: remoteVideoStats.packetsLost,
                    packetsReceived: remoteVideoStats.packetsReceived,
                    qualityLogs: qualityLogs
                })


            }
        });
        // this.state.isStatsMenu
        if (this.state.activeRoom) {
            setTimeout(() => {
                this.getStatsData();
            }, this.state.videoSettings.check_time ? this.state.videoSettings.check_time * 1000 : 10000)
        }
    }

    equals = (last, curr) => {
        if (!last && !curr) return true //dont push identical logs
        if (!last || !curr) return false
        if (last.frameRate === curr.frameRate && !last.dimensions && !curr.dimensions) return true
        if (last.frameRate === curr.frameRate && (!last.dimensions || !curr.dimensions)) return false
        return last.frameRate != curr.frameRate || last.dimensions.width != curr.dimensions.width || last.dimensions.height != curr.dimensions.height ? false : true
    }

    togglePiPMode = () => {
        let divEl = document.getElementsByClassName('demovideo');
        
        try {
            if (!document.pictureInPictureElement && divEl) {
                let key = divEl[0].requestPictureInPicture ? 0 : 1;
                divEl[key].requestPictureInPicture()
                    .catch(error => {
                        // Video failed to enter Picture-in-Picture mode.
                    });
                // let that = this;
                divEl[key].addEventListener('enterpictureinpicture', () => {
                    // Video entered Picture-in-Picture mode.
                    // const pipWindow = event.pictureInPictureWindow;
                    // console.log('Picture-in-Picture window width: ' + pipWindow.width);
                    // console.log('Picture-in-Picture window height: ' + pipWindow.height);
                    this.props.pipModeOn(true);
                });

                divEl[key].addEventListener('leavepictureinpicture', () => {
                    // Video left Picture-in-Picture mode.
                    this.props.pipModeOn(false);
                });
            } else {
                document.exitPictureInPicture()
                    .catch(error => {
                        // Video failed to leave Picture-in-Picture mode.
                    });
            }
        } catch (error) {
            console.log(error + 'Error during pip mode');
            this.props.pipModeOn(false);
        }
    }

    getEmitedShowTimeLeftMsg = (event) => {
      
        if (!this.state.isDoctor) {
            return;
        }
        
        this.setState({ alert: { show: true, title: "Time Left", text: event, type: 'info', confirmBtnText: "Ok" } })
    }

    getEmitedTimeOverEvent = (event) => {
        if (!this.state.isDoctor) {
            return;
        }
        this.setState({ alert: { show: true, title: "Consultation time", text: "Consultation time is over. Do you want to leave?", type: 'confirmation', confirmBtnText: this.state.labels.yes } })
    }

    render() {
        let { patientDetail, doctorDetail, appointmentId, accountInfo, isSupportActive, appointmentDate,
            appointmentSlot, reconnecting, stats, quality, videoSettings, showLiveStatus, remoteConnecting,
            isDoctor, chatRoomId, isSMSFeatureEnabled, chatHistory, labels, poorConnection, isUserVideoLoading, showTimer } = this.state;
        // let isDoctorNotes = accountInfo && accountInfo.ask_patient_symptoms && isDoctor ? true : false;
        return (
            <>
                <Fullscreen
                    enabled={this.state.isFull}
                    onChange={isFull => this.setState({ isFull })}
                >
                    <nav className={`navbar default-layout-navbar bg-dark col-lg-12 col-12 p-0 fixed-top d-flex flex-row`}>
                        <div className={`text-center navbar-brand-wrapper d-flex align-items-center justify-content-center text-white pl-3 bg-dark`}>
                            <span className="navbar-brand brand-logo text-white">{isDoctor ? patientDetail.name : doctorDetail.doctor_name}</span>
                            <span className="navbar-brand brand-logo-mini text-white">{isDoctor ? patientDetail.name : doctorDetail.doctor_name}</span>
                        </div>
                        <div className="navbar-menu-wrapper d-flex align-items-stretch" >
                            <ul className="navbar-nav navbar-nav-right ml-auto">
                                <li className="nav-item">
                                    {this.state.audios ? <AudioAnalyser size={25} audio={this.state.audios} />
                                        : ''}
                                </li>
                                {
                                    sessionStorage.getItem('s3AccountId') !== "breathefree" ||  sessionStorage.getItem('s3AccountId') !== "meitra_hospital" ?  <li className="nav-item">
                                    {(accountInfo && accountInfo.video_timer_settings && showTimer && isDoctor) &&
                                        <p className="nav-link m-0 mr-2 ml-2" style={{ width: 120,color:"#fff" }}><TimerComponent accountInfo={accountInfo} emitShowTimeLeftMsg={this.getEmitedShowTimeLeftMsg.bind(this)} emitTimeOverEvent={this.getEmitedTimeOverEvent.bind(this)}></TimerComponent> </p>
                                    }
                                </li> : <li className="nav-item">
                                    <i className='mdi mdi-checkbox-blank-circle' style={{color:"red"}}></i> Rec
                                </li>
                                }
                               
                                <li className="nav-item">
                                    <span className="nav-link m-0 mr-2" onClick={this.goFull.bind(this)}><i className="mdi mdi-fullscreen md-18"></i> </span>
                                </li>
                                {isDoctor && <>
                                    <li className="nav-item">
                                        <span className="nav-link m-0 mr-2" onClick={this.getEmitedEventToOpenSupport.bind(this)} style={{ cursor: 'pointer',color:"#fff" }}><i className="mdi mdi-face md-18"></i>Support </span>
                                    </li>
                                    {isSMSFeatureEnabled && <li className="nav-item">
                                        <span className="nav-link m-0 mr-2" onClick={this.getEmitedSmsStatusUpdateEvent.bind(this)} style={{ cursor: 'pointer',color:"#fff" }}><i className="mdi mdi-message md-18"></i>SMS </span>
                                    </li>}
                                </>
                                }
                                {this.state.activeRoom &&
                                    <ul className="navbar-nav navbar-nav-right navbar-collapse collapse d-block" id="NewChatRoomContainer" >
                                        <li className="nav-item dropdown pointer-cursor" ref={menuRef => { this.menuRef = menuRef }}>
                                            {/* data-toggle="dropdown" */}
                                            <button className=" navbar-toggler navbar-toggler-right align-self-center" type="button" onClick={this.setStats.bind(this)} style={{ padding: 0 }}>
                                                <i className="mdi mdi-dots-vertical" placement="top" data-toggle="tooltip" data-original-title="Stats" style={{ color: "white" }}></i>
                                            </button>
                                            <StatsMenu isStatsMenu={this.state.isStatsMenu} stats={stats} quality={quality} setQuality={this.setQuality.bind(this)} video_settings={videoSettings} />
                                        </li>
                                    </ul>
                                }
                                {(this.state.availableVideoDevices && this.state.availableVideoDevices.length > 1) &&
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="#" id="orders-dropdown" data-toggle="dropdown" aria-expanded="false"><i className="mdi mdi-camera md-18" style={{ fontSize: 24 }}></i> </a>
                                        <div className="dropdown-menu navbar-dropdown" aria-labelledby="orders-dropdown">
                                            {this.state.availableVideoDevices.map((indDevice, jndex) =>
                                                <React.Fragment key={jndex}>
                                                    <a className="dropdown-item pointer-cursor" onClick={this.toggleCameraView.bind(this, indDevice, jndex)}>
                                                        {indDevice.value} </a>
                                                    <div className="dropdown-divider"></div>
                                                </React.Fragment>
                                            )}


                                        </div>
                                    </li>
                                }
                            </ul>
                        </div>
                        <div className="clinytic-line-loader" style={{ display: this.state.loading ? '' : 'none' }}></div>
                    </nav>
                    <div className="main-panel">
                        {(reconnecting || showLiveStatus) && <nav className={`navbar default-layout-navbar reconnecting col-lg-12 col-12 p-0 fixed-top d-flex flex-row`}>
                            <div className="navbar-menu-wrapper d-flex align-items-stretch">
                                <ul className="navbar-nav ml-4">
                                    <li className="nav-item text-white">{reconnecting && <><div className="d-inline-block"><Spinner width={20} height={20} /></div>Trying to Reconnect..</> || 'Back Again!'}</li></ul>
                            </div>
                        </nav>}
                        {/* remote participant trying to reconnect */}
                        {remoteConnecting && <button type="button" className="btn" style={{ position: 'absolute', top: '35%', alignSelf: 'center', width: '186px' }}>
                            <Spinner width={40} height={40}></Spinner>
                            <p className="text-white">{isDoctor ? 'Patient' : 'Doctor'} Reconnecting..</p>
                        </button>}
                        {poorConnection && !remoteConnecting && <button type="button" className="btn" style={{ position: 'absolute', top: '35%', alignSelf: 'center', width: '186px' }}>
                            <Spinner width={40} height={40}></Spinner>
                            <p className="text-white">Poor Connection. Please try reconnecting</p>
                        </button>}
                        <section className="chatroomdemo" style={{ paddingTop: 52 }}>
                            <div className="clinytic-line-loader" style={{ display: !this.state.activeRoom ? '' : 'none' }}></div>

                            {this.state.token && this.state.activeRoom &&
                                <div className="container-fluid p-0">
                                    <div className="row ">
                                        <div className="col-md-12">
                                            <div className="user-video" ref="remoteMedia">
                                            </div>
                                            <div className=" userwindow " ref="localMedia">
                                                <div className="name" style={{ left: 30 }}>
                                                    <i className="status"></i> {isDoctor ? doctorDetail.doctor_name : patientDetail.name} </div>
                                                <div className="name" style={{ left: 2, background: "rgba(25,22,22,.5)", padding: 2 }}>
                                                    {this.state.localAudios && !this.state.isMute ? <AudioAnalyser size={20} audio={this.state.localAudios} />
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="chatcontrol d-md-block mb-3">
                                            <div className="vchat-assets text-center  justify-content-between ">
                                                <button onClick={this.mute.bind(this)} type="button" className="btn btn-social-icon  btn-rounded  ">
                                                    <i className={this.state.isMute ? "mdi mdi-microphone-off" : "mdi mdi-microphone"} placement="top" data-toggle="tooltip"
                                                        data-original-title="Mute Audio "></i>
                                                </button>
                                                <button onClick={this.disableVideo.bind(this)} type="button" className="btn btn-social-icon  btn-rounded " placement="top" data-toggle="tooltip"
                                                    data-original-title="Disable Video">
                                                    <i className={this.state.isVideoOff ? "mdi mdi-video-off" : "mdi mdi-video"}></i>
                                                </button>
                                                {/*this.visibilityShare() &&
                                                    <button onClick={this.share.bind(this)} type="button" className="btn btn-social-icon  btn-rounded  ">
                                                        <i className="mdi mdi-airplay" placement="top" data-toggle="tooltip" data-original-title="Share Screen"></i>
                                                    </button>
                            */}

                                                <button onClick={this.disconnect.bind(this)} type="button" className="btn btn-social-icon  btn-rounded  active">
                                                    <i className="mdi mdi-phone-hangup" placement="top" data-toggle="tooltip"
                                                        data-original-title="Phone Hangup"></i>
                                                </button>
                                                {
                                                    sessionStorage.getItem('s3AccountId') !== "breathefree" && <>
                                                    {isDoctor && this.state.showPipMode && <button onClick={this.togglePiPMode.bind(this)} type="button" className="btn btn-social-icon  btn-rounded">
                                                    <i className="mdi mdi-tab" placement="top" data-toggle="tooltip"
                                                        data-original-title="Mini Player"></i>
                                                </button>}
                                                    </>
                                                }
                                                
                                                {accountInfo && accountInfo.enable_tele_call && accountInfo.enable_tele_call === true &&
                                                    <button onClick={this.connectVoiceCall.bind(this)} type="button" className="btn btn-social-icon  btn-rounded">
                                                        <i className="mdi mdi-cellphone-basic" placement="top" data-toggle="tooltip"
                                                            data-original-title="Voice Call"></i>
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </section>
                        {(accountInfo && accountInfo.support_link && accountInfo.support_link !== "-") &&
                            <div className="help_widget" style={{ right: 'unset' }}>
                                <h4>{labels.clinicSupport}:</h4>
                                <a href={`https://api.whatsapp.com/send?phone=${accountInfo.support_link}`}>{accountInfo.support_link}</a>
                            </div>
                        }
                        <div className="floating-button">
                            <div className="chatbox" style={{ bottom: 0 }}>
                                {this.state.chatOnline && <PatientTextChat chatHistory={chatHistory} labels={labels.chatLabels} prevChat={this.state.prevChat} sendNewMsg={this.sendNewMsg.bind(this)} onHide={() => this.setState({ chatOnline: !this.state.chatOnline })} isDoctor={isDoctor}
                                    chatLoading={this.state.chatLoading} />}
                            </div>
                            <div className="chatbox" style={{ bottom: 0, width: 350, display: this.state.isDoctorComplaints ? '' : 'none' }}>
                                <DoctorNotesComponent
                                    patientDetail={patientDetail}
                                    chatRoomId={chatRoomId}
                                    onHide={() => this.setState({ isDoctorComplaints: !this.state.isDoctorComplaints })} isDoctor={isDoctor}>
                                </DoctorNotesComponent>
                            </div>
                            {(this.state.showChat) && (this.state.remoteHasTracks) && <a className={`float bg-light text-dark ${this.state.glowBtn ? 'glow-btn' : ''}`}
                                id="menu-share" data-toggle="tooltip" data-placement="top" onClick={() => this.setState({ chatOnline: !this.state.chatOnline, glowBtn: false })} style={{ cursor: 'pointer', bottom: '12px', right: '14px' }}>
                                {labels.chat} </a>}

                            {this.state.isDoctorNotes &&
                                <a className={`float bg-light text-dark glow-btn`}
                                    id="menu-share" data-toggle="tooltip" onClick={() => this.setState({ isDoctorComplaints: !this.state.isDoctorComplaints })} data-placement="top" style={{ cursor: 'pointer', bottom: '70px', right: '14px', fontSize: 12 }}>
                                    Notes </a>
                            }
                        </div>
                    </div>
                    {(isSupportActive && patientDetail && appointmentId) &&
                        <SupportContainer
                            open={isSupportActive}
                            providerName={this.state.providerName}
                            appointmentId={appointmentId}
                            doctorDetail={doctorDetail}
                            patientDetail={patientDetail}
                            emitSupportCloseEvent={this.getEmitedSupportCloseEvent.bind(this)}>
                        </SupportContainer>
                    }
                </Fullscreen>
                {this.state.alert.show &&
                    <SweetAlertComponent show={this.state.alert.show} type={this.state.alert.type} backBtnText={labels.back} confirmBtnText={this.state.alert.confirmBtnText} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.sweetAlertClose.bind(this, true)} onCancel={this.sweetAlertClose.bind(this, false)}></SweetAlertComponent>
                }
                {isUserVideoLoading && <CallAlertModalComponent
                    open={isUserVideoLoading}
                    waitingMessage={labels['pleaseWaitModal'].waitingMessage}
                    patientDetail={isDoctor ? { name: doctorDetail.doctor_name } : patientDetail}
                    emitOnModalCloseEvent={() => { }}
                    hideButton={true}
                    labels={labels['pleaseWaitModal']}>
                </CallAlertModalComponent>
                }
                {
                    this.state.isSmsModalOpen &&
                    <CommonModalView
                        open={this.state.isSmsModalOpen}
                        emitModalCloseEvent={this.onSmsModalCloseEvent.bind(this)}
                        title={`Send SMS Message`}>
                        <PatientSmsStatusComponent
                            source={shootSMSSource.chatroom}
                            patientDetail={this.state.patientDetail}
                            apptDetail={{ appointment_date: appointmentDate, id: appointmentId, appointment_slot: appointmentSlot, room_id: chatRoomId }}
                            emitSmsStatusSaveEvent={this.onSmsModalCloseEvent.bind(this)}>
                        </PatientSmsStatusComponent>
                    </CommonModalView>
                }
            </>
        );
    }
}

export default withSnackbar(NewChatRoomContainer);

export const StatsMenu = ({ isStatsMenu, stats, quality, setQuality, video_settings }) => {
    return <div className={`dropdown-menu navbar-dropdown ${isStatsMenu ? 'show' : ''}`} aria-labelledby="orders-dropdown" style={{ width: "100%", minWidth: "300px" }}>
        <div className="accordion accordion-solid-content" id="accordion-5" role="tablist">
            <div className="card m-0">
                <div className="card-header" role="tab" id="heading-13">
                    <h6 className="mb-0 mt-0">
                        <a className="collapsed p-3" data-toggle="collapse" href="#collapse-14" aria-expanded="false" aria-controls="collapse-13">
                            <i className="mdi mdi-settings"></i> Quality Settings</a>
                    </h6>
                </div>
                <div id="collapse-14" className="card-body collapse p-3" role="tabpanel" aria-labelledby="heading-13" data-parent="#accordion-5">
                    <div className="row">
                        <div className="col-sm-12">
                            <label className="mr-3">Video Quality</label>
                            <select className="form-control" type="select" name="quality" onChange={setQuality.bind(this)} value={quality ? quality : 'initial'}>
                                <option value='initial' disabled>Select quality</option>
                                {video_settings && video_settings.qualities && video_settings.qualities.map((q) => <option value={q.key}>{q.key}</option>)}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card m-0">
                <div className="card-header" role="tab" id="heading-13">
                    <h6 className="mb-0 mt-0">
                        <a data-toggle="collapse" href="#collapse-13" aria-expanded="false" aria-controls="collapse-13" className="collapsed p-3">
                            <i className="mdi mdi-chart-bar"></i> Check Stats</a>
                    </h6>
                </div>
                <div id="collapse-13" className="card-body collapse p-3" role="tabpanel" aria-labelledby="heading-13" data-parent="#accordion-5">
                    <p className="m-2 mb-0" style={{ textAlign: 'center' }}>Local Stats</p>
                    <table className="table table-hover table-sm border-bottom">
                        <thead className="thead-light"><td><strong>Property</strong></td><strong>Value</strong><td></td></thead>
                        <tbody>
                            <tr><td>Audio Level: </td><td>{stats && stats.localaudio && stats.localaudio.audioLevel}</td></tr>
                            <tr><td>Audio Jitter: </td><td>{stats && stats.localaudio && stats.localaudio.jitter}</td></tr>
                            <tr><td>Video dimensions: </td><td style={{ whiteSpace: "normal" }}>{stats && stats.localvideo && stats.localvideo.dimensions && <>Width: {stats.localvideo.dimensions.width} | Height: {stats.localvideo.dimensions.height}</>}</td></tr>
                            <tr><td>Video frame rate: </td><td>{stats && stats.localvideo && stats.localvideo.frameRate}</td></tr>
                        </tbody>
                    </table>
                    <p className="m-2 mb-0" style={{ textAlign: 'center' }}>Remote Stats</p>
                    <table className="table table-hover table-sm">
                        <thead className="thead-light"><td><strong>Property</strong></td><strong>Value</strong><td></td></thead>
                        <tbody>
                            <tr><td>Audio Level: </td><td>{stats && stats.remoteaudio && stats.remoteaudio.audioLevel}</td></tr>
                            <tr><td>Audio Jitter: </td><td>{stats && stats.remoteaudio && stats.remoteaudio.jitter}</td></tr>
                            <tr><td>Video dimensions: </td><td style={{ whiteSpace: "normal" }}>{stats && stats.remotevideo && stats.remotevideo.dimensions && <>Width: {stats.remotevideo.dimensions.width} | Height: {stats.remotevideo.dimensions.height}</>}</td></tr>
                            <tr><td>Video frame rate: </td><td>{stats && stats.remotevideo && stats.remotevideo.frameRate}</td></tr>
                            <tr><td>Packets Lost: </td><td>{stats && stats.remotevideo && stats.remotevideo.packetsLost}</td></tr>
                            <tr><td>Packets Received: </td><td>{stats && stats.remotevideo && stats.remotevideo.packetsReceived}</td></tr>
                        </tbody>
                        {/* packetsLost */}
                    </table>
                </div>
            </div>
        </div>
    </div>;
}