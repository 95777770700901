
import React from 'react';
import { Helmet } from "react-helmet";
import SweetAlertComponent from "../../Common/Alert/SweetAlertComponent"
import { ProviderEnum, ThemeFileEnum, BgColor } from '../../providerEnum';
import { getAccInfo } from './vaccinationService';
const $ = window["jQuery"];


export default class VaccinationHeader extends React.Component {
    constructor(props) {
        super(props)
        
        

    }

    async componentDidMount(){
        let accInfo=sessionStorage.getItem("acc_info")
        let account_id = this.props.account_id
        try {
            if(accInfo){
                accInfo= JSON.parse(accInfo)
            }else{
                let d=await getAccInfo(account_id)
                accInfo=d.data
            }
        } catch (error) {
            console.log("Error while loading theme",error)
        }
        
        this.setThemeData(accInfo)
    }

    setThemeData(accInfo) {

        if (accInfo) {
            if (!accInfo.theme || (accInfo.theme && accInfo.theme === "default")) {
                if (accInfo.theme) {
                    $(`link[src="${ThemeFileEnum[accInfo.theme]}"]`).remove();
                }
                return;
            }
            setTimeout(() => {
                const cssUrl = ThemeFileEnum[accInfo.theme];
                this.addStyle(cssUrl, accInfo.theme);
            }, 0)
        }
    }

    addStyle = (url, id) => {
        const style = document.createElement("link");
        style.href = url;
        style.id = id;
        style.rel = "stylesheet";
        style.async = true;

        document.head.appendChild(style);
    };

    render() {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.title}</title>
                    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css" />
                    <link rel="shortcut icon" type="image/x-icon" href={this.props.favicon} />

                </Helmet>
                {this.props.loading && (this.props.flowType && this.props.flowType !== "v2") && (
                    <div className="clinytic-line-loader"></div>)}
                {this.props.loading && !this.props.flowType && (
                    <div className="clinytic-line-loader"></div>)}
                {!this.props.isVisible &&
                    <> {this.props.alert && this.props.alert && <SweetAlertComponent title={this.props.alert.title} text={this.props.alert.text} sweetAlertClose={this.props.alert.onClose}></SweetAlertComponent>}</>
                }

            </>

        )
    }
}