import React from "react";
import ValidationComponent from "./ValidationComponent";

class TextBoxUpDownViewComponent extends React.PureComponent {

    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    changeValue = (event) => {
        event.preventDefault();
        this.props.emitTextboxValue({ name: event.target.name, value: event.target.value })
    }

    onFocus = (event) => {
        event.preventDefault();        
    }

    textToHtmlConversion(text) {
        return { __html: text };
    }

    setErrorStatus = (errorStatus) => {
        this.props.emitErrorStatus(errorStatus);
    }

    checkForLabelValue(str) {
        if (!str || !str.trim()) {
            return false;
        }
        return true
    }

    render() {
        // console.log('-----------------------------------textbox---------------------------------');
        const { type = "textbox", name, placeholder, label, labelSize, size, value, readOnly, offset, rowType, showUnit } = this.props;
        return (
            // .2rem 1rem 5px 0px , background:  'initial'
            <React.Fragment>
                <div className="col-sm p-1">
                    <small className="w-100 d-inline-block" style={{display: this.checkForLabelValue(label) ? '' : 'none'  }}> {label}{showUnit && <sub style={{bottom: 0, marginLeft: '3px'}}>({showUnit})</sub>}</small>
                    <input type="text"
                        className="form-control form-control-sm d-inline-block float-left"
                        width="20"
                        id={name}
                        name={name}
                        type={type}
                        disabled={readOnly ? true : false}
                        value={value}
                        placeholder={placeholder ? placeholder : showUnit}
                        onChange={this.changeValue}
                        onFocus={this.onFocus}/>
                    {this.props.validation && <ValidationComponent value={value} checkForm={this.props.checkForm} isDirty={this.props.isDirty} validation={this.props.validation} emitErrorStatus={this.setErrorStatus.bind(this)} />}
                </div>
                {offset &&
                    <div className={offset}> </div>
                }
            </React.Fragment>
        );
    }
}

export default TextBoxUpDownViewComponent;
