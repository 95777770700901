import React from "react";
import ValidationComponent from "./ValidationComponent";
class TextareaComponent extends React.Component {

    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    changeValue = (event) => {
        event.preventDefault();
        this.props.emitTextboxValue({ name: event.target.name, value: event.target.value })
    }

    onAddButtonClicked = (event) => {
        this.props.emitAddNewRowObjectData(true);
    }

    onDeleteButtonClicked = (event) => {
        this.props.emitDeleteRowObjectData(true);
    }

    setErrorStatus = (errorStatus) => {
        this.props.emitErrorStatus(errorStatus);
    }

    checkForLabelValue(str) {
        if (!str || !str.trim()) {
            return false;
        }
        return true
    }

    onFocus = (event) => {
        event.preventDefault();
        this.props.emitFocusEvent()
    }

    render() {
        // console.log('-----------------------------------textarea---------------------------------');
        const { type, name, classname, value, placeholder, label, labelSize, size, offset, rowType, isFullSize, showType, showUnit } = this.props;
        return (
            // .2rem 1rem // 5px 0px
            <React.Fragment>
                {labelSize &&
                    <label className={`col-${labelSize}`} style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}> {label}{showUnit && <sub style={{ bottom: 0, marginLeft: '3px' }}>({showUnit})</sub>}</label>
                }
                <div className={`col${size ? "-" + size : ''} ${showType === "table" ? 'p-0' : ''}`} style={{ padding: rowType === "row" ? '' : '', margin: rowType === "row" ? '' : '' }}>
                    {!labelSize &&
                        <label style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}> {label}{showUnit && <sub style={{ bottom: 0, marginLeft: '3px' }}>({showUnit})</sub>}</label>
                    }
                    <input
                        className="form-control"
                        name={name}
                        type={type}
                        value={value}
                        onFocus={this.onFocus}
                        placeholder={placeholder ? placeholder : showUnit}
                        onChange={this.changeValue}>
                    </input>
                    {this.props.validation && <ValidationComponent value={value} checkForm={this.props.checkForm} isDirty={this.props.isDirty} validation={this.props.validation} emitErrorStatus={this.setErrorStatus.bind(this)} />}
                </div>
                {offset &&
                    <div className={offset}> </div>
                }
            </React.Fragment>
        );
    }
}

export default TextareaComponent;
