import request from '../Api/request';
import userAuth from '../Authentication/authActions';
import Utils from "../Shared/utils/utils";

function formatQueryParams(queryString) {
    if (queryString.indexOf('&') !== -1) {
        let paramArray = queryString.split('&');
        return paramArray[1] !== "" ? `${paramArray[0]}&${paramArray[1]}` : `${paramArray[0]}`
    }
    return queryString;
}
// https://betaemrapi.hlthclub.in/admin/get-catalogue/thb_test?get_categories=true

function getFilterData(account_id, token) {
    let api = '';
    if (token) {
        let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1) ? 'dev' : 'prod';
        if (apiStage === "prod") {
            api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/admin` : `https://emrapi.hlthclub.in/admin`;
        } else {
            api = `https://betaemrapi.hlthclub.in/admin`;
        }
    } else {
        api = getApiStageForAccount();
    }
    return request({
        baseURL: api,
        url: `/get-catalogue/${account_id}?get_categories=true`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + (token ? ("peptoken-" + token) : userAuth.getToken())
        }
    }, 'appointment');
}

function searchCatalogue(account_id, term, category, token) {
    let api = '';
    if (token) {
        let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1) ? 'dev' : 'prod';
        if (apiStage === "prod") {
            api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/admin` : `https://emrapi.hlthclub.in/admin`;
        } else {
            api = `https://betaemrapi.hlthclub.in/admin`;
        }
    } else {
        api = getApiStageForAccount();
    }
    return request({
        // get_categories=True/False&catalogue_name=xyz&datatable_format=True
        baseURL: api,
        url: `/search/catalogue?account_id=${account_id}&term=${term}&category=${category}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + (token ? ("peptoken-" + token) : userAuth.getToken())
        }
    }, 'appointment');
}

function getCatalogueData(account_id, invoice_type, tokenData) {
    let api = '';
    if (tokenData) {
        let apiStage = ((window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1)) ? 'dev' : 'prod';
        if (apiStage === "prod") {
            api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/admin` : `https://emrapi.hlthclub.in/admin`;
        } else {
            api = `https://betaemrapi.hlthclub.in/admin`;
        }
    } else {
        if (sessionStorage.getItem("userId")) {
            api = getApiStageForAccount();
        } else {
            let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1) ? 'dev' : 'prod';
            if (apiStage === "prod") {
                api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/admin` : `https://emrapi.hlthclub.in/admin`;
            } else {
                api = `https://betaemrapi.hlthclub.in/admin`;
            }
        }
    }
    return request({
        // get_categories=True/False&catalogue_name=xyz&datatable_format=True
        baseURL: api,
        url: `/get-catalogue/${account_id}?datatable_format=true&catalogue_name=${invoice_type}`,
        method: 'get',
        headers: {
            // "Access-Control-Allow-Origin": "*",
            'Authorization': 'Bearer ' + (tokenData ? ("peptoken-" + tokenData) : userAuth.getToken())
        }
    }, 'appointment');
}

function createCatalogueData(account_id, data, isEditList, token) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let api = ''
    if (token) {
        let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1) ? 'dev' : 'prod';
        if (apiStage === "prod") {
            if(Utils.isUatEnvir()){
               api = `https://uatemrapi.hlthclub.in/admin`;
            } 
            api = `https://emrapi.hlthclub.in/admin`;
        } else {
            api = `https://betaemrapi.hlthclub.in/admin`;
        }
    } else {
        api = getApiStageForAccount();
    }

    return request({
        baseURL: api,
        url: `/catalogue/${isEditList ? 'update' : 'create'}/${account_id}`,
        method: isEditList ? 'put' : 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + (token ? ("peptoken-" + token) : userAuth.getToken())
        }
    }, 'appointment');
}

function updateCatalogueData(account_id, data) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStageForAccount();
    return request({
        baseURL: api,
        url: `/catalogue/update/${account_id}`,
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function moveBetaSettingsToProd(accountId) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStageForAccount();
    return request({
        baseURL: api,
        url: "/settings/move_to_prod?account_id=" + accountId,
        method: 'put',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getVaccinationBaseUrl(apiStage) {
    return apiStage === "prod" ? (Utils.isUatEnvir()?'https://uatemrapi.hlthclub.in/vaccination': 'https://emrapi.hlthclub.in/vaccination') : 'https://betaemrapi.hlthclub.in/vaccination';
}

function postQuestionsData(payload, accountId) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getVaccinationBaseUrl(apiStage);
    return request({
        baseURL: api,
        url: `/questionnaire/${accountId}`,
        method: 'post',
        data: payload,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
    // https://a1eky9f5y2.execute-api.ap-south-1.amazonaws.com/dev/questionnaire/demo_account
}

async function getAllAccountData(queryParams, useCache = false) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStageForAccount();
    let formatedQueryParams = '';
    if (queryParams && Object.keys(queryParams).length !== 0) {
        formatedQueryParams = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&')
    }
    let url = "/admin/account/all"
    if (formatedQueryParams) {
        url += `?${formatedQueryParams}`
    }
    let result = {};
    try {
        result = sessionStorage.getItem("all-account-api");
        if (result && useCache) {
            result = JSON.parse(result)
        } else {
            result = await request({
                baseURL: api,
                url: url,
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + userAuth.getToken()
                }
            }, 'appointment');
            if (result.type === "success")
                sessionStorage.setItem("all-account-api", JSON.stringify(result))
        }

    } catch (error) {
        console.log(error)
        throw new Error(error)
    }
    return result
}

function searchAccountData(searchTxt) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStageForAccount();
    return request({
        baseURL: api,
        url: "/admin/account/all?account_name=" + searchTxt ? searchTxt.toLowerCase() : '',
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getApiStagForUser() {
    let apiStage = sessionStorage.getItem('apiStage');
    if (apiStage === "prod") {
        return `https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        return `https://12vwe151nh.execute-api.ap-south-1.amazonaws.com/`
    }
}

function getUsersList(filterParams, id) {
    let queryString = ''
    if (!filterParams || Object.keys(filterParams).length === 0) {
        queryString = ""
    } else {
        if (Object.keys(filterParams).length > 0) {
            for (let key in filterParams) {
                let str = `${key}=${filterParams[key]}&`
                queryString = queryString.concat(str);
            }
        }
    }
    let formatedQueryParams = formatQueryParams((queryString).toLowerCase());
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStagForUser();

    if (!formatedQueryParams) {
        return request({
            baseURL: api,
            url: apiStage + "/users?account_id=" + id,
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    } else {
        return request({
            baseURL: api,
            url: apiStage + `/users?account_id=${id}&` + formatedQueryParams,
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    }

}


function getAccountDetailById(id) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStageForAccount();

    return request({
        baseURL: api,
        url: "/admin/account/" + id,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function updateAccountNewUser(data, queryData) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStagForUser();
    return request({
        baseURL: api,
        url: apiStage + `/user/${queryData.id}?account_id=${queryData.accountId}`,
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}


function getUserDetailByAccount(accountId, userId) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStagForUser();

    return request({
        baseURL: api,
        url: apiStage + `/user/${userId}?account_id=` + accountId,
        // `/admin/account/${accountId}/user/${userId}`
        // + "&id=" + userId
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');

    // https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/prod/user/f9663a22-1db1-49d8-9389-bfd47e3f1d8e?account_id=demo_account
}

function createAccountAndUser(data, id) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStageForAccount();

    if (id) {
        return request({
            baseURL: api,
            url: "/admin/account/" + id,
            method: 'put',
            data: data,
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    } else {
        return request({
            baseURL: api,
            url: "/admin/account",
            method: 'post',
            data: data,
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    }
}

function getAccountUserFilters(queryParams) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStageForAccount();

    if (queryParams) {
        return request({
            baseURL: api,
            url: "/admin/account/create-user/filters?" + queryParams,
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment')
    }
    return request({
        baseURL: api,
        url: "/admin/account/create-user/filters",
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment')

}

function createAccountNewUser(data) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1) ? 'dev' : 'prod';
    let api = '';
    if (apiStage === "prod") {
        api = `https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://12vwe151nh.execute-api.ap-south-1.amazonaws.com/`
    }

    return request({
        baseURL: api,
        url: apiStage + '/user',
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function createOnlineDoctor(data, env) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let apiStage = ''
    if (env) {
        apiStage = env
    } else {
        apiStage = sessionStorage.getItem('apiStage');
    }

    let api = '';
    if (apiStage === "prod") {
        api = `https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://12vwe151nh.execute-api.ap-south-1.amazonaws.com/`
    }

    return request({
        baseURL: api,
        url: apiStage + '/onlinedoctor',
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getOnlineDoctor(account_id, cognito_user_sub) {
    let apiStage = sessionStorage.getItem('apiStage');
    // let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1) ? 'prod' : 'prod';
    let api = '';
    if (apiStage === "prod") {
        api = `https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://12vwe151nh.execute-api.ap-south-1.amazonaws.com/`
    }

    return request({
        baseURL: api,
        url: apiStage + `/onlinedoctor/details?account_id=${account_id}&cognito_user_sub=${cognito_user_sub}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function updateStageUserById(data) {
    let id = data.id;

    data['phone'] = data['phone'] === "+977" ? data['cognito_username'] : data['phone']

    let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStagForUser();

    // delete data.id
    return request({
        baseURL: api,
        url: apiStage + "/user/" + id,
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getAccountDataById(id) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStageForAccount();
    return request({
        baseURL: api,
        url: "/settings/account/" + id,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function getApiStageForAccount() {
    let apiStage = sessionStorage.getItem('apiStage');
    if (apiStage === "prod") {
        return Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/admin` :`https://emrapi.hlthclub.in/admin`;
    } else {
        return `https://betaemrapi.hlthclub.in/admin`;
    }
}

function getLogoUrl() {
    let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1) ? 'dev' : 'prod';
    if (apiStage === "prod") {
        return Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/admin` :`https://emrapi.hlthclub.in/admin`;
    } else {
        return `https://betaemrapi.hlthclub.in/admin`;
    }
}


function uploadAccountLogo(accountId, imgData) {
    let api = getLogoUrl();
    return request({
        baseURL: api,
        url: `/settings/account/${accountId}?account_id=${accountId}`,
        method: 'put',
        data: imgData,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'users');
}

function getDoctors(data) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStagForUser();
    if (!data) {
        return request({
            baseURL: api,
            url: apiStage + "/users?role=doctor",
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'users');
    } else {
        return request({
            baseURL: api,
            url: apiStage + "/users?role=doctor&account_id=" + (data.accountId ? data.accountId : data.id),
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'users');
    }

}

function setUserPassword(passwordObj) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStagForUser();

    return request({
        baseURL: api,
        url: apiStage + `/user/admin/set_user_password`,
        method: 'put',
        data: passwordObj,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function enableMFAForUser(data, id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getApiStagForUser();

    return request({
        baseURL: api,
        url: apiStage + `/user/${id}`,
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

const accountService = {
    getAllAccountData,
    createAccountAndUser,
    getAccountUserFilters,
    getDoctors,
    createAccountNewUser,
    createOnlineDoctor,
    getOnlineDoctor,
    getUsersList,
    getAccountDataById,
    searchAccountData,

    updateStageUserById,
    getAccountDetailById,

    getUserDetailByAccount,
    updateAccountNewUser,
    setUserPassword,
    enableMFAForUser,
    moveBetaSettingsToProd,
    uploadAccountLogo,

    updateCatalogueData,
    createCatalogueData,
    getCatalogueData,
    searchCatalogue,
    getFilterData,

    // question
    postQuestionsData
}

export default accountService;