import React from "react";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';
import { PrimaryColor } from "../../providerEnum";

export default class Spinner extends React.Component {

    render() {
        let accInfo = sessionStorage.getItem('accountInfo')
        let color = accInfo && accInfo.theme && PrimaryColor[accInfo.theme] || "black";
        return (
            <div style={{ textAlign: "center" }}>
                <Loader
                    type="Oval"
                    color={color}
                    height={this.props.height}
                    width={this.props.width}
                />
            </div>
        );
    }
}