import React from "react";
import ValidationComponent from "./ValidationComponent";
class RadioComponent extends React.PureComponent {

    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue = (event) => {
        let name = event.target.name.split("-")
        this.props.emitTextboxValue({ name: name[2], value: event.target.value, type: 'radio' })
    }

    getUId() {
        var charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        var charSetSize = charSet.length;
        var id = '';
        for (var i = 1; i <= 4; i++) {
            var randPos = Math.floor(Math.random() * charSetSize);
            id += charSet[randPos];
        }
        return id;
    }

    onRadioButtonClicked = (event) => {
        
    }

    checkIfValuePresentInValuesArray(value, valuesArray) {
        if (!value || !valuesArray) {
            return;
        }
        for (let index in valuesArray) {
            if (valuesArray[index].key === value) {
                return true
            }
        }
        return false;
    }

    setErrorStatus = (errorStatus) => {
        this.props.emitErrorStatus(errorStatus);
    }

    checkForLabelValue(str) {
        if (!str || !str.trim()) {
            return false;
        }
        return true
    }

    render() {
        // console.log('-----------------------------------radio---------------------------------');
        const { type, name, classname, value, placeholder, label, labelSize, size, options = [], offset, rowType } = this.props;
        return (
            <>
                {labelSize && <label className={`form-label col-${labelSize}`} style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}>{label}</label>}
                <div className={`col-lg-${size} form-group  mb-0 `} style={{ padding: rowType === "row" ? '' : '', margin: rowType === "row" ? '' : '' }}>
                    {!labelSize && <label className="form-label " style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}>{label}</label>}
                    <div className="d-sm-flex" style={{ flexWrap: 'wrap' }}>
                        {options.map((indOption, index) => {
                            return <div className="form-check mr-3 mt-1 mb-1" key={index} >
                                <label className="form-check-label">
                                    <input type="radio" className="form-check-input" name={`${this.getUId()}-${label}-${name}`} id={name} value={indOption.value || ''} checked={indOption.value === value ? true : false} onClick={this.changeValue.bind(this)} />
                                    {indOption.value}   <i className="input-helper"></i></label>
                            </div>
                        })}
                    </div>
                    {this.props.validation && <ValidationComponent value={value} checkForm={this.props.checkForm} isDirty={this.props.isDirty} validation={this.props.validation} emitErrorStatus={this.setErrorStatus.bind(this)} />}
                </div>
                {offset &&
                    <div className={offset}> </div>
                }
            </>
        );
    }
}

export default RadioComponent;
