import React from "react";
import Checkbox from "../InputComponents/Checkbox";
import Radio from "../InputComponents/Radio";
import DateInput from "../InputComponents/DateInput";
import Select from "../InputComponents/Select";
import Textbox from "../InputComponents/Textbox";
import AutoComplete from "../InputComponents/AutoComplete";
import Multiselect from "../InputComponents/Multiselect";
import AutoSelection from '../InputComponents/AutoSelection';

class Column extends React.Component {

    constructor(props) { super(props); }

    onInputChange = (e) => {
        this.props.onInputChange(e);
    }

    getEmitedSearchFiledData = (name, data) => {
        
        this.props.emitSearchFiledData(name, data)
    }

    getEmitedSelectedAutoCompleteData=(name, data)=>{
        this.props.emitAutoSelectionData(name, data)
    }

    renderElementFromType = (inputData) => {
        
        switch (inputData.type) {
            case 'textbox': // for textbox: For field requiring validation -> send object containing "value" string 
                //and "hasErrors" boolean in dynamicObj to reflect any invalid input
                // For field not requiring any validation, send plain value
                return (<Textbox inputData={inputData} dynamicObj={this.props.dynamicValues[inputData.name]} onInputChange={this.props.onInputChange} emitSearchFiledData={this.getEmitedSearchFiledData.bind(this)}></Textbox>);
            case 'selection':
                return (<Select inputData={inputData} dynamicValue={this.props.dynamicValues[inputData.name]} isRoleDoctor={this.props.isRoleDoctor} onInputChange={this.props.onInputChange} optionData={this.props.uiData[inputData.dataKey]}></Select>);
            case 'date':
                return (<DateInput inputData={inputData} dynamicValue={this.props.dynamicValues[inputData.name]} onInputChange={this.props.onInputChange} />);
            case 'radio':
                return (<Radio inputData={inputData} accountInfo={this.props.accountInfo} dynamicValue={this.props.dynamicValues[inputData.name]} onInputChange={this.props.onInputChange} optionData={this.props.uiData[inputData.dataKey]} />);
            case 'multiselect':
                return (<Multiselect inputData={inputData} dynamicValue={this.props.dynamicValues[inputData.name]} onInputChange={this.props.onInputChange} optionData={this.props.uiData[inputData.dataKey]}></Multiselect>);
            case 'autocomplete':
                return (<AutoComplete inputData={inputData} dynamicValue={this.props.dynamicValues[inputData.name]} onInputChange={this.props.onInputChange} optionData={this.props.uiData[inputData.dataKey]}></AutoComplete>);
            case 'autoselection':
                return (
                    <>
                        {!this.props.isRoleDoctor &&
                            <AutoSelection isMulti={true} label={inputData.name} selectedOption={this.props.dynamicValues[inputData.name]} optionsData={this.props.uiData[inputData.dataKey]} emitSelectedAutoCompleteData={this.getEmitedSelectedAutoCompleteData.bind(this)}></AutoSelection>
                        }
                    </>

                    // <MultiAutocompleteComponent inputData={inputData} dynamicValue={this.props.dynamicValues[inputData.name]} onInputChange={this.props.onInputChange} optionData={this.props.uiData[inputData.dataKey]}></MultiAutocompleteComponent>
                );
            // case 'checkbox':
            //     return (<Checkbox inputData = {inputData} dynamicValue={this.props.dynamicValues[inputData.name]} onInputChange={this.props.onInputChange} optionData ={this.props.uiData[inputData.dataKey]}/>);
            // case 'textarea':
            default:
        }
    }

    render() {
        const { name, label, labelSize, colSize, hidden } = this.props.column ? this.props.column : '';
        let isUserHimselfDoctor = !!(name === 'doctor_id' && this.props.isRoleDoctor); // hide Doctor List selection box in case user Role selected is 'Doctor': ONLY for user Form
        if (hidden || (this.props.dynamicValues[name] && this.props.dynamicValues[name].hidden) ) return null;
        return (<div className="col-md-6">
            <div className="form-group row">
                {label && !isUserHimselfDoctor && <label htmlFor={name} style={{ paddingTop: '5px' }} className={`col-lg-${labelSize} col-form-label`}>{label}
                    {!!this.props.column.isRequired && <span className="text-danger" style={{ fontSize: '20px' }}>*</span>}
                </label>}
                {this.props.column.children ? this.props.column.children.map(
                    (childCol, idx) => <div key={idx} className={`col-lg-${childCol.colSize} ${childCol.className}`}>
                        {this.renderElementFromType(childCol)}
                    </div>)
                    : <div className={`col-lg-${colSize}`}>
                        {this.renderElementFromType(this.props.column)}
                    </div>}
            </div>
        </div>
        )
    }
}

export default Column;