// let showTemplateGenerator = sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole').indexOf("support") !== -1 ? true : false;
const adminNavBarRoutes = [{
    type: 'multi',
    navbarName: "Account",
    key: "Account",
    display: true,
    isActive:false,
    icon: "Account",
    route: `/account-list,/create-account`,
    options: [{
        type: 'single',
        route: `/account-list`,
        navbarName: "View",
        key: "view",
        icon: "view",
        component: 'AccountListContainer'
    }, {
        type: 'single',
        route: `/create-account`,
        navbarName: "Create",
        key: "create",
        icon: "create",
        component: 'AccountCreateContainer'
    }]
}, {
    type: 'single',
    navbarName: "Bulk Upload",
    key: "bulk-upload",
    display: true,
    isActive:false,
    icon: "bulk-upload",
    route: `/bulk-upload`
}, {
    type: 'single',
    navbarName: "Refund",
    key: "refund-status",
    display: true,
    isActive:false,
    icon: "refund-status",
    route: `/refund-status`
}
, {
    type: 'single',
    navbarName: "Payments",
    key: "payment-mgmt",
    display: true,
    isActive:false,
    route: `/payment-mgmt`
}
,
 {
    type: 'single',
    navbarName: "Appointments",
    key: "monthly-appointments",
    display: true,
    isActive:false,
    route: `/monthly-appointments`
}, {
    type: 'single',
    navbarName: "Error Logs",
    key: "error-logs",
    display: true,
    isActive:false,
    route: `/error-logs`
}
, {
    type: 'single',
    navbarName: "Call Logs",
    key: "call-logs",
    display: true,
    isActive:false,
    route: `/call-logs`
}
];


export default adminNavBarRoutes;