import request from '../Api/request';
import userAuth from '../Authentication/authActions';
import { UAParser } from 'ua-parser-js';
import Utils from '../Shared/utils/utils';
// const USE_REAL_API = true;

function getApiStageType() {
    let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1 || window.location.hostname.indexOf('d32uc620y3be9') !== -1 || window.location.hostname.indexOf('v4abbottclinytics') !== -1) ? 'dev' : 'prod';
    // console.log(" hostname "+window.location.hostname+ " stage "+apiStage)
    return apiStage
}

function formatQueryParams(queryString) {
    if (queryString.indexOf('&') !== -1) {
        let paramArray = queryString.split('&');
        return paramArray[1] !== "" ? `${paramArray[0]}&${paramArray[1]}` : `${paramArray[0]}`
    }
    return queryString;
}

function getUserApiStageUrl() {
    let apiStage = sessionStorage.getItem('apiStage');

    if (apiStage === "prod") {
        return `https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        return `https://12vwe151nh.execute-api.ap-south-1.amazonaws.com/`
    }
}

function getAppointmentApiUrl() {
    let apiStage = sessionStorage.getItem('apiStage');
    if (apiStage === "prod") {
        return Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/appointment` :`https://emrapi.hlthclub.in/appointment`;
    } else {
        return `https://betaemrapi.hlthclub.in/appointment`
    }
}

function getRefundApiUrl(env){
    let apiStage = env;
    if (apiStage === "prod") {
        return Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/payments/` :`https://emrapi.hlthclub.in/payments/`;
    } else {
        return `https://betaemrapi.hlthclub.in/payments/`
    }
}

function getUserProfileData() {
    // user/profile
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getUserApiStageUrl();

    return request({
        baseURL: api,
        url: apiStage + "/user/profile",
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function updateProfileData(data) {
    // {{user_url}}/user/profile
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getUserApiStageUrl();

    return request({
        baseURL: api,
        url: apiStage + "/user/profile",
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getUsersList(queryParams, reqParams) {
    let queryString = ""
    if (queryParams && Object.keys(queryParams).length !== 0) {
        queryString = `${queryParams.selectedFilter}=${queryParams.searchText}`;
    }

    let formatedQueryParams = formatQueryParams((queryString).toLowerCase());
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getUserApiStageUrl();

    if (!formatedQueryParams) {
        return request({
            baseURL: api,
            url: apiStage + "/users",
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    } else {
        return request({
            baseURL: api,
            url: apiStage + "/users?" + formatedQueryParams,
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    }

}

function chatlogAppError(args) {
    let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1) ? 'dev' : 'prod';
    let api = getUserApiStageUrl();
    let details = new UAParser().getResult();
    let connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection;
    let data = {
        extra_info: {
            errSource: args.source,
            email: args.patient.email
        },
        error: args.type,
        is_error: args.reType === "success" ? false : true,
        errorInfo: args.info,
        room_id: args.room_id,
        account_id: args.account_id,
        doctor_id: args.doctor.doctor_id,
        doctor_name: args.doctor.doctor_name,
        appointment_date: args.appt.appointment_date,
        appointment_slot: args.appt.appointment_slot,
        online_patientid: args.patient.online_patientid,
        patient_phone: Utils.getMaskedPhone(args.patient.phone),
        info: {
            devices: args.devices,
            ip: args.ip,
            network_type: connection && connection.effectiveType ? connection.effectiveType : '',
            browserInfo: {
                browser: details.browser,
                OS: details.os,
                device: details.device,
                cpu: details.cpu,
                engine: details.engine
            }
        }
    }
    return request({
        baseURL: api,
        url: apiStage + "/error/log",
        method: 'post',
        data: data
    }, 'appointment');

}

function logAppError(args) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getUserApiStageUrl();
    let details = new UAParser().getResult();
    let connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection;
    let data = {
        is_error: true,
        error: 'crash',
        extra_info: {
            errMessage: args.error ? args.error.message : '',
            stack: args.error ? args.error.stack : ''
        },
        account_id: sessionStorage.getItem('s3AccountId'),
        errorInfo: args.errorInfo,
        info: {
            devices: args.devices,
            ip: args.ip,
            network_type: connection && connection.effectiveType ? connection.effectiveType : '',
            browserInfo: {
                browser: details.browser,
                OS: details.os,
                device: details.device,
                cpu: details.cpu,
                engine: details.engine
            }
        }
    }
    return request({
        baseURL: api,
        url: apiStage + "/error/log",
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');

}

function getErrorLogs(data) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getUserApiStageUrl();
    let queryParams = Object.keys(data).filter(key => !!data[key]).map(key => key + '=' + data[key]).join('&');
    return request({
        baseURL: api,
        url: apiStage + `/error/log?` + queryParams,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getCallLogs(data, env) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let apiStage = env;
    let api = getRefundApiUrl(env);
    let queryParams = Object.keys(data).filter(key => !!data[key]).map(key => key + '=' + data[key]).join('&');
    return request({
        baseURL: api,
        url: `onlineconsult/get-room-call-summary?` + queryParams,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getRoomLogs(data) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getAppointmentApiUrl();
    let queryParams = Object.keys(data).filter(key => !!data[key]).map(key => key + '=' + data[key]).join('&');
    return request({
        baseURL: api,
        url: `/appointment/call_logs?` + queryParams,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');

}

function createNewUser(data, account) {

    let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1) ? 'dev' : 'prod';

    let api = '';
    if (apiStage === "prod") {
        api = `https://4o1h69rk3k.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://12vwe151nh.execute-api.ap-south-1.amazonaws.com/`
    }

    if (account) {
        return request({
            baseURL: api,
            url: apiStage + "/user?account_id=" + account,
            method: 'post',
            data: data,
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    } else {
        return request({
            baseURL: api,
            url: apiStage + "/user",
            method: 'post',
            data: data,
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    }


}

function getUserById(id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getUserApiStageUrl();

    return request({
        baseURL: api,
        url: apiStage + "/user/" + id,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getUserAccountList(id,account_id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getUserApiStageUrl();
    let url =apiStage + "/user/projections/" + id + "?projection=account_list"
    if(account_id){
        url+=`&account_id=${account_id}`
    }
    return request({
        baseURL: api,
        url: url,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function deleteUserById(id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getUserApiStageUrl();

    return request({
        baseURL: api,
        url: apiStage + "/user/" + id,
        method: 'delete',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function editExistingUser(data, user) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getUserApiStageUrl();

    return request({
        baseURL: api,
        url: apiStage + "/user/" + user.id,
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function updateStageUser(data) {

    let apiStage = sessionStorage.getItem('apiStage');
    let api = getUserApiStageUrl();

    return request({
        baseURL: api,
        url: apiStage + "/user/" + data.id,
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getDoctors(accountId) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getUserApiStageUrl();
    if (accountId) {
        return request({
            baseURL: api,
            url: apiStage + "/users?role=doctor&account_id=" + accountId,
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'users');
    } else {
        return request({
            baseURL: api,
            url: apiStage + "/users?role=doctor",
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'users');
    }

}

function resetPasswordById(id) {
    return request({
        url: "/user/" + id + "/reset-passsword",
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

const userService = {
    getUsersList,
    createNewUser,
    getUserById,
    getUserAccountList,
    editExistingUser,
    deleteUserById,
    updateStageUser,
    resetPasswordById,
    getDoctors,
    getUserProfileData,
    updateProfileData,
    logAppError,
    getErrorLogs,
    getCallLogs,
    getRoomLogs,
    chatlogAppError
}

export default userService;