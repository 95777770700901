import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Utils from '../../../Shared/utils/utils';
import { Portal } from 'react-overlays';

const CalendarContainer = ({ children }) => {
    const el = document.getElementById('calendar-portal')

    return (
        <Portal container={el}>
            {children}
        </Portal>
    )
}
class PatientInfoComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            patientData: this.props.patientDetail ? this.props.patientDetail : this.props.patient ? this.props.patient : {
                name: '',
                date_of_birth: '',
                year_of_birth: '',
                age: {
                    no_of_years: 0,
                    no_of_months: 0,
                    no_of_days: 0
                },
                gender: ''
            }
        }
    }

    // static getDerivedStateFromProps(props, state) {
    //     if (props.patientDetail !== state.patientData) {
    //         return {
    //             patientData: props.patientDetail ? props.patientDetail : {
    //                 name: '',
    //                 date_of_birth: '',
    //                 year_of_birth: '',
    //                 age: {
    //                     no_of_years: 0,
    //                     no_of_months: 0,
    //                     no_of_days: 0
    //                 },
    //                 gender: ''
    //             }
    //         };
    //     }
    //     return null;
    // }

    handleChange = (event) => {
        const value = event;
        this.setState((prevState) => ({
            patientData: {
                ...prevState.patientData,
                'date_of_birth': value
            }
        }), () => this.findAgeWRTDateOfBirth())
    }

    onDataChangeEmitData = () => {
        this.props.emitPatientData(this.state.patientData);
    }

    handleAgeChangeEvent = (event) => {
        const { value, name } = event.target;
        if (value.length > 3) {
            return;
        }
        this.setState(prevState => ({
            patientData: {
                ...prevState.patientData,
                age: {
                    ...prevState.patientData.age,
                    'no_of_years': value
                }
            }
        }), () => this.onDataChangeEmitData())
    }

    findAgeWRTDateOfBirth() {
        if (!this.state.patientData['date_of_birth']) {
            return;
        }
        const ageValue = Utils.getAge(this.state.patientData['date_of_birth']);
        this.setState((prevState) => ({
            patientData: {
                ...prevState.patientData,
                age: {
                    ...prevState.patientData.age,
                    'no_of_years': ageValue
                }
            }
        }), () => this.onDataChangeEmitData())
    }

    onFullNameChangeView = (event) => {
        const { name, value } = event.target;
        if (value.length > 30) {
            return;
        }
        this.setState((prevState) => ({
            patientData: {
                ...prevState.patientData,
                [name]: value
            }
        }), () => this.onDataChangeEmitData())
    }

    onGenderChanged = name => (e) => {
        const value = name;
        this.setState((prevState) => ({
            patientData: {
                ...prevState.patientData,
                'gender': value
            }
        }), () => this.onDataChangeEmitData())
    }

    render() {
        const { patientData } = this.state;
        console.log(patientData);
        return (
            <>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className=" col-lg-3 col-form-label">Full Name<span className="text-danger" style={{ fontSize: 20 }}>*</span></label>
                            <div className="col-lg-8">
                                <input type="text" name='name' placeholder="Enter Full Name" className="form-control" value={this.state.patientData.name} onChange={this.onFullNameChangeView.bind(this)} />

                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Gender </label>
                            <div className="col-md-3">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="membershipmale" id="membershipmale" value="" checked={this.state.patientData.gender === "male"}
                                            onChange={this.onGenderChanged('male')} />
                                        Male
                                                                <i className="input-helper"></i></label>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="membershipfemale" id="membershipfemale"
                                            checked={this.state.patientData.gender === "female"} value="" onChange={this.onGenderChanged('female')} />
                                        Female
                                                                    <i className="input-helper"></i></label>
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="form-check">
                                    <label className="form-check-label">
                                        <input type="radio" className="form-check-input" name="membershipfemale" id="membershipfemale"
                                            checked={this.state.patientData.gender === "others"} onChange={this.onGenderChanged('others')} />
                                        Others
                                                                <i className="input-helper"></i>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className="col-lg-3 col-form-label">Date of Birth</label>
                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-8">
                                        <DatePicker
                                            style={{ width: '100%' }}
                                            className="form-control"
                                            maxDate={new Date()}
                                            peekNextMonth
                                            showMonthDropdown
                                            popperContainer={CalendarContainer}
                                            showYearDropdown
                                            dropdownMode="select"
                                            dateFormat="dd MMMM, yy"
                                            selected={(this.state.patientData && this.state.patientData.date_of_birth && Utils.isValidDate(this.state.patientData.date_of_birth) )? new Date(this.state.patientData.date_of_birth) : ''}
                                            // new Date()
                                            onChange={this.handleChange.bind(this)}
                                        />
                                    </div>
                                    <div className="col-4"> <input className="form-control" placeholder="Age"
                                        name="age" value={this.state.patientData['age'] && this.state.patientData['age']['no_of_years'] ? this.state.patientData['age']['no_of_years'] : ''} onChange={this.handleAgeChangeEvent.bind(this)} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </>
        )
            ;
    }
}

export default PatientInfoComponent;
