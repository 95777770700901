import React from "react";
import ValidationComponent from "./ValidationComponent";
class SelectComponent extends React.Component {

    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
    }

    changeValue = (event) => {
        event.preventDefault();
        this.props.emitTextboxValue({ name: event.target.name, value: event.target.value, type: 'selection' })
    }

    setErrorStatus = (errorStatus) => {
        this.props.emitErrorStatus(errorStatus);
    }

    checkForLabelValue(str) {
        if (!str || !str.trim()) {
            return false;
        }
        return true
    }

    render() {
        // console.log('-----------------------------------select---------------------------------');
        const { type, name, classname, placeholder, value, label, labelSize, size, options, offset, rowType, showType } = this.props;
        return (
            <React.Fragment>
                {labelSize && <label className={`col-${labelSize}`} style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}>
                            {label}</label>}
                <div className={`col-lg-${size} ${showType === "table" ? 'p-0' : ''}`} style={{ padding: rowType === "row" ? ' ' : '', margin: rowType === "row" ? ' ' : '' }}>
                        {!labelSize && <label style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}>
                            {label}</label>}
                        <select
                            className="form-control"
                            name={name}
                            value={value}
                            placeholder={placeholder}
                            onChange={this.changeValue}>
                            <option value="">{placeholder}</option>
                            {options &&
                                <React.Fragment>
                                    {options.map((indOption, index) =>
                                        <option key={index} value={indOption.value}>{indOption.value}</option>
                                    )
                                    }
                                </React.Fragment>
                            }
                        </select>
                        {this.props.validation && <ValidationComponent value={value === placeholder ? "" : value} checkForm={this.props.checkForm} isDirty={this.props.isDirty} validation={this.props.validation} emitErrorStatus={this.setErrorStatus.bind(this)} />}
                </div>
                {offset &&
                    <div className={offset}> </div>
                }
            </React.Fragment>
        );
    }
}

export default SelectComponent;
