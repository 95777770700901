import React from "react";
import prescriptionService from '../../../Services/prescription.service';
import { Storage } from 'aws-amplify';
import Spinner from "../../../Common/Spinner/Spinner";
import { S3UPLOAD_KEY } from '../../../Constants/s3Key';
import SweetAlertComponent from '../../../Common/Alert/SweetAlertComponent';



class LinkModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            textCopied: ''
        }
    }

    onEditAppointment = (event) => {
        // this.props.emitEditAppointmentEvent('/edit-appointment');
    }

    onCallReconnectEvent = (event) => {
        // this.props.emitOnCallReconnectEvent(this.props.appointmentData);
    }

    copyToClipboard = (event) => {
        let text = ''
        let apiStage = sessionStorage.getItem('apiStage');
        let account_id = sessionStorage.getItem('s3AccountId');
        if (event === 'payment') {
            text = (apiStage === 'dev') ? `https://betapay.hlthclub.in/pay/${account_id}/${this.props.appointmentData['payment_order_id']}` : `https://pay.hlthclub.in/pay/${account_id}/${this.props.appointmentData['payment_order_id']}`
        } else {
            text = (apiStage === 'dev') ? `https://betabreathefree.hlthclub.in/${account_id}/waiting-area/${this.props.appointmentData['room_id']}` : `https://breathefreeeducator.com/${account_id}/waiting-area/${this.props.appointmentData['room_id']}`
        }
        var input = document.createElement("textarea");
        text = text.replace(/['"]+/g, '')
        input.innerHTML = text;
        document.body.appendChild(input);
        input.select();
        var result = document.execCommand('copy');
        document.body.removeChild(input);
        this.setState({ textCopied: true });
        setTimeout(() => {
            this.setState({ textCopied: false })
        }, 3000);
    };

    checkVisibilityForCopy() {
        if (this.props.appointmentData) {
            if (this.props.appointmentData['room_id'] && this.props.appointmentData['payment_order_id']) {
                if (this.props.appointmentData['invoice_status'] === "done" || this.props.appointmentData['invoice_status'] === "Done") {
                    return 'video';
                } else {
                    return 'payment';
                }
            } else if (this.props.appointmentData['room_id'] && !this.props.appointmentData['payment_order_id']) {
                return 'video';
            } else if (!this.props.appointmentData['room_id'] && this.props.appointmentData['payment_order_id']) {
                return 'payment';
            }
        }
    }

    render() {
        const { uploadedFiles, isLoading } = this.state;
        let isInValid = uploadedFiles && uploadedFiles.length === 0;
        let viewType = this.checkVisibilityForCopy();
        // this.props.appointmentData && this.props.appointmentData['room_id'] ? 'video' : (this.props.appointmentData && this.props.appointmentData['payment_order_id']) ? 'payment' : 'none';
        // invoiceStatus done:
        return (
            <>
                <div className="row statusbox">
                    {viewType === "video" ? (
                        <div className="col-md-12 stretch-card mb-2">
                            <div className="card bg-gradient-danger card-img-holder text-white">
                                <div className="card-body" style={{ display: 'flex', flex: 1, alignItems: 'flex-end', textAlign: 'center', cursor: 'pointer' }} onClick={this.copyToClipboard.bind(this, 'tele')}>
                                    <img src="images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
                                    <h5 className="font-weight-normal" style={{ width: '100%' }}> <i className="mdi mdi-message-video "></i> Video Room</h5>
                                </div>
                            </div>
                        </div>
                    ) : (
                            <>
                                {viewType === "payment" ? (
                                    <div className="col-md-12 stretch-card mb-2">
                                        {/* onClick={()=> window.open("someLink", "_blank")} */}
                                        <div className="card bg-gradient-success card-img-holder text-white">
                                            <div className="card-body" style={{ display: 'flex', flex: 1, alignItems: 'flex-end', textAlign: 'center', cursor: 'pointer' }} onClick={this.copyToClipboard.bind(this, 'payment')}>
                                                <img src="images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
                                                <h5 className="font-weight-normal" style={{ width: '100%' }}><i className="mdi mdi-link-variant"></i>Payment Link </h5>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                        <span></span>
                                    )}
                            </>
                        )}

                    {/* {this.props.appointmentData && this.props.appointmentData['room_id'] &&
                        <div className="col-md-12 stretch-card mb-2">
                            <div className="card bg-gradient-danger card-img-holder text-white">
                                <div className="card-body" style={{ display: 'flex', flex: 1, alignItems: 'flex-end', textAlign: 'center', cursor: 'pointer' }} onClick={this.copyToClipboard.bind(this, 'tele')}>
                                    <img src="images/dashboard/circle.svg" className="card-img-absolute" alt="circle-image" />
                                    <h5 className="font-weight-normal" style={{ width: '100%' }}> <i className="mdi mdi-message-video "></i> Video Room</h5>
                                </div>
                            </div>
                        </div>
                    } */}
                    <div className="col-md-6 stretch-card">
                        {this.state.textCopied &&
                            <p style={{ color: 'green', textAlign: 'center' }}>Copied!!!</p>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default LinkModalComponent;


