let showAnonymous = sessionStorage.getItem('userRole') && (sessionStorage.getItem('userRole').indexOf("admin") !== -1 || sessionStorage.getItem('userRole').indexOf("doctor") !== -1) && sessionStorage.getItem('s3AccountId') !== "breathefree" ? true : false;
let showInsight = sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole').indexOf("f:ocr") !== -1 ? true : false;
let showTemplateGenerator = sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole').indexOf("f:tempbuilder") !== -1 ? true : false;
let showPatientQueries = sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole').indexOf("doctor") !== -1  ? true : false;
// let showSupportRoutes = sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole').indexOf("support") !== -1 ? true : false;
let showInvoiceReport = sessionStorage.getItem('userRole') && ['admin', 'doctor', 'support', 'substaff'].some(role => sessionStorage.getItem('userRole').indexOf(role) !== -1) ? true : false;

let showAppointmentSummary = sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole').indexOf("r:admin") !== -1 ? true : false;

let showSmsCreditDetail = sessionStorage.getItem('userRole') &&( sessionStorage.getItem('userRole').indexOf("r:admin") !== -1|| sessionStorage.getItem('userRole').indexOf("r:doctor") !== -1)&& sessionStorage.getItem('userRole').indexOf("f:smsvolet") !== -1? true : false;
//console.log("showSmsCreditDetail",showSmsCreditDetail)

const emrRoutes = [{
        type: 'single',
        navbarName: "Users",
        key: "Users",
        display: false,
        isActive: false,
        route: `/user-view`,
        icon: "users"
    }, {
        type: 'single',
        navbarName: "Appointments",
        key: "Appointments",
        icon: "appointments",
        display: false,
        isActive: false,
        route: `/appointments-view`,

    },
    {
        type: 'single',
        navbarName: "Appointment Summary",
        key: "appointment-summary",
        icon: "appointment-summary",
        display: showAppointmentSummary,
        isActive: false,
        route: `/app-summary`,

    },
    {
        type: 'single',
        navbarName: "Patients",
        key: "Patients",
        icon: "patients",
        display: false,
        route: `/patient-view`,
        isActive: false,

    }, {
        type: 'single',
        route: `/sms`,
        isActive: false,
        navbarName: "SMS Status",
        key: "SMS",
        icon: "sms",
        component: 'SmsStatusContainer'
    }, {
        type: 'single',
        route: `/invoice-report`,
        isActive: false,
        display: showInvoiceReport,
        navbarName: "Invoice Report",
        key: "invoice-report",
        icon: "invoice-report",
        component: 'SmsStatusContainer'
    }, {
        type: 'single',
        route: `/patient-anonymous`,
        isActive: false,
        display: showAnonymous,
        navbarName: "Anonymous",
        key: "anonymous",
        icon: "anonymous",
        component: 'PrescriptionDetailContainer'
    }, {
        type: 'multi',
        navbarName: "Insights",
        key: "insights",
        display: true,
        isActive: false,
        icon: "insights",
        route: `/brief-insight,/doctor-wise-report`,
        options: [{
            type: 'single',
            route: `/brief-insight`,
            navbarName: "Brief Insight",
            key: "briefInsight",
            icon: "briefInsight",
        }, {
            type: 'single',
            route: `/doctor-wise-report`,
            navbarName: "Doctorwise Report",
            key: "docWiseReport",
            icon: "docWiseReport"
        }]
    }, {
        type: 'single',
        route: `/brief-insight`,
        navbarName: "Brief Insight",
        key: "docInsight",
        icon: "docInsight",
        display: false
    }, {
        type: 'single',
        route: `/admin-form-builder`,
        isActive: false,
        display: showTemplateGenerator,
        navbarName: "Template Builder",
        key: "admin-form-builder",
        icon: "admin-form-builder",
        component: 'FormBuilderComponent'
    }, {
        type: 'single',
        route: `/patient-queries`,
        isActive: false,
        display: showPatientQueries,
        navbarName: "Chat Inbox",
        key: "patient-queries",
        icon: "patient-queries",
        component: 'DoctorMultiChatContainer'
    }, {
        type: 'single',
        navbarName: "Users",
        key: "Users-Doc",
        display: false,
        isActive: false,
        route: `/user-view`,
        icon: "users"
    },
    {
        type: 'single',
        route: `/sms-credit-detail`,
        navbarName: "SMS Credit",
        display: showSmsCreditDetail,
        key: "sms-credit-detail",
        icon: "sms-credit-detail"
    }, {
        type: 'multi',
        navbarName: "Transactions",
        key: "transactions",
        display: false,
        isActive: false,
        icon: "transactions",
        route: `/refund-status,/payment-status`,
        options: [{
            type: 'single',
            route: `/refund-status`,
            navbarName: "Refund",
            key: "refund-status",
            icon: "refund-status"
        }, {
            type: 'single',
            route: `/payment-status`,
            navbarName: "Payment",
            key: "payment-status",
            icon: "payment-status"
        }, {
            type: 'single',
            route: `/enquiry`,
            navbarName: "Enquiry",
            key: "enquiry",
            icon: "enquiry"
        }]
    },
    {
        type: 'single',
        navbarName: "Users",
        key: "UsersMgmt",
        display: false,
        isActive: false,
        route: `/user-view`,
        icon: "users"
    }
];


export default emrRoutes;