import React from "react";
import Spinner from '../../../../Common/Spinner/Spinner';

class SaveRxComponent extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            groupName: ''
        }
    }

    onTextValueChange = (event) => {
        let { name, value } = event.target;
        this.setState({
            [name]: value
        })
    }

    onSave = () => {
        this.props.emitSaveRxData(this.state.groupName);
    }

    render() {
        return (
            <>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group ">
                            <label>Rx-Group Name*</label>
                            <input className="form-control" name="groupName" placeholder="Enter Rx-Group Name" value={this.state.groupName} onChange={this.onTextValueChange.bind(this)} />
                        </div>
                    </div>
                    <div className="col-12">

                        <button type="submit" className="btn btn-success ml-2 btn-rounded" disabled={!this.state.groupName} onClick={this.onSave.bind(this)}>Submit</button>
                        {this.props.isRxLoading && <div style={{ display: 'inline-block', 'marginLeft': '30px' }}><Spinner width={50} height={50}></Spinner></div>}
                    </div>
                </div>
            </>
        );
    }
}

export default SaveRxComponent;
