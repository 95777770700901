const dataAdminRoutes = [{


    type: 'single',
    route: `/qc-users-list`,
    display: true,
    isActive: false,
    navbarName: "Qc Users",
    key: "Users",
    icon: "Users",


    // type: 'multi',
    // navbarName: "Qc Users",
    // key: "Users",
    // display: true,
    // isActive: false,
    // icon: "users",
    // route: `/qc-users-list,/qc-create-user`,
    // options: [{
    //     type: 'single',
    //     route: `/qc-users-list`,
    //     navbarName: "View",
    //     key: "view",
    //     icon: "view",
    //     component: 'DataEntryListViewContainer'
    // }, {
    //     type: 'single',
    //     route: `/qc-create-user`,
    //     navbarName: "Create",
    //     key: "create",
    //     icon: "create",
    //     component: 'DataEntryCreateUserContainer'
    // }]
},
{
    type: 'single',
    route: `/qc-analyst`,
    display: true,
    isActive: false,
    navbarName: "Prescriptions",
    key: "prescription",
    icon: "prescription",
},
{
    type: 'single',
    route: `/rx-analysis`,
    display: true,
    isActive: false,
    navbarName: "Rx Analysis",
    key: "rxanalysis",
    icon: "rxanalysis",
},
// rx-analysis
{
    type: 'single',
    route: `/create-admin-doctor`,
    display: true,
    isActive: false,
    navbarName: "Create Doctor",
    key: "doctorlist",
    icon: "doctorlist",
}
];


export default dataAdminRoutes;