import React from 'react';
import Card from 'react-bootstrap/Card';
import ReactTooltip from 'react-tooltip';

import ID from '../../Utils/UUID';
import Column from './Column';
// const $ = window["jQuery"];
const NameOptions = ['name', 'value', 'drug', 'drugType', 'frequency', 'secondValue',
    'dosage', 'route', 'childName', 'date', 'duration', 'comment', 'cycle', 'molecule', 'brand', 'unit', 'inference', "enddate"];
class ChildRow extends React.Component {
    constructor(props) {
        super(props);
        // autoFill colIdArray (i.e., variable suggestions for creating formula)
        let colIdArray = [];
        if (this.props.row.type === 'row') {
            this.props.row.columns.forEach(col => {
                if (col.colId && col.class !== 'displayparent') colIdArray.push(col.colId);
            });
        }
        let nameSet = new Set();
        this.props.row.columns.forEach(col => {
            nameSet.add(col.name);
        });
        nameSet = [...nameSet];
        this.state = {
            expanded: true,
            isModalOpen: false,
            requiredError: false,
            currentChildRowId: '',
            availableNameArr: NameOptions.filter(o => nameSet.indexOf(o) === -1 || o == "childName"),
            colIdArray: colIdArray // used for creating formulas
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props && this.props.row && this.props.row.columns) {
            let colIdArray = [];
            this.props.row.columns.forEach(col => {
                if (col.colId && col.class !== 'displayparent') colIdArray.push(col.colId);
            });
            let nameSet = new Set();
            this.props.row.columns.forEach(col => {
                nameSet.add(col.name);
            });
            nameSet = [...nameSet];
            let availableNameArr = NameOptions.filter(o => (nameSet.indexOf(o) === -1 || o == "childName"));

            this.setState({ colIdArray, availableNameArr });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps !== this.props) {
            // either serial No change
            // or currentRowId should be same
            // for render() to run
            if (nextProps.row.config !== this.props.row.config) {
                return true;
            }
            if (this.props.isChildRow) {
                if (!nextProps.currentChildRowId) return true;
                if (nextProps.currentChildRowId === nextProps.row['rowId']
                    || nextProps.serialNo !== this.props.serialNo
                    || nextProps.showDelete !== this.props.showDelete
                    || nextProps.showEdit !== this.props.showEdit) { return true; }
                else { return false; }
            } else {
                if (!nextProps.currentRowId) return true;
                if (nextProps.currentRowId === nextProps.row['rowId'] || nextProps.serialNo !== this.props.serialNo) { return true; }
                else { return false; }
            }
        } else return true; // implies that internal state was changed
    }

    onRowAddition = (e) => {
        let meta = e.target ? {} : e; // meta = meta in case of childRow returning to parent row and meta={childRowId: 'xyz'}
        this.props.onRowAddition(this.prepareAddDelMetaData(meta));
    }

    onRowDeletion = (e) => {
        let meta = e.target ? {} : e;
        if (meta.childRowId) {
            this.setState({ currentChildRowId: meta.childRowId }, () => this.props.onRowDeletion(this.prepareAddDelMetaData(meta)));
        } else {
            this.props.onRowDeletion(this.prepareAddDelMetaData(meta));
        }
    }

    prepareAddDelMetaData(meta) {
        if (this.props.isChildRow) {
            meta = {
                ...meta,
                childRowId: this.props.row['rowId']
            };
        } else {
            meta = {
                ...meta,
                rowId: this.props.row['rowId']
            }
        }
        return meta;
    }


    // now only being used for cloning here
    onRowMetaChange = (e) => {
        let { name, value, childRowId } = e.target ? e.target : e;
        if (name === 'source' || name === 'displayconditionValues') {
            if (name === 'displayconditionValues' && value && value.split(',').length) this.emitAppropriateMetaToParent({ name: 'class', value: 'deferRendering hide', childRowId });
            if (name === 'displayconditionValues' && (!value || !value.trim())) this.emitAppropriateMetaToParent({ name: 'class', value: '', childRowId });

            value = this.getShowHideConfig(name === 'source' ? name : 'value', value === 'none' ? '' : value);
            name = 'config';
        }

        this.emitAppropriateMetaToParent({ name, value, childRowId });
    }

    emitAppropriateMetaToParent(e) {
        let { name, value, childRowId } = e;
        if (childRowId) this.setState({ currentChildRowId: childRowId });
        let meta = {};
        if (this.props.isChildRow) {
            // change was made in child row properties and this is child row
            meta = { ...meta, childRowId: this.props.row['rowId'], name, value };
        } else if (childRowId) {
            // change was made in child row properties and this is parent row
            let children = this.props.row['children'];
            children.forEach((childRow, idx) => {
                if (childRowId === childRow['rowId']) {
                    children[idx] = {
                        ...childRow,
                        [name]: value
                    }
                    return;
                }
            });
            meta = { rowId: this.props.row['rowId'], name: 'children', value: children };
        } else {
            // change was made in parent row properties
            meta = { ...meta, rowId: this.props.row['rowId'], name, value };
        }
        this.props.onRowMetaEmit(meta);
    }

    onColAddition = (colIdx) => {
        let { columns } = this.props.row;
        columns.splice(colIdx + 1, 0, {});
        this.emitAppropriateMetaToParent({ name: 'columns', value: columns });
    };

    onColDeletion = (colIdx) => {
        let { columns } = this.props.row;
        columns.splice(colIdx, 1);
        this.emitAppropriateMetaToParent({ name: 'columns', value: columns });
    };

    toggleExpandRow = () => {
        this.setState({ expanded: !this.state.expanded });
    }

    onCloneRowClick = () => {
        this.props.onCloneRow(JSON.parse(JSON.stringify(this.props.row)));
    }

    onEditRowClick = () => {
        // isChildRow
        let { serialNo, row } = this.props;
        // $('.subrow-box').addClass('highlight');
        this.props.onEditRowClick({ row: JSON.parse(JSON.stringify(row)), 'childIndex': serialNo - 1 });
    }

    onEditColumnClick = (e) => {
        this.props.onEditColumnClick({
            ...e, 'childIndex': this.props.serialNo - 1, colIdArray: this.state.colIdArray,
            availableNameArr: e && e.column && e.column.name ? this.state.availableNameArr.concat([e.column.name]) : this.state.availableNameArr
        });
    }

    onChildRowClone = (idx, childRow) => {
        let { children } = this.props.row;
        childRow['rowId'] = ID.uuid();
        children.splice(idx + 1, 0, childRow);
        this.props.onRowMetaEmit({ rowId: this.props.row['rowId'], name: 'children', value: children });
    }

    render() {
        // can be row | group | childRow | subSection
        let { row, serialNo, isChildRow, showDelete, showEdit } = this.props;
        // const element = isChildRow ? 'childrow' : row['type'];
        const { expanded } = this.state;
        return (
            <Card>
                <div className="row subrow-box" style={{ paddingLeft: '20px' }}>
                    <div className="col-md-2 childrow-header pointer-cursor" onClick={this.onEditRowClick.bind(this)}>
                        <h6 className="drag"><i className="mdi mdi-drag-vertical"></i>{' '}Subrow #{serialNo}</h6>
                        <div className="d-flex">
                            {showEdit && <a className="actions" data-tip data-for='editRow'><i className="mdi mdi-pencil-box-outline"></i></a>}
                            <a className="actions" onClick={this.onCloneRowClick.bind(this)} data-tip data-for='cloneRow'><i className="mdi mdi-content-copy"></i></a>
                            <a className="actions" onClick={this.onRowAddition.bind(this)} data-tip data-for="addRow"><i className="mdi mdi-plus-circle"></i></a>
                            {showDelete && <a className="actions" onClick={this.onRowDeletion.bind(this)} data-tip data-for="deleteRow"><i className="mdi mdi-minus-circle"></i></a>}
                        </div>
                    </div>
                    <ReactTooltip id='editRow' effect='solid'>
                        <span>Edit Row</span>
                    </ReactTooltip>
                    <ReactTooltip id='cloneRow' effect='solid'>
                        <span>Clone Row</span>
                    </ReactTooltip>
                    <ReactTooltip id='addRow' effect='solid'>
                        <span>Add Row</span>
                    </ReactTooltip>
                    <ReactTooltip id='toggleRow' effect='solid'>
                        <span>{expanded ? 'Collapse' : 'Expand'} Row</span>
                    </ReactTooltip>
                    <ReactTooltip id='deleteRow' effect='solid'>
                        <span>Delete Row</span>
                    </ReactTooltip>
                    <div className="col-md-10 childrow-body">
                        {(this.props.row['type'] === 'row' || this.props.row['type'] === 'compactRow') && this.props.row['columns'].length > 0 && <>
                            {this.props.row['columns'].map((col, idx) => <React.Fragment key={idx}>
                                <Column
                                    key={idx}
                                    col={col}
                                    showDelete={this.props.row['columns'].length > 1}
                                    sectionOffset={this.props.sectionOffset}
                                    colIndex={idx}
                                    onColAddition={this.onColAddition.bind(this)}
                                    onColDeletion={this.onColDeletion.bind(this)}
                                    uiDataDict={this.props.uiDataDict}
                                    onEditColumnClick={this.onEditColumnClick.bind(this)}
                                    onModalOpen={() => this.setState({ isModalOpen: true })} /></React.Fragment>
                            )}
                        </>}
                    </div>
                </div>
            </Card>
        );
    }
}

export default ChildRow;