import React, { useContext, useState, useEffect } from "react";
import NfnContext from "../../Layout/Main/NfnContext";

class NotificationComponent extends React.Component {

    render() {
        return <NfnContext.Consumer>
            {
                (nfn) => {
                    let formattedNfn = nfn.map(x => {
                        let body = typeof x === 'object' ? x : JSON.parse(x);
                        return { message: body.message };
                    });
                    return (
                        <ul className="navbar-nav navbar-nav-right">
                            <li className="nav-item dropdown">
                                <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-toggle="dropdown">
                                    <i className="mdi mdi-bell-outline"></i>
                                    {formattedNfn && formattedNfn.length >= 1 && <span className="count-symbol bg-danger"></span>}
                                </a>
                                <div className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list" aria-labelledby="notificationDropdown">
                                    <h6 className="ml-3">Notifications</h6>
                                    {!formattedNfn || formattedNfn.length < 1 ? <>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item preview-item">
                                            <div className="preview-thumbnail">
                                                <div className="preview-icon bg-success">
                                                    <i className="mdi mdi-check"></i>
                                                </div>
                                            </div>
                                            <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                                                <h6 className="preview-subject font-weight-normal mb-1">All caught up!</h6>
                                            </div>
                                        </a></>
                                        :
                                        formattedNfn.map((n, idx) => <span key={idx}>
                                            <div className="dropdown-divider"></div>
                                            <a className="dropdown-item preview-item">
                                                <div className="preview-thumbnail">
                                                    <div className="preview-icon bg-success">
                                                        <i className="mdi mdi-calendar"></i>
                                                    </div>
                                                </div>
                                                <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                                                    {/* <h6 className="preview-subject font-weight-normal mb-1">Event today</h6> */}
                                                    <label className="text-gray ellipsis mb-0">
                                                        {n.message}
                                                    </label>
                                                </div>
                                            </a>
                                        </span>
                                        )}
                                    {/* <div className="dropdown-divider"></div>
                    <a className="dropdown-item preview-item">
                        <div className="preview-thumbnail">
                            <div className="preview-icon bg-warning">
                                <i className="mdi mdi-settings"></i>
                            </div>
                        </div>
                        <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                            <h6 className="preview-subject font-weight-normal mb-1">Settings</h6>
                            <p className="text-gray ellipsis mb-0">
                                Update dashboard
              </p>
                        </div>
                    </a>
                    <div className="dropdown-divider"></div>
                    <a className="dropdown-item preview-item">
                        <div className="preview-thumbnail">
                            <div className="preview-icon bg-info">
                                <i className="mdi mdi-link-variant"></i>
                            </div>
                        </div>
                        <div className="preview-item-content d-flex align-items-start flex-column justify-content-center">
                            <h6 className="preview-subject font-weight-normal mb-1">Launch Admin</h6>
                            <p className="text-gray ellipsis mb-0">
                                New admin wow!
              </p>
                        </div>
                    </a>
                    <div className="dropdown-divider"></div>
                    <h6 className="p-3 mb-0 text-center">See all notifications</h6> */}
                                </div>
                            </li>
                        </ul>
                    )

                }
            }
        </NfnContext.Consumer >
    }
}

export default NotificationComponent;