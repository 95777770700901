import React from "react";
import Select from 'react-select';

// data contains text and 


class AutoSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: this.props.selectedOption || null,
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.selectedOption !== nextProps.selectedOption) {
            this.setState({
                selectedOption: nextProps.selectedOption
            })
        }
    }

    handleChange = selectedOption => {
        this.setState({ selectedOption },
            () => this.props.emitSelectedAutoCompleteData(this.props.label, this.state.selectedOption)
        );
    };

    getInputChangeData = (event) => {
       
        if (this.props.emitInputChange) {
            this.props.emitInputChange(event);
        }
    }

    render() {
        const { selectedOption } = this.state;
        const { optionsData, isMulti, closeMenuOnSelect } = this.props;
        // emitSelectedAutoCompleteData
        return (
            <>
                <Select
                    placeholder={this.props.placeholder ? this.props.placeholder : 'Select...'}
                    isMulti={isMulti ? true : false}
                    value={selectedOption}
                    closeMenuOnSelect={closeMenuOnSelect ? true : false}
                    onChange={this.handleChange}
                    onInputChange={this.getInputChangeData.bind(this)}
                    options={optionsData}
                />
            </>
        );
    }
}

export default AutoSelection;

