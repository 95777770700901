import React from "react";
import BrandViewComponent from './BrandView/BrandViewComponent';
import RouteViewComponent from './RouteView/RouteViewComponent';
import MobileRouteViewComponent from './RouteView/MobileRouteViewComponent';
import logoutService from '../../Services/logout.service';
import onlineConsultationService from '../../Services/onlineConsultation.service';
import emrRoutes from './RoutesJson';
import adminRoutesJson from './adminRoutesJson';
import dataAdminRoutes from './dataAdminRoutes';
import supportRoutesJson from './supportRoutesJson';
import prescriptionRouteJson from './PrescriptionRouteJson';
import { withSnackbar } from "notistack";



class HeaderContainer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: {},
            emrNavBarRoutes: [],
            openChatCount: null,
            navType: this.props.type,
            version: sessionStorage.getItem('appVersion'),
            mobileNav: false,
            currentRoute: this.props.location && this.props.location.pathname ? this.props.location.pathname : ''
        }

    }

    componentWillMount() {
        // hardRefreshForOldVersions();

        
    }

    componentDidMount() {
        this.filterNavBarRoutesView()
        
    }

    shouldComponentUpdate(nextProps, nextState) {
        // if (this.state.emrNavBarRoutes !== nextState.emrNavBarRoutes) {
        return true
        // }
        // return false;
    }

    getDataRoutes() {
        let currentRoute = this.props.location && this.props.location.pathname ? this.props.location.pathname : ''
        let navRoutes = JSON.parse(JSON.stringify(dataAdminRoutes));
        navRoutes.forEach(element => {
            if (!sessionStorage.getItem("dataAdmin") || sessionStorage.getItem("dataAdmin") === "") {
                if (element.key === "Users") {
                    element.display = false
                }
                if (element.key === "doctorlist") {
                    element.display = false
                }
            }
            if (element.route.indexOf(currentRoute) !== -1) {
                element.isActive = true;
            } else {
                element.isActive = false;
            }
        });
        return navRoutes;
    }

    async getOpenChats() {
        let { openChatCount } = this.state;
        try {
            let data = {
                "account_id": sessionStorage.getItem('s3AccountId'),
                "doctor_id": sessionStorage.getItem('profileInfo') ? JSON.parse(sessionStorage.getItem('profileInfo')).cognito_user_sub : null
            };
            let res = await onlineConsultationService.getOpenDoctorChats(data);
            if (res && res.type === 'success' && res.data) {
                openChatCount = res.data.chatHistory ? res.data.chatHistory.length : null;
            }
        } catch (err) {
        }
        this.setState({ openChatCount });
    }


    filterNavBarRoutesView = () => {
        let { emrNavBarRoutes } = this.state;
        let emrNavBarRoute = this.state.navType ? prescriptionRouteJson : (sessionStorage.getItem('dataUser') && sessionStorage.getItem('dataUser') !== "") ? this.getDataRoutes() : sessionStorage.getItem('isSuperUser') ? adminRoutesJson : sessionStorage.getItem('isSupport') ? supportRoutesJson : this.filterAppRoutes();
        emrNavBarRoutes = JSON.parse(JSON.stringify(emrNavBarRoute));
        this.setState({ emrNavBarRoutes });
    }

    filterAppRoutes() {
        let currentRoute = this.props.location && this.props.location.pathname ? this.props.location.pathname : '';
        let newRoutes = emrRoutes;
        let userRole = sessionStorage.getItem('userRole');
        let provider = sessionStorage.getItem('provider');
        for (let i = 0; i < newRoutes.length; i++) {
            if (newRoutes[i].key === "Users") {
                if (sessionStorage.getItem('showUser') !== "" && userRole.indexOf('doctor') === -1) {
                    newRoutes[i].display = true;
                } else {
                    newRoutes[i].display = false;
                }
            } else if (newRoutes[i].key === "Users-Doc") {
                if (sessionStorage.getItem('showUser') !== "" && userRole.indexOf('doctor') !== -1) {
                    newRoutes[i].display = true;
                } else {
                    newRoutes[i].display = false;
                }
            } else if (newRoutes[i].key === "SMS") {
                newRoutes[i].display = userRole.indexOf("f:sms") !== -1;
            } else if (newRoutes[i].key === "sms-credit-detail") {
                newRoutes[i].display = sessionStorage.getItem('userRole') && (sessionStorage.getItem('userRole').indexOf("r:admin") !== -1 || sessionStorage.getItem('userRole').indexOf("r:doctor") !== -1) && sessionStorage.getItem('userRole').indexOf("f:smsvolet") !== -1 ? true : false;
                //console.log("sms-credit-detail",newRoutes[i].display )
            }else if (newRoutes[i].key === 'insights') {
                newRoutes[i].display = userRole.includes('f:insights') && userRole.indexOf("admin") === -1 && userRole.indexOf("doctor") === -1;
            } else if (newRoutes[i].key === "appointment-summary") {
                newRoutes[i].display = userRole && (userRole.indexOf("r:admin") !== -1) ? true : false;
            } 
            else if (newRoutes[i].key === "Patients") {
                newRoutes[i].display = userRole && (userRole.indexOf("r:admin") !== -1 || userRole.indexOf("r:staff") !== -1) && provider === 'thb' ? true : false;
            }
            else if (newRoutes[i].key === "anonymous") {
                newRoutes[i].display = userRole && (userRole.indexOf("r:admin") !== -1 || userRole.indexOf("r:staff") !== -1) && provider === 'thb' && sessionStorage.getItem('s3AccountId') !== "breathefree"  ? true : false;
                //new condition for staff as per rakesh sharma changed by keshav pandit
                // newRoutes[i].display =sessionStorage.getItem('userRole') && (sessionStorage.getItem('userRole').indexOf("admin") !== -1 || sessionStorage.getItem('userRole').indexOf("doctor") !== -1) ? true : false;
            } else if (newRoutes[i].key === "docInsight") {
                // display if user is only doctor not admin and is 'aig'
                newRoutes[i].display = userRole.includes('f:insights') && userRole.indexOf("doctor") !== -1
                    && userRole.indexOf("admin") === -1
                    && sessionStorage.getItem('s3AccountId').indexOf("aig") !== -1;
            } else if (newRoutes[i].key === "Appointments") {
               
                if (sessionStorage.getItem('showAppointment') !== "") {
                    newRoutes[i].display = true;
                } else {
                    newRoutes[i].display = false;
                }
            } else if (newRoutes[i].key === "admin-form-builder") {
                if (userRole.indexOf("tempbuilder") !== -1) {
                    newRoutes[i].display = true;
                } else {
                    newRoutes[i].display = false;
                }
            } else if (newRoutes[i].key === "patient-queries") {
                if (userRole.indexOf("doctor") !== -1) {
                    newRoutes[i].display = true;
                    // this.getOpenChats();
                } else {
                    newRoutes[i].display = false;
                }
            } else if (newRoutes[i].key === "transactions") {
                newRoutes[i].display = userRole.includes('f:refund');
            } else if (newRoutes[i].key === "invoice-report") {
                let accountDetails = sessionStorage.getItem('accountInfo');
                let accInfo = accountDetails ? JSON.parse(accountDetails) : null;
                if (accInfo) {
                    newRoutes[i].display = accInfo && accInfo.account_type && accInfo.account_type.toLowerCase() === "hospital" ? false : newRoutes[i].display;
                }
            } else {
                newRoutes[i].display = true;
            }
            if (newRoutes[i].route.indexOf(currentRoute) !== -1) {
                newRoutes[i].isActive = true;
            } else {
                newRoutes[i].isActive = false;
            }
            if(userRole.indexOf("usermgmt")!==-1){
                if (newRoutes[i].key === "UsersMgmt" ) {
                    newRoutes[i].display = true;
                } else {
                    newRoutes[i].display = false;
                }
                if((sessionStorage.getItem('userRole')).indexOf('refund')!==-1){
                    if (newRoutes[i].key== "transactions") {
                        newRoutes[i].display = true;
                    }
                }
            }
            else{
                if (newRoutes[i].key === "UsersMgmt" ) {
                    newRoutes[i].display = false;
                }
            }
        }
        //console.log("newRoutes",newRoutes);
        return newRoutes
    }

    getEmitedEventonNavLogoClick = (event) => {
        for (let index in this.state.emrNavBarRoutes) {
            let indItem = this.state.emrNavBarRoutes[index];
            if (indItem.display) {
                if (indItem.route.indexOf(",") !== -1) {
                    let routeArray = indItem.route.split(",");
                    this.props.history.push(routeArray[0]);
                    return
                } else {
                    this.props.history.push(indItem.route);
                    return
                }
            }
        }
    }

    getEmitEventOnNavClick = (event) => {
        let route;
        if (event === "patient-queries") {
            this.getOpenChats();
        }
        if (event === '/appointment-anonymous') {
            route = '/prescription-detail?patientId=anonymous&type=appointment';
        } else if (event === '/patient-anonymous') {
            route = '/prescription-detail?patientId=anonymous&type=patient';
        } else if (this.state.version) {
            route = event + "?version=" + this.state.version;
        } else {
            route = event;
        }

        if (event === "/admin-form-builder") {
            this.props.history.push(event, { accountId: sessionStorage.getItem('s3AccountId') });
        } else {
            this.props.history.push(route);
        }
    }

    getEmitedLogoutEvent = (event) => {
        let isVideoOn = sessionStorage.getItem('currentVideoApptId');
        if (isVideoOn && isVideoOn !== 'undefined') {
            this.props.enqueueSnackbar('Please complete on-going video consultation before signing out.')
        } else {
            logoutService.callLogoutEvents(this.props.history)
        }
    }

    getEmitedSidebarClickEvent(event) {
        this.props.emitSidebarClickEvent();
    }

    getEmitedMobileNavEvent = (event) => {
        let { mobileNav } = this.state;
        mobileNav = !this.state.mobileNav;
        this.setState({ mobileNav })
    }

    render() {
        return (
            <React.Fragment>
                <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row 11">
                    <BrandViewComponent header={"header view "} navType={this.state.navType} emitSidebarClickEvent={this.getEmitedSidebarClickEvent.bind(this)} emitEventonNavLogoClick={this.getEmitedEventonNavLogoClick.bind(this)}></BrandViewComponent>
                    <RouteViewComponent
                        showDoctorList={this.props.showDoctorList}
                        doctorList={this.props.doctorList}
                        selectedDoc={this.props.selectedDoc}
                        openChatCount={this.state.openChatCount}
                        mobileNav={this.state.mobileNav}
                        getListForDoctor={e => this.props.getListForDoctor(e)}
                        emitMobileNavEvent={this.getEmitedMobileNavEvent.bind(this)}
                        emrNavBarRoutes={this.state.emrNavBarRoutes}
                        emitEventOnNavClick={this.getEmitEventOnNavClick.bind(this)}
                        emitLogoutEvent={this.getEmitedLogoutEvent.bind(this)}>
                    </RouteViewComponent>
                </nav>

                {/* <nav className={`sidebar m-sidemenu sidebar-offcanvas ${this.state.mobileNav ? 'active' : ''}`} id="sidebar ">
                    <ul className="nav"> */}
                {/* <MobileBrandView></MobileBrandView> */}
                <MobileRouteViewComponent
                    showDoctorList={this.props.showDoctorList}
                    openChatCount={this.state.openChatCount}
                    doctorList={this.props.doctorList}
                    mobileNav={this.state.mobileNav}
                    getListForDoctor={e => this.props.getListForDoctor(e)}
                    selectedDoc={this.props.selectedDoc}
                    emrNavBarRoutes={this.state.emrNavBarRoutes}
                    emitEventOnNavClick={this.getEmitEventOnNavClick.bind(this)}
                    emitLogoutEvent={this.getEmitedLogoutEvent.bind(this)}>
                </MobileRouteViewComponent>
                {/* </ul>
                </nav> */}
            </React.Fragment>
        );
    }
}

export default withSnackbar(HeaderContainer);
