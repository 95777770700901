import React from "react";
// component imports
import HeaderContainer from '../../Common/Header/HeaderContainer';
import AccountUserListViewComponent from './Component/AccountUserListViewComponent';
// service imports
import accountService from '../../Services/account.settings';
// Utils import
// import Utils from '../../Shared/utils/utils';
// import userAuth from '../../Authentication/authActions';
// common imports
import Spinner from '../../Common/Spinner/Spinner';
import SweetAlertComponent from '../../Common/Alert/SweetAlertComponent';
// additional plugin imports
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getImgPath } from "../../providerConfig";

class AccountUserListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            loading: false,
            queryParams: this.props.location.search !== '' ? JSON.parse('{"' + decodeURI(this.props.location.search.substr(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}') : '',
            accountDetailData: this.props.location && this.props.location.state ? this.props.location.state.accountData : null,
            alert: {
                type: '',
                title: '',
                text: ''
            },
            MFADialog: {},
            MFAUser: '',
            userAccountList: null,
            filterParams: {}
        };
    }

    componentDidMount() {
        this.setState({
            loading: true
        })
        if (this.state.queryParams.accountId) {
            this.fetchAllAccountData(this.state.queryParams.accountId);
        }
    }

    async fetchAllAccountData(id) {
        try {
            var AccountListResponse = await accountService.getUsersList(this.state.filterParams, id);
            this.checkAccountListResponseData(AccountListResponse);
        } catch (error) {
            this.onError('Error');
        }
    }

    checkAccountListResponseData(response) {
        if (!response) {
            this.onError('No Data Found');
            return;
        }
        switch (response.type) {
            case "error":
                this.onError('No Data Found')
                break;
            case "success":
                this.setState({
                    loading: false,
                    userAccountList: response.data
                })
                break;
            default:
                return;
        }
    }

    getEmitedSelectedFilerValues = (event) => {
        this.setState({
            filterParams: event,
            loading: true
        }, () => this.fetchAllAccountData(this.state.queryParams.accountId))
    }

    onError(text) {
        this.setState(prevState => ({
            show: true,
            loading: false,
            alert: {
                ...prevState.alert,
                type: 'error',
                title: 'Error',
                text: text ? text : 'Error'
            }
        }));
    }

    notify(message) {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            containerId: 'A'
        });
    }

    sweetAlertClose = (event) => {
        this.setState({ show: false });
    }

    getEmitedRedirectToEditView = (event) => {
        this.props.history.push(event, { accountDetailData: this.state.accountDetailData });
    }

    getEmitedAddNewUserEventWithAccountId = (event) => {
        this.props.history.push('/create-account-user', { accountId: event, accountDetailData: this.state.accountDetailData });
    }

    getEmitBackButtonClick = (event) => {
        this.props.history.push(event, { accountData: this.state.accountDetailData });
    }

    getEmitedStageUserData = (event) => {
        let data = event;
        data['cognito_username'] = this.checkForCountryCodeInPhoneField(data['cognito_username']);
        data['phone'] = this.checkForCountryCodeInPhoneField(data['phone']);
        this.updateStageUser(data);
    }

    checkForCountryCodeInPhoneField(phone) {

        if (phone.indexOf("+977") !== -1) {
            return phone
        }
        phone = "+977" + phone;
        return phone;
    }

    async updateStageUser(data) {
        try {
            const response = await accountService.updateStageUserById(data);
            this.checkForStageUserResponse(response);
        } catch (error) {
            console.log(error);
            this.onError(error.message);
        }
    }

    checkForStageUserResponse(response) {
        if (!response) {
            this.onError(response);
            return;
        }
        switch (response.type) {
            case 'error':
                this.onError(response.message);
                break;
            case 'success':
                this.onSuccess('UserName Updated');
                if (this.state.queryParams.accountId) {
                    this.fetchAllAccountData(this.state.queryParams.accountId);
                }
                break;
            default:
                break;
        }
    }

    onSuccess(text) {
        this.setState(prevState => ({
            show: true,
            alert: {
                ...prevState.alert,
                type: 'success',
                title: 'Success',
                text: text ? text : 'Success'
            }
        }));
    }

    getEmitedAccountUserEditEvent = (event, userData) => {
        this.props.history.push(event, userData);
    }

    async onPasswordEmit(password, userData) {
        let passwordObj = {
            "username": userData.cognito_username,
            "password": password
        }
        if (sessionStorage.getItem('isSuperUser') === 'true' || sessionStorage.getItem('isSuperUser') === true) {
            passwordObj = {
                ...passwordObj,
                "account_id": userData.account_id
            }
        }
        let response = await accountService.setUserPassword(passwordObj);
        if (!response) {
            this.onError(response);
            return;
        }
        switch (response.type) {
            case 'error':
                this.onError(response.message);
                break;
            case 'success':
                this.onSuccess('Success in setting password');
                break;
            default:
                break;
        }
    }

    getEmitedEnableMFA = (user) => {
        this.setState({
            MFADialog: { show: true, title: 'Enable Multi-Factor Authentication?', text: 'This feature adds additional security', type: 'error' },
            MFAUser: user
        })
    }

    onDialogClose = (isEnableConfirmed) => {
        if (isEnableConfirmed && this.state.MFAUser) {
            this.onEnableMFA();
        } else {
            this.setState({
                MFADialog: { show: false, title: '', text: '', type: '' },
                MFAUser: null
            })
        }
    }

    async onEnableMFA() {
        try {
            let data = {
                "mfa_enabled": this.state.MFAUser.mfa ? false : true,
                "account_id": this.state.queryParams.accountId,
                "cognito_username": this.state.MFAUser && this.state.MFAUser.cognito_username ? this.state.MFAUser.cognito_username : ''
            }
            let id = this.state.MFAUser && this.state.MFAUser.id ? this.state.MFAUser && this.state.MFAUser.id : ''
            let res = await accountService.enableMFAForUser(data, id);
            if (res && res.type === 'success') {
                this.setState({
                    MFADialog: { show: true, title: 'MFA Enabled!', text: '', type: 'success' },
                    MFAUser: null
                })
            } else {
                this.setState({
                    MFADialog: { show: true, title: 'Error', text: 'Please try again.', type: 'error' },
                    MFAUser: null
                })
            }
        } catch (err) {
            this.setState({
                MFADialog: { show: true, title: 'Error', text: 'Please try again.', type: 'error' },
                MFAUser: null
            })
        }
    }

    render() {
        let { MFADialog, MFAUser, accountDetailData, loading } = this.state;
        return (
            <div >
                <HeaderContainer {...this.props}></HeaderContainer>
                {loading ? <div style={{ marginTop: '80px' }}><Spinner width={75} height={75}></Spinner></div> :
                    <div className="container-fluid page-body-wrapper" >
                        <div className="main-panel">
                            <div className="content-wrapper" style={{ padding: '5.50rem 1.25rem  2.25rem  1.25rem' }}>
                                {this.state.userAccountList && <AccountUserListViewComponent
                                    history={this.props.history}
                                    loading={this.state.loading}
                                    accountDetailData={accountDetailData}
                                    userAccountList={this.state.userAccountList}
                                    emitPassword={this.onPasswordEmit.bind(this)}
                                    emitBackButtonClick={this.getEmitBackButtonClick.bind(this)}
                                    emitSelectedFilerValues={this.getEmitedSelectedFilerValues.bind(this)}
                                    emitAddNewUserEventWithAccountId={this.getEmitedAddNewUserEventWithAccountId.bind(this)}
                                    emitStageUserData={this.getEmitedStageUserData.bind(this)}
                                    emitEnableMFA={this.getEmitedEnableMFA.bind(this)}
                                    emitAccountUserEditEvent={this.getEmitedAccountUserEditEvent.bind(this)}></AccountUserListViewComponent>
                                    ||
                                    <div className="alert alert-light alert-elevate fade show alert alert-warning" role="alert">
                                        <div className="alert-text text-center">
                                            <h4 className="text-success">Nothing here!</h4>
                                            <img src={getImgPath('sadface')} alt="logo" className="center-align img-fluid" />
                                            <p></p>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }

                {this.state.show &&
                    <SweetAlertComponent show={this.state.show} type={this.state.alert.type}
                        title={this.state.alert.title} text={this.state.alert.text}
                        sweetAlertClose={this.sweetAlertClose}></SweetAlertComponent>
                }
                {MFADialog.show && <SweetAlertComponent type={MFADialog.type} title={MFADialog.title} text={MFADialog.text}
                    sweetAlertClose={this.onDialogClose.bind(this, true)}
                    showCancel={!!MFAUser}
                    onCancel={this.onDialogClose.bind(this, false)}></SweetAlertComponent>}
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
            </div>
        );
    }
}

export default AccountUserListContainer;
