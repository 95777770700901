import React, { useState, useEffect } from "react";
import Spinner from "../../../Common/Spinner/Spinner";
import patientService from "../../../Services/patient.service";
import SweetAlertComponent from "../../../Common/Alert/SweetAlertComponent";

export const source = {
    chatroom: 'chatroom',
    multi_doctor_chat: 'multidoctorchat',
    patient_list: 'patientlist',
    appt_list: 'apptlist',
    presc_doc_chat: 'prescdocchat'
}

function PatientSmsStatusComponent(props) {

    const [templates, setTemplates] = useState(null);
    const [messageType, setMessageType] = useState(null);
    const [message, setMessage] = useState(null);
    const [isTriggerLoading, setTriggerLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState({ show: false });
    const [delayTime, setDelayTime] = useState(false);

    useEffect(() => {
        getMessageTemplates();
    }, [])

    function setMessageTypeFn(e) {
        if (!templates) return;
        setDelayTime(templates[e.target.value].message && templates[e.target.value].message.indexOf('delay_time') !== -1)
        setMessage(templates[e.target.value].message);
        setMessageType(e.target.value);
    }

    async function saveSmsStatus() {
        setTriggerLoading(true)
        try {
            let accountId = sessionStorage.getItem('s3AccountId');
            let isTypeAppt = props.apptDetail && !!props.apptDetail.id;
            let msg = message;
            let msgType = templates[messageType] ? isTypeAppt ?
                templates[messageType].name.toLowerCase().replace(' message', '').replace(' ', '')
                :
                templates[messageType].name
                : null;
            let payload = {
                "template_type": isTypeAppt && 'appointment' || 'patient',
                "message_type": msgType,
                [isTypeAppt && "appointment_id" || "patient_id"]: isTypeAppt ? props.apptDetail && props.apptDetail["id"] : props.patientDetail && props.patientDetail["patient_id"],
                "account_id": accountId,
                "patient_phone": props.patientDetail ? props.patientDetail.phone : null,
                "patient_name": props.patientDetail ? props.patientDetail.name : null
            }
            if (props.apptDetail) {
                payload = {
                    ...payload,
                    "appt_date": props.apptDetail.appointment_date,
                    "appt_slot": props.apptDetail.appointment_slot
                }
                msg = msg.replace('[[appt_date]]', props.apptDetail.appointment_date);
                msg = msg.replace('[[appt_slot]]', props.apptDetail.appointment_slot);
                if (props.apptDetail.room_id) {
                    let chatroomlink = `${window.location.origin}/${accountId}/waiting-area/${props.apptDetail.room_id}`;
                    payload = {
                        ...payload,
                        chatroom_link: chatroomlink
                    }
                    msg = msg.replace('[[chatroom_link]]', chatroomlink);
                }
            }
            if (delayTime) {
                payload = {
                    ...payload,
                    delay_time: String(delayTime)
                }
                msg = msg.replace('[[delay_time]]', delayTime);
            }
            payload = {
                ...payload,
                message: msg
            }
            var response = await patientService.triggerSms(payload);
            checkForTriggerResponse(response);
        } catch (error) {
            console.log('error');
        }
    }

    function checkForTriggerResponse(response) {
        if (!response) {
            onError('Error Occured while SMS Trigger')
            return;
        }

        switch (response.type) {
            case "error":
                onError(response.message ? response : 'Error Occured while SMS Trigger')
                break;
            case "success":
                setTriggerLoading(false);
                setAlert({
                    show: true,
                    type: 'success',
                    title: 'success',
                    text: "SMS Trigger Done Successfully"
                })
                break;
            default:
                return;
        }
    }

    function onError(res) {
        setTriggerLoading(false)
        setAlert({
            show: true,
            title: 'Error',
            type: 'error',
            text: typeof res === 'object' ? res.message : res ? res : 'Error occurred'
        })
    }

    const sweetAlertClose = (res) => {
        setAlert({ show: false })
        props.emitSmsStatusSaveEvent();
    }

    async function getMessageTemplates() {
        setLoading(true);
        try {
            let type = props.apptDetail && props.apptDetail.id ? 'appointment' : 'patient';
            // let type = 'patient';
            var response = await patientService.getMessageTemplates(type);
            if (response && response.data) {
                let finalTemplates = filterMsgTemplates(response.data.generic_sms_template);
                setTemplates(finalTemplates);
                setLoading(false)
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            console.log('error');
        }
    }

    function filterMsgTemplates(templates) {
        if (!templates) return;

        return templates.filter(t => {
            if (!t.message) return false;
            if (t.message.indexOf('room_id') !== -1 && (!props.apptDetail || !props.apptDetail.room_id)) {
                return false
            }
            if (t.message.indexOf('appt_date') !== -1 && (!props.apptDetail || !props.apptDetail.appointment_date)) {
                return false
            }
            if (!t.name) return false;
            let msgName = t.name.toLowerCase().replace(' message', '').replace(' ', '');
            if (props.source === source.chatroom && ['reschedule', 'payment', 'chat', 'cancel'].indexOf(msgName) !== -1) {
                //hide these msgs in chatroom
                return false;
            } else if (props.source === source.appt_list && [].indexOf(msgName) !== -1) {
                //hide these msgs in appt list
                return false;
            }
            //else if (props.source === source.patient_list && [].indexOf(msgName) !== -1) {
            //     //hide these msgs in patient list
            //     return false;
            // }
            else if ((props.source === source.presc_doc_chat || props.source === source.multi_doctor_chat) && ['reschedule', 'payment', 'chat', 'cancel', 'doctorwaiting'].indexOf(msgName) !== -1) {
                //hide these msgs in presc doctor chat
                return false;
            }
            return true
        });
    }

    return (<>
        {templates && <div>
            <div>
                <label>Message Type<span className="text-danger">*</span></label>
                <select className="form-control" type="select" name="name" onChange={(e) => setMessageTypeFn(e)} value={messageType ? messageType : 'initial'}>
                    <option value='initial' disabled>Select message type</option>
                    {templates.map((o, idx) => <option value={idx}>{o.name ? o.name.replace('_', ' ') : ''}</option>)}
                </select>
            </div>
            {delayTime !== false && <div>
                <label>Delay Time<span className="text-danger">*</span></label>
                <input className="form-control" type="number" min="0" name="message" placeholder='Enter number of minutes' onChange={(e) => setDelayTime(e.target.value)} />
            </div>}
            {messageType !== null && <div>
                <label>Message<span className="text-danger">*</span></label>
                <textarea className="form-control" type="textarea" name="message" rows="6" placeholder='Enter message' onChange={(e) => setMessage(e.target.value)} value={message} />
            </div>}
            <div className="col-12 mt-1 pt-2" style={{ borderTop: 'rgba(0, 0, 0, .2) dashed 1px', textAlign: 'right' }}>{isTriggerLoading && <div style={{ display: 'inline-block', 'marginRight': '30px' }}><Spinner width={50} height={50}></Spinner></div>}
                <button type="submit" className="btn btn-success mr-2 btn-rounded" disabled={isTriggerLoading || !message || !messageType || (delayTime === true || delayTime === '')} onClick={saveSmsStatus.bind(this)}>Send SMS</button>
            </div>
            {alert.show &&
                <SweetAlertComponent show={alert.show} type={alert.type} title={alert.title} text={alert.text} sweetAlertClose={sweetAlertClose.bind(this, true)}></SweetAlertComponent>
            }
        </div>}
        {!templates && loading && <div style={{ justifyContent: 'center' }}><Spinner width={50} height={50}></Spinner></div>}
        {!templates && !loading && <small className="text-danger">Error Occured while fetching SMS templates!</small>}
    </>);
}

export default PatientSmsStatusComponent;
