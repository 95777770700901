import React from "react";
import { suggestionJson } from '../suggestionJson'
import { getImgPath } from "../../../providerConfig";

class SuggestionNewViewComponent extends React.PureComponent {

   constructor(props) {
      super(props);
      this.state = {
         height: "66vh",
         top: "-72px",
         right: (this.props.mode === "edit") ? "225px" : "-1.2%",
         borderRadius: "10px",
         width: "16.5vw",
         position: "absolute"
      }
      this.listenScrollEvent = this.listenScrollEvent.bind(this);
   }


   componentDidMount() {
      window.addEventListener("scroll", this.listenScrollEvent);
   }

   onSuggestionClicked = (event, sectionType) => {
      this.props.emitSuggestionData(event, sectionType);
   }

   listenScrollEvent = event => {
      if (window.scrollY < 200) {
         this.handleScroll('normal')
         //   return setHeader("header");
      } else if (window.scrollY > 200) {
         if (this.props.mode === "edit") {
            this.handleEditScroll('fixed')
         } else {
            this.handleScroll('fixed')
         }

         //   return setHeader("header2");
      }
   };

   handleEditScroll = (type) => {
      this.setState({
         right: "225px",
      })
   }

   handleScroll = (type) => {
      if (type === "fixed") {
         this.setState({
            height: "66vh",
            top: "14%",
            right: "18.8%",
            borderRadius: "10px",
            width: "16.5vw",
            position: "fixed"
         })
      } else {
         this.setState({
            height: "66vh",
            top: "-1%",
            right: (this.props.mode === "edit") ? "225px" : "-1.2%",
            borderRadius: "10px",
            width: "16.5vw",
            position: "absolute"
         })
      }

   }

   componentWillUnmount() {
      window.removeEventListener("scroll", this.listenScrollEvent());
   }

   onTemplateWiseSuggestionClicked = (event, sectionId) => {
      this.props.emitTemplateWiseSuggestionData(event, sectionId);
   }

   getFormattedSuggestionList(result) {
      if (Array.isArray(result)) {
         return result;
      }
   }

   render() {
      const { suggestionViewType, template_id, mode } = this.props;
      // const dynamicSuggestionData = suggestionJson.result.suggestions.suggestions.dynamic;
      const dynamicSuggestionData = this.props.suggestionList ? this.getFormattedSuggestionList(this.props.suggestionList) : [];
      let templateData = sessionStorage.getItem('suggestions');
      let messageData = '';
      templateData = this.props.suggestionList;
      if (templateData && templateData.messages) {
         messageData = templateData.messages;
         delete templateData.messages;
      }
      return (<>
         {!this.props.hideSugg && <div id="sidebar-wrapper" 
         className='active' style={{ height: this.state.height,
          top: mode === "edit" ? 0 : this.state.top, right: mode === "edit" ? this.state.right : 
          this.state.right, borderRadius: 10, width: '16.5vw', position: this.state.position }}>
            {/* <a id="menu-close" onClick={this.props.emitSuggestionCloseEvent.bind(this)} style={{ left: 0, margin: 0 }} className="btn btn-icon toggle">
               <i className="mdi mdi-close"></i>
            </a> */}
            {!suggestionViewType && ((templateData && Object.keys(templateData).length === 0) || !templateData) && <>
               <div className="text-muted p-3">
                  Suggestions will appear here
                  </div>
            </>}
            <div className="pl-3 timeslot">
               {/* {messageData && messageData.length > 0 &&
                  <>
                     <p style={{ margin: 0, textTransform: 'capitalize' }}><strong> Messages</strong></p>
                     {messageData.map((indMessage, index) =>
                        <button key={index} type="button" className="btn btn-outline-success btn-sm btn-rounded mr-1" style={{ width: "98%" }}>
                           {indMessage.message}
                        </button>
                     )}
                  </>
               } */}
               {!suggestionViewType &&
                  <>
                     {templateData &&
                        <>
                           {Object.keys(templateData).map((indSuggestion, index) =>
                              templateData[indSuggestion] && Object.keys(templateData[indSuggestion]).length > 0
                                 ? (<>
                                    {templateData[indSuggestion]["messages"] &&
                                       <>
                                          <p key={index} style={{ margin: 0, textTransform: 'capitalize' }}><strong> Messages</strong></p>
                                          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexFlow: 'wrap' }}>
                                             <>
                                                {/* {Object.keys(templateData[indSuggestion]).map((specificSuggestion, jndex) =>
                                                   <> */}
                                                <>
                                                   {templateData[indSuggestion]["messages"] && templateData[indSuggestion]["messages"].map((indMessage, index) =>
                                                      <button key={index} type="button" className="btn btn-outline-success btn-sm btn-rounded mr-1" style={{ width: "98%" }}>
                                                         {indMessage.message}
                                                      </button>
                                                   )}
                                                </>
                                                {/* </>
                                                )} */}

                                             </>


                                          </div>
                                       </>
                                    }
                                    {
                                       <>
                                          <p key={index} style={{ margin: 0, textTransform: 'capitalize' }}><strong> {indSuggestion}</strong></p>
                                          <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexFlow: 'wrap' }}>
                                             <>
                                                {Object.keys(templateData[indSuggestion]).map((specificSuggestion, jndex) =>
                                                   <>
                                                      <>
                                                         {specificSuggestion !== "messages" && templateData[indSuggestion][specificSuggestion] &&
                                                            <button key={jndex} type="button" style={{ textTransform: "capitalize", width: 'auto', padding: 5, fontSize: 12 }} className="btn btn-outline-success btn-sm btn-rounded mr-1"
                                                               onClick={this.onTemplateWiseSuggestionClicked.bind(this, templateData[indSuggestion][specificSuggestion], indSuggestion)}>
                                                               {typeof templateData[indSuggestion][specificSuggestion] === 'object' ? (templateData[indSuggestion][specificSuggestion].result_t ? templateData[indSuggestion][specificSuggestion].result_t : templateData[indSuggestion][specificSuggestion].result_date ? templateData[indSuggestion][specificSuggestion].result_date : '') + (templateData[indSuggestion][specificSuggestion].dosage ? ', ' + templateData[indSuggestion][specificSuggestion].dosage : '')
                                                                  : templateData[indSuggestion][specificSuggestion]}
                                                            </button>
                                                         }
                                                      </>

                                                   </>
                                                )}

                                             </>


                                          </div>
                                       </>


                                    }

                                 </>) : (<>
                                    <div className="alert alert-light alert-elevate fade show alert alert-warning pl-1" style={{ border: '0' }} role="alert">
                                       <div className="alert-text text-center">
                                          <h4 className="text-success">{indSuggestion}</h4>
                                          <img src={getImgPath('sadface')} alt="logo" className="center-align img-fluid" />
                                          <p>No suggestions available for {indSuggestion}</p>
                                       </div>
                                    </div>
                                 </>)
                           )}
                        </>
                     }
                  </>
               }


               {this.props.suggestionList && this.props.suggestionList.suggestions && Object.keys(this.props.suggestionList.suggestions).length > 0 &&
                  <>
                     {Object.keys(this.props.suggestionList.suggestions).map((indSuggestion, index) =>
                        <>
                           {indSuggestion &&
                              <>
                                 {this.props.suggestionList.suggestions[indSuggestion] && this.props.suggestionList.suggestions[indSuggestion].length > 0 &&
                                    <>
                                       {this.props.suggestionIndexDetails && (this.props.suggestionIndexDetails.key === indSuggestion) &&
                                          <>
                                             <p style={{ margin: 0, textTransform: 'capitalize' }}><strong> {indSuggestion}</strong></p>
                                             <div>

                                                <>
                                                   {this.props.suggestionList.suggestions[indSuggestion].map((specificSuggestion, jndex) =>
                                                      <>
                                                         {specificSuggestion.result_t &&
                                                            <button key={jndex} type="button" className="btn btn-outline-success btn-sm btn-rounded mr-1" style={{ textTransform: "capitalize",padding: 5, fontSize: 12 }}
                                                               onClick={this.onSuggestionClicked.bind(this, specificSuggestion, indSuggestion)}>
                                                               {typeof specificSuggestion === 'object' ? (specificSuggestion.result_t ? specificSuggestion.result_t : specificSuggestion.result_date ? specificSuggestion.result_date : '') + (specificSuggestion.dosage ? ', ' + specificSuggestion.dosage : '')
                                                                  : specificSuggestion}
                                                            </button>
                                                         }
                                                      </>
                                                   )}
                                                </>


                                             </div>
                                          </>
                                       }

                                    </>
                                 }
                              </>

                           }

                        </>
                     )}
                  </>
               }
               {suggestionViewType === "tagocr" &&
                  <p></p>
               }

            </div>
         </div>}
      </>);
   }
}

export default SuggestionNewViewComponent;
