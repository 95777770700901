import {
  CountryCodesJson
} from "../CountryCodes";
import {
  Specialities
} from "../Specialities";

// address: {country: "nepal", state: "state", city: "city", zipcode: "zipcode", line1: "ad1", line2: "ad2"}
// city: "city"
// country: "nepal"
// line1: "ad1"
// line2: "ad2"
// state: "state"
// zipcode: "zipcode"
// age: "40"
// country_code: "+977"
// date_of_birth: "2019-09-23"
// department: "ortho"
// doctor_id: ""
// doctor_name: ""
// email: "dirjk@gmail.com"
// gender: "male"
// name: "Fd"
// phone: "2389089078"
// role: "admin"
// speciality: "child"

// array of columns
const personRow1 = [{
  "isRequired": true,
  "name": "name",
  "placeholder": "Enter name",
  "hasErrorsMessage": "*Only letters allowed",
  "colSize": 8,
  "type": "textbox",
  "label": "Full Name",
  "labelSize": 3,
}, {
  "isRequired": true,
  "name": "role",
  "placeholder": "Select User Role",
  "colSize": 8,
  "type": "multiselect",
  "multiple": false,
  "dataKey": "userRoles",
  "label": "User Role",
  "labelSize": 3,
}, {
  "isRequired": false,
  "name": "env",
  "placeholder": "Select User Env",
  "colSize": 8,
  "type": "selection",
  "dataKey": "envData",
  "hidden": '',
  "label": "User Env",
  "labelSize": 3,
},{
  
  "name": "features",
  "placeholder": "Select Feature",
  "isRequired": false,
  "colSize": 8,
  "type": "multiselect",
  "dataKey": "featureUiData",
  "label": "Features",
  "labelSize": 3,
}];

const personRow2 = [{
  "isRequired": false,
  "name": "department",
  "placeholder": "Enter Department",
  "colSize": 8,
  "type": "textbox",
  "label": "Department",
  "labelSize": 3,
}, {
  "isRequired": true,
  "name": "doctor_id",
  "placeholder": "Select Doctor",
  "colSize": 8,
  "type": "autoselection",
  "dataKey": "doctors",
  "label": "Doctor",
  "labelSize": 3,
}];

const personRow3 = [{
  "isRequired": false,
  "name": "speciality",
  "placeholder": "Select Speciality",
  "colSize": 8,
  "dataKey": "speciality",
  "label": "Speciality",
  "labelSize": 3,
  "optionKey": "specialityCode",
  "displayValueFn": (obj) => {
    return obj.specialityCode;
  },
  "type": "autocomplete"
}];

const personRow4 = [{
  "label": "Phone",
  "labelSize": 3,
  "isRequired": true,
  "children": [{
      "name": "country_code",
      "placeholder": "Select country code",
      "colSize": 3,
      "dataKey": "countryCodes",
      "optionKey": "dialCode",
      "displayValueFn": (obj) => {
        return obj.dialCode + "(" + obj.name + ")";
      },
      "type": "autocomplete"
    },
    {
      "name": "phone",
      "placeholder": "Enter phone",
      "hasErrorsMessage": "*Invalid number, It must be ten digits",
      "colSize": 5,
      "type": "textbox",
    }
  ]
}, {
  "isRequired": false,
  "name": "email",
  "placeholder": "Enter email",
  "hasErrorsMessage": "*Invalid email format",
  "colSize": 8,
  "type": "textbox",
  "label": "Email",
  "labelSize": 3,
}];

const personRow5 = [{
  "label": "Date of Birth",
  "labelSize": 3,
  "colSize": 8,
  "isRequired": true,
  "children": [{
      "name": "date_of_birth",
      "colSize": 4,
      "type": "date",
      "maxDateFn": () => new Date(),
      "minDateFn": () => {}
    },
    {
      "name": "age",
      "placeholder": "Age in years",
      "colSize": 4,
      "type": "textbox",
    }
  ]
}, {
  "isRequired": false,
  "name": "gender",
  "colSize": 8,
  "optionColSize": 4,
  "type": "radio",
  "dataKey": "gender",
  "label": "Gender",
  "labelSize": 3,
}];

const addrRow1 = [{
  "isRequired": false,
  "name": "line1",
  "placeholder": "Enter Address Line 1",
  "colSize": 8,
  "type": "textbox",
  "label": "Address Line 1",
  "labelSize": 3,
}, {
  "isRequired": false,
  "name": "line2",
  "placeholder": "Enter Address Line 2",
  "colSize": 8,
  "type": "textbox",
  "label": "Address Line 2",
  "labelSize": 3,
}];

const addrRow2 = [{
  "isRequired": false,
  "name": "zipcode",
  "placeholder": "Enter zipcode",
  "colSize": 8,
  "type": "textbox",
  "label": "Zipcode",
  "labelSize": 3,
}, {
  "isRequired": false,
  "name": "city",
  "placeholder": "Enter city",
  "colSize": 8,
  "type": "textbox",
  "label": "City",
  "labelSize": 3,
}];

const addrRow3 = [{
  "isRequired": false,
  "name": "state",
  "placeholder": "Enter state",
  "colSize": 8,
  "type": "textbox",
  "label": "State",
  "labelSize": 3,
}];

const uiData = {
  "doctors": [], //get doctors from svc
  "countryCodes": CountryCodesJson, //get from static JSON
  "gender": {
    options: [{
        "value": "male",
        "key": "male",
        "checked": false
      },
      {
        "value": "female",
        "key": "female",
        "checked": false
      },
      {
        "value": "others",
        "key": "others",
        "checked": false
      }
    ]
  },
  "speciality": Specialities,
  "envData": [{
    key: 'e:dev',
    value: 'Dev'
  }, {
    key: 'e:prod',
    value: 'Prod'
  }],
  "userRoles": [{
    key: 'r:admin',
    value: 'Admin'
  }, {
    key: 'r:staff',
    value: 'Staff'
  }, {
    key: 'r:doctor',
    value: 'Doctor'
  }],
  "featureUiData": [{
    key: 'f:presc',
    value: 'prescription'
  }, {
    key: 'f:tmpl',
    value: 'templates'
  }, {
    key: 'f:ocr',
    value: 'ocr'
  }, {
    key: 'f:invc',
    value: 'invoice'
  }, {
    key: 'f:allpres',
    value: 'All Prescription'
}],
}
// {key: 'receptionist', value: 'Receptionist'}, 

const personalInfo = {
  sectionName: 'Personal Info',
  rows: [personRow1, personRow2, personRow3, personRow4, personRow5]
};
const addressInfo = {
  sectionName: 'Address',
  rows: [addrRow1, addrRow2, addrRow3]
}

const patientFormData = {
  formName: "User Form",
  sections: [personalInfo, addressInfo],
  uiData: uiData
};


export default patientFormData;