import React, { Fragment } from "react";
// import DatePicker from '../../../Common/FormFields/DatePicker';
import Utils from '../../../Shared/utils/utils';
import View from '../../../Shared/utils/CommonTableView';
import doctorService from "../../../Services/doctor.service";
import DateFilter from '../../../Shared/utils/DateViewUtil';
import Appt from './TableViewHelper';
import TimeKeeper from 'react-timekeeper';
import UAParser from 'ua-parser-js';
import appointmentService from '../../../Services/appointment.service';

// import AppointmentFilterViewComponent from './AppointmentFilterViewComponent';
const $ = window["jQuery"];
const appointmentStatusEnum = {
   all: 'All',
   booked: 'Booked',
   checked: 'Checked-In',
   done: 'Done',
   Intent: 'Intent'
};

const appointmentType = [
   {
      key: 'online',
      value: 'Online Appointment'
   }, {
      key: 'offline',
      value: 'Offline Appointment'
   }
]

const appointmentStatusClassEnum = {
   all: 'badge-gradient-dark',
   booked: 'badge-gradient-warning',
   checked: 'badge-gradient-primary',
   done: 'badge-gradient-success',
   Intent: 'badge-gradient-secondary'
};

const appointmentStatusOutlineClassEnum = {
   all: 'badge-outline-dark',
   booked: 'badge-outline-warning',
   checked: 'badge-outline-primary',
   done: 'badge-outline-success',
   Intent: 'badge-gradient-secondary'
};

class AppointmentsViewComponent extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         accountInfo: this.props.accountInfo,
         appointmentConfig: this.props.appointmentConfig,
         appointmentList: this.props.appointmentConfig.rows,
         filteredAppointmentList: this.props.appointmentConfig.rows,
         filters: this.props.appointmentConfig.filters,
         visibleHeader: this.props.appointmentConfig.visible,
         formatedHeader: null,
         finalTableData: [],
         intentModelOpen: false,
         loading: this.props.loading,
         date: this.props.date,
         patientData: null,
         patientDataIndex: [],
         isOnlineappointment: '',
         patientHidMappingArray: [],
         isRoleDoctor: sessionStorage.getItem('userRole').indexOf('doctor') !== -1,
         isRoleStaff: sessionStorage.getItem('userRole').indexOf('staff') !== -1,
         selectedStatus: 'all',
         selectedAppointment: [],
         isSlotLoading: false,
         slotData: null,
         slotType: 'offline',
         showClock: true,
         showPresetSlots: true,
         selectedSlot: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
      }
   }

   componentDidMount() {
      this.formatHeader()
      this.formTableBodyData();
      this.filterByAppointmentStatus('all');

      $(function () {
         $('[data-toggle="tooltip"]').tooltip()
      })
   }

   toggleAppointmentType = (event) => {
      this.state.isOnlineappointment = event.target.value;
      this.setState(this.state);
      this.filterAppointmentByType();
   }

   formatHeader() {
      if (!this.state.visibleHeader || this.state.visibleHeader.length === 0) {
         return;
      }
      let finalHeader = [];
      for (let index in this.state.visibleHeader) {
         if (this.state.visibleHeader[index].indexOf("_") !== -1) {
            let strArray = this.stringSpiltForHeader(this.state.visibleHeader[index], '_');
            if (strArray[0] === "referred") {
               strArray[0] = 'ref';
            }
            if (strArray[1] && strArray[1] === "Queue") {
               strArray[1] = 'Q';
            }
            if (strArray[2] && strArray[2] === "number") {
               strArray[2] = 'no';
            }
            if (strArray[2] && strArray[2] === "name") {
               strArray[2] = '';
            }
            if (strArray[1] && strArray[1] === "doctor") {
               strArray[1] = 'doc';
            }
            if (strArray[0] === "appointment" && strArray[1] !== 'slot') {
               strArray[0] = '';
            } else if (strArray[0] === "appointment" && strArray[1] === 'slot') {
               strArray[0] = 'time';
            }
            // Appointment 
            let indObj = {
               key: this.state.visibleHeader[index],
               value: strArray.join(" ")
            }
            finalHeader.push(indObj);
         } else if (this.state.visibleHeader[index].indexOf(".") !== -1) {
            if (this.state.visibleHeader[index].indexOf('patient') !== -1) {
               this.state.patientDataIndex.push(index);
               this.setState(this.state);
               // continue;
            }
            let strArray = this.stringSpiltForHeader(this.state.visibleHeader[index], '.');
            let indObj = {
               key: this.state.visibleHeader[index],
               value: strArray[1]
            }
            finalHeader.push(indObj);
         } else {
            let strArray = this.state.visibleHeader[index];
            let indObj = {
               key: this.state.visibleHeader[index],
               value: strArray
            }
            finalHeader.push(indObj);
         }
      }
      this.state.formatedHeader = finalHeader;
      this.setState(this.state);
   }

   stringSpiltForHeader(value, seperate) {
      return value.split(seperate);
   }

   formTableBodyData() {
      if (!this.state.appointmentList || (this.state.appointmentList && this.state.appointmentList.length === 0)) {
         this.state.finalTableData = [];
         this.state.loading = false;
         this.setState(this.state);
         return;
      }
      if (!this.state.visibleHeader || this.state.visibleHeader.length === 0) {
         return;
      }
      this.state.patientHidMappingArray = [];
      this.setState(this.state);
      if (this.state.visibleHeader.indexOf('id') === -1) this.state.visibleHeader.unshift('id');
      if (this.state.visibleHeader.indexOf('patient_id') === -1) this.state.visibleHeader.unshift('patient_id');
      if (this.state.visibleHeader.indexOf('payment_order_id') === -1) this.state.visibleHeader.unshift('payment_order_id');
      this.setState(this.state)
      let patientHidArray = []
      for (let index in this.state.appointmentList) {
         if (!this.state.visibleHeader) {
            return;
         }
         patientHidArray.push(this.state.appointmentList[index].patient_hid ? this.state.appointmentList[index].patient_hid : this.state.appointmentList[index].patient ? this.state.appointmentList[index].patient.patient_hid : '')
         this.state.patientHidMappingArray = patientHidArray;
      }
      this.state.finalTableData = this.state.appointmentList;
      this.state.loading = false;
      this.setState(this.state);
   }

   static getDerivedStateFromProps(nextProps, prevState) {
      // do things with nextProps.someProp and prevState.cachedSomeProp
      if (nextProps.appointmentConfig !== prevState.appointmentConfig) {
         return {
            appointmentConfig: nextProps.appointmentConfig,
            appointmentList: nextProps.appointmentConfig.rows,
            filteredAppointmentList: nextProps.appointmentConfig.rows,
            filters: nextProps.appointmentConfig.filters,
            visibleHeader: nextProps.appointmentConfig.visible
         };
      }
      if (nextProps.loading !== prevState.loading) {
         return {
            loading: nextProps.loading
         };
      }
      if (nextProps.accountInfo !== prevState.accountInfo) {
         return {
            accountInfo: nextProps.accountInfo
         };
      }
      return null;

   }

   componentDidUpdate(prevProps, prevState) {
      if (this.state.appointmentConfig !== prevProps.appointmentConfig) {
         this.formatHeader();
         this.formTableBodyData();
         this.filterByAppointmentStatus(this.state.selectedStatus);
      }
   }

   handleChange(datedata) {
      let { date, loading, finalTableData, formatedHeader } = this.state;
      date = datedata;
      loading = true;
      finalTableData = [];
      formatedHeader = null;
      this.setState({
         date, loading, finalTableData, formatedHeader
      }, () => this.props.emitDateValue(datedata));
   }

   getEmitedSelectedFilerValues = (event) => {
      this.setState({ loading: true });
      this.props.emitSelectedFilerValues(event)
   }

   textToHtmlConversion(text) {
      return { __html: text };
   }

   redirectToEditView = (event) => {
      this.props.emitRedirectToEditView('/edit-appointment');
   }

   componentWillUnmount() {
      $('[data-toggle="tooltip"]').tooltip('hide');
   }

   onLinkButtonClicked = (indData) => {
      this.props.emitLinkButtonClickedEvent(indData);
   }

   onCreateInvoiceEvent = (indData) => {
      this.props.emitCreateInvoiceEvent(indData);
   }

   onViewInvoiceEvent = (indData) => {
      this.props.emitViewInvoiceEvent(indData);
   }

   onSmsStatusUpdateEvent = (indData, event) => {
      this.props.emitSmsStatusUpdateEvent(indData);
   }

   onUnorderdViewEvent = (indData, event) => {
      this.props.emitUnorderedViewEvent(indData);
   }

   onAppointmentEditClicked = (indData, event) => {
      let idIndex = this.state.visibleHeader.indexOf("id");
      let id = indData[idIndex];
      this.props.emitRedirectToEditView(`/edit-appointment?id=${id}`);
   }

   onPrescriptionButtonClicked = (indData, event) => {
      let id = indData['id'];
      let patientId = indData['patient_id'];
      this.props.emitPrescriptionClickedEvent(`/prescription-detail?patientId=${patientId}&appointmentId=${id}&type=appointment`, indData);
      this.emitEventOnPrescriptionClick(indData)
   }

   emitEventOnPrescriptionClick(data) {
      const nativeMode = sessionStorage.getItem('nativeMode');
      if (nativeMode === 'true' || nativeMode === true) {
         try {
            let obj = {
               "req": data,
               "action": "view-appointment",
               "path": window.location.href
            }
            window.external.notify(JSON.stringify(obj));
         }
         catch (e) {
            console.log("Reload notify threw error!");
         }
      }
   }

   onAppointmentStatusButtonClicked = (indData, event) => {
      let idData = indData['id'];
      let slot_type = indData['appointment_type'];
      let patient_hid = indData.patient.patient_hid
      this.props.emitAppointmentStatusButtonClicked("/appointment-status?id=" + idData + "&slot_type=" + slot_type + "&patient_hid=" + patient_hid, indData);
   }
   /* code for intent booking keshav */
   onAppointmentIntentButtonClicked = (indData, event) => {
      let { selectedAppointment } = this.state;
      selectedAppointment = indData;
      this.setState({ selectedAppointment }, () => { this.getSlotData(indData['doctor_id'], indData['appointment_date']); });
      /*
        this.setState({
           someState: obj
        }, () => {
           this.afterSetStateFinished();
        });
      */
      //this.setState({ selectedAppointment: indData });

      let accountInfo = sessionStorage.getItem('accountInfo') ? JSON.parse(sessionStorage.getItem('accountInfo')) : '';

      

      let idData = indData['id'];
      let slot_type = indData['appointment_type'];
      let patient_hid = indData.patient.patient_hid

      this.setState({ intentModelOpen: (accountInfo && accountInfo.slots_required) });
      this.setState({ intentModelOpen: true });
      //this.getSlotData(indData['doctor_id'], indData['appointment_date']);
      //this.props.emitAppointmentStatusButtonClicked("/appointment-status?id="+idData+"&slot_type="+slot_type+"&patient_hid="+patient_hid, indData);
   }

   emitIntentCancelEvent = (event, status) => {
      this.setState({ intentModelOpen: false });
   }

   async getSlotData(doctorId, slotDate) {
      
      this.setState({ isSlotLoading: true });
      try {
         let date = Utils.getFormatedDate(slotDate)
         const response = await doctorService.fetchDoctorSlotForAppointment(doctorId, date, this.state.selectedAppointment['appointment_type'], null);
         this.checkForSlotData(response);
      } catch (error) {
         //console.log(error)
         let { isSlotLoading } = this.state;
         isSlotLoading = false;
         this.setState({ isSlotLoading });
      }
   }

   checkForSlotData(response) {
      let { isSlotLoading, slotData, showClock } = this.state;
      if (!response || !response.data) {
         isSlotLoading = false;
         slotData = []
         showClock = true;
         this.setState({ isSlotLoading, slotData, showClock });
         // this.props.enqueueSnackbar('Error Occurred.')
         return;
      }
      switch (response.type) {
         case 'error':
            isSlotLoading = false;
            slotData = [];
            showClock = true;
            this.setState({ isSlotLoading, slotData, showClock });
            break;
         case 'success':
            //
            this.transformSlotData(response.data);
            break;
         default:
            break;
      }
   }

   transformSlotData(data) {
      let { slotData, isSlotLoading, showClock } = this.state;
      if (!data) {
         isSlotLoading = false;
         slotData = [];
         showClock = true;
         this.setState({ isSlotLoading, slotData, showClock });
         return;
      }
      let slotArray = []
      let sequence = ['morning', 'evening', 'afternoon']
      for (let key in data) {

         if (sequence.indexOf(key) !== -1) {
            let indSlotObj = { name: key }
            let index = sequence.indexOf(key)
            indSlotObj["slots"] = data[key]

            slotArray.splice(index, 0, indSlotObj);
         }
      }
      slotData = slotArray;
      isSlotLoading = false;
      showClock = !slotData || slotData.length === 0 || !this.state.showPresetSlots;
      // if (slotData && slotData.length === 0) {
      //     this.props.enqueueSnackbar('No Slots Available!')
      // }
      this.setState({ slotData, isSlotLoading, showClock })
   }
   getEmitedTimeSlotData = (event) => {
      //console.log("event.time",event.formatted);
      let { selectedSlot } = this.state;
      //console.log("selectedSlot",selectedSlot);
      selectedSlot = event.formatted;
      this.setState({ selectedSlot });
      //this.setState({  selectedSlot: event.formatted });
   }

   setApptType = (e) => {
      let { checked, name } = e.target ? e.target : e;
      //console.log(e.target);
      let { selectedAppointment } = this.state;
      selectedAppointment.appointment_type = checked ? name : '';
      this.setState({ selectedAppointment });
      this.getSlotData(selectedAppointment.doctor_id, selectedAppointment.appointment_date);

      //console.log("selectedAppointment",selectedAppointment)
   }
   getEmitedSlotSelectionEvent = (event, slotType) => {
      let { selectedSlot } = this.state;
      selectedSlot = event;
      this.setState({ selectedSlot, slotType });
   }

   onIntentAppointmentCancel = (e) => {
      //console.log("onIntentAppointmentCancel");
      let { intentModelOpen } = this.state;
      //console.log("selectedSlot",selectedSlot);
      intentModelOpen = false;
      this.setState({ intentModelOpen });
      //this.setState({ intentModelOpen: true });
   }

   async onIntentAppointmentSubmit(e) {
      let details = new UAParser().getResult();
      let event = {
         appointment_type: this.state.selectedAppointment.appointment_type,
         appointment_slot: this.state.selectedSlot,
         appointment_status: 'Booked',
         ip_address: this.state.userIpAddress,
         browser_info: {
            browser: details.browser,
            OS: details.os,
            device: details.device,
            cpu: details.cpu,
            engine: details.engine
         }
      }
      var response = await appointmentService.updateAppointment(event, { id: this.state.selectedAppointment.id });
      let { intentModelOpen } = this.state;
      //console.log("selectedSlot",selectedSlot);
      if (response.type == 'success') {
         intentModelOpen = false;
         this.setState({ intentModelOpen });
         //console.log("update response",response);
         this.handleChange(this.state.selectedAppointment.appointment_date)
      }


   }
   /* code for intent booking ends */
   renderFilteredDataFromSearch = (searchStr) => {
      let { finalTableData } = this.state;
      let filteredArr = [];
      const regex = new RegExp(searchStr, 'gi');
      filteredArr = this.state.appointmentList.filter(row => {
         let comparableItems = [row.doctor_name, row.patient ? row.patient.name : '', row.appointment_status, row.appointment_mode, row.patient.phone];
         let retVal = true;
         let matched = [];
         comparableItems.forEach(item => {
            if (typeof (item) === 'string') {
               retVal = item.match(regex);
            }
            if ((retVal && typeof (retVal) === 'object') && retVal.length > 0) {
               matched = retVal;
            }
         })
         if (matched.length > 0) {
            return matched
         }
         return retVal;
      })

      if (this.state.selectedStatus) {
         let status = Appt.appointmentStatusEnum[this.state.selectedStatus];
         if (status !== 'All') {
            filteredArr = filteredArr.filter(e => status === e.appointment_status);
         }
      }
      let type = this.state.isOnlineappointment;
      let data = ''
      if (type != "") {
         data = filteredArr.filter(e => type === e.appointment_type);
      } else {
         data = filteredArr
      }

      finalTableData = data;
      this.setState({
         finalTableData, searchStr
      });
   }

   filterAppointmentByType = () => {
      let type = this.state.isOnlineappointment;
      let data = ''
      if (type != "") {
         data = this.state.appointmentList.filter(e => type === e.appointment_type);
      } else {
         data = this.state.appointmentList
      }
      this.setState({ finalTableData: data });
   }


   filterByAppointmentStatus = (str) => {
      let status = Appt.appointmentStatusEnum[str];
      let data = '';
      if (str === "payment_done") {
         data = this.state.appointmentList.filter(e => "Done" === e.invoice_status);
      }
      else if (str === "payment_pending") {
         data = this.state.appointmentList.filter(e => "Pending" === e.invoice_status);
      }
      else if (status === 'All') {
         data = this.state.appointmentList;
      } else {
         data = this.state.appointmentList.filter(e => status === e.appointment_status);
      }

      if (this.state.searchStr) {
         const regex = new RegExp(this.state.searchStr, 'gi');
         data = data.filter(row => {
            let comparableItems = [row.doctor_name, row.patient ? row.patient.name : '', row.appointment_status, row.appointment_mode];
            let retVal = true;
            let matched = [];
            comparableItems.forEach(item => {
               if (typeof (item) === 'string') {
                  retVal = item.match(regex);
               }
               if ((retVal && typeof (retVal) === 'object') && retVal.length > 0) {
                  matched = retVal;
               }
            })
            if (matched.length > 0) {
               return matched
            }
            return retVal;
         })
      }
      let type = this.state.isOnlineappointment;
      let filData = ''
      if (type != "") {
         filData = data.filter(e => type === e.appointment_type);
      } else {
         filData = data
      }
      this.setState({ selectedStatus: str, finalTableData: filData });
   }






   getCountByStatus(key) {
      let { appointmentList } = this.state;

      if (appointmentList && appointmentList.length) {
         let type = this.state.isOnlineappointment;
         let filData = ''
         if (key === "payment_done") {
            filData = appointmentList.filter(e => "Done" === e.invoice_status);
            return filData.length
         }
         if (key === "payment_pending") {
            filData = appointmentList.filter(e => "Pending" === e.invoice_status);
            return filData.length
         }
         if (type != "") {
            filData = appointmentList.filter(e => type === e.appointment_type);
         } else {
            filData = appointmentList
         }
         if (key === 'all') return filData.length;
         else return filData.filter(r => r.appointment_status === appointmentStatusEnum[key]).length;
      }
      return 0;
   }

   isShootSmsEnabled(indData) {
      let isSMSFeatureEnabled = false;
      if (sessionStorage.getItem('userRole').indexOf("f:sms") !== -1) {
         isSMSFeatureEnabled = true;
      }
      if (!indData) return false;
      let status = indData['appointment_status'].toLowerCase();
      let appointmentDate = new Date(indData['appointment_date']).getTime(), todaysDate = new Date().getTime();
      // if appointment gone in past
      if (todaysDate > appointmentDate && Utils.getDifferenceByDays(todaysDate, appointmentDate) > 2) return false;
      if (!isSMSFeatureEnabled) {
         return false;
      }
      return status && status.indexOf('cancelled') === -1 && status.indexOf('done') === -1 && status.indexOf('re-scheduled') === -1 && status.indexOf('intent') === -1;
   }

   isEditApptEnabled(indData) {
      if (!indData) return false;
      let userRole = sessionStorage.getItem('userRole');
      let status = indData['appointment_status'].toLowerCase();
      if (userRole.indexOf("r:admin") !== -1 && userRole.indexOf("r:staff") !== -1) {
         return true;
      }
      return status && status.indexOf('pending') === -1 && status.indexOf('transferred') === -1 && status.indexOf('cancelled') === -1 && status.indexOf('re-scheduled') === -1;
   }

   isInvoiceEnabled(indData) {
      if (!indData) return false;
      let accountDetails = sessionStorage.getItem('accountInfo');
      let accInfo = accountDetails ? JSON.parse(accountDetails) : null;
      // if (accInfo && accInfo.account_type && accInfo.account_type.toLowerCase() === "hospital"
      // ) {
      //    return false;
      // }
      let status = indData['appointment_status'].toLowerCase();
      return status && status.indexOf('pending') === -1 && status.indexOf('intent') === -1;
   }

   isPrescEnabled(indData) {
      if (!indData) return false;
      let status = indData['appointment_status'].toLowerCase();
      return status && status.indexOf('pending') === -1 && status.indexOf('transferred') === -1 && status.indexOf('cancelled') === -1 && status.indexOf('re-scheduled') === -1 && status.indexOf('intent') === -1;
   }

   isPaymentOrderId(indData) {
      if (!indData) { return false };
      if (sessionStorage.getItem("userRole") && (sessionStorage.getItem('userRole').indexOf("staff") !== -1 && (indData.payment_order_id || indData.room_id))) {
         return true
      }
      return false
   }

   isVideoButtonEnabled(indData) {
      if (!indData) { return false };

      if (sessionStorage.getItem("userRole") && (sessionStorage.getItem('userRole').indexOf("doctor") !== -1 && indData.enable_video)) {
         return true
      } else if (sessionStorage.getItem("userRole") && (sessionStorage.getItem('userRole').indexOf("staff") !== -1 &&
         sessionStorage.getItem('userRole').indexOf("allpres") !== -1 && indData.enable_video)) {
         return true;
      }
      return false
   }

   onRedirectToVideoCall = (data) => {
      sessionStorage.setItem("indData",JSON.stringify(data))
      this.props.goToVideoCall(data);
   }
   goToBookings = () => {
      this.props.goToBookings();
   }

   checkForVideoButtonStyle(indData) {
      let data = sessionStorage.getItem('activePateintRoomIds');
      if (!data) {
         return false;
      }
      data = JSON.parse(data);
      if (data && data.length > 0) {
         return data.indexOf(indData.id) !== -1 ? true : false;
      }
      return false;
      // style={{ fontSize: (this.props.activeRoomIds && this.state.appointmentList[index].id && (this.props.activeRoomIds.indexOf(this.state.appointmentList[index].id) !== -1))?'20px':'' }}
   }

   serviceBookingVisibility() {
      let accountDetails = sessionStorage.getItem('accountInfo');
      let accInfo = accountDetails ? JSON.parse(accountDetails) : null;
      if (accInfo && accInfo.account_type && accInfo.account_type.toLowerCase() === "hospital"
      ) {
         return false;
      }
      return true;
   }

   render() {
      let hidePrescription = sessionStorage.getItem("hideprescription");
      let isBreathefree = sessionStorage.getItem("s3AccountId");
      let role = sessionStorage.getItem('userRole');
      let isOfflineOnlineFeatureEnabled = role ? role.indexOf('onoff') !== -1 : false;
      let isNativeMode = sessionStorage.getItem('nativeMode') && (sessionStorage.getItem('nativeMode') === "true" || sessionStorage.getItem('nativeMode') === true) ? true : false
      const { selectedStatus, finalTableData, isRoleDoctor, isRoleStaff, isOnlineappointment, accountInfo } = this.state;
      const footerInfo = (isRoleDoctor || isRoleStaff) && this.state.accountInfo && this.state.accountInfo.brand_list ? { brand_list: this.state.accountInfo.brand_list } : null;

      return <Appt.MainLayout footer={footerInfo}>
         {/* Screen Layout */}
         <Appt.ScreenLayout>
            <div className="col-12 grid-margin">
               <div className="card cardtable">
                  <div className="card-header header-sm align-items-center p-1">
                     <div className="row justify-content-between">
                        <div className="col mb-0 align-self-center">
                           <div className="badgenumber ">

                              <Appt.FilterTabs selectedStatus={selectedStatus}
                                 filterByAppointmentStatus={this.filterByAppointmentStatus.bind(this)} accountInfo={this.state.accountInfo} getCountByStatus={(key) => this.getCountByStatus(key)} />
                           </div>
                        </div>
                        <View.SearchSelectFilter class="col-md-2 col-lg-2 pr-0"
                           name="isOnlineappointment" value={isOnlineappointment}
                           type="obj" onValueSelection={(e) => this.toggleAppointmentType(e)}
                           options={appointmentType} placeholder=" Appointment Type" />

                        {/* <div className="col-12 col-md-1 m-1 align-self-center">
                           
                           <div className="onoffswitch" >
                              <input type="checkbox" name="onoffswitch" onClick={this.toggleAppointmentType.bind(this)}
                                 className="onoffswitch-checkbox" id="myonoffswitch" tabindex="0" checked={isOnlineappointment} />
                              <label className="onoffswitch-label" for="myonoffswitch">
                                 <span className="onoffswitch-inner"></span>
                                 <span className={`${isOnlineappointment ? 'onoffswitch-switch' : 'offswitch-switch'}`}></span>
                              </label>
                           </div>
                        </div> */}

                        <DateFilter handleChange={this.handleChange.bind(this)} date={this.state.date} />
                        <Appt.SearchFilter class="col-md-2 col-lg-2 pr-0" renderFilteredDataFromSearch={this.renderFilteredDataFromSearch.bind(this)} dataToFilter={this.state.filteredData} />
                        {this.serviceBookingVisibility() &&
                           <div className="col-12 col-md-2 mb-0 align-self-center">
                              <button type="button" className="btn btn-primary btn-rounded btn-sm" onClick={this.goToBookings.bind(this)}>Service Bookings</button>
                           </div>
                        }
                     </div>
                  </div>
                  {/* callInitiateLoading */}
                  {this.props.callInitiateLoading &&
                     <div className="clinytic-line-loader"></div>
                  }
                  <div className="card-body p-0" style={{ display: this.props.listLoading ? 'none' : '', overflowX: 'auto' }}>
                     {(finalTableData && finalTableData.length > 0)
                        ? (
                           <table className="table table-hover table-sm">
                              <thead className="thead-light">

                                 <tr style={{ textTransform: 'capitalize' }}>
                                    <th>Q No</th>
                                    {!isRoleDoctor && <th>Doctor</th>}
                                    <th>Patient</th>
                                    <th>Phone</th>
                                    <th>Time Slot</th>
                                    <th>Checked In</th>
                                    <th>Status</th>
                                    {
                                       accountInfo && accountInfo.enable_opd_flow === true ? <th>Payment Status</th> : ""
                                    }

                                    <th style={{ paddingLeft: '17px' }}>Info</th>
                                    <th>Action</th>
                                 </tr>
                              </thead>
                              <tbody>
                                 {finalTableData &&
                                    <React.Fragment>
                                       {finalTableData.map((indFinalData, index) =>
                                          <tr key={index}>
                                             <td>
                                                <div className={`badge badge-pill ${indFinalData.appointment_mode === 'new' ? 'badge-success badge-rounded' : 'badge-light'} mr-1`}>{indFinalData.appointment_queue_number}</div>
                                             </td>
                                             {!isRoleDoctor &&
                                                <td>{indFinalData.doctor_name ? indFinalData.doctor_name : indFinalData.patient ? indFinalData.patient.doctor_name : '-'}
                                                </td>
                                             }

                                             {isBreathefree === "breathefree"  ||  hidePrescription || !this.isPrescEnabled(indFinalData) ?
                                                <td key={index} className="" title={`${indFinalData.patient_hid ? 'UHID: ' + indFinalData.patient_hid : ''}`}>
                                                   <i className={`mdi mdi-${indFinalData.appointment_type === 'online' ? 'video' : 'clipboard-account'} mr-1`}></i>{indFinalData.patient ? indFinalData.patient.name : '-'}
                                                </td>
                                                : <td key={index} className="text-success pointer-cursor" onClick={this.onPrescriptionButtonClicked.bind(this, indFinalData)}
                                                   title={`${indFinalData.patient_hid ? 'UHID: ' + indFinalData.patient_hid : ''}`}><i className={`mdi mdi-${indFinalData.appointment_type === 'online' ? 'video' : 'clipboard-account'} mr-1`}></i>{indFinalData.patient ? indFinalData.patient.name : '-'}</td>}
                                             <td>{indFinalData.patient && indFinalData.patient.phone ? indFinalData.patient.phone : '-'}
                                             </td>

                                             <td>{indFinalData.appointment_slot}</td>
                                             <td>
                                                {typeof indFinalData.checked_in_time != 'undefined' ? <>
                                                   {Utils.getFormatedTime(indFinalData.checked_in_time * 1000)}
                                                </> : <></>}
                                             </td>
                                             <td><label className={`ml-1 badge ${Appt.ViewStatusClass(indFinalData.appointment_status)} m-0 pt-1`}>{indFinalData.appointment_status}</label></td>

                                             {
                                                accountInfo && accountInfo.enable_opd_flow === true ? <td><label className={`ml-1 badge ${Appt.ViewStatusClass(indFinalData.invoice_status)} m-0 pt-1`}>{indFinalData.invoice_status}</label></td> : ""
                                             }



                                             <td>
                                                <a className="nav-link count-indicator dropdown dropdown-hover" id="patient-info" data-toggle="dropdown">
                                                   <i className="mdi mdi-information mdi-24px text-success"></i>
                                                </a>
                                                <div className="dropdown-menu p-0" aria-labelledby="patient-info">
                                                   <a className="dropdown-item border-bottom pt-2 pb-2" >
                                                      <p className="ellipsis mb-0 mt-0 ">
                                                         Patient Age : <strong className="text-info">{indFinalData.patient ? Utils.calculatePatientAge(indFinalData.patient) : ''}</strong> </p></a>
                                                   <a className="dropdown-item border-bottom pt-2 pb-2" >
                                                      <p className="ellipsis mb-0 mt-0 ">
                                                         Gender : <strong className="text-info">{indFinalData.patient && indFinalData.patient.gender ? indFinalData.patient.gender : '-'}</strong> </p></a>
                                                   {indFinalData.patient && indFinalData.patient.guardian_name && <a className="dropdown-item border-bottom pt-2 pb-2" >
                                                      <p className="ellipsis mb-0 mt-0 ">
                                                         Guardian Name : <strong className="text-info">{indFinalData.patient && indFinalData.patient.guardian_name || '-'}</strong> </p></a>}
                                                   {indFinalData.patient && indFinalData.patient.guardian_phone && <a className="dropdown-item border-bottom pt-2 pb-2" >
                                                      <p className="ellipsis mb-0 mt-0 ">
                                                         Guardian Phone : <strong className="text-info">{indFinalData.patient && indFinalData.patient.guardian_phone || '-'}</strong> </p></a>}
                                                   <a className="dropdown-item border-bottom pt-2 pb-2" >
                                                      <p className="ellipsis mb-0 mt-0 ">
                                                         Hospital ID : <strong className="text-info">{indFinalData.patient && indFinalData.patient.patient_hid ? indFinalData.patient.patient_hid : indFinalData.patient_hid ? indFinalData.patient_hid : '-'}</strong> </p></a>
                                                   <a className="dropdown-item border-bottom pt-2 pb-2" >
                                                      <p className="ellipsis mb-0 mt-0 ">
                                                         Created By : <strong className="text-info">{indFinalData.createdby}</strong> </p></a>
                                                   <a className="dropdown-item border-bottom pt-2 pb-2" >
                                                      <p className="ellipsis mb-0 mt-0">
                                                         Created On : <strong> {indFinalData.created_at}</strong> </p></a>
                                                   {indFinalData && indFinalData.remarks &&
                                                      <a className="dropdown-item border-bottom pt-2 pb-2" >
                                                         <p className="ellipsis mb-0 mt-0 ">
                                                            Remarks : <strong className="text-info">{indFinalData && indFinalData.remarks ? indFinalData.remarks : '-'}</strong> </p></a>
                                                   }
                                                   {
                                                      indFinalData.meta && indFinalData.meta.referring_doctor &&  <a className="dropdown-item border-bottom pt-2 pb-2" >
                                                      <p className="ellipsis mb-0 mt-0">
                                                         Ref Doc : <strong> {indFinalData.meta.referring_doctor ? indFinalData.meta.referring_doctor : indFinalData.meta ? indFinalData.meta.indFinalData.meta.referring_doctor : ''}</strong> </p></a>
                                                   }
                                                   {(indFinalData.referred_doctor_name || (indFinalData.patient && indFinalData.patient.referred_doctor_name)) &&
                                                      <a className="dropdown-item border-bottom pt-2 pb-2" >
                                                         <p className="ellipsis mb-0 mt-0">
                                                            Ref Doc : <strong> {indFinalData.referred_doctor_name ? indFinalData.referred_doctor_name : indFinalData.patient ? indFinalData.patient.referred_doctor_name : ''}</strong> </p></a>}
                                                   {
                                                      isBreathefree !== "breathefree"  ?  <a className="dropdown-item   pt-2 pb-2 ">
                                                      <p className="ellipsis mb-0 mt-0">
                                                         {indFinalData.appointment_mode === 'new' ?
                                                            <> Payment : <strong className="mr-2"><i className="mdi mdi-currency-inr"></i>{indFinalData.invoice_amount ? indFinalData.invoice_amount : '0'}</strong> <span className="badge badge-pill badge-success">New</span></>
                                                            :
                                                            <> Payment : <strong className="mr-2"><i className="mdi mdi-currency-inr"></i>{indFinalData.invoice_amount ? indFinalData.invoice_amount : '0'}</strong> <span className="badge badge-pill badge-light">Followup</span></>
                                                         }
                                                      </p>
                                                   </a> : ""
                                                   }
                                                  
                                                   {indFinalData.old_appointment_date && indFinalData.old_appointment_slot && <a className="dropdown-item border-bottom pt-2 pb-2" >
                                                      <p className="ellipsis mb-0 mt-0">
                                                         {indFinalData.is_rescheduled && <span className="badge badge-pill badge-success">Rescheduled of {indFinalData.old_appointment_date} @ {indFinalData.old_appointment_slot}</span>
                                                            || <span className="badge badge-pill badge-light">Followup of {indFinalData.old_appointment_date} @ {indFinalData.old_appointment_slot}</span>} </p></a>}
                                                   <a className="dropdown-item   pt-2 pb-2 ">
                                                      <p className="ellipsis mb-0 mt-0">
                                                         {indFinalData.appointment_type === 'online' ?
                                                            <> Appt Type : <strong className="mr-2"><i className="mdi mdi-video mr-1"></i> Online Video Call</strong></>
                                                            :
                                                            <> Appt Type : <strong className="mr-2"><i className="mdi mdi-clipboard-account mr-1"></i> Offline Visit</strong></>
                                                         }
                                                      </p>
                                                   </a>
                                                </div>
                                             </td>
                                             <td>

                                                {this.isEditApptEnabled(indFinalData) && <button key={index + 'editappointment'} type="button" title="Edit Appointment" onClick={indFinalData.appointment_status == 'Intent' ? this.onAppointmentIntentButtonClicked.bind(this, indFinalData) : this.onAppointmentStatusButtonClicked.bind(this, indFinalData)}
                                                   className="btn btn-outline-success btn-icon-text  btn-sm">Appt <i
                                                      className="mdi mdi-pencil btn-icon-append"></i></button>}
{
   isBreathefree === "breathefree" ? ""  : <>
     {this.isPrescEnabled(indFinalData) && <button type="button" style={{ display: sessionStorage.getItem("hideprescription") ? 'none' : 'inline-block' }} title="Prescription"
                                                   className="btn btn-outline-success btn-icon-text  btn-sm" onClick={this.onPrescriptionButtonClicked.bind(this, indFinalData)}>Pres <i
                                                      className="mdi mdi-clipboard-text btn-icon-append"></i></button>}

   </>
}
                                              
                                                {this.isShootSmsEnabled(indFinalData) && <button key={index + 'shootsms'} type="button" title="Shoot SMS"
                                                   className="btn btn-outline-success btn-icon-text  btn-sm" onClick={this.onSmsStatusUpdateEvent.bind(this, indFinalData)}>SMS <i
                                                      className="mdi mdi-message-text-outline btn-icon-append"></i></button>
                                                }

                                                {/* <button key={index + 'shootsms'} type="button" title="Shoot SMS"
                                                   className="btn btn-outline-success btn-icon-text  btn-sm" onClick={this.onUnorderdViewEvent.bind(this, indFinalData)}>Unordered List <i
                                                      className="mdi mdi-message-text-outline btn-icon-append"></i></button> */}

                                                {/* this.checkForVideoButtonStyle(indFinalData.is_patient_online) */}
                                                {(this.isVideoButtonEnabled(indFinalData) && !isNativeMode) &&
                                                   <button key={index + 'videocall'} type="button" className="btn btn-outline-success btn-icon-text  btn-sm" onClick={this.onRedirectToVideoCall.bind(this, indFinalData)} style={{
                                                      backgroundColor: indFinalData.is_patient_online ? '#09ce21' : '',
                                                      color: indFinalData.is_patient_online ? 'white' : '',
                                                      borderColor: indFinalData.is_patient_online ? '#09ce21' : ''
                                                   }}>
                                                      Video
                                                      <>
                                                         <i
                                                            className="mdi mdi-video btn-icon-append"></i>
                                                      </>
                                                   </button>
                                                }
                                                {this.isPaymentOrderId(indFinalData) && <button key={index + 'paymentorderid'} type="button" title="Payment And Video link"
                                                   className="btn btn-outline-success btn-icon-text  btn-sm" onClick={this.onLinkButtonClicked.bind(this, indFinalData)}>Links <i
                                                      className="mdi mdi-phone btn-icon-append"></i></button>
                                                }



                                                {
                                                   accountInfo && accountInfo.enable_opd_flow === true ? "" : isBreathefree === "breathefree" ? "" : <Fragment>
                                                      {this.isInvoiceEnabled(indFinalData) &&
                                                         <>
                                                            {(typeof accountInfo.disable_invoice!='undefined'&&accountInfo.disable_invoice==true)?"":
                                                            <>
                                                            <button id="invoice-info" data-toggle="dropdown" key={index + 'createinvoice'} type="button" title="Shoot SMS"
                                                               className="btn btn-outline-success btn-icon-text  btn-sm dropdown dropdown-hover" >Invoice <i
                                                                  className="mdi mdi-chevron-down btn-icon-append"></i>
                                                            </button>
                                                            <div className="dropdown-menu p-0" aria-labelledby="invoice-info">
                                                               <a className="dropdown-item border-bottom pt-2 pb-2" >
                                                                  <p className="ellipsis mb-0 mt-0 " onClick={this.onCreateInvoiceEvent.bind(this, indFinalData)}>
                                                                     <i
                                                                        className="mdi mdi-file-document-box btn-icon-append"></i> Create Invoice
                                                                  </p></a>
                                                               <a className="dropdown-item border-bottom pt-2 pb-2" >
                                                                  <p className="ellipsis mb-0 mt-0 " onClick={this.onViewInvoiceEvent.bind(this, indFinalData)}>
                                                                     <i
                                                                        className="mdi mdi-view-list btn-icon-append"></i> View Invoice
                                                                  </p></a>
                                                            </div>
                                                            </>
                                                         }
                                                         </>

                                                      }
                                                   </Fragment>
                                                }



                                                {/* <button key={index + 'viewinvoice'} type="button" title="Shoot SMS"
                                                   className="btn btn-outline-success btn-icon-text  btn-sm" onClick={this.onSmsStatusUpdateEvent.bind(this, indFinalData)}>View Invoice <i
                                                      className="mdi mdi-message-text-outline btn-icon-append"></i></button> */}
                                             </td>
                                          </tr>
                                       )}
                                    </React.Fragment>
                                 }
                              </tbody>
                           </table>
                           // </div>
                        ) :
                        this.state.loading ? <div className="clinytic-line-loader"></div>
                           : <Appt.NoAppointmentAvailable redirectToEditView={this.redirectToEditView.bind(this)}  isRoleDoctor={isRoleDoctor} enable_opd_flow={accountInfo && accountInfo.enable_opd_flow} />}

                     <div className="dropdown-divider"> </div>
                     {/* pagination view */}
                     <div className="row">
                        <div className="col-md-6"></div>
                     </div>
                  </div>
               </div>
            </div>
            {/**prescription intent model keshav */}
            {this.state.intentModelOpen &&
               <div className={`modal fade ${this.state.intentModelOpen ? 'show' : ''}`} id="timeslotmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                  aria-hidden={`${this.state.intentModelOpen ? true : false}`} style={{
                     display: this.state.intentModelOpen ? 'block' : 'none', paddingRight: this.state.intentModelOpen ? '17px' : '', overflowX: 'hidden', overflowY: 'auto', backgroundColor: '#716e6e54'
                  }}>
                  <div className={`modal-dialog modal-lg`} role="document" style={{ marginTop: 'calc(70px + 0px)' }}>
                     <div className="modal-content bg-white">
                        <div className="modal-header">
                           <h5 className="modal-title" id="exampleModalLabel">Confirm Appointment</h5>
                           <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.emitIntentCancelEvent.bind(this, false)}>
                              <span aria-hidden={true}>×</span>
                           </button>
                        </div>
                        <div className="modal-body " style={{ paddingTop: 0 }}>
                           <small className="text-danger error-text">Required*</small>
                           {isOfflineOnlineFeatureEnabled && <div className="form-group">
                              <div className="form-check">
                                 <label className="form-check-label pointer-cursor d-inline">
                                    <input type="radio" className="form-check-input" id="optionsRadios1" onChange={this.setApptType.bind(this)} name="online" checked={this.state.selectedAppointment['appointment_type'] === 'online'} />Online Video Call <i className="input-helper"></i></label>
                              </div>
                              <div className="form-check">
                                 <label className="form-check-label pointer-cursor d-inline">
                                    <input type="radio" className="form-check-input" id="optionsRadios1" onChange={this.setApptType.bind(this)} name="offline" checked={this.state.selectedAppointment['appointment_type'] === 'offline'} />  Face to Face Visit Doctor <i className="input-helper"></i></label>
                              </div>
                           </div>}
                           {this.state.showPresetSlots && <section className="appointmentslot">
                              <div className="row align-items-center justify-content-end ">
                                 <div className="col-12 col-sm-4 col-md-12">
                                    <h5 className="mb-1" style={{ textAlign: 'center' }}>{new Date(this.state.selectedAppointment.appointment_date).toLocaleDateString([], { year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' })}</h5>
                                    {/* <p className="text-muted pb-1 mb-1 mt-1">Monday</p> */}
                                 </div>

                              </div>
                              {!this.state.isSlotLoading ? <div className="row ">
                                 <div className="col-md-12 ">

                                    <div className="card booking-schedule schedule-widget">
                                       <div className=" card-body schedule-cont p-3" style={{ paddingTop: '0px !important' }}>
                                          <hr style={{ margin: '0px 0px 5px' }} />
                                          {this.state.slotData && this.state.slotData.length > 0 ?
                                             <div className="row timeslot">
                                                {this.state.slotData.map((indSlot, index) =>
                                                   <div className="col-4 col-md-4 border-right" key={index}>
                                                      <h5 style={{ marginTop: 0, textTransform: 'capitalize' }}> {indSlot.name}</h5>
                                                      {indSlot.slots.map((slot, jndex) => (

                                                         <button key={jndex} type="button" className={this.state.selectedSlot === slot ? "btn  btn-sm selected" : "btn btn-outline-success btn-sm"} style={{ marginRight: 5 }} onClick={this.getEmitedSlotSelectionEvent.bind(this, slot, indSlot.name)}>{slot}</button>
                                                      )
                                                      )}
                                                   </div>
                                                )}
                                             </div>
                                             :
                                             !this.state.slotData ? <p className="text-danger m-0" style={{ textAlign: 'center' }}>Error Occurred to find available slots. Please try a different date!</p> : <p className="text-danger m-0" style={{ textAlign: 'center' }}>No available slots! Select a time below.</p>
                                          }

                                       </div>
                                    </div>
                                 </div>
                              </div>
                                 :
                                 <div className="clinytic-line-loader"></div>}
                           </section>}
                           <section>
                              {(this.state.slotData && this.state.showPresetSlots) &&
                                 <>
                                    <hr style={{ margin: '5px 0px 0px' }} />
                                    <p style={{ margin: 0, textAlign: 'center' }}>Or</p>
                                    <hr style={{ margin: '5px 0px 0px' }} />
                                 </>
                              }
                              <div className="card mt-1">
                                 <div className="card-header pointer-cursor text-primary" id="heading-13" onClick={() => this.setState({ showClock: !this.state.showClock })}>
                                    Select Custom Time <i className="mdi mdi-plus" />
                                 </div>
                                 {this.state.showClock && <div className="card-body p-3">
                                    <small className="text-info">*Please confirm with doctor or coordinator before selecting an appointment time here.</small>
                                    <div className="row timeslot mb-3 mt-3" style={{ justifyContent: 'center' }}>
                                       <TimeKeeper
                                          time={this.state.selectedSlot ? this.state.selectedSlot : new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                                          onChange={this.getEmitedTimeSlotData.bind(this)}
                                          style={{ with: '100% !important' }}
                                          switchToMinuteOnHourSelect={true}
                                       />
                                    </div>
                                 </div>}
                              </div>
                           </section>
                        </div>
                        {this.state.isSlotLoading &&
                           <div className="clinytic-line-loader"></div>
                        }
                        <div className="modal-footer">
                           <button type="submit" className="btn btn-success mr-2 btn-rounded" disabled={this.state.isSlotLoading} onClick={this.onIntentAppointmentSubmit.bind(this)}>Submit</button>
                           <button type="button" className="btn btn-light" data-dismiss="modal" onClick={this.onIntentAppointmentCancel.bind(this)}>Cancel</button></div>
                     </div>
                  </div>

               </div >}
            {/* prescription intent model ends*/}
         </Appt.ScreenLayout>

         {/* Mobile Layout */}
         <Appt.MobileLayout>
            <div className="row no-gutters mb-1">
               <div className="col-12 col-md-6 mb-1 ">
                  <div className=" badgenumber  badgenumber d-flex  align-self-center">
                     <Appt.FilterTabs selectedStatus={selectedStatus} filterByAppointmentStatus={this.filterByAppointmentStatus.bind(this)} getCountByStatus={(key) => this.getCountByStatus(key)} />
                  </div>
               </div>
               <DateFilter handleChange={this.handleChange.bind(this)} date={this.state.date} className="col-6" dateFormat="dd-MM-yy" />
               <Appt.SearchFilter renderFilteredDataFromSearch={this.renderFilteredDataFromSearch.bind(this)} dataToFilter={this.state.filteredData} />
            </div>
            {!this.props.listLoading && <>
               {finalTableData && finalTableData.length > 0
                  ?
                  finalTableData.map((indFinalData, index) =>
                     <Appt.MobileIndCardWrapper key={index}>
                        {/* send whole appointment data object*/}
                        <Appt.ApptTableColMobile hidePrescription={hidePrescription} data={indFinalData} isRoleDoctor={this.state.isRoleDoctor} index={index} isPrescEnabled={this.isPrescEnabled(indFinalData)} checkForVideoButtonStyle={this.checkForVideoButtonStyle(indFinalData)}
                           isEditApptEnabled={this.isEditApptEnabled(indFinalData)} isVideoButtonEnabled={this.isVideoButtonEnabled(indFinalData)} onRedirectToVideoCall={this.onRedirectToVideoCall.bind(this, indFinalData)} isShootSmsEnabled={this.isShootSmsEnabled(indFinalData)}
                           enableLinkButton={this.isPaymentOrderId(indFinalData)} onLinkButtonClicked={this.onLinkButtonClicked.bind(this, indFinalData)} onSmsStatusUpdateEvent={this.onSmsStatusUpdateEvent.bind(this, indFinalData)}
                           onPrescriptionButtonClicked={this.onPrescriptionButtonClicked.bind(this, indFinalData)} onAppointmentStatusButtonClicked={this.onAppointmentStatusButtonClicked.bind(this, indFinalData)} />
                     </Appt.MobileIndCardWrapper>
                  )
                  :
                  this.state.loading ? <div className="clinytic-line-loader"></div>
                     : <Appt.NoAppointmentAvailable redirectToEditView={this.redirectToEditView.bind(this)} />}
            </>}
         </Appt.MobileLayout>
         
         { sessionStorage.getItem('s3AccountId') === "breathefree" ?  <a id="settings-trigger" data-toggle="tooltip" data-placement="right" title=""
               data-original-title="Add New Appointment" className="text-white" style={{ cursor: 'pointer' }} onClick={this.redirectToEditView.bind(this)}>Add</a> :
         typeof(accountInfo.disable_add_appointment)!='undefined' && accountInfo.disable_add_appointment?
         '' : 
         ( accountInfo &&   accountInfo.enabled_disabled_features === undefined ?
             <a id="settings-trigger" data-toggle="tooltip" data-placement="right" title=""
               data-original-title="Add New Appointment" className="text-white" style={{ cursor: 'pointer' }} onClick={this.redirectToEditView.bind(this)}>Add</a> : 
               !this.state.isRoleDoctor && accountInfo && <a id="settings-trigger" data-toggle="tooltip" data-placement="right" title=""
                  data-original-title="Add New Appointment" className="text-white" style={{ cursor: 'pointer' }} onClick={this.redirectToEditView.bind(this)}>Add</a>
         )}
      </Appt.MainLayout >
   }
}

export default AppointmentsViewComponent;
