import React, {
    useState, useRef, useEffect
} from 'react';
import { useHistory } from "react-router";


function QuestionFormComponent(props) {



    useEffect(() => {

    }, []);



    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className=" form-group bg-inverse-secondary pt-2 mb-1 ">
                        <label for="exampleInputPassword1">Ask question*</label>
                        <input type="text" className="form-control form-control-lg" placeholder="Vinal fever in last 2 weeks" name="question" value={props.formData.question} onChange={(e) => props.onValueChange(e)} />
                    </div>
                </div>
                <div className="col-12">
                    <div className=" form-group bg-inverse-secondary pt-2 mb-1 ">
                        <label for="exampleInputPassword1">Options* (Enter multiple options using comma i.e.,: yes,no,maybe)</label>
                        <input type="text" className="form-control form-control-lg" placeholder="yes,no,maybe" name="options" value={props.formData.options} onChange={(e) => props.onValueChange(e)} onBlur={(e) => props.onOutOfFocusEvent()} />
                    </div>

                </div>
                <div className="col-12"> <div className=" form-group bg-inverse-secondary pt-2 mb-1 ">
                    <label for="selectgender"> Select Answer*</label>
                    <select className="form-control form-control-lg" id="selectgender" name="exp_answer" value={props.formData.exp_answer} onChange={(e) => props.onValueChange(e)}>
                    <option value=''>Select Answer</option>
                        {props.optionsList && props.optionsList.map((indOption, i) =>
                            <option key={i} value={indOption}>{indOption}</option>
                        )}
                    </select>
                </div>
                </div>
                <div className="col-12 mb-4">
                    <hr />
                    <button type="submit" className="btn btn-success mr-2" disabled={!props.formData.question || !props.formData.options || !props.formData.exp_answer} onClick={() => props.onAddQuestion()}>Add</button>
                    <button className="btn btn-light" onClick={(e) => props.onClearFormValues()}>Clear</button>
                </div>
            </div>
        </>
    )
}

export default QuestionFormComponent;
