import React from "react";
import ValidationComponent from "./ValidationComponent";
import prescriptionService from "../../../Services/prescription.service";
import Select from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
class AutocompleteComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            focused: false
        }
    }

    handleInputChange = (newValue) => {
        if (!newValue) {
            return;
        }
        if (this.state.focused) {
            if (!this.props.multiselect) this.props.emitTextboxValue({ name: this.props.name, value: newValue }); //used for autosuggestion, else not needed
            return newValue;
        }
    }

    onFocus = () => {
        let { focused } = this.state;
        focused = true;
        this.setState({ focused }, () => this.props.emitFocusEvent());
    }

    onBlur = () => {
        let { focused } = this.state;
        focused = false;
        this.setState({ focused });
    }

    async asyncSelectloadAutocompleteList(str) {
        if (str && str.length < 3) {
            return [{ value: 'initial', label: 'Start typing...', isDisabled: true }];
        }
        let key = this.props.autoKey;
        try {
            const allowedTypes = ['patient', 'symptoms', 'disease', 'diagnosis', 'drugs', 'vitals', 'biomarkers', 'investigations'];
            let matchingTypeIdx = allowedTypes.findIndex(t => t.includes(key.toLowerCase()) || key.toLowerCase().includes(t));
            let allowedType = allowedTypes[matchingTypeIdx];
            if (matchingTypeIdx === -1) {
                if (('test required').includes(key.toLowerCase()) || key.toLowerCase().includes('test required')) {
                    allowedType = 'labtest';
                } else if (('complaint').includes(key.toLowerCase()) || key.toLowerCase().includes('complaint')) {
                    allowedType = 'symptoms';
                }
            }
            let res = await prescriptionService.fetchSuggestions(allowedType, 'suggestions', str);
            if (res && res.type === 'success' && res.data && res.data.result && res.data.result.length) {
                let options = res.data.result.map(v => ({ label: v, value: v }));
                return options;
            } else {
                return [{ value: 'initial', label: 'No results', isDisabled: true }];
            }
        } catch (err) {
            return [{ value: 'initial', label: 'No results', isDisabled: true }];
        }
    }

    handleChange = checkedList => {
        let str = checkedList ? Array.isArray(checkedList) ? checkedList.length ? checkedList.map(l => l.label).join(',') : '' : checkedList.label : '';
        this.props.emitTextboxValue({ name: this.props.name, value: str });
    };

    setErrorStatus = (errorStatus) => {
        this.props.emitErrorStatus(errorStatus);
    }

    checkForLabelValue(str) {
        if (!str || !str.trim()) {
            return false;
        }
        return true
    }

    loadOptions = (inputValue, callback) => {
        this.props.emitLoadOptionsEvent(inputValue, callback);
        // this.asyncSelectloadAutocompleteList(inputValue).then((options) => callback(options));
    };

    render() {
        // console.log('-----------------------------------textarea---------------------------------');
        const { autoKey, name, options, classname, value, placeholder, label, multiselect, labelSize, size, offset, rowType } = this.props;
        return (
            // .2rem 1rem // 5px 0px
            <React.Fragment>
                {label && labelSize &&
                    <label className={`col-${labelSize}`} style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}> {label}</label>
                }
                <div className={`col-lg-${size}`} style={{ padding: rowType === "row" ? '' : '', margin: rowType === "row" ? '' : '' }}>
                    {options &&
                        <>   {JSON.stringify(options)}</>
                    }

                    {label && !labelSize &&
                        <label style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}> {label}</label>
                    }
                    <AsyncCreatableSelect
                        cacheOptions
                        placeholder={placeholder}
                        styles={{
                            menuPortal: base => {
                                const { zIndex, ...rest } = base;
                                return { ...rest, zIndex: 9999, textTransform: "uppercase" };
                            },
                            control: (base, state) => ({
                                ...base,
                                border: "1px solid #ebedf2",
                                fontFamily: "'Montserrat', sans-serif",
                                fontSize: "0.8125rem",
                                background: "#gray",

                            }),
                        }}
                        isMulti={multiselect}
                        onFocus={this.onFocus.bind(this)}
                        onBlur={this.onBlur.bind(this)}
                        value={value ? value.split(',').map(v => ({ value: v, label: v })) : ''}
                        menuPortalTarget={document.body}
                        loadOptions={this.loadOptions.bind(this)}
                        defaultOptions={options ? options : [{ value: 'initial', label: 'Start typing..', isDisabled: true }]}
                        onInputChange={this.handleInputChange}
                        onChange={this.handleChange.bind(this)}
                    />
                    {this.props.validation && <ValidationComponent value={value} checkForm={this.props.checkForm} isDirty={this.props.isDirty} validation={this.props.validation} emitErrorStatus={this.setErrorStatus.bind(this)} />}
                </div>
                {offset &&
                    <div className={offset}> </div>
                }
            </React.Fragment>
        );
    }
}

export default AutocompleteComponent;
