import React from "react";
import UserListViewComponent from './View/UserListViewComponent';

class UserComponent extends React.Component {

    getEmitedNewUserCreationEvent = (event) => {
        this.props.emitNewUserCreationEvent(event);
    }

    getEmitedSelectedFilerValues = (event) => {
        this.props.emitSelectedFilerValues(event)
    }

    getEmitedUserDeleteEvent = (event) => {
        this.props.emitUserDeleteEvent(event);
    }

    getEmitedResetPasswordEvent = (event) => {
        this.props.emitResetPasswordEvent(event);
    }

    getEmitedNextButtonEvent = (event) => {
        this.props.emitNextButtonEvent(event);
    }

    getEmitedRedirectToView = (event) => {
        this.props.emitRedirectToView(event);
    }

    getEmitedUserEditEvent = (id, data) => {
        this.props.emitUserEditEvent(id, data);
    }

    render() {
        return (
                <UserListViewComponent {...this.props} userList={this.props.userList}
                    loading={this.props.loading}
                    isDeleteInProgress={this.props.isDeleteInProgress}
                    emitUserEditEvent={this.getEmitedUserEditEvent.bind(this)}
                    emitNewUserCreationEvent={this.getEmitedNewUserCreationEvent.bind(this)}
                    emitSelectedFilerValues={this.getEmitedSelectedFilerValues.bind(this)}
                    emitUserDeleteEvent={this.getEmitedUserDeleteEvent.bind(this)}
                    emitResetPasswordEvent={this.getEmitedResetPasswordEvent.bind(this)}
                    emitNextButtonEvent={this.getEmitedNextButtonEvent.bind(this)}
                    emitRedirectToView={this.getEmitedRedirectToView.bind(this)}></UserListViewComponent>
        );
    }
}

export default UserComponent;
