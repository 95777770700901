import React, { Component } from 'react';
import AudioVisualiser from './AudioVisualiser';

class AudioAnalyser extends Component {
  constructor(props) {
    super(props);
    this.state = { audioData: new Uint8Array(0) };
    this.tick = this.tick.bind(this);
  }

  componentDidMount() {
    this.audioContext = new (window.AudioContext ||
      window.webkitAudioContext)();
    this.analyser = this.audioContext.createAnalyser();
    this.analyser.smoothingTimeConstant = 0.4;
    this.analyser.fftSize = 512;
    
    this.source = this.audioContext.createMediaStreamSource(this.props.audio);
    this.source.connect(this.analyser);
    this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    this.rafId = requestAnimationFrame(this.tick); 
  }

  tick() {
     this.analyser.getByteFrequencyData(this.dataArray);
    this.setState({ dataArray: this.dataArray });
    this.rafId = requestAnimationFrame(this.tick);
  }

  render() {
    return <div>{this.state.dataArray ?<AudioVisualiser sampleArray={this.state.dataArray} size={this.props.size}/>:''}</div>
  }
}

export default AudioAnalyser;