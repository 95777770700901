import React from "react";
import { S3UPLOAD_KEY_NEW } from '../../Constants/s3Key';
import Spinner from "../Spinner/Spinner";
import { Storage } from 'aws-amplify';
import SweetAlertComponent from '../Alert/SweetAlertComponent';
import { ToastContainer, toast } from 'react-toastify';
import accountService from '../../Services/account.settings';
import "react-datepicker/dist/react-datepicker.css";
import prescriptionService from '../../Services/prescription.service';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import Papa from 'papaparse';
import { isThisHour } from "date-fns/esm";

class TranslationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            english: '',
            nepali: '',
            kannada: '',
            telugu: '',
            malayalam: '',
            gujarati: '',
            marathi: '',
            tamil: '',
            bengali: '',
            urdu: '',
            punjabi: '',
            oriya: '',
            arabic: '',
            // assamese: ''
        }
    }

    componentDidMount() {

    }

    onTextboxValueChange = (event) => {
        const { name, value } = event.target
        // this.setState({
        //     [name]: value
        // }, () => {
        //     setTimeout(() => {
        this.props.emitTextboxValueChange(name, value)
        //     }, 100)
        // })
    }

    onSaveEvent = () => {
        let { english, nepali, kannada, malayalam, gujarati, marathi, tamil, bengali, urdu, punjabi, oriya, arabic, telugu } = this.props;
        if (!this.props.isDirty) {
            return;
        }
        // let data = {
        //     "records": [
        //         {
        //             "text": english,
        //             "nepali": nepali,
        //             "tamil": tamil,
        //             "telugu": telugu,
        //             "kannada": kannada,
        //             "malayalam": malayalam,
        //             "gujarati": gujarati,
        //             "marathi": marathi,
        //             "bengali": bengali,
        //             "urdu": urdu,
        //             "punjabi": punjabi,
        //             "oriya": oriya,
        //             "arabic": arabic,
        //             // "assamese": assamese
        //         }],
        //     "lang": "english"
        // }
        this.props.emitSaveEventData();
    }

    onCategoryChangeEvent = (event) => {
        let { name, value } = event.target;
        this.props.emitCategoryChangeEvent(value);
    }

    onClose = (event) => {
        this.props.emitOnCloseClickEvent(false);
    }

    render() {
        let { english, nepali, kannada, malayalam, gujarati, marathi, tamil, bengali, urdu, punjabi, oriya, arabic, telugu, category, accountInfo } = this.props;
        return (
            <>
                <div className="row p-2">
                    {accountInfo && accountInfo.translation_categories && accountInfo.translation_categories.length &&
                        <div className="col-lg-4 col-sm-12">
                            <div className="form-group  mb-3">
                                <label for="exampleInputUsername1">Category</label>
                                <select className="form-control" name="category" value={category} id="exampleSelectGender" onChange={this.onCategoryChangeEvent.bind(this)}>
                                    <option value="">Select Category</option>
                                    {accountInfo.translation_categories.map((indValue, i) =>
                                        <option key={i} value={indValue}>{indValue}</option>
                                    )}
                                </select>
                            </div>
                        </div>
                    }
                </div>
                <div className="row p-2">
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className=" col-lg-3 col-form-label">English</label>
                            <div className="col-lg-8">
                                <input autocomplete="nope" type="text" placeholder="" className="form-control" name="english" value={english} onChange={this.onTextboxValueChange.bind(this)} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className=" col-lg-3 col-form-label">nepali</label>
                            <div className="col-lg-8">
                                <input autocomplete="nope" type="text" placeholder="" className="form-control" name="nepali" value={nepali} onChange={this.onTextboxValueChange.bind(this)} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className=" col-lg-3 col-form-label">Kannada</label>
                            <div className="col-lg-8">
                                <input autocomplete="nope" type="text" placeholder="" className="form-control" name="kannada" value={kannada} onChange={this.onTextboxValueChange.bind(this)} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className=" col-lg-3 col-form-label">Telugu</label>
                            <div className="col-lg-8">
                                <input autocomplete="nope" type="text" placeholder="" className="form-control" name="telugu" value={telugu} onChange={this.onTextboxValueChange.bind(this)} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className=" col-lg-3 col-form-label">Malayalam</label>
                            <div className="col-lg-8">
                                <input autocomplete="nope" type="text" placeholder="" className="form-control" name="malayalam" value={malayalam} onChange={this.onTextboxValueChange.bind(this)} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className=" col-lg-3 col-form-label">Gujarati</label>
                            <div className="col-lg-8">
                                <input autocomplete="nope" type="text" placeholder="" className="form-control" name="gujarati" value={gujarati} onChange={this.onTextboxValueChange.bind(this)} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className=" col-lg-3 col-form-label">Marathi</label>
                            <div className="col-lg-8">
                                <input autocomplete="nope" type="text" placeholder="" className="form-control" name="marathi" value={marathi} onChange={this.onTextboxValueChange.bind(this)} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className=" col-lg-3 col-form-label">Tamil</label>
                            <div className="col-lg-8">
                                <input autocomplete="nope" type="text" placeholder="" className="form-control" name="tamil" value={tamil} onChange={this.onTextboxValueChange.bind(this)} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className=" col-lg-3 col-form-label">Bengali</label>
                            <div className="col-lg-8">
                                <input autocomplete="nope" type="text" placeholder="" className="form-control" name="bengali" value={bengali} onChange={this.onTextboxValueChange.bind(this)} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className=" col-lg-3 col-form-label">Urdu</label>
                            <div className="col-lg-8">
                                <input autocomplete="nope" type="text" placeholder="" className="form-control" name="urdu" value={urdu} onChange={this.onTextboxValueChange.bind(this)} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className=" col-lg-3 col-form-label">Punjabi</label>
                            <div className="col-lg-8">
                                <input autocomplete="nope" type="text" placeholder="" className="form-control" name="punjabi" value={punjabi} onChange={this.onTextboxValueChange.bind(this)} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className=" col-lg-3 col-form-label">Oriya</label>
                            <div className="col-lg-8">
                                <input autocomplete="nope" type="text" placeholder="" className="form-control" name="oriya" value={oriya} onChange={this.onTextboxValueChange.bind(this)} />
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className=" col-lg-3 col-form-label">Arabic</label>
                            <div className="col-lg-8">
                                <input autocomplete="nope" type="text" placeholder="" className="form-control" name="arabic" value={arabic} onChange={this.onTextboxValueChange.bind(this)} />
                            </div>
                        </div>
                    </div>

                    {/* <div className="col-md-6">
                    <div className="form-group row">
                        <label className=" col-lg-3 col-form-label">Assamese</label>
                        <div className="col-lg-8">
                            <input autocomplete="nope" type="text" placeholder="" className="form-control" name="assamese" value={assamese} onChange={this.onTextboxValueChange.bind(this)} />
                        </div>
                    </div>
                </div> */}
                    <div className="col-12">
                        <div className="modal-footer">
                            <button type="submit" className="btn btn-success mr-2 btn-rounded" onClick={this.onSaveEvent.bind(this)}>Submit</button>
                            <button type="button" className="btn btn-light" data-dismiss="modal" onClick={this.onClose.bind(this)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </>

        )
    }
}

export default TranslationForm;

