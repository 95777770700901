import request from '../Api/request';
import userAuth from '../Authentication/authActions';
import Utils from "../Shared/utils/utils";

import {
    UAParser
} from 'ua-parser-js';


function getPrescriptionListData(date) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://vswli5erc5.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://ird52191oa.execute-api.ap-south-1.amazonaws.com/`
    }
    return request({
        baseURL: api,
        url: apiStage + "/prescription/date/" + date,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');

}

function getRxAnalysisCountData(type, fromDate, toDate, param) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/analytics` : `https://emrapi.hlthclub.in/analytics`;
    } else {
        api = `https://betaemrapi.hlthclub.in/analytics`;
    }
    let url = `/prescription/qc/${type}?from_date=${fromDate}&to_date=${toDate}`
    if (param) {
        url += `&account_id=${param}`
    }
    return request({
        baseURL: api,
        url: url,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function postLogs_patient(payload) {
    let details = new UAParser().getResult();
    let connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection;
    let apiStage = sessionStorage.getItem('apiStage');

    payload = {
        ...payload,
        device_info: {
            network_type: connection && connection.effectiveType ? connection.effectiveType : '',
            browserInfo: {
                browser: details.browser,
                OS: details.os,
                device: details.device,
                cpu: details.cpu,
                engine: details.engine
            }
        }
    }
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/onlinepatient` : `https://emrapi.hlthclub.in/onlinepatient`;
    } else {
        api = `https://betaemrapi.hlthclub.in/onlinepatient`;
    }
    return request({
        baseURL: api,
        url: '/onlinepatient/debug',
        method: 'post',
        data: payload
    });
}

function postLogs_doctor(payload) {
    let apiStage = sessionStorage.getItem('apiStage');
    let details = new UAParser().getResult();
    let connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection;
    payload = {
        ...payload,
        device_info: {
            network_type: connection && connection.effectiveType ? connection.effectiveType : '',
            browserInfo: {
                browser: details.browser,
                OS: details.os,
                device: details.device,
                cpu: details.cpu,
                engine: details.engine
            }
        }
    }
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/onlinepatient` : `https://emrapi.hlthclub.in/onlinepatient`;
    } else {
        api = `https://betaemrapi.hlthclub.in/onlinepatient`;
    }
    return request({
        baseURL: api,
        url: '/onlinepatient/debug_doc',
        method: 'post',
        data: payload,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function getApptSummaryDataForAdmin(type, date, param) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/analytics` : `https://emrapi.hlthclub.in/analytics`;
    } else {
        api = `https://betaemrapi.hlthclub.in/analytics`;
    }
    let url = `/prescription/qc/${type}?date=${date}`
    return request({
        baseURL: api,
        url: url,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function getApptSummaryReport(type, todate, fromDate, isDate) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/analytics` : `https://emrapi.hlthclub.in/analytics`;
    } else {
        api = `https://betaemrapi.hlthclub.in/analytics`;
    }
    let filter = ''
    let dateValue = fromDate;
    if (isDate) {
        filter = `?from_date=${fromDate}&to_date=${todate}&filter_by=date`;
    } else {
        dateValue = Utils.getFormatedDate(dateValue);
        let month = Utils.formatDateValueToMonthAndYear(dateValue);
        filter = `?month=${month}&filter_by=month`;
    }
    let url = `/appt-summary/report${filter}&report_type=${type}`;
    return request({
        baseURL: api,
        url: url,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}


function getApptSummaryData(type, date, param, isDate) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/analytics` : `https://emrapi.hlthclub.in/analytics`;
    } else {
        api = `https://betaemrapi.hlthclub.in/analytics`;
    }
    let filter = ''
    if (isDate) {
        filter = `?from_date=${date}&to_date=${isDate}`;
    } else {
        filter = `?date=${date}`;
    }
    let url = `/prescription/qc/${type}${filter}`
    if (param) {
        url += `&account_id=${param}`
    }
    return request({
        baseURL: api,
        url: url,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function getChatRoomPrescriptionApi() {
    let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1 || window.location.hostname.indexOf('d32uc620y3be9') !== -1 || window.location.hostname.indexOf('v4abbottclinytics') !== -1) ? 'dev' : 'prod';

    if (apiStage === "prod") {
        return `https://zdts7eoaql.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        return `https://z1x14eblxg.execute-api.ap-south-1.amazonaws.com/`
    }
}

function getUploadedPatientPrescriptionForChatRoom(data, doctor_upload) {
    let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1 || window.location.hostname.indexOf('d32uc620y3be9') !== -1 || window.location.hostname.indexOf('v4abbottclinytics') !== -1) ? 'dev' : 'prod';
    let api;
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/prescription` : `https://emrapi.hlthclub.in/prescription`;
    } else {
        api = `https://betaemrapi.hlthclub.in/prescription`
    }
    return request({
        baseURL: api,
        // url: apiStage + "/prescription/patient/uploads" + (doctor_upload && '?doctor_upload=true' || ''),
        url: "/prescription/patient/uploads",
        method: 'post',
        data: data,
    }, 'appointment');
}

function fetchPrescriptionGenerateUploadUrl(data) {
    // prescription/generate/upload
    let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1 || window.location.hostname.indexOf('d32uc620y3be9') !== -1 || window.location.hostname.indexOf('v4abbottclinytics') !== -1) ? 'dev' : 'prod';
    let api;
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/prescription` : `https://emrapi.hlthclub.in/prescription`;
    } else {
        api = `https://betaemrapi.hlthclub.in/prescription`
    }
    return request({
        baseURL: api,
        url: "/prescription/generate/upload",
        method: 'post',
        data: data,
    }, 'appointment');
}

function uploadPatientPrescriptionFromChatRoom(data) {
    let apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1 || window.location.hostname.indexOf('d32uc620y3be9') !== -1 || window.location.hostname.indexOf('v4abbottclinytics') !== -1) ? 'dev' : 'prod';
    let api;
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/prescription` : `https://emrapi.hlthclub.in/prescription`;
    } else {
        api = `https://betaemrapi.hlthclub.in/prescription`
    }
    return request({
        baseURL: api,
        url: "/prescription/online/patient",
        method: 'post',
        data: data,
    }, 'appointment');
}

function uploadPrescriptionImage(data) {
    // this
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPrescriptionApiStageUrl();

    return request({
        baseURL: api,
        url: "/prescription",
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getPrescriptionApiStageUrl() {
    let apiStage = sessionStorage.getItem('apiStage');

    if (apiStage === "prod") {
        return Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/prescription` : `https://emrapi.hlthclub.in/prescription`;
    } else {
        return `https://betaemrapi.hlthclub.in/prescription`
    }
}

function getPrescriptionApiStageUrl_analytics() {
    let apiStage = sessionStorage.getItem('apiStage');

    if (apiStage === "prod") {
        return Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/analytics` : `https://emrapi.hlthclub.in/analytics`;
    } else {
        return `https://betaemrapi.hlthclub.in/analytics`
    }
}

function getDateWisePrescReport(from, to) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let api = getPrescriptionApiStageUrl_analytics();
    return request({
        baseURL: api,
        url: `/prescription/pres/report?from_date=${from}&to_date=${to}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getDoctorWisePrescReport(from, to) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let api = getPrescriptionApiStageUrl_analytics();
    return request({
        baseURL: api,
        url: `/prescription/doctor/report?from_date=${from}&to_date=${to}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getLabReportXlsData(prescription_id, email) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = ''
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/labreport` : `https://emrapi.hlthclub.in/labreport`;
    } else {
        api = `https://betaemrapi.hlthclub.in/labreport`
    }

    return request({
        baseURL: api,
        url: `/get-data-xls?id=${prescription_id}&email=${email}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getMiniTemplate() {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://rc2k7qw4qk.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://gnunqbtpv5.execute-api.ap-south-1.amazonaws.com/`
    }

    return request({
        baseURL: api,
        url: apiStage + `/template/fetch_mini_template`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function saveMiniTemplate(data, template_id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://rc2k7qw4qk.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://gnunqbtpv5.execute-api.ap-south-1.amazonaws.com/`
    }

    // https://gnunqbtpv5.execute-api.ap-south-1.amazonaws.com/dev/
    let endUrl = '';

    if (template_id) {
        endUrl = `/template/update_mini_template/${template_id}`
    } else {
        endUrl = '/template/save_mini_template'
    }

    return request({
        baseURL: api,
        url: apiStage + endUrl,
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function createPrescription(data) {
    // this
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPrescriptionApiStageUrl();
    return request({
        baseURL: api,
        url: "/prescription",
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function updatePrescription(data, id) {
    // this
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPrescriptionApiStageUrl();
    // if (apiStage === "prod") {
    //     api = `https://zdts7eoaql.execute-api.ap-south-1.amazonaws.com/`;
    // } else {
    //     api = `https://z1x14eblxg.execute-api.ap-south-1.amazonaws.com/`
    // }

    return request({
        baseURL: api,
        url: "/prescription/" + id,
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

// function serialize(obj) {
//     var str = [];
//     for (var p in obj)
//       if (obj.hasOwnProperty(p)) {
//         str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
//       }
//     return str.join("&");
//   }

function getPatientApiStageUrl() {
    let apiStage = sessionStorage.getItem('apiStage');
    if (apiStage === "prod") {
        return Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/patient` : `https://emrapi.hlthclub.in/patient`;
    } else {
        return `https://betaemrapi.hlthclub.in/patient`
    }
}

function getNewPatientApiStageUrl() {
    return userAuth.getNewPatientApiUrl();
}

function getPatientById(id, account_id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPatientApiStageUrl();

    return request({
        baseURL: api,
        url: "/patient/" + id + "?account_id=" + account_id,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getPatientPrescriptions(patient_id, file_type, lastEvaluatedKey, currentPrescriptionIndex, prescriptionListData) {
    // this
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPrescriptionApiStageUrl();
    if (lastEvaluatedKey) {
        if (currentPrescriptionIndex === (prescriptionListData.length - 1)) {
            let url = file_type !== 'patient_file' ? "/prescriptions/patient_id/" + patient_id + "?file_type=" + file_type + "&last_evaluatedKey=" + encodeURI(JSON.stringify(lastEvaluatedKey)) + "&doctor_upload=true" :
                "/prescriptions/patient_id/" + patient_id + "?patient_upload=true" + "&last_evaluatedKey=" + encodeURI(JSON.stringify(lastEvaluatedKey)) + "&doctor_upload=true"
            return request({
                baseURL: api,
                url: url,
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + userAuth.getToken()
                }
            }, 'appointment');
        }
    } else {
        let url = file_type !== 'patient_file' ? "/prescriptions/patient_id/" + patient_id + "?file_type=" + file_type + "&doctor_upload=true" :
            "/prescriptions/patient_id/" + patient_id + "?patient_upload=true"
        return request({
            baseURL: api,
            url: url,
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    }
}

function fetchCaseHistoryData(patient_id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPrescriptionApiStageUrl();
    let url = "/prescriptions/patient_id/" + patient_id + "?patient_upload=true&case_history=true"
    return request({
        baseURL: api,
        url: url,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getPatientPrescriptionsByPatientHid(patient_hid, file_type) {
    let apiStage = sessionStorage.getItem('apiStage');
    // this
    let api = getPrescriptionApiStageUrl();
    // if (apiStage === "prod") {
    //     api = `https://zdts7eoaql.execute-api.ap-south-1.amazonaws.com/`;
    // } else {
    //     api = `https://z1x14eblxg.execute-api.ap-south-1.amazonaws.com/`
    // }
    if (patient_hid === 'anonymous') {
        return request({
            baseURL: api,
            url: "/prescriptions/patient_id/" + patient_hid + "?file_type=" + file_type,
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    } else {
        return request({
            baseURL: api,
            url: "/prescriptions/patient_hid/" + patient_hid + "?file_type=" + file_type,
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + userAuth.getToken()
            }
        }, 'appointment');
    }

}

function getDoctorPrescriptionForaDate(doctor_id, prescription_date) {
    let apiStage = sessionStorage.getItem('apiStage');
    // this
    let api = getPrescriptionApiStageUrl();
    // if (apiStage === "prod") {
    //     api = `https://zdts7eoaql.execute-api.ap-south-1.amazonaws.com/`;
    // } else {
    //     api = `https://z1x14eblxg.execute-api.ap-south-1.amazonaws.com/`
    // }
    return request({
        baseURL: api,
        url: "/prescriptions/patient_id/anonymous?file_type=prescription",
        // apiStage + "/prescriptions/doctor_id/" + doctor_id + "/prescription_date/" + prescription_date,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

// https://1r311zmywf.execute-api.ap-south-1.amazonaws.com/dev/tagsettings

function fetchTagsSettings() {
    // let apiStage = sessionStorage.getItem('apiStage');
    var hostName = window.location.hostname;
    let api = (hostName.indexOf('localhost') !== -1 || hostName.indexOf('beta') !== -1) ? 'https://1r311zmywf.execute-api.ap-south-1.amazonaws.com/dev' : 'https://qtdi0ixu13.execute-api.ap-south-1.amazonaws.com/prod';

    // https://f5qae3vz1d.execute-api.ap-south-1.amazonaws.com/staging
    // if (apiStage === "prod") {
    //     api = `https://f5qae3vz1d.execute-api.ap-south-1.amazonaws.com/`;
    // } else {
    //     api = `https://f5qae3vz1d.execute-api.ap-south-1.amazonaws.com/`;
    // }

    // https: //f5qae3vz1d.execute-api.ap-south-1.amazonaws.com/staging/tagsettings
    return request({
        baseURL: api,
        url: "/tagsettings",
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function savePrescriptionTagsData(data, isRetag) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://qtdi0ixu13.execute-api.ap-south-1.amazonaws.com/prod`;
    } else {
        api = `https://1r311zmywf.execute-api.ap-south-1.amazonaws.com/dev`;
    }

    return request({
        baseURL: api,
        url: isRetag ? "/retag" : "/tags",
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function recognizeInk(data) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://qtdi0ixu13.execute-api.ap-south-1.amazonaws.com/prod`;
    } else {
        api = `https://1r311zmywf.execute-api.ap-south-1.amazonaws.com/dev`;
    }
    return request({
        baseURL: api,
        url: "/reRecognise",
        method: 'post',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getPrescriptionByPrescriptionId(id) {
    // this
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPrescriptionApiStageUrl();

    return request({
        baseURL: api,
        url: `/prescription/${id}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function linkPrescriptionWithPatient(data, linkPrescriptionConfig) {

    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPrescriptionApiStageUrl();

    let finalUrl = ''

    if (linkPrescriptionConfig.id) {
        finalUrl = `/prescription/${linkPrescriptionConfig.id}/link-patient`;
    } else {
        finalUrl = `/prescription/${linkPrescriptionConfig.accountIdPatientId}:${linkPrescriptionConfig.prescriptionId}/link-patient`
    }

    // https: //f5qae3vz1d.execute-api.ap-south-1.amazonaws.com/staging/tagsettings
    // ${sessionStorage.getItem('s3AccountId')}
    return request({
        baseURL: api,
        //         accountIdPatientId: "demo_account:anonymous"
        // prescriptionId: "157114255204d2d270"
        url: finalUrl,
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}


// https://9jwwgz0y1i.execute-api.ap-south-1.amazonaws.com/dev/user/templates/{template_type}
function onDeletePrescriptionAction(id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPrescriptionApiStageUrl();

    return request({
        baseURL: api,
        url: `/prescription/${id}`,
        method: 'delete',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getPrescriptionTemplateByTemplateType(templatetype, accountId, prescriptionType) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/admin` : `https://emrapi.hlthclub.in/admin`;
    } else {
        api = `https://betaemrapi.hlthclub.in/admin`;
    }
    return request({
        baseURL: api,
        //         accountIdPatientId: "demo_account:anonymous"
        // prescriptionId: "157114255204d2d270"
        url: `/super-user/templates/${accountId}/${prescriptionType}`,
        method: 'get',
        // ${templatetype}
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function updatePrescriptionTemplateByTemplateType(data, settinglevel, sortId) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/admin` : `https://emrapi.hlthclub.in/admin`;
    } else {
        api = `https://betaemrapi.hlthclub.in/admin`;
    }
    return request({
        baseURL: api,
        // accountIdPatientId: "demo_account:anonymous"
        // prescriptionId: "157114255204d2d270"
        // drmanishemr ===== is account-id
        url: `/super-settings/${sortId}/${settinglevel}/${sortId}`,
        method: 'put',
        data: data,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getPrescriptionFormTemplateList() {
    let cachedTemplates = sessionStorage.getItem('templateResponse');
    if (cachedTemplates) return JSON.parse(cachedTemplates);
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/admin` : `https://emrapi.hlthclub.in/admin`;
    } else {
        api = `https://betaemrapi.hlthclub.in/admin`;
    }
    return request({
        baseURL: api,
        url: `/user/templates/prescription_form`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getPrescriptionVitalTemplateList(type) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/admin` : `https://emrapi.hlthclub.in/admin`;
    } else {
        api = `https://betaemrapi.hlthclub.in/admin`;
    }
    return request({
        baseURL: api,
        url: `/user/templates/${type ? type : 'vital_form'}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

// function savePrescription

// {{admin_url}}/user/templates/prescription_form
//   https://1vpstx4hnk.execute-api.ap-south-1.amazonaws.com/prod
//   https://9jwwgz0y1i.execute-api.ap-south-1.amazonaws.com/dev

function fetchFilteredPrescriptionInfo(date, lastKey, limit=30) {
    // this
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/prescription` : `https://emrapi.hlthclub.in/prescription`;
    } else {
        api = `https://betaemrapi.hlthclub.in/prescription`;
    }

    // &last_evaluatedKey=${lastEvaluatedKey}
    let lastEvaluatedKey
    if (lastKey) {
        lastEvaluatedKey = encodeURI(JSON.stringify(lastKey));
    }
    let url = lastEvaluatedKey ? `/prescription?pres_date=${date}&last_evaluatedKey=${lastEvaluatedKey}&limit=${limit}` : `/prescription?pres_date=${date}&limit=${limit}`
    return request({
        baseURL: api,
        url: url,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

function fetechPrescriptionById(id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPrescriptionApiStageUrl();

    return request({
        baseURL: api,
        url: "/prescription/" + id,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function fetchPatientVitalPrescriptionData(id, type) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://q2vtvsn2x5.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://o62vin4w43.execute-api.ap-south-1.amazonaws.com/`
    }
    return request({
        baseURL: api,
        url: apiStage + `/labreports/patient_id/${id}/${type}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getPatientLabReports() {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://q2vtvsn2x5.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://o62vin4w43.execute-api.ap-south-1.amazonaws.com/`
    }
    return request({
        baseURL: api,
        url: apiStage + `/labreport`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function updateLabReport(id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://q2vtvsn2x5.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://o62vin4w43.execute-api.ap-south-1.amazonaws.com/`
    }
    return request({
        baseURL: api,
        url: apiStage + `/labreport/${id}`,
        method: 'put',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function createLabReport(data) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://q2vtvsn2x5.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://o62vin4w43.execute-api.ap-south-1.amazonaws.com/`
    }
    return request({
        baseURL: api,
        url: apiStage + `/labreport`,
        data: data,
        method: 'post',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function fetchSuggestions(allowedType, type, str) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = `https://qtdi0ixu13.execute-api.ap-south-1.amazonaws.com/`;
    } else {
        api = `https://1r311zmywf.execute-api.ap-south-1.amazonaws.com/`;
    }
    return request({
        baseURL: api,
        url: apiStage + `/autocomplete?type=${allowedType}&term=${str}`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getSuggestionDataBySection(finalPayloadData, template_id, selectionSection) {
    // let apiStage = sessionStorage.getItem('apiStage');
    let api = getPrescriptionApiStageUrl_analytics();
    let type = (selectionSection === "complaints") ? 'symptoms' : (selectionSection === "testRequired") ? "investigations" : selectionSection
    return request({
        baseURL: api,
        url: `/prescription/template/suggestions`,
        // ?suggestion_types=${type}
        // url: apiStage + `/prescription/template/suggestions?template_id=3e06707e&suggestion_types=${investigationprofile,tumorprofile,treatmentprofile}`,
        method: 'post',
        data: finalPayloadData,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function fetchSuggestionObject(template_id, formnames) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = getPrescriptionApiStageUrl_analytics();
    // let section = 'complaints,diagnosis,drugs,investigations'
    return request({
        baseURL: api,
        url: `/prescription/template/suggestions?template_id=${template_id}`,
        // url: apiStage + `/prescription/template/suggestions?template_id=3e06707e&suggestion_types=${investigationprofile,tumorprofile,treatmentprofile}`,
        method: 'post',
        data: {},
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getPrintSettings() {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/admin` : `https://emrapi.hlthclub.in/admin`;
    } else {
        api = `https://betaemrapi.hlthclub.in/admin`;
    }
    let account = sessionStorage.getItem('s3AccountId');
    return request({
        baseURL: api,
        url: `/settings/account/${account}/print_settings`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function uploadBookingList(payload) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = ''
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/vaccination/` : `https://emrapi.hlthclub.in/vaccination`;
        //api = `https://emrapi.hlthclub.in/vaccination/`;
        // replace
    } else {
        api = `https://betaemrapi.hlthclub.in/vaccination/`
    }
    return request({
        baseURL: api,
        url: "save-vaccexcel-meta",
        method: 'post',
        data: payload,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function uploadRxData(payload) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = ''
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/patient` : `https://emrapi.hlthclub.in/patient`;
        // replace
    } else {
        api = `https://betaemrapi.hlthclub.in/patient`
    }
    return request({
        baseURL: api,
        url: "/upload/suggestions",
        method: 'post',
        data: payload,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function saveTranslationData(payload, accountId) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api;
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/onlinepatient` :`https://emrapi.hlthclub.in/onlinepatient`
        // `https://w892z3scc4.execute-api.ap-south-1.amazonaws.com/prod`;
    } else {
        api =  `https://betaemrapi.hlthclub.in/onlinepatient`
    }
    return request({
        baseURL: api,
        url: '/upload/translation/language?account_id=' + accountId,
        method: 'post',
        data: payload,
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function getTranslationData(account_id, type, category) {
    let apiStage = ''
    if (type) {
        apiStage = (window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1 || window.location.hostname.indexOf('d32uc620y3be9') !== -1 || window.location.hostname.indexOf('v4abbottclinytics') !== -1) ? 'dev' : 'prod';
    } else {
        apiStage = sessionStorage.getItem('apiStage');
    }
    let api;
    if (apiStage === "prod") {
        api = `https://w892z3scc4.execute-api.ap-south-1.amazonaws.com/prod`;
    } else {
        api = `https://pesyxazr1d.execute-api.ap-south-1.amazonaws.com/dev`;
    }
    let url = account_id ? '/get/translation?account_id=' + account_id : '/get/translation'
    if (url.indexOf('?') !== -1) {
        if (category) {
            url += `&category=${category}`
        }
    } else {
        if (category) {
            url += `?category=${category}`
        }
    }
    return request({
        baseURL: api,
        url: url,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

function sendPaymentSMS(invoice_id) {
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/onlinepatient` : `https://emrapi.hlthclub.in/onlinepatient`;
    } else {
        api = `https://betaemrapi.hlthclub.in/onlinepatient`;
    }
    return request({
        baseURL: api,
        url: `/invoice/shoot-message/${invoice_id}?message_type=invoice_payment`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    });
}

// https://pesyxazr1d.execute-api.ap-south-1.amazonaws.com/dev/get/translation

const prescriptionService = {
    getTranslationData,
    saveTranslationData,
    uploadRxData,
    getPrintSettings,
    fetechPrescriptionById,
    getPrescriptionListData,
    createPrescription,
    updatePrescription,
    getDoctorPrescriptionForaDate,
    getPatientPrescriptions,
    recognizeInk,
    getPatientPrescriptionsByPatientHid,
    fetchTagsSettings,
    fetchSuggestions,
    fetchSuggestionObject,
    savePrescriptionTagsData,
    linkPrescriptionWithPatient,
    getPrescriptionTemplateByTemplateType,
    updatePrescriptionTemplateByTemplateType,
    getPrescriptionFormTemplateList,
    getPrescriptionByPrescriptionId,
    fetchFilteredPrescriptionInfo,
    uploadPrescriptionImage,
    fetchPatientVitalPrescriptionData,
    getPrescriptionVitalTemplateList,
    createLabReport,
    updateLabReport,
    getPatientLabReports,
    getLabReportXlsData,
    onDeletePrescriptionAction,
    getApptSummaryData,
    getPatientById,
    getDateWisePrescReport,
    getDoctorWisePrescReport,
    uploadPatientPrescriptionFromChatRoom,
    getUploadedPatientPrescriptionForChatRoom,
    fetchPrescriptionGenerateUploadUrl,
    getSuggestionDataBySection,
    fetchCaseHistoryData,
    getRxAnalysisCountData,
    postLogs_patient,
    postLogs_doctor,
    saveMiniTemplate,
    getMiniTemplate,
    sendPaymentSMS,
    uploadBookingList,
    getApptSummaryReport
}

export default prescriptionService;