import React from "react";
import MultiSelectReact from 'multi-select-react';

/*
* Pass dynamicValue to be string of ',' separated keys present in optionData subset
* Eg: 'r:staff, r:admin' if optionData keys are 'r:staff', 'r:admin', 'r:abc'*
*/
class Multiselect extends React.Component {

    constructor(props) {
        super(props);
        let dynamicKeys = this.props.dynamicValue ? this.props.dynamicValue.toLowerCase().split(',') : []; //already selected keys
        let multiSelect = this.props.optionData.map((option, idx) => {
            return { id: idx, value: dynamicKeys.indexOf(option.key) != -1, key: option.key, label: option.value };
        });

        this.state = {
            multiSelect: multiSelect,
            userRole: sessionStorage.getItem('userRole')
        };
    }

    componentWillReceiveProps(nextProps) {
        if (this.state.multiSelect !== nextProps.optionData) {
            let dynamicKeys = nextProps.dynamicValue ? nextProps.dynamicValue.toLowerCase().split(',') : []; //already selected keys
            let multiSelect = nextProps.optionData.map((option, idx) => {
                return { id: idx, value: dynamicKeys.indexOf(option.key) != -1, key: option.key, label: option.value };
            });

            this.setState({
                multiSelect: multiSelect
            })
        }
    }


    optionDeleted(optionsList) {
        this.optionClicked(optionsList);
    }


    optionClicked = (optionsList) => {
        const isUserManagement=(this.state.userRole).indexOf("usermgmt");
		const isPDetailsE=(this.state.userRole).indexOf("personal_details");
		if(isUserManagement!==-1){
			if(isPDetailsE===-1){
				;
				return false;
			}
			

		}
        this.setState({ multiSelect: optionsList });
        let valueString = optionsList.filter(option => option.value).map((option) => option.key).join(',');
        this.props.onInputChange({ name: this.props.inputData.name, value: valueString, type: this.props.inputData.type })
    }

    render() {
        const selectedOptionsStyles = {
            padding: '9px !important',
            border: '1px solid #ebedf2 !important',
            fontFamily: '"Montserrat", sans-serif !important',
            fontSize: '0.8125rem !important'
        };
        const optionsListStyles = {
            backgroundColor: "#dff0d8",
            color: "#3c763d"
        };
        return (
            <MultiSelectReact
                options={this.state.multiSelect}
                optionClicked={this.optionClicked.bind(this)}
                selectedBadgeClicked={this.optionDeleted.bind(this)}
                selectedOptionsStyles={selectedOptionsStyles}
                optionsListStyles={optionsListStyles}
                isSingleSelect={this.props.isSingleSelect||false} />
        );
    }
}


export default Multiselect;

