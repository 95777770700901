import React from 'react';

class TimerComponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = { time: {}, seconds: 0, isLastTimeActive: false };
        this.timer = 0;

        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    componentDidMount() {
        if (this.props.accountInfo) {
            let videoSetting = this.props.accountInfo.video_timer_settings;
            let slot_time = videoSetting.slot_time;
            let miniTime = this.convertMinutestoSeconds(slot_time);
            this.setState({ seconds: miniTime }, () => {
                let timeLeftVar = this.secondsToTime(miniTime);
                this.setState({ time: timeLeftVar }, () => this.startTimer());
            })
        }
    }

    convertMinutestoSeconds(minutes) {
        return Math.floor(minutes * 60);
    }

    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        }, () => {
            let { isLastTimeActive } = this.state;
            let videoSetting = this.props.accountInfo.video_timer_settings;
            let popupTime = videoSetting.slot_time - videoSetting.popup_time;
            if (popupTime > this.state.time.m) {
                
                if(!this.state.isLastTimeActive&&videoSetting.enable_popup){
                    this.props.emitShowTimeLeftMsg(this.state.time.m + " mins left for consultation to complete!");
                }
                isLastTimeActive = true
                this.setState({
                    isLastTimeActive
                });
            }
        });

        // Check if we're at zero.
        if (seconds == 0) {
            this.props.emitTimeOverEvent();
            clearInterval(this.timer);
        }
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    render() {

        return (
            <>
                {this.state.time.m} min {this.state.time.s} sec
            </>
        )
    }
}

export default TimerComponent;