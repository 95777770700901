export const ProviderEnum = {
    thb: 'thb',
    meitra: 'meitra',
    abbott: 'abbott'
};

export const ThemeFileEnum = {
    "blue": "/css/blue-theme.css",
    "orange":"/css/orange-theme.css",
    "navyblue": "/css/navyblue-theme.css",
    "brown": "/css/brown-theme.css",
    "red": "/css/red-theme.css",
    "purple": "/css/purple-theme.css",
    "pink": "/css/pink-theme.css",
    "tan": "/css/tan-theme.css"
}

export const PrimaryColor = {
    "blue": "#009cde",
    "orange":"#ea8137",
    "navyblue": "#174c91",
    "purple": "#594082",
    "brown": "#BD2431",
    "red": "#ed1c24",
    "pink": "#F56076",
    "default": "#36a2a1"
}

export const BgColor = {
    "blue": "rgba(153, 215, 242, 0.7)",
    "orange":"#ea8137a1",
    "navyblue": "rgba(23, 76, 145, 0.7)",
    "purple": "rgba(136, 115, 168, 0.9)",
    "brown": "rgba(218, 30, 55, 0.9)",
    "red": "rgba(237, 28, 36, 0.9)",
    "pink": "#F56076",
    "default": "rgba(101, 207, 181, 0.9)",
    "white": "white"
}