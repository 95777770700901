import React from "react";
// component imports
import HeaderContainer from '../../Common/Header/HeaderContainer';
import AccountViewComponent from './Component/AccountViewComponent';
// service imports
import accountService from '../../Services/account.settings';
// Utils import
// import Utils from '../../Shared/utils/utils';
// import userAuth from '../../Authentication/authActions';
// common imports
import SweetAlertComponent from '../../Common/Alert/SweetAlertComponent';
import CommonModalView from '../../Common/Modal/CommonModalView';
import TranslationComponent from '../../Common/Translation/TranslationComponent';
import UploadPhotoComponent from '../../Common/UploadPhoto/UploadPhotoComponent';
import InvoiceConfigComponent from '../../Common/InvoiceConfig/InvoiceConfigComponent';
import BannerComponent from '../../Common/Banner/BannerComponent';
import InventoryManagementContainer from '../../Views/Inventory/InventoryManagementContainer';
import QuestionnaireContainer from "../Questionnaire/QuestionnaireContainer";
import BookingUploadModal from "../Prescription/Common/BookingUploadModal";
//  Views/Prescription/Common/BookingUploadModal";

import Spinner from '../../Common/Spinner/Spinner';
// additional plugin imports
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getImgPath } from "../../providerConfig";

class AccountListContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            loading: false,
            alert: {
                type: '',
                title: '',
                text: ''
            },
            paginationDisable: false,
            lastEvaluatedKey: [],
            accountListData: null,
            selectedAcId: '',
            selectedAc: '',
            queryParams: {},
            showConfirmationModal: false,
            openInVoiceUpload: false,
            openTranslationUpload: false,
            openQuestionnaire: false,
            openVaccinationBooking: false
        };

    }

    componentDidMount() {
        this.fetchAccountData(this.state.lastEvaluatedKey, this.state.searchTerm);
    }

    async fetchAccountData(key, term) {
        this.setState({ lastEvaluatedKey: key, loading: true, paginationDisable: true, searchTerm: term });
        try {
            let qParams = { ...this.state.queryParams, limit: this.MAX_RECORDS }
            let lEKey = encodeURI(JSON.stringify(key.slice(-1)[0]))
            if (lEKey !== "undefined") {
                qParams = { ...qParams, last_evaluatedKey: lEKey }
            }
            if (term) {
                qParams = { ...qParams, account_name: term }
            }
            let AccountListResponse = await accountService.getAllAccountData(qParams);
            this.checkAccountListResponseData(AccountListResponse);
        } catch (error) {
            this.setState({
                loading: false
            })
            console.log('error');
        }
    }

    checkAccountListResponseData(response) {
        if (!response) {
            this.onError(response);
            return;
        }
        switch (response.type) {
            case "error":
                this.onError('No Data Found')
                break;
            case "success":
                let lastKey = this.state.lastEvaluatedKey;
                if (response.data && response.data.lastEvaluatedKey) {
                    lastKey.push(response.data.lastEvaluatedKey)
                }
                this.setState({
                    loading: false,
                    paginationDisable: false,
                    lastEvaluatedKey: lastKey,
                    accountListData: response.data
                })
                break;
            default:
                return;
        }
    }

    getEmitedSelectedFilerValues = (event) => {
        this.setState({
            queryParams: event,
            loading: true
        }, () => this.fetchAccountData([], this.state.searchTerm))
    }

    onError(text) {
        this.setState(prevState => ({
            show: true,
            loading: false,
            alert: {
                ...prevState.alert,
                type: 'error',
                title: 'Error',
                text: text ? text : 'Error'
            }
        }));
    }

    notify(message) {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            containerId: 'A'
        });
    }

    sweetAlertClose = (event) => {
        this.setState({ show: false });
    }

    getEmitedAddNewUserEventWithAccountId = (id, name, acData) => {
        this.props.history.push(`/create-account-user?id=${id}&name=${name}`, { accountData: acData })
    }

    getEmitedEventForAccountUserList = (event, acData) => {
        this.props.history.push(`/account-users?accountId=${event}`, { accountData: acData })
    }

    getEmitedEventForPaymentClick = (id, acData) => {
        this.props.history.push(`/payment-status`, { providerName: id, accountData: acData });
    }

    getEmitedAddTemplateWithAccountId = (event, acName, acData) => {
        this.props.history.push(`/upload-prescription?accountId=${event}&settinglevel=account&templatetype=prescription_form`, { accountData: acData })
    }

    getEmitedCreateTemplateWithAccountId = (event, acData) => {
        this.props.history.push(`/form-builder?accountId=${event}`, { accountData: acData });
    }

    getEmitedTranslationUpload = (account_id, acData) => {
        this.setState({ selectedAcId: account_id, selectedAc: acData, openTranslationUpload: true });
    }

    getEmitedBookingUpload = (account_id, acData) => {
        this.setState({ selectedAcId: account_id, selectedAc: acData, openVaccinationBooking: true });
    }

    getEmitedQuestionnaireWithAccountId = (account_id, acData) => {
        this.setState({ selectedAcId: account_id, selectedAc: acData, openQuestionnaire: true });
    }

    getEmitedAccountEditEvent = (event, acc_data) => {
        this.props.history.push(event, { accountData: acc_data });
    }

    getOnInvoiceSettingsUpdate = (account_id, acData) => {
        this.setState({ selectedAcId: account_id, selectedAc: acData, openInVoiceUpload: true });
    }

    getOnBannerSettingsUpdate = (account_id, acData) => {
        this.setState({ selectedAcId: account_id, selectedAc: acData, openBannerModal: true });
    }

    onUploadLogo = (account_id, acData) => {
        this.setState({ selectedAcId: account_id, selectedAc: acData, openLogoUpload: true });
    }

    getEmitedMoveToProdEvent = (account_id, acData) => {
        this.setState({ selectedAcId: account_id, selectedAc: acData, showConfirmationModal: true });
    }

    getEmitedModalCloseEvent = (save) => {
        this.setState({
            openLogoUpload: false,
            loading: save ? true : false,
            selectedAcId: '',
            selectedAc: ''
        }, () => {
            if (save) {
                this.fetchAccountData(this.state.lastEvaluatedKey, this.state.searchTerm)
            }
        })
    }

    getBookingCloseEvent = (save) => {
        this.setState({
            openVaccinationBooking: false,
            loading: save ? true : false,
            selectedAcId: '',
            selectedAc: ''
        }, () => {
            if (save) {
                this.fetchAccountData(this.state.lastEvaluatedKey, this.state.searchTerm)
            }
        })
    }

    getEmitedInvoiceModalCloseEvent = (save) => {
        this.setState({
            openInVoiceUpload: false,
            loading: save ? true : false,
            selectedAcId: '',
            selectedAc: ''
        }, () => {
            if (save) {
                this.fetchAccountData(this.state.lastEvaluatedKey, this.state.searchTerm)
            }
        })
    }

    getEmitedQuestionnairModalCloseEvent = (save) => {
        this.setState({
            openQuestionnaire: false,
            loading: save ? true : false,
            selectedAcId: '',
            selectedAc: ''
        }, () => {
            if (save) {
                this.fetchAccountData(this.state.lastEvaluatedKey, this.state.searchTerm)
            }
        })
    }

    getEmitedBannerModalCloseEvent = (save) => {
        this.setState({
            openBannerModal: false,
            loading: save ? true : false,
            selectedAcId: '',
            selectedAc: ''
        }, () => {
            if (save) {
                this.fetchAccountData(this.state.lastEvaluatedKey, this.state.searchTerm)
            }
        })
    }

    getEmitedTranslationModalCloseEvent = (save) => {
        this.setState({
            openTranslationUpload: false,
            loading: save ? true : false,
            selectedAcId: '',
            selectedAc: ''
        }, () => {
            if (save) {
                this.fetchAccountData(this.state.lastEvaluatedKey, this.state.searchTerm)
            }
        })
    }

    onDialogClose = (event) => {
        let { showConfirmationModal } = this.state;
        if (event) {
            // this.onEnableMFA();
        } else {
            showConfirmationModal = false;
            this.setState({ showConfirmationModal });
        }
    }

    async updateProdSettings() {
        try {
            var response = await accountService.moveBetaSettingsToProd(this.state.selectedAcId);
            this.checkForSettingsResponse(response);
        } catch (error) {
            console.log('error');
        }
    }

    checkForSettingsResponse(response) {
        
    }

    setLastEvaluatedKey = (key) => {
        this.fetchAccountData(key, this.state.searchTerm);
    }

    componentWillUnmount() {
        if (this.timeout) clearTimeout();
    }

    emitSearchAcc = (term) => {
        if (this.timeout) clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
            this.fetchAccountData([], term);
        }, 500)
    }

    MAX_RECORDS = 10;

    render() {
        return (
            <div >
                <HeaderContainer {...this.props}></HeaderContainer>
                {!this.state.accountListData && this.state.loading ? <div style={{ marginTop: '80px' }}><Spinner width={75} height={75}></Spinner></div> :
                    this.state.accountListData ? <AccountViewComponent
                        paginationDisable={this.state.paginationDisable}
                        lastEvaluatedKey={this.state.lastEvaluatedKey}
                        loading={this.state.loading}
                        accountListData={this.state.accountListData}
                        emitSelectedFilerValues={this.getEmitedSelectedFilerValues.bind(this)}
                        emitAddNewUserEventWithAccountId={this.getEmitedAddNewUserEventWithAccountId.bind(this)}
                        emitAccountEditEvent={this.getEmitedAccountEditEvent.bind(this)}
                        emitEventForAccountUserList={this.getEmitedEventForAccountUserList.bind(this)}
                        emitEventForPaymentClick={this.getEmitedEventForPaymentClick.bind(this)}
                        emitAddTemplateWithAccountId={this.getEmitedAddTemplateWithAccountId.bind(this)}
                        onUploadLogo={this.onUploadLogo.bind(this)}
                        onInvoiceSettingsUpdate={this.getOnInvoiceSettingsUpdate.bind(this)}
                        onBannerSettingsUpdate={this.getOnBannerSettingsUpdate.bind(this)}
                        setLastEvaluatedKey={this.setLastEvaluatedKey.bind(this)}
                        emitSearchAcc={this.emitSearchAcc.bind(this)}
                        emitTranslationUpload={this.getEmitedTranslationUpload.bind(this)}
                        emitCreateTemplateWithAccountId={this.getEmitedCreateTemplateWithAccountId.bind(this)}
                        emitQuestionnaireWithAccountId={this.getEmitedQuestionnaireWithAccountId.bind(this)}
                        emitBookingUpload={this.getEmitedBookingUpload.bind(this)}
                        emitMoveToProdEvent={this.getEmitedMoveToProdEvent.bind(this)}
                    ></AccountViewComponent>
                        :
                        <div className="container-fluid page-body-wrapper" >
                            <div className="main-panel">
                                <div className="content-wrapper" style={{ padding: '4.50rem 1.25rem  2.25rem  1.25rem' }}></div>
                                <div className="alert alert-light alert-elevate fade show alert alert-warning" role="alert">
                                    <div className="alert-text text-center">
                                        <h4 className="text-success">Nothing here!</h4>
                                        <img src={getImgPath('sadface')} alt="logo" className="center-align img-fluid" />
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

                {
                    this.state.show &&
                    <SweetAlertComponent show={this.state.show} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.sweetAlertClose}></SweetAlertComponent>
                }
                {
                    this.state.openLogoUpload &&
                    <UploadPhotoComponent
                        fullModal
                        open={this.state.openLogoUpload}
                        title={`Update A/C Settings: ${this.state.selectedAcId}`}
                        emitCancelEvent={this.getEmitedModalCloseEvent.bind(this)}
                        providerName={this.state.selectedAcId}
                        details={this.state.selectedAc}
                    />
                }
                {
                    this.state.openInVoiceUpload &&
                    <CommonModalView open={this.state.openInVoiceUpload}
                        emitModalCloseEvent={this.getEmitedInvoiceModalCloseEvent.bind(this)}
                        title={'Service Management'} size="lg">
                        <InventoryManagementContainer
                            viewFrom={'admin'}
                            providerName={this.state.selectedAcId}
                        ></InventoryManagementContainer>
                    </CommonModalView>
                    // <InvoiceConfigComponent
                    //     fullModal
                    //     open={this.state.openInVoiceUpload}
                    //     title={`Invoice Config`}
                    //     emitCancelEvent={this.getEmitedInvoiceModalCloseEvent.bind(this)}
                    //     providerName={this.state.selectedAcId}
                    //     details={this.state.selectedAc}
                    // />
                }
                {this.state.openTranslationUpload &&
                    <TranslationComponent
                        fullModal
                        open={this.state.openTranslationUpload}
                        title={`Custom Translation`}
                        emitCancelEvent={this.getEmitedTranslationModalCloseEvent.bind(this)}
                        providerName={this.state.selectedAcId}
                        details={this.state.selectedAc}
                    />

                }
                {this.state.openQuestionnaire &&
                    <QuestionnaireContainer
                        fullModal
                        open={this.state.openQuestionnaire}
                        title={`Questionnair Upload`}
                        emitCancelEvent={this.getEmitedQuestionnairModalCloseEvent.bind(this)}
                        providerName={this.state.selectedAcId}
                        details={this.state.selectedAc}>
                    </QuestionnaireContainer>
                }
                {
                    this.state.openBannerModal &&
                    <BannerComponent
                        fullModal
                        open={this.state.openBannerModal}
                        title={`Banner Upload`}
                        emitCancelEvent={this.getEmitedBannerModalCloseEvent.bind(this)}
                        providerName={this.state.selectedAcId}
                        details={this.state.selectedAc}
                    />
                }
                {this.state.openVaccinationBooking &&
                    // BookingUploadModal
                    <CommonModalView open={this.state.openVaccinationBooking} emitModalCloseEvent={this.getBookingCloseEvent.bind(this)} size={'md'} title={'Upload Booking List'}>
                        <BookingUploadModal accountId={this.state.selectedAcId} emitModalCloseEvent={this.getBookingCloseEvent.bind(this)}></BookingUploadModal>
                    </CommonModalView>
                }
                {/* BannerComponent */}

                {
                    this.state.showConfirmationModal && <SweetAlertComponent type={'error'} title={"Are you sure"} text={"want to override prod settings with beta?"}
                        sweetAlertClose={this.onDialogClose.bind(this, true)}
                        showCancel={true}
                        onCancel={this.onDialogClose.bind(this, false)}></SweetAlertComponent>
                }
                <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
            </div >
        );
    }
}

export default AccountListContainer;
