import UsersViewContainer from '../Views/Users/UsersViewContainer';
import AppointmentsViewContainer from '../Views/Appointments/AppointmentsViewContainer';
import AppointmentsCreateContainer from '../Views/Appointments/AppointmentsCreateContainer';
import UserEditContainer from '../Views/Users/UserEditContainer';
// import BranchViewContainer from '../Views/Branch/BranchViewContainer';
// import SettingsContainer from '../Views/Settings/SettingsContainer';
// import SettingsEditContainer from '../Views/Settings/SettingsEditContainer';
// import DepartmentContainer from '../Views/Department/DepartmentContainer';
// import BranchEditContainer from '../Views/Branch/BranchEditContainer';
// import AppointmentEditContainer from '../Views/Appointments/AppointmentEditContainer';

const appRoutes = [{
    route: `/user-view`,
    navbarName: "View",
    key: "Users",
    icon: "view",
    isHide: false,
    component: UsersViewContainer
  }, {
    route: `/create-user`,
    navbarName: "Create",
    key: "Users",
    icon: "create",
    isHide: false,
    component: UserEditContainer
  }, 
  // {
  //   route: `/patient-view`,
  //   navbarName: "View",
  //   key: "Patients",
  //   icon: "View",
  //   isHide: false,
  //   component: PatientListViewContainer
  // }, {
  //   route: `/create-patient`,
  //   navbarName: "Create",
  //   key: "Patients",
  //   icon: "create",
  //   isHide: false,
  //   component: PatientCreateContainer
  // },
  {
    route: `/appointments-view`,
    navbarName: "View",
    key: "Appointments",
    icon: "view",
    isHide: false,
    component: AppointmentsViewContainer
  }, {
    route: `/create-appointment`,
    navbarName: "Create",
    key: "Appointments",
    icon: "create",
    isHide: false,
    component: AppointmentsCreateContainer
  }, {
    redirect: true,
    path: "/doctor-login",
    to: "/doctor-login",
    navbarName: "Redirect"
  }
];

export default appRoutes;