export const Specialities = [{
        uniqueKey: '',
        specialityCode: 'all'

    },
    {
        uniqueKey: '0',
        specialityCode: 'Adult Intensivist'
    },
    {
        uniqueKey: '1',
        specialityCode: 'Allergy'
    },
    {
        uniqueKey: '2',
        specialityCode: 'Anesthesia'
    },
    {
        uniqueKey: '3',
        specialityCode: 'Bariatric Medicine/Surgery'
    },
    {
        uniqueKey: '4',
        specialityCode: 'Burn/Trauma'
    },
    {
        uniqueKey: '5',
        specialityCode: 'Cardiac Catheterization'
    },
    {
        uniqueKey: '6',
        specialityCode: 'Cardiology'
    },
    {
        uniqueKey: '7',
        specialityCode: 'Cardiovascular Surgery'
    },
    {
        uniqueKey: '8',
        specialityCode: 'Colorectal Surgery'
    },
    {
        uniqueKey: '9',
        specialityCode: 'Dermatology'
    },
    {
        uniqueKey: '10',
        specialityCode: 'Electrophysiology'
    },
    {
        uniqueKey: '11',
        specialityCode: 'Emergency Medicine'
    },
    {
        uniqueKey: '12',
        specialityCode: 'Endocrinology'
    },
    {
        uniqueKey: '13',
        specialityCode: 'Family Practice'
    },
    {
        uniqueKey: '14',
        specialityCode: 'Gastroenterology'
    },
    {
        uniqueKey: '15',
        specialityCode: 'General Surgery'
    },
    {
        uniqueKey: '16',
        specialityCode: 'Geriatrics'
    },
    {
        uniqueKey: '17',
        specialityCode: 'Gynecologic Oncology'
    },
    {
        uniqueKey: '18',
        specialityCode: 'Hematology/Oncology'
    },
    {
        uniqueKey: '19',
        specialityCode: 'Hepatobiliary'
    },
    {
        uniqueKey: '20',
        specialityCode: 'Hospitalist'
    },
    {
        uniqueKey: '21',
        specialityCode: 'Infectious Disease'
    },
    {
        uniqueKey: '22',
        specialityCode: 'Internal Medicine'
    },
    {
        uniqueKey: '23',
        specialityCode: 'Interventional Radiology'
    },
    {
        uniqueKey: '24',
        specialityCode: 'Medical Genetics'
    },
    {
        uniqueKey: '25',
        specialityCode: 'Neonatology'
    },
    {
        uniqueKey: '26',
        specialityCode: 'Nephrology'
    },
    {
        uniqueKey: '27',
        specialityCode: 'Neuroradiology'
    },
    {
        uniqueKey: '28',
        specialityCode: 'Neurology'
    },
    {
        uniqueKey: '29',
        specialityCode: 'Neurosurgery'
    },
    {
        uniqueKey: '30',
        specialityCode: 'Nuclear Medicine'
    },
    {
        uniqueKey: '31',
        specialityCode: 'Obstetrics & Gynecology'
    },
    {
        uniqueKey: '32',
        specialityCode: 'Occupational Medicine'
    },
    {
        uniqueKey: '33',
        specialityCode: 'Ophthalmology'
    },
    {
        uniqueKey: '34',
        specialityCode: 'Oral Surgery'
    },
    {
        uniqueKey: '35',
        specialityCode: 'Orthopedics'
    },
    {
        uniqueKey: '36',
        specialityCode: 'Otolaryngology / Head & Neck Surgery'
    },
    {
        uniqueKey: '37',
        specialityCode: 'Pain Management'
    },
    {
        uniqueKey: '38',
        specialityCode: 'Palliative Care'
    },
    {
        uniqueKey: '39',
        specialityCode: 'Pain Management'
    },
    {
        uniqueKey: '40',
        specialityCode: 'Palliative Care'
    },
    {
        uniqueKey: '41',
        specialityCode: 'Pathology: Surgical & Anatomic'
    },
    {
        uniqueKey: '42',
        specialityCode: 'Pediatric Intensivist'
    },
    {
        uniqueKey: '43',
        specialityCode: 'Pediatrics'
    },
    {
        uniqueKey: '44',
        specialityCode: 'Pediatric Surgery'
    },
    {
        uniqueKey: '45',
        specialityCode: 'Physical Medicine'
    },
    {
        uniqueKey: '46',
        specialityCode: 'Plastic & Reconstructive Surgery'
    },
    {
        uniqueKey: '47',
        specialityCode: 'Podiatric Surgery'
    },
    {
        uniqueKey: '48',
        specialityCode: 'Psychiatry'
    },
    {
        uniqueKey: '49',
        specialityCode: 'Pulmonary Medicine'
    },
    {
        uniqueKey: '50',
        specialityCode: 'Radiation Oncology'
    },
    {
        uniqueKey: '51',
        specialityCode: 'Radiology'
    },
    {
        uniqueKey: '52',
        specialityCode: 'Rheumatology'
    },
    {
        uniqueKey: '53',
        specialityCode: 'Surgical Oncology'
    },
    {
        uniqueKey: '54',
        specialityCode: 'Thoracic Surgery'
    },
    {
        uniqueKey: '55',
        specialityCode: 'Transplant Surgery'
    },
    {
        uniqueKey: '56',
        specialityCode: 'Urology'
    },
    {
        uniqueKey: '57',
        specialityCode: 'Vascular Surgery'
    },
    {
        uniqueKey: '58',
        specialityCode: 'Wound Care'
    },
    {
        uniqueKey: '59',
        specialityCode: 'Bariatric Surgery'
    },
    {
        uniqueKey: '60',
        specialityCode: 'ENT'
    },
    {
        uniqueKey: '61',
        specialityCode: 'General Surgery'
    },
    {
        uniqueKey: '62',
        specialityCode: 'Gastroenterology'
    },
    {
        uniqueKey: '63',
        specialityCode: 'Neurosurgery'
    },
    {
        uniqueKey: '64',
        specialityCode: 'Obstetrics & Gynecology'
    },
    {
        uniqueKey: '65',
        specialityCode: 'Ophthalmology'
    },
    {
        uniqueKey: '66',
        specialityCode: 'Oral/Dental Surgery'
    },
    {
        uniqueKey: '67',
        specialityCode: 'Orthopedic Surgery'
    },
    {
        uniqueKey: '68',
        specialityCode: 'Pain Management'
    },
    {
        uniqueKey: '69',
        specialityCode: 'Pediatric Surgery'
    },
    {
        uniqueKey: '70',
        specialityCode: 'Plastic Surgery'
    },
    {
        uniqueKey: '71',
        specialityCode: 'Podiatric Surgery'
    },
    {
        uniqueKey: '72',
        specialityCode: 'Urology'
    },
    {
        uniqueKey: '73',
        specialityCode: 'Vascular Surgery'
    },
    {
        uniqueKey: '74',
        specialityCode: 'Ambulatory Clinics'
    },
    {
        uniqueKey: '75',
        specialityCode: 'Cardiac Catheterization Labs'
    },
    {
        uniqueKey: '76',
        specialityCode: 'Diagnostic Radiology'
    },
    {
        uniqueKey: '77',
        specialityCode: 'Emergency Department'
    },
    {
        uniqueKey: '78',
        specialityCode: 'GI Endoscopy Labs'
    },
    {
        uniqueKey: '79',
        specialityCode: 'Inpatient Acute Care'
    },
    {
        uniqueKey: '80',
        specialityCode: 'Interventional Cardiology'
    },
    {
        uniqueKey: '81',
        specialityCode: 'Interventional Radiology'
    },
    {
        uniqueKey: '82',
        specialityCode: 'Same Day Surgery'
    },
    {
        uniqueKey: '83',
        specialityCode: 'Small Specialty Hospitals'
    },
    {
        uniqueKey: '84',
        specialityCode: 'Surgical Pathology'
    },
    {
        uniqueKey: '85',
        specialityCode: 'HCC Audits'
    },
    {
        uniqueKey: '86',
        specialityCode: 'Ambulance Coding'
    },
    {
        uniqueKey: '87',
        specialityCode: 'General Physician'
        
    }
];

export default Specialities;