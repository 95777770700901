import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import RootRouters from './Layout/RootRouters';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import { createBrowserHistory } from "history";
// import userAuth from './Authentication/authActions'
import { Router, Switch } from "react-router-dom";
import { SnackbarProvider, withSnackbar } from 'notistack';
import { askForPermissionToReceiveNotifications, setNotifications, getNotifications } from './push-notification';
import * as firebase from "firebase/app";
import 'firebase/messaging';
import ErrorBoundaryComponent from './Layout/Main/ErrorBoundaryComponent';
import '../node_modules/aos/dist/aos.css';
import AOS from 'aos';
import Fingerprint2 from 'fingerprintjs2'
import { setProvider, getImgPath } from './providerConfig';
import { ProviderEnum, ThemeFileEnum } from './providerEnum';
import NfnContext from './Layout/Main/NfnContext';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import CacheBuster from './CacheBuster';

const hist = createBrowserHistory();
const $ = window["jQuery"];
class ClientApp extends React.Component {
    constructor(props) {
        super(props);

        AOS.init();
        this.state = {
            nfnCount: 0,
            notif: [],
            play: false
        }
        this.audio = new Audio("https://breathefreeeducator.com/notification.mp3")

    }
    
    addStyle = (url, id) => {
        const style = document.createElement("link");
        style.rel = "stylesheet";
        style.href = url;
        style.id = id;
        style.async = true;

        document.head.appendChild(style);
    };

    addHelmetIcon = url => {
        const style = document.createElement("link");
        style.href = url;
        style.rel = "shortcut icon";
        style.async = false;

        document.head.appendChild(style);
    };

    componentWillMount() {
        sessionStorage.setItem('s3AccountId', 'breathefree');
        let idx2 = Object.values(document.styleSheets).findIndex(s => s && s.href && s.href.indexOf('landing-page.css') !== -1);
        if (idx2 !== -1 && document.styleSheets[idx2])
            document.styleSheets[idx2].disabled = true;
    }

    componentDidMount() {
        AOS.refresh();
        

        this.setStylesBasedOnProvider();

        this.audio.addEventListener('ended', () => this.setState({ play: false }));
        setTimeout(function () {
            firebase.initializeApp({
                apiKey: "AIzaSyAp0I6KJh3mmZh3xh74hJXoMdNpIhQuReo",
                authDomain: "clinytics-emr-3f206.firebaseapp.com",
                databaseURL: "https://clinytics-emr-3f206.firebaseio.com",
                projectId: "clinytics-emr-3f206",
                storageBucket: "clinytics-emr-3f206.appspot.com",
                messagingSenderId: "373141077425",
                appId: "1:373141077425:web:92e55152de8aeb2fa89190",
                measurementId: "G-3NF2BW953M"
            });
            const messaging = firebase.messaging();
            askForPermissionToReceiveNotifications();
            messaging.onMessage(notificationData => {
                if (!notificationData || !notificationData["data"]) { return; }
                let data = notificationData["data"];
                setNotifications(data);
                this.setState({ nfnCount: this.state ? this.state.nfnCount + 1 : 1 });
                this.onNotificationReceived(typeof data !== 'object' ? JSON.parse(data) : data);
            });
        }.bind(this), 10);
    }

    setStylesBasedOnProvider() {
        
        sessionStorage.setItem('provider', 'thb')
            setProvider(ProviderEnum.thb);
            setTimeout(() => {
                const cssUrl = "/css/cipla-theme.css";
                this.addStyle(cssUrl);
            }, 0)
        this.addHelmetIcon(getImgPath('favicon'));
    }

    checkForAccountTheme = () => {
        let accountDetails = sessionStorage.getItem('accountInfo');
        let emrToken = sessionStorage.getItem("emrToken");
        let accInfo = accountDetails ? JSON.parse(accountDetails) : null;
        if (!emrToken && accInfo) {
            if (!accInfo.theme || (accInfo.theme && accInfo.theme === "default")) {
                if (accInfo.theme) {
                    $(`link[src="${ThemeFileEnum[accInfo.theme]}"]`).remove();
                }
                return;
            }
            setTimeout(() => {
                const cssUrl = ThemeFileEnum[accInfo.theme];
                this.addStyle(cssUrl, accInfo.theme);
            }, 0)
        }
    }

    // UNSAFE_componentWillReceiveProps() {
    //     AOS.refresh();
    // }

    componentWillUnmount() {
        this.audio.removeEventListener('ended', () => this.setState({ play: false }));
    }

    checkForSenderTypeInfo(bodyData, sender_type) {
        if (sender_type === "patient") {
            let docId = sessionStorage.getItem('userId');
            let receiverInfo = bodyData.receiver_info ? JSON.parse(bodyData.receiver_info) : null;
            if (!docId || !receiverInfo) {
                return false
            }
            if (docId === receiverInfo.doctor_id) {
                return true;
            }
            return false;
        } else if (sender_type === "doctor") {
            let consultpatientdata = sessionStorage.getItem('consultpatientdata') ? JSON.parse(sessionStorage.getItem('consultpatientdata')) : '';
            let receiverInfo = bodyData.receiver_info ? JSON.parse(bodyData.receiver_info) : null;
            if (!consultpatientdata || !receiverInfo) {
                return false;
            }
            if (consultpatientdata.id === receiverInfo.patient_id) {
                return true;
            }
            return false;
        }
    }

    onNotificationReceived(bodyData) {
        if (!bodyData) { return; }
        
        if (!bodyData.notification_type && bodyData["message"]) {
            this.handleNotification(bodyData["message"], "info");
            return;
        }
        if (!this.checkForSenderTypeInfo(bodyData, bodyData.sender_type)) {
            return;
        }
        this.playNotificationSound();
        let { notif } = this.state;
        switch (bodyData['notification_type']) {
            case 'consultation_accept':
                this.handleNotification(bodyData, "success");
                break;
            case 'consultation_reject':
                this.handleNotification(bodyData, "error");
                break;
            case 'doctor_remarks':
                this.handleNotification(bodyData, "info");
                break;
            case 'online':
                this.handleNotification(bodyData, "info");
                break;
            case 'payment_done':
                this.handleNotification(bodyData, "success");
                break;
            case 'chat_link':
                this.redirectToVideoChat(bodyData);
                break;
            case 'payment_initiate':
                this.redirectToPaymentUrl(bodyData);
                break;
            case 'appointment_refresh':
                notif.push(bodyData)
                this.setState({ notif })
                break;
            case 'user_edited':
                notif.push(bodyData)
                this.setState({ notif })
                break;
            default:
                this.handleNotification(bodyData, "info");
        }
    }

    redirectToVideoChat(bodyData) {
        let senderInfo = typeof bodyData.sender_info === 'object' ? bodyData.sender_info : JSON.parse(bodyData.sender_info);
        if (window.location !== window.parent.location) {
            // website is in an iframe, where webcam faces issues
            window.open(senderInfo.chat_link, '_blank');
        } else {
            window.location.href = senderInfo.chat_link;
        }
    }

    redirectToPaymentUrl(bodyData) {
        let senderInfo = typeof bodyData.sender_info === 'object' ? bodyData.sender_info : JSON.parse(bodyData.sender_info);
        window.location.href = senderInfo.payment_url;
    }

    playNotificationSound() {
        this.setState({ play: true }, () => {
            this.audio.play()
        });
        setTimeout(() => {
            this.setState({ play: false }, () => {
                this.audio.pause();
            });
        }, 2000);
        // let src = 'https://file-examples.com/wp-content/uploads/2017/11/file_example_MP3_700KB.mp3';
        // let audio = new Audio(src);
        // audio.play();
        // setTimeout(() => {
        //     audio.pause();
        // }, 2000);
    }

    handleNotification(bodyData, variant) {
        let msg;
        if (bodyData.notification_type === "online") {
            let data = JSON.parse(bodyData['sender_info'])
            msg = data.name + " is online now";
        } else if (bodyData.notification_type === "payment_done") {
            let data = JSON.parse(bodyData['sender_info'])
            msg = bodyData.message + " | " + data.name;
            let accountDetails = sessionStorage.getItem('accountInfo');
            window._paq.push(['trackEvent', accountDetails.account_id ? accountDetails.account_id : '', 'paymentDone', data.id ? data.id : '']);
        } else {
            msg = bodyData['message'];
        }

        const hidingNotNeeded = [];
        let autoHideDuration = hidingNotNeeded.indexOf(bodyData.notification_type) !== -1 ? null : 6000;
        const action = key => {

            return '';
        }

        this.props.enqueueSnackbar(msg, {
            variant: variant,
            autoHideDuration,
            action
        });
        var title = msg;
        var options = {
            body: msg,
            icon: getImgPath('minilogolight')
        }
        if (window.Notification && Notification.permission === "granted") {
            var notification = new Notification(title, options);
            console.log(notification);
        }
    }

    componentDidCatch(err, info) {
        console.log(err)
        console.log(info)
    }

    render() {
        let nfn = this.state.nfnCount ? getNotifications() : [];
        if (nfn.length > 0) {
            let data = []
            for (let i in nfn) {
                let indNotification = nfn[i];
                if (indNotification['notification_type'] === "online") {
                    let senderInfoData = JSON.parse(indNotification.sender_info);
                    if ((senderInfoData['sender_type'] && senderInfoData['sender_type'] === 'patient')) {
                        data.push(senderInfoData['appointment_id'])
                    }
                }
            }
            sessionStorage.setItem('activePateintRoomIds', JSON.stringify(data));
        }

        return (
            <NfnContext.Provider value={this.state.notif}>
                <SnackbarProvider maxSnack={4}>
                    <Sentry.ErrorBoundary fallback={ErrorBoundaryComponent}>
                        <Router history={hist}>
                            <Switch>
                                <RootRouters nfn={nfn} />
                            </Switch>
                        </Router>
                    </Sentry.ErrorBoundary>
                </SnackbarProvider>
            </NfnContext.Provider>

        );
    }
}

ClientApp.propTypes = {
    enqueueSnackbar: PropTypes.func.isRequired,
};
const MyApp = withSnackbar(ClientApp);

class MYClientApp extends React.Component {
    componentDidMount() {
        if (window.requestIdleCallback) {
            requestIdleCallback(() => {
                Fingerprint2.get((components) => {
                    var values = components.map((component) => { return component.value })
                    var murmur = Fingerprint2.x64hash128(values.join(''), 31)
                    sessionStorage.setItem('deviceId', murmur);
                })
            })
        } else {
            setTimeout(() => {
                Fingerprint2.get((components) => {
                    var values = components.map((component) => { return component.value })
                    var murmur = Fingerprint2.x64hash128(values.join(''), 31)
                    sessionStorage.setItem('deviceId', murmur);
                })
            }, 500)
        }
    }

    render() {
        return (
            <CacheBuster>
                {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                    if (loading) return null;
                    if (!loading && !isLatestVersion) {
                        refreshCacheAndReload();
                    }

                    return (
                        <SnackbarProvider maxSnack={3} anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}>
                            <div>
                                <MyApp />
                            </div>
                        </SnackbarProvider>
                    );
                }}
            </CacheBuster>
        );
    }
}

function extractHostname(url) {
    if (!url) {
        return ''
    }
    var hostname;
    //find & remove protocol (http, ftp, etc.) and get hostname

    if (url.indexOf("//") > -1) {
        hostname = url.split('/')[2];
    }
    else {
        hostname = url.split('/')[0];
    }

    //find & remove port number
    hostname = hostname.split(':')[0];
    //find & remove "?"
    hostname = hostname.split('?')[0];
    hostname = hostname.replace("www.", "")

    return hostname;
}

var x = extractHostname(window.location ? window.location.hostname : '')


Sentry.init({
    dsn: "https://a21c67aeb5354e149afb2c42e04f3b17@o440084.ingest.sentry.io/5408192",
    release: "clinytics-ui@" + process.env.npm_package_version,
    integrations: [
        new Integrations.BrowserTracing(),
    ],
    maxBreadcrumbs: 50,
    environment: x || process.env.NODE_ENV,
    tracesSampleRate: 0.1,
});

ReactDOM.render(<React.StrictMode><MYClientApp /></React.StrictMode>, document.getElementById("root")
);

// registerServiceWorker();
unregister();