import React from "react";
import Spinner from "../../Spinner/Spinner";

class Textbox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showFullAge: false
        }
        this.onInputChange = this.onInputChange.bind(this);
        // this.onFocus = this.onFocus.bind(this);
        // this.onBlur = this.onBlur.bind(this);
    }

    onInputChange = (e) => {
        e.preventDefault();
        // this.setState({value: e.target.value});
        if (this.props.inputData.view === 'separate') {
            if (this.state.showFullAge === false) this.setState({ showFullAge: true })
            this.props.onInputChange({ name: e.target.name, value: e.target.value, type: 'textbox' })
        } else {
            this.props.onInputChange({ name: this.props.inputData.name, value: e.target.value, type: this.props.inputData.type })
        }
    }

    // onFocus = (event) => {
    //     event.preventDefault();
    //     if (this.props.formula && this.props.localvar) {
    //         console.log('onfocus:', event);
    //         let data = {
    //             formula: this.props.formula,
    //             localvar: this.props.localvar
    //         }
    //         this.props.emitFormalLocalVar(data);
    //     } else if (this.props.suggestion) {
    //         this.props.emitSuggestionEvent(true);
    //     }
    // }

    onSearchClick = (name, event) => {
        event.preventDefault();
        this.props.emitSearchFiledData(name, this.props.dynamicObj && (this.props.dynamicObj.value !== undefined) ? this.props.dynamicObj.value : this.props.dynamicObj)
    }

    render() {
        const { name, placeholder, readOnly, hasErrorsMessage, isSearch, view } = this.props.inputData;
        return (<>
            {view === 'separate' ?
                <>
                    <div className="row no-gutters">
                        <div className="col-6">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Age in" id={name} name={'age:years'} min={0} type="number"
                                    value={this.props.dynamicObj && (this.props.dynamicObj.value !== undefined) ? this.props.dynamicObj.value.no_of_years : ''}
                                    onChange={this.onInputChange} aria-describedby="basic-addon2" />
                                <div className="input-group-append">
                                    <button className="btn btn-sm btn-secondary text-small" type="button"><small>Yrs</small></button>
                                </div>
                            </div>
                        </div>
                        {this.state.showFullAge && <div className="col-6">
                            <div className="input-group">
                                <input type="text" className="form-control" placeholder="Age in" id={name} name={'age:months'} type="number" min={0}
                                    value={this.props.dynamicObj && (this.props.dynamicObj.value !== undefined) ? this.props.dynamicObj.value.no_of_months : ''}
                                    onChange={this.onInputChange} aria-describedby="basic-addon2" />
                                <div className="input-group-append">
                                    <button className="btn btn-sm btn-secondary" type="button"><small>Mth</small> </button>
                                </div>
                            </div>
                        </div>}
                    </div>
                </>
                :
                <div className="input-group">
                    <input
                        className="form-control"
                        id={name}
                        name={name}
                        type="textbox"
                        disabled={readOnly ? true : (this.props.dynamicObj && this.props.dynamicObj.readOnly) ? this.props.dynamicObj.readOnly : false}
                        value={this.props.dynamicObj && (this.props.dynamicObj.value !== undefined) ? this.props.dynamicObj.value : this.props.dynamicObj ? this.props.dynamicObj : this.props.dynamicValue}
                        placeholder={placeholder}
                        onChange={this.onInputChange}></input>
                    {isSearch &&
                        <div className="input-group-append">
                            <button className="btn btn-sm btn-outline-success radius-0" type="button" onClick={this.onSearchClick.bind(this, name)}>
                                {this.props.dynamicObj && this.props.dynamicObj.isLoading ? <Spinner width={15} height={15}></Spinner> : <i className="mdi mdi-magnify"></i>}
                            </button>
                        </div>
                    }
                </div>}
            {this.props.dynamicObj !== undefined && <>{this.props.dynamicObj.hasErrors ? <small className="text-danger error-text" >{hasErrorsMessage}</small>
                : this.props.dynamicObj.patientError && <small className="text-danger error-text" >{this.props.dynamicObj.patientError}</small>}</>}
        </>
        );
    }
}


export default Textbox;
