import React from "react";
import Utils from '../../../Shared/utils/utils';

class AccountCreateComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accountData: null,
            slotsData: null,
            openModal: false,
            isFormDisabled: true,
            modalSlotData: null,
            templatesArray: null,
            isFormLoading: false,
            requiredFeilds: null,
            isFileUpload: false,
            selectedSlot: null,
            templateName: '',
            adminFormSettings: {
                "account_name": "",
                "subscription_plan": "",
                'account_id': '',
                "max_user_allowed": 5,
                "account_type": "",
                "enable_tele_call": false
            }
        }
        this.bindaccountSettingDataToForm();
    }

    static getDerivedStateFromProps(props, state) {
        // Re-run the filter whenever the list array or filter text change.
        // Note we need to store prevPropsList and prevFilterText to detect changes.
        if (props.isFormLoading !== state.isFormLoading) {
            return {
                isFormLoading: props.isFormLoading
            };
        }
    }

    bindaccountSettingDataToForm() {
        if (!this.state.accountData) {
            return;
        }
        let { adminFormSettings } = this.state;
        adminFormSettings = { ...this.state.accountData };
        this.setState({ adminFormSettings });
    }

    onUserSelectValueChange = (event) => {
        const { name, value } = event.target

        this.setState(prevState => ({
            adminFormSettings: {
                ...prevState.adminFormSettings,
                user: {
                    ...prevState.adminFormSettings.user,
                    [name]: value
                }
            }
        }), () => this.checkFormValues())
    }

    onSelectValueChange = (event) => {
        const { name, value } = event.target
        if (value.indexOf('Select') !== -1) {
            this.setState((prevState) => ({
                adminFormSettings: {
                    ...prevState.adminFormSettings,
                    [name]: ''
                }
            }), () => this.checkFormValues())
        } else {
            this.setState(prevState => ({
                adminFormSettings: {
                    ...prevState.adminFormSettings,
                    [name]: value
                }
            }), () => this.checkFormValues())
        }
    }

    onAccountIdValueChange = (event) => {
        const { name, value } = event.target;
        if (parseInt(value) < 0 || value > 30) {
            return;
        }

        this.setState(prevState => ({
            adminFormSettings: {
                ...prevState.adminFormSettings,
                [name]: value
            }
        }), () => this.checkFormValues())
    }

    getRadioChange = (e) => {
        const { name, value } = e.target;
        this.setState(prevState => ({
            adminFormSettings: {
                ...prevState.adminFormSettings,
                [name]: !this.state.adminFormSettings['enable_tele_call']
            }
        }), () => this.checkFormValues())
    }

    onUserTextboxValueChange = (event) => {
        const { name, value } = event.target
        this.setState(prevState => ({
            adminFormSettings: {
                ...prevState.adminFormSettings,
                user: {
                    ...prevState.adminFormSettings.user,
                    [name]: value
                }
            }
        }), () => this.checkFormValues())
    }

    onMaxUserAllowedValueChange = (event) => {
        const { name, value } = event.target
        if (parseInt(value) < 0 || value.length >= 3) {
            return;
        }
        this.setState(prevState => ({
            adminFormSettings: {
                ...prevState.adminFormSettings,
                [name]: value
            }
        }), () => this.checkFormValues())
    }

    onTextboxValueChange = (event) => {
        const { name, value } = event.target
        this.setState(prevState => ({
            adminFormSettings: {
                ...prevState.adminFormSettings,
                [name]: value
            }
        }), () => this.checkFormValues())
    }

    onCancelClicked = (event) => {
        this.props.emitCancelEvent('/account-list');
    }

    onAccountSettingsSaveEvent = (event) => {
        event.preventDefault();
        let data = {
            "account_name": Utils.removeSpecialCharFromString(this.state.adminFormSettings['account_name']),
            "account_type": this.state.adminFormSettings['account_type'],
            'account_id': this.state.adminFormSettings['account_id'],
            "max_user_allowed": this.state.adminFormSettings['max_user_allowed'],
            "enable_tele_call": this.state.adminFormSettings["enable_tele_call"]
        }
        let isSafe = this.checkForEmptyData();
        if (isSafe) {
            this.props.emitAccountSettingsData(data);
        }
    }

    clearFormData() {
        this.setState(prevState => ({
            adminFormSettings: {
                ...prevState.adminFormSettings,
                "account_name": '',
                "account_type": '',
                "account_id": '',
                "max_user_allowed": '',
                "enable_tele_call": false
            }
        }))
    }

    checkForRequiredData(name) {
        if (!this.state.requiredFeilds || this.state.requiredFeilds.length === 0) {
            return false;
        }
        if (this.state.requiredFeilds.indexOf(name) !== -1) {
            return true;
        }
        return false;
    }

    checkFormValues() {
        // event.preventDefault();
        let requiredFeilds = this.checkForNoDataValue();
        let { isFormDisabled } = this.state;
        if (!this.state.isFormLoading) {
            if (!requiredFeilds) {
                isFormDisabled = false;
                this.setState({ isFormDisabled });
                return
            }
            isFormDisabled = true;
            this.setState({ isFormDisabled });
            return
        }
        isFormDisabled = true;
        this.setState({ isFormDisabled });
    }

    checkForNoDataValue() {
        let requiredFeilds = [];
        if (!this.state.adminFormSettings['account_name']) {
            requiredFeilds.push('account_name');
        }
        if (!this.state.adminFormSettings['account_type']) {
            requiredFeilds.push('account_type')
        }
        if (!this.state.adminFormSettings['account_id']) {
            requiredFeilds.push('account_id')
        }
        if (requiredFeilds.length > 0) {
            return true;
        }
        return false;
    }

    checkForEmptyData() {
        let requiredFeilds = []
        if (!this.state.adminFormSettings['account_name']) {
            requiredFeilds.push('account_name');
        }
        if (!this.state.adminFormSettings['account_type']) {
            requiredFeilds.push('account_type')
        }
        if (!this.state.adminFormSettings['account_id']) {
            requiredFeilds.push('account_id')
        }

        this.setState({
            requiredFeilds
        })
        if (requiredFeilds.length > 0) {
            return false;
        }
        return true;
    }

    render() {
        return (
            <div className="main-panel">
                <div className="content-wrapper" style={{ padding: '5.50rem 1.25rem  2.25rem  1.25rem' }}>
                    <div className="row mb-4 card cardtable">
                        <div className="col-lg-12 card card-body">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h4 className="card-title"> Configure Account</h4>
                                </div>
                                <div>
                                    {this.props.type === "edit" &&
                                        <button className="btn btn-outline-success btn-icon btn-rounded" onClick={this.onCancelClicked.bind(this)}>
                                            <i className="mdi mdi-arrow-left"></i>
                                        </button>
                                    }
                                </div>
                            </div>
                            <form className="form-sample">

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label className="col-lg-3 col-form-label">Account Name*</label>
                                            <div className="col-sm-8">
                                                <input type="text" placeholder="Account Name" className="form-control" name="account_name" value={this.state.adminFormSettings['account_name']} onChange={this.onTextboxValueChange.bind(this)} />
                                                {!!this.state.requiredFeilds &&
                                                    <small className="text-danger error-text" style={{ display: this.checkForRequiredData('account_name') ? '' : 'none' }}>*Required Field</small>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label className="col-lg-3 col-form-label">Account Id* (eg. account_1234 )</label>
                                            <div className="col-sm-8">
                                                <input type="text" placeholder="Enter Account Id" className="form-control" name="account_id" value={this.state.adminFormSettings['account_id']} onChange={this.onAccountIdValueChange.bind(this)} />
                                                {!!this.state.requiredFeilds &&
                                                    <small className="text-danger error-text" style={{ display: this.checkForRequiredData('account_id') ? '' : 'none' }}>*Required Field</small>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label className="col-lg-3 col-form-label">Account For*</label>
                                            <div className="col-lg-8">
                                                <select className="form-control"
                                                    name="account_type"
                                                    value={this.state.adminFormSettings['account_type']}
                                                    onChange={this.onSelectValueChange}>
                                                    <option>Select Account For</option>
                                                    <option value={"hospital"}>Hospital</option>
                                                    <option value={"clinic"}>Clinic</option>
                                                    <option value={'lab'}>Lab</option>
                                                </select>
                                                {!!this.state.requiredFeilds &&
                                                    <small className="text-danger error-text" style={{ display: this.checkForRequiredData('account_type') ? '' : 'none' }}>*Required Field</small>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group row">
                                            <label className="col-lg-3 col-form-label">Max User Allowed</label>
                                            <div className="col-sm-8">
                                                <input type="number" placeholder="Enter Max User Allowed" className="form-control" maxLength="2" name="max_user_allowed" value={this.state.adminFormSettings['max_user_allowed']} onChange={this.onMaxUserAllowedValueChange.bind(this)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-check form-check-primary">
                                            <label className="form-check-label" htmlFor="name" style={{ textTransform: 'uppercase' }}>
                                                <input type="radio" className="form-check-input" name="enable_tele_call" id="enable_tele_call" value="" checked={this.state.adminFormSettings['enable_tele_call'] ? true : false}
                                                    onClick={this.getRadioChange.bind(this)} /> Enable Tele Call <i className="input-helper"></i> </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <div className="col-lg-9 offset-lg-3">
                                                <button type="button" disabled={this.state.isFormDisabled} className="btn btn-success mr-2 btn-rounded" onClick={this.onAccountSettingsSaveEvent.bind(this)}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>

                        <a id="settings-trigger" data-toggle="tooltip" onClick={this.clearFormData.bind(this)} data-placement="right" title="" data-original-title="Clear Form Data" style={{ cursor: 'pointer' }}><i className="mdi mdi-format-paint"></i></a>
                    </div>
                </div>
            </div >
        );
    }
}

export default AccountCreateComponent;
