
import request from '../Api/request';
import userAuth from '../Authentication/authActions';
import Utils from '../Shared/utils/utils';

function getTemplateListOfTypePrescriptionForm(){
    let cachedTemplates = sessionStorage.getItem('templateResponse');
    if (cachedTemplates) return JSON.parse(cachedTemplates);
    let apiStage = sessionStorage.getItem('apiStage');
    let api = '';
    if (apiStage === "prod") {
        api = Utils.isUatEnvir() ? `https://uatemrapi.hlthclub.in/admin` :`https://emrapi.hlthclub.in/admin`;
    } else {
        api = `https://betaemrapi.hlthclub.in/admin`;
    }
    return request({
        baseURL: api,
        url: `/user/templates/prescription_form`,
        method: 'get',
        headers: {
            'Authorization': 'Bearer ' + userAuth.getToken()
        }
    }, 'appointment');
}

const templateService = {
    getTemplateListOfTypePrescriptionForm
}

export default templateService;