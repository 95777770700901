const prescriptionNavBarRoutes = [{
        type: 'single',
        route: `/prescription-detail`,
        display: true,
        navbarName: "Digital Prescripton",
        key: "digital-prescripton",
        icon: "digital-prescripton",
        component: 'PrescriptionDetailContainer'
    },
    {
        type: 'single',
        route: '',
        display: true,
        navbarName: "Image Prescription",
        key: "image-prescription",
        icon: "image-prescription",
        // component: 'PrescriptionDetailContainer'
    },
    {
        type: 'single',
        route: '',
        display: true,
        navbarName: "Reports",
        key: "reports",
        icon: "reports",
        // component: 'PrescriptionDetailContainer'
    },
    {
        type: 'single',
        route: '',
        display: true,
        navbarName: "Vital",
        key: "vital",
        icon: "vital",
        // component: 'PrescriptionDetailContainer'  Patient Summary 
    },
    {
        type: 'single',
        route: '',
        display: true,
        navbarName: "Patient Summary",
        key: "patient-summary",
        icon: "patient-summary",
        // component: 'PrescriptionDetailContainer'  Patient Summary 
    }
];


export default prescriptionNavBarRoutes;