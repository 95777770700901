import React from "react";
import Column from "./Column";

class Row extends React.Component {

    constructor(props) {super(props);}

    onInputChange = (e) => {
        this.props.onInputChange(e);
    }

    getEmitedSearchFiledData=(name,data)=>{
        this.props.emitSearchFiledData(name,data);
    }

    getEmitedAutoSelectionData=(name,data)=>{
        this.props.emitAutoSelectionData(name,data);
    }

    render() {
        return this.props.row &&
            <div className="row">
                {this.props.row.map((column, idx) => (<Column key={idx} 
                column={column} 
                emitSearchFiledData={this.getEmitedSearchFiledData.bind(this)}
                isRoleDoctor={this.props.isRoleDoctor}
                dynamicValues={this.props.dynamicValues} 
                onInputChange={this.onInputChange} 
                uiData={this.props.uiData}
                accountInfo={this.props.accountInfo}
                emitAutoSelectionData={this.getEmitedAutoSelectionData.bind(this)}/>))}
            </div> 
    }
}

export default Row;