import React from 'react';
import OtpComponent from '../../OTPVerfication/Component/OtpCounterComponent';
import Utils from '../../../Shared/utils/utils';
import Spinner from '../../../Common/Spinner/Spinner';

const OTPVerification = (props) => {
    let onSubmitOtpEvent = (event) => {
        event.preventDefault()
        props.emitFinalOtpSubmit();
    }

    let emitOtpTextChangeEvent = (e) => {
        props.emitOtpTextChangeEvent(e.target.value);
    }
    let { patientDetail } = props;
    return <div className="mx-auto  no-gutter ">
        <div className=" text-left p-4 bg-white">
            <h4>OTP Verification</h4>
            {patientDetail && <>
                {patientDetail.country_code === '+977' || patientDetail.country_code === '91' ?
                    <h6 className="font-weight-light">Enter OTP sent to <span className="text-black mr-1"> {patientDetail.country_code ? patientDetail.country_code + '-' : ''}{patientDetail.phone ? patientDetail.phone : ''}</span>
                        {patientDetail.email && <>or email : <span className="text-black">{Utils.getMaskedEmail(patientDetail.email)}</span></>}
                    </h6>
                    :
                    patientDetail.email && <h6 className="font-weight-light">Enter OTP sent to <span className="text-black">{Utils.getMaskedEmail(patientDetail.email)}</span></h6>
                }
            </>}

            <form className="pt-1" >
                <div className="form-group  mb-3">
                    <label htmlFor="exampleInputUsername1">Enter OTP</label>
                    <input
                        type="number"
                        name={'otp'}
                        onChange={emitOtpTextChangeEvent.bind(this)}
                        className="form-control" id="exampleInputUsername1"
                        placeholder="Please enter the 6 digit OTP here to verify" />
                    <small id="passwordHelpInline" className="text-muted">
                    </small>
                </div>
                <OtpComponent style={''} minutes={2.0} resendEvent={props.emitResendOtpButtonEvent.bind(this)}></OtpComponent>

                <div className="mt-3">
                    <button type="submit" disabled={(props.otp ? props.otp.length !== 6 : true) || props.otpWrong} className="btn btn-success"
                        onClick={onSubmitOtpEvent.bind(this)}>Verify & Proceed</button>
                    {props.otploading && <div style={{ display: 'inline-block', marginLeft: '10px' }}><Spinner width={30} height={30}/></div>}
                    {props.otpWrong && <div className="text-danger" style={{ display: 'inline-block', marginLeft: '10px' }}>OTP entered was wrong!</div>}
                </div>
            </form>
        </div>
    </div>;
}

export default OTPVerification;