/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types'; // ES6
import labels from "../../../Constants/WaitingAreaLabel";
import labelFile from '../../../Constants/WaitingAreaLabel';

const propTypes = {

};

class OtpComponent extends React.Component {

    constructor(props) {
        super(props);
        this.resendOTP = this.resendOTP.bind(this);
        let lang=sessionStorage.getItem("lang") ;
        this.state = {
            start: Date.now(),
            diff: 0,
            minutes: 0,
            seconds: 0,
            duration: 60 * this.props.minutes,
            resend: false,
            labels: labelFile[lang],
            lang: lang,
        }
        this.timerState = '';
        this.timerCount = '';
        this.count = 0;

    }

    componentDidMount() {
        window.clearInterval(this.timerCount);
        this.timerCount = setInterval(() => {
            this.timer();
        }, 1000)

    }

    starttimer() {
        window.clearInterval(this.timerCount);
        this.timerCount = setInterval(() => {
            this.timer();
        }, 1000)
    }


    componentWillUnmount() {
        window.clearInterval(this.timerCount);
    }

    timer() {

        let diff = this.state.diff;
        let minutes = this.state.minutes;
        let seconds = this.state.seconds;

        diff = this.state.duration - (((Date.now() - this.state.start) / 1000) | 0);
        minutes = (diff / 60) | 0;
        seconds = (diff % 60) | 0;
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        this.setState({ minutes: minutes, seconds: seconds });

        if (diff <= 0) {
            this.setState({ resend: true });
            window.clearInterval(this.timerCount);
        }
    }



    resendOTP(evt) {
        this.setState({ start: Date.now(), minutes: 0, seconds: 0, duration: 60 * this.props.minutes });
        this.props.resendEvent();
        this.starttimer();
        evt.preventDefault();
    }

    render() {
        const {labels}=this.state;
        return (

            <div>
                    <h6 className="my-2 d-flex font-weight-normal">
                    {this.state.minutes} : {this.state.seconds}
                    </h6>
                    <h6 className="my-2 d-flex font-weight-normal" onClick={this.resendOTP.bind(this)}>
                        {labels.DidntReceiveTheOTP} <a href="#" className="text-success"> &nbsp; <u>{labels.resendOTP}</u></a>
                    </h6>
            </div>
        );
    }
}
OtpComponent.propTypes = propTypes;

export default OtpComponent;

 