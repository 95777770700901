import React from "react";
import BrandViewComponent from './BrandView/BrandViewComponent';
import RouteViewComponent from './RouteView/RouteViewComponent';
import MobileRouteViewComponent from './RouteView/MobileRouteViewComponent';
import logoutService from '../../Services/logout.service';
import onlineConsultationService from '../../Services/onlineConsultation.service';
import emrRoutes from './RoutesJson';
import adminRoutesJson from './adminRoutesJson';
import supportRoutesJson from './supportRoutesJson';
import subStaffRoutesJson from './subStaffRoutesJson';
import dataAdminRoutes from './dataAdminRoutes';
import prescriptionRouteJson from './PrescriptionRouteJson';
import openUrlsWithAuth from './openUrlsWithAuth';
import pharmacistNavBarRoutes from './pharmacistRoute';
import { withSnackbar } from "notistack";
import Utils from "../../Shared/utils/utils";
class HeaderWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: {},
            openChatCount: null,
            emrNavBarRoutes: [],
            navType: this.props.type,
            version: sessionStorage.getItem('appVersion'),
            accountInfo: sessionStorage.getItem("profileInfo") ? JSON.parse(sessionStorage.getItem("profileInfo")) : null,
            mobileNav: false,
            currentRoute: this.props.location && this.props.location.pathname ? this.props.location.pathname : ''
        }
    }

    componentWillMount() {
        let idx2 = Object.values(document.styleSheets).findIndex(s => s && s.href && s.href.indexOf('landing-page.css') !== -1);
        if (document.styleSheets[idx2])
            document.styleSheets[idx2].disabled = true;
        // hardRefreshForOldVersions();
    }

    componentDidMount() {
        this.filterNavBarRoutesView()
        
        if(sessionStorage.getItem('accountInfo')){
            
        }
        else{
            
        }
        this.getAccountInfoRaw().then(function(result){
            Utils.hardRefresh();
         })
        
    }

    shouldComponentUpdate(nextProps, nextState) {
        // if (this.state.emrNavBarRoutes !== nextState.emrNavBarRoutes) {
        return true
        // }
        // return false;
    }
    async getAccountInfoRaw() {
        try {
            let account_id = sessionStorage.getItem('s3AccountId');
            let user_id = sessionStorage.getItem('userId');
            let res = await onlineConsultationService.getAccountInfo(account_id, 'video', user_id);
            if (res && res.data) {
                sessionStorage.setItem('accountInfo', JSON.stringify(res.data));
                return res.data;
            }
        } catch (err) {
            console.log(err);
        }
    }

    getDataRoutes() {
        let currentRoute = this.props.location && this.props.location.pathname ? this.props.location.pathname : ''
        let navRoutes = JSON.parse(JSON.stringify(dataAdminRoutes));
        navRoutes.forEach(element => {
            if (!sessionStorage.getItem("dataAdmin") || sessionStorage.getItem("dataAdmin") === "") {
                if (element.key === "Users") {
                    element.display = false
                }
                if (element.key === "doctorlist") {
                    element.display = false
                }
                // if (element.key === "rxanalysis") {
                //     element.display = false;
                // }
            }
            if (element.route.indexOf(currentRoute) !== -1) {
                element.isActive = true;
            } else {
                element.isActive = false;
            }
        });
        return navRoutes;
    }


    filterNavBarRoutesView = () => {
        let { emrNavBarRoutes } = this.state;
        let emrNavBarRoute;
        if (this.props.isOpenRoute) {
            emrNavBarRoute = openUrlsWithAuth;
        } else {
            if (this.state.navType) {
                emrNavBarRoute = prescriptionRouteJson;
            } else if (sessionStorage.getItem('isPharmacist')) {
                let userRole = sessionStorage.getItem('userRole')
                // admin+pharmacy=>show tansactions and bookings
                if (userRole.indexOf("r:admin") !== -1) {
                    pharmacistNavBarRoutes.forEach((role, index) => {
                        if (role.key == "transactions") {
                            if (userRole.indexOf("refund") !== -1) {
                                pharmacistNavBarRoutes[index].display = true;
                            } else {
                                pharmacistNavBarRoutes[index].display = false;
                            }
                        }
                    })
                }
                emrNavBarRoute = pharmacistNavBarRoutes;
                //console.log(pharmacistNavBarRoutes)
            } else if (sessionStorage.getItem('dataUser') && sessionStorage.getItem('dataUser') !== "") {
                emrNavBarRoute = this.getDataRoutes()
            } else if (sessionStorage.getItem('isSuperUser')) {
                emrNavBarRoute = adminRoutesJson;
            } else if (sessionStorage.getItem('isSupport')) {
                emrNavBarRoute = supportRoutesJson
            } else if (sessionStorage.getItem('substaff')) {
                emrNavBarRoute = subStaffRoutesJson
            } 
            else if((sessionStorage.getItem('userRole')).indexOf('r:usermgmt')!==-1){
                //console.log('userRole',sessionStorage.getItem('userRole'))
                emrNavBarRoute = this.filterAppRoutes();
                emrNavBarRoute.forEach((role, index) => {
                    if (role.key == "UsersMgmt") {
                        emrNavBarRoute[index].display = true;
                    }
                   if((sessionStorage.getItem('userRole')).indexOf('fee_management')!==-1){
                    if (role.key == "transactions") {
                        emrNavBarRoute[index].display = true;
                    }
                   }
                })
            } else {
                emrNavBarRoute = this.filterAppRoutes()
            }

           
        }
        //console.log("emrNavBarRoute",emrNavBarRoute);
        emrNavBarRoutes = JSON.parse(JSON.stringify(emrNavBarRoute));
        this.setState({ emrNavBarRoutes });
    }

    filterAppRoutes() {
        let currentRoute = this.props.location && this.props.location.pathname ? this.props.location.pathname : '';
        let newRoutes = emrRoutes;
        let userRole = sessionStorage.getItem('userRole');
        let provider = sessionStorage.getItem('provider');
        let acInfo = sessionStorage.getItem('accountInfo') ? JSON.parse(sessionStorage.getItem('accountInfo')) : null
        for (let i = 0; i < newRoutes.length; i++) {
            if (newRoutes[i].key === "Users") {
                if (sessionStorage.getItem('showUser') !== "" && userRole.indexOf('doctor') === -1) {
                    newRoutes[i].display = true;
                } else {
                    newRoutes[i].display = false;
                }
            }
            else if (newRoutes[i].key === "UsersMgmt") {
                if (acInfo && acInfo?.enable_opd_flow) {
                    //commented for double menu
                    //newRoutes[i].display = userRole && (userRole.indexOf("r:admin") !== -1) && provider === 'thb' ? true : false;
                }
            }
            
            else if (newRoutes[i].key === "Users-Doc") {
                if (sessionStorage.getItem('showUser') !== "" && userRole.indexOf('doctor') !== -1) {
                    newRoutes[i].display = true;
                } else {
                    newRoutes[i].display = false;
                }
            } else if (newRoutes[i].key === "sms-credit-detail") {
                newRoutes[i].display = sessionStorage.getItem('userRole') && (sessionStorage.getItem('userRole').indexOf("r:admin") !== -1 || sessionStorage.getItem('userRole').indexOf("r:doctor") !== -1) && sessionStorage.getItem('userRole').indexOf("f:smsvolet") !== -1 ? true : false;
                //console.log("sms-credit-detail",newRoutes[i].display )
            } else if (newRoutes[i].key === "SMS") {
                newRoutes[i].display = userRole.indexOf("f:sms") !== -1;
            } 
            // else if (newRoutes[i].key === "Patients") {
            //     newRoutes[i].display = userRole && (userRole.indexOf("r:admin") !== -1 || userRole.indexOf("r:staff") !== -1) && provider === 'thb' ? true : false;
            // }
            /*else if (newRoutes[i].key === "anonymous") {
                newRoutes[i].display = userRole && (userRole.indexOf("r:admin") !== -1 || userRole.indexOf("r:doctor") !== -1) && provider === 'thb' ? true : false;
            }else if (newRoutes[i].key === "appointment-summary") {
            }*/ 

            else if (newRoutes[i].key === "Patients") {
   
                 if (acInfo && acInfo?.enable_opd_flow) {
                    newRoutes[i].display = userRole && (userRole.indexOf("r:admin") !== -1 || userRole.indexOf("r:staff") !== -1) && provider === 'thb' ? true : false;
                }
                else {
                    newRoutes[i].display = userRole && (userRole.indexOf("r:admin") !== -1 || userRole.indexOf("r:doctor") !== -1 || userRole.indexOf("r:staff") !== -1) && (provider === 'thb'||provider ==='meitra') ? true : false;
                }
            }
            else if (newRoutes[i].key === "anonymous") {
                
                 if (acInfo && acInfo?.enable_opd_flow) {
                    newRoutes[i].display = userRole && (userRole.indexOf("r:admin") !== -1) && provider === 'thb'  && sessionStorage.getItem('s3AccountId') !== "breathefree"  ? true : false;
                }
                else if(acInfo && acInfo?.hide_anonymous_header){
                    newRoutes[i].display = false;
                }
                else {
                    newRoutes[i].display = userRole && (userRole.indexOf("r:admin") !== -1 || userRole.indexOf("r:doctor") !== -1) && provider === 'thb' && sessionStorage.getItem('s3AccountId') !== "breathefree"  ? true : false;
                }


            }
            else if (newRoutes[i].key === "appointment-summary") {
                newRoutes[i].display = userRole && (userRole.indexOf("r:admin") !== -1) ? true : false;
            }
            else if (newRoutes[i].key === 'insights') {
                newRoutes[i].display = userRole.includes('f:insights') && userRole.indexOf("admin") !== -1
            } else if (newRoutes[i].key === "docInsight") {
                // display if user is only doctor not admin and is 'aig'
                newRoutes[i].display = userRole.includes('f:insights') && userRole.indexOf("doctor") !== -1
                    && userRole.indexOf("admin") === -1
                    && sessionStorage.getItem('s3AccountId').indexOf("aig") !== -1;
            } else if (newRoutes[i].key === "Appointments") {
                if (sessionStorage.getItem('showAppointment') !== "" && userRole.indexOf("usermgmt") === -1) {
                    newRoutes[i].display = true;
                } else {
                    newRoutes[i].display = false;
                }
            } else if (newRoutes[i].key === "admin-form-builder") {
                if (userRole.indexOf("tempbuilder") !== -1) {
                    newRoutes[i].display = true;
                } else {
                    newRoutes[i].display = false;
                }
            } else if (newRoutes[i].key === "patient-queries") {
                if (userRole.indexOf("doctor") !== -1) {
                    newRoutes[i].display = true;
                    // this.getOpenChats();
                } else {
                    newRoutes[i].display = false;
                }
            } else if (newRoutes[i].key === "transactions") {
                newRoutes[i].display = userRole.includes('f:refund');
            }
            else if (newRoutes[i].key === "invoice-report") {
                let accountDetails = sessionStorage.getItem('accountInfo');
                let accInfo = accountDetails ? JSON.parse(accountDetails) : null;
                if (accInfo) {
                    newRoutes[i].display = accInfo && accInfo.account_type && accInfo.account_type.toLowerCase() === "hospital" ? false : newRoutes[i].display;
                }
            } else {
                newRoutes[i].display = true;
            }

            if (newRoutes[i].route.indexOf(currentRoute) !== -1) {
                newRoutes[i].isActive = true;
            } else {
                newRoutes[i].isActive = false;
            }
        }
        //console.log("here");
        return newRoutes
    }

    async getOpenChats() {
        let { openChatCount } = this.state;
        try {
            let data = {
                "account_id": sessionStorage.getItem('s3AccountId'),
                "doctor_id": sessionStorage.getItem('profileInfo') ? JSON.parse(sessionStorage.getItem('profileInfo')).cognito_user_sub : null
            };
            let res = await onlineConsultationService.getOpenDoctorChats(data);
            if (res && res.type === 'success' && res.data) {
                openChatCount = res.data.chatHistory ? res.data.chatHistory.length : null;
            }
        } catch (err) {
        }
        this.setState({ openChatCount });
    }

    getEmitedEventonNavLogoClick = (event) => {
        //console.log(this.state.emrNavBarRoutes);
        for (let index in this.state.emrNavBarRoutes) {
            let indItem = this.state.emrNavBarRoutes[index];
            if (indItem.display) {
                if (indItem.route.indexOf(",") !== -1) {
                    let routeArray = indItem.route.split(",");
                    this.props.history.push(routeArray[0]);
                    return
                } else {
                    this.props.history.push(indItem.route);
                    return
                }
            }
        }
    }

    getEmitEventOnNavClick = (event) => {
        let route;
        if (this.props.isOpenRoute) {
            if (event === "/inventory-management") {
                route = `/${sessionStorage.getItem('s3AccountId')}/inventory-management`;
            } else if (event === "/bookings-list") {
                route = `/${sessionStorage.getItem('s3AccountId')}/bookings-list`
            }
            this.props.history.push(route);

        } else {
            if (event === "patient-queries") {
                this.getOpenChats();
            }
            if (event === '/appointment-anonymous') {
                route = '/prescription-detail?patientId=anonymous&type=appointment';
            } else if (event === '/patient-anonymous') {
                route = '/prescription-detail?patientId=anonymous&type=patient';
            } else if (this.state.version) {
                route = event + "?version=" + this.state.version;
            } else {
                route = event;
            }
            if (event === "/admin-form-builder") {
                this.props.history.push(event, { accountId: sessionStorage.getItem('s3AccountId') });
            } else {
                this.props.history.push(route);
            }
        }

    }

    getEmitedLogoutEvent = (event) => {
        let isVideoOn = sessionStorage.getItem('currentVideoApptId');
        if (isVideoOn && isVideoOn !== 'undefined') {
            this.props.enqueueSnackbar('Please complete on-going video consultation before signing out.')
        } else {
            logoutService.callLogoutEvents(this.props.history)
        }
    }

    getEmitedSidebarClickEvent(event) {
        this.props.emitSidebarClickEvent();
    }

    getEmitedMobileNavEvent = (event) => {
        this.props.toggleMobileNav();
    }

    render() {
        
        return <>
            {this.props.screen && <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row 12">
                <BrandViewComponent header={"header view "} navType={this.state.navType} emitSidebarClickEvent={this.getEmitedSidebarClickEvent.bind(this)} emitEventonNavLogoClick={this.getEmitedEventonNavLogoClick.bind(this)}></BrandViewComponent>
                <RouteViewComponent
                    isOpenRoute={this.props.isOpenRoute}
                    showDoctorList={this.props.showDoctorList}
                    doctorList={this.props.doctorList}
                    selectedDoc={this.props.selectedDoc}
                    mobileNav={this.props.mobileNav}
                    openChatCount={this.state.openChatCount}
                    getListForDoctor={e => this.props.getListForDoctor(e)}
                    emitMobileNavEvent={this.getEmitedMobileNavEvent.bind(this)}
                    emrNavBarRoutes={this.state.emrNavBarRoutes}
                    emitEventOnNavClick={this.getEmitEventOnNavClick.bind(this)}
                    emitLogoutEvent={this.getEmitedLogoutEvent.bind(this)}>
                </RouteViewComponent>
            </nav>}
            {!this.props.screen && <MobileRouteViewComponent
                showDoctorList={this.props.showDoctorList}
                doctorList={this.props.doctorList}
                isOpenRoute={this.props.isOpenRoute}
                mobileNav={this.props.mobileNav}
                openChatCount={this.state.openChatCount}
                getListForDoctor={e => this.props.getListForDoctor(e)}
                selectedDoc={this.props.selectedDoc}
                emrNavBarRoutes={this.state.emrNavBarRoutes}
                emitEventOnNavClick={this.getEmitEventOnNavClick.bind(this)}
                emitLogoutEvent={this.getEmitedLogoutEvent.bind(this)}>
            </MobileRouteViewComponent>}
        </>;
    }
}

export default withSnackbar(HeaderWrapper);
