import React from "react";

class AutoComplete extends React.Component {
    constructor(props) { 
        super(props); 
    }

    onInputChange = (e) => {
        this.props.onInputChange({ name: this.props.inputData.name, value: e.target.value, type: this.props.inputData.type })
    }

    render() {
        const {name, placeholder, optionKey} = this.props.inputData;
        return (
            <>
                <input type="text" placeholder={placeholder} className="form-control" name={name} value={this.props.dynamicValue} onChange={this.onInputChange} list={name} />
                <datalist id={name}>
                    {this.props.optionData.map((obj, index) =>
                        <option key={index} value={obj[optionKey]} style={{ display: 'flex' }}> {this.props.inputData.displayValueFn(obj)}</option>
                    )}
                </datalist>
            </>
        )
    }
}

export default AutoComplete;