import React, { Component } from 'react';
import supportService from '../../Services/support.service';
import { withSnackbar } from 'notistack';

class SupportContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            complaint: '',
            other: false,
            otherText: '',
            isSubmitEnable: false,
            complainType: '',
            optionsData: [
                {
                    key: '',
                    value: 'Select Complaint'
                }, {
                    key: 'voice_not_clear',
                    value: 'Patient voice was not heard'
                }, {
                    key: 'video_not_seen',
                    value: 'Patient voice was not clear'
                }, {
                    key: 'video_not_clear',
                    value: 'Patient video was not seen'
                }, {
                    key: 'consultation_start',
                    value: 'Patient video was not clear'
                }, {
                    key: 'consultation_dropped',
                    value: 'Consultation was started, but call dropped in between'
                },   {
                    key: 'others',
                    value: 'Others'
                }
            ]
        }
    }

    onSelectValueChange = (event) => {
        let { name, value } = event.target;
        let { complaint, other, otherText, complainType } = this.state;
        if (value === "others") {
            // complaint = value;
            complainType = value
            this.setState({ complaint,complainType }, () => this.enableTextbox());
        } else {
            other = false;
            otherText = ''
            complainType = value;
            complaint = this.getComplaintValue(value);
            this.setState({ complaint, other, otherText, complainType });
        }
    }

    getComplaintValue(value) {
        if (!value) {
            return;
        }
        for (let index in this.state.optionsData) {
            if (this.state.optionsData[index].key === value) {
                return this.state.optionsData[index].value
            }
        }
    }

    onTextBoxDataChange = (event) => {
        const { value } = event.target;
        let { otherText } = this.state;
        // complaint = value;
        otherText = value
        this.setState({ otherText });

    }

    enableTextbox = () => {
        let { other } = this.state;
        other = true;
        this.setState({ other });
    }

    checkForButtonDisability() {
        return this.state.other ? (this.state.otherText ? true : false) : (this.state.complainType ? true : false)
    }

    onComplaintSubmit = (event) => {
        
        let data = {
            doctor_id: this.props.doctorDetail.doctor_id,
            complaint: this.state.other ? this.state.otherText : this.state.complaint,
            appointment_id: this.props.appointmentId,
            account_id: this.props.providerName,
            complain_type: this.state.complainType
        }

        //         patient_id/doctor_id: '', 
        // 'complaint': '', 
        // 'appointment_id' : '',
        // 'account_id': '', 
        // 'complain_type': ''

        if (!data.complaint) {
            return
        }
        let { isSubmitEnable } = this.state;
        isSubmitEnable = true;
        this.setState({ isSubmitEnable }, () => this.submitSupportData(data));

    }

    async submitSupportData(data) {
        try {
            let response = await supportService.postDoctorComplaint(data);
            this.checkSupportResponse(response);
        } catch (error) {
            let { isSubmitEnable } = this.state;
            isSubmitEnable = false;
            this.setState({ isSubmitEnable })
            this.props.enqueueSnackbar('Error While getting Slots.')
        }
    }

    checkSupportResponse(response) {
        let { isSubmitEnable } = this.state;
        if (!response) {
            isSubmitEnable = false;
            this.setState({ isSubmitEnable })
            this.props.enqueueSnackbar('Error While Submiting Complaint.', { variant: 'error' })
            return;
        }
        switch (response.type) {
            case "error":
                isSubmitEnable = false;
                this.setState({ isSubmitEnable })
                this.props.enqueueSnackbar('Error While Submiting Complaint.', { variant: 'error' })
                break;
            case "success":
                isSubmitEnable = false;
                this.setState({ isSubmitEnable })
                this.props.enqueueSnackbar('Complaint Added Successfully!!', { variant: 'success' })
                this.onCloseEvent();
                break;
            default:
                return;
        }
    }

    onCloseEvent = (event) => {
        
        this.props.emitSupportCloseEvent()
    }

    render() {


        const { open, patientDetail } = this.props;
        return (
            <div className={`modal fade ${open ? 'show' : ''}`} id="notificationmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: open ? 'block' : 'none', backgroundColor: '#716e6e54' }}>
                <div className="modal-dialog " role="document">
                    <div className="modal-content bg-white">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Fill out the form and we'll be in touch
              as soon as possible!</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onCloseEvent.bind(this)}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body ">
                            <form className="forms-sample">
                                <div className="form-group">
                                    <label for="exampleInputCity1">Select a query</label>
                                    <select className="form-control custom-select" 
                                        name='complainType' value={this.state.complainType} onChange={this.onSelectValueChange.bind(this)}>
                                        {this.state.optionsData.map((indValue) =>
                                            <option value={indValue.key} >{indValue.value}</option>
                                        )}

                                    </select>
                                </div>
                                {this.state.other &&
                                    <div className="form-group">
                                        <label for="exampleTextarea1">Write your query </label>
                                        <textarea className="form-control" id="exampleTextarea1" name="otherText" value={this.state.otherText} rows="4" onChange={this.onTextBoxDataChange.bind(this)}></textarea>
                                    </div>
                                }
                            </form>
                        </div>
                        <div className="modal-footer"> <button type="submit" className="btn btn-outline-success mr-2" disabled={!this.checkForButtonDisability() || this.state.isSubmitEnable} onClick={this.onComplaintSubmit.bind(this)}>Submit</button>
                            <button className="btn btn-light" onClick={this.onCloseEvent.bind(this)}>Cancel</button></div>
                    </div>
                    <div className="clinytic-line-loader" style={{ display: this.state.isSubmitEnable ? '' : 'none' }}></div>
                </div>

            </div>
        );
    }
}

export default withSnackbar(SupportContainer);