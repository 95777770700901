import React, { Component } from 'react';

export default class CallAlertModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    onModalConfirmationClicked = (event) => {
        this.props.emitModalConfirmationEvent();
    }

    onModalCloseEvent = (event) => {
        this.props.emitOnModalCloseEvent()
    }

    render() {
        const { open, patientDetail, waitingMessage, labels, hideButton } = this.props;
        return (

            <div className={`modal fade ${open ? 'show' : ''}`} id="notificationmodal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display: open ? 'block' : 'none', backgroundColor: '#716e6e54' }}>
                <div className="modal-dialog " role="document">
                    <div className="modal-content bg-white">
                        <div className="modal-header">
                            {!hideButton && <h5 className="modal-title" id="exampleModalLabel">{labels.confirm}</h5>}
                            {!hideButton && <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.onModalCloseEvent.bind(this)}><span aria-hidden="true">×</span></button>}</div>
                        <div className="modal-body call-modal">
                            <div className="call-box incoming-box">
                                <div className="call-wrapper">
                                    <div className="call-inner">
                                        <div className="call-user">
                                            <img className="call-avatar animated infinite tada delay-1s" src="/images/notification-bell.png"
                                                width="100px" style={{ maxWidth: 100, minWidth: 100, height: 100 }} alt="User Image" />
                                            <h4>{labels.hi} {patientDetail.name},</h4>


                                            <p className=""> {`${waitingMessage}`} <span className="d-none d-md-block "></span>{labels.acceptBrowser}</p>
                                        </div>

                                        {!hideButton && <button type="button" className="btn btn-outline-success w-100 m-auto animated infinite shake delay-1s" onClick={this.onModalConfirmationClicked.bind(this)}>{labels.clickToJoin}</button>}

                                    </div>
                                </div>
                            </div>



                        </div>

                    </div>
                </div>
            </div>

        );
    }
}