import React from "react";
import View from '../../../../Shared/utils/CommonTableView';
import CommonModalView from '../../../../Common/Modal/CommonModalView';
import StageUserFormModalComponent from '../../../../Common/Modal/StageUserFormModalComponent';
import SearchPageViewComponent from '../../../../Common/SearchPage/SearchPageViewComponent';
import Utils from '../../../../Shared/utils/utils';
import SetUserPasswordComponent from "../../../Account/Component/SetUserPasswordComponent";
const $ = window["jQuery"];
class UserListViewComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            role: sessionStorage.getItem('userRole'),
            users: this.props.userList.rows,
            filters: this.props.userList.filters,
            visibleHeader: this.props.userList.visible,
            userList: this.props.userList,
            formatedHeader: null,
            finalTableData: [],
            filteredData: [],
            loading: this.props.loading,
            isStageModalOpen: false,
            stageModalData: null,
            selectedUser: null,
            limit: 10,
            offSet: 0
        }
        this.formatHeader();
        this.formTableBodyData();
    }

    formatHeader() {
        if (!this.state.visibleHeader || this.state.visibleHeader.length === 0) {
            return;
        }
        let finalHeader = [];
        for (let index in this.state.visibleHeader) {
            if (this.state.visibleHeader[index].indexOf("_") !== -1) {
                let strArray = this.stringSpiltForHeader(this.state.visibleHeader[index], '_');
                let indObj = {
                    key: this.state.visibleHeader[index],
                    value: strArray.join(" ")
                }
                finalHeader.push(indObj);
            } else {
                let strArray = this.state.visibleHeader[index];
                let indObj = {
                    key: this.state.visibleHeader[index],
                    value: strArray
                }
                finalHeader.push(indObj);
            }
        }
        this.state.formatedHeader = finalHeader;
        this.setState(this.state);
    }

    stringSpiltForHeader(value, seperate) {
        return value.split(seperate);
    }

    formTableBodyData() {
        if (!this.state.users || (this.state.users && this.state.users.length === 0)) {
            this.state.finalTableData = [];
            this.state.filteredData = [];
            this.state.loading = false;
            this.setState(this.state);
            return;
        }
        this.state.finalTableData = [];
        this.state.filteredData = [];
        this.setState(this.state);
        this.state.visibleHeader.unshift('is_stage');
        this.state.visibleHeader.unshift('id');
        this.setState(this.state);
        for (let index in this.state.users) {
            let indBodyData = []
            let indUserData = this.state.users[index];
            for (let key in indUserData) {
                if (this.state.visibleHeader.indexOf(key) !== -1) {
                    let dataIndex = this.state.visibleHeader.indexOf(key);
                    if (typeof indUserData[key] === "object") {
                        let indObjData = indUserData[key];
                        for (let values in indObjData) {
                            let str = '';
                            str = str + '' + values + " : " + indObjData[values] + " ,";
                            indBodyData[dataIndex] = str;
                        }
                    } else {
                        if (key === "is_stage") {
                            let cognitoUserIndex = this.state.visibleHeader.indexOf('cognito_username');
                            if (!indUserData[cognitoUserIndex] && (indUserData[key] && indUserData['cognito_user_sub'] === "-")) {
                                indBodyData[dataIndex] = true;
                            }
                        } else {
                            indBodyData[dataIndex] = indUserData[key];
                        }
                    }
                }
            }
            this.state.finalTableData.push(indBodyData);
            this.state.filteredData.push(indBodyData);
            this.state.loading = false;
            this.setState(this.state);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.loading !== this.state.loading) {
            this.state.loading = nextProps.loading;
            this.setState(this.state);
        }
        if (this.state.userList !== nextProps.userList) {
            this.setState({
                users: nextProps.userList.rows,
                filters: nextProps.userList.filters,
                visibleHeader: nextProps.userList.visible
            }, () => this.formTableBodyData())
        }
    }

    onUserEditClicked = (event) => {
        if (!event) {
            return;
        }
        let userId = event;
        let userData = this.getUserDataBasedOnUserId(userId);
        this.props.emitUserEditEvent(userId, userData);
    }

    getUserDataBasedOnUserId(id) {
        for (let data of this.state.users) {
            if (data.id === id) {
                return data;
            }
        }
    }

    onResetPasswordClicked = (event) => {
        let userId = event;
        this.props.emitResetPasswordEvent(userId);
    }

    onUserDeleteClicked = (event) => {
        let userId = event;
        this.props.emitUserDeleteEvent(userId);
    }

    onEventForNewUserOccur = (event) => {
        this.props.emitNewUserCreationEvent();
    }

    getEmitedSelectedFilerValues = (event) => {
        if (!event) {
            return;
        }
        this.setState({ loading: true });
        this.props.emitSelectedFilerValues(event)
    }

    componentDidMount() {
        $('[data-toggle="tooltip"]').tooltip()
    }

    componentWillUnmount() {
        $('[data-toggle="tooltip"]').tooltip('hide');
    }



    formatDataIfDateIsPresent(value) {
        if (!value) {
            return;
        }
        let timestamp = Date.parse(value);
        if (isNaN(timestamp) === false) {
            var d = new Date(timestamp);
            return Utils.getFormatedDate(d);
        }
        return value;
    }

    onStageUser = (indData, event) => {
        if (!indData || !event) {
            return;
        }
        
        let accountIdIndex = this.state.visibleHeader.indexOf("account_id");
        let idIndex = this.state.visibleHeader.indexOf("id");
        let emailIndex = this.state.visibleHeader.indexOf("email");
        let phoneIndex = this.state.visibleHeader.indexOf("phone");
        let email = indData[emailIndex];
        let accountId = indData[accountIdIndex];
        let id = indData[idIndex];
        let phone = indData[phoneIndex];
        let data = {
            id: id,
            account_id: accountId,
            cognito_username: '',
            email: email,
            phone: phone
        }
        this.setState(prevState => ({
            isStageModalOpen: true,
            stageModalData: data
        }))
    }

    getEmitedModalCloseEvent = (event) => {
        this.setState({
            isStageModalOpen: false
        })
    }

    redirectToCreateView = (event) => {
        this.props.emitRedirectToView();
    }

    getEmitedStageUserData = (event) => {
        if (!event) {
            return;
        }
        this.props.emitStageUserData(event);
    }

    renderFilteredDataFromSearch = (event) => {
        let filteredData = [];
        filteredData = this.state.filteredData.filter(e => {
            let mathesItems = Object.values(e)
            let retVal = true;
            let matched = [];
            mathesItems.forEach(e => {
                const regex = new RegExp(event, 'gi')
                if (typeof (e) === 'string') {
                    retVal = e.match(regex)
                }
                if ((retVal && typeof (retVal) === 'object') && retVal.length > 0) {
                    matched = retVal;
                }
            })
            if (matched.length > 0) {
                return matched
            }
            return retVal;
        })
        this.setState((prevState) => ({
            ...prevState,
            finalTableData: filteredData
        }));
    }

    onNextButtonClick = (event) => {
        let { offSet, limit } = this.state;
        offSet = offSet + limit;
        this.setState({
            offSet
        }, this.props.emitNextButtonEvent(offSet));

    }

    onSetPasswordBtnClick = (indData) => {
        let idIndex = this.state.visibleHeader.indexOf("account_id");
        let patintIdIndex = this.state.visibleHeader.indexOf("id");
        let cognitoUsernameIndex = this.state.visibleHeader.indexOf("cognito_username");
        let accountId = indData[idIndex];
        let patientId = indData[patintIdIndex];
        let cognitoUsername = indData[cognitoUsernameIndex];
        let user = {
            cognito_username: cognitoUsername,
            account_id: accountId,
            id: patientId
        };
        this.setState({ openSetPasswordModal: true, selectedUser: user })
    }

    onPasswordEmit = (password) => {
        this.props.emitPassword(password, this.state.selectedUser);
        this.setState({ openSetPasswordModal: false, selectedUser: null });
    }

    render() {
        let { role } = this.state;
        let showAddNew = role.indexOf('admplus') !== -1
        return (
            <View.MainLayout>
                <div className="card-header header-sm align-items-center p-1">
                    <div className="row justify-content-between">
                        <div className="col-12 col-md-4 col-lg-3 mb-0 align-self-center">  User List
                                        <label className="badge badge-outline-info ml-2 mb-0 p-1">
                                {showAddNew && <a onClick={this.redirectToCreateView.bind(this)} className="pointer-cursor text-info"><i className="mdi mdi-plus"></i> Add New</a>}
                            </label>
                        </div>
                        <div className="col-12 col-md-3 col-lg-3 form-group mb-0">
                            <SearchPageViewComponent
                                renderFilteredData={this.renderFilteredDataFromSearch.bind(this)}
                                dataToFilter={this.state.filteredData}></SearchPageViewComponent>
                        </div>
                    </div>
                </div>
                {(this.state.loading || this.props.isDeleteInProgress) && <div className="clinytic-line-loader"></div>}
                <div className="card-body p-0">
                    <View.ScreenLayout>
                        <table className="table table-hover table-sm">
                            <thead className="thead-light">
                                {this.state.formatedHeader &&
                                    <tr style={{ textTransform: 'capitalize' }} >
                                        {this.state.formatedHeader.map((indHeader, index) =>
                                            <th key={index} style={{ display: indHeader.key === "id" || indHeader.key === "is_stage" ? 'none' : '' }}>{indHeader.value}</th>
                                        )}
                                        <th>Actions</th>
                                    </tr>
                                }
                            </thead>
                            <tbody>
                                {this.state.finalTableData &&
                                    <React.Fragment>
                                        {this.state.finalTableData.map((indFinalData, index) =>
                                            <tr key={index}>
                                                {indFinalData.map((indRow, jndex) =>
                                                    <td key={jndex} style={{ display: jndex === 0 || jndex === 1 ? 'none' : '' }} className={jndex === 2 ? "text-success" : ""}>{indRow}</td>
                                                )}
                                                <td>
                                                    {indFinalData[1] === true &&
                                                        <button
                                                            title="Activate User"
                                                            className="btn btn-inverse-dark btn-icon btn-rounded" onClick={this.onStageUser.bind(this, indFinalData)}><i className="mdi mdi-table-edit btn-icon-append"></i>
                                                        </button>

                                                    }
                                                    <button
                                                        title="Edit"
                                                        className="btn btn-inverse-dark btn-icon btn-rounded" onClick={this.onUserEditClicked.bind(this, indFinalData[0])}><i className="mdi mdi-lead-pencil btn-icon-append"></i>
                                                    </button>
                                                    {showAddNew && <button className="btn btn-inverse-dark btn-icon btn-rounded" onClick={this.onSetPasswordBtnClick.bind(this, indFinalData)}
                                                        title="Set/Reset user password"
                                                    ><i className="mdi mdi-lock-outline btn-icon-append"></i>
                                                    </button>}
                                                    <button title="Delete"
                                                        className="btn btn-inverse-dark btn-icon btn-rounded" onClick={this.onUserDeleteClicked.bind(this, indFinalData[0])}><i className="mdi mdi-delete-forever btn-icon-append" ></i></button>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                }
                            </tbody>
                        </table>
                        <div className="dropdown-divider"> </div>
                    </View.ScreenLayout>

                    <View.MobileLayout>
                        {this.state.finalTableData &&
                            <React.Fragment>
                                {this.state.finalTableData.map((indFinalData, index) =>
                                    <View.MobileIndCardWrapper key={index}>
                                        <h6 className="ml-1 mb-1 mt-0"><span className="bg-success dot-indicator d-inline-block">  </span>
                                            <span>{indFinalData[4]}</span>
                                            <View.ActionsColumnMobile>
                                                {indFinalData[1] === true && <a key={index + 'activate'} style={{ padding: '15px' }} className="dropdown-item" onClick={this.onStageUser.bind(this, indFinalData)}><i className="mdi mdi-table-edit mr-1"></i> Activate User</a>}
                                                <a key={index + 'edit'} style={{ padding: '15px' }} className="dropdown-item" onClick={this.onUserEditClicked.bind(this, indFinalData[0])}><i className="mdi mdi-lead-pencil mr-1"></i> Edit</a>
                                                <a key={index + 'delete'} style={{ padding: '15px' }} className="dropdown-item" onClick={this.onUserDeleteClicked.bind(this, indFinalData[0])}><i className="mdi mdi-delete-forever mr-1"></i> Delete</a>
                                            </View.ActionsColumnMobile>
                                        </h6>
                                        <small className="text-muted mr-1"><i className="mdi mdi-human-handsdown text-muted mr-1 "></i>Cog Username: {indFinalData[5]}</small>
                                        <small className="text-muted mr-1"><i className="mdi mdi-phone text-muted mr-1 "></i>{indFinalData[6]}</small>
                                        <div className="pt-1">
                                            <div>
                                                {indFinalData[7] && <small className="text-muted mr-1"><i className="mdi mdi-email text-muted mr-1 "></i>{indFinalData[7]}</small>}
                                                {indFinalData[8] && <small className="text-muted mr-1"><i className="mdi mdi-apps text-muted mr-1 "></i>Role: {indFinalData[8]}</small>}
                                            </div>
                                            <div>
                                                {indFinalData[9] && <small className="text-muted mr-1"><i className="mdi mdi-hospital-building"></i> Dept: {indFinalData[9]}</small>}
                                                {/*account id*/} {indFinalData[10] && <small className="text-muted mr-1"><i className="mdi mdi-account"></i> {indFinalData[10]}</small>}
                                            </div>
                                            <div>
                                                {indFinalData[11] && <small className="text-muted mr-1"><i className="mdi mdi-pencil"></i> Created At: {indFinalData[11]}</small>}
                                                <small className="text-muted mr-1"><i className="mdi mdi-qrcode-scan"></i> MFA Enabled:{indFinalData[12] ? 'Yes' : 'No'}</small>
                                            </div>
                                        </div>
                                    </View.MobileIndCardWrapper>
                                )}
                            </React.Fragment>
                        }
                    </View.MobileLayout>
                    {this.state.isStageModalOpen && this.state.stageModalData &&
                        <CommonModalView open={this.state.isStageModalOpen} emitModalCloseEvent={this.getEmitedModalCloseEvent.bind(this)} title={'Activate User'}>
                            <StageUserFormModalComponent userData={this.state.stageModalData} emitStageUserData={this.getEmitedStageUserData.bind(this)}></StageUserFormModalComponent>
                        </CommonModalView>
                    }
                    {
                        (this.state.openSetPasswordModal) &&
                        <CommonModalView open={this.state.openSetPasswordModal} emitModalCloseEvent={() => this.setState({ openSetPasswordModal: false })} title={'Set User Password'}>
                            <SetUserPasswordComponent emitPassword={this.onPasswordEmit.bind(this)} />
                        </CommonModalView>
                    }
                </div>
            </View.MainLayout>
        );
    }
}

export default UserListViewComponent;
