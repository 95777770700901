import React from 'react';
import View from '../ChatPanel/ViewHelper';

class DoctorNotesComponent extends React.Component {
    constructor(props) {
        super(props)
        let role = sessionStorage.getItem('userRole');
        this.state = {
        }
    }

    render() {
        let { isDoctor, chatRoomId, patientDetail } = this.props;
        let url = 'api_key=0ED10A7AFB3D44DEBB96&';

        // if (patientDetail.age && patientDetail.age.no_of_years) {
        //     url = url + `age=${patientDetail.age.no_of_years}&`
        // }
        // if (patientDetail.age && patientDetail.gender) {
        //     url = url + `gender=${patientDetail.gender}&`
        // }
        // if (chatRoomId) {
        //     url = url + `cid=${chatRoomId}`
        // }
        return <View.OuterLayout padding={'0px'} onHide={this.props.onHide.bind(this)} title={`${'Clinical Notes'}`}>
            <View.ChatHistory padding={'0px'} height={"500px"} overflowY={'hidden'}>
                <div className="accordion" id="accordionExample">
                    <div className="card">
                        <div className="card-header" id="headingOne">
                            <h2 className="mb-0">
                                <button className="btn btn-link p-0" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Emr Notes
                                </button>
                            </h2>
                        </div>

                        <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
                            <div className="card-body p-0" style={{ height: "58vh" }}>
                                <iframe src={`https://ihop-dot-kaisehain01-dev01.appspot.com/${chatRoomId}/create_emr_notes`} frameBorder="0" style={{ height: "100%", width: '100%' }} />
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-header" id="headingTwo">
                            <h2 className="mb-0">
                                <button className="btn btn-link collapsed p-0" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Timeline
                                </button>
                            </h2>
                        </div>
                        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                            <div className="card-body p-0" style={{ height: "58vh" }}>
                                <iframe src={`https://ihop-dot-kaisehain01-dev01.appspot.com/${chatRoomId}/timeline_view`} frameBorder="0" style={{ height: "100%", width: '100%' }} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <iframe src={`https://ihop.arintra.com/intake?${url}`} frameBorder="0" style={{ height: "100%", width: '100%' }} /> */}
            </View.ChatHistory>

        </View.OuterLayout>
    }
}

export default DoctorNotesComponent;