import React from "react";
// component imports
import HeaderWrapper from '../../Common/Header/HeaderWrapper';
import AppointmentsViewComponent from './ViewComponents/AppointmentsViewComponent';
// service imports
import appointmentService from '../../Services/appointment.service';
import userService from '../../Services/users.service';
import onlineConsultationService from '../../Services/onlineConsultation.service';
// Utils import
import Utils from '../../Shared/utils/utils';
// import userAuth from '../../Authentication/authActions';
// common imports
import SweetAlertComponent from '../../Common/Alert/SweetAlertComponent';
import CommonModalView from '../../Common/Modal/CommonModalView';
import PatientSmsStatusComponent, { source as shootSMSSource } from '../Patient/Component/PatientSmsStatusComponent';
import AfterCallSelectionModal from './Modal/AfterCallSelectionModal';
import LinkModalComponent from './Modal/LinkModalComponent';
// additional plugin imports
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from "../../Common/Spinner/Spinner";
import { Helmet } from "react-helmet";
import chatRoomService from "../../Services/chatroom.service";
import axios from 'axios';
import UAParser from 'ua-parser-js';
import IdleTimer from 'react-idle-timer';
import { getImgPath } from "../../providerConfig";
import { withSnackbar } from 'notistack';

class AppointmentsViewContainer extends React.Component {
    constructor(props) {
        super(props);
        let locationState = this.props.location && this.props.location.state ? this.props.location.state : null;
        let accInfo = sessionStorage.getItem('accountInfo');
        let doc = sessionStorage.getItem('appointmentDoc') ? JSON.parse(sessionStorage.getItem('appointmentDoc')) : null;
        this.state = {
            inActivityTimeout: 60000, // 1minute
            defaultRefreshInterval: 30000, // 30s
            currRefreshInterval: 30000, // 
            setRefreshTimer: false,
            callInitiateLoading: false,
            apptRefreshTimer: null,
            isNfnDisabled: false, //if false, appt refreshes using notifications. if true autorefresh happens.

            accountInfo: accInfo ? JSON.parse(accInfo) : null,
            show: false,

            date: locationState ? locationState.appointmentDate : new Date(),
            loading: false,
            isRoleDoctor: (sessionStorage.getItem('userRole').indexOf('doctor') !== -1 || sessionStorage.getItem('userRole').indexOf('admin') !== -1),
            linkedDoctor: null,
            doctorList: null,
            selectedDoc: doc && doc.hasOwnProperty('doctor_id') ? doc : null,
            firstTimeLoading: true,
            mobileNav: false,
            userIpAddress: null,
            alert: {
                type: '',
                title: '',
                text: ''
            },
            appointmentConfig: null,
            queryParams: {},
            smsStatusData: null,
            isSmsModalOpen: false,
            smsStatus: '',
            isTriggerLoading: false,
            listLoading: false,
            isOnlineMode: false,
            isAfterCallModalOpen: false,
            afterCallAppointmentId: locationState && locationState.appointmentId ? locationState.appointmentId : null,
            callAppointmentData: null,
            selectedAppointment: null,
            isLinkModalOpen: false
        };
         this.getIpAddress();
        this.parser = new UAParser();
         this.getAccountInfo();
         this.getAccountInfoRaw().then(function(result){
            
         })
         

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        // this.checkAppointmentIdAfterCallEnd();
    }

    async isNfnDisabled() {
        let isOff = await navigator.permissions.query({ name: 'notifications' })
            .then((result) => result.state === 'denied')
            .catch(() => true);

        if (!isOff) {
            this.unsetTimer();
            if (this.apptTimeOut) clearTimeout(this.apptTimeOut);
            if (this.idleTimer) this.idleTimer.pause();
        }

        this.setState({ isNfnDisabled: isOff, setRefreshTimer: isOff });
    }
    async getAccountInfoRaw() {
        try {
            let account_id = sessionStorage.getItem('s3AccountId');
            let user_id = sessionStorage.getItem('userId');
            let res = await onlineConsultationService.getAccountInfo(account_id, 'video', user_id);
            if (res && res.data) {
                sessionStorage.setItem('accountInfo', JSON.stringify(res.data));
                return res.data;
            }
        } catch (err) {
            console.log(err);
        }
    }
    async getAccountInfo() {
        if (this.state.accountInfo) return;
        try {
            let account_id = sessionStorage.getItem('s3AccountId');
            let user_id = sessionStorage.getItem('userId');
            let res = await onlineConsultationService.getAccountInfo(account_id, 'video', user_id);
            if (res && res.data) {
                sessionStorage.setItem('accountInfo', JSON.stringify(res.data));
                this.setState({ accountInfo: res.data }, () => this.checkForSMsCredit());
            }
        } catch (err) {
            console.log(err);
        }
    }

    componentDidMount() {
        this.isNfnDisabled();

        this.setState({
            loading: true,
            listLoading: true
        }, () => this.getListData());
    }

    checkForSMsCredit() {
        let userRole = sessionStorage.getItem('userRole') ? sessionStorage.getItem('userRole') : null;
        if (!userRole) {
            return;
        }
        if ((userRole.indexOf('r:doctor') !== -1 || userRole.indexOf('r:admin') !== -1) && userRole.indexOf('f:smsvolet') !== -1) {
            let accountInfo = sessionStorage.getItem('accountInfo') ? JSON.parse(sessionStorage.getItem('accountInfo')) : null;
            if (!accountInfo) {
                return;
            }
            if (accountInfo.sms_credit_available && accountInfo.min_sms_balance) {
                if (parseInt(accountInfo.sms_credit_available) < parseInt(accountInfo.min_sms_balance)) {
                    let isPopupShow = sessionStorage.getItem('isPopupShow');
                    if (!isPopupShow) {
                        this.props.enqueueSnackbar('SMS balance is low please recharge!!!', { variant: 'error' });
                    }
                    sessionStorage.setItem('isPopupShow', 'true')
                    return;
                }
            }
        }
    }


    getListData() {
        if (!this.state.isRoleDoctor) {
            // this.fetchDoctorList();
            if (this.state.selectedDoc) {
                if (!this.state.doctorList) {
                    this.getDoctorLinkedToUser();
                    // return;
                }
                this.getAppointmentViewListData(this.state.selectedDoc.doctor_id)
            } else {
                this.getDoctorLinkedToUser();
            }
        } else this.getAppointmentViewListData();
    }

    async getIpAddress() {
        delete axios.defaults.headers.common["Authorization"];
        axios.get('https://api.ipify.org')
            .then((response) => {
                let { userIpAddress } = this.state;
                userIpAddress = response.data;
                this.setState({ userIpAddress });
                // 
            })
            .catch((error) => {
                let { userIpAddress } = this.state;
                userIpAddress = '';
                this.setState({ userIpAddress });
            });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        // do things with nextProps.someProp and prevState.cachedSomeProp
        if (nextProps.notificationData) {
            return {
                notificationData: nextProps.notificationData,
                // ... other derived state properties
            };
        }

    }

    componentDidUpdate(prevProps, prevState) {
        for (let index in this.state.notificationData) {
            let indNotification = this.state.notificationData[index];
            if (indNotification.notification_type === "new_appointment" || indNotification.notification_type === "appointment_refresh" || indNotification.notification_type === "online" || indNotification.notification_type === "payment_done") {
                this.state.notificationData.splice(index, 1);
                this.setState({ notificationData: this.state.notificationData }, () => this.autoRefreshList())
                return;
            }
        }
    }

    _onAction = (e) => {
        //console.log('user did something', e);
        if (this.state.currRefreshInterval > this.state.defaultRefreshInterval) {
            
            this.unsetTimer();
            this.setRefreshTimer(this.state.defaultRefreshInterval);
            this.idleTimer.reset();
            clearTimeout(this.apptTimeOut)
        }
    }

    _onActive = (e) => {
        console.log('user is active', e);
        if (this.state.currRefreshInterval > this.state.defaultRefreshInterval) {
            console.log('loop reset to ' + this.state.defaultRefreshInterval / 60000 + 'min');
            this.unsetTimer();
            let formatedDate = this.state.date && this.state.date instanceof Date ? this.state.date.toDateString() : this.state.date ? new Date(this.state.date) : '';
            if(!formatedDate){
                return;
            }
            if (formatedDate === new Date().toDateString()) {
                this.setRefreshTimer(this.state.defaultRefreshInterval);
            }
            this.idleTimer.reset();
            clearTimeout(this.apptTimeOut)
        }
    }

    // when (IT)inactivity timeout > refresh interval(RT), refresh appt list after every RT time.
    // After every IT time, RT = RT * 2
    _onIdle = (e) => {
        console.log('user is idle', e);

        if (this.state.inActivityTimeout <= this.state.currRefreshInterval) {
            this.unsetTimer();
            let formatedDate = this.state.date && this.state.date instanceof Date ? this.state.date.toDateString() : this.state.date ? new Date(this.state.date) : '';
            if(!formatedDate){
                return;
            }
            if (formatedDate === new Date().toDateString()) {
                this.setRecursiveTimeout(this.state.currRefreshInterval - this.state.inActivityTimeout);
            }
        } else {
            this.unsetTimer()
            let formatedDate = this.state.date && this.state.date instanceof Date ? this.state.date.toDateString() : this.state.date ? new Date(this.state.date) : '';
            if(!formatedDate){
                return;
            }
            if (formatedDate === new Date().toDateString()) {
                this.setRefreshTimer(this.state.currRefreshInterval * 2);
            }
            console.log('loop refresh double:' + this.state.currRefreshInterval * 2 / 60000 + 'min');
            this.idleTimer.reset();
        }
    }

    // when (IT)inactivity timeout <= refresh interval(RT), set an interval at 2*RT interval only after calling API
    // after RT time (to prevent multiple stored timeouts in system)
    setRecursiveTimeout(interval) {
        this.apptTimeOut = setTimeout(() => {
            console.log('calling API after:' + (interval) / 60000 + 'min')
            let nextTimeOut = this.state.currRefreshInterval * 2;
            console.log('Next API will be called after:' + nextTimeOut / 60000 + 'min')
            this.setState({ currRefreshInterval: nextTimeOut });
            this.autoRefreshList()
            this.setRecursiveTimeout(nextTimeOut);
        }, interval);
    }

    setRefreshTimer(timeInterval) {
        let apptRefreshTimer = setInterval(() => this.autoRefreshList(''), timeInterval);
        console.log(`curr ref interval: ${timeInterval / 60000}min`)
        this.setState({ setRefreshTimer: false, apptRefreshTimer, currRefreshInterval: timeInterval });
    }

    unsetTimer() {
        clearInterval(this.state.apptRefreshTimer);
        this.setState({ apptRefreshTimer: null });
    }

    componentWillUnmount() {
        this.unsetTimer();
        clearTimeout(this.apptTimeOut);
        if (this.idleTimer) this.idleTimer.pause();
    }

    async autoRefreshList() {
        try {
            if (window.location.pathname.indexOf('appointment') === -1) {
                this.setState({ setRefreshTimer: false });
                return;
            }
            let doctor_id = this.state.selectedDoc ? this.state.selectedDoc.doctor_id : null;
            let date = Utils.getFormatedDate(this.state.date);
            var response;
            console.log('API called ++');
            if (this.state.isOnlineMode) {
                response = await appointmentService.getOnlineAppointmentList(date);
            } else {
                response = await appointmentService.getAppointmentList(date, this.state.queryParams, doctor_id);
            }
            switch (response.type) {
                case "error":
                    console.log('Error while auto refreshing appointment list');
                    break;
                case "success":
                    this.setState({
                        appointmentConfig: response.data
                    })
                    break;
                default:
                    return;
            }
        } catch (error) {
            console.log('error while auto refreshing appointment list');
        }
    }


    checkIfAppointmentIdPresentInNotificationData(data) {
        let { isAfterCallModalOpen, afterCallAppointmentId } = this.state;
        // pending_prescription
        if (!afterCallAppointmentId) {
            return;
        }
        for (let index in data.rows) {
            let indPrescription = data.rows[index];
            if (indPrescription.id === afterCallAppointmentId && indPrescription.pending_prescription) {
                isAfterCallModalOpen = true;
                this.setState({ isAfterCallModalOpen }, () => this.getAppointmentDetailForModal(data));
                return;
            }
        }

        this.clearshowCallEndModalVar()
        // notificationData = JSON.parse(notificationData);
        // if (notificationData && notificationData.length > 0) {
        //     if (notificationData.indexOf(afterCallAppointmentId) !== -1) {
        //         isAfterCallModalOpen = true;
        //         this.setState({ isAfterCallModalOpen }, () => this.getAppointmentDetailForModal(data));
        //         return;
        //     }
        // }
    }

    clearshowCallEndModalVar() {
        const { state } = this.props.location;
        let { afterCallAppointmentId } = this.state;
        afterCallAppointmentId = '';
        this.setState({ afterCallAppointmentId });
        if (state && state.appointmentId) {
            const stateCopy = state;
            delete stateCopy.appointmentId;
            this.props.history.replace({ state: stateCopy });
        }
    }

    getAppointmentDetailForModal(data) {
        let { callAppointmentData, afterCallAppointmentId } = this.state;
        if (!data || !data.rows || data.rows.length === 0) {
            callAppointmentData = null;
            this.setState({ callAppointmentData });
            return;
        }
        for (let index in data.rows) {
            let indPre = data.rows[index];
            if (indPre.id === afterCallAppointmentId) {
                callAppointmentData = indPre;
                this.setState({ callAppointmentData });
            }
        }

    }

    async fetchDoctorList() {
        if (!this.state.loading) {
            this.setState({ loading: true });
        }
        try {
            const list = await appointmentService.fetchSettings();
            this.checkDoctorListResponse(list);
        } catch (error) {
            console.log('error');
        }
    }

    checkDoctorListResponse(response) {
        switch (response.type) {
            case "error":
                this.setState({ doctorList: [] });
                break;
            case "success":

                let doctorList = response.data && response.data.rows ? response.data.rows : [];
                this.setState({ doctorList });
                break;
            default:
                break;
        }
    }

    async getDoctorLinkedToUser() {
        if (!this.state.loading) {
            this.setState({ loading: true });
        }
        try {
            // let account_id = sessionStorage.getItem("s3AccountId");
            // var res = await userService.getDoctors(account_id);
            var res = await appointmentService.getDoctorLinkedToUser();

            this.checkDoctorData(res);
        } catch (error) {
            console.log('error');
            this.getAppointmentViewListData();
        }
    }

    checkDoctorData(response) {
        if (!response) {
            let { doctorList } = this.state;
            doctorList = null;
            this.setState({ doctorList }, () => this.getAppointmentViewListData());
            return;
        }
        switch (response.type) {
            case "success":
                this.convertLinkDoctorResponseToList(response.data);
                break;
            case "error":
                let { doctorList } = this.state;
                doctorList = null;
                this.setState({ doctorList }, () => this.getAppointmentViewListData());
            default:
                this.getAppointmentViewListData();
                return;
        }
    }

    convertLinkDoctorResponseToList(data) {
        if (!data) {
            this.fetchDoctorList();
            this.getAppointmentViewListData();
            return;
        }
        let { doctorList } = this.state;
        if (data.doctor_id && !Array.isArray(data.doctor_id)) {
            let docData = data.doctor_id ? data.doctor_id : data.rows;
            doctorList = [{
                name: docData.doctor_name ? docData.doctor_name : docData.name,
                cognito_user_sub: docData.doctor_id ? docData.doctor_id : docData.cognito_user_sub
            }];
            this.setState({ doctorList }, () => this.getAppointmentViewListData());
            return;
        }

        if ((data.doctor_id && data.doctor_id.length === 0) || (data.rows && data.rows.length === 0)) {
            doctorList = data.doctor_id ? data.doctor_id : data.rows;
            this.setState({ doctorList }, () => this.getAppointmentViewListData(), this.fetchDoctorList());
        } else {
            let type = data.doctor_id ? 'selected' : 'all';
            doctorList = data.doctor_id ? data.doctor_id : data.rows;
            doctorList.sort((a, b) => a.name.localeCompare(b.name, 'en', { 'sensitivity': 'base' }));
            this.setState({ doctorList }, () => {
                this.getAppointmentViewListData(this.state.selectedDoc && this.state.selectedDoc.doctor_id ? this.state.selectedDoc.doctor_id : null);
                if (type === "selected") {
                    this.fetchFullDoctorList();
                }
            });
        }
    }

    async fetchFullDoctorList() {
        try {
            const list = await appointmentService.fetchSettings();
            this.checkFullDoctorListResponse(list);
        } catch (error) {
            console.log('error');
        }
    }

    checkFullDoctorListResponse(response) {
        switch (response.type) {
            case "error":
                console.log('error')
                break;
            case "success":

                let fullDocList = response.data && response.data.rows ? response.data.rows : [];
                this.formatDoctorList(fullDocList);
                break;
            default:
                break;
        }
    }

    formatDoctorList = (fullDocList) => {
        let { doctorList } = this.state;
        for (let index in fullDocList) {
            let indDoc = fullDocList[index];
            for (let jndex in doctorList) {
                let indSelDoc = doctorList[jndex];
                if (indSelDoc.cognito_user_sub === indDoc.cognito_user_sub) {
                    doctorList[jndex]['multi_consultation_fee'] = fullDocList[index]['multi_consultation_fee'] ? fullDocList[index]['multi_consultation_fee'] : null
                }
            }
        }
        this.setState({ doctorList });
    }

    onUnorderedViewEvent = (data) => {
        
        // patient_id
        this.getUnorderedListData("1611919408d8d03a6c", "teleheal:2021-01-29$appt:56fb1df3-f301-49a0-97a9-cb28686efb1d:2021-01-29T11:23:30:406cc87", "teleheal")
        // this.getUnorderedListData(data.patient_id,data.id,data.account_id)
    }

    async getUnorderedListData(patientId, appointmentId, accountId) {
        try {

            let response = await appointmentService.getInvoiceUnorderedListData(patientId, appointmentId, accountId);
            
        } catch (error) {
            console.log(error);
        }
    }

    async getOfflineAppointmentViewListData() {
        if (!this.state.loading) {
            this.setState({ loading: true });
        }
        try {
            let date = Utils.getFormatedDate(this.state.date);
            var userListResponse = await appointmentService.getAppointmentList(date, this.state.queryParams);
            if (this.state.setRefreshTimer) this.setRefreshTimer(this.state.defaultRefreshInterval);
            this.checkAppointmentResponseData(userListResponse);
        } catch (error) {
            console.log('error');
        }
    }

    async getOnlineAppointmentViewListData() {
        if (!this.state.loading) {
            this.setState({ loading: true });
        }
        try {
            let date = Utils.getFormatedDate(this.state.date);
            var userListResponse = await appointmentService.getOnlineAppointmentList(date);
            if (this.state.setRefreshTimer) this.setRefreshTimer(this.state.defaultRefreshInterval);
            this.checkAppointmentResponseData(userListResponse);
        } catch (error) {
            console.log('error');
        }
    }

    checkAppointmentResponseData(response) {
        switch (response.type) {
            case "error":
                this.onError('No Data Found')
                break;
            case "success":
                this.setState({
                    loading: false,
                    listLoading: false,
                    firstTimeLoading: false,
                    appointmentConfig: response.data
                }, () => {
                    if (this.state.isRoleDoctor) {
                        this.checkIfAppointmentIdPresentInNotificationData(response.data);
                    }
                })
                break;
            default:
                return;
        }
    }

    onError(text) {
        this.setState(prevState => ({
            show: true,
            loading: false,
            firstTimeLoading: false,
            listLoading: false,
            alert: {
                ...prevState.alert,
                type: 'error',
                title: 'Error',
                text: text ? text : 'Error'
            }
        }));
    }

    getEmitedDateValue = (event) => {
        let { selectedDoc, isNfnDisabled } = this.state;
        let selectedDocId = selectedDoc && selectedDoc.doctor_id ? selectedDoc.doctor_id : null;

        let dateIsTodayDate = event && event.toDateString && event.toDateString() === new Date().toDateString();
        if (!dateIsTodayDate) {
            this.unsetTimer();
        }
        if (isNfnDisabled) {
            this.setState({
                date: event,
                loading: true,
                setRefreshTimer: dateIsTodayDate,
            }, () => this.getAppointmentViewListData(selectedDocId));
        } else {
            this.setState({
                date: event,
                loading: true
            }, () => this.getAppointmentViewListData(selectedDocId));
        }
    }


    notify(message) {
        toast.success(message, {
            position: toast.POSITION.TOP_RIGHT,
            containerId: 'A'
        });
    }

    sweetAlertClose = (event) => {
        this.setState({ show: false });
    }

    getEmitedSelectedFilerValues = (event) => {
        this.setState({
            queryParams: event,
            loading: true
        }, () => this.getAppointmentViewListData())
    }

    getEmitedRedirectToEditView = (event) => {
        this.props.history.push(event, { doctorList: { rows: this.state.doctorList } });
    }

    getEmitedSmsStatusUpdateEvent = (event) => {
        if (!event) {
            return;
        }
        let { smsStatusData, isSmsModalOpen } = this.state;
        smsStatusData = event;
        isSmsModalOpen = true;
        this.setState({ smsStatusData, isSmsModalOpen });
    }

    onSmsModalCloseEvent = (event) => {
        let { smsStatusData, isSmsModalOpen } = this.state;
        smsStatusData = null;
        isSmsModalOpen = false;
        this.setState({ smsStatusData, isSmsModalOpen });
    }

    getEmitedViewInvoiceEvent = (event) => {
        this.props.history.push('invoice-list', {
            appointment_id: event.id,
            patient_id: event.patient_id,
            account_id: sessionStorage.getItem('s3AccountId'),
            doctor_id: event.doctor_id,
            patient: {
                name: event.patient.name,
                phone: event.patient.phone,
                email: event.patient.email,
                address: event.patient.address
            }
        });
    }

    getEmitedCreateInvoiceEvent = (event) => {
        this.props.history.push('create-invoice', {
            appointment_id: event.id,
            patient_id: event.patient_id,
            account_id: sessionStorage.getItem('s3AccountId'),
            doctor_id: event.doctor_id,
            patient: {
                name: event.patient.name,
                phone: event.patient.phone,
                email: event.patient.email,
                address: event.patient.address
            }
        });
    }

    getEmitedPrescriptionClickedEvent = (event, obj,data) => {
        if (sessionStorage.getItem('userRole').indexOf('doctor') !== -1 && obj.appointment_type === 'online' && obj.online_patientid) {
            let doc = sessionStorage.getItem('profileInfo');
            this.props.history.push(event, {
                appointment: { ...obj },
                showChat: true,
                patient_id: obj.online_patientid,
                account_id: sessionStorage.getItem('s3AccountId'),
                doctor_id: doc ? JSON.parse(doc).cognito_user_sub : null,
                showPrescription : data
            });
        } else {
            this.props.history.push(event, { appointment: { ...obj }, showChat: false });
        }
    }

    getEmitedAppointmentStatusButtonClicked = (event, obj) => {
        let details = this.parser.getResult();
        this.props.history.push(event, {
            appointment: { ...obj }, appointmentDate: this.state.date, doctorList: this.state.doctorList,
            ipAddr: this.state.userIpAddress, browserInfo: {
                browser: details.browser,
                OS: details.os,
                device: details.device,
                cpu: details.cpu,
                engine: details.engine
            }
        });
    }

    getListForDoctor = (doctor) => {
        let { selectedDoc } = this.state;
        if (selectedDoc && doctor.doctor_id === selectedDoc.doctor_id) {
            doctor.doctor_id = '';
            doctor.doctor_name = '';
            doctor.speciality = '';
        }
        sessionStorage.setItem('appointmentDoc', JSON.stringify(doctor));
        this.setState({ selectedDoc: doctor, loading: true }, () => this.getAppointmentViewListDataWhenDoctorIsSelected(doctor.doctor_id));
    }

    async getAppointmentViewListDataWhenDoctorIsSelected(doctor_id) {
        if (!this.state.loading) {
            this.setState({ loading: true });
        }
        try {
            let date = Utils.getFormatedDate(this.state.date);
            var response = await appointmentService.getAppointmentList(date, this.state.queryParams, doctor_id);
            this.checkAppointmentResponseData(response);
        } catch (error) {
            console.log('error');
        }
    }


    goToVideoCall = (data) => {
        if (!data) return;
        let currentVideoId = sessionStorage.getItem('currentVideoApptId');
        if (currentVideoId && currentVideoId !== 'undefined' && this.props.isPipModeOn) {
            if (currentVideoId === data.id) {
                // if video already running for this apptId (video is in pipMode as user was able to get Access to Video Btn)
                this.props.closePipMode();
            } else {
                //if user wants to open video for another patient(pipMode), show snackbar
                this.props.enqueueSnackbar('Please complete ongoing appointment. Check your mini player.')
            }
            return;
        } else {
            this.setState({ isAfterCallModalOpen: false, callInitiateLoading: true }, () => this.checkinPatient(data));
        }
    }
    goToBookings = () => {
        this.props.history.push('/service-sold');
    }

    getEmitedLinkButtonClickedEvent = (event) => {
        let { selectedAppointment, isLinkModalOpen } = this.state;
        selectedAppointment = event
        isLinkModalOpen = true;
        this.setState({ selectedAppointment, isLinkModalOpen })
    }

    onlineButtonToggle = () => {
        let selectedDocId = this.state.selectedDoc && this.state.selectedDoc.doctor_id ? this.state.selectedDoc.doctor_id : null;
        let { isOnlineMode } = this.state;
        isOnlineMode = !isOnlineMode;
        this.setState({ isOnlineMode });
        if (isOnlineMode) {
            this.getOnlineAppointmentViewListData();
        } else {
            this.getOfflineAppointmentViewListData(selectedDocId);
        }
    }

    getAppointmentViewListData(doctor_id) {
        if (this.state.isOnlineMode) {
            this.getOnlineAppointmentViewListData();
        } else {
            this.getOfflineAppointmentViewListData(doctor_id);
        }
    }

    async checkinPatient(data) {
        if (!data || !data.id || !data.room_id) return;

        this.setState({ loading: true });
        try {
            let details = this.parser.getResult();
            let connection = window.navigator.connection || window.navigator.mozConnection || window.navigator.webkitConnection;
            let finalData = {
                "appointment_id": data.id,
                "doctor_id": data.doctor_id,
                "account_id": data.account_id,
                "online_patientid": data.online_patientid,
                "status": "initiated",
                "room_id": data.room_id,
                'info': {
                    ip: this.state.userIpAddress,
                    "network_type": connection && connection.effectiveType ? connection.effectiveType : '',
                    browserInfo: {
                        browser: details.browser,
                        OS: details.os,
                        device: details.device,
                        cpu: details.cpu,
                        engine: details.engine
                    }
                }
            }
            const response = await chatRoomService.callrecordInitiateDoctor(finalData);
            this.checkPatientCheckedInResponse(response, data);
        } catch (error) {
            let { callInitiateLoading } = this.state;
            callInitiateLoading = false;
            this.setState({ callInitiateLoading });
            console.log(error)
        }
    }

    checkPatientCheckedInResponse(response, apptData) {
        if (!response) {
            this.setState({ loading: false, callInitiateLoading: false, show: true, alert: { type: 'error', text: 'Please try again later', title: 'Error while calling!' } });
            return;
        }

        switch (response.type) {
            case "error":
                this.setState({ loading: false, callInitiateLoading: false, show: true, alert: { type: 'error', text: 'Please try again later', title: 'Error while calling!' } })
                break;
            case "success":
                let token = response.data && response.data.token;
                let doctor = {
                    doctor_id: apptData.doctor_id,
                    doctor_name: apptData.doctor_name,
                    sourceView: 'appointment'
                }
                let { accountInfo } = this.state;
                let locationstate = {
                    token,
                    patient: apptData.patient,
                    doctor,
                    video_settings: accountInfo && accountInfo.video_settings || '',
                    quality: accountInfo && accountInfo.video_settings && accountInfo.video_settings.default_value && accountInfo.video_settings.default_value.key,
                    appointmentId: apptData.id,
                    appointmentDate: apptData.appointment_date,
                    appointmentSlot: apptData.appointment_slot,
                    callEndId: response && response.data && response.data.id ? response.data.id : null
                };
                this.setState({ callInitiateLoading: false });
                
                this.props.emitVideoRoomState({
                    apptData,
                    dummyLocationState: locationstate,
                    room_id: apptData.room_id,
                    providerName: response.data.account_id,
                    videoRoomHash: `/${response.data.account_id}/video-room/${apptData.room_id}`
                });
                break;
            default:
                break;
        }
    }

    onLinkModalCloseEvent = (event) => {
        let { isLinkModalOpen, selectedAppointment } = this.state;
        isLinkModalOpen = false;
        selectedAppointment = null;
        this.setState({ isLinkModalOpen, selectedAppointment })
    }

    onAfterCallModalCloseEvent = (event) => {
        let { isAfterCallModalOpen, afterCallAppointmentId } = this.state;

        if (event === "close") {
            isAfterCallModalOpen = false;
            this.setState({ isAfterCallModalOpen })
            const { state } = this.props.location;
            if (state && state.afterCallAppointmentId) {
                this.duplicateSurvey(state.afterCallAppointmentId);
                const stateCopy = { ...state };
                delete stateCopy.afterCallAppointmentId;
                this.props.history.replace({ state: stateCopy });
            }
        } else {
            this.props.enqueueSnackbar('To close click on CLOSE button!!!')
        }
        // isAfterCallModalOpen = false;
        // this.setState({ isAfterCallModalOpen })
        // const { state } = this.props.location;
        // if (state && state.afterCallAppointmentId) {
        //     this.duplicateSurvey(state.afterCallAppointmentId);
        //     const stateCopy = { ...state };
        //     delete stateCopy.afterCallAppointmentId;
        //     this.props.history.replace({ state: stateCopy });
        // }
    }

    render() {
        let showDoctorList = !this.state.isRoleDoctor && ((this.state.doctorList && this.state.doctorList.length > 0) || (this.state.linkedDoctor && this.state.linkedDoctor.doctor_id));
        let loading = this.state.loading;
        return (
            <>
                {this.state.isNfnDisabled && <IdleTimer
                    ref={ref => { this.idleTimer = ref }}
                    element={document}
                    onActive={this.onActive}
                    onIdle={this.onIdle}
                    onAction={this.onAction}
                    debounce={250}
                    timeout={this.state.inActivityTimeout} />}
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Appointments</title>
                    <link rel="icon" type="image/png" href={getImgPath('logolight')} sizes="16x16" />
                </Helmet>
                <div>
                    {/* Header For screen */}
                    <HeaderWrapper {...this.props} screen showDoctorList={showDoctorList} doctorList={this.state.doctorList} getListForDoctor={this.getListForDoctor.bind(this)}
                        selectedDoc={this.state.selectedDoc} toggleMobileNav={() => this.setState({ mobileNav: !this.state.mobileNav })} mobileNav={this.state.mobileNav}></HeaderWrapper>
                    <div className="container-fluid page-body-wrapper">
                        {this.state.firstTimeLoading && <div style={{ marginLeft: '47%' }}><Spinner></Spinner></div>}
                        {/* Header For mobile view */}
                        <HeaderWrapper {...this.props} mobile mobileNav={this.state.mobileNav} showDoctorList={showDoctorList} doctorList={this.state.doctorList} getListForDoctor={this.getListForDoctor.bind(this)}
                            selectedDoc={this.state.selectedDoc}></HeaderWrapper>
                            
                        {!this.state.firstTimeLoading && this.state.appointmentConfig &&
                            <AppointmentsViewComponent
                                loading={loading}
                                accountInfo={this.state.accountInfo}
                                date={this.state.date}
                                callInitiateLoading={this.state.callInitiateLoading}
                                isOnlineMode={this.state.isOnlineMode}
                                listLoading={this.state.listLoading}
                                appointmentConfig={this.state.appointmentConfig}
                                emitDateValue={this.getEmitedDateValue.bind(this)}
                                onlineButtonToggle={this.onlineButtonToggle.bind(this)}
                                emitSelectedFilerValues={this.getEmitedSelectedFilerValues.bind(this)} emitRedirectToEditView={this.getEmitedRedirectToEditView.bind(this)}
                                emitAppointmentStatusButtonClicked={this.getEmitedAppointmentStatusButtonClicked.bind(this)}
                                emitPrescriptionClickedEvent={this.getEmitedPrescriptionClickedEvent.bind(this)}
                                emitUnorderedViewEvent={this.onUnorderedViewEvent.bind(this)}
                                emitCreateInvoiceEvent={this.getEmitedCreateInvoiceEvent.bind(this)}
                                emitViewInvoiceEvent={this.getEmitedViewInvoiceEvent.bind(this)}
                                emitSmsStatusUpdateEvent={this.getEmitedSmsStatusUpdateEvent.bind(this)}
                                goToVideoCall={this.goToVideoCall.bind(this)}
                                goToBookings={this.goToBookings.bind(this)}
                                emitLinkButtonClickedEvent={this.getEmitedLinkButtonClickedEvent.bind(this)}
                            ></AppointmentsViewComponent>
                        }
                        {this.state.show &&
                            <SweetAlertComponent show={this.state.show} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.sweetAlertClose}></SweetAlertComponent>
                        }
                        {
                            this.state.isSmsModalOpen &&
                            <CommonModalView
                                open={this.state.isSmsModalOpen}
                                emitModalCloseEvent={this.onSmsModalCloseEvent.bind(this)}
                                title={`Shoot SMS For :  ${this.state.smsStatusData && this.state.smsStatusData.patient ? this.state.smsStatusData.patient.name : ''}`}>
                                <PatientSmsStatusComponent
                                    source={shootSMSSource.appt_list}
                                    patientDetail={this.state.smsStatusData.patient}
                                    apptDetail={this.state.smsStatusData}
                                    emitSmsStatusSaveEvent={this.onSmsModalCloseEvent.bind(this)}>
                                </PatientSmsStatusComponent>
                            </CommonModalView>
                        }
                        {
                            this.state.isAfterCallModalOpen && this.state.callAppointmentData &&
                            <CommonModalView
                                hideCloseButton={true}
                                open={this.state.isAfterCallModalOpen}
                                emitModalCloseEvent={this.onAfterCallModalCloseEvent.bind(this)}
                                title={`Appointment details  ${this.state.callAppointmentData && this.state.callAppointmentData.patient && this.state.callAppointmentData.patient.name ? `for ${this.state.callAppointmentData.patient.name}` : ''}`}>
                                <AfterCallSelectionModal
                                 accountInfo={this.state.accountInfo}
                                    date={this.state.date}
                                    emitPrescriptionClickedEvent={this.getEmitedPrescriptionClickedEvent.bind(this)}
                                    emitAfterCallModalClose={this.onAfterCallModalCloseEvent.bind(this)}
                                    appointmentData={this.state.callAppointmentData}
                                    emitEditAppointmentEvent={this.getEmitedRedirectToEditView.bind(this)}
                                    emitOnCallReconnectEvent={this.goToVideoCall.bind(this)}
                                >
                                </AfterCallSelectionModal>
                                {/* emitOnCallReconnectEvent()emitEditAppointmentEvent */}
                            </CommonModalView>
                        }
                        {
                            this.state.isLinkModalOpen && this.state.selectedAppointment &&
                            <CommonModalView
                                open={this.state.isLinkModalOpen}
                                emitModalCloseEvent={this.onLinkModalCloseEvent.bind(this)}
                                title={`Copy Link`}>
                                <LinkModalComponent
                                    appointmentData={this.state.selectedAppointment}
                                >
                                </LinkModalComponent>
                                {/* emitOnCallReconnectEvent()emitEditAppointmentEvent */}
                            </CommonModalView>
                        }
                        <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
                    </div>
                </div>
            </>
        );
    }
}

export default withSnackbar(AppointmentsViewContainer);
