import React from "react";

class PatientTableViewComponent extends React.Component {
    constructor(props) {
        super(props);

    }

    componentWillMount() {

    }

    onPatientClicked = (indPatient, event) => {
        event.preventDefault();
        this.props.emitDataOnPatientSelect(indPatient)

    }

    render() {
        const { formattedPatientList } = this.props;
        return (
            <div className="table-responsive">

                <table className="table vital-table table-sm table-hover">

                    <thead className="thead-light">
                        <tr>
                            <th scope="col">Name </th>
                            <th scope="col">Patient HId</th>
                            <th scope="col"> Phone </th>
                            <th scope="col"> Select</th>
                        </tr>
                    </thead>
                    <tbody>
                        {formattedPatientList.map((indPatient, index) =>
                            <tr key={index}>
                                <th scope="row">
                                    {indPatient.name}
                                </th>
                                <td> {indPatient.patient_hid}
                                </td>
                                <td> {indPatient.phone} </td>
                                <td>
                                    <button type="button" className="btn btn-outline-success btn-sm btn-rounded" onClick={this.onPatientClicked.bind(this, indPatient)}>Select</button>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default PatientTableViewComponent;
