import React from "react";
import { Helmet } from "react-helmet";
// component imports
import UserComponent from './Components/UserComponent';
// common imports
// import LoaderComponent from '../../Common/Loader/LoaderComponent';
import HeaderWrapper from '../../Common/Header/HeaderWrapper';
import SweetAlertComponent from '../../Common/Alert/SweetAlertComponent';
// additional plugin imports
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// service imports
import usersService from '../../Services/users.service';
import Spinner from "../../Common/Spinner/Spinner";
import accountService from "../../Services/account.settings";

class UsersViewContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userList: null,
            show: false,
            deleteData: null,
            loading: false,
            firstTimeLoading: true,
            showConfirmation: false,
            mobileNav: false,
            queryParams: null,
            isDeleteInProgress: false,
            reqParams: {
                limit: 20,
                // offset: 20
            },
            alert: {
                type: '',
                title: '',
                text: ''
            }
        }
        this.getUserListData();
    }

    async getUserListData() {
        try {
            this.setState({ loading: true });
            var userListResponse = await usersService.getUsersList(this.state.queryParams, this.state.reqParams);
            this.checkForUserListResponse(userListResponse)
        } catch (error) {
            console.log('error');
        }
    }

    getEmitedNextButtonEvent = (event) => {
        if (!event) {
            return;
        }
        let { reqParams } = this.state;
        reqParams.offset = event;
        this.setState({
            reqParams
        }, this.getUserListData())
        // reqParams: {
        //     limit: 10,
        //     offset: 0
        // },
    }

    checkForUserListResponse(response) {
        if (!response) {
            this.onError(response);
            return;
        }
        switch (response.type) {
            case 'error':
                this.onError(response)
                break;
            case 'success':
                this.setState({
                    loading: false,
                    firstTimeLoading: false,
                    userList: response.data
                });
                break;
            default:
                return
        }
    }

    async resetPasswordById(event) {
        try {
            const response = await usersService.resetPasswordById(event);
            this.checkForResetPasswordResponse(response);
        } catch (error) {
            console.log(error);
        }
    }

    checkForResetPasswordResponse(response) {
        if (!response) {
            this.onError(response);
            return;
        }
        switch (response.type) {
            case 'error':
                this.onError(response);
                break;
            case 'success':
                this.notify('Sms Send For Password Details');
                break;
            default:
                return
        }
    }

    async deleteUserById(event) {
        try {
            const response = await usersService.deleteUserById(event);
            this.checkForDeleteResponse(response);
        } catch (error) {
            console.log(error);
        }
    }

    checkForDeleteResponse(response) {
        if (!response) {
            // deleteData
            let { isDeleteInProgress } = this.state;
            isDeleteInProgress = false;
            this.setState({ isDeleteInProgress }, () => this.onError(response));
            return;
        }
        let { isDeleteInProgress } = this.state;
        switch (response.type) {

            case 'error':

                isDeleteInProgress = false;
                this.setState({ isDeleteInProgress }, () => this.onError(response));

                break;
            case 'success':
                isDeleteInProgress = false;
                this.notify('User Deleted successfully');
                this.setState({ isDeleteInProgress }, () => this.getUserListData());

                break;
            default:
                return
        }
    }

    getEmitedNewUserCreationEvent = () => {
        this.props.history.push('/create-user');
    }

    getEmitedUserEditEvent = (id, data) => {
        
        let role = sessionStorage.getItem('userRole');
        if (role.indexOf('admin') !== -1) { // for both admin and admplus
            let accountId = sessionStorage.getItem('s3AccountId');
            let accountInfo = sessionStorage.getItem('accountInfo');
            accountInfo = accountInfo ? JSON.parse(accountInfo) : null;
            this.props.history.push(
                `/account-user-edit?id=${id}&accountId=${accountId}`, { accountUserData: data, accountDetailData: accountInfo, from: 'users' }
            );
        }
        else if (role.indexOf('usermgmt') !== -1) { // for both admin and admplus
            let accountId = sessionStorage.getItem('s3AccountId');
            let accountInfo = sessionStorage.getItem('accountInfo');
            accountInfo = accountInfo ? JSON.parse(accountInfo) : null;
            this.props.history.push(
                `/account-user-edit?id=${id}&accountId=${accountId}`, { accountUserData: data, accountDetailData: accountInfo, from: 'users' }
            );
        } else {
            this.props.history.push(
                "/create-user?id=" + id,
                { userData: data }
            );
        }
    }

    getEmitedSelectedFilerValues = (event) => {
        this.setState({
            queryParams: event
        }, () => this.getUserListData())
    }

    getEmitedUserDeleteEvent = (event) => {
        let { showConfirmation, deleteData, alert } = this.state;
        alert.type = 'confirmation';
        alert.title = "Delete User";
        alert.text = "Are You Sure?"
        showConfirmation = true;
        deleteData = event
        this.setState({ showConfirmation, deleteData, alert });
    }

    getEmitedResetPasswordEvent = (event) => {
        this.resetPasswordById(event);
    }

    onError(response) {
        this.setState(prevState => ({
            show: true,
            loading: false,
            firstTimeLoading: false,
            alert: {
                ...prevState.alert,
                type: 'error',
                title: 'Error',
                text: (response && response.message) ? response.message : 'Error'
            }
        }));
    }

    notify(message) {
        toast.success(message, { position: toast.POSITION.TOP_RIGHT, containerId: 'A' });
    }

    sweetAlertDeleteClose = (status) => {
        let { showConfirmation } = this.state;
        showConfirmation = false;
        this.setState({ showConfirmation });
        if (status) {
            let { isDeleteInProgress } = this.state;
            isDeleteInProgress = true;
            this.setState({ isDeleteInProgress }, () => this.deleteUserById(this.state.deleteData));
        }
    }

    sweetAlertClose = (event) => {
        this.setState({ show: false });
    }

    getEmitedStageUserData = (event) => {
        this.updateStageUser(event);
    }

    async updateStageUser(data) {
        try {
            const response = await usersService.updateStageUser(data);
            this.checkForStageUserResponse(response);
        } catch (error) {
            console.log(error);
        }
    }

    checkForStageUserResponse(response) {
        if (!response) {
            this.onError(response);
            return;
        }
        switch (response.type) {
            case 'error':
                this.onError(response);
                break;
            case 'success':
                this.notify('User Details Updated successfully');
                this.getUserListData();
                break;
            default:
                break;
        }
    }

    getEmitedRedirectToView = () => {
        let role = sessionStorage.getItem('userRole');
        if (role.indexOf('admplus') !== -1) {
            let accountInfo = sessionStorage.getItem('accountInfo');
            accountInfo = accountInfo ? JSON.parse(accountInfo) : null;
            this.props.history.push(`/create-account-user?id=${accountInfo.account_id}&name=${accountInfo.account_name}`, { accountData: accountInfo, from: 'users' })
        } else {
            this.props.history.push('/create-user');
        }
    }

    async onPasswordEmit(password, userData) {
        let passwordObj = {
            "username": userData.cognito_username,
            "password": password
        }
        let role = sessionStorage.getItem('userRole');
        if (role && role.indexOf('admplus') !== -1) {
            passwordObj = {
                ...passwordObj,
                "account_id": userData.account_id
            }
        }
        let response = await accountService.setUserPassword(passwordObj);
        if (!response) {
            this.onError(response);
            return;
        }
        switch (response.type) {
            case 'error':
                this.onError(response.message);
                break;
            case 'success':
                this.onSuccess('Success in setting password');
                break;
            default:
                break;
        }
    }

    onSuccess(text) {
        this.setState(prevState => ({
            show: true,
            loading: false,
            firstTimeLoading: false,
            alert: {
                ...prevState.alert,
                type: 'success',
                title: 'Success',
                text: text ? text : 'Success'
            }
        }));
    }

    render() {
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>Users</title>
                </Helmet>
                <HeaderWrapper {...this.props} screen toggleMobileNav={() => this.setState({ mobileNav: !this.state.mobileNav })} mobileNav={this.state.mobileNav}></HeaderWrapper>
                <div className="container-fluid page-body-wrapper">
                    {this.state.firstTimeLoading && <div style={{ marginLeft: '47%' }}><Spinner></Spinner></div>}
                    {/* Header For mobile view */}
                    <HeaderWrapper {...this.props} mobile mobileNav={this.state.mobileNav}></HeaderWrapper>
                    {this.state.userList && <UserComponent
                        {...this.props}
                        loading={this.state.loading}
                        userList={this.state.userList}
                        emitNewUserCreationEvent={this.getEmitedNewUserCreationEvent.bind(this)}
                        emitSelectedFilerValues={this.getEmitedSelectedFilerValues.bind(this)} emitUserDeleteEvent={this.getEmitedUserDeleteEvent.bind(this)} emitResetPasswordEvent={this.getEmitedResetPasswordEvent.bind(this)}
                        emitStageUserData={this.getEmitedStageUserData.bind(this)}
                        emitPassword={this.onPasswordEmit.bind(this)}
                        isDeleteInProgress={this.state.isDeleteInProgress}
                        emitUserEditEvent={this.getEmitedUserEditEvent.bind(this)}
                        emitNextButtonEvent={this.getEmitedNextButtonEvent.bind(this)}
                        emitRedirectToView={this.getEmitedRedirectToView.bind(this)}>
                    </UserComponent>
                    }
                    {this.state.show &&
                        <SweetAlertComponent show={this.state.show} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.sweetAlertClose}></SweetAlertComponent>
                    }
                    {this.state.showConfirmation &&
                        <SweetAlertComponent show={this.state.showConfirmation} type={this.state.alert.type} title={this.state.alert.title} text={this.state.alert.text} sweetAlertClose={this.sweetAlertDeleteClose.bind(this, true)} showCancel={true} confirmBtnText={"Yes, Delete it!"}
                            onCancel={this.sweetAlertDeleteClose.bind(this, false)}></SweetAlertComponent>
                    }
                    <ToastContainer enableMultiContainer containerId={'A'} position={toast.POSITION.TOP_RIGHT} />
                </div>
            </>
        );
    }
}

export default UsersViewContainer;
