import React, { useEffect, useState } from "react";
import AsyncTypeAhead from "./AsyncTypeAhead";
import { Portal } from 'react-overlays';

// Copy this:
// <TypeAhead async className="col-3" name="<name>" emitTypedText={(e) => console.log(e.target.value, e.target.name, e.target.async, 'when this async is true, call API')} 
// optionKey={'b'} optionValue={'a'} options={[{ a: 'We', b: 'we' }, { a: 'The', b: 'the' }, { a: 'People of nepal', b: 'people' }, { a: 'Are', b: 'are' }, { a: 'Cooler than anything!', b: 'cool' }]} />

function TypeAhead(props) {
    return props.async && <AsyncTypeAhead {...props} /> || <StaticTypeAhead {...props} />;
}

// props: options, name, placeholder, class, key, value, async
export function StaticTypeAhead(props) {
    const [options, setOptions] = useState(null)
    const [text, setText] = useState(props.value || '')
    const [visibleOptions, setVisibleOptions] = useState(null)

    useEffect(() => {
        if (!options) {
            initializeOptions()
        }
    }, [props.value])

    function initializeOptions() {
        let obj = props.options
        if (!obj || (Array.isArray(obj) && obj.length === 0)) {
            setOptions([]);
            setVisibleOptions([]);
            return
        }
        if (Array.isArray(obj) && obj.length) {
            // options = [{a: , b: , c:}, {a: , b: , c:}...]
            if (typeof obj[0] === 'object') {
                let keyProp = props.optionKey || 'key'
                let valueProp = props.optionValue || 'value'
                if (obj[0][keyProp] === undefined) {
                    throw "Type Ahead Requires a valid 'optionKey' prop for options with objects or provide a 'key' in your objects"
                }
                if (obj[0][valueProp] === undefined) {
                    throw "Type Ahead Requires a valid 'optionValue' prop for options with objects or provide a 'value' in your objects"
                }
                let opts = obj.map(o => ({ key: o[keyProp], value: o[valueProp] }))
                setOptions(opts);
                setVisibleOptions(opts);
                return
            }
            // options = [a, b, c]
            if (typeof obj[0] === 'string') {
                let opts = obj.map(o => ({ key: o, value: o }))
                setOptions(opts);
                setVisibleOptions(opts);
                return
            }
        }
    }

    function valueClicked(option) {
        setText(option.value)
        props.emitTypedText({ target: { name: props.name, value: option.key, async: false } })
    }

    function valueSetByEnter(option, e) {
        if (!e || !e.keyCode || e.keyCode !== 13) return;
        e.currentTarget.click(e);
    }

    function onTextChangeValue(event) {
        let { name, value } = event.target;
        setText(value);
        props.emitTypedText({ target: { name, value, async: false } })
        const regex = new RegExp(value, 'gi');
        let data = options.filter(opt => {
            let comparableItems = [opt.key, opt.value];
            let retVal = true;
            let matched = [];
            comparableItems.forEach(item => {
                if (typeof (item) === 'string') {
                    retVal = item.match(regex);
                }
                if ((retVal && typeof (retVal) === 'object') && retVal.length > 0) {
                    matched = retVal;
                }
            })
            if (matched.length > 0) {
                return matched
            }
            return retVal;
        })
        setVisibleOptions(data)
    }

    return <>
        {/* <OverlayContainer> */}
            <div className={`${props.class || 'col-3'} dropdown`} data-toggle="dropdown">
                <input value={props.value} name={props.name} placeholder={props.placeholder || 'Type to see options'} autoComplete="off" className="form-control" 
                onChange={onTextChangeValue.bind(this)} onFocus={props.onFocus ? props.onFocus.bind(this) : () => {}}/>
            </div>
            <div className={`p-0 ${props.class} dropdown-menu type-ahead`}>
                {visibleOptions && visibleOptions.map((opt, idx) => <a key={idx} tabIndex="0" onClick={valueClicked.bind(this, opt)} onKeyDown={valueSetByEnter.bind(this, opt)}
                className="dropdown-item border-bottom pt-2 pb-2" style={{textTransform:'uppercase'}}>{opt.value}</a>)}
            </div>
        {/* </OverlayContainer> */}
    </>;
}

export default TypeAhead;

