import React from "react";

class SomethingWentWrongComponent extends React.Component {

    //  window.location = "/doctor-login";
    onHomeButtonClicked = (event) => {
        window.location = "/doctor-login";
    }
    render() {
        const { error, errorInfo } = this.props;
        return (
            <>
                <div className="container-fluid page-body-wrapper full-page-wrapper">
                    <div className="content-wrapper d-flex align-items-center text-center error-page bg-success">
                        <div className="row flex-grow">
                            <div className="col-lg-7 mx-auto text-white">
                                <div className="row align-items-center d-flex flex-row">
                                    <div className="col-lg-6 text-lg-right pr-lg-4">
                                        <h1 className="display-1 mb-0">
                                            <img src="images/error-link.png" className="img-fluid " /></h1>
                                    </div>
                                    <div className="col-lg-6 error-page-divider text-lg-left pl-lg-4">
                                        <h2 className="heading-2">OOP's! </h2>
                                        <h3 className="font-weight-light">Something went wrong ! </h3>
                                        <p className="text-white">{error}. </p>
                                        <p className="text-white">{errorInfo}. </p>
                                    </div>
                                </div>
                                <div className="row mt-5">
                                    <div className="col-12 text-center mt-xl-2">
                                        <a className="text-white font-weight-medium" onClick={this.onHomeButtonClicked.bind(this)} style={{ cursor: 'pointer' }}>Back to home</a>
                                    </div>
                                </div>
                                {/* <div className="row mt-5">
                                    <div className="col-12 mt-xl-2">
                                        <p className="text-white font-weight-medium text-center">Copyright &copy; 2019  All rights reserved.</p>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </div>
            </>

        )
    }
}

export default SomethingWentWrongComponent;