import React from "react";
import ValidationComponent from "./ValidationComponent";
import ReactTooltip from 'react-tooltip';

class TextboxComponent extends React.Component {

    constructor(props) {
        super(props);
        this.changeValue = this.changeValue.bind(this);
        this.onFocus = this.onFocus.bind(this);
    }

    changeValue = (event) => {
        event.preventDefault();
        this.props.emitTextboxValue({ name: event.target.name, value: event.target.value })
    }

    onFocus = (event) => {
        event.preventDefault();
        this.props.emitFocusEvent()
    }

    textToHtmlConversion(text) {
        return { __html: text };
    }

    setErrorStatus = (errorStatus) => {
        this.props.emitErrorStatus(errorStatus);
    }

    checkForLabelValue(str) {
        if (!str || !str.trim()) {
            return false;
        }
        return true
    }

    render() {
        // console.log('-----------------------------------textbox---------------------------------');
        const { type = "textbox", name,
            placeholder, label, labelSize, size,
            value, readOnly, offset, rowType,
            showType, showUnit, info } = this.props;
        return (

            <React.Fragment>
                {/* <div className={`col-lg-${size ? size : 12} `} style={{ padding: rowType === "row" ? '' : '', margin: rowType === "row" ? '' : '' }}> */}
                {/* <div className="row"> */}
                {labelSize && <label className={`col-${labelSize} col-form-label mb-0`} style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}>
                    {label}{showUnit && <sub style={{ bottom: 0, marginLeft: '3px' }}>({showUnit})</sub>}</label>}
                <div className={`col${(showType !== "xyTable" && size) ? "-" + size : ''} 
                ${showType === "table" ? 'p-0' : ''}`} >
                    {!labelSize && <label className={'col-form-label mb-0'} style={{ display: this.checkForLabelValue(label) ? '' : 'none' }}>
                        {label}{showUnit && <sub style={{ bottom: 0, marginLeft: '3px' }}>({showUnit})</sub>}</label>}
                    {readOnly ? (
                        <label className="form-label" dangerouslySetInnerHTML={this.textToHtmlConversion(value)}></label>

                    ) : (<>
                        <div style={{ display: info ? 'flex' : '' }}>
                            <input
                                className="form-control"
                                id={name}
                                name={name}
                                type={type}
                                disabled={readOnly ? true : false}
                                value={value}
                                placeholder={placeholder ? placeholder : showUnit}
                                onChange={this.changeValue}
                                onFocus={this.onFocus} />
                            {info &&
                                <>
                                    <a style={{ padding: "0px 5px" }} className="nav-link count-indicator dropdown dropdown-hover"
                                        data-tip data-for="textbox-info">
                                        <i className="mdi mdi-information mdi-24px text-success"></i>
                                    </a>
                                    <ReactTooltip id="textbox-info" effect='solid' place="right">
                                        <span>{info}</span>
                                    </ReactTooltip>
                                </>
                            }
                        </div>



                        {this.props.validation && <ValidationComponent value={value} checkForm={this.props.checkForm} isDirty={this.props.isDirty} validation={this.props.validation} emitErrorStatus={this.setErrorStatus.bind(this)} />}
                    </>)

                    }
                </div>
                {/* </div> */}

                {/* </div> */}
                {offset &&
                    <div className={offset}> </div>
                }
            </React.Fragment>
        );
    }
}

export default TextboxComponent;
