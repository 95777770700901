export const S3UPLOAD_KEY = {
    bucket: 'thbemrlive-userfiles-mobilehub-1407691031', //Your bucket name;
    region: 'ap-south-1', //Specify the region your bucket was created in;
    startUrl:'https://thbemrlive-userfiles-mobilehub-1407691031.s3.amazonaws.com/'
}

export const S3UPLOAD_KEY_PUBLIC = {
    bucket: 'healthylabs', 
    region: 'ap-southeast-1'  
}

export const S3UPLOAD_KEY_NEW = {
    bucket:'provider-logos',
    region: 'ap-south-1'
}