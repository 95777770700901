import React from "react";
import DoctorListView from "./DoctorListView";
import appointmentService from "../../../Services/appointment.service";
import NotificationComponent from "../../Notification/NotificationComponent";
import Utils from "../../../Shared/utils/utils";
class RouteViewComponent extends React.Component {

    userRole = sessionStorage.getItem('userRole')

    onRouteClick = (event) => {
        this.props.emitEventOnNavClick(event);
    }

    onLogOutEvent = (event) => {
        this.props.emitLogoutEvent(true);
    }

    onMobileSideNavClicked = (event) => {
        this.props.emitMobileNavEvent(true);
    }

    onCheckboxClicked = (event) => {
        if (sessionStorage.getItem('apiStage') === "prod") {
            sessionStorage.setItem('apiStage', 'dev');
        } else {
            sessionStorage.setItem('apiStage', 'prod');
        }
        window.location.reload()
    }

    getUserInitials() {
        let profileData = sessionStorage.getItem('profileInfo') ? JSON.parse(sessionStorage.getItem('profileInfo')) : null;
        if (!profileData) {
            return "User";
        }
        let initials
        let names = profileData.name.split(' ');
        initials = names[0].substring(0, 1).toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
    }

    getUserName() {
        let profileData = sessionStorage.getItem('profileInfo') ? JSON.parse(sessionStorage.getItem('profileInfo')) : null;
        if (!profileData) {
            return "";
        }
        return profileData.name;
    }
    swithUserView(event) {
        let active_view = sessionStorage.getItem("active_view");
        let switchRole = active_view == "r:admin" ? Utils.isAdminAndDataUser(sessionStorage.getItem("userRole")) : "r:admin"
        sessionStorage.setItem('dataAdmin', "");
        sessionStorage.setItem('dataUser', "");
        sessionStorage.setItem('dataOps', "");
        sessionStorage.setItem('showUser', "");
        sessionStorage.setItem('showAppointment', "");
        switch (true) {
            case switchRole == "r:admin":
                sessionStorage.setItem('showUser', 'true');
                if (sessionStorage.getItem("userRole").indexOf("doctor") !== -1 || sessionStorage.getItem("userRole").indexOf("staff") !== -1) {
                    sessionStorage.setItem('showAppointment', 'true');
                } else {
                    sessionStorage.setItem('showAppointment', '');
                }
                break;
            case switchRole == "r:dataadmin":
                sessionStorage.setItem('dataAdmin', 'true');
                sessionStorage.setItem('dataUser', 'true');
                break;
            case switchRole == "r:dataops":
                sessionStorage.setItem('dataOps', 'true');
                sessionStorage.setItem('dataUser', 'true');
                break;
            default:
                sessionStorage.setItem('dataUser', 'true');

        }
        window.location.href = "/"
        sessionStorage.setItem("active_view", switchRole)

    }

    switchRole() {
        let buttonView = (<ul className="navbar-nav navbar-nav-left  navbar-collapse" style={{ color: 'white' }}>
            <li className="nav-item nav-profile dropdown" style={{ textTransform: 'capitalize' }}>
                <a className="nav-link" style={{ width: 100 }}>
                    <div className="nav-profile-text ml-1" style={{ marginLeft: "35px", fontSize: "12px" }}>
                        <p className="mb-1 text-white">Admin</p>
                    </div>
                    <div className="nav-profile-img">
                        <div className="myswitch" style={{ marginTop: -9 }}>
                            <label className="switch" >
                                <input type="checkbox" name="toogleView" checked={(sessionStorage.getItem('active_view') === "r:admin") ? false : true} onClick={this.swithUserView.bind(this)} />
                                <span className="slider"></span>
                            </label>

                        </div>
                    </div>
                    <div className="nav-profile-text" style={{ marginLeft: "35px", fontSize: "12px" }}>
                        <p className="mb-1 text-white">Data</p>
                    </div>
                </a>

            </li>
        </ul>)
        return Utils.isAdminAndDataUser(sessionStorage.getItem("userRole")) ? buttonView : false
    }

    async updateVersion() {
        try {
            let response = await appointmentService.updateAppVersion();
            if (response && response.type === 'success' && response.data && response.data.web_app_details) {
                alert('Please update this number in AppVersion.js also!! "new version:"' + response.data.web_app_details.version);
                sessionStorage.setItem('appVersion', response.data.web_app_details.version);
            } else {
                alert('error:' + response);
            }
        } catch (err) {
            alert('error in catch:' + err);
        }
    }

    render() {
        const { emrNavBarRoutes } = this.props;
        return (
            <div className="navbar-menu-wrapper d-flex align-items-stretch">
               
                <ul className="navbar-nav navbar-nav-left navbar-collapse collapse" id="RouteViewComponent">
                    {this.props.emrNavBarRoutes && this.props.emrNavBarRoutes.map((indEmrNavBarRoutes, index) =>
                        <React.Fragment key={index}>
                            {(indEmrNavBarRoutes.type === "single" && indEmrNavBarRoutes.display) &&
                                <li className="nav-item">
                                    <div className={`nav-link ${indEmrNavBarRoutes.isActive ? 'active' : ''}`} onClick={this.onRouteClick.bind(this, indEmrNavBarRoutes.route)} style={{ cursor: 'pointer', marginLeft: '.8rem', marginRight: '.8rem' }}> {indEmrNavBarRoutes.navbarName} 
                                        {indEmrNavBarRoutes.key === 'patient-queries' && this.props.openChatCount > 0 && <label className="mt-2">({this.props.openChatCount})</label>}
                                    </div>
                                </li>
                            }
                            {(indEmrNavBarRoutes.type === "multi" && indEmrNavBarRoutes.display) &&
                                <li className="nav-item dropdown pointer-cursor">
                                    <a className={`nav-link dropdown-toggle ${indEmrNavBarRoutes.isActive ? 'active' : ''}`} id="orders-dropdown" data-toggle="dropdown" aria-expanded="false"> {indEmrNavBarRoutes.navbarName} </a>
                                    <div className="dropdown-menu navbar-dropdown" aria-labelledby="orders-dropdown">
                                        {indEmrNavBarRoutes.options.map((indRouteOptions, jndex) =>
                                            <React.Fragment key={jndex}>
                                                <a className="dropdown-item pointer-cursor" onClick={this.onRouteClick.bind(this, indRouteOptions.route)}>
                                                    {indRouteOptions.navbarName} </a>
                                                <div className="dropdown-divider"></div>
                                            </React.Fragment>
                                        )}
                                    </div>
                                </li>
                            }
                        </React.Fragment>
                    )}
                </ul>
                {this.userRole && this.userRole.indexOf('doctor') !== -1 && false && <NotificationComponent />}

                {/* */}
                {/* <i className="mdi mdi-account-box-outline"></i> */}
                <ul className="navbar-nav navbar-nav-right">
                    {
                        this.switchRole()
                    }
                    {sessionStorage.getItem('userRole') === "superadmin" &&
                        <ul className="navbar-nav navbar-nav-left  navbar-collapse" style={{ color: 'white' }}>

                            <li className="nav-item nav-profile dropdown" style={{ textTransform: 'capitalize' }}>
                                <a className="nav-link" style={{ width: 100 }}>
                                    <div className="nav-profile-img">
                                        <div className="myswitch" style={{ marginTop: -9 }}>
                                            <label className="switch" >
                                                <input type="checkbox" name={"isActive"} checked={(sessionStorage.getItem('apiStage') === "prod") ? true : false} onClick={this.onCheckboxClicked.bind(this)} />
                                                <span className="slider"></span>
                                            </label>

                                        </div>
                                    </div>
                                    <div className="nav-profile-text ml-5">
                                        <p className="mb-1 text-white">{sessionStorage.getItem('apiStage')}</p>
                                    </div>
                                </a>

                            </li>
                        </ul>
                    }
                    {sessionStorage.getItem('userRole') === "support" &&
                        <ul className="navbar-nav navbar-nav-left  navbar-collapse" style={{ color: 'white' }}>

                            <li className="nav-item nav-profile dropdown" style={{ textTransform: 'capitalize' }}>
                                <a className="nav-link" style={{ width: 100 }}>
                                    <div className="nav-profile-img">
                                        <div className="myswitch" style={{ marginTop: -9 }}>
                                            <label className="switch" >
                                                <input type="checkbox" name={"isActive"} checked={(sessionStorage.getItem('apiStage') === "prod") ? true : false} onClick={this.onCheckboxClicked.bind(this)} />
                                                <span className="slider"></span>
                                            </label>

                                        </div>
                                    </div>
                                    <div className="nav-profile-text ml-5">
                                        <p className="mb-1 text-white">{sessionStorage.getItem('apiStage')}</p>
                                    </div>
                                </a>

                            </li>
                        </ul>
                    }
                    {this.props.showDoctorList && <DoctorListView doctorList={this.props.doctorList}
                        selectedDoc={this.props.selectedDoc}
                        getListForDoctor={e => this.props.getListForDoctor(e)}></DoctorListView>}
                    <li className="nav-item nav-profile dropdown" style={{ display: this.props.isOpenRoute ? 'none' : '' }}>
                        <a className="nav-link dropdown-toggle" id="profileDropdown" data-toggle="dropdown"
                            aria-expanded="false">
                            <div className="nav-profile-img" title={(window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1) ? 'Beta' : ""}>
                                {(window.location.hostname.indexOf('localhost') !== -1 || window.location.hostname.indexOf('beta') !== -1) &&
                                    <img className="spinner rotate" src="images/sunny.svg" alt="image" />
                                }
                            </div>
                            <div className="nav-profile-text" style={{ cursor: 'pointer' }}>
                                <p data-letters={this.getUserInitials()} style={{ margin: 5 }} title={this.getUserName()}></p>
                            </div>
                        </a>
                        <div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
                            <a className="dropdown-item" onClick={this.onRouteClick.bind(this, '/user-profile')} style={{ cursor: 'pointer' }}>
                                <i className="mdi mdi-account-box-outline mr-2 text-success"></i>
                                Profile
                                </a>
                            {sessionStorage.getItem('s3AccountId')=='meitra_hospital'?'':((sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole').indexOf("admin") !== -1) || (sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole').indexOf("doctor") !== -1) || (sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole').indexOf("isPharmacist") !== -1)) &&
                                <>
                                    <div className="dropdown-divider"></div>
                                    <a className="dropdown-item" onClick={this.onRouteClick.bind(this, '/settings')} style={{ cursor: 'pointer' }}>
                                        <i className="mdi mdi-settings mr-2 text-success"></i>
                                        Settings
                                    </a>
                                </>
                            }
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" onClick={this.onRouteClick.bind(this, '/about-us')} style={{ cursor: 'pointer' }}>
                                <i className="mdi mdi-home mr-2 text-success"></i>
                                About Us
                                </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" onClick={this.onRouteClick.bind(this, '/terms-conditions')} style={{ cursor: 'pointer' }}>
                                <i className="mdi mdi-file-document-box mr-2 text-success"></i>
                                Terms And Conditions
                                </a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item" onClick={this.onRouteClick.bind(this, '/clinytics-faqs')} style={{ cursor: 'pointer' }}>
                                <i className="mdi mdi-format-list-bulleted-type mr-2 text-success"></i>
                                FAQ
                                </a>
                            <div className="dropdown-divider"></div>
                            {window.location.hostname === 'localhost' && <><a className="dropdown-item" onClick={() => this.updateVersion()} style={{ cursor: 'pointer' }}>
                                <i className="mdi mdi-arrow-up mr-2 text-success"></i>
                                Update App Version
                                </a>
                                <div className="dropdown-divider"></div></>}
                            <a className="dropdown-item" onClick={this.onLogOutEvent.bind(this)} style={{ cursor: 'pointer' }}>
                                <i className="mdi mdi-logout mr-2 text-primary"></i>
                                Signout
                                </a>
                        </div>
                    </li>
                </ul>
                <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas" onClick={this.onMobileSideNavClicked.bind(this)}>
                    {/* <span className="mdi mdi-menu"></span> */}
                    <div id="nav-icon1" className={this.props.mobileNav ? "open" : ''}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </button>
            </div>

        );
    }
}

export default RouteViewComponent;
