const nepali = {
    "dear": "नमस्ते",
    "ok": "ठिक छ",
    "thankPatient": "टेलि कन्सल्टेशन प्रयोग गर्नुभएकोमा धन्यवाद",
    "acceptBrowserReq": "वेब ब्राउजरले माइक्रोफोन र क्यामरको प्रयोगलाई अनुमति दिन प्रम्प्ट गर्नेछ। कृपया भिडियो-परामर्श कोठामा सामेल हुनु अघि 'अनुमति दिनुहोस्' मा क्लिक गर्नुहोस्। ",
    "docExited": "एजुकेटर भिडियो परामर्श कोठाबाट बाहिर निस्कनुभएको छ।",
    "chatRoomExpired": "यो च्याट कोठाको म्याद सकिएको छ",
    "thisIsWA": "यो परामर्शको लागि प्रतीक्षा क्षेत्र हो",
    "docName": "एजुकेटरको नाम",
    "yourApptSlot": "तपाईंको परामर्श स्लट :- ",
    "uploadInstructn": "यदि आप अपने पिछले पर्चे या रिपोर्ट डॉक्टर को दिखाना चाहते हैं तो उनकी फोटो खिंच कर यहाँ से अपलोड करें",
    "upload": "अपलोड करें",
    "checkinDisabled": "तपाईंको परामर्श समय भन्दा 1 घण्टा अगाडि चेक-इन सक्षमर सुचारु हुनेछ ",
    "recordingText": "यो कल गुणस्तर र अनुगमन उद्देश्यका लागि रेकर्ड गरिनेछ।",
    "clickToCopy": "URL प्रतिलिपि गर्न क्लिक गर्नुहोस्",
    "copied": "प्रतिलिपि गरियो",
    "clickTocheckIn": "चेक इन गर्न यहाँ क्लिक गर्नुहोस्",
    "startsIn": "तपाईंको भिडियो-परामर्श यहाँ सुरु हुन्छ: ",
    "startConsult": "परामर्श सुरु गर्नुहोस्",
    "faq": "प्रश्नहरू",
    "useChrome": "तपाईंले उत्कृष्ट अनुभवको लागि क्रोम ब्राउजर प्रयोग गरिरहनु पर्छ",
    "useSafari": "तपाईंले उत्तम अनुभवको लागि सफारी ब्राउजर प्रयोग गरिरहनु पर्छ",
    "support": "सहयोग",
    "clickToOpen": "खोलने के लिए क्लिक करें",
    "clinicSupport": "सहायता",
    "afterCheckinInstruc": "तपाईं आफ्नो समयभन्दा 10 मिनेट अगाडी परामर्श सुरु गर्न सक्नुहुन्छ।",
    "noPrescYet": "अभी डॉक्टर ने आपके परामर्श का परचा अपलोड नहीं करा है. परामर्श पर्ची आपको आपके SMS, ईमेल और इस स्थान पर मिलेगी",
    "uploadedAPresc": "ने एक प्रिस्क्रिप्शन अपलोड किया है",
    "required":"आवश्यक छ",
    "ageRequired":"उमेर आवश्यक छ",
    "invalidAge":"अवैध उमेर",
    "requiredForChildrenBelow18":"18 वर्ष मुनिका बालबालिकाका लागि आवश्यक छ",
    "patientIsAlreadyRegistered":"बिरामी पहिले नै दर्ता गरिएको छ !",
    "registrationDone":"दर्ता सकियो !",
    "clickOkToProceed":"दर्ता भयो । अघि बढ्नका लागि ठीक छ बटन थिच्नुहोला।",
    "useChromeBrowserForBestViewingExperience":"उत्कृष्ट अवलोकन अनुभवको लागि क्रोम ब्राउजर प्रयोग गर्नुहोस्",
    "clickToCopyURL":"URL प्रतिलिपि गर्न क्लिक गर्नुहोस्",
    "urlCopied":"Url प्रतिलिपि गरियो",
    "registrationForm":"दर्ता फारम",
    "name":"नाम",
    "enterFullName":"पूरा नाम भर्नुहोस्",
    "age":"उमेर",
    "ageExample":"उदाहरण: 2 वर्ष 5 महिना",
    "ageIn":"उमेर मा ",
    "yrs":"वर्ष",
    "mth":"महिना",
    "useSafariBrowserForBestViewingExperience":"उत्तम अवलोकन अनुभवको लागि सफारी ब्राउजर प्रयोग गर्नुहोस्",
    "nextAvailable":"अर्को उपलब्ध",
    "notAvailableTodayAndTomorrow":"आज र भोलि उपलब्ध छैन",
    "bookAppointment":"समय बुक गर्नुहोस्",
    "back":"पछाडि",


    "province":"प्रदेश",
    "district":"जिल्ला",
    "chooseOne":"एउटा छान्नुहोस्",

    "Province No.1":"प्रदेश नं. १",
    "Madesh Pradesh":"मधेश प्रदेश",
    "Bagmati Pradesh":"बागमती प्रदेश",
    "Gandaki Pradesh":"गण्डकी प्रदेश",
    "Lumbini Pradesh":"लुम्बिनी प्रदेश",
    "Karnali Pradesh":"कर्णाली प्रदेश",
    "Sudurpashchim Pradesh":"सुदूरपश्चिम प्रदेश",

    "Solukhumbu":"सोलुखुम्बु",
    "Sankhuwasabha":"सङ्खुवासभा",
    "Taplejung":"ताप्लेजुङ",
    "Okhaldhunga":"ओखलढुङ्गा",
    "Khotang":"खोटाङ",
    "Bhojpur":"भोजपुर",
    "Tehrathum":"तेह्रथुम",
    "Panchthar":"पाँचथर",
    "Dhankuta":"धनकुटा",
    "Ilam":"इलाम",
    "Udayapur":"उदयपुर",
    "Sunsari":"सुनसरी",
    "Morang": "मोरङ",
    "Jhapa":"झापा",


    "Parsa":"पर्सा",
    "Bara":"बारा",
    "Rautahat":"रौतहट",
    "Sarlahi":"सर्लाही",
    "Mahottari":"महोत्तरी",
    "Dhanusha":"धनुषा",
    "Siraha":"सिराहा",
    "Saptari":"सप्तरी",


    "Rasuwa":"रसुवा",
    "Nuwakot": "नुवाकोट",
    "Dhading": "धादिङ",
    "Chitwan":"चितवन",
    "Makwanpur":"मकवानपुर",
    "Kathmandu":"काठमाडौं",
    "Bhaktapur":"भक्तपुर",
    "Lalitpur":"ललितपुर",
    "Sindhupalchok":"सिन्धुपाल्चोक",
    "Kavrepalanchok": "काभ्रेपलाञ्चोक",
    "Sindhuli": "सिन्धुली",
    "Ramechap":"रामेछाप",
    "Dolakha": "दोलखा",


    "Mustang":"मुस्ताङ",
    "Manang":"मनाङ",
    "Gorkha":"गोरखा",
    "Lamjung":"लमजुङ",
    "Tanahun":"तनहुँ",
    "Nawalpur":"नवलपुर",
    "Syangja":"स्याङ्जा",
    "Parbat":"पर्वत",
    "Baglung": "बाग्लुङ",
    "Myagdi":"म्याग्दी",
    "Kaski":"कास्की",


    "Bardiya":"बर्दिया",
    "Banke":"बाँके",
    "Dang":"दाङ",
    "Rolpa":"रोल्पा",
    "Eastern Rukum":"पूर्वी रुकुम",
    "Pyuthan":"प्युठान",
    "Gulmi":"गुल्मी",
    "Arghakhanchu":"अर्घाखाँचु",
    "Palpa":"पाल्पा",
    "Kapilvastu": "कपिलवस्तु",
    "Rupandehi":"रुपन्देही",
    "Nawalparasi West":"नवलपरासी पश्चिम",


    "Humla":"हुम्ला",
    "Jumla":"जुम्ला",
    "Mugu":"मुगु",
    "Kalikot":"कालिकोट",
    "Dailekh":"दैलेख",
    "Surkhet":"सुर्खेत",
    "Salyan":"सल्यान",
    "Jajarkot":"जाजरकोट",
    "Dolpa":"डोल्पा",
    "Rukum West":"रुकुम पश्चिम",


    "Darchula":"दार्चुला",
    "Baitadi":"बैतडी",
    "Dadeldhura":"डडेल्धुरा",
    "Kanchanpur":"कञ्चनपुर",
    "Kailali":"कैलाली",
    "Doti":"डोटी",
    "Bajhang":"बझाङ",
    "Bajura":"बाजुरा",
    "Achham":"अछाम",


    "guardianName":"संरक्षक नाम",
    "enterGuardianName":"संरक्षक नाम भर्नुहोस्",
    "guardianPhone":"अभिभावक फोन",
    "gender":"लिङ्ग",
    "male":"पुरुष",
    "female":"महिला",
    "others": "अन्य",
    "emailID":"इमेल आईडी",
    "enterEmail":"इमेल भर्नुहोस्",
    "mobile":"मोबाइल",
    "forGettingVerificationCode":"प्रमाणिकरण कोड प्राप्त गर्न को लागी",
    "referringDoctor":"रिफर गर्ने डाक्टर",
    "enterFullName ":"पूरा नाम भर्नुहोस्",
    "note":"नोट",
    "ifYouAreRegisteringFromOutsideIndiaPleaseUseEmailIdToProceedForRegistration":"यदि तपाइँ भारत बाहिरबाट दर्ता गर्दै हुनुहुन्छ भने, कृपया दर्ताको लागि अगाडि बढ्नको लागि इमेल आईडी प्रयोग गर्नुहोस्",
    "anyQueriesToBeSharedWithEducator":"कुनै पनि प्रश्नहरू एजुकेटर सँग साझेदारी गर्न सोध्ने",
    "enterYourQuery":"आफ्नो क्वेरी भर्नुहोस्",
    "byContinuingYouAgreeToThePrivacyPolicyTheDisclaimerClausesOfThisTeleVideoConsultation":"तपाईं यस टेलिभिडियो परामर्शको गोपनियता नीति तथा अस्वीकरण एवम् शर्तहरुसँग सहमती हुनुहुन्छ।",
    "continue":"जारी राख्नुहोस्",
    "enterYourAge":"उमेर भर्नुहोस्",

    "OTPVerification":"OTP प्रमाणीकरण",
    "couldNotVerifyOTPPleaseTryAgain":"OTP प्रमाणित गर्न सकेन, कृपया पुन: प्रयास गर्नुहोस्",
    "yourChosenEducator":"तपाईंले रोजेको एजुकेटर",
    "easyVideoChat":"सजिलो भिडियो च्याट",
    "enterOTPSentTo":"पठाइएको OTP भर्नुहोस्",
    "andAlsoEmail":"र इमेल पनि",
    "enterOTP":"OTP भर्नुहोस्",
    "pleaseEnterThe6DigitOTPHereToVerify":"कृपया प्रमाणीकरण गर्न यहाँ ६ अंकको OTP भर्नुहोस्",
    "verifyProceed":"प्रमाणित गर्नुहोस् र अगाडि बढ्नुहोस्",
    "DidntReceiveTheOTP":"OTP प्राप्त भएन",
    "resendOTP":"OTP पुन: पठाउनुहोस्",

    "onlineEducators":"डिजिटल एजुकेटर",
    "searchDoctorBySpeciality":"विशेषज्ञ द्वारा एजुकेटर खोज्नुहोस्",

    "selectYourBookingSlot":"आफ्नो बुकिङ स्लट छान्नुहोस्",
    "experience":"अनुभव",
    "location":"स्थान",

    "selectDateTime":"मिति र समय छान्नुहोस्",

    "success":"सफलता",
    "error":"त्रुटि",

    "thanksForYourBooking":"तपाईंको बुकिङको लागि धन्यवाद!",
    "pleaseWaitWeAreRedirectingYouToTheTeleConsultPage":"बुकिङ गनुभएकोमा धन्यवाद । कृपया प्रतिक्षा गर्नुहोस् हामीले तपाईंलाई टेलिकन्सल्ट पेजसँग जोड्दैछौं।",
    "pleaseWaitWeAreRedirectingYouToThePaymentPage":"कृपया पर्खनुहोस्, हामी तपाईंलाई भुक्तानी पृष्ठमा रिडिरेक्ट गर्दैछौं",
    "youllAlsoReceiveTheConfirmationAndPaymentLinkOnYourEmailidMobileNumberToMakeThePaymentPaymentInvoiceWillBeSentOnYourEmailid":"तपाईंले भुक्तानी गर्नको लागि आफ्नो इमेल-आईडी/मोबाइल नम्बरमा पुष्टिकरण र भुक्तानी लिङ्क पनि प्राप्त गर्नुहुनेछ। भुक्तानी इनभ्वाइस तपाईंको इमेल-आईडीमा पठाइनेछ",

    "teleConsultation":"टेली परामर्श",
    "teleConsultationLink":"टेली परामर्श लिङ्क",
    "pleaseClickHereToStartYourConsultation":"आफ्नो परामर्श सुरु गर्न कृपया यहाँ क्लिक गर्नुहोस्",

    "MustBeMinimum6Digits":"न्यूनतम 8 अंकको हुनुपर्छ",
    "MustBeMinimum10Digits":"न्यूनतम 8 अंकको हुनुपर्छ",

    "chatExpired": {
        "uploadedAPresc": "ने एक प्रिस्क्रिप्शन अपलोड किया है",
        "noPrescYet": "अभी डॉक्टर ने आपके परामर्श का परचा अपलोड नहीं करा है। कृपया प्रतीक्षा करें",
        "clickToOpen": "खोलने के लिए क्लिक करें",
        "createNewAppt": "नयाँ अपोइन्टमेन्ट सिर्जना गर्न क्लिक गर्नुहोस्",
        "thankyou": "परामर्शको लागि धन्यवाद",
        "doctor": "डॉक्टर"
    },
    "videoRoomLabels": {
        "wishToLeave": "के तपाई कोठा छोड्न चाहनुहुन्छ?",
        "onlyOneLeft": "तपाईं मात्र बाँकी हुनुहुन्छ",
        "back": "फिर्ता",
        "yes": "हो! छोड्नुहोस्",
        "clinicSupport": "सहयोग",
        "chat": "च्याट"
    },
    "settingsInfoLabels": {
        "useChrome": "तपाईंले उत्कृष्ट अनुभवको लागि क्रोम ब्राउजर प्रयोग गरिरहनु पर्छ",
        "steps": "अनुमति प्रदान गर्ने चरणहरु",
        "reloadPage": "पृष्ठ पुन: लोड गर्नुहोस्",
        "msgWillAppear": "भिडियो कल विन्डोको शीर्षमा सन्देश देखा पर्नेछ",
        "lockBtn": "अनुमतिहरूको सूची हेर्न लक बटनमा क्लिक गर्नुहोस्। त्यस पछि प्रदान गर्न भिडियो र अडियो अनुमतिहरूमा क्लिक गर्नुहोस्",
        "IGetIt": "म बुझ्दछु",
         "yesInstall": "हाँ",
        "close": "बंद करे"
    },
    "callAlert": {
       "confirm": "पुष्टि गर्नुहोस्",
        "hi": "नमस्ते",
        "acceptBrowser": "वेब ब्राउजरले माइक्रोफोन र क्यामरको प्रयोगलाई अनुमति दिन प्रम्प्ट गर्नेछ। कृपया भिडियो-परामर्श कोठामा सामेल हुनु अघि 'अनुमति दिनुहोस्' मा क्लिक गर्नुहोस्।",
        "beReady": "लाइनमा कोही पनि तयार हुनुहुन्न",
        "youAreNext": "तपाईं परामर्शको लागि अर्को हुनुहुन्छ तयार रहनुहोस्",
        "doctorJoined": "एजुकेटर सामेल हुनुभएको छ तपाईंको परामर्श सुरु गर्न तयार बटनमा क्लिक गर्नुहोस् वेब ब्राउजरले माइक्रोफोन र क्यामेरा प्रयोग गर्न अनुमति दिनेछ कृपया भिडियो परामर्श कोठामा सामेल हुनु अघि अनुमतिमा क्लिक गर्नुहोस्।",
        "clickToJoin": "अहिले सामेल हुन यहाँ क्लिक गर्नुहोस्"
    },
    "pleaseWaitModal": {
        "hi": "नमस्ते",
        "acceptBrowser": "",
        "waitingMessage": "हामीले तपाईंको कल जडान गरिरहँदा कृपया पर्खनुहोस्.."
    },
    "camBlocked": "क्यामेरा अवरुद्ध छ",
    "chat": "च्याट",
    "chatLabels": {
        "oneMsg": "तपाईले एक पटकमा एउटा मात्र सन्देश पठाउन सक्नुहुन्छ। कृपया आफ्नो सन्देश लेख्नुहोस् र त्यसपछि पठाउनुहोस्",
        "chatWithDoc": "डॉक्टर को चैट मैसेज भेजें",
  		"chatWithEdu": "एजुकेटर सँग कुराकानी",
        "noReply": "डॉक्टर ने अभी तक जवाब नहीं दिया। कृपया तब तक प्रतीक्षा करें।",
  		"noReplyEdu": "एजुकेटरले अझै जवाफ दिएको छैन। कृपया तब सम्म पर्खनुहोस्",
        "msgLengthInfo": "सन्देश लम्बाइ 20 र 1000 वर्णहरू बीच हुनुपर्छ",
        "newMsgError": "केही नयाँ सन्देशहरू लोड गर्न सकिएन।",
        "sendFailed": "पठाउन सकिएन! पुन: प्रयास गर्नुहोस्",
        "typeMsg": "सन्देश टाइप गर्नुहोस्",
        "maxLimitOver": "आपकी अधिकतम संदेश सीमा समाप्त हो गई है। कृपया जवाब मिलने तक प्रतीक्षा करें",
        "msgRemain": "बाँकी सन्देशहरू"
    },
    "supportLabels": {
        "fillForm": "फारम भर्नुहोस् र हामी सकेसम्म चाँडो सम्पर्कमा हुनेछौं",
        "selectQuery": "एउटा प्रश्न छान्नुहोस्",
        "writeQuery": "प्रश्न लेख्नुहोस्",
        "voiceNotHeard": "एजुकेटरको आवाज सुनिएन",
        "voiceNotClear": "एजुकेटरको आवाज स्पष्ट थिएन",
        "videoNotSeen": "एजुकेटरको भिडियो देख्नु भएन",
        "videoNotClear": "एजुकेटरको भिडियो स्पष्ट थिएन",
        "noPaymentLink": "परामर्श के लिए भुगतान नहीं कर सका",
        "callDrop": "परामर्श सुरु भयो, तर बीचमा कल ड्रप भयो",
        "noLink": "मुझे एसएमएस / ईमेल पर निर्देश लिंक नहीं मिला",
        "noReceipt": "मुझे भुगतान रसीद नहीं मिली",
        "requestRefund": "भुगतान वापस चाहिए",
        "couldNotUpload": "पिछली रिपोर्ट दाल नहीं पाए",
        "others": "अरू",
        "submit": "पेश गर्नुहोस्",
        "cancel": "रद्द गर्नुहोस्",
        "errorRefund": "रिफंड शुरू नहीं हो सका।"
    },
    "faqLabels": {
        "useChrome": "यो भिडियो परामर्शले एन्ड्रोइड मोबाइलको लागि क्रोम र आईओएस मोबाइलको लागि सफारीमा उत्कृष्ट काम गर्नेछ।",
        "turnOnPermisn": "महत्त्वपूर्ण: तपाईंले मोबाइल वा कम्प्युटरको प्रणाली सेटिङहरूबाट क्यामेरा र माइक्रोफोनको लागि अनुमतिहरू खोल्न आवश्यक पर्दछ।",
        "allowCamNMicro": "आफ्नो क्यामेरा र माइक्रोफोन प्रयोग गर्न अनुमति दिनुहोस्",
        "videoNeedsCam": "भिडियो कलहरूमा तपाईंको कम्प्युटरको क्यामेरा र माइक्रोफोन प्रयोग गर्न आवश्यक छ ताकि तपाईं आफ्नो भिडियो कल सुरु गर्न सक्नुहुनेछ।",
        "clickVdoCall": "भिडियो कलमा क्लिक गर्नुहोस्। भिडियो कल विन्डोको माथिल्लो भागमा सन्देश देखा पर्नेछ । ",
        "clickAllow": "'अनुमति' मा क्लिक गर्नुहोस्। तपाईंले यो केवल एक पटक गर्न आवश्यक छ। तपाईंले प्रयोग गर्न अनुमति दिन सक्नुहुन्छ:",
        "userInfo": "रेकर्डिङ, भिडियो कल तपाईंको क्यामेरा र माइक्रोफोन प्रयोग गर्दैछ",
        "contactSupp": "यदि समस्या कायमै छ भने, तपाईं हाम्रो सहयोग गर्न सक्नुहुनेछ",
        "beforeStart": "भिडियो कल सुरु गर्नु अघि",
        "ensureLatestSW": "क्यामेरा, माइक्रोफोन र स्पिकरहरू आफ्नो कम्प्युटरमा जडान गर्नुहोस्। सुनिश्चित गर्नुहोस् कि तिनीहरूसँग नवीनतम सफ्टवेयर छ",
        "noNeedForDevices": "ल्यापटप/मोबाइल/आईप्याडको मामलामा जडान गर्न आवश्यक पर्दैन किनभने तिनीहरूसँग इनबिल्ट क्यामेरा, माइक र स्पिकर हुन्छन्। आवश्यक परेमा तपाईंले आफ्नो इयरफोनहरू जडान गर्न सक्नुहुन्छ।",
        "whenAskedClickAllow": "तपाईंको कम्प्युटरको क्यामेरा र माइक्रोफोन प्रयोग गर्न सोध्दा 'अनुमति दिनुहोस्' मा क्लिक गर्नुहोस्",
       "installLatestVs": "सधैं ब्राउजरहरूको नवीनतम संस्करण इन्सटल गर्नुहोस्र राख्नुहोस्ं",
    },
    "uploadPopUp": {
        "upload": "अपनी रिपोर्ट को अपलोड करें",
        "max10": "आप अधिकतम 10 रिपोर्ट अपलोड कर सकते हैं",
        "mb_5": "हर दस्तावेज़ 5 एमबी से कम होना चाहिए",
        "fileTypes": "आप फोटो, वर्ड फाइल या पी डी एफ अपलोड कर सकते हैं",
        "allowCam": "कैमरा को तस्वीर लेने की अनुमति दे",
        "date": "दिनांक",
        "reportName": "रिपोर्ट का नाम",
        "docUploadSuccess": "दस्तावेज़ सफलतापूर्वक अपलोड हो गया!",
        "uploadError": "दस्तावेज़ अपलोड नहीं हो सका! कृपया पुन: प्रयास करें।",
        "remarks": "टिप्पणी दर्ज करें",
        "ok": "ठीक है",
        "submit": "सबमिट",
        "cancel": "रद्द गर्नुहोस्"
    }
}


const english = {
    "dear": "Dear",
    "ok": "Ok",
    "thankPatient": "Thank you for using teleconsult.",
    "chatRoomExpired": "This Chat room has expired",
    "acceptBrowserReq": "Web-browser will prompt to allow usage of microphone and camera. Please click on 'Allow' before  joining video-consultation room.",
    "docExited": "Educator has exited video consult room.",
    "thisIsWA": "This is the Waiting Area to Consult",
    "yourApptSlot": "Your consultation slot :- ",
    "docName": "Educator Name",
    "uploadInstructn": "If you have any past reports and prescription to share with doctor, upload them here",
    "uploadInstructnMoolchand": "Please upload past reports and prescriptions here before starting your consultation.",
    "upload": "Upload Files",
    "clickToCopy": "Click to copy URL",
    "copied": "Url Copied!!",
    "startsIn": " Your Video-Consultation starts in: ",
    "checkinDisabled": " Check-in will be enabled 1 hour before your consultation time.",
    "recordingText": " This call will be recorded for quality and monitoring purposes.",
    "checkinDisabled15m": " Check-in will be enabled 15 minutes before your consultation time.",
    "clickTocheckIn": "Click here to Check-In",
    "startConsult": "Start Consultation",
    "faq": "FAQ",
    "support": "Support",
    "clickToOpen": "Click to open",
    "clinicSupport": "Hospital Support",
    "afterCheckinInstruc": "You can start consultation 10 minutes before your appointment slot.",
    "noPrescYet": "No prescription has been uploaded yet. Check this space after the appointment, for prescription link.",
    "uploadedAPresc": "uploaded a prescription.",
    "useChrome": "You should be using Chrome browser for best experience",
    "useSafari": "You should be using Safari browser for best experience",
    "required":"Required",
    "ageRequired":"Age Required",
    "invalidAge":"Invalid Age",
    "requiredForChildrenBelow18":"Required for children below 18",
    "name":"Name",
    "enterFullName":"Enter full name",
    "age":"Age",
    "ageExample":"Eg: 2 Yrs 5 Months",
    "ageIn":"Age in ",
    "yrs":"Yrs",
    "mth":"Mth",


    "guardianName":"Guardian Name",
    "enterGuardianName":"Enter guardian name",
    "guardianPhone":"Guardian Phone",
    "gender":"Gender",
    "male":"Male",
    "female":"Female",
    "others": "Others",
    "emailID":"Email ID",
    "enterEmail":"Enter email",
    "mobile":"Mobile",
    "forGettingVerificationCode":"For getting verification code",
    "referringDoctor":"Referring Doctor",
    "enterFullName ":"Enter Full Name ",
    "note":"Note",
    "ifYouAreRegisteringFromOutsideIndiaPleaseUseEmailIdToProceedForRegistration":"If you are registering from outside nepal, please use email id to proceed for registration",
    "anyQueriesToBeSharedWithEducator":"Any Queries to be shared with Educator",
    "enterYourQuery":"Enter Your Query",
    "byContinuingYouAgreeToThePrivacyPolicyTheDisclaimerClausesOfThisTeleVideoConsultation":"By continuing, you agree to the Privacy Policy & the Disclaimer & Clauses of this Tele/Video consultation.",
    "continue":"Continue",
    "enterYourAge":"Enter your age",

    "OTPVerification":"OTP Verification",
    "couldNotVerifyOTPPleaseTryAgain":"Could not verify OTP. Please try again",
    "yourChosenEducator":"Your chosen Educator",
    "easyVideoChat":"Easy Video Chat",
    "enterOTPSentTo":"Enter OTP sent to",
    "andAlsoEmail":"and also email",
    "enterOTP":"Enter OTP",
    "pleaseEnterThe6DigitOTPHereToVerify":"Please enter the 6 digit OTP here to verify",
    "verifyProceed":"Verify & Proceed",
    "DidntReceiveTheOTP":"Didn’t receive the OTP?",
    "resendOTP":"Resend OTP",

    "onlineEducators":"Online Educators",
    "searchDoctorBySpeciality":"Search Educator by Speciality",
    "nextAvailable":"Next Available",
    "notAvailableTodayAndTomorrow":"Not Available Today and Tomorrow",
    "bookAppointment":"Book Appointment",
    "back":"Back",


    "province":"Province",
    "district":"District",
    "chooseOne":"Choose one",

    "Province No.1":"Province No.1",
    "Madesh Pradesh":"Madesh Pradesh",
    "Bagmati Pradesh":"Bagmati Pradesh",
    "Gandaki Pradesh":"Gandaki Pradesh",
    "Lumbini Pradesh":"Lumbini Pradesh",
    "Karnali Pradesh":"Karnali Pradesh",
    "Sudurpashchim Pradesh":"Sudurpashchim Pradesh",

    "Solukhumbu":"Solukhumbu",
    "Sankhuwasabha":"Sankhuwasabha",
    "Taplejung":"Taplejung",
    "Okhaldhunga":"Okhaldhunga",
    "Khotang":"Khotang",
    "Bhojpur":"Bhojpur",
    "Tehrathum":"Tehrathum",
    "Panchthar":"Panchthar",
    "Dhankuta":"Dhankuta",
    "Ilam":"Ilam",
    "Udayapur":"Udayapur",
    "Sunsari":"Sunsari",
    "Morang": "Morang",
    "Jhapa":"Jhapa",
    "Parsa":"Parsa",
    "Bara":"Bara",
    "Rautahat":"Rautahat",
    "Sarlahi":"Sarlahi",
    "Mahottari":"Mahottari",
    "Dhanusha":"Dhanusha",
    "Siraha":"Siraha",
    "Saptari":"Saptari",
    "Rasuwa":"Rasuwa",
    "Nuwakot": "Nuwakot",
    "Dhading": "Dhading",
    "Chitwan":"Chitwan",
    "Makwanpur":"Makwanpur",
    "Kathmandu":"Kathmandu",
    "Bhaktapur":"Bhaktapur",
    "Lalitpur":"Lalitpur",
    "Sindhupalchok":"Sindhupalchok",
    "Kavrepalanchok": "Kavrepalanchok",
    "Sindhuli": "Sindhuli",
    "Ramechap":"Ramechap",
    "Dolakha": "Dolakha",
    "Mustang":"Mustang",
    "Manang":"Manang",
    "Gorkha":"Gorkha",
    "Lamjung":"Lamjung",
    "Tanahun":"Tanahun",
    "Nawalpur":"Nawalpur",
    "Syangja":"Syangja",
    "Parbat":"Parbat",
    "Baglung": "Baglung",
    "Myagdi":"Myagdi",
    "Kaski":"Kaski",
    "Bardiya":"Bardiya",
    "Banke":"Banke",
    "Dang":"Dang",
    "Rolpa":"Rolpa",
    "Eastern Rukum":"Eastern Rukum",
    "Pyuthan":"Pyuthan",
    "Gulmi":"Gulmi",
    "Arghakhanchu":"Arghakhanchu",
    "Palpa":"Palpa",
    "Kapilvastu": "Kapilvastu",
    "Rupandehi":"Rupandehi",
    "Nawalparasi West":"Nawalparasi West",
    "Humla":"Humla",
    "Jumla":"Jumla",
    "Mugu":"Mugu",
    "Kalikot":"Kalikot",
    "Dailekh":"Dailekh",
    "Surkhet":"Surkhet",
    "Salyan":"Salyan",
    "Jajarkot":"Jajarkot",
    "Dolpa":"Dolpa",
    "Rukum West":"Rukum West",
    "Darchula":"Darchula",
    "Baitadi":"Baitadi",
    "Dadeldhura":"Dadeldhura",
    "Kanchanpur":"Kanchanpur",
    "Kailali":"Kailali",
    "Doti":"Doti",
    "Bajhang":"Bajhang",
    "Bajura":"Bajura",
    "Achham":"Achham",


    "selectYourBookingSlot":"Select your booking slot",
    "experience":"Experience",
    "location":"Location",

    "selectDateTime":"Select Date & Time",

    "success":"Success",
    "error":"error",

    "MustBeMinimum6Digits":"Must be minimum 8 digits",
    "MustBeMinimum10Digits":"Must be minimum 8 digits",

    "thanksForYourBooking":"Thanks for your booking!",
    "pleaseWaitWeAreRedirectingYouToTheTeleConsultPage":"Please wait, we are redirecting you to the Tele-Consult page",
    "pleaseWaitWeAreRedirectingYouToThePaymentPage":"Please wait, we are redirecting you to the payment page",
    "youllAlsoReceiveTheConfirmationAndPaymentLinkOnYourEmailidMobileNumberToMakeThePaymentPaymentInvoiceWillBeSentOnYourEmailid":"You’ll also receive the confirmation and payment link on your email-id/mobile number to make the payment. Payment invoice will be sent on your email-id",

    "teleConsultation":"Tele Consultation",
    "teleConsultationLink":"Tele Consultation Link",
    "pleaseClickHereToStartYourConsultation":"Please click here to start your Consultation",

    "patientIsAlreadyRegistered":"Patient is already registered!",
    "registrationDone":"Registration Done !",
    "useChromeBrowserForBestViewingExperience":"Use Chrome browser for best viewing experience.",
    "registrationForm":"Registration Form",
    "clickToCopyURL":"Click to copy URL",
    "clickOkToProceed":"Click OK to proceed.",
    "urlCopied":"Url Copied",
    "useSafariBrowserForBestViewingExperience":"Use Safari browser for best viewing experience",
    "chatExpired": {
        "uploadedAPresc": "uploaded a prescription.",
        "noPrescYet": "Please wait. Educator has not uploaded prescription yet.",
        "clickToOpen": "Click to view prescription",
        "createNewAppt": "Click to create a new appointment",
        "thankyou": "Thank you for consultation",
        "doctor": "Doctor"
    },
    "videoRoomLabels": {
        "wishToLeave": "Do you wish to leave the room?",
        "onlyOneLeft": "You are the only one left.",
        "yes": "Yes!Leave.",
        "back": "Back",
        "clinicSupport": "Support",
        "chat": "Chat"
    },
    "callAlert": {
        "confirm": "Confirm",
        "hi": "hi",
        "acceptBrowser": "Web-browser will prompt to Allow usage of microphone and camera. Please click on Allow before your join video-consultation room.",
        "beReady": "There is no one in the queue be ready.",
        "youAreNext": "You are next for consultation be ready.",
        "doctorJoined": `Educator has joined. Click on ready button to start your consultation`,
        "clickToJoin": "Click here to join now"
    },
    "pleaseWaitModal": {
        "hi": "Hi!",
        "acceptBrowser": "",
        "waitingMessage": "Please wait while we are connecting your call.."
    },
    "settingsInfoLabels": {
        "useChrome": "You should be using Chrome browser for best experience",
        "steps": "Steps To Enable Permission",
        "reloadPage": "Re-load / Refresh the page",
        "msgWillAppear": "A message will appear at the top of the video call window",
        "lockBtn": "Click on Lock button to see list of permissions. After that click on video and audio permission to enable.",
        "IGetIt": "I understand",
        "yesInstall": "Yes Install",
        "close": "Close"
    },
    "camBlocked": "Camera is Blocked",
    "chat": "Chat",
    "chatLabels": {
        "oneMsg": "You can send only one message at a time. Please write your message then send.",
        "chatWithDoc": "Chat with doctor",
        "chatWithEdu": "Chat with educator",
        "noReply": "Educator has not replied yet. Please wait until then.",
        "noReplyEdu": "Educator has not replied yet. Please wait until then.",
        "msgLengthInfo": "Message length should be between 20 and 1000 characters",
        "newMsgError": "Couldn\'t load some new messages.",
        "sendFailed": "Send Failed! Retry.",
        "typeMsg": "Type a message",
        "maxLimitOver": "Your max message limit is over. Please wait until Patient replies.",
        "msgRemain": "Remaining Messages: "
    },
    "supportLabels": {
        "fillForm": "Fill out the form and we'll be in touch as soon as possible!",
        "selectQuery": "Select a query",
        "writeQuery": "Write your query",
        "voiceNotHeard": "Educator voice was not heard",
        "voiceNotClear": "Educator voice was not clear",
        "videoNotSeen": "Educator video was not seen",
        "videoNotClear": "Educator video was not clear",
        "noPaymentLink": "Could not make payment for consultation",
        "callDrop": "Consultation was started,but call dropped in between",
        "noLink": "I didn't get prescription link on sms/email",
        "noReceipt": "I didn't get payment receipt",
        "requestRefund": "Request refund",
        "couldNotUpload": "I couldn't upload past reports ",
        "others": "Others",
        "submit": "Submit",
        "cancel": "Cancel",
        "errorRefund": "Error while initiating refund."
    },
    "faqLabels": {
        "useChrome": "This video consultation will work best on chrome for android mobile and safari for iOS mobile",
        "turnOnPermisn": "Important: You may need to turn on permissions for camera and microphone from the mobile or computer’s system settings",
        "allowCamNMicro": "Allow to use your camera and microphone",
        "videoNeedsCam": "Video calls need to use your computer's camera and microphone so you can start your video call.",
        "clickVdoCall": "Click video call . A message will appear at the top of the video call window.",
        "clickAllow": "Click 'Allow'. You only need to do this once. You can allow to use:",
        "userInfo": "Recording, the video call is using your camera and microphone.",
        "contactSupp": "If the problem persists, you can contact our support.",
        "beforeStart": "Before you start your video call",
        "ensureLatestSW": "Connect camera, microphone and speakers to your computer. Ensure they have the latest software.",
        "noNeedForDevices": "In case of laptop/mobile/ipad no need to connect – they have inbuilt camera, mic and speaker. You can connect your earphones if need be.",
        "whenAskedClickAllow": "When asked to use your computer’s camera and microphone, click 'Allow'.",
        "installLatestVs": "Always install the latest version of browsers."
    },
    "uploadPopUp": {
        "upload": "Upload/view your file & report",
        "max10": "You can upload maximum 10 documents",
        "mb_5": "Document size to be less than 5MB/document",
        "fileTypes": "Document type can be photo, PDF",
        "allowCam": "Allow camera access to take picture",
        "date": "Enter date",
        "reportName": "Report name",
        "docUploadSuccess": "Document uploaded succesfully!",
        "uploadError": "Error while uploading! Please try again.",
        "remarks": "Enter Remarks",
        "ok": "Ok",
        "submit": "Submit",
        "cancel": "Cancel"
    }
}

const labels = {
    nepali,
    english
}

export default labels;