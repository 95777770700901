import React, {
  useState, useRef, useEffect
} from 'react';

// const items = [
//   { number: "1", title: "🇦🇷 Argentina" },
//   { number: "2", title: "🤩 YASS" },
//   { number: "3", title: "👩🏼‍💻 Tech Girl" },
//   { number: "4", title: "💋 Lipstick & Code" },
//   { number: "5", title: "💃🏼 Latina" },
// ]

const initialDnDState = {
  draggedFrom: null,
  draggedTo: null,
  isDragging: false,
  originalOrder: [],
  updatedOrder: []
}

const DragToReorderList = (props) => {

  const [list, setList] = useState(props.items);
  const [dragAndDrop, setDragAndDrop] = useState(initialDnDState);
  const [isDragActive, setDragActive] = useState(false)
  useEffect(() => {
    setList(props.items)
  }, [props.items])
  // onDragStart fires when an element
  // starts being dragged
  const onDragStart = (event) => {
    const initialPosition = Number(event.currentTarget.dataset.position);
    setDragActive(true);
    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: initialPosition,
      isDragging: true,
      originalOrder: list
    });


    // Note: this is only for Firefox.
    // Without it, the DnD won't work.
    // But we are not using it.
    event.dataTransfer.setData("text/html", '');
  }

  // onDragOver fires when an element being dragged
  // enters a droppable area.
  // In this case, any of the items on the list
  const onDragOver = (event) => {

    // in order for the onDrop
    // event to fire, we have
    // to cancel out this one
    event.preventDefault();

    let newList = dragAndDrop.originalOrder;

    // index of the item being dragged
    const draggedFrom = dragAndDrop.draggedFrom;

    // index of the droppable area being hovered
    const draggedTo = Number(event.currentTarget.dataset.position);

    const itemDragged = newList[draggedFrom];
    const remainingItems = newList.filter((item, index) => index !== draggedFrom);

    newList = [
      ...remainingItems.slice(0, draggedTo),
      itemDragged,
      ...remainingItems.slice(draggedTo)
    ];

    if (draggedTo !== dragAndDrop.draggedTo) {
      setDragAndDrop({
        ...dragAndDrop,
        updatedOrder: newList,
        draggedTo: draggedTo
      })
    }

  }

  const onDrop = (event) => {

    setList(dragAndDrop.updatedOrder);

    setDragAndDrop({
      ...dragAndDrop,
      draggedFrom: null,
      draggedTo: null,
      isDragging: false
    });
  }


  const onDragLeave = () => {
    setDragAndDrop({
      ...dragAndDrop,
      draggedTo: null
    });

  }

  // Not needed, just for logging purposes:
  useEffect(() => {
    console.log("Dragged From: ", dragAndDrop && dragAndDrop.draggedFrom);
    console.log("Dropping Into: ", dragAndDrop && dragAndDrop.draggedTo);
  }, [dragAndDrop])

  useEffect(() => {
    console.log("List updated!");
  }, [list])

  return (
    <section>
      <div>

        {list.map((item, index) => {
          return (
            <div
              key={index}

              data-position={index}
              draggable

              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDrop={onDrop}

              onDragLeave={onDragLeave}

              className={dragAndDrop && dragAndDrop.draggedTo === Number(index) ? "dropArea" : ""}
            >
              <div className="accordion accordion-solid-content" role="tablist">
                <div className="card">
                  <div className="card-header" role="tab" >
                    <h6 className="m-0">
                      <a data-toggle="collapse" href="#collapse-13" aria-controls="collapse-13" className="" style={{ padding: 15 }}>{index + 1}:{item.question} </a>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          )
        })}

      </div>
      {list && list.length > 0 &&
        <div class="col-12 mb-4"><hr /><button type="submit" disabled={!isDragActive} class="btn btn-sm btn-success mr-2" onClick={() => props.emitUpdatedList(list)}>Save List Order</button></div>
      }

    </section >
  )
};

export default DragToReorderList;

