// let showTemplateGenerator = sessionStorage.getItem('userRole') && sessionStorage.getItem('userRole').indexOf("support") !== -1 ? true : false;
const  supportNavBarRoutes = [{
    type: 'single',
    navbarName: "Bookings",
    key: "service-sold",
    display: true,
    isActive: false,
    route: `/service-sold`
}, {
    type: 'single',
    route: `/invoice-report`,
    isActive: false,
    display: true,
    navbarName: "Invoice Report",
    key: "invoice-report"
}];


export default supportNavBarRoutes;